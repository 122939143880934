/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import {
  addGeneralPersonToMaillingList,
  generalPersonsList,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
import CommonLoader from "../../CommanComponents/CommonLoader";
import LoadingPage from "../../CommanComponents/LoadingPage";
import SearchGeneralPerson from "./SearchGeneralPerson";
class GeneralPersonList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      person_list: [],
      start: 0,
      end: 10,
      hasMore: true,
      isLoading: false,
      addPersonLoading: false,
      searchPersonList: [],
    };
  }

  handleLoader = (status) => {
    this.setState(() => {
      return {
        ...this.state,
        isLoading: status,
      };
    });
  };

  componentDidMount() {
    this.loadPersonList();
  }

  loadPersonList = () => {
    const { start, end, hasMore, isLoading } = this.state;
    if (!isLoading && hasMore) {
      this.setState({
        isLoading: true,
      });

      apiCall(
        METHOD.QUERY,
        generalPersonsList(getUserID(), start, end, ""),
        (data) => this.handlePersonListResponse(data),
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  setSearchArrayList = (arrayList) => {
    this.setState({
      searchPersonList: arrayList,
    });
  };

  handlePersonListResponse = (data) => {
    const { start, end } = this.state;
    if (data.is_last == 1) {
      this.setState({ hasMore: false });
    }

    this.setState((prevState) => {
      return {
        ...this.state,
        start: start + end,
        person_list: [...prevState.person_list, ...data.person_list],
        isLoading: false,
      };
    });
  };

  handleAddClick = (person_id) => {

    const {isFromMailDetail,listIdForAction} = this.props
    let  list_id=0
    if(isFromMailDetail){
      list_id= listIdForAction;

    }else{
      const mail_list_id = this.props.match.params.mail_person_id;
      const fields = mail_list_id.split("-");
       list_id = fields[0];
    }
  
    apiCall(
      METHOD.QUERY,
      addGeneralPersonToMaillingList(getUserID(), person_id, list_id),
      (data) => {
        toast.success(this.props.t(data.message));
        this.props.handleAddModal(true);
      },
      (isLoading) => {
        this.setState({
          addPersonLoading: isLoading,
        });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  loader = (<LoadingPage key={0} />);

  render() {
    const {
      end,
      isLoading,
      hasMore,
      person_list,
      addPersonLoading,
      searchPersonList,
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="d-block">
              <div
                className="modal fade show"
                id="NewEvent"
                tabindex="-1"
                aria-labelledby="NewEventLabel"
                aria-modal="true"
                style={{ display: "block" }}
              >
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header border-0 d-block pt-4 pb-0">
                      <div className="d-flex align-items-center justify-content-between mb-4 text-center">
                        <button
                          type="button"
                          onClick={() => {
                            this.props.handleAddModal();
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                        </button>
                        <h5 className="modal-title " style={{margin:'auto',textAlign:'center'}}>
                        {t("generalGroupList.addPerson")}
                      </h5>
                      </div>
                      

                      <SearchGeneralPerson
                        searchArray={this.setSearchArrayList}
                      />
                    </div>

                    <div className="modal-body">
                      {addPersonLoading && <CommonLoader />}
                      <div className="wrapper position-relative">
                        <div className="middle-section sab-middle-section1">
                          {searchPersonList.length != 0 ? (
                            <div className="container my-4 00z-index-99">
                              {searchPersonList &&
                                searchPersonList.map((item, index) => {
                                  const { id, name } = item;
                                  return (
                                    <div className="form-group" key={index}>
                                      <div className="d-flex align-items-center justify-content-between mb-1">
                                        <label className="font-14">
                                          {name && name}
                                        </label>

                                        <button
                                          type="button"
                                          className="btn btn-secondary btn-sm btn-small"
                                          onClick={() =>
                                            this.handleAddClick(id)
                                          }
                                        >
                                          <i className="zmdi zmdi-plus zmdi-hc-fw"></i>
                                        </button>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          ) : (
                            <div className="container my-4 00z-index-99">
                              {person_list &&
                                person_list.map((item, index) => {
                                  const { id, name } = item;
                                  return (
                                    <div className="form-group" key={index}>
                                      <div className="d-flex align-items-center justify-content-between mb-1">
                                        <label className="font-14">
                                          {name && name}
                                        </label>

                                        <button
                                          type="button"
                                          className="btn btn-secondary btn-sm btn-small"
                                          onClick={() =>
                                            this.handleAddClick(id)
                                          }
                                        >
                                          <i className="zmdi zmdi-plus zmdi-hc-fw"></i>
                                        </button>
                                      </div>
                                    </div>
                                  );
                                })}

                              {hasMore && (
                                <div className="text-center mt-5">
                                  <button
                                    type="button"
                                    className="btn btn-primary-theme list"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.loadPersonList();
                                    }}
                                  >
                                    {t("HomeScreen.showMore")}
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop fade show"></div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(withRouter(GeneralPersonList));
