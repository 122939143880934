import React, { Component } from 'react'
import { Translation, withTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import SetUpSystemDesktop from '../SetupSystem/SetUpSystemDesktop';

 class AdminSideMenu extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      isSystemSetupOpen: false,
    }
  }
  
  handleSystemSetupdialogue = () => {
    this.setState((prev) => {
      return {
        isSystemSetupOpen: !prev.isSystemSetupOpen,
      };
    });
  };
    render() {
      const {isSystemSetupOpen} = this.state
      const {activeIndex} = this.props
        return (
            <Translation ns="translation">
            {(t, { i18n }) => {
              return (
            <div className="col-lg-3">
            <ul className="card list-unstyled sidebar-list p-4 m-0">
            
              <li className="mb-4">
                <Link to="/manage-menu" className={activeIndex==0 && "active"}>
                {t("administration.manageMenuList")}
                </Link>
              </li>

              <li className="mb-4">
              <Link to="/manage-emails" className={activeIndex==1 && "active"}>
                {t("administration.manageEmailTitle")}
                </Link>
              </li>
            
              <li className="mb-4">
              <Link to="/manage-location" className={activeIndex==2 && "active"}>
              {t("administration.manageLocationList")}
                </Link>
              </li>

              <li className="mb-4">
              <Link to="/manage-user" className={activeIndex==3 && "active"}>
              {t("administration.manageUserTitle")}
                </Link>
              </li>

              <li className="mb-4">
              <Link to="/channel-list" className={activeIndex==4 && "active"}>
              {t("administration.manageChannelTitle")}
                </Link>
              </li>

              <li className="mb-4">
              <Link to="/manage-eventlist-type" className={activeIndex==5 && "active"}>
              {t("administration.manageEventistTypeTitle")}
                </Link>
              </li>

              <li className="mb-4">
              <Link to="/manage-report-type" className={activeIndex==6 && "active"}>
              {t("administration.manageReportTypeTitle")}
                </Link>
              </li>

              <li className="mb-4">
              <Link to="/manage-channel-type" className={activeIndex==7 && "active"}>
              {t("administration.manageChannelTypeTitle")}
                </Link>
              </li>

              <li className="mb-4">
              <Link to="/manage-snippet" className={activeIndex==8 && "active"}>
              {t("administration.manageSnippetTitle")}
                </Link>
              </li>

              <li className="mb-4">
              <Link to="/manage-default-snippet" className={activeIndex==9 && "active"}>
              {t("administration.manageDefaultSnippetTitle")}
                </Link>
              </li>

              <li className="mb-4">
              <Link to="/manage-person-datatype" className={activeIndex==10 && "active"}>
              {t("administration.managePersonDatatypeList")}
                </Link>
              </li>

              <li className="mb-4">
              <Link to="/manage-group-type" className={activeIndex==11 && "active"}>
              {t("administration.manageGroupTypeTitle")}
                </Link>
              </li>
              
              <li className="mb-4">
              <Link to="/manage-list-type" className={activeIndex==12 && "active"}>
              {t("administration.manageListTypeTitle")}
                </Link>
              </li>
             
              <li className="mb-4">
              <a href="#" className=""   onClick={(e) => {
                    e.preventDefault();
                    this.handleSystemSetupdialogue();
                  }}>
              {t("administration.setupSystem")}
                </a>
              </li>

              {isSystemSetupOpen && (
                          <SetUpSystemDesktop
                            handleSystemSetupdialogue={() =>
                              this.handleSystemSetupdialogue()
                            }
                          />
                        )}
              </ul>
          </div>
         );
        }}
      </Translation>)
    }
}
export default withTranslation()(AdminSideMenu);
