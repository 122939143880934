import React from "react";
import MediaQuery from "react-responsive";
import UpdateProfileMobile from "./UpdateProfileMobile";
import UpdateProfileDesktop from "./UpdateProfileDesktop";

export default function UpdateProfileResponsive() {
  return (
    <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <UpdateProfileDesktop />
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <UpdateProfileMobile />
      </MediaQuery>
    </React.Fragment>
  );
}
