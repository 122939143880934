import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { withRouter } from "react-router";
import { checkAdminPermission } from "../../../util/utils";
import ManageGroupTypeDesktop from "./ManageGroupTypeDesktop/GroupList";
import ManageGroupTypeMobile from "./ManageGroupTypeMobile/Index";
class ManageGroupTypeResponsive extends Component {
  componentDidMount() {
    if (!checkAdminPermission()) {
      this.props.history.push("/");
    }
  }
  render() {
    return (
      <React.Fragment>
        <MediaQuery query="(min-device-width: 1224px)">
          <ManageGroupTypeDesktop />
        </MediaQuery>
        <MediaQuery query="(max-device-width: 1224px)">
          <ManageGroupTypeMobile />
        </MediaQuery>
      </React.Fragment>
    );
  }
}
export default withRouter(ManageGroupTypeResponsive);
