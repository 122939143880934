/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AddToGroupList from "./AddToGroupList";
import AddToSubGroupList from "./AddToSubGroupList";
import EditPerson from "./EditPerson";
import GroupDetail from "./GroupDetail";
import GroupList from "./GroupList";
import PersonDetail from "./PersonDetail";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeStatus: 0,
      rootGroupId:0
    };
  }
  changeRouteStatus = (value) => {
    this.setState((prevState) => {
      return {
        routeStatus: value,
      };
    });
  };

  changeToGroupList=()=>{
    this.setState({
      routeStatus: 2,
      rootGroupId:0
    })
  }

  changeToAddGroupList=()=>{
    this.setState({
      routeStatus: 3,
      rootGroupId:0
    })
  }
  render() {
    const { routeStatus,rootGroupId } = this.state;
    return (
      <React.Fragment>
        <div className="wrapper position-relative">
          {routeStatus == 0 && (
            <PersonDetail changeRouteStatus={this.changeRouteStatus} />
          )}
          {routeStatus == 1 && (
            <EditPerson changeRouteStatus={this.changeRouteStatus} />
          )}

          {routeStatus == 2 && (
            <GroupList changeRouteStatus={this.changeRouteStatus}
            setGroupListId={(groupId)=>{this.setState({rootGroupId:groupId,routeStatus:4})}} />
          )}

          {routeStatus == 3 && (
            <AddToGroupList changeRouteStatus={this.changeRouteStatus} 
            setGroupListId={(groupId)=>{this.setState({rootGroupId:groupId,routeStatus:5})}}
            />
          )}

         {routeStatus == 4 && (
            <GroupDetail changeRouteStatus={this.changeRouteStatus} rootGroupId={rootGroupId}
            setGroupListId={(groupId)=>{this.setState({rootGroupId:groupId,routeStatus:4})}}
            changeToGroupList={()=>{this.changeToGroupList()}}/>
          )}
         {routeStatus == 5 && (
            <AddToSubGroupList changeRouteStatus={this.changeRouteStatus} rootGroupId={rootGroupId}
            setGroupListId={(groupId)=>{
              this.setState({rootGroupId:groupId})}}
            changeToGroupList={()=>{this.changeToAddGroupList()}}/>
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(Index);
