/* eslint eqeqeq: 0 */

import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import gql from "graphql-tag";
import React, { Component } from "react";
import { ApolloProvider, Mutation } from "react-apollo";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { getlocationdata } from "../../../../constants/apiParams";
import apiCall, { METHOD, uploadLink } from "../../../../constants/baseUrl";
import { getUserID } from "../../../../util/utils";
import Footer from "../../../Footer/FooterMobile";
import LoadingPage from "../../../CommanComponents/LoadingPage";
import CommonLoader from "../../../CommanComponents/CommonLoader";

const apolloCache = new InMemoryCache();
const client_mutation = new ApolloClient({
  cache: apolloCache,
  link: uploadLink,
});

const Addlocation = gql`
  mutation Addlocation($profile: AddlocationInput!) {
    Addlocation(input: $profile) {
      success
      message
    }
  }
`;

class EditLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      icon: undefined,
      icon_file: undefined,
      base_64_icon: undefined,
      reportDialogueStatus: false,
      name: "",
      address: "",
      coordinates: "",
      description: "",
    };
  }

  handleTextChange = (e, type) => {
    if (type == "name") {
      this.setState({
        name: e.target.value,
      });
    } else if (type == "description") {
      this.setState({
        description: e.target.value,
      });
    } else if (type == "coordinates") {
      this.setState({
        coordinates: e.target.value,
      });
    } else if (type == "address") {
      this.setState({
        address: e.target.value,
      });
    }
  };
  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleReportDialogue = () => {
    this.setState((prevState) => {
      return {
        reportDialogueStatus: !prevState.reportDialogueStatus,
      };
    });
  };

  setGender = (event) => {
    this.setState({
      type: event.target.value,
    });
  };

  componentDidMount() {
    apiCall(
      METHOD.QUERY,
      getlocationdata(getUserID(), this.props.item.id),
      (data) => this.getlocationdata(data),
      (isLoading) => {
        this.setState({ isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }
  getlocationdata = (data) => {
    const { name, description, address, coordinates, icon } = data;

    this.setState({
      name,
      description,
      address,
      coordinates,
      base_64_icon: icon,
    });
  };
  getIconUrl = (file, uploadFile) => {
    let images_extensions = ["jpg", "jpeg", "png"];
    let image_extension = file.type.split("/")[1];
    const result = images_extensions.indexOf(image_extension);
    if (result == -1) {
      toast.info(`${image_extension} file is not supported`);
    } else {
      this.setState({
        icon: URL.createObjectURL(file),
        icon_file: uploadFile,
      });
    }
  };
  loader = (<CommonLoader />);
  render() {
    const {
      icon,
      isLoading,
      name,
      address,
      description,
      coordinates,
      base_64_icon,
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <ApolloProvider client={client_mutation}>
              <Mutation mutation={Addlocation}>
                {(Addlocation, { data, error, loading }) => {
                  if (data) {
                    if (data.Addlocation.success == 0) {
                      toast.error(this.props.t(data.Addlocation.message));
                    } else {
                      toast.success(this.props.t(data.Addlocation.message));
                      this.props.handleEditModal(true);
                    }
                  }
                  if (error) {
                    toast.error(
                      i18n.getDataByLanguage("en").translation.GeneralMessages
                        .errorServerisNotResponding &&
                        i18n.getDataByLanguage("en").translation.GeneralMessages
                          .errorServerisNotResponding
                    );
                  }

                  return (

                    <div className="d-block">
                    <div
                      className="modal fade show"
                      id="NewEvent"
                      tabindex="-1"
                      aria-labelledby="NewEventLabel"
                      aria-modal="true"
                      style={{ display: "block" }}
                    >
                      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="modal-header border-0 d-block pt-4 pb-0">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                              <button
                                type="button"
                                onClick={() =>{this.props.handleEditModal(false)}}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                              </button>

                              {loading ? (
                                <div className="btn btn-primary-theme pointer">
                                  {t("AdminAddUser.save")}
                                  <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                    <span className="sr-only">
                                      {t("GeneralMessages.loading")}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="btn btn-primary-theme pointer"
                                  onClick={() => {
                                    const {
                                      name,
                                      description,
                                      coordinates,
                                      address,
                                      icon_file,
                                    } = this.state;
  
                                    if (!name) {
                                      toast.info(
                                        this.props.t(
                                          "ManageLocation.nameTitleValidation"
                                        )
                                      );
                                    } else if (!description) {
                                      toast.info(
                                        this.props.t(
                                          "ManageLocation.descriptionTitleValidation"
                                        )
                                      );
                                    } else {
                                      let obj = {
                                        variables: {
                                          profile: {
                                            user_id: getUserID(),
                                            id: this.props.item.id,
                                            name,
                                            description,
                                            address,
                                            coordinates,
                                            icon: icon_file ? icon_file : null,
                                          },
                                        },
                                      };
                                      Addlocation(obj);
                                    }
                                  }}
                                >
                                  {t("AdminAddUser.save")}
                                </div>
                              )}
                            </div>
                            <h5 className="modal-title">
                            {t("ManageLocation.addLocationTitle")}
                            </h5>
                          </div>
                          <div className="modal-body">

                     
                      <div className="middle-section sab-middle-section1">
                        {isLoading && this.loader}
                        <div className="container my-4 z-index-99">
                          <div className="form-group mb-5">
                            <div className="form-group">
                              <div className="container my-4 00z-index-99">
                                <form>
                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("ManageLocation.nameTitle")}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        this.handleTextChange(e, "name")
                                      }
                                      name="name"
                                      value={name && name}
                                      placeholder={t(
                                        "ManageLocation.nameTitlePlaceHolder"
                                      )}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("ManageLocation.descriptionTitle")}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        this.handleTextChange(e, "description")
                                      }
                                      name="description"
                                      value={description && description}
                                      placeholder={t(
                                        "ManageLocation.descriptionTitlePlaceHolder"
                                      )}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("ManageLocation.addressTitle")}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        this.handleTextChange(e, "address")
                                      }
                                      name="address"
                                      value={address && address}
                                      placeholder={t(
                                        "ManageLocation.addressTitlePlaceHolder"
                                      )}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("ManageLocation.coordinateTitle")}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        this.handleTextChange(e, "coordinates")
                                      }
                                      name="coordinates"
                                      value={coordinates && coordinates}
                                      placeholder={t(
                                        "ManageLocation.coordinateTitlePlaceHolder"
                                      )}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("ManageLocation.iconTitle")}
                                    </label>

                                    <div
                                      className="fileinput text-center fileinput-new d-flex align-items-center"
                                      data-provides="fileinput"
                                    >
                                      <div className="btn-file">
                                        <div
                                          className="thumbnail fileinput-new "
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            background: "#999",
                                          }}
                                        >
                                          {icon ? (
                                            <img src={icon} alt=""  style={{
                                              width: "100px",
                                              height: "100px",
                                            }}/>
                                          ) : base_64_icon ? (
                                            <img
                                              src={`data:image/png;base64,${
                                                base_64_icon && base_64_icon
                                              }`}
                                              style={{
                                                width: "100px",
                                                height: "100px",
                                              }}
                                              alt="icon"
                                            />
                                          ) : (
                                            <img
                                              src="/images/no-image.png"
                                              alt="icon"
                                              style={{
                                                width: "100px",
                                                height: "100px",
                                              }}
                                            />
                                          )}
                                        </div>

                                        <input
                                          type={"file"}
                                          file-model="myFile"
                                          name="image"
                                          accept=""
                                          onChange={(event) => {
                                            const file = event.target.files[0];
                                            this.getIconUrl(
                                              event.currentTarget.files[0],
                                              file
                                            );
                                          }}
                                        />

                                        <div
                                          className="fileinput-preview fileinput-exists thumbnail uploaded-user-image rounded-circle"
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            borderRadius: "100%",
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                 </div>
                 </div>
                        </div>
                      </div>
                      <div className="modal-backdrop fade show"></div>
                    </div>
                  );
                }}
              </Mutation>
            </ApolloProvider>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(EditLocation));
