/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { sendFacebookPost } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
class FacebookChannelSend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }
  postOnFacebook = () => {
    const message_id = this.props.match.params.message_id;
    apiCall(
      METHOD.QUERY,
      sendFacebookPost(getUserID(), message_id),
      (data) => {
        toast.success(this.props.t(data.message));
        this.props.closeSlider();
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };
  render() {
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <a
                href="javascript;:"
                className="float-right mr-3"
                onClick={(e) => {
                  e.preventDefault();
                  this.postOnFacebook();
                }}
              >
                <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                  {t("ChannelDetail.postOnFaceBook")}
                </p>
                <div className="float-left rounded-40 red-bg text-center">
                  <img
                    className="m-0"
                    src="/images/meldung-neu.png"
                    alt="ico"
                  />
                </div>
              </a>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(FacebookChannelSend));
