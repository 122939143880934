/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";

class GroupDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      range: 0,
      child_group_list: [],
      isSubGroupList: false,
      isChild_Group_Loading: true,
    };
  }

  // handleChangeGroup = (group_id, person) => {

  //   console.log("group_id",group_id);
  //   console.log("person",person);
  //   const child_id = this.props.match.params.group_id;

  //   if(child_id!=group_id){
  //     if (person == 0) {
  //       this.props.clearStateGroupDetail({});
  //       this.props.history.push("/group-detail/" + group_id);
  //     } else {
  //       this.props.clearStateGroupPersonDetail({});
  //        this.props.setPersonListHasMoreValue(true)
  //        this.props.setPersonListStartValue(0)
  //        this.props.history.push("/group-personlist/" + group_id);
  //        setTimeout(() => {
  //         this.loadItem(group_id)
  //        }, 100);

  //     }
  //   }
  // };

  handleActiveIndex = (group_id, id) => {
    if (group_id == id) {
      return "active";
    } else {
      return "";
    }
  };

  render() {
    const { name, breadcrumb, listType, isFromPersonList } = this.props;

    // const { isChild_Group_Loading, displayType } = this.state;
    // const { total_group, total_contact } = this.props.groupDetail;

    const pathName = this.props.location.pathname;

    let group_id = 0;
    if (pathName.includes("group-detail")) {
      group_id = this.props.groupDetail.id;
    } else if (pathName.includes("group-personlist")) {
      group_id = this.props.groupDetailPersonList.id;
    }

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <>
              <div className="d-flex t-n-25 ">
                <p className="font-12 m-0">
                  <React.Fragment>
                    <Link
                      to="/group-list"
                      className="text-muted"
                      data-tip={"Start"}
                    >
                      {t("GroupListScreen.start")}
                      <ReactTooltip place="bottom" type="info" effect="float" />
                    </Link>
                    {" > "}
                  </React.Fragment>
                  {breadcrumb &&
                    breadcrumb.map((item, index) => {
                      if (index == 0) {
                        return (
                          <React.Fragment key={index}>
                            <a
                              href="javascript;:"
                              className="text-muted"
                              data-tip={item.name}
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.handleChangeGroup(
                                  item.id,
                                  item.person
                                );
                              }}
                            >
                              <ReactTooltip
                                place="bottom"
                                type="info"
                                effect="float"
                              />
                              {item.name}
                            </a>
                            {" > "}
                          </React.Fragment>
                        );
                      } else if (index != 0 && index != breadcrumb.length - 1) {
                        return (
                          <React.Fragment key={index}>
                            <a
                              href="javascript;:"
                              className="text-muted"
                              data-tip={item.name}
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.handleChangeGroup(
                                  item.id,
                                  item.person
                                );
                              }}
                            >
                              <ReactTooltip
                                place="bottom"
                                type="info"
                                effect="float"
                              />
                              [...]
                            </a>
                            {" > "}
                          </React.Fragment>
                        );
                      } else if (index == breadcrumb.length - 1) {
                        return (
                          <React.Fragment key={index}>
                            {name && name}
                          </React.Fragment>
                        );
                      }
                    })}
                </p>
              </div>
              <div className="section-title text-center ">
                <h1 className="page-title h4">{name && name}</h1>
              </div>
              <div className="d-flex align-items-center justify-content-center ">
                <ul
                  className="list-inline custom-tab big group "
                  style={{ borderColor: "#1e8dcb" }}
                >
                  {!isFromPersonList && (
                    <li
                      style={{ minWidth: "100px" }}
                      className="list-inline-item pointer group "
                      onClick={() => {
                        this.props.handleListType(0);
                      }}
                    >
                      <a
                        href="javascript:;"
                        style={{ borderColor: "#1e8dcb" }}
                        className={listType == 0 && "active"}
                      >
                        {t("GroupListScreen.groupLable")}
                        <span className="font-size-20 d-block">
                          {this.props.groupDetail.total_group || 0}
                        </span>
                      </a>
                    </li>
                  )}

                  {!isFromPersonList && (
                    <li
                      style={{ minWidth: "100px" }}
                      className="list-inline-item pointer group "
                      onClick={() => {
                        if (this.props?.groupDetail?.total_contact != 0) {
                          this.props.handleListType(1);
                        } else {
                          toast.warn(
                            this.props.t("GroupDetailScreen.personNotFound")
                          );
                        }
                      }}
                    >
                      <a
                        href="#"
                        style={{ borderColor: "#1e8dcb" }}
                        className={listType == 1 && "active"}
                      >
                        {t("GroupListScreen.personLable")}
                        <span className="font-size-20 d-block">
                          {" "}
                          {this.props.groupDetail.total_contact || 0}
                        </span>
                      </a>
                    </li>
                  )}

                  {isFromPersonList && (
                    <li
                      style={{ minWidth: "100px" }}
                      className="list-inline-item pointer active group "
                      // onClick={() => {
                      //   if (this.props?.groupDetail?.total_contact != 0) {
                      //     this.props.handleListType(1);
                      //   } else {
                      //     toast.warn(
                      //       this.props.t("GroupDetailScreen.personNotFound")
                      //     );
                      //   }
                      // }}
                    >
                      <a
                        href="#"
                        style={{ borderColor: "#1e8dcb" }}
                        className="active"
                      >
                        {t("GroupListScreen.personLable")}
                        <span className="font-size-20 d-block">
                          {this.props.groupDetailPersonList.person || 0}
                        </span>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.groupListStateToProps,
        stateToProps.groupDetailStateToProps,
        stateToProps.groupDetailPersonListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupListDispatchToProps,
    actions.groupDetailDispatchToProps,
    actions.groupDetailPersonListDispatchToProps,
  ])
)(withRouter(withTranslation()(GroupDetails)));
