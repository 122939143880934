/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  groupallpersonlist,
  GroupListQuery,
  removegroup,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../../util/utils";
import CommonLoader from "../../CommanComponents/CommonLoader";
import LoadingPage from "../../CommanComponents/LoadingPage";
import PersonList from "./PersonList";
import AddPerson from "./AddPerson";
import AddSubGroup from "./AddSubGroup";
import EditNewGroup from "./EditNewGroup";
import GroupDetails from "./GroupDetails";
import SubGroupItem from "./SubGroupItem";


import SearchGroup from "../../Group/GroupListDesktop/SearchGroup";

import SearchPersonResult from "../../Group/GroupListDesktop/SearchPersonResult";
import SearchGroupListBaseOnPerson from "../../Group/GroupListDesktop/SearchGroupListBaseOnPerson";
import SearchPersonBaseOnGroup from "../../Group/GroupListDesktop/SearchPersonBaseOnGroup";

import GroupItemGridView from "./GroupItemGridView";
import SearchPersonBaseonCompany from "../../Group/GroupListDesktop/SearchPersonBaseonCompany";

class GroupDetailItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortType: 1,
      isLoading: false,
      headerMenuStatus: false,
      hasMore: true,
      end: 10,
      group_header_list: [],
      addSubGroup: false,
      addNewPerson: false,
      addGroupPermission: false,
      editGroupPermission: false,
      addPersonPermission: false,
      deleteGroupPermission: false,
      listType: 0,
      personListForGroup: [],
      displayType: 0,
      searchGroupList: [],
      searchDisplayType: 0,
      groupListBaseOnPerson: [],
      personListBaseOnGroupID:[],
      personListBreadCumb:[],
      selectedPersonId:0,
      groupIdBaseOnPersonDetail:0,
      selectedPersonId:0
    };
  }

  handleListType = (listType) => {
    this.setState({
      listType,
    });
  };
  componentDidMount() {
    const editGroupPermission = checkUserPermission("create_group");
    const addGroupPermission = checkUserPermission("edit_group");
    const addPersonPermission = checkUserPermission("create_person");
    const deleteGroupPermission = checkUserPermission("delete_group");
    this.setState({
      addGroupPermission,
      editGroupPermission,
      addPersonPermission,
      deleteGroupPermission,
    });
    this.loadItem();
    this.getPersonList();
  }

  loadItem = () => {
    const rootGroup = this.props.match.params.group_id;
    const { end, isLoading, sortType } = this.state;
    if (!isLoading && this.props.groupDetail.hasMore) {
      this.setState({
        isLoading: true,
      });
      apiCall(
        METHOD.QUERY,
        GroupListQuery(
          getUserID(),
          this.props.groupDetail.start,
          end,
          rootGroup,
          "",
          sortType
        ),
        (data) => {
          this.setState({
            group_header_list: data.group_header_list,
          });
          if (this.props.groupDetail.id) {
            this.props.updateGroupList(data);
          } else {
            this.props.getGroupDetail(data);
          }
          this.handleGroupDetailApiResponse(data);
        },
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  loader = (<CommonLoader key={0} />);

  handleGroupDetailApiResponse = (data) => {
    const { end } = this.state;
    this.setState({
      isLoading: true,
    });

    if (data.is_last == 1) {
      this.props.setHasMoreValue(false);
    }

    this.props.setStartValue(this.props.groupDetail.start + end);
    this.setState((prevState) => {
      return {
        isLoading: false,
      };
    });
  };

  changeSortType = (sortType) => {
    this.setState(
      {
        sortType: sortType,
      },
      () => {
        this.props.clearStateGroupDetail();
      }
    );
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };
  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  handleActiveIndex = (group_id, id) => {
    if (group_id == id) {
      return "active";
    } else {
      return "";
    }
  };

  // handleChangeGroup = (group_id, person) => {
  //   this.props.clearStateGroupDetail({});
  //   this.props.clearStateGroupPersonDetail({});
  //   if (person == 0) {
  //     this.setState({ isSubGroupList: true });
  //     this.props.history.push("/group-detail/" + group_id);
  //   } else {
  //     this.setState({ isSubGroupList: false });
  //     this.props.history.push("/group-personlist/" + group_id);
  //   }
  // };

  handleChangeGroup = (group_id, person) => {
    console.log("group_id", group_id);
    console.log("person", person);
    const child_id = this.props.match.params.group_id;

    if (child_id != group_id) {
      if (person == 0) {
        this.props.clearStateGroupDetail({});
        this.props.setPersonListHasMoreValue(true);
        this.props.setPersonListStartValue(0);
        this.props.history.push("/group-detail/" + group_id);
        setTimeout(() => {
          this.loadItem(group_id);
          this.getPersonList(group_id);
        }, 100);
      } else {
        this.props.clearStateGroupPersonDetail({});
        this.props.setPersonListHasMoreValue(true);
        this.props.setPersonListStartValue(0);
        this.props.history.push("/group-personlist/" + group_id);
        setTimeout(() => {
          //this.loadItem(group_id);
        }, 100);
      }
    }
  };

  handleAddPerson = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          addNewPerson: !prev.addNewPerson,
        };
      },
      () => {
        if (this.state.addNewPerson) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            // const message_id = this.props.match.params.message_id;
            // const event_id = this.props.match.params.event_id;
            // this.channelDetailApi(message_id, event_id);
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  deletegroup = () => {
    const id = this.props.match.params.group_id;
    confirmAlert({
      title: this.props.t("GroupDetailScreen.deleteGroupTitle"),
      message: this.props.t("GroupDetailScreen.deleteGroupMessage"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              removegroup(getUserID(), id),
              (data) => {
                toast.success(this.props.t(data.message));
                this.props.deleteGroup({ id });
                this.props.history.push("/group-list");
              },
              (isLoading) => {
                this.setState({ deleteLoader: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };

  handleAddSubGroup = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          addSubGroup: !prev.addSubGroup,
        };
      },
      () => {
        if (this.state.addSubGroup) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.loadItem();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };
  handleEditGroup = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          editGroup: !prev.editGroup,
        };
      },
      () => {
        if (this.state.editGroup) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.loadItem();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  getPersonList = () => {
    const rootGroup = this.props.match.params.group_id;
    apiCall(
      METHOD.QUERY,
      groupallpersonlist(getUserID(), rootGroup, ""),
      (data) => {
        this.setState({
          personListForGroup: data.person_list,
        });
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  setSearchGroupList = (groupList, personList) => {
    this.setState({
      searchGroupList: groupList,
      searchPersonList: personList,
    });
  };

  
  handlePersonListBaseonCompany = (personListBaseOnGroupID)=>{
    console.log("personListBaseOnGroupIDpersonListBaseOnGroupID",personListBaseOnGroupID);
    this.setState({
      personListBaseOnGroupID,
      searchDisplayType:4
    })
  }

// search methods
setGroupFromPerson=(groupListBaseOnPerson,selectedPersonId)=>{
  console.log("groupListBaseOnPerson",groupListBaseOnPerson);
  console.log("selectedPersonId",selectedPersonId);
  if(groupListBaseOnPerson.length!=0){
    this.setState({
      groupListBaseOnPerson,
      searchDisplayType:2,
      selectedPersonId
    })
  }else{
    toast.info(
      this.props.t("GeneralMessages.noMailFound") );
  }
}

handleGroupWiseResponse=(personListBaseOnGroupID,personResponse,personListBreadCumb)=>{
  this.setState({
    personListBaseOnGroupID,
    personListBreadCumb,
    personResponse,
    searchDisplayType:3
  })
}

handleDisplayType =()=>{
  this.setState({
    searchDisplayType:0
  })
}

setSearchGroupList = (personList) => {
  if (personList.length == 0) {
  }
  this.setState({
    searchPersonList: personList,
    searchDisplayType: personList.length == 0 ? 0 : 1,
  });
};

handleSearchDisplayTypeToInitialStage = () => {
  this.setState({
    searchDisplayType:0,
    search:'',
    value:''

  });
};

handleSearchDisplayType  = (searchDisplayType ) => {
  this.setState({
    searchDisplayType,
    search:'',
    value:''
  });
};

handleGroupbaseOnPerson = (mailList) => {
  if (mailList.length == 0) {
    this.setState({
      groupListBaseOnPerson: [],
      searchPersonList: [],
      // searchDisplayType: 0,
    });
  } else {
    this.setState({
      // searchDisplayType: 0,
      groupListBaseOnPerson: mailList,
    });
  }
};

  render() {
    const {
      sortType,
      end,
      isLoading,
      group_header_list,
      addNewPerson,
      addSubGroup,
      editGroup,
      addGroupPermission,
      editGroupPermission,
      deleteGroupPermission,
      addPersonPermission,
      personListForGroup,
      listType,
      displayType,
      searchGroupList,searchPersonList,
      searchDisplayType,groupListBaseOnPerson,selectedPersonId,personListBreadCumb,personListBaseOnGroupID,
      groupIdBaseOnPersonDetail
    } = this.state;
    const {
      name,
      group_type_icon,
      total_contact,
      breadcrumb,
      total_group,
      group_list,
    } = this.props.groupDetail && this.props.groupDetail;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <GroupDetails
                name={name}
                breadcrumb={breadcrumb}
                handleChangeGroup={(group_id, person) =>
                  this.handleChangeGroup(group_id, person)
                }
                handleListType={this.handleListType}
                listType={listType}
                isFromPersonList={false}
              />
              <div className="section-content">
                <div className="d-flex align-items-center justify-content-center mb-4">
                  <ul className="list-inline custom-tab group">
                    {group_header_list &&
                      group_header_list.map((data, index) => {
                        const { id, name, person } = data;
                        return (
                          <li className="list-inline-item" key={index}
                       
                          >
                            <a
                              href="javascript;:"
                              onClick={(e) => {
                                e.preventDefault();
                                this.handleChangeGroup(id, person);
                              }}
                              className={this.handleActiveIndex(
                                this.props.groupDetail.id,
                                id
                              )}
                            >
                              {name && name}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>

                  <div className="d-flex align-items-center justify-content-end pb-3">
                    <div className="flex-fill">
                    <SearchGroup
                    handleMailbaseOnPerson={(mailList) => {
                      this.handleMailbaseOnPerson(mailList);
                    }}
                    handlePersonListBaseonCompany={(personList)=>this.handlePersonListBaseonCompany(personList)}

                    setGroupFromPerson={(personList,selectedPersonId)=>{this.setGroupFromPerson(personList,selectedPersonId)}}
                    searchArray={this.setSearchGroupList}
                    handleSearchDisplayType={(searchDisplayType) => {
                      this.handleSearchDisplayType(searchDisplayType);
                    }}
                  />
                  
                    </div>
                    {listType == 0 && (
                    <div className="sort">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            displayType: 0,
                          });
                        }}
                        className={displayType == 0 ? "active mr-2" : "mr-2"}
                      >
                        <i className="fa fa-list fa-lg text-muted"></i>
                      </a>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            displayType: 1,
                          });
                        }}
                        className={displayType == 1 ? "active" : ""}
                      >
                        <i className="fa fa-th-large fa-lg text-muted"></i>
                      </a>
                    </div>
                )}
                     
                  </div>

                {searchDisplayType==0 &&<React.Fragment>
                  <div className="my-4 z-index-99">
                  <div className="mb-4">
                    <div className="clearfix sorting-filter">
                      <span className="font-13 text-muted mr-2">
                        {t("GroupListScreen.sorting")}
                      </span>
                      <div
                        className="btn-group btn-group-sm"
                        role="group"
                        aria-label="First group"
                      >
                        <button
                          type="button"
                          onClick={() => this.changeSortType(1)}
                          className={
                            sortType == 1
                              ? "btn btn-dark"
                              : "btn btn-outline-secondary"
                          }
                        >
                          {t("GroupListScreen.a-z")}
                        </button>

                        <button
                          type="button"
                          onClick={() => this.changeSortType(2)}
                          className={
                            sortType == 2
                              ? "btn btn-dark ml-2"
                              : "btn btn-outline-secondary ml-2"
                          }
                        >
                          {t("GroupListScreen.orderOfPriority")}
                        </button>
                        <button
                          type="button"
                          onClick={() => this.changeSortType(3)}
                          className={
                            sortType == 3
                              ? "btn btn-dark ml-2"
                              : "btn btn-outline-secondary ml-2"
                          }
                        >
                          {t("GroupListScreen.crisisContacts")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  {isLoading && this.loader}
                  <div className="card-header-custom">
                    <div className="media">
                      <div
                        className="bg-blue left-icon-box d-flex align-items-center justify-content-center text-center"
                        style={{ backgroundColor: "#b31218" }}
                      >
                        <div className="circle big m-0">
                          <img
                            src={`data:image/png;base64,${
                              group_type_icon && group_type_icon
                            }`}
                            alt="ico"
                            style={{ width: "24px", height: "24px" }}
                          />
                        </div>
                      </div>
                      <div className="media-body pt-3 px-4">
                        <div className="form-row">
                          <div className="col-md-6">
                            <h5 className="font-size-18 mb-1">
                              {name && name}
                            </h5>
                            <div className="d-flex align-items-center font-size-14 mt-2">
                              <div className="icon mr-3">
                                {total_contact && total_contact}{" "}
                                {total_contact && total_contact == 1
                                  ? t("GroupListScreen.singularPerson")
                                  : t("GroupListScreen.pluralPerson")}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="font-size-14 action-icon text-right">
                              {this.props.groupDetail.group_list.length == 0 &&
                                addPersonPermission && (
                                  <a
                                    href="javascript;:"
                                    className="mr-4"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.handleAddPerson(false);
                                    }}
                                  >
                                    <i className="zmdi zmdi-plus zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                    {t("GroupDetailScreen.addPerson")}
                                  </a>
                                )}

                              {addGroupPermission && (
                                <a
                                  href="javascript;:"
                                  className="mr-4"
                                  data-toggle="modal"
                                  data-target="#NewMessage"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleAddSubGroup(false);
                                  }}
                                >
                                  <i className="zmdi zmdi-plus zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                  {t("GroupDetailScreen.addsubGroup")}
                                </a>
                              )}

                              {editGroupPermission && (
                                <a
                                  href="javascript;:"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleEditGroup(false);
                                  }}
                                  className="mr-4"
                                >
                                  <i className="zmdi zmdi-edit zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                  {t("GroupDetailScreen.editGroup")}
                                </a>
                              )}

                              {deleteGroupPermission && (
                                <a
                                  href="javascript;:"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.deletegroup();
                                  }}
                                  className="mr-4"
                                >
                                  <i className="zmdi zmdi-delete zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                  {t("GroupDetailScreen.deleteGroupTitle")}
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* {!isLoading &&
                                    report_list &&
                                    report_list.length == 0 && (
                                      <NoRecordFoundTemplate2 title="Report List" />
                                    )}
                          {newEventModalOpen && <EditNewEvent eventId={this.props.match.params.event_id} operation="edit"
                            handleEditeventModal={()=>{this.handleEditeventModal(this.props.match.params.event_id)}}
                            />}
                          {messageModalOpen && <AddNewReport 
                            handleMessageModal={(isFromSave)=>{this.handleMessageModal(isFromSave)}}
                            />} */}

                  {addSubGroup && (
                    <AddSubGroup
                      handleAddSubGroup={(isFromSave) => {
                        this.handleAddSubGroup(isFromSave);
                      }}
                    />
                  )}
                  {addNewPerson && (
                    <AddPerson
                      handleAddPerson={(isFromSave) => {
                        this.handleAddPerson(isFromSave);
                      }}
                    />
                  )}

                  {editGroup && (
                    <EditNewGroup
                      handleEditGroup={(isFromSave) => {
                        this.handleEditGroup(isFromSave);
                      }}
                    />
                  )}

                  {addNewPerson && (
                    <AddPerson
                      handleAddPerson={(isFromSave) => {
                        this.handleAddPerson(isFromSave);
                      }}
                    />
                  )}

                  {addNewPerson && (
                    <AddPerson
                      handleAddPerson={(isFromSave) => {
                        this.handleAddPerson(isFromSave);
                      }}
                    />
                  )}
                  {addNewPerson && (
                    <AddPerson
                      handleAddPerson={(isFromSave) => {
                        this.handleAddPerson(isFromSave);
                      }}
                    />
                  )}

                  <div
                    className={
                      displayType == 0 && listType == 0
                        ? "card-body-custom side-line"
                        : "card-body-custom"
                    }
                  >
                    {listType == 1 ? (
                      <PersonList personListForGroup={personListForGroup} />
                    ) : (
                      <>
                        {!!group_list.length &&
                          searchGroupList.length == 0 &&
                          displayType == 0 &&
                          group_list.map((data, index) => {
                            return (
                              <SubGroupItem
                                data={data}
                                key={index}
                                index={index}
                                isFromSearch={false}
                              />
                            );
                          })}

                        {displayType == 1 && searchGroupList.length == 0 && (
                          <div className="row mt-2 p-2">
                            {!!group_list.length &&
                              group_list.map((data, index) => {
                                return (
                                  <GroupItemGridView
                                    data={data}
                                    key={index}
                                    index={index}
                                    isFromSearch={false}
                                  />
                                );
                              })}
                          </div>
                        )}

                        {displayType == 1 && searchGroupList.length != 0 && (
                          <div className="row mt-2 p-2">
                            {searchGroupList.map((data, index) => {
                              return (
                                <GroupItemGridView
                                  data={data}
                                  key={index}
                                  index={index}
                                  isFromSearch={true}
                                />
                              );
                            })}
                          </div>
                        )}

                        {searchGroupList.length != 0 &&
                          displayType == 0 &&
                          searchGroupList.map((data, index) => {
                            return (
                              <SubGroupItem
                                data={data}
                                key={index}
                                index={index}
                                isFromSearch={true}
                              />
                            );
                          })}
                      </>
                    )}
                  </div>
                </div>
                  </React.Fragment>}
            {searchDisplayType == 1 && (
                 <SearchPersonResult 
                      suggetionList={this.state.searchPersonList}
                      handlePersonListBaseonCompany={(personList)=>this.handlePersonListBaseonCompany(personList)}
                      setGroupFromPerson={(personList,selectedPersonId)=>{this.setGroupFromPerson(personList,selectedPersonId)}}/>
                )}

            {searchDisplayType == 2 && (
                  <div className="container my-2">
                    {groupListBaseOnPerson.map((data, index) => {
                      return <SearchGroupListBaseOnPerson data={data} key={index} 
                      selectedPersonId={selectedPersonId}
                      handleGroupWiseResponse={(personListBaseOnGroupID,personResponse,personListBreadCumb,group_id)=>{this.handleGroupWiseResponse(personListBaseOnGroupID,personResponse,personListBreadCumb,group_id)}}/>;
                    })}
                  </div>
                )}

              {searchDisplayType == 3 && (
                  <div className="container my-2">
                       <nav aria-label="breadcrumb">
                        <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item">
                            {t("mailDetail.home")}
                          </li>
                          <li
                              className="breadcrumb-item pointer"
                              aria-current="page"
                              onClick={()=>{
                                // const mailDetailId = breadcrumb[0]?.id
                                // this.props.history.push("/mail-detail/" + mailDetailId);
                                }}
                            >
                             {personListBreadCumb[0]?.name}
                            </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                             {personListBreadCumb[1]?.name}
                         
                          </li>
                        </ol>
                      </nav>

                    {personListBaseOnGroupID.map((data, index) => {
                      return <SearchPersonBaseOnGroup
                      handleSearchDisplayType={(searchDisplayType) => {
                        this.handleSearchDisplayType(searchDisplayType);
                      }}
                      groupIdBaseOnPersonDetail={groupIdBaseOnPersonDetail}
                      data={data} key={index} 
                      personResponse={this.state.personResponse}/>;
                    })}
                  </div>
                )}

                
{searchDisplayType == 4 && (
                  <div className="container my-2">
                    {personListBaseOnGroupID.map((data, index) => {
                      return (
                        <SearchPersonBaseonCompany
                          handleSearchDisplayType={(searchDisplayType) => {
                            this.handleSearchDisplayType(searchDisplayType);
                          }}
                          data={data}
                          key={index}
                          personResponse={this.state.personResponse}
                          groupIdBaseOnPersonDetail={groupIdBaseOnPersonDetail}
                        />
                      );
                    })}
                  </div>
                )}

            
              </div>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.groupListStateToProps,
        stateToProps.groupDetailStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupListDispatchToProps,
    actions.groupDetailDispatchToProps,
    actions.groupDetailPersonListDispatchToProps,
  ])
)(withRouter(withTranslation()(GroupDetailItem)));
