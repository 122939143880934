/* eslint eqeqeq: 0 */
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { Field, Formik } from "formik";
import gql from "graphql-tag";
import React, { Component } from "react";
import { ApolloProvider, Mutation } from "react-apollo";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { getUserDetails, updateUserDetails } from "../../constants/apiParams";
import apiCall, { METHOD, uploadLink } from "../../constants/baseUrl";
import { getUserID, saveDatatoLocalStorage } from "../../util/utils";
import { ManageProfileSchema } from "../../util/validationSchema";
import ErrorMessageFormik from "../CommanComponents/ErrorMessageFormik";
import FooterDesktop from "../Footer/FooterDesktop";
import HeaderDesktop from "../Header/HeaderDesktop";


const apolloCache = new InMemoryCache();
const client_mutation = new ApolloClient({
  cache: apolloCache,
  link: uploadLink,
});

const Updateuserdetail = gql`
 mutation Updateuserdetail($profile: UpdateuserdetailInput!) 
 {Updateuserdetail(input: $profile) 
  {success message name first_name email  image }
}
`;

 class ManageProfileDesktop extends Component {
   constructor(props) {
     super(props);

     this.state = {
       isLoading: false,
       name:'',
       email:'',
       first_name:'',
       icon: undefined,
      icon_file: undefined,
      base_64_icon: undefined,

     };
   }

   getIconUrl = (file, uploadFile) => {
    let images_extensions = ["jpg", "jpeg", "png"];
    let image_extension = file.type.split("/")[1];
    const result = images_extensions.indexOf(image_extension);
    if (result == -1) {
      toast.info(`${image_extension} file is not supported`);
    } else {
      this.setState({
        icon: URL.createObjectURL(file),
        icon_file: uploadFile
      });
    }
  };

   componentDidMount() {

       apiCall(
         METHOD.QUERY,
         getUserDetails(
           getUserID(),getUserID()
         ),
         (data) => {
           this.getProfileResponse(data);
         },
         (isLoading) => {
         },
      (data) => {
        toast.error(this.props.t(data.message));
      }
       );
   }
   
   getProfileResponse = (data) => {
     const { name, first_name, email,image } = data
     
     this.setState({
       name,
       email,
       first_name,
       base_64_icon: image,
     })
   }
   handleSubmit = (values) => {
     const { name,first_name,email } = values;
     apiCall(
       METHOD.QUERY,
       updateUserDetails(getUserID(), email, name, first_name),
       (data) => {
         toast.success(this.props.t(data.message));
         let { from } = this.props.location.state || {
           from: { pathname: "/" },
         };
         this.props.history.push(from);
       },
       (isLoading) => {
         this.setState({ isLoading });
       },
      (data) => {
        toast.error(this.props.t(data.message));
      }
     );
   };
   render() {
     const { isLoading ,name,email,first_name,icon_file,icon,base_64_icon} = this.state;
     return (
       <Translation ns="translation">
         {(t, { i18n }) => {
           return (
            <ApolloProvider client={client_mutation}>
            <Mutation mutation={Updateuserdetail}>
              {(Updateuserdetail, { data, error, loading }) => {
                if (data) {

                  this.setState({
                    isLoading:false
                  })
                  if (data.Updateuserdetail.success == 0) {
                    toast.error(this.props.t(data.Updateuserdetail.message));
                  } else {
                    toast.success(this.props.t(data.Updateuserdetail.message));
                   saveDatatoLocalStorage("image", data.Updateuserdetail.image);
                  
                  
                    let { from } = this.props.location.state || {
                      from: { pathname: "/" },
                    };
                    this.props.history.push(from);
                  }
                }
                if (error) {
                  toast.error(
                    i18n.getDataByLanguage("en").translation.GeneralMessages
                      .errorServerisNotResponding &&
                    i18n.getDataByLanguage("en").translation.GeneralMessages
                      .errorServerisNotResponding
                  );
                }
                return (
             <div className="wrapper position-relative">
               <Formik
                 enableReinitialize
                 initialValues={{
                   name: name ? name : "",
                   email: email ? email : "",
                   first_name: first_name ? first_name : "",
                 }}
                 validationSchema={ManageProfileSchema(
                   t("manageProfile.first_name_error"),
                   t("manageProfile.name_error"),
                   t("manageProfile.email_error"),
                   t("manageProfile.email_valid_error")
                 )}
                 onSubmit={(values) => {

                  this.setState({
                    isLoading:true
                  })

                  const { name,first_name,email } = values;

                   let obj = {
                    variables: {
                      profile: {
                        user_id: getUserID(),
                        email: email?email:null,
                        name:name?name:null,
                        first_name:first_name?first_name:null,
                        image: icon_file?icon_file:null,
                      },
                    },
                  };
                  Updateuserdetail(obj)


                 }}
               >
                 {({ handleSubmit }) => (
                  
                  <div className="content-wrapper">
                  <HeaderDesktop selectionType={0}/>
                  <div className="content">
                    <div className="section section-padding">
                      <div className="container">
                        <div className="section-container">
                          <div className="section-title">
                              <h1 className="page-title h4">
                              {t("manageProfile.title")} 
                              </h1>
                          </div>
                          <div className="section-content">
                            <div className="card mt-4">
                                <div className="card-body">
                                    <form>
                                      <div className="form-row">
                                        <div className="col-xl-8">
                                          <div className="form-row">
                                            <div className="col-lg-6">
                                              <div className="form-group">
                                                <label className="font-14">
                                                  {t("manageProfile.first_name")}
                                                </label>
                                                <Field
                                                  type="text"
                                                  name="first_name"
                                                  placeholder={t(
                                                    "manageProfile.first_name_placeholder"
                                                  )}
                                                  className="form-control"
                                                  required
                                                />
                                                <ErrorMessageFormik name="first_name" />
                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="form-group">
                                                <label className="font-14">
                                                  {t("manageProfile.name")}
                                                </label>
                                                <Field
                                                  type="text"
                                                  name="name"
                                                  placeholder={t("manageProfile.name")}
                                                  className="form-control"
                                                  required
                                                />
                                                <ErrorMessageFormik name="name" />
                                              </div>
                                            </div>
                                          </div>
                                            <div className="form-group">
                                              <label className="font-14">
                                                {t("manageProfile.email")}
                                              </label>
                                              <Field
                                                type="text"
                                                name="email"
                                                placeholder={t(
                                                  "manageProfile.email_placeholder"
                                                )}
                                                className="form-control"
                                                required
                                              />
                                              <ErrorMessageFormik name="email" />
                                            </div>

                                            <div className="form-group">
                                                      <label className="font-14">
                                                        {t("ManageLocation.iconTitle")}
                                                      </label>


                                                      <div
                                                        className="fileinput text-center fileinput-new d-flex align-items-center"
                                                        data-provides="fileinput"
                                                      >
                                                        <div className="btn-file">
                                                          <div
                                                            className="thumbnail fileinput-new "
                                                            style={{
                                                              width: "100px",
                                                              height: "100px",
                                                              background: "#999"

                                                            }}
                                                          >
                                                          {icon ? (
                                                              <img src={icon} alt="" />
                                                            ) : base_64_icon ? (
                                                              <img
                                                                src={`data:image/png;base64,${
                                                                  base_64_icon && base_64_icon
                                                                }`}
                                                                alt="icon"
                                                              />
                                                            ) : (
                                                              <img
                                                                src="/images/no-image.png"
                                                                alt="icon"
                                                              />
                                                            )}
                                                          </div>

                                                          <input
                                                            type={"file"}
                                                            file-model="myFile"
                                                            name="image"
                                                            accept=""
                                                            onChange={(event) => {
                                                              const file = event.target.files[0];
                                                              this.getIconUrl(event.currentTarget.files[0], file);
                                                            }}
                                                          />


                                                          <div
                                                            className="fileinput-preview fileinput-exists thumbnail uploaded-user-image rounded-circle"
                                                            style={{
                                                              width: "100px",
                                                              height: "100px",
                                                              borderRadius: "100%",
                                                            }}
                                                          ></div>
                                                        </div>
                                                      </div>
                                                  
                                                  
                                                    </div>


                                            <div className="form-group text-right mt-4 mb-2">
                                              {isLoading ? (
                                                <button
                                                  type="button"
                                                  onClick={handleSubmit}
                                                  className="btn btn-success btn-block"
                                                >
                                                  {t("manageProfile.update_profile")}

                                                  <div className="spinner-border spinner-border-sm ml-1 text-white">
                                                    <span className="sr-only">
                                                      {t("GeneralMessages.loading")}
                                                    </span>
                                                  </div>
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  onClick={handleSubmit}
                                                  className="btn btn-primary-theme"
                                                >
                                                  {t("manageProfile.update_profile")}
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                    </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <FooterDesktop selectionType={0}/>
                  </div>
               )}
               </Formik>
             </div>
            );
            }}
          </Mutation>
        </ApolloProvider>
         );
         }}
       </Translation>
     );
   }
 }
export default withTranslation()(withRouter(ManageProfileDesktop));
