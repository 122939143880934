import moment from "moment-timezone";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Translation, withTranslation } from "react-i18next";
import { ArchiveEventQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { checkUserPermission, getUserID } from "../../../util/utils";
import { toast } from "react-toastify";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { connect } from "react-redux";


 class EventItem extends Component {
constructor(props) {
  super(props)

  this.state = {
    editEventPermission:false,
    archiveEventPermission:false,
    newMessagePermission:false
  }
}
componentDidMount() {
  const editEventPermission = checkUserPermission("edit_event");
    const archiveEventPermission = checkUserPermission("archive_event");
    const newMessagePermission = checkUserPermission("create_report");
   
    this.setState({
      editEventPermission,
      archiveEventPermission,
      newMessagePermission
    })
   
}
  eventItemClick = (id) => {
    const { pathname } = this.props.location;
    const location = {
      pathname: "/event/" + id,
      state: { from: { pathname: pathname } },
    };
    this.props.history.push(location);
  };

   archiveEvent = (event_id) => {
    apiCall(
      METHOD.QUERY,
      ArchiveEventQuery(getUserID(), event_id),
      (data) => {
        this.props.handleArchiveEvent()
        let count = this.props.eventList.total_event;
        count = count && count - 1;
        this.props.setEventCount(count);
        toast.success(this.props.t(data.message));
        this.props.deleteEvent({ id: event_id });
      },
      (isLoading) => {},
      (data) => {
        console.log(data);
      }
    );
  };

  render() {
    const { data,index } = this.props;
    const {
      id,
      event_type_icon,
      name,
      event_creator_name,
      event_datetime,
      location,
    } = data;
const {editEventPermission,
  archiveEventPermission,
  newMessagePermission } = this.state
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
      <div className="card pointer" key={index}>
            <div className="card-header-custom">
              <div className="media">
              <div className="bg-grey left-icon-box d-flex align-items-center justify-content-center text-center">
                <div
                              className="bg-red left-icon-box d-flex align-items-center justify-content-center text-center"
                              style={{ background: "#b31218" }}
                            >
                            <div className="circle big m-0">
                    <img
                      src={`data:image/png;base64,${
                        event_type_icon && event_type_icon
                      }`}
                      alt="ico"
                      style={{ width: "32px" }}
                    />
                  </div>
                </div>
                </div>
          
             
                <div className="media-body pt-3 px-4">
                  <div className="form-row">
                    <div
                      className="col-md-8"
                      onClick={() => this.eventItemClick(id)}
                    >
                      <h5 className="font-size-18 mb-1">{name && name}</h5>
                      <small>{event_creator_name && event_creator_name}</small>
                      <div className="d-flex align-items-center font-size-14 mt-2">
                        <div className="icon mr-3">
                          <i className="zmdi zmdi-time mr-1"></i>{" "}
                          {event_datetime &&
                            moment
                              .unix(event_datetime)
                              .tz(moment.tz.guess())
                              .format("HH:mm")}
                        </div>
                        <div className="icon mr-3">
                          <i className="zmdi zmdi-calendar mr-1"></i>{" "}
                          {event_datetime &&
                            moment
                              .unix(event_datetime)
                              .tz(moment.tz.guess())
                              .format("DD.MM.YYYY")}
                        </div>
                        <div className="icon mr-3">
                          <i className="zmdi zmdi-pin-drop mr-1"></i>{" "}
                          {location && location}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="font-size-14 action-icon text-right">
                      {editEventPermission && !this.props.isFromArchiveEvent && (<a
                          href="javascript;:"
                          className="mr-4"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.handleEditeventModal(id);
                          }}
                        >
                          <i className="zmdi zmdi-edit zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                          {t("HomeScreen.toEdit")}
                        </a>
              )}
                        
              {newMessagePermission && !this.props.isFromArchiveEvent &&( <a
                          href="javascript;:"
                          className="mr-4"
                          data-toggle="modal"
                          data-target="#NewMessage"
                          onClick={(e) => {
                            e.preventDefault()
                            this.props.handleMessageModal(data);
                          }}
                        >
                          <i className="zmdi zmdi-comment zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                          {t("HomeScreen.newMessage")}
                        </a>
                       )}
                       
                       {this.props.eventType && this.props.eventType==1 && archiveEventPermission && (<a
                          href="javascript;:"
                          onClick={(e) => {
                            e.preventDefault();
                            this.archiveEvent(id);
                          }}
                          className="mr-4"
                        >
                          <i className="zmdi zmdi-archive zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                          {t("HomeScreen.archiveEvent")}
                        </a>)}
                       
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.eventListStateToProps,
        stateToProps.reporttListStateToProps,
        stateToProps.eventEventCountStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.deleteEventReportDispatchToProps,
    actions.eventListDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(EventItem)));
