/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ChannelList from "./ChannelList";

class Index extends Component {
  render() {
    return (
      <div className="wrapper position-relative">
        <ChannelList />
      </div>
    );
  }
}
export default withRouter(Index);
