import React, { Component } from "react";
import Bounce from "react-reveal/Bounce";
import BrandType from "./BrandType";

export default class BrandTypeList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      status,
      closeBrandDialogue,
      handleBrandSelection,
      BrandList
    } = this.props;
    return (
      <div className="modal-custom-footer" style={{display:'block'}}>
      <div className="modal-inner-content">
          <div className="card-header d-flex justify-content-between pb-0">
              <a href="javascript;:" onClick={(e)=>{
                e.preventDefault()
                closeBrandDialogue()
              }}><i className="zmdi zmdi-close zmdi-hc-2x"></i></a>
             
          </div>
     
          <div className="card-body pt-3">
            <ul className="navbar-nav mr-auto">
              {
                BrandList &&
                BrandList.map((item, index) => {
                  
                  return (
                    <BrandType
                      handleBrandSelection={handleBrandSelection}
                      key={index}
                      item={item}
                    />
                  );
                })}
            </ul>
          </div>
            </div>
                </div>
    );
  }
}
