/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getreporteventsnippetdata,
  updatereporteventsnippet,
} from "../../../../constants/apiParams";
import apiCall, { METHOD } from "../../../../constants/baseUrl";
import { getUserID } from "../../../../util/utils";
import Footer from "../../../Footer/FooterMobile";
import LoadingPage from "../../../CommanComponents/LoadingPage";
import SnippetAsyncSelector from "./SnippetAsyncSelector";

class EditDefaultSnippet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      event_type_name: "",
      event_type_id: "",
      report_type_name: "",
      report_type_id: "",
      snippet_list: [],
      snippet_id: 0,
      data: {},
      editSnippetLoader: false,
    };
  }

  handleTextChange = (e, type) => {
    if (type == "name") {
      this.setState({
        name: e.target.value,
      });
    } else if (type == "description") {
      this.setState({
        description: e.target.value,
      });
    } else {
      this.setState({
        identifier: e.target.value,
      });
    }
  };
  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  getSnippetID = (value) => {
    this.setState({
      snippet_id: value.value,
    });
  };

  componentDidMount() {
    apiCall(
      METHOD.QUERY,
      getreporteventsnippetdata(getUserID(), this.props.item.id),
      (data) => this.getEventTypeDetail(data),
      (isLoading) => {
        this.setState({ isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  getEventTypeDetail = (data) => {
    this.setState({
      data,
      snippet_id: "93",
      snippet_name: "asdas",
      name: data.name,
    });
  };

  submitData = () => {
    const { snippet_id, name } = this.state;
    if (snippet_id == 0) {
      toast.info(this.props.t("manageDefaultSnippet.snippetValidation"));
    } else if (!name) {
      toast.info(this.props.t("manageDefaultSnippet.nameValidation"));
    } else {
      apiCall(
        METHOD.QUERY,
        updatereporteventsnippet(
          getUserID(),
          this.props.item.id,
          snippet_id,
          name
        ),
        (data) => {
          toast.success(this.props.t(data.message));
          this.props.changeRouteStatus(0, {});
        },
        (isLoading) => {
          this.setState({ isLoading });
        },
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };
  render() {
    const { editSnippetLoader, isLoading, data, name } = this.state;

    const {
      report_type_icon,
      report_type_name,
      event_type_name,
      event_type_icon,
    } = data && data;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-green sab-header1 sticky-top">
                <div className="container">
                  <div className="py-2 d-flex align-items-center">
                    <span>
                      <i
                        className="zmdi zmdi-close zmdi-hc-fw font-30 text-light pointer"
                        onClick={() => this.props.changeRouteStatus(0, {})}
                      ></i>
                    </span>

                    {editSnippetLoader ? (
                      <div className="btn btn-light font-13 ml-auto pointer">
                        {t("AdminSetteliteSideMenu.btnSave")}
                        <div className="spinner-border spinner-border-sm ml-1 text-blue">
                          <span className="sr-only">
                            {t("GeneralMessages.loading")}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="btn btn-light font-13 ml-auto pointer"
                        onClick={() => {
                          this.submitData();
                        }}
                      >
                        {t("AdminSetteliteSideMenu.btnSave")}
                      </div>
                    )}
                  </div>
                  <div className="text-center mt-4 text-light">
                    <h2 className="m-0 text-light">
                      {t("manageDefaultSnippet.editSnippetDefault")}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="middle-section sab-middle-section1">
                {isLoading && <LoadingPage />}

                <div className="container my-4 z-index-99">
                  <div className="form-group mb-5">
                    <div className="form-group">
                      <label className="font-14">
                        {t("manageDefaultSnippet.nameTitle")}
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => this.handleTextChange(e, "name")}
                        name="name"
                        value={name && name}
                      />
                    </div>

                    <div className="form-group max-170 mr-2">
                      <a
                        href="javascript;:"
                        className="clearfix text-dark"
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleReportDialogue();
                        }}
                      >
                        <label className="font-14">
                          {t("manageDefaultSnippet.evnetTitle")}
                        </label>
                        <div className="icon-drop-bg pointer">
                          <div className="rounded-circle">
                            <img
                              src={`data:image/png;base64,${
                                event_type_icon && event_type_icon
                              }`}
                              alt="ico"
                            />
                          </div>

                          <span className="font-12">
                            {event_type_name && event_type_name}
                          </span>
                        </div>
                      </a>
                    </div>

                    <div className="form-group max-170 mr-2">
                      <a
                        href="javascript;:"
                        className="clearfix text-dark"
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleReportDialogue();
                        }}
                      >
                        <label className="font-14">
                          {t("manageDefaultSnippet.reportTitle")}
                        </label>
                        <div className="icon-drop-bg pointer">
                          <div className="rounded-circle">
                            <img
                              src={`data:image/png;base64,${
                                report_type_icon && report_type_icon
                              }`}
                              alt="ico"
                            />
                          </div>

                          <span className="font-12">
                            {report_type_name && report_type_name}
                          </span>
                        </div>
                      </a>
                    </div>

                    <div className="form-group">
                      <label className="font-14">
                        {t("manageDefaultSnippet.snippetTitle")}
                      </label>

                      <SnippetAsyncSelector
                        getSnippetID={this.getSnippetID}
                        snippet_id={this.props.item.id}
                        snippet_name={this.props.item.snippet_name}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Footer type="2" />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(EditDefaultSnippet));
