import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { SideMenuQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { checkUserPermission } from "../../util/utils";
import SideMenuItem from "./SideMenuItem";

class TopHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuStatus: false,
      side_menu_list: [],
      isLoading: false,
      installed_version: 0,
      systemLogo: "",
      adminPermission:false

    };
  }

  openMenu = () => {
    this.setState(() => {
      return {
        ...this.state,
        menuStatus: true,
      };
    });
  };

  closeMenu = () => {
    this.setState(() => {
      return {
        ...this.state,
        menuStatus: false,
      };
    });
  };

  handleAfterApiCall = (data) => {
    this.props.getSideMenuList(data);

    const systemLogo = localStorage.getItem("system_logo");
    this.setState(() => {
      return {
        ...this.state,
        systemLogo,
        side_menu_list: data.side_menu_list,
        installed_version: data.installed_version,
      };
    });
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        ...this.state,
        isLoading: status,
      };
    });
  };

  componentDidMount() {
    // if (!this.props.sideMenu.side_menu_list) {
      const adminPermission = checkUserPermission("administration");
    this.setState({
      adminPermission
    },()=>{
      apiCall(
        METHOD.QUERY,
        SideMenuQuery(),
        (data) => this.handleAfterApiCall(data),
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    });
  }

  render() {
    const { menuStatus, systemLogo,adminPermission } = this.state;
    const { side_menu_list, installed_version } =
      this.props.sideMenu && this.props.sideMenu;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <nav className="navbar navbar-dark ">
                <button
                  className="navbar-toggler collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarsExample01"
                  aria-controls="navbarsExample01"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={this.openMenu}
                >
                  <span className="navbar-toggler-icon "></span>
                </button>
                <Fade left when={menuStatus}>
                  <div
                    className={
                      menuStatus
                        ? "navbar-collapse show"
                        : "navbar-collapse collapse"
                    }
                    id="navbarsExample01"
                  >
                    <button
                      className="navbar-toggler icon-close collapsed ml-3 mt-3"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarsExample01"
                      aria-controls="navbarsExample01"
                      aria-expanded="false"
                      onClick={this.closeMenu}
                      aria-label="Toggle navigation"
                    >
                      <i className="zmdi zmdi-close zmdi-hc-fw font-30"></i>
                    </button>
                    <ul className="navbar-nav mr-auto">
                      <li className="text-center mb-2">
                        <Link to="/home">
                          {systemLogo ? (
                            <img
                              src={`data:image/png;base64,${systemLogo}`}
                              alt="loginLogo"
                              style={{ width: "150px", height: "150px" }}
                            />
                          ) : (
                            <img
                              src="/images/login_logo.png"
                              alt="loginLogo"
                              style={{ width: "150px", height: "150px" }}
                            />
                          )}
                        </Link>
                      </li>

                      <li className="text-center mb-2">
                        {t("GeneralMessages.version")}{" "}
                        {installed_version && installed_version}
                      </li>
                      {side_menu_list &&
                        side_menu_list.map((item, index) => {
                          if(item.identifier=='administration'){
                            if(adminPermission){
                              return <SideMenuItem {...item} key={index} />
                            }
                          }else{
                            return <SideMenuItem {...item} key={index} />
                          }
                        })}
                    </ul>
                  </div>
                </Fade>
              </nav>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps([stateToProps.sideMenuStateToProps], state),
  actions.appMapDispatchToProps([actions.sideMenuDispatchToProps])
)(withTranslation()(TopHeader));
