import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import {
  getmailinglistfromperson,
  getallpersonbypersondata,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { toast } from "react-toastify";
import { getUserID } from "../../../util/utils";
class SearchPersonResult extends Component {
  handelMailItemClick = (mail_id_range) => {
    this.props.history.push("/mail-detail/" + mail_id_range);
  };

  getMailPersonList = (personId) => {
    apiCall(
      METHOD.QUERY,
      getmailinglistfromperson(getUserID(), personId),
      (data) => this.handleGetMailPersonResponse(data, personId),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  getPersonFromCompany = (personDataType) => {
    apiCall(
      METHOD.QUERY,
      getallpersonbypersondata(getUserID(), personDataType),
      (data) => this.handlePersonDataTypeValue(data, personDataType),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handlePersonDataTypeValue = (data) => {
    this.props.handlePersonListBaseonCompany(data.person_list);
  };
  handleGetMailPersonResponse = (data, personId) => {
    console.log("data", data.mailing_list);
    this.props.setmailFromPerson(data.mailing_list, personId);
    // this.props.handleMailbaseOnPerson(data.mailing_list)
  };

  handleItemClick = (suggestion) => {
    const type = suggestion?.type;
    if (type == "person") {
      this.getMailPersonList(suggestion?.id);
    } else if (type == "data") {
      const is_parent = suggestion?.is_parent;
      const list_id = suggestion?.id;
      const parent_id = suggestion?.parent_id;
      const list_parent_id = list_id + "-" + parent_id;
      if (is_parent == 1) {
        this.props.history.push("/mail-detail/" + list_id);
      } else {
        this.props.history.push("/mail-details-personlist/" + list_parent_id);
      }
    } else if (type == "company") {
      this.getPersonFromCompany(suggestion?.name);
    }
  };
  render() {
    const { suggetionList } = this.props;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="container my-2">
              {suggetionList.map((suggestion, index) => {
                return (
                  <React.Fragment>
                    {suggestion.type == "data" && (
                      <div className="bg-white shadow-sm p-3">
                        {suggestion?.isFirst && suggestion.type == "data" && (
                          <h5 className="mt-1 mb-3 text-muted">
                            <u>{t("GeneralMessages.mailList")}</u>
                          </h5>
                        )}
                        <ul className="list-unstyled autosuggest-list">
                          <a
                            href="javascript;:"
                            className="icon-box-style2 "
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleItemClick(suggestion);
                            }}
                          >
                            <div className="icon-box yellow-bg d-flex">
                              <div className="d-flex align-items-center justify-content-center w-100">
                                <div className="rounded-50 bg-light">
                                  <img
                                    alt="ico"
                                    src={`data:image/png;base64,${
                                      suggestion.type_icon || ""
                                    }`}
                                    style={{ width: "32px" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="ml-100p m-3 clearfix d-flex align-items-center flex-fill">
                              <div className="align-self-center">
                                <h5 className="m-0 font-18 d-block">
                                  {suggestion.name || ""}
                                </h5>
                                <p className="text-muted font-12 m-0">
                                  {suggestion.child_element &&
                                    suggestion.child_element}{" "}
                                  {t("mailDetail.receiver")}
                                </p>
                              </div>
                              <div className="align-self-center ml-auto">
                                <i className="zmdi zmdi-chevron-right zmdi-hc-fw font-26 text-dark"></i>
                              </div>
                            </div>
                          </a>
                        </ul>
                      </div>
                    )}

                    {suggestion.type == "person" && (
                      <div className="bg-white shadow-sm p-3">
                        {suggestion?.isFirst && suggestion.type == "person" && (
                          <h5 className="mt-1 mb-3 text-muted">
                            <u>{t("GeneralMessages.personList")}</u>
                          </h5>
                        )}
                        <ul className="list-unstyled autosuggest-list">
                          <li
                            className="border-bottom pointer"
                            onClick={() => {
                              this.handleItemClick(suggestion);
                            }}
                          >
                            <h5 className="font-15 mb-1">{suggestion.name}</h5>
                            <p className="font-15 text-muted m-0">
                              {suggestion.company && suggestion.company}{" "}
                              {suggestion.job_title && suggestion.job_title}
                            </p>
                            <hr className="dotted" />
                          </li>
                        </ul>
                      </div>
                    )}

                    {suggestion.type == "company" && (
                      <div className="bg-white shadow-sm p-3 pointer">
                        {suggestion?.isFirst && suggestion.type == "company" && (
                          <h5 className="mt-1 mb-3 text-muted">
                            <u>{t("GeneralMessages.companyList")}</u>
                          </h5>
                        )}
                        <ul className="list-unstyled autosuggest-list">
                          <li
                            className=" pb-1 border-bottom pointer"
                            onClick={() => {
                              this.handleItemClick(suggestion);
                            }}
                          >
                            <h5 className="font-15 ">{suggestion.name}</h5>
                            <hr className="dotted" />

                            {/* <p className="font-15 text-muted m-0">{suggestion.company && suggestion.company}  {suggestion.job_title && suggestion.job_title}</p> */}
                          </li>
                        </ul>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(withRouter(SearchPersonResult));
