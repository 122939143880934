/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { MailDetailsQuery, removelist } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../../util/utils";
import LoadingPage from "../../CommanComponents/LoadingPage";
import NoRecordFoundTemplate2 from "../../CommanComponents/NoRecordFoundTemplate2";
import FooterDesktop from "../../Footer/FooterDesktop";
import HeaderDesktop from "../../Header/HeaderDesktop";
import EditMail from "./EditMail";
import AddSubMail from "./AddSubMail";
import MailDetailPersonList from "./MailDetailPersonList";
import CommonLoader from "../../CommanComponents/CommonLoader";

import SearchMail from "../../Mail/MailListDesktop/SearchMail";
import SearchMailListBaseOnPerson from "../../Mail/MailListDesktop/SearchMailListBaseOnPerson";
import SearchPersonBaseOnMail from "../../Mail/MailListDesktop/SearchPersonBaseOnMail";
import SearchPersonResult from "../../Mail/MailListDesktop/SearchPersonResult";
import GeneralPersonList from "../../MailDetailsPersonList/MailDetailPersonListDesktop/GeneralPersonList";
import GeneralGGroupList from "../../MailDetailsPersonList/MailDetailPersonListDesktop/GeneralGGroupList";
import GroupListAddContacts from "../../MailDetailsPersonList/MailDetailPersonListDesktop/GroupListAddContacts";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import EditMailSubGroup from "./EditMailSubGroup";
import SearchPersonBaseonCompany from "../../Mail/MailListMobile/SearchPersonBaseonCompany";

class MailDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      mailList: [],
      activeIndex: 0,
      range: 0,
      headerMenuStatus: false,
      displayType: 0,
      search: "",
      searchMailList: [],
      addMailingListGroupModal: false,
      addMailingListModal: false,
      editMailing: false,
      editMailPermission: false,
      addMailPermission: false,
      mailListPermission: false,
      listType: 0,
      searchDisplayType: 0,
      mailListBaseOnPerson: [],
      personListBaseOnMailID: [],
      personListBreadCumb: [],
      searchPersonList: [],
      selectedPersonId: 0,

      personModal: false,
      addGGroup: false,
      addGroupContactModal: false,
      editGroup: false,
      listIdForAction: 0,
      editSubGroupItem: {},
      personListBaseOnMailID:[]
    };
  }
  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  componentDidMount() {
    const editMailPermission = checkUserPermission("create_group");
    const addMailPermission = checkUserPermission("edit_group");
    const mailListPermission = checkUserPermission("read_list");

    this.setState(
      {
        addMailPermission,
        editMailPermission,
        mailListPermission,
      },
      () => {
        if (this.state.mailListPermission) {
          this.getMailDetail();
        }
      }
    );
  }

  getMailDetail = () => {
    const mail_id = this.props.match.params.mail_id;
    apiCall(
      METHOD.QUERY,
      MailDetailsQuery(getUserID(), mail_id, ""),
      (data) => {
        this.props.getMailDetails(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  handleChangeMail = (mailId) => {
    const mailListPermission = checkUserPermission("read_list");

    this.props.history.push("/mail-detail/" + mailId);
    if (mailListPermission) {
      this.getMailList(mailId);
    }
  };

  getMailList = (mailId) => {
    apiCall(
      METHOD.QUERY,
      MailDetailsQuery(getUserID(), mailId, ""),
      (data) => {
        this.props.getMailDetails(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  loader = (<CommonLoader key={0} />);

  handleBackStack = () => {
    let { from } = this.props.location.state || {
      from: { pathname: "/mail-list" },
    };
    this.props.history.push("/mail-list", { from });
    // window.location.assign("/mail-list");
  };

  handleActiveIndex = (mail_id, id) => {
    if (mail_id == id) {
      this.handleScroll(mail_id);
      return "active";
    } else {
      return "";
    }
  };

  handleScroll = (id) => {
    const index = this.props.mailDetail.mailing_list.findIndex(
      (mail) => mail.id == id
    );

    if (index > 3) {
      setTimeout(function () {
        var element = document.getElementById(`li_${id}`);
        var element1 = document.getElementById("ulID");
        var elementPosition = element.getBoundingClientRect();
        element1.scrollLeft = elementPosition.left;
      }, 500);
    }
  };

  handelMailDetailItemClick = (mail_list_id) => {
    const { pathname } = this.props.location;
    const location = {
      pathname: "/mail-details-personlist/" + mail_list_id,
      state: { from: { pathname: pathname } },
    };
    this.props.history.push(location);
  };

  handleAddMailingListGroupModal = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          addMailingListGroupModal: !prev.addMailingListGroupModal,
        };
      },
      () => {
        if (this.state.addMailingListGroupModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.getMailDetail();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  handleAddMailingListModal = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          addMailingListModal: !prev.addMailingListModal,
        };
      },
      () => {
        if (this.state.addMailingListModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.getMailDetail();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  handleEditMail = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          editMailing: !prev.editMailing,
        };
      },
      () => {
        if (this.state.editMailing) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.getMailDetail();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  deleteList = () => {
    const { listIdForAction } = this.state;
    confirmAlert({
      title: this.props.t("personList.deleteList"),
      message: this.props.t("personList.deleteListMessage"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              removelist(getUserID(), listIdForAction),
              (data) => {
                this.getMailDetail();
              },
              (isLoading) => {
                this.setState({ deleteLoader: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };
  // Search method

  handleDisplayType = () => {
    this.setState({
      searchDisplayType: 0,
    });
  };

  setSearchMailList = (personList) => {
    if (personList.length == 0) {
    }
    this.setState({
      searchPersonList: personList,
      searchDisplayType: personList.length == 0 ? 0 : 1,
    });
  };

  handleSearchDisplayTypeToInitialStage = () => {
    this.setState({
      searchDisplayType: 0,
      search: "",
      value: "",
    });
  };
  handleSearchDisplayType = (searchDisplayType) => {
    this.setState({
      searchDisplayType,
      search: "",
      value: "",
    });
  };

  handleMailbaseOnPerson = (mailList) => {
    if (mailList.length == 0) {
      this.setState({
        mailListBaseOnPerson: [],
        searchPersonList: [],
        // searchDisplayType: 0,
      });
    } else {
      this.setState({
        // searchDisplayType: 0,
        mailListBaseOnPerson: mailList,
      });
    }
  };
  setmailFromPerson = (mailListBaseOnPerson, selectedPersonId) => {
    console.log("mailListBaseOnPerson", mailListBaseOnPerson);
    console.log("selectedPersonId", selectedPersonId);
    if (mailListBaseOnPerson.length != 0) {
      this.setState({
        mailListBaseOnPerson,
        searchDisplayType: 2,
        selectedPersonId,
      });
    } else {
      toast.info(this.props.t("GeneralMessages.noMailFound"));
    }
  };
  handleMailWiseResponse = (
    personListBaseOnMailID,
    personResponse,
    personListBreadCumb
  ) => {
    this.setState({
      personListBaseOnMailID,
      personListBreadCumb,
      personResponse,
      searchDisplayType: 3,
    });
  };

  handleAddModal = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          personModal: !prev.personModal,
        };
      },
      () => {
        if (this.state.personModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.getMailDetail();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  handleEditModal = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          editGroup: !prev.editGroup,
        };
      },
      () => {
        if (this.state.editGroup) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.getMailDetail();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  handleAddGroupContactModal = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          addGroupContactModal: !prev.addGroupContactModal,
        };
      },
      () => {
        if (this.state.addGroupContactModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.getMailDetail();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  handleAddGroupModal = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          addGGroup: !prev.addGGroup,
        };
      },
      () => {
        if (this.state.addGGroup) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.getMailDetail();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  
  handlePersonListBaseonCompany = (personListBaseOnMailID) => {
    console.log("personList------>",personListBaseOnMailID);

    this.setState({
      personListBaseOnMailID,
      searchDisplayType: 4,
    });
  };
  render() {
    const {
      id,
      name,
      list_type_icon,
      child_element,
      child_list,
      mailing_list,
      total_person,
      breadcrumb,
    } = this.props.mailDetail;
    const {
      isLoading,
      displayType,
      searchMailList,
      addMailingListGroupModal,
      addMailingListModal,
      editMailing,
      addMailPermission,
      editMailPermission,
      listType,
      searchDisplayType,
      searchPersonList,
      mailListBaseOnPerson,
      personListBaseOnMailID,
      personListBreadCumb,
      selectedPersonId,
      listIdForAction,
      personModal,
      addGGroup,
      addGroupContactModal,
      editGroup,
      groupIdBaseOnPersonDetail
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="content-wrapper">
              <HeaderDesktop selectionType={4} />
              <div className="content">
                <div className="section section-padding">
                  <div className="container">
                    <div className="section-container">
                      <div className="d-flex t-n-25">
                        <Link to="/mail-list" className="mr-3">
                          <i className="fa fa-arrow-left fa-lg font-size-18"></i>
                        </Link>
                        <nav className="mr-auto" aria-label="breadcrumb">
                          <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                              <Link to="/mail-list">
                                {t("MailDetailScreen.mailList")}
                              </Link>
                            </li>
                            <li
                              className="breadcrumb-item pointer"
                              aria-current="page"
                            >
                              {name}
                            </li>
                          </ol>
                        </nav>

                        <button
                          type="button"
                          className="btn btn-primary-theme list btn-sm mr-2"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleAddMailingListModal();
                          }}
                        >
                          <i className="zmdi zmdi-plus zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                          {t("mailDetail.addMailingListGroup")}
                        </button>
                        {addMailPermission && (
                          <button
                            type="button"
                            className="btn btn-primary-theme list btn-sm mr-2"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleAddMailingListGroupModal();
                            }}
                          >
                            <i className="zmdi zmdi-plus zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                            {t("mailDetail.addMailingList")}
                          </button>
                        )}
                        {editMailPermission && (
                          <button
                            type="button"
                            className="btn btn-primary-theme list btn-sm mr-2"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleEditMail();
                            }}
                          >
                            <i className="zmdi zmdi-edit zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                            {t("mailDetail.editMail")}
                          </button>
                        )}

                        {personModal && (
                          <GeneralPersonList
                            handleAddModal={(isFromSave) => {
                              this.handleAddModal(isFromSave);
                            }}
                            isFromMailDetail={true}
                            listIdForAction={listIdForAction}
                          />
                        )}

                        {addGGroup && (
                          <GeneralGGroupList
                            handleAddGroupModal={(isFromSave) => {
                              this.handleAddGroupModal(isFromSave);
                            }}
                            isFromMailDetail={true}
                            listIdForAction={listIdForAction}
                          />
                        )}

                        {addGroupContactModal && (
                          <GroupListAddContacts
                            handleAddGroupContactModal={(isFromSave) => {
                              this.handleAddGroupContactModal(isFromSave);
                            }}
                            isFromMailDetail={true}
                            listIdForAction={listIdForAction}
                          />
                        )}
                        {editGroup && (
                          <EditMailSubGroup
                            data={this.state.editSubGroupItem}
                            handleEditModal={(isFromSave) => {
                              this.handleEditModal(isFromSave);
                            }}
                          />
                        )}

                        {/* 
{editGroup && (
                                <EditMailSubGroup
                                  data={this.state.data}
                                  handleEditModal={(isFromSave) => {
                                    this.handleEditModal(isFromSave);
                                  }}
                                  isFromMailDetail={true}
                                  listIdForAction={listIdForAction}
                                />
                              )} */}
                      </div>

                      <div className="section-title text-center d-md-flex align-items-start m-0">
                        <div className="flex-fill ">
                          <h1 className="page-title h4"> {name && name}</h1>
                        </div>
                      </div>
                      <div className="section-content">
                        {addMailingListGroupModal && (
                          <AddSubMail
                            item={{
                              id: 1,
                              name: "Group",
                              description: "Group",
                              identifier: "group",
                              icon: "iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACGSURBVHgB7ZQxDoAgDEW/xoPgGVi4/84Z4B4MWkxcNPQzVE0MbyhDm5T+X5ig4L3f0EGMcWrlZgwIN+16dW9x9WN4QDn0Et2THA62ZPFjPT1wsMfV8LjJ3zaoO639MyxPG1iw9BSxW2qoE4QQHAisRp2glJLkjYDUqPmfr+lo8GqDDHtyDTsZwyQcDhELBwAAAABJRU5ErkJggg==",
                              __typename: "Listtypelist",
                            }}
                            handleAddMailingListGroupModal={(isFromSave) => {
                              this.handleAddMailingListGroupModal(isFromSave);
                            }}
                          />
                        )}

                        {addMailingListModal && (
                          <AddSubMail
                            item={{
                              id: 2,
                              name: "Simple List",
                              description: "Simple List",
                              identifier: "list",
                              icon: "iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD3SURBVHgB3ZTLCcMwDIYt51JysjNBjh2hY3ST0Ftupceemo7QTTJCRugEsW/JKalcQiiBWkLQBxEYP9AnGUn8SjEty7KjtdaFhecLl0s4TiHgOI4lHjfT2qVpCl3X1RQLimHh17iZxbN3zlmK1erDxkqA5bku3wDgxkB5Pej7vsaah3JswxUTnrE8pfoHI5tsjMm11gX+eo/XfHpusETNMAwn7/1dnACDFxioigYAOLRtW4kSvBnPpUXHlZoiKjjXZy0mFbQYl7w4iQSN4uYpkgoaxX1P7KSCRnFzD6SC9nMhfDZZKmgcDqSCxuVAKmhcTiu5oLG4B4W9ojGP+hlUAAAAAElFTkSuQmCC",
                              __typename: "Listtypelist",
                            }}
                            handleAddMailingListModal={(isFromSave) => {
                              this.handleAddMailingListModal(isFromSave);
                            }}
                          />
                        )}
                        {editMailing && (
                          <EditMail
                            handleEditMail={(isFromSave) => {
                              this.handleEditMail(isFromSave);
                            }}
                          />
                        )}

                        <div className="d-flex align-items-center justify-content-center my-1">
                          <ul className="list-inline custom-tab big">
                            <li
                              className="list-inline-item pointer"
                              style={{ minWidth: "100px" }}
                              onClick={() => {
                                this.setState({
                                  listType: 0,
                                });
                              }}
                            >
                              <a
                                href="javascript:;"
                                className={listType == 0 ? "active" : ""}
                              >
                                {t("MailDetailScreen.distributor")}
                                <span className="font-size-20 d-block">
                                  {child_element && child_element}
                                </span>
                              </a>
                            </li>
                            <li
                              className="list-inline-item pointer"
                              style={{ minWidth: "100px" }}
                              onClick={() => {
                                this.setState({
                                  listType: 1,
                                });
                              }}
                            >
                              <a
                                href="#"
                                className={listType == 1 ? "active" : ""}
                              >
                                {t("MailDetailScreen.person")}
                                <span className="font-size-20 d-block">
                                  {total_person && total_person}
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>

                        <div className="d-flex align-items-center justify-content-end  mb-2 pb-3">
                          <div className="flex-fill mr-n-25">
                            <div className="w-50 mx-auto">
                              <div className="flex-fill">
                                <SearchMail
                                  handleMailbaseOnPerson={(mailList) => {
                                    this.handleMailbaseOnPerson(mailList);
                                  }}

                                  handlePersonListBaseonCompany={(
                                    personList
                                  ) => {
                                    this.handlePersonListBaseonCompany(
                                      personList
                                    );
                                  }}
                                  
                                  setmailFromPerson={(
                                    personList,
                                    selectedPersonId
                                  ) => {
                                    this.setmailFromPerson(
                                      personList,
                                      selectedPersonId
                                    );
                                  }}
                                  searchArray={this.setSearchMailList}
                                  handleSearchDisplayType={(
                                    searchDisplayType
                                  ) => {
                                    this.handleSearchDisplayType(
                                      searchDisplayType
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="sort">
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  displayType: 0,
                                });
                              }}
                              className={
                                displayType == 0 ? "active mr-2" : "mr-2"
                              }
                            >
                              <i className="fa fa-list fa-lg text-muted"></i>
                            </a>
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  displayType: 1,
                                });
                              }}
                              className={displayType == 1 ? "active" : ""}
                            >
                              <i className="fa fa-th-large fa-lg text-muted"></i>
                            </a>
                          </div>
                        </div>

                        {searchDisplayType == 0 && (
                          <React.Fragment>
                            {listType == 1 && <MailDetailPersonList />}
                            {listType == 0 && (
                              <React.Fragment>
                                {searchMailList.length == 0 &&
                                  child_list &&
                                  displayType == 0 &&
                                  child_list.map((item, index) => {
                                    const { person, name, list_type_icon, id } =
                                      item;
                                    let mail_list_id =
                                      item.id + "-" + this.props.mailDetail.id;

                                    return (
                                      <div className="card pointer" key={index}>
                                        <div className="card-header-custom pointer">
                                          <div className="media">
                                            <div className="bg-grey left-icon-box d-flex align-items-center justify-content-center text-center">
                                              <div
                                                className="bg-yellow left-icon-box d-flex align-items-center justify-content-center text-center"
                                                style={{
                                                  background: "#f2ae00",
                                                }}
                                              >
                                                <div className="circle big m-0">
                                                  <img
                                                    src={`data:image/png;base64,${
                                                      list_type_icon &&
                                                      list_type_icon
                                                    }`}
                                                    alt="ico"
                                                    style={{ width: "24px" }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="media-body pt-3 px-4 pointer">
                                              <div className="form-row">
                                                <div
                                                  className="col-md-4"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handelMailDetailItemClick(
                                                      mail_list_id
                                                    );
                                                  }}
                                                >
                                                  <h5 className="font-size-18 mb-1">
                                                    {name && name}
                                                  </h5>
                                                  <div className="d-flex align-items-center font-size-14 mt-2">
                                                    <div className="icon mr-3">
                                                      {person || "0 "}{" "}
                                                      {t("mailDetail.receiver")}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-md-8">
                                                  <div className="font-size-14 action-icon text-right">
                                                    <a
                                                      href="javascript;:"
                                                      className="mr-4"
                                                      data-toggle="modal"
                                                      data-target="#NewMessage"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState(
                                                          {
                                                            listIdForAction: id,
                                                          },
                                                          () => {
                                                            this.handleAddModal(
                                                              false
                                                            );
                                                          }
                                                        );
                                                        // props.handleMessageModal(data);
                                                      }}
                                                    >
                                                      <i className="zmdi zmdi-plus zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                                      {t(
                                                        "personDetail.addPerson"
                                                      )}
                                                    </a>

                                                    <a
                                                      href="javascript;:"
                                                      className="mr-4"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState(
                                                          {
                                                            listIdForAction: id,
                                                          },
                                                          () => {
                                                            this.handleAddGroupModal(
                                                              false
                                                            );
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      <i className="zmdi zmdi-plus zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                                      {t("personList.addGroup")}
                                                    </a>
                                                    <a
                                                      href="javascript;:"
                                                      className="mr-4"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState(
                                                          {
                                                            listIdForAction: id,
                                                          },
                                                          () => {
                                                            this.deleteList();
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      <i className="zmdi zmdi-delete zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                                      {t(
                                                        "personList.deleteGroup"
                                                      )}
                                                    </a>
                                                    <a
                                                      href="javascript;:"
                                                      className="mr-4"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState(
                                                          {
                                                            editSubGroupItem:
                                                              item,
                                                          },
                                                          () => {
                                                            this.handleEditModal(
                                                              false
                                                            );
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      <i className="zmdi zmdi-edit zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                                      {t(
                                                        "personList.editGroup"
                                                      )}
                                                    </a>

                                                    <a
                                                      href="javascript;:"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState(
                                                          {
                                                            listIdForAction: id,
                                                          },
                                                          () => {
                                                            this.handleAddGroupContactModal(
                                                              false
                                                            );
                                                          }
                                                        );
                                                      }}
                                                      className="mr-4"
                                                    >
                                                      <i className="zmdi zmdi-plus zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                                      {t(
                                                        "personDetail.addGroupContacts"
                                                      )}
                                                    </a>

                                                    {/* <a
                                              href="javascript;:"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.handleEditModal(false);
                                              }}
                                              className="mr-4"
                                            >
                                              <i className="zmdi zmdi-edit zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                              {t("HomeScreen.archiveEvent")}
                                            </a> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}

                                {searchMailList.length == 0 &&
                                  displayType == 1 && (
                                    <div className="row">
                                      {child_list.map((item, index) => {
                                        const {
                                          id,
                                          name,
                                          child_element,
                                          list_type_icon,
                                        } = item;
                                        let mail_list_id =
                                          item.id +
                                          "-" +
                                          this.props.mailDetail.id;

                                        return (
                                          <div
                                            className="col-lg-4 mb-4"
                                            key={index}
                                          >
                                            <a
                                              href="javascript:;"
                                              className="card list-card"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.handelMailDetailItemClick(
                                                  mail_list_id
                                                );
                                              }}
                                            >
                                              <div className="card-body p-0">
                                                <div className="media">
                                                  <div className="bg-yellow left-icon-box-medium d-flex align-items-center justify-content-center text-center">
                                                    <div className="circle big m-0">
                                                      <img
                                                        src={`data:image/png;base64,${
                                                          list_type_icon &&
                                                          list_type_icon
                                                        }`}
                                                        alt="ico"
                                                        style={{
                                                          height: "20px",
                                                          width: "20px",
                                                        }}
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="clearfix p-3">
                                                    <h6 className="mb-1">
                                                      {name}
                                                    </h6>
                                                    <small className="text-muted text-break">
                                                      {child_element &&
                                                        child_element}{" "}
                                                      {t("mailDetail.receiver")}
                                                    </small>
                                                  </div>
                                                </div>
                                              </div>
                                            </a>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}

                                {searchMailList.length != 0 &&
                                  displayType == 0 &&
                                  searchMailList.map((item, index) => {
                                    const { person, name, list_type_icon } =
                                      item;
                                    let mail_list_id =
                                      item.id + "-" + this.props.mailDetail.id;
                                    return (
                                      <div
                                        className="card pointer bg-border yellow"
                                        key={index}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handelMailDetailItemClick(
                                            mail_list_id
                                          );
                                        }}
                                      >
                                        <div className="card-header-custom">
                                          <div className="media">
                                            <div className="media-body pt-4 px-4 pb-4">
                                              <div className="form-row">
                                                <div className="col-md-8">
                                                  <h5 className="mb-1">
                                                    {name && name}
                                                  </h5>
                                                  <small className="text-muted">
                                                    {person || "0 "}{" "}
                                                    {t("mailDetail.receiver")}
                                                  </small>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}

                                {searchMailList.length != 0 &&
                                  displayType == 1 && (
                                    <div className="row">
                                      {searchMailList.map((item, index) => {
                                        const {
                                          id,
                                          name,
                                          child_element,
                                          list_type_icon,
                                        } = item;
                                        let mail_list_id =
                                          item.id +
                                          "-" +
                                          this.props.mailDetail.id;
                                        return (
                                          <div
                                            className="col-lg-4 mb-4"
                                            key={index}
                                          >
                                            <a
                                              href="javascript:;"
                                              className="card list-card"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.props.history.push(
                                                  "/mail-detail/" + id
                                                );
                                              }}
                                            >
                                              <div className="card-body p-0">
                                                <div className="media">
                                                  <div className="bg-yellow left-icon-box-medium d-flex align-items-center justify-content-center text-center">
                                                    <div className="circle big m-0">
                                                      <img
                                                        src={`data:image/png;base64,${
                                                          list_type_icon &&
                                                          list_type_icon
                                                        }`}
                                                        alt="ico"
                                                        style={{
                                                          height: "20px",
                                                          width: "20px",
                                                        }}
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="clearfix p-3">
                                                    <h6 className="mb-1">
                                                      {name}
                                                    </h6>
                                                    <small className="text-muted text-break">
                                                      {child_element &&
                                                        child_element}{" "}
                                                      {t("mailDetail.receiver")}
                                                    </small>
                                                  </div>
                                                </div>
                                              </div>
                                            </a>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )}

                        {searchDisplayType == 1 && (
                          <div className="container my-2">
                            {searchPersonList.map((data, index) => {
                              return (
                                <SearchPersonResult
                                handlePersonListBaseonCompany={(personList) => {
                                  this.handlePersonListBaseonCompany(personList);
                                }}
                                  suggetionList={this.state.searchPersonList}
                                  setmailFromPerson={(
                                    personList,
                                    selectedPersonId
                                  ) => {
                                    this.setmailFromPerson(
                                      personList,
                                      selectedPersonId
                                    );
                                  }}
                                />
                              );
                            })}
                          </div>
                        )}

                        {searchDisplayType == 2 && (
                          <div className="container my-2">
                            {mailListBaseOnPerson.map((data, index) => {
                              return (
                                <SearchMailListBaseOnPerson
                                  data={data}
                                  key={index}
                                  selectedPersonId={selectedPersonId}
                                  handleMailWiseResponse={(
                                    personListBaseOnMailID,
                                    personResponse,
                                    personListBreadCumb
                                  ) => {
                                    this.handleMailWiseResponse(
                                      personListBaseOnMailID,
                                      personResponse,
                                      personListBreadCumb
                                    );
                                  }}
                                />
                              );
                            })}
                          </div>
                        )}

                        {searchDisplayType == 3 && (
                          <div className="container my-2">
                            <nav aria-label="breadcrumb">
                              <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                  {t("mailDetail.home")}
                                </li>
                                <li
                                  className="breadcrumb-item pointer"
                                  aria-current="page"
                                  onClick={() => {
                                    // const mailDetailId = breadcrumb[0]?.id
                                    // this.props.history.push("/mail-detail/" + mailDetailId);
                                  }}
                                >
                                  {personListBreadCumb[0]?.name}
                                </li>
                                <li
                                  className="breadcrumb-item active"
                                  aria-current="page"
                                >
                                  {personListBreadCumb[1]?.name}
                                </li>
                              </ol>
                            </nav>

                            {personListBaseOnMailID.map((data, index) => {
                              return (
                                <SearchPersonBaseOnMail
                                  handleSearchDisplayType={(
                                    searchDisplayType
                                  ) => {
                                    this.handleSearchDisplayType(
                                      searchDisplayType
                                    );
                                  }}
                                  data={data}
                                  key={index}
                                  personResponse={this.state.personResponse}
                                />
                              );
                            })}


                          </div>
                        )}


                        
{searchDisplayType == 4 && (
                          <div className="container my-2">
                            {personListBaseOnMailID.map((data, index) => {
                              return (
                                <SearchPersonBaseonCompany
                                  handleSearchDisplayType={(
                                    searchDisplayType
                                  ) => {
                                    this.handleSearchDisplayType(
                                      searchDisplayType
                                    );
                                  }}
                                  data={data}
                                  key={index}
                                  personResponse={this.state.personResponse}
                                  groupIdBaseOnPersonDetail={
                                    groupIdBaseOnPersonDetail
                                  }
                                />
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FooterDesktop selectionType={4} />
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.mailDetailStateToProps, stateToProps.mailListStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.reportListDispatchToProps,
    actions.mailListDispatchToProps,
    actions.mailDetailsDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(MailDetails)));
