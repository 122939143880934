/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { adminEmailList } from "../../../../constants/apiParams";
import apiCall, { METHOD } from "../../../../constants/baseUrl";
import { getUserID } from "../../../../util/utils";
import CommonLoader from "../../../CommanComponents/CommonLoader";
import FooterDesktop from "../../../Footer/FooterDesktop";
import HeaderDesktop from "../../../Header/HeaderDesktop";
import AdminSideMenu from "../../AdminMenu/AdminSideMenu";
import EditEmail from "./EditEmail";
class EmailList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email_list: [],
      headerMenuStatus: false,
      editModal: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    this.loadUserList();
  }

  loadUserList = () => {
    apiCall(
      METHOD.QUERY,
      adminEmailList(getUserID()),
      (data) => this.handleEmailApiResponse(data),
      (isLoading) => {
        this.setState({ isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleEmailApiResponse = (data) => {
    this.setState((prevState) => {
      return {
        ...this.state,
        email_list: data.systememail_list,
        isLoading: false,
      };
    });
  };

  handleEditEmailModal = (isFromSave, item) => {
    this.setState(
      (prev) => {
        return {
          editModal: !prev.editModal,
          editItemValue: item,
        };
      },
      () => {
        if (this.state.editModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.loadUserList();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  loader = (<CommonLoader key={0} />);
  render() {
    const { email_list, isLoading, editModal } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="content-wrapper">
              <HeaderDesktop selectionType={0} />
              <div className="content">
                <div className="section section-padding">
                  <div className="container">
                    <div className="section-container">
                      <div className="section-content">
                        <div className="row mt-2">
                          {isLoading && <CommonLoader />}
                          <AdminSideMenu activeIndex={1} />
                          <div className="col-lg-9">
                            <div className="section-title text-center d-md-flex align-items-start m-0">
                              <div className="flex-fill mr-n-1300">
                                <Link
                                  to="/administration"
                                  className="text-light"
                                >
                                  <h1 className="page-title h4">
                                    {t("manageAdminEmail.title")}
                                  </h1>
                                </Link>
                              </div>
                            </div>

                            <div className="row mt-4">
                              {email_list &&
                                email_list.map((item, index) => {
                                  const { subject } = item;
                                  return (
                                    <div className="col-lg-4 mt-2">
                                      <a
                                        href="javascript:;"
                                        className="card list-card"
                                      >
                                        <div className="card-body d-flex py-3 px-3">
                                          <div className="circle big m-0 mr-3"></div>
                                          <div className="clearfix mr-2">
                                            <h6 className="mb-1">{subject}</h6>
                                          </div>
                                          <div className="ml-auto">
                                            <ul className="list-inline action-menu-list d-flex m-0">
                                              <li className="list-inline-item mr-3">
                                                <i
                                                  className="zmdi zmdi-edit zmdi-hc-lg pointer"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleEditEmailModal(
                                                      false,
                                                      item
                                                    );
                                                  }}
                                                />
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {editModal && (
                      <EditEmail
                        item={this.state.editItemValue}
                        handleEditEmailModal={(isFromSave, item) => {
                          this.handleEditEmailModal(isFromSave, item);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <FooterDesktop selectionType={0} />
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(EmailList);
