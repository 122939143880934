/* eslint eqeqeq: 0 */
/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { GroupListQuery, movepersongroup } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";
import LoadingPage from "../../CommanComponents/LoadingPage";
import NoRecordFound from "../../CommanComponents/NoRecordFound";
import ReactTooltip from "react-tooltip";
import CommonLoader from "../../CommanComponents/CommonLoader";

class GroupList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      groupList: [],
      end: 10,
      headerMenuStatus: false,
      isLoading: false,
      breadcrumb: [],
      sortType: 1,
      start: 0,
      hasMore: true,
      subGroupID: 0,
    };
    this.searchRef = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadItem();
  }

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  // loader = (<CommonLoader key={0} />);

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  loadItem = () => {
    const { end, loader, subGroupID, start, hasMore } = this.state;
    if (hasMore) {
      apiCall(
        METHOD.QUERY,
        GroupListQuery(getUserID(), start, end, subGroupID, "", 1),
        (data) => {
          this.handleGroupApiResponse(data);
        },
        (isLoading) => {
          this.handleLoader(isLoading);
        },
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  handleGroupApiResponse = (data) => {
    if (data.is_last == 1) {
      this.setState({
        hasMore: false,
      });
    }
    this.setState((prev) => {
      return {
        loader: false,
        start: prev.start + prev.end,
        groupList: [...prev.groupList, ...data.group_list],
        breadcrumb: data.breadcrumb,
      };
    });
  };

  handleGroupSelection = (groupId) => {
    const { id } = this.props.groupPersonDetail && this.props.groupPersonDetail;
    const group_person_id = this.props.match.params.group_person_id;
    const fields = group_person_id.split("-");
    const old_group_id = fields[0];
    apiCall(
      METHOD.QUERY,
      movepersongroup(getUserID(), groupId, id, old_group_id),
      (data) => {
        toast.success(this.props.t(data.message));
        this.props.changeRouteStatus(0);
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  render() {
    const { hasMore, groupList, breadcrumb, subGroupID, isLoading } =
      this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="d-block">
              <div
                className="modal fade show"
                id="NewEvent"
                tabindex="-1"
                aria-labelledby="NewEventLabel"
                aria-modal="true"
                style={{ display: "block" }}
              >
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header border-0 d-block pt-4 pb-0">
                      <div className="d-flex align-items-center justify-content-between mb-4">
                        <button
                          type="button"
                          onClick={() => {
                            this.props.handleChangeToGroupModal(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                        </button>
                        <h5
                          className="modal-title "
                          style={{ alignItems: "center", margin: "auto" }}
                        >
                          {t("personDetail.changeGroup")}
                        </h5>
                      </div>
                      {isLoading && <CommonLoader />}
                      {subGroupID != 0 && (
                        <div className="d-flex justify-content-between align-items-center my-4 ml-2">
                          <p className="font-12 m-0">
                            <React.Fragment>
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState(
                                    {
                                      start: 0,
                                      hasMore: true,
                                      groupList: [],
                                      subGroupID: 0,
                                    },
                                    () => {
                                      this.loadItem();
                                    }
                                  );
                                }}
                                className="text-muted"
                                data-tip={"Start"}
                              >
                                {t("GroupListScreen.start")}
                                <ReactTooltip
                                  place="top"
                                  type="info"
                                  effect="float"
                                />
                              </a>
                              {" > "}
                            </React.Fragment>
                            {breadcrumb &&
                              breadcrumb.map((item, index) => {
                                if (index == 0) {
                                  return (
                                    <React.Fragment key={index}>
                                      <a
                                        href="javascript;:"
                                        className="text-muted"
                                        data-tip={item.name}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.setState(
                                            {
                                              start: 0,
                                              hasMore: true,
                                              groupList: [],
                                              subGroupID: item.id,
                                            },
                                            () => {
                                              this.loadItem();
                                            }
                                          );
                                        }}
                                      >
                                        <ReactTooltip
                                          place="top"
                                          type="info"
                                          effect="float"
                                        />
                                        {item.name}
                                      </a>
                                      {" > "}
                                    </React.Fragment>
                                  );
                                } else if (
                                  index != 0 &&
                                  index != breadcrumb.length - 1
                                ) {
                                  return (
                                    <React.Fragment key={index}>
                                      <a
                                        href="javascript;:"
                                        className="text-muted"
                                        data-tip={item.name}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.setState(
                                            {
                                              start: 0,
                                              hasMore: true,
                                              groupList: [],
                                              subGroupID: item.id,
                                            },
                                            () => {
                                              this.loadItem();
                                            }
                                          );
                                        }}
                                      >
                                        <ReactTooltip
                                          place="top"
                                          type="info"
                                          effect="float"
                                        />
                                        [...]
                                      </a>
                                      {" > "}
                                    </React.Fragment>
                                  );
                                } else if (index == breadcrumb.length - 1) {
                                  return (
                                    <React.Fragment key={index}>
                                      {item.name && item.name}
                                    </React.Fragment>
                                  );
                                }
                              })}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="modal-body">
                      <div className="middle-section sab-middle-section1 ">
                        <div className="container my-4">
                        <div className="row">
                          {groupList &&
                            groupList.map((data, index) => {
                              const {
                                id,
                                name,
                                group_type_icon,
                                person,
                                description,
                                has_child,
                              } = data;
                              return (
                               
                            <div className="col-lg-6 mb-4 pointer" key={this.props.id}>
                            <a
                              href="javascript:;"
                              className="card list-card"
                              onClick={(e) => {
                                e.preventDefault()
                                if (has_child == 0) {
                                   this.handleGroupSelection(id);
                                } else {
                                  this.setState(
                                    {
                                      subGroupID: id,
                                      hasMore: true,
                                      start: 0,
                                      groupList: [],
                                    },
                                    () => {
                                      this.loadItem();
                                    }
                                  );
                                }
                              }}
                            >
                              <div className="card-body p-0">
                                <div className="media">
                                  <div className="bg-blue left-icon-box-medium d-flex align-items-center justify-content-center text-center">
                                    <div className="circle big m-0">
                                      <img
                                        src={`data:image/png;base64,${
                                          group_type_icon && group_type_icon
                                        }`}
                                        alt="ico"
                                        style={{ height: "20px", width: "20px" }}
                                      />
                                    </div>
                                  </div>
                                  <div className="clearfix p-3">
                                    <h6 className="mb-1">{name}</h6>
              
                                    {person == 0 && description != "" ? (
                                      <p className="text-muted font-12 mb-0">
                                        {description && description}
                                      </p>
                                    ) : person == 0 && description == "" ? (
                                      <p className="text-muted font-12 mb-0">
                                        {has_child && has_child}{" "}
                                        {t("GroupListScreen.subGroup")}
                                      </p>
                                    ) : person != 0 ? (
                                      <p className="text-muted font-12 mb-0">
                                        {person && person}{" "}
                                        {person && person == 1
                                          ? t("GroupListScreen.singularPerson")
                                          : t("GroupListScreen.pluralPerson")}
                                      </p>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                       
                            
                            );
                            })}
                          </div>
                          {groupList && groupList.length == 0 && (
                            <NoRecordFound
                              title="Group List"
                              name="group list"
                            />
                          )}

                          {hasMore && (
                            <div className="text-center mt-5">
                              <button
                                type="button"
                                className="btn btn-primary-theme group"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.loadItem();
                                }}
                              >
                                {t("HomeScreen.showMore")}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop fade show"></div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.groupListStateToProps,
        stateToProps.groupPersonDetailStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupListDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(GroupList)));
