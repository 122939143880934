/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { toast } from "react-toastify";
import { EventListQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
import LoadingPage from "../../CommanComponents/LoadingPage";
import EventItem from "./EventItem";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import CommonLoader from "../../CommanComponents/CommonLoader";

 class ArchiveEventList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventList: [],
      start: 0,
      end: 10,
      isLoading: false,
      hasMore: true
    };
  }
  handleLoader = status => {
    this.setState(() => {
      return {
        ...this.state,
        isLoading: status
      };
    });
  };
componentDidMount() {
  this.loadArchiveEvent()
}
  loadArchiveEvent = () => {
    const { isLoading, start, end, hasMore } = this.state;
    
    if (!isLoading && hasMore) {
      this.setState({
        isLoading: true
      });
      apiCall(
        METHOD.QUERY,
        EventListQuery(getUserID(), start, end, "2", ""),
        (data) => this.handleArchiveResponse(data),
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };
  handleArchiveResponse = data => {
    const { start, end } = this.state;
    if (data.is_last === 1) {
      this.setState( { hasMore: false }
      );
    }

    this.setState(prevState => {
      return {
        ...this.state,
        start: start + end,
        eventList: [...prevState.eventList, ...data.event_list],
        isLoading: false
      };
    });
  };

  loader = (<CommonLoader key={0} />);
  render() {
    const { end, hasMore, eventList,isLoading } = this.state;
    const { searchEnd, searchHasMore, searchEvent } = this.props.searchData;
    return (

      <Translation ns="translation">
      {(t, { i18n }) => {
        return (
      <React.Fragment>
         {isLoading && this.loader}
        {searchEvent && searchEvent.length === 0 ? (
        <div>
                  {eventList &&
                    eventList.map((data, index) => {
                      return (
                        <EventItem 
                        eventType={2}
                        isFromArchiveEvent={true}
                        data={data} key={index} index={index} />
                      );
                    })}

                  {hasMore && (
                    <div className="text-center mt-5">
                      <button
                        type="button"
                        className="btn btn-primary-theme event"
                        onClick={(e) => {
                          e.preventDefault();
                          this.loadArchiveEvent();
                        }}
                      >
                        {t("HomeScreen.showMore")}
                      </button>
                    </div> )}
                </div>
       ) : (
        <div className="container my-4">
        {searchEvent &&
          searchEvent.map((data, index) => {
            return <EventItem 
            eventType={2}
            isFromArchiveEvent={true}
            data={data} key={index} />;
          })}

        {searchHasMore && (
          <div className="text-center mt-5">
            <button
              type="button"
              className="btn btn-primary-theme event"
              onClick={(e) => {
                e.preventDefault();
                this.props.searchApi();
              }}
            >
              {t("HomeScreen.showMore")}
            </button>
          </div>
        )}
      </div>
        )}
      </React.Fragment>
       );
      }}
    </Translation>
    );
  }
}
export default withTranslation()(withRouter(ArchiveEventList));
