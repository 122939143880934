import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";

class MailingItemGridView extends Component {
  handelMailItemClick = (mail_id_range) => {
    this.props.history.push("/mail-detail/" + mail_id_range);
  };

  render() {
    const { data, index } = this.props;
    const { id, name, child_element, list_type_icon } = data;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            // <Link
            //   to={"/mail-detail/" + id}
            //   className="icon-box-style2 mb-2"
            //   key={index}
            // >
            //   <div className="icon-box yellow-bg d-flex">
            //     <div className="d-flex align-items-center justify-content-center w-100">
            //       <div className="rounded-50 bg-light">
            //         <img
            //           alt="ico"
            //           src={`data:image/png;base64,${
            //             list_type_icon && list_type_icon
            //           }`}
            //           style={{ width: "32px" }}
            //         />
            //       </div>
            //     </div>
            //   </div>
            //   <div className="ml-100p m-3 clearfix d-flex align-items-center flex-fill">
            //     <div className="align-self-center">
            //       <h5 className="m-0 font-18 d-block">{name && name}</h5>
            //       <p className="text-muted font-12 m-0">
            //         {child_element && child_element} {t("mailDetail.receiver")}
            //       </p>
            //     </div>
            //     <div className="align-self-center ml-auto">
            //       <i className="zmdi zmdi-chevron-right zmdi-hc-fw font-26 text-dark"></i>
            //     </div>
            //   </div>
            // </Link>

            
               <div className="col-6 mb-2 pointer ">
              <Link
              to={"/mail-detail/" + id}
                className="card shadow-sm p-3 px-2 h-100 bg-yellow-bottom justify-content-between"
              >
                <div className="d-flex justify-content-between">
                  <div className="">
                    <h5 className="m-0 font-18 word-break">{name && name}</h5>
                    <p className="text-muted font-12 mb-0">
                      {child_element && child_element}{" "}
                      {t("mailDetail.receiver")}
                    </p>
                  </div>
                </div>
              </Link>
              </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(withRouter(MailingItemGridView));
