/* eslint eqeqeq: 0 */
import { Field, Formik } from "formik";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { UpdateMailQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";
import { CreateMailSchema } from "../../../util/validationSchema";
import ErrorMessageFormik from "../../CommanComponents/ErrorMessageFormik";

class EditMail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: false,
      name: "",
      description: "",
      mailType: "",
      mailImage: "",
      mailId: 0,
      isLoading: false,
    };
  }

  handleAfterCreateApiCall = (data) => {
    toast.info(this.props.t(data.message));
    this.props.handleEditMail(true);
  };

  handleSubmit = (values) => {
    const { name, description } = values;
    const { mailId } = this.state;

    apiCall(
      METHOD.QUERY,
      UpdateMailQuery(
        getUserID(),
        this.props.mailDetail.id,
        mailId,
        name,
        description
      ),
      (data) => this.handleAfterCreateApiCall(data),
      (isLoading) => {
        this.setState({ isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  componentDidMount() {
    const {
      name,
      description,
      list_type_id,
      list_type_icon,
      list_type_name,
    } = this.props.mailDetail;

    this.setState(() => {
      return {
        mailType: list_type_name,
        mailImage: list_type_icon,
        mailId: list_type_id,
        name,
        description,
      };
    });
  }

  render() {
    const { name, description, mailType, mailImage, isLoading } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <Formik
                enableReinitialize
                initialValues={{
                  name: name,
                  description: description,
                }}
                validationSchema={CreateMailSchema(
                  t("MailList.tooShort"),
                  t("MailList.tooLong"),
                  t("MailList.mailNameErrorMessage"),
                  t("MailList.descriptionErrorMessage")
                )}
                onSubmit={(values) => {
                  this.handleSubmit(values);
                }}
              >
                {({ errors, touched, values, handleSubmit, setErrors }) => (
                
                <div className="d-block">
                <div
                  className="modal fade show"
                  id="NewEvent"
                  tabindex="-1"
                  aria-labelledby="NewEventLabel"
                  aria-modal="true"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-header border-0 d-block pt-4 pb-0">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <button
                            type="button"
                            onClick={() => {
                                this.props.handleEditMail(
                                  false
                                );
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                          </button>

                          <h5 className="modal-title" style={{marginLeft:'45px'}}>
                        {t("MailList.editMail")}
                        </h5>

                          {isLoading ? (
                            <div className="btn btn-primary-theme list pointer">
                              {t("AdminAddUser.save")}
                              <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                <span className="sr-only">
                                  {t("GeneralMessages.loading")}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="btn btn-primary-theme list pointer"
                              onClick={handleSubmit}
                            >
                              {t("AdminAddUser.save")}
                            </div>
                          )}
                        </div>
                      
                      </div>
                      <div className="modal-body">
                  <div className="middle-section sab-middle-section1">
                      <div className="container my-4 00z-index-99">
                        <form>
                          {/* <div className="form-group max-170 m-auto text-center">
                            <label className="font-14">
                              {" "}
                              {t("MailList.type")}
                            </label>
                            <div
                              className="icon-drop-bg pointer"
                              onClick={this.openMailDialogue}
                            >
                              <div className="rounded-circle ">
                                <img
                                  src={`data:image/png;base64,${
                                    mailImage && mailImage
                                  }`}
                                  alt="ico"
                                />
                              </div>
                              <span className="font-12">
                                {mailType && mailType}
                              </span>
                            </div>
                          </div> */}

<div
                                    className=" pointer custom-control custom-radio form-group max-170 m-auto custom-control-box text-dark text-center mb-3"
                                  >
                                    <input
                                      type="radio"
                                      name="NeuesEreignis"
                                      className="custom-control-input"
                                      checked={true}
                                    />
                                    <label
                                      className="card-body py-3 px-2 mb-0 text-center custom-control-label"
                                    >
                                      <p className="mb-2">
                                        {mailImage && (
                                          <img
                                            src={`data:image/png;base64,${
                                              mailImage && mailImage
                                            }`}
                                            alt="brand_icon"
                                            style={{width:'32px',height:'32px'}}
                                          />
                                        )}
                                      </p>
                                      <p className="font-size-14 text-nowrap mb-0">
                                      {mailType && mailType}
                                      </p>
                                    </label>
                                  </div>

                          <div className="form-group">
                            <label className="font-14">
                              {t("MailList.titleInternal")}
                            </label>
                            <Field
                              type="text"
                              name="name"
                              placeholder={t("MailList.titleInternal")}
                              className="form-control"
                              required
                            />
                            <ErrorMessageFormik name="name" />
                          </div>

                          <div className="form-group">
                            <label className="font-14">
                              {t("MailList.description")}
                            </label>
                            <Field
                              as="textarea"
                              name="description"
                              placeholder={t("MailList.description")}
                              className="form-control"
                              required
                            />
                            <ErrorMessageFormik name="description" />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                  </div>
                )}
              </Formik>
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.mailDetailStateToProps],
      state
    ),
  actions.appMapDispatchToProps([actions.asyncApiDispatchToProps])
)(withRouter(withTranslation()(EditMail)));
