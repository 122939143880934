/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  deleteDefaultSnippet,
  GetReportTypeQuery,
  reporteventsnippetlist,
} from "../../../../constants/apiParams";
import apiCall, { METHOD } from "../../../../constants/baseUrl";
import { getUserID, removeDuplicates } from "../../../../util/utils";
import Footer from "../../../Footer/FooterMobile";
import LoadingPage from "../../../CommanComponents/LoadingPage";
import LogoutMenu from "../../../CommanComponents/LogoutMenu";
import TopHeader from "../../../CommanComponents/TopHeader";
import FooterDesktop from "../../../Footer/FooterDesktop";
import CommonLoader from "../../../CommanComponents/CommonLoader";
import HeaderDesktop from "../../../Header/HeaderDesktop";
import EditDefaultSnippet from "./EditDefaultSnippet";
import AdminSideMenu from "../../AdminMenu/AdminSideMenu";

class EventReportList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      report_event_snippet_list: [],
      start: 0,
      end: 20,
      hasMore: true,
      deleteLoadingStatus: false,
      headerMenuStatus: false,
      filterReportType: {},
      options: [],
      report_type_list: [],
      report_type_id: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    apiCall(
      METHOD.QUERY,
      GetReportTypeQuery(getUserID(), 1),
      (data) => {
        let options = [];
        data.report_type_list &&
          data.report_type_list.map((d) =>
            options.push({ value: d.id, label: d.name })
          );

        this.setState({
          report_type_list: data.report_type_list,
          options,
        });
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
    this.loadList();
  }

  loadList = () => {
    const { start, end, hasMore, isLoading, report_type_id } = this.state;
    this.setState({
      isLoading: true,
    });

    apiCall(
      METHOD.QUERY,
      reporteventsnippetlist(getUserID(), start, end, report_type_id),
      (data) => this.handleResponse(data),
      (isLoading) => {
        this.handleLoader(isLoading);
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleResponse = (data) => {
    const { start, end } = this.state;
    if (data.is_last == 1) {
      this.setState({ hasMore: false });
    }

    let old_report_event_snippet_list = this.state.report_event_snippet_list;
    old_report_event_snippet_list = old_report_event_snippet_list.concat(
      data.report_event_snippet_list
    );
    old_report_event_snippet_list = removeDuplicates(
      old_report_event_snippet_list,
      "id"
    );

    this.setState((prevState) => {
      return {
        ...this.state,
        start: start + end,
        report_event_snippet_list: old_report_event_snippet_list,
        isLoading: false,
      };
    });
  };

  handleFilterChange = (filterReportType) => {
    if (Object.keys(filterReportType).length == 0) {
      this.setState(
        {
          filterReportType: {},
          report_type_id: 0,
          hasMore: true,
          start: 0,
          report_event_snippet_list: [],
          isLoading: false,
        },
        () => {
          this.loadList();
        }
      );
    } else {
      this.setState(
        {
          filterReportType,
          report_type_id: filterReportType.value,
          hasMore: true,
          start: 0,
          report_event_snippet_list: [],
          isLoading: false,
        },
        () => {
          this.loadList();
        }
      );
    }
  };

  handleDelete = (item) => {
    const { id } = item;

    confirmAlert({
      title: this.props.t(
        "manageDefaultSnippet.confirmTodeleteDefaultSnippetTitle"
      ),
      message: this.props.t(
        "manageDefaultSnippet.confirmTodeleteLocationMessage"
      ),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              deleteDefaultSnippet(getUserID(), id),
              (data) => {
                let afterDeletedMenuList = this.state.report_event_snippet_list.filter(
                  (event_snippet) => {
                    return event_snippet.id != item.id;
                  }
                );
                toast.success(this.props.t(data.message));
                this.setState({
                  report_event_snippet_list: afterDeletedMenuList,
                });
              },
              (isLoading) => {
                this.setState({ deleteLoadingStatus: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };

  clearFilter = () => {
    this.setState(
      {
        filterReportType: {},
        report_type_id: 0,
        hasMore: true,
        start: 0,
        report_event_snippet_list: [],
        isLoading: false,
      },
      () => {
        this.loadList();
      }
    );
  };
  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };
  handleEditModal = (isFromSave, item) => {
    this.setState(
      (prev) => {
        return {
          editModal: !prev.editModal,
          editItemValue: item,
        };
      },
      () => {
        if (this.state.editModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.setState(
              {
                report_event_snippet_list: [],
                hasMore: true,
                start: 0,
                end: 10,
              },
              () => {
                this.loadList();
              }
            );

            this.loadList();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  loader = (<CommonLoader key={0} />);
  render() {
    const {
      hasMore,
      report_event_snippet_list,
      filterReportType,
      options,
      deleteLoadingStatus,
      isLoading,
      editModal,
    } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="content-wrapper">
              <HeaderDesktop selectionType={0} />
              <div className="content">
                <div className="section section-padding">
                  <div className="container">
                    <div className="section-container">
                      <div className="section-content">
                        <div className="row mt-2">
                          {isLoading && <CommonLoader />}
                          <AdminSideMenu activeIndex={9} />
                          <div className="col-lg-9">
                            <div className="section-title text-center d-md-flex align-items-start m-0">
                              <div className="flex-fill mr-n-1300">
                                <Link
                                  to="/administration"
                                  className="text-light"
                                >
                                  <h1 className="page-title h4">
                                    {t("manageDefaultSnippet.title")}
                                  </h1>
                                </Link>
                              </div>
                            </div>

                            <div className="position-relative mt-2">
                              <Select
                                value={filterReportType}
                                onChange={(value) =>
                                  this.handleFilterChange(value)
                                }
                                options={options}
                                className="w-100 z-index-999"
                                clearable={false}
                                placeholder={t("administration.filterSnippet")}
                              />
                              {Object.keys(filterReportType).length != 0 && (
                                <span className="dropdown-close-icon">
                                  <i
                                    className="zmdi zmdi-close zmdi-hc-lg align-middle pointer"
                                    onClick={() => this.clearFilter()}
                                  />
                                </span>
                              )}
                            </div>

                            <div className="row mt-4">
                              {deleteLoadingStatus && this.loader}

                              {report_event_snippet_list &&
                                report_event_snippet_list.map((item, index) => {
                                  const { name, snippet_name } = item;

                                  return (
                                    <div className="col-lg-6 mb-4" key={index}>
                                      <a
                                        href="javascript:;"
                                        className="card list-card"
                                      >
                                        <div className="card-body d-flex py-3 px-3">
                                          <div className="circle big m-0 mr-3"></div>
                                          <div className="clearfix mr-2">
                                            <h6 className="mb-1">
                                              {" "}
                                              {snippet_name || " "}
                                            </h6>
                                            <small className="text-muted text-break">
                                              {name}
                                            </small>
                                          </div>
                                          <div className="ml-auto">
                                            <ul className="list-inline action-menu-list d-flex m-0">
                                              <li className="list-inline-item mr-3">
                                                <i
                                                  className="zmdi zmdi-edit zmdi-hc-lg pointer"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleEditModal(
                                                      false,
                                                      item
                                                    );
                                                  }}
                                                />
                                              </li>
                                              <li className="list-inline-item mr-3 ">
                                                <i
                                                  className="zmdi zmdi-delete zmdi-hc-lg pointer"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleDelete(item);
                                                  }}
                                                />
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  );
                                })}
                            </div>

                            {hasMore && (
                              <div className="text-center mt-2">
                                <button
                                  type="button"
                                  className="btn btn-primary-theme"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.loadList();
                                  }}
                                >
                                  {t("HomeScreen.showMore")}
                                </button>
                              </div>
                            )}
                          </div>
                        </div>{" "}
                      </div>
                    </div>

                    {editModal && (
                      <EditDefaultSnippet
                        item={this.state.editItemValue}
                        handleEditModal={(isFromSave, item) => {
                          this.handleEditModal(isFromSave, item);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <FooterDesktop selectionType={0} />
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(EventReportList);
