import React, { Component } from "react";
import MediaQuery from "react-responsive";
import ManageChannelTypeMobile from "./ManageChannelTypeMobile/Index";
import ManageChannelTypeDesktop from "./ManageChannelTypeDesktop/ChannelType";
import { withRouter } from "react-router";
import { checkAdminPermission } from "../../../util/utils";

class ManageChannelTypeResponsive extends Component {
  componentDidMount() {
    if (!checkAdminPermission()) {
      this.props.history.push("/");
    }
  }
  render() {
    return (
      <React.Fragment>
        <MediaQuery query="(min-device-width: 1224px)">
          <ManageChannelTypeDesktop />
        </MediaQuery>
        <MediaQuery query="(max-device-width: 1224px)">
          <ManageChannelTypeMobile />
        </MediaQuery>
      </React.Fragment>
    );
  }
}
export default withRouter(ManageChannelTypeResponsive);
