import { reArrangePersonList, setEventCount, addNewEvent, addNewGroup,updateChannelMessageLink, updateChannelDetail, addNewMail, addSubMail, asyncApi, clearGroupState, clearStateGroupDetail, clearStateGroupPersonDetail, deleteApiState, deleteEvent, editEvent, editEventReport, getChannelList, getEventList, getGroupDetail, getGroupHasMoreValue, getGroupList, getGroupStartValue, getHasMoreValue, getLoginDetail, getMailDetails, getMailHasMoreValue, getMailList, getMailPersonList, getMailStartValue, getPersonList, getReportList, getStartValue, setHasMoreValue, setPersonListHasMoreValue, setPersonListStartValue, setStartValue, updateChannelList, updateGroupById, updateGroupDetailById, updateGroupDetails, updateGroupList, updatePersonDetail, updatePersonList, updateReportlList, deleteGroup, deleteGroupDetailList, getPersonDetail, addNewPerson, deletePersonList, deletePersonFromPersonList, getSideMenuList, updatePersonCount, deleteMailList } from "../action";
import { getChannelDetail, updateChannelSendingStatus } from "../action/channelDetaiAction";


export const asyncApiDispatchToProps = dispatch => {
  return {
    apiCall: (
      endPoint,
      params,
      successMethod,
      errorMethod,
      method = "post",
      config
    ) => {
      dispatch(
        asyncApi(endPoint, params, successMethod, errorMethod, method, config)
      );
    },
    deleteApiState: () => dispatch(deleteApiState())
  };
};

export const appMapDispatchToProps = data => {
  // debugger
  let tempObj = {};
  return dispatch => {

    for(let i = 0 ; i<data.length; i++){
    let d = data[i]
    const m = d(dispatch);
    Object.keys(m).map(key => (tempObj[key] = m[key]));
    }
    return tempObj;
  };
};

export const loginDetailsDispatchToProps = dispatch => {
  return {
    getLoginDetail: data => dispatch(getLoginDetail(data))
  };
};
export const eventListDispatchToProps = dispatch => {
  return {
    getEventList: data => dispatch(getEventList(data)),
    addNewEvent: data => dispatch(addNewEvent(data)),
    editEvent: data => dispatch(editEvent(data)),
    getStartValue: data => dispatch(getStartValue(data)),
    getHasMoreValue: data => dispatch(getHasMoreValue(data)),
    setEventCount: data => dispatch(setEventCount(data))
  };
};


export const reportListDispatchToProps = dispatch => {
  return {
    getReportList: data => dispatch(getReportList(data)),
    editEventReport: data => dispatch(editEventReport(data)),
    updateReportlList: data => dispatch(updateReportlList(data))
  };
};

export const mailListDispatchToProps = dispatch => {
  return {
    getMailList: data => dispatch(getMailList(data)),
    addNewMail: data => dispatch(addNewMail(data)),
    getMailHasMoreValue: data => dispatch(getMailHasMoreValue(data)),
    getMailStartValue: data => dispatch(getMailStartValue(data))
  };
};


export const groupListDispatchToProps = dispatch => {
  return {
    getGroupList: data => dispatch(getGroupList(data)),
    addnewGroup: data => dispatch(addNewGroup(data)),
    getGroupStartValue: data => dispatch(getGroupStartValue(data)),
    getGroupHasMoreValue: data => dispatch(getGroupHasMoreValue(data)),
    clearGroupState: data => dispatch(clearGroupState(data)),
    updateGroupById: data => dispatch(updateGroupById(data)),
    deleteGroup: data => dispatch(deleteGroup(data))
  };
};

export const groupDetailDispatchToProps = dispatch => {
  return {
    getGroupDetail: data => dispatch(getGroupDetail(data)),
    setStartValue: data => dispatch(setStartValue(data)),
    setHasMoreValue: data => dispatch(setHasMoreValue(data)),
    clearStateGroupDetail: data => dispatch(clearStateGroupDetail(data)),
    updateGroupList: data => dispatch(updateGroupList(data)),
    updateGroupDetails: data => dispatch(updateGroupDetails(data)),
    updateGroupDetailById: data => dispatch(updateGroupDetailById(data)),
    deleteGroupDetailList: data => dispatch(deleteGroupDetailList(data))
  };
};

export const channelListDispatchToProps = dispatch => {
  return {
    getChannelList: data => dispatch(getChannelList(data)),
    updateChannelList: data => dispatch(updateChannelList(data)),
    updateChannelDetail: data => dispatch(updateChannelDetail(data))
  };
};


export const groupDetailPersonListDispatchToProps = dispatch => {
  return {
    getPersonList: data => dispatch(getPersonList(data)),
    setPersonListStartValue: data => dispatch(setPersonListStartValue(data)),
    setPersonListHasMoreValue: data =>
      dispatch(setPersonListHasMoreValue(data)),
    clearStateGroupPersonDetail: data => dispatch(clearStateGroupPersonDetail(data)),
    updatePersonList: data => dispatch(updatePersonList(data)),
    updatePersonDetail: data => dispatch(updatePersonDetail(data)),
    editEventReport: data => dispatch(editEventReport(data)),
    deletePersonList: data => dispatch(deletePersonList(data)),
    addNewPerson: data => dispatch(addNewPerson(data)),
    reArrangePersonList: data => dispatch(reArrangePersonList(data))
  };
};



export const groupPersonDetailDispatchToProps = dispatch => {
  return {
    getPersonDetail: data => dispatch(getPersonDetail(data)),
    deleteMailList: data => dispatch(deleteMailList(data))

  };
};



export const editEventReportDispatchToProps = dispatch => {
  return {
    editEventReport: data => dispatch(editEventReport(data))
  };
};
export const deleteEventReportDispatchToProps = dispatch => {
  return {
    deleteEvent: data => dispatch(deleteEvent(data))
  };
};



export const mailDetailsDispatchToProps = dispatch => {
  return {
    getMailDetails: data => dispatch(getMailDetails(data)),
    addSubMail: data => dispatch(addSubMail(data)),
  };
};

export const mailPersonListDispatchToProps = dispatch => {
  return {
    getMailPersonList: data => dispatch(getMailPersonList(data)),
    deletePersonFromPersonList: data => dispatch(deletePersonFromPersonList(data)),
    updatePersonCount: data => dispatch(updatePersonCount(data)),


  };
};
export const channelDetailsDispatchToProps = dispatch => {
  return {
    getChannelDetail: data => dispatch(getChannelDetail(data)),
    updateChannelSendingStatus: data => dispatch(updateChannelSendingStatus(data)),
    updateChannelMessageLink: data => dispatch(updateChannelMessageLink(data)),
  };
};
export const sideMenuDispatchToProps = dispatch => {
  return {
    getSideMenuList: data => dispatch(getSideMenuList(data))
  };
};
