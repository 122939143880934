/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { checkUserPermission } from "../../../util/utils";
import FooterDesktop from "../../Footer/FooterDesktop";
import HeaderDesktop from "../../Header/HeaderDesktop";
import GroupDetailItem from "./GroupDetailItem";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeStatus: 0,
      groupListPermission:false
    };
  }
  changeRouteStatus = (value) => {
    this.setState((prevState) => {
      return {
        routeStatus: value,
      };
    });
  };

  componentDidMount() {
    const groupListPermission = checkUserPermission("read_group");
    this.setState({
      groupListPermission
    })
    
  }
  render() {
    const { routeStatus ,groupListPermission} = this.state;
    return (
      <Translation ns="translation">
      {(t, { i18n }) => {
        return (
      <div className="content-wrapper">
              <HeaderDesktop selectionType={3}/>
              <div className="content">
                <div className="section section-padding">
                  <div className="container">
                    <div className="section-container">

                    {groupListPermission ?  <GroupDetailItem changeRouteStatus={this.changeRouteStatus} />:(<span>{t("GeneralMessages.permissionError")}</span>)}

                   
                   </div>
                  </div>
                </div>
              </div>
              <FooterDesktop selectionType={3}/>
            </div>
     );
    }}
  </Translation>
    );
  }
}
export default withRouter(withTranslation()(Index));
