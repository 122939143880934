import React from "react";

export default function BrandType(props) {
  const { handleBrandSelection, item } = props;
  const { icon, name } = item;
  
  return (
    <li className="nav-item pointer" onClick={() => handleBrandSelection(item)}>
      <a className="nav-link" href="javascript;:" onClick={e => e.preventDefault()
      }>
        {icon && (
          <img src={`data:image/png;base64,${icon && icon}`} alt="brand_icon" style={{width:'24px',height:'24px'}} />
        )}
       <span className="ml-2"> {name && name}</span>
      </a>
    </li>
  );
}
