/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { getautosuggestperson,getlistsearchhistory, getgrouplistfromperson, getmailinglistfromperson, MaillingListQuery, getallpersonbypersondata } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
import LoadingPage from "../../CommanComponents/LoadingPage";
import Autosuggest from 'react-autosuggest';


  
// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (suggestions,value) => {
  return suggestions;
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion =(suggestion,translationGroupList,translationPersonList,
  translationCompanyList,searchValue,translationPerson,translationPersons)  => (
  <React.Fragment>
     {suggestion.type=='data' && <div className="bg-white shadow-sm p-3">
    {suggestion?.isFirst && suggestion.type=='data' && <h5 className="mt-1 mb-3 text-muted"><u>{translationGroupList}</u></h5>}
    <ul className="list-unstyled autosuggest-list">
    <div
              className="icon-box-style2  pointer"
            >
              <div className="icon-box blue-bg d-flex">
                <div className="d-flex align-items-center justify-content-center w-100">
                  <div className="rounded-50 bg-light">
                    <img
                      src={`data:image/png;base64,${
                        suggestion.type_icon && suggestion.type_icon
                      }`}
                      style={{ width: "32px" }}
                      alt="icon"
                    />
                  </div>
                </div>
              </div>
              <div className="ml-3 my-3 d-inline-block">
                <h5 className="m-0 font-18">{suggestion.name || ''}</h5>
                <p className="text-muted font-12 mb-0">
                {suggestion.child_element}{" "}
                          {suggestion.child_element == 1
                            ? translationPerson
                            : translationPersons}
                        </p>
              </div>
            </div>

      
 </ul>
  </div>}


    {suggestion.type=='person' && <div className="bg-white shadow-sm p-3">
    {suggestion?.isFirst && suggestion.type=='person' && searchValue.length!=0 &&<h5 className="mt-1 mb-3 text-muted"><u>{translationPersonList}</u></h5>}
    <ul className="list-unstyled autosuggest-list">
      <li className="border-bottom">
        <h5 className="font-15 mb-1">{suggestion.name}</h5>
        <p className="font-15 text-muted m-0">{suggestion.company && suggestion.company}  {suggestion.job_title && suggestion.job_title}</p>
      </li>
    </ul>
  </div>}

  {suggestion.type=='company' && <div className="bg-white shadow-sm p-3 ">
    {suggestion?.isFirst && suggestion.type=='company' && <h5 className="mt-1 mb-3 text-muted"><u>{translationCompanyList}</u></h5>}
    <ul className="list-unstyled autosuggest-list">
      <li className=" pb-1 border-bottom">
        <h5 className="font-15 ">{suggestion.name}</h5>
        {/* <p className="font-15 text-muted m-0">{suggestion.company && suggestion.company}  {suggestion.job_title && suggestion.job_title}</p> */}
      </li>
    </ul>
  </div>}

 
  </React.Fragment>
  
);
class SearchGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      mailList: [],
      isLoading: false,
      suggestions: [],
      value: '',
      searchDisplayType: 0,
      mailListBaseOnPerson: [],
      personListBaseOnMailID:[],
      personListBreadCumb:[],
      selectedPersonId:0
    };
    this.searchRef = React.createRef();
  }

  
  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested = ({ value }) => {
    if(value.length!=0){
      apiCall(
        METHOD.QUERY,
        getautosuggestperson(getUserID(),value,0,0),
        (data) => this.handleSearchResponse(data,value,false),
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }else{
      apiCall(
        METHOD.QUERY,
        getlistsearchhistory(getUserID(),0),
        (data) => this.handleSearchResponse(data,'',true),
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  componentDidMount() {
    // apiCall(
    //   METHOD.QUERY,
    //   getlistsearchhistory(getUserID(),0),
    //   (data) => this.handleSearchResponse(data,'a'),
    //   (isLoading) => this.handleLoader(isLoading),
    //   (data) => {
    //     toast.error(this.props.t(data.message));
    //   }
    // );
  }

  handleGetGroupPersonResponse=(data)=>{
    console.log("data",data);
    // this.props.handleMailbaseOnPerson(data.mailing_list)
  }
  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  shouldRenderSuggestions = () => {
    return true
  };

  changeEventSearch = (e,{newValue }) => {
    const searchValue = newValue ;
    this.setState(
      {
        search: searchValue,
        value:searchValue,
        suggestions: []
      },
      () => {
        this.props.searchArray([]);
      }
    );
  };

  handleLoader = status => {
    this.setState(() => {
      return {
        isLoading: status
      };
    });
  };

  
  prepareSuggetionArray=(personList,company_list,data_list)=>{
    let suggetion =[]

    for (let index = 0; index < data_list.length; index++) {
      let data = data_list[index];
      data.type = "data";
      data.isFirst = index == 0 ? true : false;
      suggetion.push(data);
    }
    for (let index = 0; index < personList.length; index++) {
      let person = personList[index];
      person.type = "person";
      person.isFirst = index == 0 ? true : false;
      suggetion.push(person);
    }
    
    for (let index = 0; index < company_list.length; index++) {
      let company = company_list[index];
      company.type = "company";
      company.isFirst = index == 0 ? true : false;
      suggetion.push(company);
    }

    console.log("suggetion",suggetion);
    return suggetion
  }
  handleSearchResponse = (data,value,isFromHistory) => {
    let suggetion =[]
    if(!isFromHistory){
    let personList = data.person_list
   let company_list = data.company_list
   let data_list = data.data_list
   suggetion=  this.prepareSuggetionArray(personList,company_list,data_list)
    }else{
      let personList = data.person_list
      suggetion=  this.prepareSuggetionArray(personList,[],[])
    }
 
    this.setState(
      () => {
        return {
          suggestions: getSuggestions(suggetion,value),
          isLoading: false
        };
      },
      () => {
        // this.props.searchArray(data.mailing_list);
        // if (this.state.mailList.length == 0) {
        //   toast.info(
        //    this.props.t("GeneralMessages.searchErrorMessage") +
        //      "'" +
        //      this.state.search +
        //      "'"
        //  );
        // }
      }
    );
  };
  loader = (<LoadingPage />);
  clearSearch = () => {
    this.searchRef.current.value = "";

    this.setState(
      () => {
        return {
          ...this.state,
          search: "",
          suggestions: [],
          value:'',

        };
      },
      () => {
        this.props.handleSearchDisplayType(0)
        this.props.searchArray([]);
      }
    );
  };



  getGroupPersonResponse=(data,personId)=>{
    console.log("data",data.group_list);
    this.props.setGroupFromPerson(data.group_list,personId)
    // this.props.handleMailbaseOnPerson(data.mailing_list)
  }


  searchApi = () => {
    const { search } = this.state;
    apiCall(
      METHOD.QUERY,
      MaillingListQuery(getUserID(), 0, 100, search),
      (data) => this.handleSearchResponse(data),
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  
  
  getGroupPersonList=(personId)=>{
    if(personId==0){
      // alert(suggetion)
      // this.onClickSearch()
    }else{
      apiCall(
        METHOD.QUERY,
        getgrouplistfromperson(getUserID(),personId),
        (data) => this.getGroupPersonResponse(data,personId),
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  }
  
  onClickSearch = ()=>{
    const {value} = this.state
    apiCall(
      METHOD.QUERY,
      getautosuggestperson(getUserID(),value,0,1),
      (data) => {
        let personList = data.person_list
          let company_list = data.company_list
          let data_list = data.data_list
        if(personList.length==0 && company_list.length==0 && data_list.length==0){
          toast.info(this.props.t("GeneralMessages.searchErrorMessage") +
              "'" + this.state.value +"'");
        }else{
        let  suggetion=  this.prepareSuggetionArray(personList,company_list,data_list)
          this.props.searchArray(suggetion);
        }
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }
  handlePersonDataTypeValue=(data)=>{
    this.props.handlePersonListBaseonCompany(data.person_list)
  }
  getPersonFromCompany=(personDataType)=>{
    apiCall(
      METHOD.QUERY,
      getallpersonbypersondata(getUserID(),personDataType),
      (data) => this.handlePersonDataTypeValue(data),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }
  render() {
    const { search } = this.state;
    const { value, suggestions } = this.state;
    const inputProps = {
      value,
      onChange: this.changeEventSearch
    };
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="clearfix ">
              <div className="input-group align-items-start mt-3">
                {/* <input
                  type="text"
                  placeholder={t("MailList.search")}
                  className="form-control"
                  ref={this.searchRef}
                  onChange={this.changeEventSearch}
                  onKeyPress={e =>
                    e.keyCode == 13 || (e.which == 13 && this.searchApi())
                  }
                /> */}


<Autosuggest
                suggestions={suggestions||[]}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                shouldRenderSuggestions={this.shouldRenderSuggestions}
                className="form-control"
                ref={this.searchRef}
                onSuggestionSelected={(suggetion,suggestionValue )=>{
                  const type = suggestionValue?.suggestion?.type
                  if(type=='person'){
                    this.getGroupPersonList(suggestionValue?.suggestion?.id,suggestionValue?.suggestion?.name)
                  }else if(type=='data'){
                  const person = suggestionValue?.suggestion?.child_element
                  const group_id = suggestionValue?.suggestion?.id
                  if (person == 0) {
                    this.props.history.push("/group-detail/" + group_id);
                  } else {
                    this.props.history.push("/group-personlist/" + group_id);
                  }
                  }else if(type=='company'){
                    this.getPersonFromCompany(suggestionValue?.suggestion?.name)
                  }
                }}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={(suggetions)=>renderSuggestion(suggetions,t("GeneralMessages.groupList")
                ,t("GeneralMessages.personList"),t("GeneralMessages.companyList"),this.state.value,
                t("GroupListScreen.singularPerson")
                , t("GroupListScreen.pluralPerson"))}
                inputProps={inputProps}
                placeholder= {t("MailList.search")}
              />
                <div className="input-group-append">
                  <span className="input-group-text bg-white h-38">
                    <i
                      className="zmdi zmdi-search zmdi-hc-fw font-22 pointer"
                      onClick={()=>{
                       this.onClickSearch()
                      }}
                    ></i>
                    {value.length != 0 && (
                      <i
                        className="zmdi zmdi-close zmdi-hc-fw font-22 pointer"
                        onClick={this.clearSearch}
                      ></i>
                    )}
                  </span>
                </div>
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(SearchGroup));
