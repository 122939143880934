import MediaQuery from "react-responsive";
import LinkReportAndSnippetMobile from "./LinkReportAndSnippetMobile/Index";
import LinkReportAndSnippetDesktop from "./LinkReportAndSnippetDesktop/EventReportList";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { checkAdminPermission } from "../../../util/utils";

class LinkReportAndSnippetResponsive extends Component {
  componentDidMount() {
    if (!checkAdminPermission()) {
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <React.Fragment>
        <MediaQuery query="(min-device-width: 1224px)">
          <LinkReportAndSnippetDesktop />
        </MediaQuery>
        <MediaQuery query="(max-device-width: 1224px)">
          <LinkReportAndSnippetMobile />
        </MediaQuery>
      </React.Fragment>
    );
  }
}
export default withRouter(LinkReportAndSnippetResponsive);
