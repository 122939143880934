import React from 'react'
import { Translation, withTranslation } from "react-i18next";
import * as actions from "../../constants/mapDispatchToProps";
import { connect } from "react-redux";
import * as stateToProps from "../../constants/mapStateToProps";
import { withRouter } from 'react-router';

 function FooterDesktop() {
    return (
        <Translation ns="translation">
        {(t, { i18n }) => {
          return (
        <footer className="footer">
        <div className="footer-copyright">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 text-center text-md-left">
                        <p className="mb-0">{t("FooterScreen.copyWrite")}</p>
                    </div>
                    <div className="col-md-6 text-center text-md-right">
                        <ul className="list-inline m-0">
                            <li className="list-inline-item"><a href="javascript;:">{t("FooterScreen.start")} </a></li>
                            <li className="list-inline-item"><a href="javascript;:">{t("FooterScreen.event")}</a></li>
                            <li className="list-inline-item"><a href="javascript;:">{t("FooterScreen.contactPerson")}</a></li>
                            <li className="list-inline-item"><a href="javascript;:">{t("FooterScreen.distributor")}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer> );
        }}
      </Translation>
    )
}
export default connect(
    (state) =>
      stateToProps.appMapStateToProps(
        [],
        state
      ),
    actions.appMapDispatchToProps([
      actions.groupListDispatchToProps
    ])
  )(withRouter(withTranslation()(FooterDesktop)));