/* eslint eqeqeq: 0 */
import React, { Component, Suspense } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import {
  getmailinglistgroupperson,
  removegroupfromlist,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
import LoadingPage from "../../CommanComponents/LoadingPage";

const PersonItem = React.lazy(() => import("./PersonItem"));

class GroupItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      person_list: [],
      is_Loading: false,
    };
  }

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };
  getPersonList = (group_id) => {
    const list_id = this.props.match.params.mail_id;

    apiCall(
      METHOD.QUERY,
      getmailinglistgroupperson(getUserID(), group_id, list_id),
      (data) => {
        this.setState({
          person_list: data.person_list,
        });
        // this.props.getMailDetails(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  updatePersonList = (person_id) => {
    let temp_person_list = this.state.person_list;

    temp_person_list = temp_person_list.filter((item) => {
      return item.id != person_id;
    });

    this.setState({
      person_list: temp_person_list,
    });
  };

  deleteGroup = (group_id, list_id) => {
    confirmAlert({
      title: this.props.t("personList.deleteGroup"),
      message: this.props.t("personList.deleteGroupMessage"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              removegroupfromlist(list_id, getUserID(), group_id),
              (data) => {
                this.props.delete_group_item(group_id);
              },
              (isLoading) => {
                this.setState({ deleteLoader: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    const { item, index } = this.props;
    const { id, name, is_associated, person, list_id } = item;
    const { person_list } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div
                className="custom-accordian bg-border-accordion"
                id={`groupDiv${index}`}
              >
                <Accordion
                  allowZeroExpanded={true}
                  onChange={(res) => {
                    res.length > 0 && this.getPersonList(list_id);
                  }}
                >
                  <AccordionItem className="bg-border blue">
                    <AccordionItemButton>
                      {name && name}

                      {is_associated == 1 ? (
                        <p className="text-muted font-12 m-0">
                          {person && person}{" "}
                          {person == 1
                            ? t("GroupListScreen.singularPerson")
                            : t("GroupListScreen.pluralPerson")}
                          {" / "}
                          {t("GroupListScreen.associatedLable")}
                        </p>
                      ) : (
                        <p className="text-muted font-12 m-0">
                          {person && person}{" "}
                          {person == 1
                            ? t("GroupListScreen.singularPerson")
                            : t("GroupListScreen.pluralPerson")}
                        </p>
                      )}
                      <div
                        className="accordian-delete-btn pointer"
                        onClick={() => {
                          this.deleteGroup(id, list_id);
                        }}
                      >
                        <i className="zmdi zmdi-delete zmdi-hc-fw font-22"></i>
                      </div>
                    </AccordionItemButton>
                    <Suspense fallback={<LoadingPage />}>
                      <AccordionItemPanel>
                        {person_list &&
                          person_list.map((item, index) => (
                            <PersonItem
                              group_id={id}
                              item={item}
                              index={index}
                              key={index}
                              updatePersonList={this.updatePersonList}
                              updatePersonCount={this.props.updatePersonCount}
                            />
                          ))}
                      </AccordionItemPanel>
                    </Suspense>
                  </AccordionItem>
                </Accordion>
              </div>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(withRouter(GroupItem));
