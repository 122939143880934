/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { toast } from "react-toastify";
import { EventListQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
import LoadingPage from "../../CommanComponents/LoadingPage";
import EventItem from "./EventItem";

export default class ArchiveEventList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventList: [],
      start: 0,
      end: 10,
      isLoading: false,
      hasMore: true
    };
  }
  handleLoader = status => {
    this.setState(() => {
      return {
        ...this.state,
        isLoading: status
      };
    });
  };

  loadArchiveEvent = () => {
    const { isLoading, start, end, hasMore } = this.state;
    
    if (!isLoading && hasMore) {
      this.setState({
        isLoading: true
      });
      apiCall(
        METHOD.QUERY,
        EventListQuery(getUserID(), start, end, "2", ""),
        (data) => this.handleArchiveResponse(data),
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };
  handleArchiveResponse = data => {
    const { start, end } = this.state;
    if (data.is_last === 1) {
      this.setState( { hasMore: false }
      );
    }

    this.setState(prevState => {
      return {
        ...this.state,
        start: start + end,
        eventList: [...prevState.eventList, ...data.event_list],
        isLoading: false
      };
    });
  };

  loader = (<LoadingPage key={0} />);
  render() {
    const { end, hasMore, eventList } = this.state;
    const { searchEnd, searchHasMore, searchEvent } = this.props.searchData;
    return (
      <React.Fragment>
        {searchEvent && searchEvent.length === 0 ? (
          <InfiniteScroll
            pageStart={end}
            loadMore={this.loadArchiveEvent}
            hasMore={hasMore}
            useWindow={false}
            loader={this.loader}
          >
            <div className="container my-3">
              {eventList &&
                eventList.map((data, index) => {
                  return <EventItem data={data} key={index} index={index} />;
                })}
            </div>
          </InfiniteScroll>
        ) : (
          <InfiniteScroll
            pageStart={searchEnd}
            loadMore={this.props.searchApi}
            hasMore={searchHasMore}
            useWindow={false}
            loader={this.loader}
          >
            <div className="container my-4">
              {searchEvent &&
                searchEvent.map((data, index) => {
                  return <EventItem data={data} key={index} />;
                })}
            </div>
          </InfiniteScroll>
        )}
      </React.Fragment>
    );
  }
}
