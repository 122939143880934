/* eslint eqeqeq: 0 */
import React, { Component,Suspense } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import {
  addGeneralGroupToMaillingList,
  groupallpersonlist,
  GroupListQuery,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID, removeDuplicates } from "../../../util/utils";
import LoadingPage from "../../CommanComponents/LoadingPage";
import SearchGeneralGroup from "./SearchGeneralGroup";

class GeneralGGroupList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      group_list: [],
      start: 0,
      end: 20,
      hasMore: true,
      isLoading: false,
      addGroupLoading: false,
      searchGroupList: [],
      parentGroupId: 0,
      parent_group: true,
      breadcrumb: [],
      selectedGroupList: [],
      groupLinkLoader: false,
      person_list:[]
    };
  }


  getPersonList = (group_id) => {
    apiCall(
      METHOD.QUERY,
      groupallpersonlist(getUserID(), group_id, ""),
      (data) => {
        this.setState({
          person_list: data.person_list,
        });
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  handleLoader = (status) => {
    this.setState(() => {
      return {
        ...this.state,
        isLoading: status,
      };
    });
  };

  loadGeneralGroupList = () => {
    const { start, end, hasMore, isLoading, parentGroupId } = this.state;
    if (!isLoading && hasMore) {
      this.setState({
        isLoading: true,
      });

      apiCall(
        METHOD.QUERY,
        GroupListQuery(getUserID(), start, end, parentGroupId, "", 0),
        (data) => this.handleGeneralListResponse(data),
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  setSearchArrayList = (arrayList) => {
    this.setState({
      searchGroupList: arrayList,
    });
  };
  
  handleGroupClick = (group_id) => {
    this.setState(
      {
        parentGroupId: group_id,
        group_list: [],
        start: 0,
        hasMore: true,
        parent_group:false
      },
      () => {
        this.loadGeneralGroupList();
      }
    );
  };

  loader = (<LoadingPage key={0} />);

  handleCheckBox = (e, group_id) => {
    if (e.target.checked) {
      this.addSelectedGroupID(group_id);
    } else {
      this.removeSelectedGroupID(group_id);
    }
  };

  addSelectedGroupID = (group_id) => {
    this.setState((prev) => {
      return {
        selectedGroupList: [...prev.selectedGroupList, group_id],
      };
    });
  };

  handleGeneralListResponse = (data) => {
    const { start, end, group_list } = this.state;
    if (data.is_last == 1) {
      this.setState({ hasMore: false });
    }
    let new_group_list = [...group_list, ...data.group_list];
    let tempArray = [];
    tempArray = removeDuplicates(new_group_list, "id");

    this.setState((prevState) => {
      return {
        ...this.state,
        start: start + end,
        group_list: tempArray,
        isLoading: false,
        breadcrumb: data.breadcrumb,
      };
    });
  };

  removeSelectedGroupID = (group_id) => {
    let afterRemoveGroupList = this.state.selectedGroupList.filter((id) => {
      return id != group_id;
    });

    this.setState({
      selectedGroupList: afterRemoveGroupList,
    });
  };

  handleSubmit = () => {
    const { selectedGroupList } = this.state;
    const {isFromMailDetail,listIdForAction} = this.props
    let  list_id=0
    if(isFromMailDetail){
      list_id= listIdForAction;

    }else{
      const mail_list_id = this.props.match.params.mail_person_id;
      const fields = mail_list_id.split("-");
       list_id = fields[0];
    }
  

    let selectedAllGroup = selectedGroupList.toString();
    console.log("selectedAllGroup", selectedAllGroup);

    apiCall(
      METHOD.QUERY,
      addGeneralGroupToMaillingList(getUserID(), selectedAllGroup, list_id),
      (data) => {
        toast.success(this.props.t(data.message));
        this.props.handleAddGroupModal(true);
      },
      (isLoading) => {
        this.setState({
          addGroupLoading: isLoading,
        });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  
  handleAddClick = (group_id) => {
    const mail_list_id = this.props.match.params.mail_person_id;
    const fields = mail_list_id.split("-");
    const list_id = fields[0];
    apiCall(
      METHOD.QUERY,
      addGeneralGroupToMaillingList(getUserID(), group_id, list_id),
      (data) => {
        toast.success(this.props.t(data.message));
        this.props.handleAddGroupModal(true);
      },
      (isLoading) => {
        this.setState({
          addGroupLoading: isLoading,
        });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  render() {
    const {
      end,
      hasMore,
      group_list,
      addGroupLoading,
      searchGroupList,
      breadcrumb,
      parent_group,
      parentGroupId,
      groupLinkLoader,
      selectedGroupList,
      person_list
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="d-block">
              <div
                className="modal fade show"
                id="NewEvent"
                tabindex="-1"
                aria-labelledby="NewEventLabel"
                aria-modal="true"
                style={{ display: "block" }}
              >
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header border-0 d-block pt-4 pb-0">
                    <div className="d-flex align-items-center justify-content-between mb-4">
                        <button
                          type="button"
                          onClick={() => {
                            this.props.handleAddGroupModal(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                        </button>
                        <h5 className="modal-title m-0 text-center">
                        {t("generalGroupList.addGroup")}
                      </h5>
                        {groupLinkLoader ? (
                          <div className="btn btn-primary-theme list font-13 ml-auto pointer disabled">
                            {t("MailDetailScreen.btnSave")}
                            <div className="spinner-border spinner-border-sm ml-1 text-blue">
                              <span className="sr-only">
                                {t("GeneralMessages.loading")}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="btn btn-primary-theme list font-13  pointer"
                            onClick={() => {
                              this.handleSubmit();
                            }}
                          >
                            {t("MailDetailScreen.btnSave")}
                          </div>
                        )}
                      </div>
                     
                      <SearchGeneralGroup
                        searchArray={this.setSearchArrayList}
                      />
                    </div>
                    <div className="modal-body">
                      <div className="wrapper position-relative">
                        {!parent_group && (
                          <div className="container my-4 z-index-99">
                            <div className="d-block flex-fill z-index-9999">
                              <div className="ml-2 my-2 mb-3 d-flex">
                                <div className="d-flex w-100">
                                  <div className="clearfix d-flex0 0align-items-center">
                                    <p className="font-12 m-0">
                                      <React.Fragment>
                                        <a
                                          href="javascript;:"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.handleGroupClick(0);
                                          }}
                                          className="text-muted"
                                          data-tip={"Start"}
                                        >
                                          {t("GroupListScreen.start")}
                                          <ReactTooltip
                                            place="top"
                                            type="info"
                                            effect="float"
                                          />
                                        </a>
                                        {" > "}
                                      </React.Fragment>
                                      {breadcrumb &&
                                        breadcrumb.map((item, index) => {
                                          if (index == 0) {
                                            return (
                                              <React.Fragment key={index}>
                                                <a
                                                  href="javascript;:"
                                                  className="text-muted"
                                                  data-tip={item.name}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleGroupClick(
                                                      item.id,
                                                      item.person
                                                    );
                                                  }}
                                                >
                                                  <ReactTooltip
                                                    place="top"
                                                    type="info"
                                                    effect="float"
                                                  />
                                                  {item.name}
                                                </a>
                                                {" > "}
                                              </React.Fragment>
                                            );
                                          } else if (
                                            index != 0 &&
                                            index != breadcrumb.length - 1
                                          ) {
                                            return (
                                              <React.Fragment key={index}>
                                                <a
                                                  href="javascript;:"
                                                  className="text-muted"
                                                  data-tip={item.name}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleGroupClick(
                                                      item.id,
                                                    );
                                                  }}
                                                >
                                                  <ReactTooltip
                                                    place="top"
                                                    type="info"
                                                    effect="float"
                                                  />
                                                  [...]
                                                </a>

                                                {index !=
                                                  breadcrumb.length - 1 &&
                                                  " > "}
                                              </React.Fragment>
                                            );
                                          } else if (
                                            index ==
                                            breadcrumb.length - 1
                                          ) {
                                            return (
                                              <React.Fragment key={index}>
                                                {item.name && item.name}
                                              </React.Fragment>
                                            );
                                          }
                                        })}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="middle-section sab-middle-section1">
                          {addGroupLoading && this.loader}
                          {searchGroupList && searchGroupList.length != 0 ? (
                            <div className="container my-4  00z-index-99">
                              {searchGroupList &&
                                searchGroupList.map((item, index) => {
                                  const { id, name, has_child, person,sub_group} = item;

                                  return (
                                    <div className="custom-accordian bg-border-accordion" id={`groupDiv${index}`}>
                                    <Accordion
                                    allowZeroExpanded={true}
                                    onChange={(res) => {
                                      if(has_child!=0){
                                        res.length > 0 && this.handleGroupClick(id);
                                      }else{
                                        res.length > 0 && this.getPersonList(id);
                                      }
                  
                                    }}
                                    key={index}
                                  >
                                    <AccordionItem className="bg-border blue">
                                      <AccordionItemButton>
                                        {name && name}
                  
                            {has_child == 1 ? (
                              
                                <p className="text-muted font-12 m-0">
                                {sub_group && sub_group}{" "}
                                {t("GroupListScreen.subGroup")}
                              </p>
                            ) : (
                              <p className="text-muted font-12 m-0">
                              {person && person}{" "}
                              {person == 1
                                ? t("GroupListScreen.singularPerson")
                                : t("GroupListScreen.pluralPerson")}
                            </p>
                            )}
      
                  
                  {has_child==0 && <div className="align-self-center accordian-add-btn">
                                <a
                                href="#"
                                className="text-dark"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.handleAddClick(id)
                                }}
                              >
                                <i className="fa fa-plus pointer"  ></i>
                              </a>
                                      </div>}
                                       
                                      </AccordionItemButton>
                                      <Suspense fallback={<LoadingPage />}>
                                        <AccordionItemPanel>
                                          {person_list && person_list.map((perosn,index)=>{
                                            const {full_name,email,is_valid_email} = perosn
                                            return( 
                                           <div className="left-icon-box small d-flex" key={index}>
                                                  <div
                                                    className="circle"
                                                    style={{ backgroundColor: "transparent", lineHeight:'55px' }}
                                                  >
                                                    {is_valid_email && is_valid_email == 1 ? (
                                                      <i className="zmdi zmdi-check-circle zmdi-hc-fw font-size-22 text-success"></i>
                                                    ) : (
                                                      <i className="zmdi zmdi-alert-circle zmdi-hc-fw font-size-22 text-blue"></i>
                                                    )}
                                                  </div>
                                                <div className="media-body pt-3 px-4">
                                                  <h6 className="font-size-15 mb-1">{full_name && full_name}</h6>
                                                  <p className="text-muted font-12 m-0">{email && email}</p>
                                                </div>
                                          </div>)
                                          })}
                                        </AccordionItemPanel>
                                      </Suspense>
                                    </AccordionItem>
                                  </Accordion>
                                  </div>
                  );
                                })}
                            </div>
                          ) : (
                            <InfiniteScroll
                              pageStart={end}
                              loadMore={this.loadGeneralGroupList}
                              hasMore={hasMore}
                              useWindow={false}
                              loader={this.loader}
                            >
                              <div className="container my-2">
                                {group_list &&
                                  group_list.map((item, index) => {
                                    const { name, id, has_child,sub_group,person } = item;

                                    return (
                                      <div className="custom-accordian bg-border-accordion" id={`groupDiv${index}`}>
                                      <Accordion
                                      allowZeroExpanded={true}
                                      onChange={(res) => {
                                        if(has_child!=0){
                                          res.length > 0 && this.handleGroupClick(id);
                                        }else{
                                          res.length > 0 && this.getPersonList(id);
                                        }
                    
                                      }}
                                      key={index}
                                    >
                                      <AccordionItem className="bg-border blue">
                                        <AccordionItemButton>
                                          {name && name}
                    
                                          {has_child == 1 ? (
                                            <p className="text-muted font-12 m-0">
                                            {sub_group && sub_group}{" "}
                                            {t("GroupListScreen.subGroup")}
                                          </p>
                                          ) : (
                                            <p className="text-muted font-12 m-0">
                                              {person && person}{" "}
                                              {person == 1
                                                ? t("GroupListScreen.singularPerson")
                                                : t("GroupListScreen.pluralPerson")}
                                            </p>
                                          )}
                    
                    {has_child==0 && <div className="align-self-center accordian-add-btn">
                                  <a
                                  href="#"
                                  className="text-dark"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleAddClick(id)
                                  }}
                                >
                                  <i className="fa fa-plus pointer"  ></i>
                                </a>
                                        </div>}
                                         
                                        </AccordionItemButton>
                                        <Suspense fallback={<LoadingPage />}>
                                          <AccordionItemPanel>
                                            {person_list && person_list.map((perosn,index)=>{
                                              const {full_name,email,is_valid_email} = perosn
                                              return( 
                                             <div className="left-icon-box small d-flex" key={index}>
                                                    <div
                                                      className="circle"
                                                      style={{ backgroundColor: "transparent", lineHeight:'55px' }}
                                                    >
                                                      {is_valid_email && is_valid_email == 1 ? (
                                                        <i className="zmdi zmdi-check-circle zmdi-hc-fw font-size-22 text-success"></i>
                                                      ) : (
                                                        <i className="zmdi zmdi-alert-circle zmdi-hc-fw font-size-22 text-blue"></i>
                                                      )}
                                                    </div>
                                                  <div className="media-body pt-3 px-4">
                                                    <h6 className="font-size-15 mb-1">{full_name && full_name}</h6>
                                                    <p className="text-muted font-12 m-0">{email && email}</p>
                                                  </div>
                                            </div>)
                                            })}
                                          </AccordionItemPanel>
                                        </Suspense>
                                      </AccordionItem>
                                    </Accordion>
                                    </div>
                    );
                                  })}
                              </div>
                            </InfiniteScroll>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop fade show"></div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(GeneralGGroupList));
