import React from "react";
import MediaQuery from "react-responsive";
import ChangeUserPasswordMobile from "./ChangeUserPasswordMobile";
import ChangeUserPasswordDesktop from "./ChangeUserPasswordDesktop";

export default function ChangeLanguageResponsive() {
  return (
    <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <ChangeUserPasswordDesktop />
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <ChangeUserPasswordMobile />
      </MediaQuery>
    </React.Fragment>
  );
}
