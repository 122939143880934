/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deletePersonMailQuery,
  getallpersondatatypevaluesfromfilter,
  getallpersonmailinglistgroup,
  getmailinglistgroup,
  getmailinglistgroupForFilter,
  getpersondatatypefromfilter,
  removelist,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../../util/utils";
import GroupItem from "./GroupItem";

class MailDetailPersonList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      headerMenuStatus: false,
      list_id: 0,
      mail_id: 0,
      data: { person_list: [], group_list: [] },
      searchValue: "",
      personModal: false,
      addGroupContactModal: false,
      editGroup: false,
      addGGroup: false,
      editListPermission: false,
      deleteListPermission: false,
      breadcrumb: [],
      personDataTypeList: false,
      searchData: { person_list: [], group_list: [], breadcrumb: [] },
      personDataTypeList: [],
      personDataTypeValueList: [],
      personDataTypeListIsOpen: false,
      personDataTypeValueIsOpen: false,
      selectedValue: [],
      personDataTypeName: "",
      filterValue: "",
      personDataTypeId: 0,
      sortType: 1,
    };
  }

  storeApiData = (data) => {
    this.setState({
      data,
    });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    const list_id = this.props.match.params.mail_id;

    this.setState({
      list_id: list_id,
    });
    this.loadPersonDataTypeList();

    apiCall(
      METHOD.QUERY,
      getallpersonmailinglistgroup(getUserID(), list_id, ""),
      (data) => {
        this.setState({
          group_list: data.group_list,
          data,
        });
      },
      (isLoading) => this.setState({ isLoading }),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }
  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };
  handleAfterApiCall = (data) => {
    this.setState(() => {
      return {
        ...this.state,
        mailList: data.mailing_list,
      };
    });
  };

  loadPersonList = (mail_id) => {
    apiCall(
      METHOD.QUERY,
      getallpersonmailinglistgroup(getUserID(), mail_id, ""),
      (data) => {
        this.setState({
          header_list: data.mailing_list,
          group_list: data.group_list,
          breadcrumb: data.breadcrumb,
          data,
        });
        this.props.getMailDetails(data);
      },
      (isLoading) => {
        this.setState({ isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleDelete = (item) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to delete this person.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              deletePersonMailQuery(getUserID(), item.list_id, item.id),
              (data) => {
                toast.success(this.props.t(data.message));
                let getMailDetailData = this.state.data;
                getMailDetailData.total_person =
                  getMailDetailData.total_person - 1;
                let personList = getMailDetailData.person_list;

                let afterDeletePerson = personList.filter((person) => {
                  return person.id != item.id;
                });
                getMailDetailData.person_list = afterDeletePerson;
                this.setState({ data: getMailDetailData });
              },
              (isLoading) => this.handleLoader(isLoading),
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  updatePersonCount = (group_id) => {
    let temp_data = this.state.data;
    let group_list = temp_data.group_list;
    const index = group_list.findIndex((group) => group.id == group_id);
    group_list[index].person = group_list[index].person - 1;
    temp_data.group_list = group_list;
    this.setState({ data: temp_data });
  };

  delete_group_item = (id) => {
    let after_delete_group_list = this.state.group_list.filter((item) => {
      return item.id != id;
    });

    this.setState({
      group_list: after_delete_group_list,
    });
  };

  handleAlphabatice = (name, index, list) => {
    name = name.charAt(0).toUpperCase() + name.slice(1);

    console.log("name", name);

    let resulAlpha = "";
    if (index == 0) {
      resulAlpha = name.charAt(0);
    } else {
      let oldName = list[index - 1].name;
      oldName = oldName.charAt(0).toUpperCase() + oldName.slice(1);
      const digit1 = oldName.charAt(0);
      const digit2 = name.charAt(0);

      if (digit1 != digit2) {
        resulAlpha = digit2;
      } else {
        resulAlpha = "";
      }
    }
    return resulAlpha;
  };

  handleCheckBox = (e, value) => {
    let selectedValue = this.state.selectedValue;
    if (e.target.checked) {
      selectedValue.push(value);
    } else {
      selectedValue = selectedValue.filter((e) => e !== value);
    }
    this.setState({
      selectedValue,
    });
  };

  handleAlphabaticeSort = (alphabet) => {
    const { group_list } = this.state;
    let index = -1;
    for (let i = 0; i < group_list.length; i++) {
      const group = group_list[i];
      let name = group.name;
      name = name.charAt(0).toUpperCase();
      if (alphabet == name && index == -1) {
        index = i;
      }
    }
    if (index != -1) {
      const divID = `groupDiv${index}`;
      const section = document.getElementById(divID);
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  loadPersonDataTypeList = () => {
    apiCall(
      METHOD.QUERY,
      getpersondatatypefromfilter(getUserID()),
      (data) => this.handlePersonDataTypeResponse(data),
      (isLoading) => {
        this.setState({ isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handlePersonDataTypeResponse = (data) => {
    this.setState({
      personDataTypeList: data.person_data_type_list,
    });
  };

  loadPersonDataTypeValue = (personDataTypeId) => {
    apiCall(
      METHOD.QUERY,
      getallpersondatatypevaluesfromfilter(
        getUserID(),
        personDataTypeId,
        this.state.list_id
      ),
      (data) => this.handlePersonDataTypeValueResponse(data),
      (isLoading) => {
        this.setState({
          isLoading,
        });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handlePersonDataTypeValueResponse = (data) => {
    this.setState({
      personDataTypeValueList: data.list,
    });
  };

  handleApplyFilter = (filterValue) => {
    const { personDataTypeId, list_id } = this.state;
    console.log("handleApplyFilter -->", filterValue);
    this.setState({
      personDataTypeValueIsOpen: false,
    });
    if (filterValue == "") {
      this.setState(
        {
          filterValue,
          personDataTypeName: "",
          personDataTypeId: 0,
        },
        () => {
          this.loadPersonList(list_id);
        }
      );
    } else {
      apiCall(
        METHOD.QUERY,
        getmailinglistgroupForFilter(
          getUserID(),
          list_id,
          personDataTypeId,
          filterValue
        ),
        (data) => {
          this.setState({
            header_list: data.mailing_list,
            group_list: data.group_list,
            breadcrumb: data.breadcrumb,
            data,
          });
        },
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  render() {
    const {
      group_list,
      data,
      personDataTypeListIsOpen,
      personDataTypeList,
      personDataTypeValueIsOpen,
      personDataTypeValueList,
      selectedValue,
      personDataTypeName,
      personDataTypeId,
      sortType,
    } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="section-container">
              <div className="section-content">
                {/* <div className="d-flex align-items-center justify-content-center mb-4 pb-3">
                          <ul className="list-inline alphabetic-sort m-0">
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("A");
                              }}
                            >
                              A.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("B");
                              }}
                            >
                              B.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("C");
                              }}
                            >
                              C.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("D");
                              }}
                            >
                              D.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("E");
                              }}
                            >
                              E.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("F");
                              }}
                            >
                              F.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("G");
                              }}
                            >
                              G.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("H");
                              }}
                            >
                              H.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("I");
                              }}
                            >
                              I.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("J");
                              }}
                            >
                              J.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("K");
                              }}
                            >
                              K.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("L");
                              }}
                            >
                              L.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("M");
                              }}
                            >
                              M.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("N");
                              }}
                            >
                              N.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("O");
                              }}
                            >
                              O.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("P");
                              }}
                            >
                              P.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("Q");
                              }}
                            >
                              Q.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("R");
                              }}
                            >
                              R.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("S");
                              }}
                            >
                              S.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("T");
                              }}
                            >
                              T.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("U");
                              }}
                            >
                              U.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("V");
                              }}
                            >
                              V.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("W");
                              }}
                            >
                              W.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("X");
                              }}
                            >
                              X.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("Y");
                              }}
                            >
                              Y.
                            </li>
                            <li
                              className="list-inline-item pointer"
                              onClick={() => {
                                this.handleAlphabaticeSort("Z");
                              }}
                            >
                              Z.
                            </li>
                          </ul>
                        </div>
                        */}

                <div className="inner-sm-container w-75 mx-auto">
                  <div className="d-flex justify-content-between align-items-center font-size-14 mb-4">
                    <ul className="list-inline m-0">
                      <li
                        className={
                          personDataTypeListIsOpen
                            ? "list-inline-item mr-4 dropdown show"
                            : "list-inline-item mr-4 dropdown"
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState((prev) => {
                            return {
                              personDataTypeListIsOpen:
                                !prev.personDataTypeListIsOpen,
                            };
                          });
                        }}
                      >
                        <button
                          type="button"
                          className="btn  dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded={
                            personDataTypeListIsOpen ? "true" : "false"
                          }
                        >
                          {t("mailDetailPersonListFilter.filterPerson")}
                          <i className="zmdi zmdi-chevron-down zmdi-hc-2x font-size-28 align-middle ml-1"></i>
                        </button>

                        <div
                          className={
                            personDataTypeListIsOpen
                              ? "dropdown-menu show"
                              : "dropdown-menu"
                          }
                        >
                          {personDataTypeList &&
                            personDataTypeList.map((item, index) => {
                              const { id, name } = item;
                              return (
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState(
                                      {
                                        personDataTypeValueIsOpen: true,
                                        personDataTypeId: id,
                                        personDataTypeName: name,
                                      },
                                      () => {
                                        this.loadPersonDataTypeValue(id);
                                      }
                                    );
                                  }}
                                >
                                  {name}
                                </a>
                              );
                            })}
                        </div>
                      </li>

                      {personDataTypeId != 0 && (
                        <li
                        className={
                          personDataTypeValueIsOpen
                            ? "list-inline-item mr-4 dropdown show"
                            : "list-inline-item mr-4 dropdown"
                        }
                        // onClick={() => {
                        //   this.setState((prev) => {
                        //     return {
                        //       personDataTypeValueIsOpen: !prev.personDataTypeValueIsOpen,
                        //     };
                        //   });
                        // }}
                      >
                        <button
                          type="button"
                          class="btn  dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded={
                            personDataTypeValueIsOpen
                              ? "true"
                              : "false"
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState((prev) => {
                              return {
                                personDataTypeValueIsOpen:
                                  !prev.personDataTypeValueIsOpen,
                              };
                            });
                          }}
                        >
                          {personDataTypeName}
                          <i className="zmdi zmdi-chevron-down zmdi-hc-2x font-size-28 align-middle ml-1"></i>
                        </button>

                        <div
                          className={
                            personDataTypeValueIsOpen
                              ? "dropdown-menu min-w-200 show"
                              : "dropdown-menu min-w-200"
                          }
                        >
                          {personDataTypeValueList &&
                            personDataTypeValueList.map(
                              (item, index) => {
                                const { value } = item;
                                return (
                                  <div
                                    key={index}
                                    className="dropdown-item 0align-self-center "
                                  >
                                    <div className="d-flex">
                                      <label className="checkbox-container">
                                        {value == "Yes"
                                          ? t(
                                              "mailDetailPersonListFilter.yes"
                                            )
                                          : value == "No"
                                          ? t(
                                              "mailDetailPersonListFilter.no"
                                            )
                                          : value}
                                        <input
                                          type="checkbox"
                                          onChange={(e) =>
                                            this.handleCheckBox(
                                              e,
                                              value
                                            )
                                          }
                                          checked={selectedValue.includes(
                                            value
                                          )}
                                        />
                                        <span className="checkmark align-self-center"></span>
                                      </label>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          <div className="text-right mx-3 mt-4 mb-2">
                            {personDataTypeValueList &&
                            personDataTypeValueList.length !=
                              0 ? (
                              <div
                                className="btn btn-primary-theme list pointer"
                                onClick={() => {
                                  const { selectedValue } =
                                    this.state;
                                  const value =
                                    selectedValue.toString();
                                  console.log("value", value);
                                  this.handleApplyFilter(value);
                                }}
                              >
                                {t("mailDetailPersonList.apply")}
                              </div>
                            ) : (
                              <span>
                                {t(
                                  "mailDetailPersonList.no_record_found"
                                )}
                              </span>
                            )}
                          </div>
                        </div>
                      </li>
                    )}
                    </ul>
                    <div className="clearfix sorting-filter ">
                      <span className="font-13 text-muted mr-2">
                        {t("GroupListScreen.sorting")}
                      </span>
                      <div
                        className="btn-group btn-group-sm"
                        role="group"
                        aria-label="First group"
                      >
                        <button
                          type="button"
                          onClick={() => this.setState({ sortType: 1 })}
                          className={
                            sortType == 1
                              ? "btn btn-dark mr-2"
                              : "btn btn-outline-secondary mr-2"
                          }
                        >
                          {t("mailDetailPersonList.all")}
                        </button>
                        <button
                          type="button"
                          onClick={() => this.setState({ sortType: 2 })}
                          className={
                            sortType == 2
                              ? "btn btn-dark mr-2"
                              : "btn btn-outline-secondary mr-2"
                          }
                        >
                          {t("GroupListScreen.a-z")}
                        </button>
                        <button
                          type="button"
                          onClick={() => this.setState({ sortType: 3 })}
                          className={
                            sortType == 3
                              ? "btn btn-dark mr-2"
                              : "btn btn-outline-secondary mr-2"
                          }
                        >
                          {t("mailDetailPersonList.contactGroup")}
                        </button>
                      </div>
                    </div>
                  </div>

                  {sortType != 2 &&
                    group_list &&
                    group_list.map((item, index) => {
                      return (
                        <GroupItem
                          delete_group_item={this.delete_group_item}
                          updatePersonCount={this.updatePersonCount}
                          key={index}
                          item={item}
                          index={index}
                        />
                      );
                    })}

                  {sortType != 3 && data.person_list.length != 0 && (
                    <div className="table-responsive">
                      <table className="table table-hover font-size-14  m-0 bg-white">
                        {data.person_list &&
                          data.person_list.map((item, index) => {
                            const {
                              id,
                              name,
                              email,
                              phone,
                              list_id,
                              is_deletable,
                            } = item;
                            return (
                              <>
                                {this.handleAlphabatice(
                                  name,
                                  index,
                                  data.person_list
                                ) != "" && (
                                  <thead className="bg-white ">
                                    <tr>
                                      <th>
                                        <span className="letter">
                                          {this.handleAlphabatice(
                                            name,
                                            index,
                                            data.person_list
                                          )}
                                        </span>
                                      </th>
                                      {index == 0 && (
                                        <>
                                          {" "}
                                          <th>
                                            <i className="zmdi zmdi-email zmdi-hc-2x font-size-28 text-muted"></i>
                                          </th>
                                          <th>
                                            <i className="zmdi zmdi-smartphone-android zmdi-hc-2x font-size-28 text-muted"></i>
                                          </th>
                                        </>
                                      )}
                                    </tr>
                                  </thead>
                                )}

                                <tbody
                                  className="bg-white "
                                  style={{ borderTop: "none" }}
                                >
                                  <tr>
                                    <td className="font-weight-600">{name}</td>
                                    <td className="text-muted">{email}</td>
                                    <td className="text-muted">{phone}</td>
                                    <td className="text-right">
                                    {is_deletable == 1 && (
                                     
                                        <a
                                          href="javascript;:"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.handleDelete(item, list_id);
                                          }}
                                        >
                                          <i className="zmdi zmdi-delete zmdi-hc-lg"></i>
                                        </a>
                                      
                                    )}
                                    </td>
                                  </tr>
                                </tbody>
                              </>
                            );
                          })}
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.mailDetailStateToProps,
        stateToProps.mailPersonListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.mailDetailsDispatchToProps,
    actions.mailPersonListDispatchToProps,
  ])
)(withTranslation()(withRouter(MailDetailPersonList)));
