import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { SendSmsQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";

import { connect } from "react-redux";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";

class SMSSend extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  sendSpeechToText = () => {
     const message_id = this.props.match.params.message_id;
     

    apiCall(
      METHOD.QUERY,
      SendSmsQuery(getUserID(), message_id),
      (data) => {
        this.props.updateChannelSendingStatus({message_status_identifier:'sending',message_status:'sending'})
        toast.success(this.props.t(data.message));
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  render() {
    const { isLoading } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <a
            href="javascript;:"
            className="float-right mr-3"
            onClick={(e) => {
              e.preventDefault();
              this.sendSpeechToText();
            }}
            >
              <i className="zmdi zmdi-mail-send zmdi-hc-2x font-size-20 align-bottom mr-2"></i>
              {t("ChannelDetail.sendSms")}
            
            </a>

          );
        }}
      </Translation>
    );
  }
}
export default connect(
  state =>
    stateToProps.appMapStateToProps(
      [stateToProps.channelDetailStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.channelDetailsDispatchToProps,
  ])
)(withRouter(withTranslation()(SMSSend)));
