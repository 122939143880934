/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { GroupListQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../../util/utils";
import CommonLoader from "../../CommanComponents/CommonLoader";
import NoRecordFound from "../../CommanComponents/NoRecordFound";
import FooterDesktop from "../../Footer/FooterDesktop";
import HeaderDesktop from "../../Header/HeaderDesktop";
import AddNewGroup from "./AddNewGroup";
import GroupItemListView from "./GroupItemListView";
import RearrangeGroup from "./RearrangeGroup";

import SearchGroup from "./SearchGroup";
import SearchPersonResult from "./SearchPersonResult";
import SearchGroupListBaseOnPerson from "./SearchGroupListBaseOnPerson";
import SearchPersonBaseOnGroup from "./SearchPersonBaseOnGroup";
import SearchGroupList from "./SearchGroupList";
import SearchPersonBaseonCompany from "./SearchPersonBaseonCompany";

class GroupList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      groupList: [],
      end: 10,
      headerMenuStatus: false,
      loader: false,
      searchGroupList: [],
      searchPersonList: [],
      sortType: 1,
      displayType: 0,
      addModal:false,
      reArrangeModal:false,
      groupPermission:false,
      groupListPermission:false,
      searchDisplayType: 0,
      groupListBaseOnPerson: [],
      personListBaseOnGroupID:[],
      personListBreadCumb:[],
      selectedPersonId:0,
      groupIdBaseOnPersonDetail:0
    };
    this.searchRef = React.createRef();
  }

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  loader = (<CommonLoader key={0} />);

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  loadItem = () => {
    const { end, loader, sortType } = this.state;
    apiCall(
      METHOD.QUERY,
      GroupListQuery(
        getUserID(),
        this.props.groupList.start,
        end,
        0,
        "",
        sortType
      ),
      (data) => {
        this.props.getGroupList(data.group_list);
        this.handleGroupApiResponse(data);
      },
      (isLoading) => {
        this.handleLoader(isLoading);
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  changeSortType = (sortType) => {
    this.setState(
      {
        sortType: sortType,
      },
      () => {
        this.props.clearGroupState();
        setTimeout(() => {
          this.loadItem()
        }, 300);
      }
    );
  };

  handleGroupApiResponse = (data) => {
    if (data.is_last == 1) {
      this.props.getGroupHasMoreValue(false);
    }
    this.props.getGroupStartValue(this.props.groupList.start + this.state.end);
  };

  setSearchGroupList = (groupList, personList) => {
    this.setState({
      searchGroupList: groupList,
      searchPersonList: personList,
    });
  };

  componentDidMount() {
    const groupListPermission = checkUserPermission("read_group");
    const groupPermission = checkUserPermission("create_group");
      this.setState({
        groupPermission,
        groupListPermission
      })
    this.loadItem()
  }

  handleAddModal = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          addModal: !prev.addModal,
        };
      },
      () => {
        if (this.state.addModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.loadItem()
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  
  handleRearrangeModal = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          reArrangeModal: !prev.reArrangeModal,
        };
      },
      () => {
        if (this.state.reArrangeModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.loadItem()
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  handleDisplayType =()=>{
    this.setState({
      searchDisplayType:0
    })
  }
  
  setSearchGroupList = (personList) => {
    this.setState({
      searchPersonList: personList,
      searchDisplayType: personList.length == 0 ? 0 : 1,
    });
  };

  handleSearchDisplayTypeToInitialStage = () => {
    this.setState({
      searchDisplayType:0,
      search:'',
      value:''

    });
  };
  handleSearchDisplayType  = (searchDisplayType ) => {
    this.setState({
      searchDisplayType,
      search:'',
      value:''
    });
  };

  handleGroupbaseOnPerson = (mailList) => {
    if (mailList.length == 0) {
      this.setState({
        groupListBaseOnPerson: [],
        searchPersonList: [],
        // searchDisplayType: 0,
      });
    } else {
      this.setState({
        // searchDisplayType: 0,
        groupListBaseOnPerson: mailList,
      });
    }
  };
  // getMailPersonList=(personId)=>{
  //   apiCall(
  //     METHOD.QUERY,
  //     getmailinglistfromperson(getUserID(),personId),
  //     (data) => this.handleGetGroupPersonResponse(data),
  //     (isLoading) => this.handleLoader(isLoading),
  //     (data) => {
  //       toast.error(this.props.t(data.message));
  //     }
  //   );
  // }

  // handleGetGroupPersonResponse=(data)=>{
  //   console.log("data",data.person_list);
  //   // this.props.handleMailbaseOnPerson(data.mailing_list)
  // }

  
  setGroupFromPerson=(groupListBaseOnPerson,selectedPersonId)=>{
    console.log("groupListBaseOnPerson",groupListBaseOnPerson);
    console.log("selectedPersonId",selectedPersonId);
    if(groupListBaseOnPerson.length!=0){
      this.setState({
        groupListBaseOnPerson,
        searchDisplayType:2,
        selectedPersonId
      })
    }else{
      toast.info(
        this.props.t("GeneralMessages.noMailFound") );
    }
  
  }

  handleGroupWiseResponse=(personListBaseOnGroupID,personResponse,personListBreadCumb,group_id)=>{
    this.setState({
      personListBaseOnGroupID,
      personListBreadCumb,
      personResponse,
      searchDisplayType:3,
      groupIdBaseOnPersonDetail:group_id
    })
  }

  
  handlePersonListBaseonCompany = (personListBaseOnGroupID)=>{
    console.log("personListBaseOnGroupIDpersonListBaseOnGroupID",personListBaseOnGroupID);
    this.setState({
      personListBaseOnGroupID,
      searchDisplayType:4
    })
  }

  loader = (<CommonLoader key={0} />);

  render() {
    const {
      end,
      searchGroupList,
      searchPersonList,
      sortType,
      displayType,
      isLoading,
      addModal,
      reArrangeModal,
      groupPermission,
      groupListPermission,
      searchDisplayType,groupListBaseOnPerson,selectedPersonId,personListBreadCumb,personListBaseOnGroupID,
      groupIdBaseOnPersonDetail
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="content-wrapper">
              <HeaderDesktop selectionType={3}/>
              <div className="content">
                <div className="section section-padding">
                  <div className="container">
                    <div className="section-container">
                      {isLoading && <CommonLoader />}

                      <div className="section-title position-relative text-center d-md-flex align-items-start mb-1">
                        <div className="flex-fill mr-n-228">
                          <h1 className="page-title h4">
                            {t("GroupListScreen.headerTitle")}
                          </h1>
                        </div>

                        {addModal && (
                      <AddNewGroup
                      handleAddModal={(isFromSave) => {
                          this.handleAddModal(isFromSave);
                        }}
                      />
                    )}

                  {reArrangeModal && (
                      <RearrangeGroup
                      handleRearrangeModal={(isFromSave) => {
                          this.handleRearrangeModal(isFromSave);
                        }}
                      />
                    )}

                            <div className="d-inline-block">
                              {groupPermission &&  <button
                                  type="button"
                                  className="btn btn-primary-theme group btn-sm mr-2"
                                  data-toggle="modal"
                                  data-target="#NewEvent"
                                  onClick={() => {
                                    this.handleAddModal(false);
                                  }}
                                >
                                   {t("GroupListScreen.addGroup")}
                                </button>}
                               
                                <button
                                  type="button"
                                  className="btn btn-primary-theme group btn-sm"
                                  data-toggle="modal"
                                  data-target="#NewEvent"
                                  onClick={() => {
                                    this.handleRearrangeModal(false);
                                  }}
                                >
                                {t("GroupListScreen.setpriority")}
                                </button>
                            </div>
                      </div>
                      <div className="section-content">
                          <div className="d-flex align-items-center justify-content-end mt-3 mb-4">
                            <div className="flex-fill">
                            <SearchGroup
                    handleMailbaseOnPerson={(mailList) => {this.handleMailbaseOnPerson(mailList);}}
                    handlePersonListBaseonCompany={(personList)=>this.handlePersonListBaseonCompany(personList)}
                    setGroupFromPerson={(personList,selectedPersonId)=>{this.setGroupFromPerson(personList,selectedPersonId)}}
                    searchArray={this.setSearchGroupList}
                    handleSearchDisplayType={(searchDisplayType) => {this.handleSearchDisplayType(searchDisplayType);}}
                  />
                            </div>
                          </div>
                          {searchDisplayType==0 &&  <div className="clearfix sorting-filter mb-4">
                                <span className="font-13 text-muted mr-2">
                                  {t("GroupListScreen.sorting")}
                                </span>
                                <div
                                  className="btn-group btn-group-sm"
                                  role="group"
                                  aria-label="First group"
                                >
                                  <button
                                    type="button"
                                    onClick={() => this.changeSortType(1)}
                                    className={
                                      sortType == 1
                                        ? "btn btn-dark mr-2"
                                        : "btn btn-outline-secondary mr-2"
                                    }
                                  >
                                    {t("GroupListScreen.a-z")}
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => this.changeSortType(2)}
                                    className={
                                      sortType == 2
                                        ? "btn btn-dark mr-2"
                                        : "btn btn-outline-secondary mr-2"
                                    }
                                  >
                                    {t("GroupListScreen.orderOfPriority")}
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => this.changeSortType(3)}
                                    className={
                                      sortType == 3
                                        ? "btn btn-dark mr-2"
                                        : "btn btn-outline-secondary mr-2"
                                    }
                                  >
                                    {t("GroupListScreen.crisisContacts")}
                                  </button>
                                </div>
                              </div>
                      }
                         
                      
                        {searchDisplayType==0 && groupListPermission &&(  <div className="row">
                        {this.props.groupList &&
                          this.props.groupList.groupList.map(
                            (data, index) => {
                              return (
                                <GroupItemListView
                                  data={data}
                                  key={index}
                                  index={index}
                                />
                              );
                            }
                          )}
                      </div>
                )}
{/* :(<span>{t("GeneralMessages.permissionError")}</span>) */}
                        {/* {this.props.groupList &&
                          this.props.groupList.length == 0 && (
                            <NoRecordFound
                              title="Group List"
                              name="group list"
                            />
                          )} */}
                      </div>

                      {this.props.groupList.hasMore && searchDisplayType== 0 && (
                        <div className="text-center mt-5">
                          <button
                            type="button"
                            className="btn btn-primary-theme group"
                            onClick={(e) => {
                              e.preventDefault();
                              this.loadItem();
                            }}
                          >
                            {t("HomeScreen.showMore")}
                          </button>
                        </div>
                      )}


{searchDisplayType == 1 && (
                 <SearchPersonResult 
                      suggetionList={this.state.searchPersonList}
                      handlePersonListBaseonCompany={(personList)=>this.handlePersonListBaseonCompany(personList)}
                      setGroupFromPerson={(personList,selectedPersonId)=>{this.setGroupFromPerson(personList,selectedPersonId)}}/>
                )}

            {searchDisplayType == 2 && (
                  <div className="container my-2">
                    {groupListBaseOnPerson.map((data, index) => {
                      return <SearchGroupListBaseOnPerson data={data} key={index} 
                      selectedPersonId={selectedPersonId}
                      handleGroupWiseResponse={(personListBaseOnGroupID,personResponse,personListBreadCumb,group_id)=>{this.handleGroupWiseResponse(personListBaseOnGroupID,personResponse,personListBreadCumb,group_id)}}/>;
                    })}
                  </div>
                )}

              {searchDisplayType == 3 && (
                  <div className="container my-2">
                       <nav aria-label="breadcrumb">
                        <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item">
                            {t("mailDetail.home")}
                          </li>
                          <li
                              className="breadcrumb-item pointer"
                              aria-current="page"
                              onClick={()=>{
                                // const mailDetailId = breadcrumb[0]?.id
                                // this.props.history.push("/mail-detail/" + mailDetailId);
                                }}
                            >
                             {personListBreadCumb[0]?.name}
                            </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                             {personListBreadCumb[1]?.name}
                         
                          </li>
                        </ol>
                      </nav>

                    {personListBaseOnGroupID.map((data, index) => {
                      return <SearchPersonBaseOnGroup 
                      handleSearchDisplayType={(searchDisplayType) => {
                        this.handleSearchDisplayType(searchDisplayType);
                      }}
                      groupIdBaseOnPersonDetail={groupIdBaseOnPersonDetail}
                      data={data} key={index} 
                      personResponse={this.state.personResponse}/>;
                    })}
                  </div>
                )}

                
{searchDisplayType == 4 && (
                  <div className="container my-2">
                    {personListBaseOnGroupID.map((data, index) => {
                      return (
                        <SearchPersonBaseonCompany
                          handleSearchDisplayType={(searchDisplayType) => {
                            this.handleSearchDisplayType(searchDisplayType);
                          }}
                          data={data}
                          key={index}
                          personResponse={this.state.personResponse}
                          groupIdBaseOnPersonDetail={groupIdBaseOnPersonDetail}
                        />
                      );
                    })}
                  </div>
                )}

                    </div>
                  </div>
                </div>
              </div>
              <FooterDesktop selectionType={3}/>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.groupListStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupListDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(GroupList)));
