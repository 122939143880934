import { Field, Formik } from "formik";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { CreateMailQuery, MailtypeQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";
import { CreateMailSchema } from "../../../util/validationSchema";
import ErrorMessageFormik from "../../CommanComponents/ErrorMessageFormik";
import BrandTypeList from "../../Event/EventListMobile/BrandTypeList";

class AddNewMail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: false,
      brandList: [],
      mailType: "",
      mailImage: "",
      mailId: 0,
      isLoading: false
    };
  }

  handleMailSelection = item => {
    const { name, icon, id } = item;
    this.setState(() => {
      return {
        ...this.state,
        status: false,
        mailType: name,
        mailImage: icon,
        mailId: id
      };
    });
  };

  closeMailDialogue = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: false
      };
    });
  };

  openMailDialogue = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true
      };
    });
  };

  handleAfterCreateApiCall = (data, name, description) => {
    toast.info(this.props.t(data.message));
    let mail = {
      id: data.id,
      name: name,
      description: description,
      list_type_icon: this.state.mailImage,
      list_type_id: this.state.mailId,
      child_element: 0
    };

    this.props.addNewMail([mail]);
    this.props.changeStatus(0);
  };

  handleAfterMailApiCall = data => {
    let brandImageDefault, brandNameDefault, mailID;
    if (data.list_type_list && data.list_type_list.langth !== 0) {
      brandNameDefault = data.list_type_list[0].name;
      brandImageDefault = data.list_type_list[0].icon;
      mailID = data.list_type_list[0].id;
    }

    this.setState(() => {
      return {
        ...this.state,
        brandList: data.list_type_list,
        mailType: brandNameDefault,
        mailImage: brandImageDefault,
        mailId: mailID
      };
    });
  };

  handleSubmit = values => {
    const { name, description } = values;
    const { mailId } = this.state;

    apiCall(
      METHOD.QUERY,
      CreateMailQuery(getUserID(), mailId, name, description),
      (data) => this.handleAfterCreateApiCall(data, name, description),
      (isLoading) =>
        this.setState(() => {
          return { ...this.state, isLoading: isLoading };
        }),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  componentDidMount() {
    apiCall(
      METHOD.QUERY,
      MailtypeQuery(getUserID(),0),
      (data) => this.handleAfterMailApiCall(data),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  render() {
    const { brandList, status, mailType, mailImage, isLoading } = this.state;
    const { changeStatus } = this.props;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <Formik
                enableReinitialize
                initialValues={{}}
                validationSchema={CreateMailSchema(
                  t("MailList.tooShort"),
                  t("MailList.tooLong"),
                  t("MailList.mailNameErrorMessage"),
                  t("MailList.descriptionErrorMessage")
                )}
                onSubmit={values => {
                  this.handleSubmit(values);
                }}>
                {({
                  errors,
                  touched,
                  values,
                  handleSubmit,
                  setErrors
                }) => (
                  <React.Fragment>
                    <div className="header-bg header-yellow sab-header1 sticky-top">
                      <div className="container">
                        <div className="py-2 d-flex align-items-center">
                          <Link to="/mail-list">
                            {" "}
                            <span
                              className="pointer"
                              onClick={() => changeStatus(0)}
                            >
                              <i className="zmdi zmdi-close zmdi-hc-fw font-30 text-light"></i>
                            </span>
                          </Link>

                          {isLoading ? (
                            <div
                              className="btn btn-light font-13 ml-auto pointer disabled"
                              onClick={handleSubmit}
                            >
                              {t("MailList.btnSave")}
                              <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                <span className="sr-only">{t("GeneralMessages.loading")}</span>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="btn btn-light font-13 ml-auto pointer"
                              onClick={handleSubmit}
                            >
                              {t("MailList.btnSave")}
                            </div>
                          )}
                        </div>
                        <div className="text-center mt-4 text-light">
                          <h2 className="m-0 text-light">
                            {t("MailList.addNewGroup")}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="middle-section sab-middle-section1">
                      <div className="container my-4 00z-index-99">
                        <form>
                          <div className="form-group max-170 m-auto text-center">
                            <label className="font-14">
                              {" "}
                              {t("MailList.type")}
                            </label>
                            <div
                              className="icon-drop-bg pointer"
                              onClick={this.openMailDialogue}
                            >
                              <div className="rounded-circle ">
                                <img
                                  src={`data:image/png;base64,${mailImage &&
                                    mailImage}`}
                                  alt="ico"
                                />
                              </div>
                              <span className="font-12">
                                {mailType && mailType}
                              </span>
                            </div>

                            <BrandTypeList
                              closeBrandDialogue={this.closeMailDialogue}
                              handleBrandSelection={this.handleMailSelection}
                              status={status}
                              BrandList={brandList}
                            />
                          </div>

                          <div className="form-group">
                            <label className="font-14">
                              {" "}
                              {t("MailList.titleInternal")}
                            </label>
                            <Field
                              type="text"
                              name="name"
                              placeholder={t("MailList.titleInternal")}
                              className="form-control"
                              required
                            />
                            <ErrorMessageFormik name="name" />
                          </div>

                          <div className="form-group">
                            <label className="font-14">
                              {" "}
                              {t("MailList.description")}
                            </label>
                            <Field
                              as="textarea"
                              name="description"
                              placeholder={t("MailList.description")}
                              className="form-control"
                              required
                            />
                            <ErrorMessageFormik name="description" />
                          </div>
                        </form>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </Formik>
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  state =>
    stateToProps.appMapStateToProps(
      [stateToProps.eventListStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.mailListDispatchToProps,
    actions.asyncApiDispatchToProps
  ])
)(withRouter(withTranslation()(AddNewMail)));
