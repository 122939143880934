import React from "react";
import MediaQuery from "react-responsive";
import GroupListMobile from "./GroupListMobile/Index";
import GroupListDesktop from "./GroupListDesktop/GroupList";

export default function GroupListResponsive() {
  return (
    <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <GroupListDesktop />
       
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <GroupListMobile />
      </MediaQuery>
    </React.Fragment>
  );
}
