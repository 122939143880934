/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Bounce from "react-reveal/Bounce";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { removegroup } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../../util/utils";

class GroupDetailsSetteliteSideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      addGroupPermission:false,
      editGroupPermission:false,
      addPersonPermission:false,
      deleteGroupPermission:false,
      editPermission: false,
    };
  }

  componentDidMount() {
    const editGroupPermission = checkUserPermission("create_group");
    const addGroupPermission = checkUserPermission("edit_group");
    const addPersonPermission = checkUserPermission("create_person");
    const deleteGroupPermission = checkUserPermission("delete_group");
    this.setState({
      addGroupPermission,
      editGroupPermission,
      addPersonPermission,
      deleteGroupPermission
    })
   
  }

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  deletegroup = () => {
    const id = this.props.match.params.group_id;
    confirmAlert({
      title: this.props.t("GroupDetailScreen.deleteGroupTitle"),
      message: this.props.t("GroupDetailScreen.deleteGroupMessage"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              removegroup(getUserID(), id),
              (data) => {
                toast.success(this.props.t(data.message));
                this.props.deleteGroup({ id });
                this.props.history.push("/group-list");
              },
              (isLoading) => {
                this.setState({ deleteLoader: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };
  render() {
    const { headerMenuStatus, closeSlider } = this.props;
    const { addGroupPermission,
      editGroupPermission,
      addPersonPermission,
      deleteGroupPermission } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <Bounce bottom when={headerMenuStatus}>
              <div
                className={
                  headerMenuStatus
                    ? "navbar-collapse collapse text-left full-toggler background-trans show"
                    : "navbar-collapse collapse text-left background-trans full-toggler"
                }
                id="Brand-toggler"
              >
                <button
                  className="navbar-toggler icon-close collapsed ml-3 mt-3"
                  onClick={closeSlider}
                  type="button"
                  data-toggle="collapse"
                  data-target="#Brand-toggler"
                  aria-controls="Brand-toggler"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="zmdi zmdi-close zmdi-hc-fw font-30"></i>
                </button>
                <ul className="navbar-nav fab-icon">
                  {this.props.groupDetail.group_list.length == 0 &&
                    addPersonPermission && (
                      <li className="nav-item">
                        <a
                          href="javascript;:"
                          className="float-right mr-3"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.changeRouteStatus(3);
                          }}
                        >
                          <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                            {t("GroupDetailScreen.addPerson")}
                          </p>
                          <div className="float-left rounded-40 blue-bg text-center">
                            <img
                              className="m-0"
                              src="/images/meldung-neu.png"
                              alt="ico"
                            />
                          </div>
                        </a>
                      </li>
                    )}
                  {addGroupPermission && (
                    <li className="nav-item">
                      <a
                        href="javascript;:"
                        className="float-right mr-3"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.changeRouteStatus(1);
                        }}
                      >
                        <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                          {t("GroupDetailScreen.addsubGroup")}
                        </p>
                        <div className="float-left rounded-40 blue-bg text-center">
                          <img
                            className="m-0"
                            src="/images/meldung-neu.png"
                            alt="ico"
                          />
                        </div>
                      </a>
                    </li>
                  )}

                  {editGroupPermission && (
                    <li className="nav-item">
                      <a
                        href="javascript;:"
                        className="float-right mr-3"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.changeRouteStatus(2);
                        }}
                      >
                        <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                          {t("GroupDetailScreen.editGroup")}
                        </p>
                        <div className="float-left rounded-40 blue-bg text-center">
                          <img
                            className="m-0"
                            src="/images/meldung-neu.png"
                            alt="ico"
                          />
                        </div>
                      </a>
                    </li>
                  )}

{deleteGroupPermission && <li className="nav-item">
                    <a
                      href="javascript;:"
                      className="float-right mr-3"
                      onClick={(e) => {
                        e.preventDefault();
                        this.deletegroup();
                      }}
                    >
                      <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                        {t("GroupDetailScreen.deleteGroupTitle")}
                      </p>
                      <div className="float-left rounded-40 blue-bg text-center">
                        <img
                          className="m-0"
                          src="/images/meldung-neu.png"
                          alt="ico"
                        />
                      </div>
                    </a>
                  </li>
}
                  
                  <li className="nav-item ">
                    <a
                      href="javascript;:"
                      className="float-right mr-3 pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.changeRouteStatus(4);
                      }}
                    >
                      <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                        {t("GroupListScreen.setpriority")}
                      </p>
                      <div className="float-left rounded-40 blue-bg text-center">
                        <img
                          className="m-0"
                          src="/images/brand-icon-w.png"
                          alt="ico"
                        />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </Bounce>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.groupDetailStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.deleteEventReportDispatchToProps,
    actions.groupListDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(GroupDetailsSetteliteSideMenu)));
