/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import Bounce from "react-reveal/Bounce";

class FieldTypeList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldList: [],
    };
  }

  removeItemFromfieldList = (itemId) => {
    let updatedFieldList = this.state.fieldList.filter((item) => {
      return item.id != itemId;
    });

    this.setState({
      fieldList: updatedFieldList,
    });
  };

  handleAddClick = (item) => {
    this.removeItemFromfieldList(item.id);

    item["value"] = "";
    item["is_default_custom"] = 1;
    item["is_default_field"] = 1;

    this.props.addValue(item);
    this.props.handleFieldDialogStatus(false);
  };
  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };
  componentDidMount() {
    this.setState({
      fieldList: this.props.fieldList,
    });
  }

  render() {
    const { handleFieldDialogStatus } = this.props;
    const { fieldList } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="modal-custom-footer" style={{display:'block'}}>
            <div className="modal-inner-content">
                <div className="card-header d-flex justify-content-between pb-0">
                    <a href="javascript;:" onClick={(e)=>{
                      e.preventDefault()
                     handleFieldDialogStatus(false)
                    }}><i className="zmdi zmdi-close zmdi-hc-2x"></i></a>

                      {t("addNewPerson.fieldTypeLable")}
                </div>
           
                <div className="card-body pt-3">
                <div className="modal-dialog 0modal-dialog-scrollable 0modal-dialog-centered">
                  <div className="modal-content">
                 
                    <div className="modal-body">
                      <div className="text-left">
                        <ul className="navbar-nav mr-auto">
                          {fieldList &&
                            fieldList.map((item, index) => {
                              const {
                                id,
                                name,
                                description,
                                field_type,
                                is_default_field,
                              } = item;
                              return (
                                <React.Fragment key={index}>
                                  {is_default_field == 0 &&
                                    field_type == "text" && (
                                      <div className="form-group">
                                        <div className="d-flex align-items-center justify-content-between mb-1">
                                          <label className="font-14">
                                            {name && name}
                                          </label>
                                          <button
                                            type="button"
                                            className="btn btn-secondary btn-sm btn-small"
                                            onClick={() =>
                                              this.handleAddClick(item)
                                            }
                                          >
                                            <i className="zmdi zmdi-plus zmdi-hc-fw"></i>
                                          </button>
                                        </div>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id={id}
                                          disabled
                                        />
                                      </div>
                                    )}


{is_default_field == 0 &&
                                    field_type == "html" && (
                                      <div className="form-group">
                                        <div className="d-flex align-items-center justify-content-between mb-1">
                                          <label className="font-14">
                                            {name && name}
                                          </label>
                                          <button
                                            type="button"
                                            className="btn btn-secondary btn-sm btn-small"
                                            onClick={() =>
                                              this.handleAddClick(item)
                                            }
                                          >
                                            <i className="zmdi zmdi-plus zmdi-hc-fw"></i>
                                          </button>
                                        </div>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id={id}
                                          disabled
                                        />
                                      </div>
                                    )}

                                  {is_default_field == 0 &&
                                    field_type == "email" && (
                                      <div className="form-group">
                                        <div className="d-flex align-items-center justify-content-between mb-1">
                                          <label className="font-14">
                                            {name && name}
                                          </label>
                                          <button
                                            type="button"
                                            className="btn btn-secondary btn-sm btn-small"
                                            onClick={() =>
                                              this.handleAddClick(item)
                                            }
                                          >
                                            <i className="zmdi zmdi-plus zmdi-hc-fw"></i>
                                          </button>
                                        </div>
                                        <input
                                          type="email"
                                          className="form-control"
                                          id={id}
                                          disabled
                                        />
                                      </div>
                                    )}

                                  {is_default_field == 0 &&
                                    field_type == "text_multiline" && (
                                      <div className="form-group">
                                        <div className="d-flex align-items-center justify-content-between mb-1">
                                          <label className="font-14">
                                            {name && name}
                                          </label>
                                          <button
                                            type="button"
                                            className="btn btn-secondary btn-sm btn-small"
                                            onClick={() =>
                                              this.handleAddClick(item)
                                            }
                                          >
                                            <i className="zmdi zmdi-plus zmdi-hc-fw"></i>
                                          </button>
                                        </div>

                                        <input
                                          type="textarea"
                                          className="form-control"
                                          id={id}
                                          disabled
                                        />
                                      </div>
                                    )}

                                  {is_default_field == 0 &&
                                    field_type == "checkbox" && (
                                      <div className="form-group">
                                        <input
                                          type="checkbox"
                                          id="vehicle1"
                                          className="custom-checkbox"
                                          name={name && name}
                                        />
                                        <div className="d-flex align-items-center justify-content-between mb-1">
                                          <label className="font-14">
                                            {description && description}
                                          </label>
                                          <button
                                            type="button"
                                            className="btn btn-secondary btn-sm btn-small"
                                            onClick={() =>
                                              this.handleAddClick(item)
                                            }
                                          >
                                            <i className="zmdi zmdi-plus zmdi-hc-fw"></i>
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                </React.Fragment>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(FieldTypeList);
