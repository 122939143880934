import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { UpdatePersonStatus } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";

class ChangePersonStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      message: "",
    };
  }

  handleAfterApiCall = (data) => {
    this.setState({
      message: data.message,
    });
  };

  componentDidMount() {
    let reset_token = this.props.history.location.hash;
    reset_token = reset_token.replace("#", "");

    apiCall(
      METHOD.QUERY,
      UpdatePersonStatus(reset_token),
      (data) => this.handleAfterApiCall(data),
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  handleLoader = (status) => {
    this.setState(() => {
      return {
        ...this.state,
        isLoading: status,
      };
    });
  };

  render() {
    const { message } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative overflow-auto">
              <div className="pt-5 pb-3 d-block text-center">
                <img
                  src="/images/login_logo.png"
                  alt="loginLogo"
                  style={{ width: "150px", height: "150px" }}
                ></img>
              </div>

              <div className="container login-container">
                <span> {t(message && message)}</span>
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(ChangePersonStatus));
