/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { GroupDetailPersonListQuery,grouppersonlistNew } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";
import FabIcon from "../../CommanComponents/FabIcon";
import LoadingPage from "../../CommanComponents/LoadingPage";
import NoRecordFound from "../../CommanComponents/NoRecordFound";
import Footer from "../../Footer/FooterMobile";
import SearchPersonBaseonCompany from "../../Group/GroupListDesktop/SearchPersonBaseonCompany";
import SearchGroupListBaseOnPerson from "../../Group/GroupListMobile/SearchGroupListBaseOnPerson";
import SearchPersonBaseOnGroup from "../../Group/GroupListMobile/SearchPersonBaseOnGroup";
import SearchPersonResult from "../../Group/GroupListMobile/SearchPersonResult";
import GroupDetails from "../../GroupDetail/GroupDetailMobile/GroupDetails";
import GroupItem from "./GroupItem";
import PersonItem from "./PersonItem";
import PersonListSetteliteSideMenu from "./PersonListSetteliteSideMenu";



class PersonList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      activeIndex: 0,
      headerMenuStatus: false,
      searchPersonList:[],
      hasMore: true,
      end: 10,
      child_group_id: 0,
      start: 0,
      sortType: 1,
      child_group_list: [],
      personListForGroup: [],
      searchDisplayType: 0,
      groupListBaseOnPerson: [],
      personListBaseOnGroupID:[],
      personListBreadCumb:[],
      selectedPersonId:0,
      groupIdBaseOnPersonDetail:0,
      group_list:[],
      person_list:[]
    };
  }
  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  setSearchGroupList = (groupList, personList) => {
    this.setState({
      searchGroupList: groupList,
      searchPersonList: personList,
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  componentDidMount() {
    const group_id = this.props.match.params.group_id;
    this.setState({
      child_group_id: group_id,
    });
    this.loadGroupPersonList(group_id)
  }

  handleAfterApiCall = (data) => {
    this.setState(() => {
      return {
        ...this.state,
        groupList: data.group_list,
      };
    });
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  handleChangeGroup = (group_id, person) => {
    this.props.clearStateGroupDetail({});
    this.props.clearStateGroupPersonDetail({});
    if (person == 0) {
      this.props.history.push("/group-detail/" + group_id);
    } else {
      this.props.history.push("/group-personlist/" + group_id);
    }
  };

  changeSortType = (sortType) => {
    this.setState(
      {
        sortType: sortType,
      },
      () => {
        this.props.clearStateGroupPersonDetail();
      }
    );
  };


  
  handlePersonListBaseonCompany = (personListBaseOnGroupID)=>{
    this.setState({
      personListBaseOnGroupID,
      searchDisplayType:4
    })
  }
  
  loadGroupPersonList = (group_id) => {
    console.log("this.props.filterValue",this.props.filterValue);
    apiCall(
      METHOD.QUERY,
      grouppersonlistNew(getUserID(), group_id,this.props.personDataTypeId,this.props.filterValue),
      (data) => {
        this.setState({
          group_list: data.group_list,
          breadcrumb:data.breadcrumb,
          person_list:data.person_list,
          data,
        });
        // this.props.getMailDetails(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };


  // loadItem = () => {
  //   const child_id = this.props.match.params.group_id;
  //   const { end, isLoading, sortType } = this.state;

  //   if (!isLoading && this.props.groupDetailPersonList.hasMore) {
  //     this.setState({
  //       isLoading: true,
  //     });

  //     apiCall(
  //       METHOD.QUERY,
  //       GroupDetailPersonListQuery(
  //         getUserID(),
  //         child_id,
  //         this.props.groupDetailPersonList.start,
  //         end,
  //         sortType
  //       ),
  //       (data) => {
  //         this.setState({ child_group_list: data.group_list });
  //         if (this.props.groupDetailPersonList.id) {
  //           this.props.updatePersonList(data);
  //         } else {
  //           this.props.getPersonList(data);
  //         }

  //         this.handleGroupDetailApiResponse(data);
  //       },
  //       (isLoading) => {},
  //       (data) => {
  //         toast.error(this.props.t(data.message));
  //       }
  //     );
  //   }
  // };

  loader = (<LoadingPage key={0} />);

  handleGroupDetailApiResponse = (data) => {
    const { end } = this.state;
    this.setState({
      isLoading: true,
    });

    if (data.is_last == 1) {
      this.props.setPersonListHasMoreValue(false);

      this.setState(() => {
        return {
          ...this.state,
          hasMore: false,
        };
      });
    }

    this.props.setPersonListStartValue(
      this.props.groupDetailPersonList.start + end
    );
    this.setState((prevState) => {
      return {
        isLoading: false,
        start: prevState.start + end,
      };
    });
  };

  handleActiveIndex = (group_id, id) => {
    if (group_id == id) {
      this.handleScroll(group_id);
      return "active";
    } else {
      return "";
    }
  };

  handleScroll = (id) => {
    const index = this.props.groupDetailPersonList.group_list.findIndex(
      (group) => group.id == id
    );

    if (index > 3) {
      setTimeout(function () {
        var element = document.getElementById(`li_${id}`);
        var element1 = document.getElementById("ulID");
        var elementPosition = element.getBoundingClientRect();
        element1.scrollLeft = elementPosition.left;
      }, 500);
    }
  };




  
// search methods


setGroupFromPerson=(groupListBaseOnPerson,selectedPersonId)=>{
  console.log("groupListBaseOnPerson",groupListBaseOnPerson);
  console.log("selectedPersonId",selectedPersonId);
  if(groupListBaseOnPerson.length!=0){
    this.setState({
      groupListBaseOnPerson,
      searchDisplayType:2,
      selectedPersonId
    })
  }else{
    toast.info(
      this.props.t("GeneralMessages.noMailFound") );
  }

}

handleGroupWiseResponse=(personListBaseOnGroupID,personResponse,personListBreadCumb)=>{
  this.setState({
    personListBaseOnGroupID,
    personListBreadCumb,
    personResponse,
    searchDisplayType:3
  })
}


handleDisplayType =()=>{
  this.setState({
    searchDisplayType:0
  })
}

setSearchGroupList = (personList) => {
  if (personList.length == 0) {
  }
  this.setState({
    searchPersonList: personList,
    searchDisplayType: personList.length == 0 ? 0 : 1,
  });
};

handleSearchDisplayTypeToInitialStage = () => {
  this.setState({
    searchDisplayType:0,
    search:'',
    value:''

  });
};
handleSearchDisplayType  = (searchDisplayType ) => {
  this.setState({
    searchDisplayType,
    search:'',
    value:''
  });
};

handleGroupbaseOnPerson = (mailList) => {
  if (mailList.length == 0) {
    this.setState({
      groupListBaseOnPerson: [],
      searchPersonList: [],
      // searchDisplayType: 0,
    });
  } else {
    this.setState({
      // searchDisplayType: 0,
      groupListBaseOnPerson: mailList,
    });
  }
};
  render() {
    const {filterSortType} = this.props

    const { end, sortType, isLoading, child_group_list,searchPersonList,
      group_list,breadcrumb,person_list,data,
      personListForGroup,
      searchDisplayType,groupListBaseOnPerson,selectedPersonId,personListBreadCumb,personListBaseOnGroupID,
      groupIdBaseOnPersonDetail }
       = this.state;
    const {
      name,
      group_type_icon,
      person,
    } = this.props.groupDetailPersonList && this.props.groupDetailPersonList;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <>
              <GroupDetails
                group_header_list={child_group_list}
                current_id={this.props.groupDetailPersonList.id}
                searchArray={this.setSearchGroupList}
                isFromPersonList={true}
                GroupDetailPersonCount ={data?.person}
                setGroupFromPerson={(personList,selectedPersonId)=>{this.setGroupFromPerson(personList,selectedPersonId)}}
                searchArray={this.setSearchGroupList}
                handleSearchDisplayType={(searchDisplayType) => {
                  this.handleSearchDisplayType(searchDisplayType);
                }}
                handlePersonListBaseonCompany={(personList)=>this.handlePersonListBaseonCompany(personList)}

              />





               {searchDisplayType==0 && <React.Fragment>
                <div className="middle-section sab-middle-section1">
                <div className="container my-4 z-index-99">
                
                <div className="d-flex align-items-center justify-content-between ">
                        <div className="clearfix d-flex0 0align-items-center">
                          <p className="font-12 m-0">
                            <React.Fragment>
                              <Link
                                to="/group-list"
                                className="text-muted"
                                data-tip={"Start"}
                              >
                                {t("GroupListScreen.start")}
                                <ReactTooltip
                                  place="top"
                                  type="info"
                                  effect="float"
                                />
                              </Link>
                              {" > "}
                            </React.Fragment>
                            {breadcrumb &&
                              breadcrumb.map((item, index) => {
                                if (index == 0 && breadcrumb.length != 1) {
                                  return (
                                    <React.Fragment key={index}>
                                      <Link
                                        to={"/group-detail/" + item.id}
                                        className="text-muted"
                                        data-tip={item.name}
                                      >
                                        <ReactTooltip
                                          place="top"
                                          type="info"
                                          effect="float"
                                        />
                                        {item.name}
                                      </Link>
                                      {" > "}
                                    </React.Fragment>
                                  );
                                } else if (
                                  index != 0 &&
                                  index != breadcrumb.length - 1
                                ) {
                                  return (
                                    <React.Fragment key={index}>
                                      <Link
                                        to={"/group-detail/" + item.id}
                                        className="text-muted"
                                        data-tip={item.name}
                                      >
                                        <ReactTooltip
                                          place="top"
                                          type="info"
                                          effect="float"
                                        />
                                        [...]
                                      </Link>
                                      {" > "}
                                    </React.Fragment>
                                  );
                                } else if (
                                  index == breadcrumb.length - 1 ||
                                  breadcrumb.length == 1
                                ) {
                                  return (
                                    <React.Fragment key={index}>
                                      {data.name || ''}
                                    </React.Fragment>
                                  );
                                }
                              })}
                          </p>
                        </div>
                     
                        <ul className="list-inline grid-view-list m-0">
                      <li className="list-inline-item mr-3">
                        <a className="pointer" href="javascript;:" onClick={(e)=>{
                          e.preventDefault();
                          this.props.changeRouteStatus(4)}}>
                          <i className="fa fa-filter font-22 text-dark"></i>
                        </a>
                      </li>
                    </ul>
                      </div>
                </div>
                {/* <InfiniteScroll
                      pageStart={end}
                      loadMore={this.loadItem}
                      hasMore={this.props.groupDetailPersonList.hasMore}
                      useWindow={false}
                      loader={this.loader}
                    >
                      <div className="container my-4 mr-4">
                        {!!person_list.length &&
                          person_list.map((data, index) => {
                            return (
                              <PersonItem data={data} key={index} index={index} />
                            );
                          })}
    
                        {!isLoading && person_list.length == 0 && (
                          <NoRecordFound title="Person List" name="person list" />
                        )}
                      </div>
                    </InfiniteScroll> */}
          <div className="container my-4 z-index-99">
                  {/* {group_list &&
                    group_list.map((item, index) => {
                      return (
                        <GroupItem
                          delete_group_item={this.delete_group_item}
                          updatePersonCount={this.updatePersonCount}
                          key={index}
                          item={item}
                          index={index}
                        />
                      );
                    })} */}

                 
                  {person_list &&
                    person_list.map((data, index) => {
                      return ( <PersonItem data={data} index={index}/>
                       );
                    })}
             </div>
                <FabIcon color="rounded-blue" openSlider={this.openSlider} />
              </div>
              <PersonListSetteliteSideMenu
                closeSlider={this.closeSlider}
                headerMenuStatus={this.state.headerMenuStatus}
                changeRouteStatus={this.props.changeRouteStatus}
              />
              <Footer type="3" />
                 </React.Fragment>}
              
                 {searchDisplayType!=0 && <div className="middle-section sab-middle-section1">
                      
                 {searchDisplayType == 1 && (
                 <SearchPersonResult 
                      suggetionList={this.state.searchPersonList}
                      handlePersonListBaseonCompany={(personList)=>this.handlePersonListBaseonCompany(personList)}
                      setGroupFromPerson={(personList,selectedPersonId)=>{this.setGroupFromPerson(personList,selectedPersonId)}}/>
                )}

            {searchDisplayType == 2 && (
                  <div className="container my-2">
                    {groupListBaseOnPerson.map((data, index) => {
                      return <SearchGroupListBaseOnPerson data={data} key={index} 
                      selectedPersonId={selectedPersonId}
                      handleGroupWiseResponse={(personListBaseOnGroupID,personResponse,personListBreadCumb,group_id)=>{this.handleGroupWiseResponse(personListBaseOnGroupID,personResponse,personListBreadCumb,group_id)}}/>;
                    })}
                  </div>
                )}

              {searchDisplayType == 3 && (
                  <div className="container my-2">
                       <nav aria-label="breadcrumb">
                        <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item">
                            {t("mailDetail.home")}
                          </li>
                          <li
                              className="breadcrumb-item pointer"
                              aria-current="page"
                              onClick={()=>{
                                // const mailDetailId = breadcrumb[0]?.id
                                // this.props.history.push("/mail-detail/" + mailDetailId);
                                }}
                            >
                             {personListBreadCumb[0]?.name}
                            </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                             {personListBreadCumb[1]?.name}
                         
                          </li>
                        </ol>
                      </nav>

                    {personListBaseOnGroupID.map((data, index) => {
                      return <SearchPersonBaseOnGroup
                      handleSearchDisplayType={(searchDisplayType) => {
                        this.handleSearchDisplayType(searchDisplayType);
                      }}
                      groupIdBaseOnPersonDetail={groupIdBaseOnPersonDetail}
                      data={data} key={index} 
                      personResponse={this.state.personResponse}/>;
                    })}
                  </div>
                )}

                
{searchDisplayType == 4 && (
                  <div className="container my-2">
                    {personListBaseOnGroupID.map((data, index) => {
                      return (
                        <SearchPersonBaseonCompany
                          handleSearchDisplayType={(searchDisplayType) => {
                            this.handleSearchDisplayType(searchDisplayType);
                          }}
                          data={data}
                          key={index}
                          personResponse={this.state.personResponse}
                          groupIdBaseOnPersonDetail={groupIdBaseOnPersonDetail}
                        />
                      );
                    })}
                  </div>
                )}


                    </div>}
                 


            </>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.groupDetailStateToProps,
        stateToProps.groupDetailPersonListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupDetailDispatchToProps,
    actions.groupDetailPersonListDispatchToProps,
  ])
)(withRouter(withTranslation()(PersonList)));
