/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import {
  getmailinglistfromperson,
  MaillingListQuery,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import {
  getUserID,
  getValueFromLocalStorage,
  saveDatatoLocalStorage,
} from "../../../util/utils";
import FabIcon from "../../CommanComponents/FabIcon";
import Footer from "../../Footer/FooterMobile";
import LoadingPage from "../../CommanComponents/LoadingPage";
import LogoutMenu from "../../CommanComponents/LogoutMenu";
import TopHeader from "../../CommanComponents/TopHeader";
import MailingItemGridView from "./MailingItemGridView";
import MailingItemListView from "./MailingItemListView";
import MailSetteliteSideMenu from "./MailSetteliteSideMenu";
import SearchMail from "./SearchMail";
import SearchPersonResult from "./SearchPersonResult";
import SearchPersonBaseonCompany from "./SearchPersonBaseonCompany";
import SearchMailListBaseOnPerson from "./SearchMailListBaseOnPerson";
import SearchPersonBaseOnMail from "./SearchPersonBaseOnMail";

class MailingList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      mailList: [],
      end: 10,
      headerMenuStatus: false,
      loader: false,
      displayType: 0,
      searchDisplayType: 0,
      searchPersonList: [],
      mailListBaseOnPerson: [],
      personListBaseOnMailID: [],
      personListBreadCumb: [],
      selectedPersonId: 0,
      groupIdBaseOnPersonDetail:0
    };
    this.searchRef = React.createRef();
  }

  handleLoader = (status) => {
    this.setState({
      loader: status,
    });
  };

  loader = (<LoadingPage key={0} />);

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  loadItem = () => {
    const { end, loader } = this.state;
    if (!loader && this.props.mailList.hasMore) {
      this.setState({
        loader: true,
      });

      apiCall(
        METHOD.QUERY,
        MaillingListQuery(getUserID(), this.props.mailList.start, end, ""),
        (data) => {
          this.props.getMailList(data.mailing_list);
          this.handleMailApiResponse(data);
        },
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
          this.props.getMailHasMoreValue(false);
          this.setState({
            loader: false,
          });
        }
      );
    }
  };

  handleMailApiResponse = (data) => {
    if (data.is_last === 1) {
      this.props.getMailHasMoreValue(false);
    }
    this.props.getMailStartValue(this.props.mailList.start + this.state.end);

    this.setState({
      loader: false,
    });
  };

  handleDisplayType = () => {
    this.setState({
      searchDisplayType: 0,
    });
  };

  setSearchMailList = (personList) => {
    if (personList.length == 0) {
    }
    this.setState({
      searchPersonList: personList,
      searchDisplayType: personList.length == 0 ? 0 : 1,
    });
  };

  handleSearchDisplayTypeToInitialStage = () => {
    this.setState({
      searchDisplayType: 0,
      search: "",
      value: "",
    });
  };
  handleSearchDisplayType = (searchDisplayType) => {
    this.setState({
      searchDisplayType,
      search: "",
      value: "",
    });
  };

  handlePersonListBaseonCompany = (personListBaseOnMailID)=>{
    this.setState({
      personListBaseOnMailID,
      searchDisplayType:4
    })
  }
  handleMailbaseOnPerson = (mailList) => {
    if (mailList.length == 0) {
      this.setState({
        mailListBaseOnPerson: [],
        searchPersonList: [],
        // searchDisplayType: 0,
      });
    } else {
      this.setState({
        // searchDisplayType: 0,
        mailListBaseOnPerson: mailList,
      });
    }
  };
  getMailPersonList = (personId) => {
    apiCall(
      METHOD.QUERY,
      getmailinglistfromperson(getUserID(), personId),
      (data) => this.handleGetMailPersonResponse(data),
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleGetMailPersonResponse = (data) => {
    console.log("data", data.person_list);
    // this.props.handleMailbaseOnPerson(data.mailing_list)
  };

  setmailFromPerson = (mailListBaseOnPerson, selectedPersonId) => {
    console.log("mailListBaseOnPerson", mailListBaseOnPerson);
    console.log("selectedPersonId", selectedPersonId);
    if (mailListBaseOnPerson.length != 0) {
      this.setState({
        mailListBaseOnPerson,
        searchDisplayType: 2,
        selectedPersonId,
      });
    } else {
      toast.info(this.props.t("GeneralMessages.noMailFound"));
    }
  };


  componentDidMount() {
    const mailDisplayType = getValueFromLocalStorage("mailDisplayType");
    if (mailDisplayType == 1) {
      this.setState({
        displayType: 1,
      });
    } else {
      this.setState({
        displayType: 0,
      });
    }

    this.setState(
      {
        hasMore: true,
        start: 0,
        mailList: [],
        isLoading: false,
      },
      () => {
        this.loadItem();
      }
    );
  }

  // Search method

  handleDisplayType = () => {
    this.setState({
      searchDisplayType: 0,
    });
  };

  setSearchMailList = (personList) => {
    if (personList.length == 0) {
    }
    this.setState({
      searchPersonList: personList,
      searchDisplayType: personList.length == 0 ? 0 : 1,
    });
  };

  handleSearchDisplayTypeToInitialStage = () => {
    this.setState({
      searchDisplayType: 0,
      search: "",
      value: "",
    });
  };
  handleSearchDisplayType = (searchDisplayType) => {
    this.setState({
      searchDisplayType,
      search: "",
      value: "",
    });
  };

  handleMailbaseOnPerson = (mailList) => {
    if (mailList.length == 0) {
      this.setState({
        mailListBaseOnPerson: [],
        searchPersonList: [],
        // searchDisplayType: 0,
      });
    } else {
      this.setState({
        // searchDisplayType: 0,
        mailListBaseOnPerson: mailList,
      });
    }
  };

  setmailFromPerson = (mailListBaseOnPerson, selectedPersonId) => {
    console.log("mailListBaseOnPerson", mailListBaseOnPerson);
    console.log("selectedPersonId", selectedPersonId);
    if (mailListBaseOnPerson.length != 0) {
      this.setState({
        mailListBaseOnPerson,
        searchDisplayType: 2,
        selectedPersonId,
      });
    } else {
      toast.info(this.props.t("GeneralMessages.noMailFound"));
    }
  };

  handleMailWiseResponse = (
    personListBaseOnMailID,
    personResponse,
    personListBreadCumb,
    groupIdBaseOnPersonDetail
  ) => {
    this.setState({
      personListBaseOnMailID,
      personListBreadCumb,
      personResponse,
      searchDisplayType: 3,
      groupIdBaseOnPersonDetail
    });
  };

  


  render() {
    const {
      end,
      mailList,
      displayType,
      searchPersonList,
      searchDisplayType,
      mailListBaseOnPerson,
      personListBaseOnMailID,
      personListBreadCumb,
      selectedPersonId,
      groupIdBaseOnPersonDetail
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-yellow sab-header1 sticky-top d-flex flex-column">
                <div className="container d-flex flex-column flex-fill">
                  <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                    <TopHeader />
                    <p className="mb-0 ml-auto text-light">
                      {t("MailList.title")}
                    </p>
                    <LogoutMenu />
                  </div>
                  <SearchMail
                    handleMailbaseOnPerson={(mailList) => {
                      this.handleMailbaseOnPerson(mailList);
                    }}
                    handlePersonListBaseonCompany={(personList)=>{
                      this.handlePersonListBaseonCompany(personList)
                    }}

                    setmailFromPerson={(personList, selectedPersonId) => {
                      this.setmailFromPerson(personList, selectedPersonId);
                    }}
                    searchArray={this.setSearchMailList}
                    handleSearchDisplayType={(searchDisplayType) => {
                      this.handleSearchDisplayType(searchDisplayType);
                    }}
                  />
                </div>
              </div>

              <div className="middle-section sab-middle-section1 ">
                {searchDisplayType == 0 && (
                  <div className="d-flex  m-3 float-right">
                    <ul className="list-inline grid-view-list m-0">
                      <li
                        className={
                          displayType == 0
                            ? "list-inline-item mr-3 active"
                            : "list-inline-item mr-3"
                        }
                      >
                        <a
                          className="pointer"
                          href="javascript;:"
                          onClick={(e) => {
                            e.preventDefault();

                            saveDatatoLocalStorage("mailDisplayType", 0);
                            this.setState({
                              displayType: 0,
                            });
                          }}
                        >
                          <i className="fa fa-list fa-lg text-muted"></i>
                        </a>
                      </li>
                      <li
                        className={
                          displayType == 1
                            ? "list-inline-item mr-3 active"
                            : "list-inline-item mr-3"
                        }
                      >
                        <a
                          className="pointer"
                          href="javascript;:"
                          onClick={(e) => {
                            e.preventDefault();
                            saveDatatoLocalStorage("mailDisplayType", 1);
                            this.setState({
                              displayType: 1,
                            });
                          }}
                        >
                          <i className="fa fa-th-large fa-lg text-muted"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                )}

                {searchDisplayType == 0 && (
                  <InfiniteScroll
                    pageStart={end}
                    loadMore={this.loadItem}
                    hasMore={this.props.mailList.hasMore}
                    useWindow={false}
                    loader={this.loader}
                  >
                    {displayType === 0 ? (
                      <div className="container my-3">
                        {this.props.mailList &&
                          this.props.mailList.mailList.map((data, index) => {
                            return (
                              <MailingItemListView
                                data={data}
                                key={index}
                                index={index}
                              />
                            );
                          })}
                      </div>
                    ) : (
                      <div className="form-row container my-3">
                        {this.props.mailList &&
                          this.props.mailList.mailList.map((data, index) => {
                            return (
                              <MailingItemGridView
                                data={data}
                                key={index}
                                index={index}
                              />
                            );
                          })}
                      </div>
                    )}
                  </InfiniteScroll>
                )}


{searchDisplayType == 1 && (
                 <SearchPersonResult 
                      suggetionList={this.state.searchPersonList}
                      handlePersonListBaseonCompany={(personList)=>{
                        this.handlePersonListBaseonCompany(personList)
                      }}
                      setmailFromPerson={(personList, selectedPersonId) => {
                        this.setmailFromPerson(
                          personList,
                          selectedPersonId
                        );
                      }}/>
                )}


                {searchDisplayType == 2 && (
                  <div className="container my-2">
                    {mailListBaseOnPerson.map((data, index) => {
                      return (
                        <SearchMailListBaseOnPerson
                          data={data}
                          key={index}
                          selectedPersonId={selectedPersonId}
                          handleMailWiseResponse={(
                            personListBaseOnMailID,
                            personResponse,
                            personListBreadCumb,
                            groupIdBaseOnPersonDetail
                          ) => {
                            this.handleMailWiseResponse(
                              personListBaseOnMailID,
                              personResponse,
                              personListBreadCumb,
                              groupIdBaseOnPersonDetail
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                )}

                {searchDisplayType == 3 && (
                  <div className="container my-2">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          {t("mailDetail.home")}
                        </li>
                        <li
                          className="breadcrumb-item pointer"
                          aria-current="page"
                          onClick={() => {
                            // const mailDetailId = breadcrumb[0]?.id
                            // this.props.history.push("/mail-detail/" + mailDetailId);
                          }}
                        >
                          {personListBreadCumb[0]?.name}
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          {personListBreadCumb[1]?.name}
                        </li>
                      </ol>
                    </nav>

                    {personListBaseOnMailID.map((data, index) => {
                      return (
                        <SearchPersonBaseOnMail
                          handleSearchDisplayType={(searchDisplayType) => {
                            this.handleSearchDisplayType(searchDisplayType);
                          }}
                          data={data}
                          key={index}
                          personResponse={this.state.personResponse}
                          groupIdBaseOnPersonDetail={groupIdBaseOnPersonDetail}
                        />
                      );
                    })}
                  </div>
                )}


{searchDisplayType == 4 && (
                  <div className="container my-2">
                    {personListBaseOnMailID.map((data, index) => {
                      return (
                        <SearchPersonBaseonCompany
                          handleSearchDisplayType={(searchDisplayType) => {
                            this.handleSearchDisplayType(searchDisplayType);
                          }}
                          data={data}
                          key={index}
                          personResponse={this.state.personResponse}
                          groupIdBaseOnPersonDetail={groupIdBaseOnPersonDetail}
                        />
                      );
                    })}
                  </div>
                )}

                  {/* {searchDisplayType ==4 } */}
                <MailSetteliteSideMenu
                  closeSlider={this.closeSlider}
                  headerMenuStatus={this.state.headerMenuStatus}
                  changeStatus={this.props.changeStatus}
                />
                <FabIcon color="rounded-yellow" openSlider={this.openSlider} />
              </div>
              <Footer type="4" />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps([stateToProps.mailListStateToProps], state),
  actions.appMapDispatchToProps([
    actions.mailListDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(MailingList)));
