import React from "react";
import MediaQuery from "react-responsive";
import MailDetailMobile from "./MailDetailMobile/Index";
import MailDetailDesktop from "./MailDetailDesktop/MailDetails";

export default function MailDetailResponsive() {
  return (
    <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <MailDetailDesktop />
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <MailDetailMobile />
      </MediaQuery>
    </React.Fragment>
  );
}
