/* eslint eqeqeq: 0 */
import { Field, Formik } from "formik";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { UpdateMailQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";
import { CreateMailSchema } from "../../../util/validationSchema";
import ErrorMessageFormik from "../../CommanComponents/ErrorMessageFormik";

class EditMail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: false,
      name: "",
      description: "",
      mailType: "",
      mailImage: "",
      mailId: 0,
      isLoading: false,
    };
  }

  handleAfterCreateApiCall = (data) => {
    toast.info(this.props.t(data.message));
    this.props.changeRouteStatus(0);
  };

  handleSubmit = (values) => {
    const { name, description } = values;
    const { mailId } = this.state;

    apiCall(
      METHOD.QUERY,
      UpdateMailQuery(
        getUserID(),
        this.props.mailDetail.id,
        mailId,
        name,
        description
      ),
      (data) => this.handleAfterCreateApiCall(data),
      (isLoading) => {
        this.setState({ isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  componentDidMount() {
    const {
      name,
      description,
      list_type_id,
      list_type_icon,
      list_type_name,
    } = this.props.mailDetail;

    this.setState(() => {
      return {
        mailType: list_type_name,
        mailImage: list_type_icon,
        mailId: list_type_id,
        name,
        description,
      };
    });
  }

  render() {
    const { name, description, mailType, mailImage, isLoading } = this.state;
    const { changeRouteStatus } = this.props;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <Formik
                enableReinitialize
                initialValues={{
                  name: name,
                  description: description,
                }}
                validationSchema={CreateMailSchema(
                  t("MailList.tooShort"),
                  t("MailList.tooLong"),
                  t("MailList.mailNameErrorMessage"),
                  t("MailList.descriptionErrorMessage")
                )}
                onSubmit={(values) => {
                  this.handleSubmit(values);
                }}
              >
                {({ errors, touched, values, handleSubmit, setErrors }) => (
                  <React.Fragment>
                    <div className="header-bg header-yellow sab-header1 sticky-top">
                      <div className="container">
                        <div className="py-2 d-flex align-items-center">
                          <span
                            className="pointer"
                            onClick={() => changeRouteStatus(0)}
                          >
                            <i className="zmdi zmdi-close zmdi-hc-fw font-30 text-light"></i>
                          </span>

                          {isLoading ? (
                            <div
                              className="btn btn-light font-13 ml-auto pointer disabled"
                              onClick={handleSubmit}
                            >
                              {t("MailList.btnSave")}
                              <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                <span className="sr-only">
                                  {t("GeneralMessages.loading")}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="btn btn-light font-13 ml-auto pointer"
                              onClick={handleSubmit}
                            >
                              {t("MailList.btnSave")}
                            </div>
                          )}
                        </div>
                        <div className="text-center mt-4 text-light">
                          <h2 className="m-0 text-light">
                            {t("MailList.editMail")}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="middle-section sab-middle-section1">
                      <div className="container my-4 00z-index-99">
                        <form>
                          <div className="form-group max-170 m-auto text-center">
                            <label className="font-14">
                              {" "}
                              {t("MailList.type")}
                            </label>
                            <div
                              className="icon-drop-bg pointer"
                              onClick={this.openMailDialogue}
                            >
                              <div className="rounded-circle ">
                                <img
                                  src={`data:image/png;base64,${
                                    mailImage && mailImage
                                  }`}
                                  alt="ico"
                                />
                              </div>
                              <span className="font-12">
                                {mailType && mailType}
                              </span>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="font-14">
                              {t("MailList.titleInternal")}
                            </label>
                            <Field
                              type="text"
                              name="name"
                              placeholder={t("MailList.titleInternal")}
                              className="form-control"
                              required
                            />
                            <ErrorMessageFormik name="name" />
                          </div>

                          <div className="form-group">
                            <label className="font-14">
                              {t("MailList.description")}
                            </label>
                            <Field
                              as="textarea"
                              name="description"
                              placeholder={t("MailList.description")}
                              className="form-control"
                              required
                            />
                            <ErrorMessageFormik name="description" />
                          </div>
                        </form>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </Formik>
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.mailDetailStateToProps],
      state
    ),
  actions.appMapDispatchToProps([actions.asyncApiDispatchToProps])
)(withRouter(withTranslation()(EditMail)));
