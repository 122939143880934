import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { adminOptionList } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import {
  getUserID,
  getValueFromLocalStorage,
  saveDatatoLocalStorage,
} from "../../../util/utils";
import CommonLoader from "../../CommanComponents/CommonLoader";
import LoadingPage from "../../CommanComponents/LoadingPage";
import FooterDesktop from "../../Footer/FooterDesktop";
import HeaderDesktop from "../../Header/HeaderDesktop";
import SetUpSystemDesktop from "../SetupSystem/SetUpSystemDesktop";
import GridTypeMenu from "./GridTypeMenu";
import ListTypeMenu from "./ListTypeMenu";

class AdminstrationMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: {},
      isSystemSetupOpen: false,
      displayType: 1,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const adminMenuDisplayType = getValueFromLocalStorage(
      "adminMenuDisplayType"
    );

    if (adminMenuDisplayType == 1) {
      this.setState({
        displayType: 1,
      });
    } else {
      this.setState({
        displayType: 0,
      });
    }

    apiCall(
      METHOD.QUERY,
      adminOptionList(getUserID()),
      (data) => {
        this.setState({
          data,
        });
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };
  handleSystemSetupdialogue = () => {
    this.setState((prev) => {
      return {
        isSystemSetupOpen: !prev.isSystemSetupOpen,
      };
    });
  };
  loader = (<CommonLoader key={0} />);
  render() {
    const { isLoading, data, isSystemSetupOpen, displayType } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="content-wrapper">
              <HeaderDesktop selectionType={0} />
              <div className="content">
                <div className="section section-padding">
                  <div className="container">
                    <div className="section-container">
                      <div className="section-title text-center d-md-flex align-items-start m-0">
                        <div className="flex-fill mr-n-1300">
                          <Link to="/">
                            <h1 className="page-title h4">
                              {t("administration.title")}
                            </h1>
                          </Link>
                        </div>
                      </div>
                      <div className="section-content">
                        {isLoading && this.loader}

                        <div className="d-flex align-items-center justify-content-end mt-3 mb-4 pb-3">
                          <div className="sort">
                            <a
                              href="javascript;:"
                              className={
                                displayType == 0 ? "mr-2 active" : "mr-2"
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                saveDatatoLocalStorage(
                                  "adminMenuDisplayType",
                                  0
                                );
                                this.setState({
                                  displayType: 0,
                                });
                              }}
                            >
                              <i className="fa fa-list fa-lg text-muted"></i>
                            </a>
                            <a
                              href="javascript;:"
                              className={displayType == 1 ? "active" : ""}
                              onClick={(e) => {
                                e.preventDefault();
                                saveDatatoLocalStorage(
                                  "adminMenuDisplayType",
                                  1
                                );
                                this.setState({
                                  displayType: 1,
                                });
                              }}
                            >
                              <i className="fa fa-th-large fa-lg text-muted"></i>
                            </a>
                          </div>
                        </div>

                        {isSystemSetupOpen && (
                          <SetUpSystemDesktop
                            handleSystemSetupdialogue={() =>
                              this.handleSystemSetupdialogue()
                            }
                          />
                        )}

                        {displayType == 0 && (
                          <ListTypeMenu
                            handleSystemSetupdialogue={() => {
                              this.handleSystemSetupdialogue();
                            }}
                            data={data}
                          />
                        )}

                        {displayType == 1 && (
                          <GridTypeMenu
                            handleSystemSetupdialogue={() => {
                              this.handleSystemSetupdialogue();
                            }}
                            data={data}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FooterDesktop selectionType={0} />
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(AdminstrationMenu);
