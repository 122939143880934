/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deletePersonMailQuery,
  getmailinglistgroup,
  getmailinglistgroupForFilter
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";
import FabIcon from "../../CommanComponents/FabIcon";
import Footer from "../../Footer/FooterMobile";
import LogoutMenu from "../../CommanComponents/LogoutMenu";
import TopHeader from "../../CommanComponents/TopHeader";
import PersonListSetteliteSideMenu from "./PersonListSetteliteSideMenu";
import GroupItem from "./GroupItem";

import SearchMail from "../../Mail/MailListMobile/SearchMail";
import SearchPersonResult from "../../Mail/MailListMobile/SearchPersonResult";
import SearchMailListBaseOnPerson from "../../Mail/MailListMobile/SearchMailListBaseOnPerson";
import SearchPersonBaseOnMail from "../../Mail/MailListMobile/SearchPersonBaseOnMail";
import SearchPersonBaseonCompany from "../../Mail/MailListMobile/SearchPersonBaseonCompany";
class PersonList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      headerMenuStatus: false,
      list_id: 0,
      mail_id: 0,
      data: { person_list: [], group_list: [],breadcrumb:[] },
      searchData: { person_list: [], group_list: [],breadcrumb:[] },
      searchValue: "",
      breadcrumb:[],
      searchDisplayType: 0,
      searchPersonList: [],
      mailListBaseOnPerson: [],
      personListBaseOnMailID:[],
      personListBreadCumb:[],
      selectedPersonId:0
    };
  }

  setSearchPersonList = (data, searchValue) => {
    this.setState(
      {
        searchData: data,
        searchValue,
      },
      () => {
        console.log("<---searchData--->", this.state.searchData);
      }
    );
  };

  handleBackStack = () => {
    const mail_list_id = this.props.match.params.mail_person_id;
    const fields = mail_list_id.split("-");
    const mail_id = fields[1];

    let { from } = this.props.location.state || {
      from: { pathname: "/mail-detail/" + mail_id },
    };
    this.props.history.push(from);
  };
  openSlider = () => {
    this.setState(() => {
      return {
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  handleActiveIndex = (mail_id, id) => {
    if (mail_id == id) {
      this.handleScroll(mail_id);
      return "active";
    } else {
      return "";
    }
  };

  handleScroll = (id) => {
    const index = this.state.header_list.findIndex((mail) => mail.id == id);

    if (index > 3) {
      setTimeout(function () {
        var element = document.getElementById(`li_${id}`);
        var element1 = document.getElementById("ulID");
        var elementPosition = element.getBoundingClientRect();
        element1.scrollLeft = elementPosition.left;
      }, 500);
    }
  };

  // componentDidMount() {
  //   const mail_list_id = this.props.match.params.mail_person_id;
  //   const fields = mail_list_id.split("-");
  //   const list_id = fields[0];
  //   const mail_id = fields[1];

  //   this.setState({
  //     list_id: list_id,
  //     mail_id: mail_id,
  //   });

  //   apiCall(
  //     METHOD.QUERY,
  //     getmailinglistgroup(getUserID(), list_id),
  //     (data) => {
  //       this.props.storeApiData(data);
  //       this.setState({
  //         header_list: data.mailing_list,
  //         group_list: data.group_list,
  //         breadcrumb:data.breadcrumb,
  //         data,
  //       });
  //       // this.props.getMailDetails(data);
  //     },
  //     (isLoading) => this.handleLoader(isLoading),
  //     (data) => {
  //       toast.error(this.props.t(data.message));
  //     }
  //   );
  // }


  componentDidMount() {
    const mail_list_id = this.props.match.params.mail_person_id;
    const fields = mail_list_id.split("-");
    const list_id = fields[0];
    const mail_id = fields[1];

    this.setState({
      list_id: list_id,
      mail_id: mail_id,
    });

    const personDataTypeId = this.props.personDataTypeId
    if(personDataTypeId && personDataTypeId != 0){
      apiCall(
        METHOD.QUERY,
        getmailinglistgroupForFilter(getUserID(), list_id,personDataTypeId,this.props.filterValue),
        (data) => {
          this.props.storeApiData(data);
          this.setState({
            header_list: data.mailing_list,
            group_list: data.group_list,
            breadcrumb:data.breadcrumb,
            data,
          });
          // this.props.getMailDetails(data);
        },
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }else{
      apiCall(
            METHOD.QUERY,
            getmailinglistgroup(getUserID(), list_id),
            (data) => {
              this.props.storeApiData(data);
              this.setState({
                header_list: data.mailing_list,
                group_list: data.group_list,
                breadcrumb:data.breadcrumb,
                data,
              });
              // this.props.getMailDetails(data);
            },
            (isLoading) => this.handleLoader(isLoading),
            (data) => {
              toast.error(this.props.t(data.message));
            }
          );
    }
  }

  handleAfterApiCall = (data) => {
    this.setState(() => {
      return {
        ...this.state,
        mailList: data.mailing_list,
      };
    });
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  handleChangeMail = (id) => {
    this.setState(
      {
        list_id: id,
      },
      () => {
        const { mail_id } = this.state;
        this.props.history.push(
          "/mail-details-personlist/" + id + "-" + mail_id
        );
        this.loadPersonList(id);
      }
    );
  };

  loadPersonList = (mail_id) => {
    apiCall(
      METHOD.QUERY,
      getmailinglistgroup(getUserID(), mail_id,this.props.personDataTypeId,this.props.filterValue),
      (data) => {
        this.props.storeApiData(data);
        this.setState({
          header_list: data.mailing_list,
          group_list: data.group_list,
          breadcrumb:data.breadcrumb,
          data,
        });
        // this.props.getMailDetails(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleDelete = (item) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to delete this person.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              deletePersonMailQuery(getUserID(), this.state.list_id, item.id),
              (data) => {
                toast.success(this.props.t(data.message));
                let person_count = this.props.mailPersonList.person;
                person_count = person_count - 1;
                this.props.deletePersonFromPersonList(item);
                this.props.updatePersonCount(person_count);
              },
              (isLoading) => this.handleLoader(),
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  updatePersonCount = (group_id) => {
    let temp_data = this.state.data;
    let group_list = temp_data.group_list;
    const index = group_list.findIndex((group) => group.id == group_id);
    group_list[index].person = group_list[index].person - 1;
    temp_data.group_list = group_list;
    this.setState({ data: temp_data });
  };

  delete_group_item = (id) => {
    let searchData = this.state.searchData;
    let searchGroupList = searchData.group_list;
    if (searchGroupList.length != 0 && this.state.searchValue != 0) {
      let after_delete_group_list = searchGroupList.filter((item) => {
        return item.id != id;
      });

      let after_delete_group_list1 = this.state.group_list.filter((item) => {
        return item.id != id;
      });

      searchData.group_list = after_delete_group_list;
      this.setState({
        searchData,
        group_list: after_delete_group_list1,
      });
    } else {
      let after_delete_group_list = this.state.group_list.filter((item) => {
        return item.id != id;
      });

      this.setState({
        group_list: after_delete_group_list,
      });
    }
  };

  handleAlphabaticeSort = (alphabet) => {
    const { group_list } = this.state;
    let index = -1;
    for (let i = 0; i < group_list.length; i++) {
      const group = group_list[i];
      let name = group.name;
      name = name.charAt(0).toUpperCase();
      if (alphabet == name && index == -1) {
        index = i;
      }
    }
    if (index != -1) {
      const divID = `groupDiv${index}`;
      const section = document.getElementById(divID);
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  handleAlphabatice = (name, index, list) => {
    name = name.charAt(0).toUpperCase() + name.slice(1);
    let resulAlpha = "";
    if (index == 0) {
      resulAlpha = name.charAt(0);
    } else {
      let oldName = list[index - 1].name;
      oldName = oldName.charAt(0).toUpperCase() + oldName.slice(1);
      const digit1 = oldName.charAt(0);
      const digit2 = name.charAt(0);

      if (digit1 != digit2) {
        resulAlpha = digit2;
      } else {
        resulAlpha = "";
      }
    }
    return resulAlpha;
  };
  


  // Search method
  
  handleDisplayType =()=>{
    this.setState({
      searchDisplayType:0
    })
  }
  
  setSearchMailList = (personList) => {
    if (personList.length == 0) {
    }
    this.setState({
      searchPersonList: personList,
      searchDisplayType: personList.length == 0 ? 0 : 1,
    });
  };

  handleSearchDisplayTypeToInitialStage = () => {
    this.setState({
      searchDisplayType:0,
      search:'',
      value:''

    });
  };
  handleSearchDisplayType  = (searchDisplayType ) => {
    this.setState({
      searchDisplayType,
      search:'',
      value:''
    });
  };

  handleMailbaseOnPerson = (mailList) => {
    if (mailList.length == 0) {
      this.setState({
        mailListBaseOnPerson: [],
        searchPersonList: [],
        // searchDisplayType: 0,
      });
    } else {
      this.setState({
        // searchDisplayType: 0,
        mailListBaseOnPerson: mailList,
      });
    }
  };

  handlePersonListBaseonCompany = (personListBaseOnMailID)=>{
    this.setState({
      personListBaseOnMailID,
      searchDisplayType:4
    })
  }
  
  setmailFromPerson=(mailListBaseOnPerson,selectedPersonId)=>{
    console.log("mailListBaseOnPerson",mailListBaseOnPerson);
    console.log("selectedPersonId",selectedPersonId);
    if(mailListBaseOnPerson.length!=0){
      this.setState({
        mailListBaseOnPerson,
        searchDisplayType:2,
        selectedPersonId
      })
    }else{
      toast.info(
        this.props.t("GeneralMessages.noMailFound") );
    }
  
  }

  handleMailWiseResponse=(personListBaseOnMailID,personResponse,personListBreadCumb)=>{
    this.setState({
      personListBaseOnMailID,
      personListBreadCumb,
      personResponse,
      searchDisplayType:3
    })
  }
  

  render() {
    const { header_list, group_list, data, list_id, searchData,breadcrumb,
      searchDisplayType,
      mailListBaseOnPerson,
      personListBaseOnMailID,
      personListBreadCumb,
      selectedPersonId,
      groupIdBaseOnPersonDetail} = this.state;
    const {filterSortType} = this.props

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-yellow sticky-top">
                <div className="container">
                  <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                    <TopHeader />
                    <p
                      className="mb-0 ml-auto text-light pointer"
                      onClick={() => {
                        this.handleBackStack();
                      }}
                    >
                      {data.mailing_name && data.mailing_name}
                    </p>
                    <LogoutMenu />
                  </div>
                  <div className="clearfix ">
                    <div className="d-flex justify-content-center">
                      <ul className="list-inline header-count-list text-center m-0">
                        <li className="list-inline-item active">
                          <p className="font-16 text-white m-0">
                            {t("MailDetailScreen.person")}
                          </p>
                          <h5 className="font-weight-bold text-white m-0">
                            {data.total_person && data.total_person}
                          </h5>
                        </li>
                      </ul>
                    </div>
                    <SearchMail
                    handleMailbaseOnPerson={(mailList) => {
                      this.handleMailbaseOnPerson(mailList);
                    }}
                    handlePersonListBaseonCompany={(personList)=>{
                      this.handlePersonListBaseonCompany(personList)
                    }}

                    setmailFromPerson={(personList, selectedPersonId) => {
                      this.setmailFromPerson(personList, selectedPersonId);
                    }}
                    searchArray={this.setSearchMailList}
                    handleSearchDisplayType={(searchDisplayType) => {
                      this.handleSearchDisplayType(searchDisplayType);
                    }}
                  />
                  </div>
                </div>
              </div>

              <div className="middle-section sab-middle-section1 custom-middle-section">
                <div className="container mt-4 z-index-99">
                  <div className="d-flex align-items-center justify-content-between ">
                    <div className="clearfix">
                      <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                              <Link to="/mail-list">
                                {t("MailDetailScreen.mailList")}
                              </Link>
                            </li>
                            <li
                              className="breadcrumb-item pointer"
                              aria-current="page"
                              onClick={()=>{
                                const mailDetailId = breadcrumb[0]?.id
                                this.props.history.push("/mail-detail/" + mailDetailId);
                                }}
                            >
                              {breadcrumb[0]?.name }
                            </li>
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                            {data.mailing_name && data.mailing_name}
                            </li>
                          </ol>
                     
                      </nav>
                     </div>
                    <ul className="list-inline grid-view-list m-0">
                      <li className="list-inline-item mr-3">
                        <a className="pointer" href="javascript;:" onClick={(e)=>{
                          e.preventDefault();
                          this.props.changeRouteStatus(5)}}>
                          <i className="fa fa-filter font-30 text-dark"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="d-flex">
                  </div>
                   {searchDisplayType==0 && <React.Fragment>
                    {searchData.group_list.length == 0 &&filterSortType!=3 &&
                    group_list &&
                    group_list.map((item, index) => {
                      return (
                        <GroupItem
                          delete_group_item={this.delete_group_item}
                          updatePersonCount={this.updatePersonCount}
                          key={index}
                          item={item}
                          index={index}
                        />
                      );
                    })}

                  {searchData.group_list.length != 0 &&filterSortType!=3 &&
                    this.state.searchValue != "" &&
                    searchData.group_list.map((item, index) => {
                      return (
                        <GroupItem
                          delete_group_item={this.delete_group_item}
                          updatePersonCount={this.updatePersonCount}
                          key={index}
                          item={item}
                          index={index}
                        />
                      );
                    })}

                  {(this.state.searchValue!='') && filterSortType!=2 &&(
                    <div className="mt-2 ">
                      <h3>{t("GroupListScreen.pluralPerson")}</h3>
                    </div>
                  )}

                  {searchData.person_list &&filterSortType!=2 &&
                    searchData.person_list.map((item, index) => {
                      const { id, name, email } = item;
                      return (
                        <div className="container mt-4 d-block" key={index}>
                          {this.handleAlphabatice(
                            name,
                            index,
                            searchData.person_list
                          ) != "" && (
                            <div className="rounded-40 yellow-bg float-left ml-5p z-index-99">
                              <span className="text-light">
                                {this.handleAlphabatice(
                                  name,
                                  index,
                                  searchData.person_list
                                )}
                              </span>
                            </div>
                          )}
                          <div className="ml-65p pt-1 0d-flex">
                            <div className="w-100 mb-4">
                              <a
                                href="javascript;:"
                                className="text-dark float-right mt-1 pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.handleDelete(item);
                                  // this.handleDelete(item);
                                }}
                              >
                                <i className="zmdi zmdi-delete zmdi-hc-fw font-24"></i>
                              </a>
                              <h6 className="m-0">{name && name}</h6>
                              <p className="text-muted font-12 mb-2">
                                {email && email}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  {this.state.searchValue == "" && filterSortType!=2 &&
                    searchData.person_list.length == 0 &&
                    data.person_list &&
                    data.person_list.map((item, index) => {
                      const { id, name, email } = item;
                      return (
                        <div className="container mb-4 d-block">
                          <div className="ml-45p pt-1 0d-flex">
                            <div className="w-100 mb-4">
                              <a
                                href="javascript;:"
                                className="text-dark float-right mt-1 pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.handleDelete(item);
                                }}
                              >
                                <i className="zmdi zmdi-delete zmdi-hc-fw font-24"></i>
                              </a>
                              <h6 className="m-0">{name && name}</h6>
                              <p className="text-muted font-12 mb-2">
                                {email && email}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                     </React.Fragment>} 
            
              
{searchDisplayType == 1 && (
                 <SearchPersonResult 
                      suggetionList={this.state.searchPersonList}
                      handlePersonListBaseonCompany={(personList)=>{
                        this.handlePersonListBaseonCompany(personList)
                      }}
                      setmailFromPerson={(personList, selectedPersonId) => {
                        this.setmailFromPerson(
                          personList,
                          selectedPersonId
                        );
                      }}/>
                )}


                {searchDisplayType == 2 && (
                  <div className="container my-2">
                    {mailListBaseOnPerson.map((data, index) => {
                      return (
                        <SearchMailListBaseOnPerson
                          data={data}
                          key={index}
                          selectedPersonId={selectedPersonId}
                          handleMailWiseResponse={(
                            personListBaseOnMailID,
                            personResponse,
                            personListBreadCumb,
                            groupIdBaseOnPersonDetail
                          ) => {
                            this.handleMailWiseResponse(
                              personListBaseOnMailID,
                              personResponse,
                              personListBreadCumb,
                              groupIdBaseOnPersonDetail
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                )}

                {searchDisplayType == 3 && (
                  <div className="container my-2">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          {t("mailDetail.home")}
                        </li>
                        <li
                          className="breadcrumb-item pointer"
                          aria-current="page"
                          onClick={() => {
                            // const mailDetailId = breadcrumb[0]?.id
                            // this.props.history.push("/mail-detail/" + mailDetailId);
                          }}
                        >
                          {personListBreadCumb[0]?.name}
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          {personListBreadCumb[1]?.name}
                        </li>
                      </ol>
                    </nav>

                    {personListBaseOnMailID.map((data, index) => {
                      return (
                        <SearchPersonBaseOnMail
                          handleSearchDisplayType={(searchDisplayType) => {
                            this.handleSearchDisplayType(searchDisplayType);
                          }}
                          data={data}
                          key={index}
                          personResponse={this.state.personResponse}
                          groupIdBaseOnPersonDetail={groupIdBaseOnPersonDetail}
                        />
                      );
                    })}
                  </div>
                )}


{searchDisplayType == 4 && (
                  <div className="container my-2">
                    {personListBaseOnMailID.map((data, index) => {
                      return (
                        <SearchPersonBaseonCompany
                          handleSearchDisplayType={(searchDisplayType) => {
                            this.handleSearchDisplayType(searchDisplayType);
                          }}
                          data={data}
                          key={index}
                          personResponse={this.state.personResponse}
                          groupIdBaseOnPersonDetail={groupIdBaseOnPersonDetail}
                        />
                      );
                    })}
                  </div>
                )}


                </div>
                <PersonListSetteliteSideMenu
                  closeSlider={this.closeSlider}
                  headerMenuStatus={this.state.headerMenuStatus}
                  changeRouteStatus={this.props.changeRouteStatus}
                />
                <FabIcon color="rounded-yellow" openSlider={this.openSlider} />
              </div>

              <Footer type="4" />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.mailDetailStateToProps,
        stateToProps.mailPersonListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.mailDetailsDispatchToProps,
    actions.mailPersonListDispatchToProps,
  ])
)(withTranslation()(withRouter(PersonList)));
