/* eslint eqeqeq: 0 */

import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import gql from "graphql-tag";
import React, { Component } from "react";
import { ApolloProvider, Mutation } from "react-apollo";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { uploadLink } from "../../../../constants/baseUrl";
import { getUserID } from "../../../../util/utils";
import Footer from "../../../Footer/FooterMobile";

const apolloCache = new InMemoryCache();
const client_mutation = new ApolloClient({
  cache: apolloCache,
  link: uploadLink,
});

const Addpersondatatype = gql`
  mutation Addpersondatatype($profile: AddpersondatatypeInput!) {
    Addpersondatatype(input: $profile) {
      success
      message
    }
  }
`;

class AddPersonDataType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportDialogueStatus: false,
      name: "",
      identifier: "",
      description: "",
      field_type: "",
      is_default: 0,
      is_required: 0,
    };
  }

  handleTextChange = (e, type) => {
    if (type == "name") {
      this.setState({
        name: e.target.value,
      });
    } else if (type == "identifier") {
      this.setState({
        identifier: e.target.value,
      });
    } else if (type == "description") {
      this.setState({
        description: e.target.value,
      });
    }
  };
  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleReportDialogue = () => {
    this.setState((prevState) => {
      return {
        reportDialogueStatus: !prevState.reportDialogueStatus,
      };
    });
  };

  setFieldType = (event) => {
    this.setState({
      field_type: event.target.value,
    });
  };

  set_is_required = (event) => {
    if (event.target.checked) {
      this.setState({
        is_required: 1,
      });
    } else {
      this.setState({
        is_required: 0,
      });
    }
  };

  set_is_default = (event) => {
    if (event.target.checked) {
      this.setState({
        is_default: 1,
      });
    } else {
      this.setState({
        is_default: 0,
      });
    }
  };

  render() {
    const {
      name,
      identifier,
      description,
      field_type,
      is_default,
      is_required,
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <ApolloProvider client={client_mutation}>
              <Mutation mutation={Addpersondatatype}>
                {(Addpersondatatype, { data, error, loading }) => {
                  if (data) {
                    if (data.Addpersondatatype.success == 0) {
                      toast.error(this.props.t(data.Addpersondatatype.message));
                    } else {
                      toast.success(
                        this.props.t(data.Addpersondatatype.message)
                      );
                      this.props.handleAddModal(true);
                    }
                  }
                  if (error) {
                    toast.error(
                      i18n.getDataByLanguage("en").translation.GeneralMessages
                        .errorServerisNotResponding &&
                        i18n.getDataByLanguage("en").translation.GeneralMessages
                          .errorServerisNotResponding
                    );
                  }

                  return (

                    <div className="d-block">
                    <div
                      className="modal fade show"
                      id="NewEvent"
                      tabindex="-1"
                      aria-labelledby="NewEventLabel"
                      aria-modal="true"
                      style={{ display: "block" }}
                    >
                      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="modal-header border-0 d-block pt-4 pb-0">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                              <button
                                type="button"
                                onClick={() =>{this.props.handleAddModal(false)}}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                              </button>

                              {loading ? (
                                <div className="btn btn-primary-theme pointer">
                                  {t("AdminAddUser.save")}
                                  <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                    <span className="sr-only">
                                      {t("GeneralMessages.loading")}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="btn btn-primary-theme pointer"
                                  onClick={() => {
                                    const {
                                      name,
                                      description,
                                      identifier,
                                      field_type,
                                      is_default,
                                      is_required,
                                    } = this.state;
                                    if (!name) {
                                      toast.info(
                                        this.props.t(
                                          "ManagePersonDataType.nameTitleValidation"
                                        )
                                      );
                                    } else if (!identifier) {
                                      toast.info(
                                        this.props.t(
                                          "ManagePersonDataType.identifierTitleValidation"
                                        )
                                      );
                                    } else if (!field_type) {
                                      toast.info(
                                        this.props.t(
                                          "ManagePersonDataType.fieldTypeTitleValidation"
                                        )
                                      );
                                    } else {
                                      let obj = {
                                        variables: {
                                          profile: {
                                            user_id: getUserID(),
                                            id: null,
                                            name,
                                            description: description
                                              ? description
                                              : null,
                                            identifier,
                                            field_type,
                                            is_default_field: is_default,
                                            is_required,
                                          },
                                        },
                                      };
                                      Addpersondatatype(obj);
                                    }
                                  }}
                                >
                                  {t("AdminAddUser.save")}
                                </div>
                              )}
                            </div>
                            <h5 className="modal-title">
                            {t("ManagePersonDataType.addPersonDataType")}
                            </h5>
                          </div>
                          <div className="modal-body">
                      <div className="middle-section sab-middle-section1">
                        <div className="container my-4 z-index-99">
                          <div className="form-group mb-5">
                            <div className="form-group">
                              <div className="container my-4 00z-index-99">
                                <form>
                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("ManagePersonDataType.nameTitle")}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        this.handleTextChange(e, "name")
                                      }
                                      name="name"
                                      value={name && name}
                                      placeholder={t(
                                        "ManagePersonDataType.nameTitlePlaceHolder"
                                      )}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t(
                                        "ManagePersonDataType.descriptionTitle"
                                      )}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        this.handleTextChange(e, "description")
                                      }
                                      name="description"
                                      value={description && description}
                                      placeholder={t(
                                        "ManagePersonDataType.descriptionTitlePlaceHolder"
                                      )}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t(
                                        "ManagePersonDataType.identifierTitle"
                                      )}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        this.handleTextChange(e, "identifier")
                                      }
                                      name="identifier"
                                      value={identifier && identifier}
                                      placeholder={t(
                                        "ManagePersonDataType.identifierTitlePlaceHolder"
                                      )}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("ManagePersonDataType.fieldTypeTitle")}
                                    </label>

                                    <div onChange={(e) => this.setFieldType(e)}>
                                      <input
                                        type="radio"
                                        className="mr-2"
                                        checked={
                                          field_type == "text" ? true : false
                                        }
                                        value="text"
                                        name="link_type"
                                      />
                                      {t("ManagePersonDataType.fieldType1")}
                                    </div>
                                    <div onChange={(e) => this.setFieldType(e)}>
                                      <input
                                        type="radio"
                                        className="mr-2"
                                        value="email"
                                        checked={
                                          field_type == "email" ? true : false
                                        }
                                        name="link_type"
                                      />
                                      {t("ManagePersonDataType.fieldType2")}
                                    </div>
                                    <div onChange={(e) => this.setFieldType(e)}>
                                      <input
                                        type="radio"
                                        className="mr-2"
                                        value="text_multiline"
                                        checked={
                                          field_type == "text_multiline"
                                            ? true
                                            : false
                                        }
                                        name="link_type"
                                      />
                                      {t("ManagePersonDataType.fieldType3")}
                                    </div>
                                    <div onChange={(e) => this.setFieldType(e)}>
                                      <input
                                        type="radio"
                                        className="mr-2"
                                        value="checkbox"
                                        checked={
                                          field_type == "checkbox"
                                            ? true
                                            : false
                                        }
                                        name="link_type"
                                      />
                                      {t("ManagePersonDataType.fieldType4")}
                                    </div>
                                    <div onChange={(e) => this.setFieldType(e)}>
                                      <input
                                        type="radio"
                                        className="mr-2"
                                        value="tel"
                                        checked={
                                          field_type == "tel" ? true : false
                                        }
                                        name="link_type"
                                      />
                                      {t("ManagePersonDataType.fieldType5")}
                                    </div>
                                    <div onChange={(e) => this.setFieldType(e)}>
                                      <input
                                        type="radio"
                                        className="mr-2"
                                        value="link"
                                        checked={
                                          field_type == "link" ? true : false
                                        }
                                        name="link_type"
                                      />
                                      {t("ManagePersonDataType.fieldType6")}
                                    </div>
                                    <div onChange={(e) => this.setFieldType(e)}>
                                      <input
                                        type="radio"
                                        className="mr-2"
                                        value="html"
                                        checked={
                                          field_type == "html" ? true : false
                                        }
                                        name="html"
                                      />
                                      {t("ManagePersonDataType.fieldType7")}
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("ManagePersonDataType.is_required")}
                                    </label>

                                    <div
                                      onChange={(e) => this.set_is_required(e)}
                                    >
                                      <input
                                        type="checkbox"
                                        className="mr-2"
                                        checked={
                                          is_required == 1 ? true : false
                                        }
                                        value="0"
                                        name="link_type"
                                      />
                                      {t("ManagePersonDataType.is_required")}
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("ManagePersonDataType.is_default")}
                                    </label>
                                    <div
                                      onChange={(e) => this.set_is_default(e)}
                                    >
                                      <input
                                        type="checkbox"
                                        value="0"
                                        name="link_type"
                                        checked={is_default == 1 ? true : false}
                                        className="mr-2"
                                      />
                                      {t("ManagePersonDataType.is_default")}
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    </div>
                        </div>
                      </div>
                      <div className="modal-backdrop fade show"></div>
                    </div>
                
                
                );
                }}
              </Mutation>
            </ApolloProvider>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(AddPersonDataType));
