/* eslint eqeqeq: 0 */
import update from "immutability-helper";
import React, { Component } from "react";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import MultiBackend, { TouchTransition } from "react-dnd-multi-backend";
import Preview from "react-dnd-preview";
import TouchBackend from "react-dnd-touch-backend";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import {
  GroupDetailPersonListQuery,
  reArrangePersonListQuery,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";
import LoadingPage from "../../CommanComponents/LoadingPage";
import RearrangePersonItem from "./RearrangePersonItem";

const HTML5toTouch = {
  backends: [
    {
      backend: HTML5Backend,
    },
    {
      backend: TouchBackend,
      options: { enableMouseEvents: true }, // Note that you can call your backends with options
      preview: false,
      transition: TouchTransition,
    },
  ],
};

class RearrangePerson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addPersonLoading: false,
      end: 10,
      person_list: [],
    };
  }

  moveCard = (dragIndex, hoverIndex) => {
    const { person_list } = this.state;
    const dragCard = person_list[dragIndex];
    this.setState(
      update(this.state, {
        person_list: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        },
      })
    );
  };
  previewGenerator = (itemType, item, style) => {
    return <div as="h1" style={style}></div>;
  };

  loadItem = () => {
    const child_id = this.props.match.params.group_id;
    const { end, isLoading } = this.state;

    if (!isLoading && this.props.groupDetailPersonList.hasMore) {
      this.setState({
        isLoading: true,
      });

      //static
      apiCall(
        METHOD.QUERY,
        GroupDetailPersonListQuery(
          getUserID(),
          child_id,
          this.props.groupDetailPersonList.start,
          end,
          3
        ),
        (data) => {
          if (this.props.groupDetailPersonList.id) {
            this.props.updatePersonList(data);
          } else {
            this.props.getPersonList(data);
          }

          this.handleGroupDetailApiResponse(data);
        },
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };
  loader = (<LoadingPage key={0} />);
  handleGroupDetailApiResponse = (data) => {
    const { end } = this.state;
    this.setState({
      isLoading: true,
    });

    if (data.is_last == 1) {
      this.props.setPersonListHasMoreValue(false);

      this.setState(() => {
        return {
          ...this.state,
          hasMore: false,
        };
      });
    }

    this.props.setPersonListStartValue(
      this.props.groupDetailPersonList.start + end
    );
    this.setState((prevState) => {
      return {
        isLoading: false,
        start: prevState.start + end,
        person_list: this.props.groupDetailPersonList.person_list,
      };
    });
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        ...this.state,
        isLoading: status,
      };
    });
  };

  loader = (<LoadingPage key={0} />);

  handleSubmit = () => {
    const child_id = this.props.match.params.group_id;

    let reArrangeArray = [];
    const { person_list } = this.state;

    person_list.map((item) => {
      return reArrangeArray.push({ ...item, id: item.master_id });
    });

    let newArray = JSON.stringify(JSON.stringify(reArrangeArray));
    apiCall(
      METHOD.QUERY,
      reArrangePersonListQuery(getUserID(), child_id, newArray),
      (data) => {
        console.log("person_list-->",person_list);
        this.props.reArrangePersonList(person_list);
        toast.success(this.props.t(data.message));
        setTimeout(() => {
          this.props.handleRearrangePerson(true);
        }, 100);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  componentDidMount() {
    this.setState((prevState) => {
      return {
        isLoading: false,
        person_list: this.props.groupDetailPersonList.person_list,
      };
    });
  }

  render() {
    const { end, isLoading, person_list } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="d-block">
              <div
                className="modal fade show"
                id="NewMessage"
                tabindex="-1"
                aria-labelledby="NewMessageLabel"
                aria-modal="true"
                style={{ display: "block" }}
              >
                <div className="modal-dialog modal-lg 0modal-dialog-centered 0modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header border-0 d-block pt-4 pb-0">
                      <div className="d-flex align-items-center justify-content-between mb-4">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            this.props.handleRearrangePerson(false);
                          }}
                        >
                          <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                        </button>
                        <h5 className="modal-title">
                        {t("GroupDetailPersonListScreen.reArrange")}
                      </h5>
                        {isLoading ? (
                          <div
                            className="btn btn-primary-theme group pointer"
                            onClick={this.handleSubmit}
                          >
                            {t("GroupDetailPersonListScreen.btnSave")}
                            <div className="spinner-border spinner-border-sm ml-1 text-blue">
                              <span className="sr-only">
                                {t("GeneralMessages.loading")}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="btn btn-primary-theme group pointer"
                            onClick={this.handleSubmit}
                          >
                            {t("GroupDetailPersonListScreen.btnSave")}
                          </div>
                        )}
                      </div>

                    
                    </div>
                    <div className="modal-body">
                      <div className="middle-section sab-middle-section1">
                        <DndProvider
                          backend={MultiBackend}
                          options={HTML5toTouch}
                        >
                          <InfiniteScroll
                            pageStart={end}
                            loadMore={this.loadItem}
                            hasMore={this.props.groupDetailPersonList.hasMore}
                            useWindow={false}
                            loader={this.loader}
                          >
                            <div className="container my-4 00z-index-99">
                              {!!person_list.length &&
                                person_list.map((item, index) => {
                                  const { id, full_name, email } = item;
                                  return (
                                    <RearrangePersonItem
                                      id={id}
                                      index={index}
                                      name={full_name}
                                      email={email}
                                      moveCard={this.moveCard}
                                      key={index}
                                    />
                                  );
                                })}
                            </div>
                          </InfiniteScroll>

                          <Preview generator={this.previewGenerator} />
                        </DndProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop fade show"></div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.groupDetailPersonListStateToProps],
      state
    ),
  actions.appMapDispatchToProps([actions.groupDetailPersonListDispatchToProps])
)(withTranslation()(withRouter(RearrangePerson)));
