/* eslint eqeqeq: 0 */

//Editor Import
import { convertFromRaw, convertToRaw, EditorState, Modifier } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import stateToMarkdown from "draft-js-export-markdown/lib/stateToMarkdown";
import {
  createTypeStrategy,
  DraftJS,
  editorStateFromRaw,
  MegadraftEditor,
} from "megadraft";
// this is the default LinkInput that handles `LINK`-entities:
import LinkInput from "megadraft/lib/entity_inputs/LinkInput";
import icons from "megadraft/lib/icons";
import moment from "moment-timezone";
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CreateReportDataQuery,
  GetReportTypeQuery,
  GetSnippetQuery,
  refernceSnippetList,
  SnippetDataQuery,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";
import CommonLoader from "../../CommanComponents/CommonLoader";
import LoadingPage from "../../CommanComponents/LoadingPage";
import ConditionDialogue from "../../Dialogue/DialogueDesktop/ConditionDialogue";
import DateTimeDialogue from "../../Dialogue/DialogueDesktop/DateTimeDialogue";
import FreeTextDialogue from "../../Dialogue/DialogueDesktop/FreeTextDialogue";
import NumberDialogue from "../../Dialogue/DialogueDesktop/NumberDialogue";
import SelectionDialogue from "../../Dialogue/DialogueDesktop/SelectionDialogue";

//Editor Import

/**
 * All Editor Related method
 */

/*
 * This component represents the reference in the editor. In this case it is a span
 * */
const SnippetReferenceComponent = ({ entityKey, children, contentState }) => {
  const {
    url,
    id,
    snippet_type_identifier,
    master_id,
  } = contentState.getEntity(entityKey).getData();
  return (
    <span
      className="editor__link reference-backgroupred"
      onClick={() => window.KbdClick(id, snippet_type_identifier, master_id)}
      href={snippet_type_identifier}
      id={id}
      title={url}
    >
      {children}
    </span>
  );
};

/*
 * This is the dialog to select the reference to assign to the selected text
 * The list should be loaded dynamically
 * */
class ReferenceInput extends React.Component {
  constructor(props) {
    super(props);
    // load pages from somewhere
    this.pages = [
      { type: "text", title: "Text", id: "1" },
      { type: "selection", title: "Selection", id: "2" },
      { type: "number", title: "Number", id: "42" },
      // ...
    ];
    this.state = {
      snippet_list: [],
    };
  }

  componentDidMount() {
    apiCall(
      METHOD.QUERY,
      refernceSnippetList(getUserID(), 0, 10),
      (data) => this.handleSnippetResponse(data),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  handleSnippetResponse = (data) => {
    this.setState((prevState) => {
      return {
        snippet_list: [...prevState.snippet_list, ...data.snippet_list],
      };
    });
  };

  onPageChange = (event) => {
    const index = event.target.value;
    this.props.setEntity(this.state.snippet_list[index]);
  };

  render() {
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <select className="toolbar__input" onChange={this.onPageChange}>
              <option className="white-text-color">
                {t("GeneralMessages.snippetSelectReference")}
              </option>

              {this.state.snippet_list.map(
                ({ snippet_type_name, name, id }, index) => (
                  <option
                    className=" reference-text-color"
                    key={index}
                    value={index}
                  >
                    {name}
                  </option>
                )
              )}
            </select>
          );
        }}
      </Translation>
    );
  }
}

const entityInputs = {
  LINK: LinkInput,
  INTERNAL_PAGE_LINK: ReferenceInput,
};

const myDecorator = new DraftJS.CompositeDecorator([
  {
    strategy: createTypeStrategy("INTERNAL_PAGE_LINK"),
    component: SnippetReferenceComponent,
  },
]);

const styleMap = {
  INTERNAL_PAGE_LINK: {
    textDecoration: "line-through",
  },
};

const editor_actions = [
  { type: "inline", label: "B", style: "BOLD", icon: icons.BoldIcon },
  { type: "inline", label: "I", style: "ITALIC", icon: icons.ItalicIcon },
  // these actions correspond with the entityInputs above
  {
    type: "entity",
    label: "Page Link",
    style: "link",
    entity: "INTERNAL_PAGE_LINK",
    icon: icons.LinkIcon,
  },

  { type: "separator" },
  {
    type: "block",
    label: "UL",
    style: "unordered-list-item",
    icon: icons.ULIcon,
  },
  {
    type: "block",
    label: "OL",
    style: "ordered-list-item",
    icon: icons.OLIcon,
  },
  { type: "block", label: "H2", style: "header-two", icon: icons.H2Icon },
  {
    type: "block",
    label: "QT",
    style: "blockquote",
    icon: icons.BlockQuoteIcon,
  },
];

/*
 * Here we define the output for the server
 * */
const entityInputStyles = {
  LINK: (data) => {
    return {
      element: "a",
      attributes: {
        href: data.url,
      },
    };
  },
  INTERNAL_PAGE_LINK: (data) => {
    return {
      element: "reference",
      attributes: {
        id: data.id,
        placeholder: data.name,
        type: data.snippet_type_identifier,
      },
    };
  },
};

const editorPlugins = [];

const draftToHtmlOptions = {
  blockRenderers: {},
  entityStyleFn(entity) {
    const entityType = entity.getType();
    const data = entity.getData();
    if (
      entityType in entityInputStyles &&
      typeof entityInputStyles[entityType] === "function"
    ) {
      return entityInputStyles[entityType](data);
    }
    return {};
  },
};

const input_dialogue = ["text", "free_text"];
class AddNewReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportImage: "EB",
      report: "Einberufung",
      reportID: 0,
      reportTypeList: [],
      reportDialogueStatus: false,
      channelName: "",
      // Display snippet
      snippetType: "",
      snippetId: 0,
      master_id: 0,
      dialogueStatus: false,
      isLoading: false,
      loader:false,
      editorState: editorStateFromRaw(null, myDecorator),
    };
  }

  componentDidMount() {
    // var megadraft = document.querySelector("#megadraft-editor");
    // megadraft.onclick = function () {
    //   this.focus();
    // };

    apiCall(
      METHOD.QUERY,
      GetReportTypeQuery(getUserID(),0),
      (data) => {
        let brandImageDefault, brandNameDefault, brandID;
        if (data.report_type_list && data.report_type_list.langth !== 0) {
          brandNameDefault = data.report_type_list[0].name;
          brandImageDefault = data.report_type_list[0].icon;
          brandID = data.report_type_list[0].id;
        }
        this.setState(
          {
            reportTypeList: data.report_type_list,
            reportImage: brandImageDefault,
            reportID: brandID,
            report: brandNameDefault,
          },
          () => {
            this.apiCallSnippet(brandID);
          }
        );
      },
      (isLoading) => {this.setState({loader:isLoading})},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );

    window.KbdClick = (snippet_id, snippet_type_identifier, master_id) => {
      window.scrollTo(0, 0)
      this.setState({
        snippetType: snippet_type_identifier,
        snippetId: snippet_id,
        master_id: master_id,
        dialogueStatus: true,
      });
    };
  }

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  onChange = (editorState) => {
    const raw_value = convertToRaw(editorState.getCurrentContent());
    this.setState({ editorState, raw_value });
  };

  setDynamicValueForMultiLineWithInnerSnippet = (
    snippet_id,
    reference_raw_value
  ) => {
    const { raw_value, master_id } = this.state;
    /**
     * ***********Step to set the normal value.*********
     * 1) get the raw_value & find the snippet in entitymap base on snippet_id & master_id.
     * 2) Find the blocks and entity range
     * 3) get the text base on block_index and entity_range index
     * 4) find the <value> tag
     * 5) replace <value> tag with dynamic text
     * 6) update the offset base on new value in entity range
     * 6) remove the selected reference from snippet
     * 7) update the temp_raw_value in raw_value
     * 8) update the editor state
     */

    // @temp_raw_value store current raw_value in temp_raw_value
    let temp_raw_value = raw_value;

    let entityMap = temp_raw_value.entityMap; // store current entitymap from temp_raw_value
    let index; //@index position of reference base on snippet_id in entityMap
    let key_value_entity_map; //@key_value_entity_map,  position of key, in entitymap base on snippet_id
    let strRemoveOldString;
    let temp_entityRanges;

    /**
     * find the key_value(snippet position) base on snippet_id from entityMap
     */
    for (let i in entityMap) {
      const element = entityMap[i];
      if (
        element.data.id == snippet_id &&
        element.data.master_id == master_id
      ) {
        key_value_entity_map = i;
      }
    }

    /** @blocks store blocks from temp_raw_value */
    let blocks = temp_raw_value.blocks;

    /** @blockIndex  for multiple blocks need to store block_index */
    let blockIndex;

    /**  @entityRanges  to store the offset,key,length base on entityMap key_value */
    let entityRanges = false;

    /** search block base on key_value and store @block_index @entityRanges @index : new potion at enity ranges */
    outer_loop: for (let i in blocks) {
      let block = blocks[i];
      for (let j in block.entityRanges) {
        if (block.entityRanges[j].key == key_value_entity_map) {
          index = j; /** @index paticular key potion at inner box in entityRanges */
          blockIndex = i; /** @blockIndex paticular block in multiple blocks */
          entityRanges =
            block.entityRanges; /** @entityRanges entityRanges array for that block */
          break outer_loop;
        }
      }
    }

    /**************Start**********************Update text and style**********Start****************** */

    /**
     * text value from paticular blocks
     */
    let text = temp_raw_value.blocks[blockIndex].text;

    //refernece position
    const potion = entityRanges[index].offset;

    let dynamicBeforeVal = text.substr(0, entityRanges[index].offset);
    let dynamicBeforeValLenght = dynamicBeforeVal.length;
    const dynamicVal = text.substr(
      entityRanges[index].offset,
      entityRanges[index].length
    );
    const afterWordsPosition = potion + entityRanges[index].length;
    const dynamiAfterVal = text.slice(afterWordsPosition);

    let _new_blocks = [];

    let _referenceRawValue = reference_raw_value;
    let _referenceBlocks = _referenceRawValue.blocks;
    let _0IndexText = _referenceBlocks[0].text;
    dynamicBeforeVal = dynamicBeforeVal + _0IndexText;
    temp_raw_value.blocks[blockIndex].text = dynamicBeforeVal;

    let _inlineStyleRanges =
      temp_raw_value.blocks[blockIndex].inlineStyleRanges;
    let _referenceInlineStyleRangs = _referenceBlocks[0].inlineStyleRanges;
    let _new_inlineStyleRanges = [];

    for (let index = 0; index < blocks.length; index++) {
      if (index < blockIndex) {
        const element = blocks[index];
        _new_blocks.push(element);
      }
    }

    for (let k in _inlineStyleRanges) {
      if (_inlineStyleRanges[k].offset < potion) {
        _new_inlineStyleRanges.push(_inlineStyleRanges[k]);
      }
    }

    for (let n in _referenceInlineStyleRangs) {
      let offset = _referenceInlineStyleRangs[n].offset;
      offset = offset + dynamicBeforeValLenght;
      _referenceInlineStyleRangs[n].offset = offset;
      _new_inlineStyleRanges.push(_referenceInlineStyleRangs[n]);
    }

    blocks[blockIndex].inlineStyleRanges = _new_inlineStyleRanges;

    /**************Start******************Update entity ranges and entity map ******************/

    /*
     * @temp_entityRanges remove entity_range_item after update the values
     */

    temp_entityRanges = this.removeItem(entityRanges, entityRanges[index]);
    blocks[blockIndex].entityRanges = temp_entityRanges;
    /*
     *  update the entity_range in temp raw value
     */

    // dynamic value pela ni badhi entity range add kari didhi
    //  dynamic value pachi ni badhi entity range last block ma add thase

    let _0entityRanges = [];
    entityRanges.map((item, i) => {
      if (i < index) {
        _0entityRanges.push(item);
      }
    });
    blocks[blockIndex].entityRanges = _0entityRanges;

    // entity range  and entity map update

    let rootEntityMap = temp_raw_value.entityMap;
    let rootEntityRange = blocks[blockIndex].entityRanges;
    let refernceEntityMap = reference_raw_value.entityMap;
    let _referenceEntityRange = _referenceBlocks[0].entityRanges;
    let t_entityMap = [];

    // purani entity ne t_entityMap ma store karo
    for (let i in rootEntityMap) {
      t_entityMap = [...t_entityMap, entityMap[i]];
    }

    // reference vari entity map ne store karo rootentityMap ma and entity range ne bhi add karo 0 position par
    for (let iRanges in _referenceEntityRange) {
      let range = _referenceEntityRange[iRanges];
      const key = range.key;
      const offset = range.offset;

      t_entityMap = [...t_entityMap, refernceEntityMap[key]];
      range.key = t_entityMap.length - 1;
      range.offset = offset + potion;
      rootEntityRange.push(range);
      blocks[blockIndex].entityRanges = rootEntityRange;
    }

    //temp remove
    // t_entityMap = { ...t_entityMap }
    // temp_raw_value.entityMap = t_entityMap
    _new_blocks.push(blocks[blockIndex]);
    /* ***** 0 index and last index vagar na  reference block add karva entitymap and entityrange add karva */

    for (let i in _referenceBlocks) {
      if (i != 0 && i != _referenceBlocks.length - 1) {
        const index = blockIndex + i;
        let block = _referenceBlocks[i];
        let key_value = new Date().getTime() + Math.random();
        //key_value = parseInt(key_value);
        block.key = key_value;
        let tempEntityRange = block.entityRanges;
        for (let itempEntityRange in tempEntityRange) {
          let range = tempEntityRange[itempEntityRange];
          const key = range.key;
          t_entityMap = [...t_entityMap, refernceEntityMap[key]];
          range.key = t_entityMap.length - 1;
          tempEntityRange[itempEntityRange] = range;
        }
        block.entityRanges = tempEntityRange;
        _referenceBlocks[i] = block;
        _new_blocks.push(_referenceBlocks[i]);
      }
    }

    /* ***** 0 index and last index vagar na  reference block add karva entitymap and entityrange add karva */

    if (_referenceBlocks.length - 1 != 0) {
      let last_reference_block = _referenceBlocks[_referenceBlocks.length - 1];
      let last_reference_text = last_reference_block.text;
      const last_reference_text_length = last_reference_text.length;
      last_reference_text = last_reference_text + dynamiAfterVal;
      last_reference_block.text = last_reference_text;
      let _last_new_inlineStyleRanges = [];
      for (let m in _inlineStyleRanges) {
        // include varu check karvanu baki che bold
        if (_inlineStyleRanges[m].offset > potion) {
          let offset = _inlineStyleRanges[m].offset;
          offset =
            offset +
            last_reference_text_length -
            dynamicVal.length -
            dynamicBeforeValLenght;
          _inlineStyleRanges[m].offset = offset;
          _last_new_inlineStyleRanges.push(_inlineStyleRanges[m]);
        }
      }
      _last_new_inlineStyleRanges = _last_new_inlineStyleRanges.concat(
        last_reference_block.inlineStyleRanges
      );
      last_reference_block.inlineStyleRanges = _last_new_inlineStyleRanges;

      /**************Start******************Update entity ranges and entity map of last block ******************/

      let main_block_entityRanges = entityRanges;

      let last_block_entityRanges = [];
      main_block_entityRanges = main_block_entityRanges.map((item, i) => {
        item = {
          ...item,
          offset:
            item.offset +
            last_reference_text_length -
            dynamicVal.length -
            dynamicBeforeValLenght,
        };

        if (item.offset > potion) {
          let new_entityRange = this.removeItem(entityRanges, entityRanges[i]);
          _new_blocks[blockIndex].entityRanges = new_entityRange;
          last_block_entityRanges.push(item);
        }
      });

      let tempEntityRange = last_reference_block.entityRanges;
      for (let itempEntityRange in tempEntityRange) {
        let range = tempEntityRange[itempEntityRange];
        const key = range.key;
        t_entityMap = [...t_entityMap, refernceEntityMap[key]];
        range.key = t_entityMap.length - 1;
        tempEntityRange[itempEntityRange] = range;
      }
      last_block_entityRanges = [
        ...last_block_entityRanges,
        ...tempEntityRange,
      ];

      last_reference_block.entityRanges = last_block_entityRanges;
      let key_value = new Date().getTime() + Math.random();
      // key_value = parseInt(key_value);
      last_reference_block.key = key_value;

      _referenceBlocks[_referenceBlocks.length - 1] = last_reference_block;
      const lne = _referenceBlocks.length - 1;
      let _last_index = blockIndex + lne;
      _new_blocks.push(last_reference_block);
    }
    /**************END**********************Update text and style**********END*******************/
    t_entityMap = { ...t_entityMap };
    temp_raw_value.entityMap = t_entityMap;

    for (let index = 0; index < blocks.length; index++) {
      if (index > blockIndex) {
        const element = blocks[index];
        _new_blocks.push(element);
      }
    }
    temp_raw_value.blocks = _new_blocks;
    // /*
    // * @contentState convert final temp_raw_value to editor state.
    // */
    /**
     * set updated raw_value & editor state after value update.
     */

    let contentState = convertFromRaw(temp_raw_value);

    this.setState(
      {
        raw_value: temp_raw_value,
        editorState: EditorState.createWithContent(contentState, myDecorator),
      },
      () => {}
    );
  };

  setFinalDynamicValueWithInnerSnippet = (
    snippet_id,
    value,
    nested_raw_value
  ) => {
    let temp_nested_raw_value = nested_raw_value;
    let nested_text = temp_nested_raw_value.blocks[0].text;
    let nested_entityMap = temp_nested_raw_value.entityMap;
    let nested_entityRanges = temp_nested_raw_value.blocks[0].entityRanges;
    let inlineStyleRanges = temp_nested_raw_value.blocks[0].inlineStyleRanges;
    let valuetag_potion = 0;
    let current_nested_offset = nested_entityRanges[0].offset;
    valuetag_potion = nested_text.search("<value>");
    nested_text = nested_text.replace("<value>", value);
    if (current_nested_offset > valuetag_potion) {
      const new_offset_val = nested_entityRanges[0].offset + value.length - 7;
      let offset_update_nested_entityRanges = nested_entityRanges;
      offset_update_nested_entityRanges[0].offset = new_offset_val;
      temp_nested_raw_value.blocks[0].entityRanges = offset_update_nested_entityRanges;
    }

    temp_nested_raw_value.blocks[0] = {
      ...temp_nested_raw_value.blocks[0],
      text: nested_text,
    };

    value = value && value.replace(/&nbsp;/g, " ");
    const { raw_value, master_id } = this.state;
    // @temp_raw_value store current raw_value in temp_raw_value
    let temp_raw_value = raw_value;

    let entityMap = temp_raw_value.entityMap; // store current entitymap from temp_raw_value
    let index; //@index position of reference base on snippet_id in entityMap
    let key_value_entity_map; //@key_value_entity_map,  position of key, in entitymap base on snippet_id
    let strRemoveOldString;
    let temp_entityRanges;

    /**
     * find the key_value(snippet position) base on snippet_id from entityMap
     */
    for (let i in entityMap) {
      const element = entityMap[i];
      if (
        element.data.id == snippet_id &&
        element.data.master_id == master_id
      ) {
        key_value_entity_map = i;
      }
    }

    /** @blocks store blocks from temp_raw_value */
    let blocks = temp_raw_value.blocks;

    /** @blockIndex  for multiple blocks need to store block_index */
    let blockIndex;

    /**  @entityRanges  to store the offset,key,length base on entityMap key_value */
    let entityRanges = false;

    /** search block base on key_value and store @block_index @entityRanges @index : new potion at enity ranges */
    outer_loop: for (let i in blocks) {
      let block = blocks[i];
      for (let j in block.entityRanges) {
        if (block.entityRanges[j].key == key_value_entity_map) {
          index = j; /** @index paticular key potion at inner box in entityRanges */
          blockIndex = i; /** @blockIndex paticular block in multiple blocks */
          entityRanges =
            block.entityRanges; /** @entityRanges entityRanges array for that block */
          break outer_loop;
        }
      }
    }

    /**
     * text value from paticular blocks
     */
    let text = temp_raw_value.blocks[blockIndex].text;

    /**
     * first find the <value> and replace with empty string
     * add new dynamic value at particular <value> potion
     */
    strRemoveOldString = text.split("");
    strRemoveOldString.splice(
      entityRanges[index].offset,
      entityRanges[index].length
    );
    strRemoveOldString = strRemoveOldString.join("");
    text = strRemoveOldString;
    text = [
      text.slice(0, entityRanges[index].offset),
      nested_text,
      text.slice(entityRanges[index].offset),
    ].join("");

    /** update the offset value and length of other dynamic snippet in entityRanges */
    let dynamic_string_length =
      nested_text.length; /** @dynamic_string_length new dynamic value length */
    let previous_string_length =
      entityRanges[index].length; /** @previous_string_length previous length*/
    let differnece =
      dynamic_string_length - previous_string_length; /**@difference */

    let temp_inlineStyleRanges =
      temp_nested_raw_value.blocks[0].inlineStyleRanges;
    let previous_offset = entityRanges[index].offset;
    let previous_length = entityRanges[index].length;
    let update__inlineStyleRanges = this.handleReferenceStyle(
      previous_offset,
      temp_inlineStyleRanges,
      temp_raw_value.blocks[blockIndex].inlineStyleRanges,
      valuetag_potion,
      value.length,
      differnece,
      previous_length
    );
    temp_nested_raw_value.blocks[0].inlineStyleRanges = update__inlineStyleRanges;

    let offset_update_entityRanges = entityRanges;
    offset_update_entityRanges = offset_update_entityRanges.map((item, i) => {
      if (i > index) {
        item = { ...item, offset: item.offset + differnece };
        // item = { ...item, offset: item.offset + differnece, key: item.key - 1 }
        offset_update_entityRanges[i] = item;
      }
    });

    /**
     *  update final raw_value the entityRanges & text at particular block using block_index
     */
    temp_raw_value.blocks[blockIndex].entityRanges = offset_update_entityRanges;
    temp_raw_value.blocks[blockIndex] = {
      ...temp_raw_value.blocks[blockIndex],
      text: text,
    };

    let temp_entityMap = [];
    for (let i in entityMap) {
      if (i != key_value_entity_map) {
        temp_entityMap = [...temp_entityMap, entityMap[i]];
      } else {
        temp_entityMap = [...temp_entityMap, nested_entityMap[0]];
      }
    }

    temp_entityMap = { ...temp_entityMap };
    temp_raw_value.entityMap = temp_entityMap;

    let previous_string_offset = entityRanges[index].offset;
    let previous_string_key = entityRanges[index].key;
    let temp_range_item = nested_entityRanges[0];

    temp_range_item.offset =
      temp_nested_raw_value.blocks[0].entityRanges[0].offset +
      previous_string_offset;
    temp_range_item.key = previous_string_key;
    temp_range_item.length =
      temp_nested_raw_value.blocks[0].entityRanges[0].length;

    nested_entityRanges[0] = temp_range_item;
    entityRanges[index] = temp_range_item;
    temp_raw_value.blocks[blockIndex].entityRanges = entityRanges;

    let contentState = convertFromRaw(temp_raw_value);

    this.setState({
      raw_value: temp_raw_value,
      editorState: EditorState.createWithContent(contentState, myDecorator),
    });
  };

 
  setFinalConditionalDynamicValueWithInnerSnippet = (
    snippet_id,
    value,
    nested_raw_value,
    value_tag_position
  ) => {
    console.log("nested_raw_value -->", nested_raw_value);

    let temp_nested_raw_value = nested_raw_value;

    let nested_text = temp_nested_raw_value.blocks[0].text;
    let nested_entityMap = temp_nested_raw_value.entityMap;
    let nested_entityRanges = temp_nested_raw_value.blocks[0].entityRanges;
    const { raw_value, master_id } = this.state;
    // @temp_raw_value store current raw_value in temp_raw_value
    let temp_raw_value = raw_value;

    let entityMap = temp_raw_value.entityMap; // store current entitymap from temp_raw_value
    let index; //@index position of reference base on snippet_id in entityMap
    let key_value_entity_map; //@key_value_entity_map,  position of key, in entitymap base on snippet_id
    let strRemoveOldString;
    let temp_entityRanges;

    /**
     * find the key_value(snippet position) base on snippet_id from entityMap
     */
    for (let i in entityMap) {
      const element = entityMap[i];
      if (
        element.data.id == snippet_id &&
        element.data.master_id == master_id
      ) {
        key_value_entity_map = i;
      }
    }

    /** @blocks store blocks from temp_raw_value */
    let blocks = temp_raw_value.blocks;

    /** @blockIndex  for multiple blocks need to store block_index */
    let blockIndex;

    /**  @entityRanges  to store the offset,key,length base on entityMap key_value */
    let entityRanges = false;

    /** search block base on key_value and store @block_index @entityRanges @index : new potion at enity ranges */
    outer_loop: for (let i in blocks) {
      let block = blocks[i];
      for (let j in block.entityRanges) {
        if (block.entityRanges[j].key == key_value_entity_map) {
          index =
            j; /** @index paticular key potion at inner box in entityRanges */
          blockIndex = i; /** @blockIndex paticular block in multiple blocks */
          entityRanges =
            block.entityRanges; /** @entityRanges entityRanges array for that block */
          break outer_loop;
        }
      }
    }

    /**
     * text value from paticular blocks
     */
    let text = temp_raw_value.blocks[blockIndex].text;

    /**
     * first find the <value> and replace with empty string
     * add new dynamic value at particular <value> potion
     */
    strRemoveOldString = text.split("");
    strRemoveOldString.splice(
      entityRanges[index].offset,
      entityRanges[index].length
    );
    strRemoveOldString = strRemoveOldString.join("");
    text = strRemoveOldString;
    text = [
      text.slice(0, entityRanges[index].offset),
      nested_text,
      text.slice(entityRanges[index].offset),
    ].join("");

    /** update the offset value and length of other dynamic snippet in entityRanges */
    let dynamic_string_length =
      nested_text.length; /** @dynamic_string_length new dynamic value length */
    let previous_string_length =
      entityRanges[index].length; /** @previous_string_length previous length*/
    let differnece =
      dynamic_string_length - previous_string_length; /**@difference */

    let temp_inlineStyleRanges =
      temp_nested_raw_value.blocks[0].inlineStyleRanges;
    let previous_offset = entityRanges[index].offset;
    let previous_length = entityRanges[index].length;

    let update__inlineStyleRanges = this.handleReferenceStyle(
      previous_offset,
      temp_inlineStyleRanges,
      temp_raw_value.blocks[blockIndex].inlineStyleRanges,
      value_tag_position,
      value.length,
      differnece,
      previous_length
    );
    temp_nested_raw_value.blocks[0].inlineStyleRanges =
      update__inlineStyleRanges;

    let offset_update_entityRanges = entityRanges;
    offset_update_entityRanges = offset_update_entityRanges.map((item, i) => {
      if (i > index) {
        item = { ...item, offset: item.offset + differnece };
        // item = { ...item, offset: item.offset + differnece, key: item.key - 1 }
        offset_update_entityRanges[i] = item;
      }
    });

    /**
     *  update final raw_value the entityRanges & text at particular block using block_index
     */
    temp_raw_value.blocks[blockIndex].entityRanges = offset_update_entityRanges;
    temp_raw_value.blocks[blockIndex] = {
      ...temp_raw_value.blocks[blockIndex],
      text: text,
    };
    console.log("entityMap[0]", entityMap);

    let temp_entityMap = [];
    for (let i in entityMap) {
      if (i != key_value_entity_map) {
        temp_entityMap = [...temp_entityMap, entityMap[i]];
      } else {
      
        for (let j in nested_entityMap) {
          temp_entityMap = [...temp_entityMap, nested_entityMap[j]];
        }
      }
    }
    console.log("nested_entityMap[0]", temp_entityMap);

    temp_entityMap = { ...temp_entityMap };
    temp_raw_value.entityMap = temp_entityMap;

    let previous_string_offset = entityRanges[index].offset;
    let previous_string_key = entityRanges[index].key;
    // let temp_range_item = nested_entityRanges[0];

    let new_nested_entityranges = temp_nested_raw_value.blocks[0].entityRanges;
    for (let j = 0; j < new_nested_entityranges.length; j++) {
      let temp_range_item = new_nested_entityranges[j];

      temp_range_item.offset = temp_range_item.offset + previous_string_offset;
      temp_range_item.key = previous_string_key + j;
      temp_range_item.length = temp_range_item.length;
      new_nested_entityranges[j] = temp_range_item;
    }
    // temp_range_item.offset = temp_nested_raw_value.blocks[0].entityRanges[0].offset + previous_string_offset;
    // temp_range_item.key = previous_string_key;
    // temp_range_item.length = temp_nested_raw_value.blocks[0].entityRanges[0].length;

    // nested_entityRanges[0] = temp_range_item;
    // entityRanges[index] = temp_range_item;

    // let newEntityRanges= entityRanges.splice(index, 1, ...new_nested_entityranges);
    let spliceEntityRanges =[] ;
    // spliceEntityRanges.splice(index, 1, ...new_nested_entityranges);

    for (let iEntity = 0; iEntity < entityRanges.length; iEntity++) {
      let element = entityRanges[iEntity];
      if(iEntity<index){
        spliceEntityRanges=  [...spliceEntityRanges,element]
      }else if(iEntity==index){
        spliceEntityRanges=  [...spliceEntityRanges,...new_nested_entityranges]
      }else if(iEntity>index){
        let key = element.key+new_nested_entityranges.length-1
        element.key=key
        spliceEntityRanges=  [...spliceEntityRanges,element]
      }
      

    }

    // let newEntityRanges=spliceArray.splice.apply(entityRanges, [index, 0].concat(new_nested_entityranges));
    temp_raw_value.blocks[blockIndex].entityRanges = spliceEntityRanges;
    console.log("temp_raw_value.blocks[blockIndex].entityRanges", temp_raw_value.blocks[blockIndex].entityRanges);
    let contentState = convertFromRaw(temp_raw_value);
    console.log("updated temp_raw_value", temp_raw_value);

    this.setState({
      raw_value: temp_raw_value,
      editorState: EditorState.createWithContent(contentState, myDecorator),
    });
  };

  setFinalDynamicValue = (
    snippet_id,
    value,
    nested_raw_value,
    value_tag_position,
    input_Value
  ) => {
    value = value && value.replace(/&nbsp;/g, " ");
    const { raw_value, master_id } = this.state;

    /**
     * ***********Step to set the normal value.*********
     * 1) get the raw_value & find the snippet in entitymap base on snippet_id & master_id.
     * 2) Find the blocks and entity range
     * 3) get the text base on block_index and entity_range index
     * 4) find the <value> tag
     * 5) replace <value> tag with dynamic text
     * 6) update the offset base on new value in entity range
     * 6) remove the selected reference from snippet
     * 7) update the temp_raw_value in raw_value
     * 8) update the editor state
     */

    // @temp_raw_value store current raw_value in temp_raw_value
    let temp_raw_value = raw_value;

    let entityMap = temp_raw_value.entityMap; // store current entitymap from temp_raw_value
    let index; //@index position of reference base on snippet_id in entityMap
    let key_value_entity_map; //@key_value_entity_map,  position of key, in entitymap base on snippet_id
    let strRemoveOldString;
    let temp_entityRanges;

    /**
     * find the key_value(snippet position) base on snippet_id from entityMap
     */
    for (let i in entityMap) {
      const element = entityMap[i];
      if (
        element.data.id == snippet_id &&
        element.data.master_id == master_id
      ) {
        key_value_entity_map = i;
      }
    }

    /** @blocks store blocks from temp_raw_value */
    let blocks = temp_raw_value.blocks;

    /** @blockIndex  for multiple blocks need to store block_index */
    let blockIndex;

    /**  @entityRanges  to store the offset,key,length base on entityMap key_value */
    let entityRanges = false;

    /** search block base on key_value and store @block_index @entityRanges @index : new potion at enity ranges */
    outer_loop: for (let i in blocks) {
      let block = blocks[i];
      for (let j in block.entityRanges) {
        if (block.entityRanges[j].key == key_value_entity_map) {
          index = j; /** @index paticular key potion at inner box in entityRanges */
          blockIndex = i; /** @blockIndex paticular block in multiple blocks */
          entityRanges =
            block.entityRanges; /** @entityRanges entityRanges array for that block */
          break outer_loop;
        }
      }
    }

    /**
     * text value from paticular blocks
     */
    let text = temp_raw_value.blocks[blockIndex].text;
    /**
     * first find the <value> and replace with empty string
     * add new dynamic value at particular <value> potion
     */
    strRemoveOldString = text.split("");
    strRemoveOldString.splice(
      entityRanges[index].offset,
      entityRanges[index].length
    );
    strRemoveOldString = strRemoveOldString.join("");
    text = strRemoveOldString;
    text = [
      text.slice(0, entityRanges[index].offset),
      value,
      text.slice(entityRanges[index].offset),
    ].join("");

    /** update the offset value and length of other dynamic snippet in entityRanges */
    let dynamic_string_length =
      value.length; /** @dynamic_string_length new dynamic value length */
    let previous_string_length =
      entityRanges[index].length; /** @previous_string_length previous length*/
    let differnece =
      dynamic_string_length - previous_string_length; /**@difference */

    let temp_nested_raw_value = nested_raw_value;
    let temp_inlineStyleRanges =
      temp_nested_raw_value &&
      temp_nested_raw_value.blocks[0].inlineStyleRanges;
    let previous_offset = entityRanges[index].offset;
    let previous_length = entityRanges[index].length;
    let update__inlineStyleRanges = this.handleReferenceStyle(
      previous_offset,
      temp_inlineStyleRanges,
      temp_raw_value.blocks[blockIndex].inlineStyleRanges,
      value_tag_position,
      input_Value.length,
      differnece,
      previous_length
    );
    temp_nested_raw_value.blocks[0].inlineStyleRanges = update__inlineStyleRanges;

    let offset_update_entityRanges = entityRanges;
    offset_update_entityRanges = offset_update_entityRanges.map((item, i) => {
      if (i > index) {
        item = { ...item, offset: item.offset + differnece };
        // item = { ...item, offset: item.offset + differnece, key: item.key - 1 }
        offset_update_entityRanges[i] = item;
      }
    });

    /**
     *  update final raw_value the entityRanges & text at particular block using block_index
     */
    temp_raw_value.blocks[blockIndex].entityRanges = offset_update_entityRanges;
    temp_raw_value.blocks[blockIndex] = {
      ...temp_raw_value.blocks[blockIndex],
      text: text,
    };

    /*
     * @temp_entityRanges remove entity_range_item after update the values
     */
    temp_entityRanges = this.removeItem(entityRanges, entityRanges[index]);
    /*
     *  update the entity_range in temp raw value
     */
    temp_raw_value.blocks[blockIndex].entityRanges = temp_entityRanges;

    /*
     * @contentState convert final temp_raw_value to editor state.
     */
    let contentState = convertFromRaw(temp_raw_value);

    /**
     * set updated raw_value & editor state after value update.
     */
    this.setState({
      raw_value: temp_raw_value,
      editorState: EditorState.createWithContent(contentState, myDecorator),
    });
  };

  setFinalDynamicConditionalValue = (
    snippet_id,
    value,
    nested_raw_value,
    value_tag_position,
    input_Value
  ) => {
    value = value && value.replace(/&nbsp;/g, " ");
    const { raw_value, master_id } = this.state;

    /**
     * ***********Step to set the normal value.*********
     * 1) get the raw_value & find the snippet in entitymap base on snippet_id & master_id.
     * 2) Find the blocks and entity range
     * 3) get the text base on block_index and entity_range index
     * 4) find the <value> tag
     * 5) replace <value> tag with dynamic text
     * 6) update the offset base on new value in entity range
     * 6) remove the selected reference from snippet
     * 7) update the temp_raw_value in raw_value
     * 8) update the editor state
     */

    // @temp_raw_value store current raw_value in temp_raw_value
    let temp_raw_value = raw_value;

    let entityMap = temp_raw_value.entityMap; // store current entitymap from temp_raw_value
    let index; //@index position of reference base on snippet_id in entityMap
    let key_value_entity_map; //@key_value_entity_map,  position of key, in entitymap base on snippet_id
    let strRemoveOldString;
    let temp_entityRanges;

    /**
     * find the key_value(snippet position) base on snippet_id from entityMap
     */
    for (let i in entityMap) {
      const element = entityMap[i];
      if (
        element.data.id == snippet_id &&
        element.data.master_id == master_id
      ) {
        key_value_entity_map = i;
      }
    }

    /** @blocks store blocks from temp_raw_value */
    let blocks = temp_raw_value.blocks;

    /** @blockIndex  for multiple blocks need to store block_index */
    let blockIndex;

    /**  @entityRanges  to store the offset,key,length base on entityMap key_value */
    let entityRanges = false;

    /** search block base on key_value and store @block_index @entityRanges @index : new potion at enity ranges */
    outer_loop: for (let i in blocks) {
      let block = blocks[i];
      for (let j in block.entityRanges) {
        if (block.entityRanges[j].key == key_value_entity_map) {
          index = j; /** @index paticular key potion at inner box in entityRanges */
          blockIndex = i; /** @blockIndex paticular block in multiple blocks */
          entityRanges =
            block.entityRanges; /** @entityRanges entityRanges array for that block */
          break outer_loop;
        }
      }
    }

    /**
     * text value from paticular blocks
     */
    let text = temp_raw_value.blocks[blockIndex].text;
    /**
     * first find the <value> and replace with empty string
     * add new dynamic value at particular <value> potion
     */
    strRemoveOldString = text.split("");
    strRemoveOldString.splice(
      entityRanges[index].offset,
      entityRanges[index].length
    );
    strRemoveOldString = strRemoveOldString.join("");
    text = strRemoveOldString;
    text = [
      text.slice(0, entityRanges[index].offset),
      value,
      text.slice(entityRanges[index].offset),
    ].join("");

    /** update the offset value and length of other dynamic snippet in entityRanges */
    let dynamic_string_length =
      value.length; /** @dynamic_string_length new dynamic value length */
    let previous_string_length =
      entityRanges[index].length; /** @previous_string_length previous length*/
    let differnece =
      dynamic_string_length - previous_string_length; /**@difference */

    let temp_nested_raw_value = nested_raw_value;
    let temp_inlineStyleRanges =
      temp_nested_raw_value &&
      temp_nested_raw_value.blocks[0].inlineStyleRanges;
    let previous_offset = entityRanges[index].offset;
    let previous_length = entityRanges[index].length;
    let update__inlineStyleRanges = this.handleReferenceStyle(
      previous_offset,
      temp_inlineStyleRanges,
      temp_raw_value.blocks[blockIndex].inlineStyleRanges,
      value_tag_position,
      input_Value.length,
      differnece,
      previous_length
    );
    temp_nested_raw_value.blocks[0].inlineStyleRanges = update__inlineStyleRanges;

    let offset_update_entityRanges = entityRanges;
    offset_update_entityRanges = offset_update_entityRanges.map((item, i) => {
      if (i > index) {
        item = { ...item, offset: item.offset + differnece };
        // item = { ...item, offset: item.offset + differnece, key: item.key - 1 }
        offset_update_entityRanges[i] = item;
      }
    });

    /**
     *  update final raw_value the entityRanges & text at particular block using block_index
     */
    temp_raw_value.blocks[blockIndex].entityRanges = offset_update_entityRanges;
    temp_raw_value.blocks[blockIndex] = {
      ...temp_raw_value.blocks[blockIndex],
      text: text,
    };

    /*
     * @temp_entityRanges remove entity_range_item after update the values
     */
    temp_entityRanges = this.removeItem(entityRanges, entityRanges[index]);
    /*
     *  update the entity_range in temp raw value
     */
    temp_raw_value.blocks[blockIndex].entityRanges = temp_entityRanges;

    /*
     * @contentState convert final temp_raw_value to editor state.
     */
    let contentState = convertFromRaw(temp_raw_value);

    /**
     * set updated raw_value & editor state after value update.
     */
    this.setState({
      raw_value: temp_raw_value,
      editorState: EditorState.createWithContent(contentState, myDecorator),
    });
  };

  handleReferenceStyle = (
    previous_offset,
    temp_inlineStyleRanges,
    main_inline_style,
    value_tag_position,
    input_Value_length,
    differnece,
    previous_length
  ) => {
    let inlineStyleRanges = temp_inlineStyleRanges;
    let temp_main_inline_style = main_inline_style;

    for (let i in inlineStyleRanges) {
      let style = inlineStyleRanges[i];

      if (value_tag_position > style.offset) {
        style = { ...style, offset: style.offset + previous_offset };
      } else {
        style = {
          ...style,
          offset: style.offset + previous_offset + input_Value_length - 7,
        };
      }
      inlineStyleRanges[i] = style;
    }

    for (let k in temp_main_inline_style) {
      let temp_element = temp_main_inline_style[k];
      let temp_element_offset = temp_element.offset;
      if (
        (temp_element_offset == previous_offset &&
          temp_element.length == previous_length) ||
        previous_offset < temp_element.length
      ) {
        temp_element = {
          ...temp_element,
          length: temp_element.length + differnece,
        };
        temp_main_inline_style[k] = temp_element;
      } else if (temp_element_offset > previous_offset) {
        temp_element = {
          ...temp_element,
          offset: temp_element.offset + differnece,
        };
        temp_main_inline_style[k] = temp_element;
      }
    }
    for (let j in inlineStyleRanges) {
      const element = inlineStyleRanges[j];
      temp_main_inline_style.push(element);
    }
    return temp_main_inline_style;
  };
  removeItem = (arr, item) => {
    for (var i = arr.length; i--; ) {
      if (arr[i] === item) {
        arr.splice(i, 1);
      }
    }
    return arr;
  };
  insertAtParticularPositon = (str, index, value) => {
    return str.substr(0, index) + value + str.substr(index);
  };

  setDynamicValueForMultiLine = (snippet_id, reference_raw_value) => {
    const { raw_value, master_id } = this.state;

    /**
     * ***********Step to set the normal value.*********
     * 1) get the raw_value & find the snippet in entitymap base on snippet_id & master_id.
     * 2) Find the blocks and entity range
     * 3) get the text base on block_index and entity_range index
     * 4) find the <value> tag
     * 5) replace <value> tag with dynamic text
     * 6) update the offset base on new value in entity range
     * 6) remove the selected reference from snippet
     * 7) update the temp_raw_value in raw_value
     * 8) update the editor state
     */

    // @temp_raw_value store current raw_value in temp_raw_value
    let temp_raw_value = raw_value;

    let entityMap = temp_raw_value.entityMap; // store current entitymap from temp_raw_value
    let index; //@index position of reference base on snippet_id in entityMap
    let key_value_entity_map; //@key_value_entity_map,  position of key, in entitymap base on snippet_id
    let strRemoveOldString;
    let temp_entityRanges;

    /**
     * find the key_value(snippet position) base on snippet_id from entityMap
     */
    for (let i in entityMap) {
      const element = entityMap[i];
      if (
        element.data.id == snippet_id &&
        element.data.master_id == master_id
      ) {
        key_value_entity_map = i;
      }
    }

    /** @blocks store blocks from temp_raw_value */
    let blocks = temp_raw_value.blocks;

    /** @blockIndex  for multiple blocks need to store block_index */
    let blockIndex;

    /**  @entityRanges  to store the offset,key,length base on entityMap key_value */
    let entityRanges = false;

    /** search block base on key_value and store @block_index @entityRanges @index : new potion at enity ranges */
    outer_loop: for (let i in blocks) {
      let block = blocks[i];
      for (let j in block.entityRanges) {
        if (block.entityRanges[j].key == key_value_entity_map) {
          index = j; /** @index paticular key potion at inner box in entityRanges */
          blockIndex = i; /** @blockIndex paticular block in multiple blocks */
          entityRanges =
            block.entityRanges; /** @entityRanges entityRanges array for that block */
          break outer_loop;
        }
      }
    }

    /**************Start**********************Update text and style**********Start****************** */

    /**
     * text value from paticular blocks
     */
    let text = temp_raw_value.blocks[blockIndex].text;

    //refernece position
    const potion = entityRanges[index].offset;

    let dynamicBeforeVal = text.substr(0, entityRanges[index].offset);
    let dynamicBeforeValLenght = dynamicBeforeVal.length;
    const dynamicVal = text.substr(
      entityRanges[index].offset,
      entityRanges[index].length
    );

    const afterWordsPosition = potion + entityRanges[index].length;
    const dynamiAfterVal = text.slice(afterWordsPosition);

    let _new_blocks = [];

    let _referenceRawValue = reference_raw_value;
    let _referenceBlocks = _referenceRawValue.blocks;
    let _0IndexText = _referenceBlocks[0].text;
    dynamicBeforeVal = dynamicBeforeVal + _0IndexText;
    temp_raw_value.blocks[blockIndex].text = dynamicBeforeVal;

    let _inlineStyleRanges =
      temp_raw_value.blocks[blockIndex].inlineStyleRanges;
    let _referenceInlineStyleRangs = _referenceBlocks[0].inlineStyleRanges;
    let _new_inlineStyleRanges = [];

    for (let index = 0; index < blocks.length; index++) {
      if (index < blockIndex) {
        const element = blocks[index];
        _new_blocks.push(element);
      }
    }

    for (let k in _inlineStyleRanges) {
      if (_inlineStyleRanges[k].offset < potion) {
        _new_inlineStyleRanges.push(_inlineStyleRanges[k]);
      }
    }

    for (let n in _referenceInlineStyleRangs) {
      let offset = _referenceInlineStyleRangs[n].offset;
      offset = offset + dynamicBeforeValLenght;
      _referenceInlineStyleRangs[n].offset = offset;
      _new_inlineStyleRanges.push(_referenceInlineStyleRangs[n]);
    }

    blocks[blockIndex].inlineStyleRanges = _new_inlineStyleRanges;

    /**************Start******************Update entity ranges and entity map ******************/

    /*
     * @temp_entityRanges remove entity_range_item after update the values
     */

    temp_entityRanges = this.removeItem(entityRanges, entityRanges[index]);
    /*
     *  update the entity_range in temp raw value
     */
    blocks[blockIndex].entityRanges = temp_entityRanges;

    let _0entityRanges = [];
    entityRanges.map((item, i) => {
      if (i < index) {
        _0entityRanges.push(item);
      }
    });

    blocks[blockIndex].entityRanges = _0entityRanges;

    _new_blocks.push(blocks[blockIndex]);
    // let _rawValueBlocks = temp_raw_value.blocks

    for (let i in _referenceBlocks) {
      if (i != 0 && i != _referenceBlocks.length - 1) {
        const index = blockIndex + i;
        let block = _referenceBlocks[i];
        let key_value = new Date().getTime() + Math.random();
        // key_value = parseInt(key_value);
        block.key = key_value;
        _referenceBlocks[i] = block;
        _new_blocks.push(_referenceBlocks[i]);
      }
    }

    if (_referenceBlocks.length - 1 != 0) {
      let last_reference_block = _referenceBlocks[_referenceBlocks.length - 1];
      let last_reference_text = last_reference_block.text;
      const last_reference_text_length = last_reference_text.length;
      last_reference_text = last_reference_text + dynamiAfterVal;
      last_reference_block.text = last_reference_text;
      let _last_new_inlineStyleRanges = [];
      for (let m in _inlineStyleRanges) {
        // include varu check karvanu baki che bold
        if (_inlineStyleRanges[m].offset > potion) {
          let offset = _inlineStyleRanges[m].offset;
          offset =
            offset +
            last_reference_text_length -
            dynamicVal.length -
            dynamicBeforeValLenght;
          _inlineStyleRanges[m].offset = offset;
          _last_new_inlineStyleRanges.push(_inlineStyleRanges[m]);
        }
      }
      _last_new_inlineStyleRanges = _last_new_inlineStyleRanges.concat(
        last_reference_block.inlineStyleRanges
      );
      last_reference_block.inlineStyleRanges = _last_new_inlineStyleRanges;

      /**************Start******************Update entity ranges and entity map of last block ******************/

      let main_block_entityRanges = entityRanges;

      let last_block_entityRanges = [];
      main_block_entityRanges = main_block_entityRanges.map((item, i) => {
        item = {
          ...item,
          offset:
            item.offset +
            last_reference_text_length -
            dynamicVal.length -
            dynamicBeforeValLenght,
        };

        if (item.offset > potion) {
          let new_entityRange = this.removeItem(entityRanges, entityRanges[i]);
          _new_blocks[blockIndex].entityRanges = new_entityRange;
          last_block_entityRanges.push(item);
        }
      });
      last_reference_block.entityRanges = last_block_entityRanges;
      let key_value = new Date().getTime() + Math.random();
      //key_value = parseInt(key_value);
      last_reference_block.key = key_value;

      _referenceBlocks[_referenceBlocks.length - 1] = last_reference_block;
      const lne = _referenceBlocks.length - 1;
      let _last_index = blockIndex + lne;
      _new_blocks.push(last_reference_block);
    }
    /**************END**********************Update text and style**********END*******************/

    for (let index = 0; index < blocks.length; index++) {
      if (index > blockIndex) {
        const element = blocks[index];
        _new_blocks.push(element);
      }
    }
    temp_raw_value.blocks = _new_blocks;
    // /*
    // * @contentState convert final temp_raw_value to editor state.
    // */
    /**
     * set updated raw_value & editor state after value update.
     */
    let contentState = convertFromRaw(this.state.raw_value);

    this.setState(
      {
        raw_value: temp_raw_value,
        editorState: EditorState.createWithContent(contentState, myDecorator),
      },
      () => {}
    );
  };

  handleReportDialogue = () => {
    this.setState((prevState) => {
      return {
        reportDialogueStatus: !prevState.reportDialogueStatus,
      };
    });
  };

  handleBrand = (image, name) => {
    this.setState(() => {
      return {
        status: false,
        brandType: name,
        brandImage: image,
      };
    });
  };

  handleReportSelection = (item) => {
    const { name, icon, id } = item;

    this.setState(
      {
        reportDialogueStatus: false,
        reportImage: icon,
        report: name,
        reportID: id,
      },
      () => {
        this.apiCallSnippet(id);
      }
    );
  };

  closeDialogue = () => {
    this.setState({
      dialogueStatus: false,
    });
  };

  apiCallSnippet = (report_type_id) => {
    const event_id = this.props.newMessageEventItem.id;
    // GetSnippetQuery(event_id, this.props.reportList.event_type_id, report_type_id),
    // GetSnippetQuery(81, 1, 2),
    console.log("this.props.newMessageEventItem",this.props.newMessageEventItem);
    apiCall(
      METHOD.QUERY,
      GetSnippetQuery(
        event_id,
        this.props.newMessageEventItem.event_type_id,
        report_type_id
      ),
      // GetSnippetQuery(77, 1, 6),
      (data) => {
        this.handleSnipetResopnse(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  nestedApicall = (snippetId) => {
    apiCall(
      METHOD.QUERY,
      SnippetDataQuery(snippetId,getUserID()),
      (data) => {
        let jsonContent = JSON.parse(data.content && data.content);
        this.setState({
          nestedSnippetData: data,
        });
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handle_event_values = (raw_value) => {
    const { name, event_datetime, location } = this.props.newMessageEventItem;
    const date = moment
      .unix(event_datetime)
      .tz(moment.tz.guess())
      .format("DD.MM.YYYY");
    const time = moment
      .unix(event_datetime)
      .tz(moment.tz.guess())
      .format("HH:mm");

    let temp_raw_value = raw_value;
    let blocks = temp_raw_value.blocks;
    outer_loop: for (let i in blocks) {
      let block = blocks[i];
      block = this.find_the_location_update_entityrange(block, location);
      block = this.find_the_event_update_entityrange(block, name);
      block = this.find_the_date_update_entityrange(block, date);
      block = this.find_the_time_update_entityrange(block, time);
    }
    return temp_raw_value;
  };

  find_the_location_update_entityrange = (block, location) => {
    let text_value = block.text;
    let difference = location.length - 10;
    let find_the_position = text_value.search("<location>");
    if (find_the_position != -1) {
      text_value =
        text_value.substring(0, find_the_position) +
        location +
        text_value.substring(find_the_position + 10);
      for (let j in block.entityRanges) {
        if (block.entityRanges[j].offset > find_the_position) {
          let element = block.entityRanges[j];
          element = { ...element, offset: element.offset + difference };
          block.entityRanges[j] = element;
        }
      }

      for (let k in block.inlineStyleRanges) {
        if (block.inlineStyleRanges[k].offset > find_the_position) {
          let element = block.inlineStyleRanges[k];
          element = { ...element, offset: element.offset + difference };
          block.inlineStyleRanges[k] = element;
        } else if (
          this.isBetween(
            find_the_position,
            block.inlineStyleRanges[k].offset,
            block.inlineStyleRanges[k].offset +
              block.inlineStyleRanges[k].length
          )
        ) {
          let element = block.inlineStyleRanges[k];
          element = { ...element, length: element.length + difference };
          block.inlineStyleRanges[k] = element;
        }
      }
      block.text = text_value;
      this.find_the_location_update_entityrange(block, location);
      return block;
    }
    return block;
  };

  find_the_event_update_entityrange = (block, name) => {
    let text_value = block.text;
    let difference = name.length - 7;

    let find_the_position = text_value.search("<event>");

    if (find_the_position != -1) {
      text_value =
        text_value.substring(0, find_the_position) +
        name +
        text_value.substring(find_the_position + 7);
      for (let j in block.entityRanges) {
        if (block.entityRanges[j].offset > find_the_position) {
          let element = block.entityRanges[j];
          element = { ...element, offset: element.offset + difference };
          block.entityRanges[j] = element;
        }
      }
      for (let k in block.inlineStyleRanges) {
        if (block.inlineStyleRanges[k].offset > find_the_position) {
          let element = block.inlineStyleRanges[k];
          element = { ...element, offset: element.offset + difference };
          block.inlineStyleRanges[k] = element;
        } else if (
          this.isBetween(
            find_the_position,
            block.inlineStyleRanges[k].offset,
            block.inlineStyleRanges[k].offset +
              block.inlineStyleRanges[k].length
          )
        ) {
          let element = block.inlineStyleRanges[k];
          element = { ...element, length: element.length + difference };
          block.inlineStyleRanges[k] = element;
        }
      }
      block.text = text_value;
      this.find_the_event_update_entityrange(block, name);
      return block;
    }
    return block;
  };
  isBetween = (n, a, b) => {
    return (n - a) * (n - b) <= 0;
  };
  find_the_date_update_entityrange = (block, date) => {
    let text_value = block.text;
    let difference = date.length - 6;
    let find_the_position = text_value.search("<date>");
    if (find_the_position != -1) {
      text_value =
        text_value.substring(0, find_the_position) +
        date +
        text_value.substring(find_the_position + 6);
      for (let j in block.entityRanges) {
        if (block.entityRanges[j].offset > find_the_position) {
          let element = block.entityRanges[j];
          element = { ...element, offset: element.offset + difference };
          block.entityRanges[j] = element;
        }
      }
      for (let k in block.inlineStyleRanges) {
        if (block.inlineStyleRanges[k].offset > find_the_position) {
          let element = block.inlineStyleRanges[k];
          element = { ...element, offset: element.offset + difference };
          block.inlineStyleRanges[k] = element;
        } else if (
          this.isBetween(
            find_the_position,
            block.inlineStyleRanges[k].offset,
            block.inlineStyleRanges[k].offset +
              block.inlineStyleRanges[k].length
          )
        ) {
          let element = block.inlineStyleRanges[k];
          element = { ...element, length: element.length + difference };
          block.inlineStyleRanges[k] = element;
        }
      }
      block.text = text_value;
      this.find_the_date_update_entityrange(block, date);
      return block;
    }
    return block;
  };
  find_the_time_update_entityrange = (block, time) => {
    let text_value = block.text;
    let difference = time.length - 6;
    let find_the_position = text_value.search("<time>");
    if (find_the_position != -1) {
      text_value =
        text_value.substring(0, find_the_position) +
        time +
        text_value.substring(find_the_position + 6);
      for (let j in block.entityRanges) {
        if (block.entityRanges[j].offset > find_the_position) {
          let element = block.entityRanges[j];
          element = { ...element, offset: element.offset + difference };
          block.entityRanges[j] = element;
        }
      }
      for (let k in block.inlineStyleRanges) {
        if (block.inlineStyleRanges[k].offset > find_the_position) {
          let element = block.inlineStyleRanges[k];
          element = { ...element, offset: element.offset + difference };
          block.inlineStyleRanges[k] = element;
        } else if (
          this.isBetween(
            find_the_position,
            block.inlineStyleRanges[k].offset,
            block.inlineStyleRanges[k].offset +
              block.inlineStyleRanges[k].length
          )
        ) {
          let element = block.inlineStyleRanges[k];
          element = { ...element, length: element.length + difference };
          block.inlineStyleRanges[k] = element;
        }
      }
      block.text = text_value;
      this.find_the_time_update_entityrange(block, time);
      return block;
    }
    return block;
  };

  handleSnipetResopnse = (data) => {
    const { content, read_only, raw_value } = data;

    let raw_value1 = JSON.parse(JSON.parse(raw_value));
    raw_value1 = this.handle_event_values(raw_value1);

    let contentState = convertFromRaw(raw_value1);

    this.setState(
      {
        read_only: read_only == 1 ? true : false,
        content: `${content}`,
        oldContent: `${content}`,
        raw_value: raw_value1,
        editorState: EditorState.createWithContent(contentState, myDecorator),
      },
      () => {
        // alert("")
      }
    );
  };

  handleNodeClick = () => {
    var childrendivs = [],
      children = document.getElementById("mySnippetDiv").children;
    this.recurevieCall(children);
  };

  handleReferenceClick = (rf) => {
    this.setState(
      {
        snippetType: rf.getAttribute("type"),
        snippetId: rf.id,
        dialogueStatus: true,
      },
      () => {
        this.nestedApicall(rf.id)}
    );
  };

  handleReportNameTextChange = (e) => {
    this.setState({
      channelName: e.target.value,
    });
  };

  creatReport = () => {
    if (this.state.channelName.length == 0) {
      toast.info(this.props.t("AddNewReport.channelValidation"));
    } else {
      const new_markdown = stateToMarkdown(
        this.state.editorState.getCurrentContent()
      );
      let html = stateToHTML(this.state.editorState.getCurrentContent());

      let finalResult = document.getElementById("megadraft-editor").innerHTML;
      finalResult =
        finalResult &&
        finalResult.replace(/<\s*br[^>]?>/, "\n").replace(/(<([^>]+)>)/g, "");

      // finalResult = finalResult.replace("<strong>", "||strong||");
      // finalResult = finalResult.replace("</strong>", "||/strong||");
      // finalResult = finalResult && finalResult
      //   .replace(/<\s*br[^>]?>/, "\n")
      //   .replace(/(<([^>]+)>)/g, "");

      this.setState(
        {
          content: html,
        },
        () => {
          this.apiCallCreateReport();
        }
      );
    }
  };

  insertCharacter = (characterToInsert, editorState, selectionState) => {
    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      selectionState,
      characterToInsert
    );

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      "insert-characters"
    );

    // return newEditorState;
  };
  apiCallCreateReport = () => {
    const event_id = this.props.newMessageEventItem.id;
    const { content, reportID, oldContent, channelName } = this.state;
    let replaced_event_details = content;

    apiCall(
      METHOD.QUERY,
      CreateReportDataQuery(
        event_id,
        getUserID(),
        reportID,
        channelName,
        JSON.stringify(oldContent),
        JSON.stringify(replaced_event_details)
      ),
      (data) => {
        const {
          message_id,
          channel_id,
          channel_type_id,
          channel_type_name,
          channel_type_icon,
          message_datetime,
          message_creator_name,
        } = data;

        const reportObj = {
          message_id,
          channel_id,
          channel_type_id,
          channel_type_name,
          channel_type_icon,
          message_datetime,
          message_creator_name,
        };

        toast.success(this.props.t(data.message));
        this.props.handleMessageModal()
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  handleEditorClick = () => {
    this.setState({
      showEditor: true,
    });
  };

  focus = () => {
    this.editor.focus();
  };

  render() {
    const {
      isEditable,
      content,
      EditContent,
      dialogueStatus,
      snippetType,
      snippetId,
      referenceValue,
      isLoading,
      raw_value,
      reportTypeList,
      reportID,
      loader
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            
            <div className="d-block">
                <div
                  className="modal fade show"
                  id="NewMessage"
                  tabindex="-1"
                  aria-labelledby="NewMessageLabel"
                  aria-modal="true"
                  style={{display:'block'}}
                >
                  <div className="modal-dialog modal-lg 0modal-dialog-centered 0modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-header border-0 d-block pt-4 pb-0">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={()=>{
                              this.props.handleMessageModal()
                            }}
                          >
                            <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                          </button>
                        <h5 className="modal-title">{t("AddNewReport.newMessageLable")}</h5>

                          <button
                            type="button"
                            className="btn btn-primary-theme event"
                            data-dismiss="modal"
                            onClick={this.creatReport}
                          >
                            {t("AddNewReport.btnSave")}
                          </button>
                        </div>
                      </div>
                      <div className="modal-body overflow-x-hidden">
                        {loader && <CommonLoader />}

                        <small className="d-block mb-2">  {t("AddNewReport.type")}</small>
                        <div className="radio-container d-flex overflow-x-auto mb-3 p-0">
                        {reportTypeList && reportTypeList.map((reportType,index)=>{
                          const { icon, name,id } = reportType;

                          return( <div
                          onClick={()=>{this.handleReportSelection(reportType)}}
                          key={index} className="custom-control custom-radio custom-control-box text-dark mr-3 mb-3">
                          <input
                            type="radio"
                            name="NeueMeldung"
                            id={id}
                            className="custom-control-input"
                            checked={reportID == id ? true : false}
                          />
                          <label
                            className="card-body py-3 px-2 mb-0 text-center custom-control-label"
                            htmlFor={id}
                          >
                            <p className="mb-2">
                              <span className="font-size-20 font-weight-bold">
                              {icon && (
                                  <img src={`data:image/png;base64,${icon && icon}`} style={{width:"24px",height:'24px'}} alt="brand_icon" />
                                )}
                              </span>
                            </p>
                            <p className="font-size-14 text-nowrap mb-0">{name}</p>
                          </label>
                        </div>
                      )
                        })}
                         </div>
                         <div className="form-row">
                          <div className="col-xl-12">
                            <div className="form-group">
                              <label className="font-size-14"> {t("AddNewReport.title")}
                                </label>
                              <input
                                type="text"
                                className="form-control"
                                onChange={this.handleReportNameTextChange}
                                name="title"
                                text={this.state.channelName}
                              />
                            </div>
                        </div>
                    </div>
                        <div className="clearfix">
                        <div className="pl-1 py-2 flex-fill border rounded megadraft-box" id="divMegadraft">
                        
                          <div className="w-100" id="mySnippetDiv">
                        <MegadraftEditor
                          editorState={this.state.editorState}
                          onChange={this.onChange}
                          ref={(element) => {
                            this.editor = element;
                          }}
                          onFocus={()=>{
                            document.getElementById("divMegadraft").classList.add('megadraftOnSelect')
                          }}

                          onBlur={()=>{
                            document.getElementById("divMegadraft").classList.remove('megadraftOnSelect')
                          }}
                        />
                      
                      </div>
                    </div>
                      </div>
                      </div>
                      
                      {dialogueStatus && snippetType == "selection" && (
                    <SelectionDialogue
                      closeDialogue={this.closeDialogue}
                      id={snippetId}
                      setDynamicValue={this.setFinalDynamicValue}
                      setDynamicValueForMultiLine={
                        this.setDynamicValueForMultiLine
                      }
                      setDynamicValueForMultiLineWithInnerSnippet={
                        this.setDynamicValueForMultiLineWithInnerSnippet
                      }
                      setFinalDynamicValueWithInnerSnippet={
                        this.setFinalDynamicValueWithInnerSnippet
                      }
                      handleReferenceClick={this.handleReferenceClick}
                    />
                  )}

                  {dialogueStatus && snippetType == "number" && (
                    <NumberDialogue
                      closeDialogue={this.closeDialogue}
                      id={snippetId}
                      setDynamicValue={this.setFinalDynamicValue}
                      setDynamicValueForMultiLine={
                        this.setDynamicValueForMultiLine
                      }
                      setFinalDynamicValueWithInnerSnippet={
                        this.setFinalDynamicValueWithInnerSnippet
                      }
                      setDynamicValueForMultiLineWithInnerSnippet={
                        this.setDynamicValueForMultiLineWithInnerSnippet
                      }
                      handleReferenceClick={this.handleReferenceClick}
                    />
                  )}

                  {dialogueStatus && snippetType == "text" && (
                    <FreeTextDialogue
                      closeDialogue={this.closeDialogue}
                      id={snippetId}
                      setDynamicValue={this.setFinalDynamicValue}
                      setDynamicValueForMultiLineWithInnerSnippet={
                        this.setDynamicValueForMultiLineWithInnerSnippet
                      }
                      setDynamicValueForMultiLine={
                        this.setDynamicValueForMultiLine
                      }
                      setFinalDynamicValueWithInnerSnippet={
                        this.setFinalDynamicValueWithInnerSnippet
                      }
                      handleReferenceClick={this.handleReferenceClick}
                    />
                  )}

                  {dialogueStatus && snippetType == "date_time" && (
                    <DateTimeDialogue
                      closeDialogue={this.closeDialogue}
                      id={snippetId}
                      setDynamicValueForMultiLine={
                        this.setDynamicValueForMultiLine
                      }
                      setDynamicValueForMultiLineWithInnerSnippet={
                        this.setDynamicValueForMultiLineWithInnerSnippet
                      }
                      setDynamicValue={this.setFinalDynamicValue}
                      setFinalDynamicValueWithInnerSnippet={
                        this.setFinalDynamicValueWithInnerSnippet
                      }
                      handleReferenceClick={this.handleReferenceClick}
                    />
                  )}
                
                  {dialogueStatus && snippetType == "condition" && (
                    <ConditionDialogue
                      closeDialogue={this.closeDialogue}
                      id={snippetId}
                      setDynamicValue={this.setFinalDynamicConditionalValue}
                      setDynamicValueForMultiLine={
                        this.setDynamicValueForMultiLine
                      }
                      setDynamicValueForMultiLineWithInnerSnippet={
                        this.setDynamicValueForMultiLineWithInnerSnippet
                      }
                      setFinalDynamicValueWithInnerSnippet={
                        this.setFinalConditionalDynamicValueWithInnerSnippet
                      }
                      handleReferenceClick={this.handleReferenceClick}
                    />
                  )}

                    
                    </div>
                  </div>
                </div>
                <div className="modal-backdrop fade show"></div>
              </div>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [ stateToProps.eventListStateToProps,
        stateToProps.eventStartStateToProps,
        stateToProps.eventHasMoreStateToProps,
        stateToProps.eventEventCountStateToProps,],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.reportListDispatchToProps,
  ])
)(withRouter(withTranslation()(AddNewReport)));
