/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { toast } from "react-toastify";
import { MaillingListQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
import LoadingPage from "../../CommanComponents/LoadingPage";
import { Translation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

class MailList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mail_list: [],
      start: 0,
      end: 10,
      hasMore: true,
    };
  }

  loadMailingList = () => {
    const { start, end, hasMore, isLoading } = this.state;
    if (!isLoading && hasMore) {
      this.setState({
        isLoading: true,
      });

      apiCall(
        METHOD.QUERY,
        MaillingListQuery(getUserID(), start, end, ""),
        (data) => this.handleMailListResponse(data),
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
          this.setState({
            hasMore: false,
            isLoading: false,
          });
        }
      );
    }
  };

  handleMailListResponse = (data) => {
    const { start, end } = this.state;
    if (data.is_last == 1) {
      this.setState({ hasMore: false });
    }

    this.setState((prevState) => {
      return {
        ...this.state,
        start: start + end,
        mail_list: [...prevState.mail_list, ...data.mailing_list],
        isLoading: false,
      };
    });
  };

  loader = (<LoadingPage key={0} />);

  handleSubMailList = (mail_id) => {
    this.props.changeMailRouteStatus(1, mail_id);
  };

  handleCheckBox = (e, checkMailID, child_element) => {
    let personCountForMail = this.props.personCountForMail;
    console.log("personCountForMail", personCountForMail);
    console.log("child_element", child_element);
    if (e.target.checked) {
      this.props.addSelectedMailId(checkMailID);
      personCountForMail = personCountForMail + child_element;
      this.props.updatePersonCountForMail(personCountForMail);
    } else {
      this.props.removeSelectedMailId(checkMailID);
      personCountForMail = personCountForMail - child_element;
      this.props.updatePersonCountForMail(personCountForMail);
    }
  };

  render() {
    const { end, hasMore, mail_list } = this.state;
    const { searchArray } = this.props;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="container my-2">
              <div className="d-flex align-items-center justify-content-between ">
                <div className="clearfix mb-2">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        {t("mailDetail.home")}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              {searchArray.length != 0 && (
                <div className="form-row mt-2">
                  {searchArray.map((item, index) => {
                    const { name, id, child_element } = item;
                    return (
                      <div className="col-6 mb-2 pointer">
                        <a
                          href="#"
                          key={index}
                          className="card shadow-sm p-3 px-2 bg-yellow-bottom justify-content-between  h-100"
                        >
                          <div className="d-flex justify-content-between">
                            <div
                              className="pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.changeMailRouteStatus(1, id);
                              }}
                            >
                              <h5 className="m-0 font-18 word-break">
                                {name && name}
                              </h5>
                              <p className="text-muted font-12 mb-0">
                                {child_element && child_element}{" "}
                                {t("mailDetail.receiver")}
                              </p>
                            </div>
                            <div className="align-self-end">
                              <label className="checkbox-container p-0 m-0">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    this.handleCheckBox(e, id, child_element)
                                  }
                                  checked={this.props.checkedList.includes(id)}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </div>
              )}

              {searchArray.length == 0 && (
                <InfiniteScroll
                  pageStart={end}
                  loadMore={this.loadMailingList}
                  hasMore={hasMore}
                  useWindow={false}
                  loader={this.loader}
                >
                  <div className="form-row mt-2">
                    {mail_list &&
                      mail_list.map((item, index) => {
                        const { name, id, child_element } = item;
                        return (
                          <div className="col-6 mb-2 pointer">
                            <a
                              href="#"
                              className="card shadow-sm p-3 px-2 bg-yellow-bottom justify-content-between  h-100"
                            >
                              <div className="d-flex justify-content-between">
                                <div
                                  className="pointer"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.changeMailRouteStatus(1, id);
                                  }}
                                >
                                  <h5 className="m-0 font-18 word-break">
                                    {name && name}
                                  </h5>
                                  <p className="text-muted font-12 mb-0">
                                    {child_element && child_element}{" "}
                                    {t("mailDetail.receiver")}
                                  </p>
                                </div>
                                <div className="align-self-end">
                                  <label className="checkbox-container p-0 m-0">
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        this.handleCheckBox(
                                          e,
                                          id,
                                          child_element
                                        )
                                      }
                                      checked={this.props.checkedList.includes(
                                        id
                                      )}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })}
                  </div>
                </InfiniteScroll>
              )}
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(MailList);
