/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Translation, withTranslation } from "react-i18next";
import Bounce from "react-reveal/Bounce";
import { toast } from "react-toastify";
import { SnippetDataQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID, stringToHTML } from "../../../util/utils";
// default_date Is remaining to call

class DateTimeDialogue extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      isLoading: false,
      nestedSnippetData: {},
      content: {},
      default_date: 0,
      set_date: false,
      set_time: false,
      hide_on_zero: false,
      startDate: new Date(),
      startTime: new Date(),
      datePickerIsOpen: false,
      timePickerIsOpen: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.openDatePicker = this.openDatePicker.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.openTimePicker = this.openTimePicker.bind(this);
  }

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  openDatePicker() {
    this.setState({
      ...this.state,
      datePickerIsOpen: !this.state.datePickerIsOpen,
    });
  }

  handleChange(date) {
    this.setState({
      startDate: date,
      datePickerIsOpen: false,
    });
  }

  openTimePicker() {
    this.setState({
      timePickerIsOpen: !this.state.timePickerIsOpen,
    });
  }

  handleTimeChange(time) {
    this.setState({
      ...this.state,
      startTime: time,
      timePickerIsOpen: false,
    });
  }

  componentDidMount() {
    const { id } = this.props;
    apiCall(
      METHOD.QUERY,
      SnippetDataQuery(id,getUserID()),
      (data) => {
        let jsonContent = JSON.parse(data.content);
        this.setState({
          nestedSnippetData: data,
          content: jsonContent,
          default_date: jsonContent.default_date,
          set_date: jsonContent.set_date,
          set_time: jsonContent.set_time,
          hide_on_zero: jsonContent.hide_on_zero,
          raw_value: JSON.parse(JSON.parse(data.raw_value)),
        });
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }
  convertToDate = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join(".");
  };
  convertToTime = (str) => {
    var date = new Date(str),
      hours = ("0" + date.getHours()).slice(-2),
      minutes = ("0" + date.getMinutes()).slice(-2);
    return [hours, minutes].join(":");
  };

  close_text_dialogue = () => {
    const { hide_on_zero } = this.state;

    const { setDynamicValue, closeDialogue, id } = this.props;

    if (hide_on_zero) {
      setDynamicValue(id, "");
      closeDialogue();
    } else {
      closeDialogue();
    }
  };

  submitData = () => {
    const {
      setDynamicValue,
      setFinalDynamicValueWithInnerSnippet,
      closeDialogue,
      id,
      setDynamicValueForMultiLine,
      setDynamicValueForMultiLineWithInnerSnippet,
    } = this.props;

    const {
      content,
      set_date,
      set_time,
      startDate,
      startTime,
      raw_value,
    } = this.state;

    let val = content.content;
    if (set_date && set_time) {
      val = val.replace(
        "<value>",
        this.convertToDate(startDate) + " " + this.convertToTime(startTime)
      );
    } else if (set_date) {
      val = val.replace("<value>", this.convertToDate(startDate));
    } else {
      val = val.replace("<value>", this.convertToTime(startTime));
    }

    let is_multiline = false;
    if (raw_value.blocks.length > 1) {
      is_multiline = true;
    }

    let input_value;
    if (set_date && set_time) {
      input_value =
        this.convertToDate(startDate) + " " + this.convertToTime(startTime);
    } else if (set_date) {
      input_value = this.convertToDate(startDate);
    } else {
      input_value = this.convertToTime(startTime);
    }

    let entityMap = raw_value.entityMap;

    if (is_multiline) {
      let blocks = raw_value.blocks;
      // find the value tag
      if (entityMap == 0) {
         for (let i in blocks) {
          let block = blocks[i];
          let text = block.text;
          const value_position = text.indexOf("<value>");

          if (value_position != -1) {
            text = text.replace("<value>", input_value);
            blocks[i].text = text;
            let inlineStyleRanges = blocks[i].inlineStyleRanges;

            for (let j in inlineStyleRanges) {
              let style = inlineStyleRanges[j];
              if (style.offset >= value_position) {
                const input_Value_length = input_value.length;
                let offset = style.offset;
                offset = offset + input_Value_length - 7;
                style.offset = offset;
                inlineStyleRanges[j] = style;
              }
            }
            blocks[i].inlineStyleRanges = inlineStyleRanges;
          }
        }
        setDynamicValueForMultiLine(id, raw_value);
      } else {
         for (let i in blocks) {
          let block = blocks[i];
          let text = block.text;
          const value_position = text.indexOf("<value>");

          if (value_position != -1) {
            text = text.replace("<value>", input_value);
            blocks[i].text = text;
            let inlineStyleRanges = blocks[i].inlineStyleRanges;

            // update style with new value
            for (let j in inlineStyleRanges) {
              let style = inlineStyleRanges[j];
              if (style.offset >= value_position) {
                const input_Value_length = input_value.length;
                let offset = style.offset;
                offset = offset + input_Value_length - 7;
                style.offset = offset;
                inlineStyleRanges[j] = style;
              }
            }
            blocks[i].inlineStyleRanges = inlineStyleRanges;

            let entityRange = blocks[i].entityRanges;
            for (let i in entityRange) {
              let entityElement = entityRange[i];
              let offset = entityElement.offset;
              if (offset > value_position) {
                const input_Value_length = input_value.length;
                offset = offset + input_Value_length - 7;
                entityElement.offset = offset;
                entityRange[i] = entityElement;
              }
            }
          }
        }
        setDynamicValueForMultiLineWithInnerSnippet(id, raw_value);
      }
    } else {
      if (entityMap == 0) {
        let innerReference = stringToHTML(val, Date.now());
        var children = innerReference.children;
        let referenceInSpan = children[0];

        referenceInSpan.onclick = (e) => {
          e.preventDefault();
          if (e.stopPropagation) e.stopPropagation();
          this.props.handleReferenceClick(referenceInSpan);
        };
        referenceInSpan.setAttribute("class", "reference-backgroupred");
        referenceInSpan.innerHTML = referenceInSpan.getAttribute("placeholder");
        setFinalDynamicValueWithInnerSnippet(id, input_value, raw_value);
      } else {
        let val1 = raw_value.blocks[0].text;
        let value_tag_position = val1.search("<value>");
        val1 = val1.replace("<value>", input_value);
        setDynamicValue(id, val1, raw_value, value_tag_position, input_value);
      }
    }

    closeDialogue();
  };

  render() {
    const { set_date, set_time, nestedSnippetData } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <Bounce bottom>
              <div
                className="navbar-collapse collapse text-left bottom-half show"
                id="Brand-toggler"
              >
                <div className="d-flex ">
                  <button
                    className="navbar-toggler icon-close mr-3 mt-3 w-100 text-right"
                    onClick={this.submitData}
                    type="button"
                    data-toggle="collapse"
                    data-target="#Brand-toggler"
                    aria-controls="Brand-toggler"
                    aria-expanded="true"
                    aria-label="Toggle navigation"
                  >
                    <i className="zmdi zmdi-save zmdi-hc-fw font-30"></i>
                  </button>

                  <button
                    className="navbar-toggler icon-close mr-3 mt-3 w-100 text-right"
                    onClick={() => {
                      this.close_text_dialogue();
                    }}
                    type="button"
                    data-toggle="collapse"
                    data-target="#Brand-toggler"
                    aria-controls="Brand-toggler"
                    aria-expanded="true"
                    aria-label="Toggle navigation"
                  >
                    <i className="zmdi zmdi-close zmdi-hc-fw font-30"></i>
                  </button>
                </div>

                <div className="text-center">
                  <p className="mt-4">
                    {nestedSnippetData.name && nestedSnippetData.name}
                  </p>

                  <div
                    className="row"
                    style={
                      set_date ? { display: "inline" } : { display: "none" }
                    }
                  >
                    <div className="col">
                      <label className="font-14">
                        {t("datePickerDialogue.dateLable")}
                      </label>
                      <div className="input-group">
                        <DatePicker
                          selected={this.state.startDate}
                          onChange={this.handleChange}
                          onClickOutside={this.openDatePicker}
                          open={this.state.datePickerIsOpen}
                          dateFormat="dd.MM.yyyy"
                          className="form-control combine-datepicker"
                          disabled
                        />
                        <div
                          className="input-group-append pointer"
                          onClick={this.openDatePicker}
                        >
                          <span className="input-group-text bg-white">
                            <i className="zmdi zmdi-calendar-note zmdi-hc-fw font-22"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row m-0"
                    style={
                      set_time ? { display: "inline" } : { display: "none" }
                    }
                  >
                    <div className="col">
                      <label className="font-14">
                        {t("datePickerDialogue.timeLable")}
                      </label>

                      <div className="form-group">
                        <div className="input-group date" id="datetimepicker3">
                          <DatePicker
                            selected={this.state.startTime}
                            onChange={this.handleTimeChange}
                            showTimeSelect
                            showTimeSelectOnly
                            timeCaption={t("datePickerDialogue.timeLable")}
                            timeIntervals={15}
                            timeFormat="HH:mm"
                            dateFormat="HH:mm"
                            onClickOutside={this.openTimePicker}
                            open={this.state.timePickerIsOpen}
                            className="form-control combine-timepicker pointer"
                            disabled
                          />

                          <div
                            className="input-group-append pointer"
                            onClick={this.openTimePicker}
                          >
                            <span className="input-group-text bg-white">
                              <i className="zmdi zmdi-time zmdi-hc-fw font-22"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Bounce>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(DateTimeDialogue);
