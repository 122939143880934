import React from "react";
import MediaQuery from "react-responsive";
import ChangeLanguageMobile from "./ChangeLanguageMobile";
import ChangeLanguageDesktop from "./ChangeLanguageDesktop";

export default function ChangeLanguageResponsive() {
  return (
    <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <ChangeLanguageDesktop />
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <ChangeLanguageMobile />
      </MediaQuery>
    </React.Fragment>
  );
}
