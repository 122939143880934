/* eslint eqeqeq: 0 */

import { Field, Formik } from "formik";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CreateSubMailQuery,
  MailtypeQuery,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";
import { CreateMailSchema } from "../../../util/validationSchema";
import ErrorMessageFormik from "../../CommanComponents/ErrorMessageFormik";

class AddSubMail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: false,
      brandList: [],
      mailType: "",
      mailImage: "",
      mailId: 0,
      isLoading: false,
    };
  }

  handleMailSelection = (item) => {
    const { name, icon, id } = item;
    this.setState(() => {
      return {
        ...this.state,
        status: false,
        mailType: name,
        mailImage: icon,
        mailId: id,
      };
    });
  };

  closeMailDialogue = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: false,
      };
    });
  };

  openMailDialogue = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
      };
    });
  };

  handleAfterCreateApiCall = (data, name, description, paranet_id) => {
    toast.success(this.props.t(data.message));
    let newAddedmail = {
      id: data.id,
      name: name,
      description: description,
      list_type_icon: this.state.mailImage,
      list_type_id: this.state.mailId,
      person: 0,
    };
    this.props.addSubMail([newAddedmail]);
    if (this.props.item.identifier == "list") {
      this.props.handleAddMailingListModal(true);
    } else {
      this.props.handleAddMailingListGroupModal(true);
    }
  };

  handleAfterMailApiCall = (data) => {
    let brandImageDefault, brandNameDefault, mailID;
    const { item } = this.props;
    const { id, name, icon } = item && item;
    brandNameDefault = name;
    brandImageDefault = icon;
    mailID = id;

    this.setState(() => {
      return {
        ...this.state,
        brandList: data.list_type_list,
        mailType: brandNameDefault,
        mailImage: brandImageDefault,
        mailId: mailID,
      };
    });
  };

  handleSubmit = (values) => {
    const { name, description } = values;
    const { mailId } = this.state;
    const mail_id = this.props.match.params.mail_id;
    apiCall(
      METHOD.QUERY,
      CreateSubMailQuery(getUserID(), mailId, name, description, mail_id),
      (data) => this.handleAfterCreateApiCall(data, name, description, mail_id),
      (isLoading) =>
        this.setState(() => {
          return { ...this.state, isLoading: isLoading };
        }),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  componentDidMount() {
    apiCall(
      METHOD.QUERY,
      MailtypeQuery(getUserID(),0),
      (data) => this.handleAfterMailApiCall(data),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  render() {
    const { mailType, mailImage, isLoading } = this.state;
    const { changeRouteStatus } = this.props;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <Formik
                enableReinitialize
                initialValues={{ description: "" }}
                validationSchema={CreateMailSchema(
                  t("MailList.tooShort"),
                  t("MailList.tooLong"),
                  t("MailList.mailNameErrorMessage"),
                  t("MailList.descriptionErrorMessage")
                )}
                onSubmit={(values) => {
                  this.handleSubmit(values);
                }}
              >
                {({ errors, touched, values, handleSubmit, setErrors }) => (
                  <div className="d-block">
                    <div
                      className="modal fade show"
                      id="NewEvent"
                      tabindex="-1"
                      aria-labelledby="NewEventLabel"
                      aria-modal="true"
                      style={{ display: "block" }}
                    >
                      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="modal-header border-0 d-block pt-4 pb-0">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                              <button
                                type="button"
                                onClick={() => {
                                  if (this.props.item.identifier == "list") {
                                    this.props.handleAddMailingListModal(false);
                                  } else {
                                    this.props.handleAddMailingListGroupModal(
                                      false
                                    );
                                  }
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                              </button>




                              <h5
                                className="modal-title"
                                style={{ marginLeft: "45px" }}
                              >
                        {t("MailDetailScreen.NewSubMail")}
                              </h5>

                              {isLoading ? (
                                <div className="btn btn-primary-theme list pointer">
                                  {t("AdminAddUser.save")}
                                  <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                    <span className="sr-only">
                                      {t("GeneralMessages.loading")}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="btn btn-primary-theme list pointer"
                                  onClick={handleSubmit}
                                >
                                  {t("AdminAddUser.save")}
                                </div>
                              )}
                            </div>
                           
                          </div>
                          <div className="modal-body">
                            <div className="middle-section sab-middle-section1">
                              <div className="container my-4 00z-index-99">
                                <form>
                                <div
                                    onClick={this.openMailDialogue}
                                    className=" pointer custom-control custom-radio form-group max-170 m-auto custom-control-box text-dark text-center mb-3"
                                  >
                                   
                                    <input
                                      type="radio"
                                      name="NeuesEreignis"
                                      className="custom-control-input"
                                      checked={true}
                                    />
                                    <label className="card-body py-3 px-2 mb-0 text-center custom-control-label">
                                      <p className="mb-2">
                                        {mailImage && (
                                          <img
                                            src={`data:image/png;base64,${
                                              mailImage && mailImage
                                            }`}
                                            alt="brand_icon"
                                            style={{
                                              width: "32px",
                                              height: "32px",
                                            }}
                                          />
                                        )}
                                      </p>
                                      <p className="font-size-14 text-nowrap mb-0">
                                        {mailType && mailType}
                                      </p>
                                    </label>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("MailDetailScreen.titleInternal")}
                                    </label>
                                    <Field
                                      type="text"
                                      name="name"
                                      placeholder={t(
                                        "MailDetailScreen.titleInternal"
                                      )}
                                      className="form-control"
                                      required
                                    />
                                    <ErrorMessageFormik name="group_name" />
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("MailDetailScreen.description")}
                                    </label>
                                    <Field
                                      as="textarea"
                                      name="description"
                                      placeholder={t(
                                        "MailDetailScreen.description"
                                      )}
                                      className="form-control"
                                      required
                                    />
                                    <ErrorMessageFormik name="description" />
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                  </div>
                )}
              </Formik>
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.eventListStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.mailListDispatchToProps,
    actions.mailDetailsDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(AddSubMail)));
