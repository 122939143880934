import React, { Component } from 'react'
import MediaQuery from "react-responsive";
import ManageMenuMobile from "./ManageMenuMobile/Index";
import ManageMenuDesktop from "./ManageMenuDesktop/MenuList";
import { withRouter } from 'react-router';
import { checkAdminPermission } from '../../../util/utils';
class ManageMenuResponsive extends Component {
  componentDidMount() {
    if(!checkAdminPermission()){
      this.props.history.push("/")
    }
  }
  render() {
    return (
      <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <ManageMenuDesktop />
        
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <ManageMenuMobile />
      </MediaQuery>
    </React.Fragment>
    )
  }
}
export default withRouter(ManageMenuResponsive)