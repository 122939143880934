import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import AddSubMail from "../../Mail/MailListDesktop/AddSubMail";
import EditMail from "../../Mail/MailListDesktop/EditMail";

class MailListItemListView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addMailingListModal: false,
      editMailing: false,
      addMailingListGroupModal:false
    };
  }

  handelMailItemClick = (mail_id_range) => {
    this.props.history.push("/mail-detail/" + mail_id_range);
  };

  handleAddMailingListModal = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          addMailingListModal: !prev.addMailingListModal,
        };
      },
      () => {
        if (this.state.addMailingListModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            // window.location.reload();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  
  handleAddMailingListGroupModal = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          addMailingListGroupModal: !prev.addMailingListGroupModal,
        };
      },
      () => {
        if (this.state.addMailingListGroupModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            // this.getMailDetail();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  handleEditMail = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          editMailing: !prev.editMailing,
        };
      },
      () => {
        if (this.state.editMailing) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            window.location.reload();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };
  render() {
    const { data, index } = this.props;

    const { id, name, child_element, list_type_icon, person } = data;
    const { addMailingListModal, editMailing,addMailingListGroupModal } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            // <div className="col-lg-8 mb-4" key={index}>
            //   <a
            //     href="javascript:;"
            //     className="card list-card"
            //     onClick={(e) => {
            //       e.preventDefault();
            //       this.props.history.push("/mail-detail/" + id);
            //     }}
            //   >
            //     <div className="card-body p-0">
            //       <div className="media">
            //         <div className="bg-yellow left-icon-box-medium d-flex align-items-center justify-content-center text-center">
            //           <div className="circle big m-0">
            //             <img
            //               src={`data:image/png;base64,${
            //                 list_type_icon && list_type_icon
            //               }`}
            //               alt="ico"
            //               style={{ height: "20px", width: "20px" }}
            //             />
            //           </div>
            //         </div>

            //         <div
            //           className="clearfix p-3"
            //           style={{ textAlign: "start" }}
            //         >
            //           <h6 className="mb-1">{name}</h6>
            //           <small className="text-muted text-break">
            //             {child_element && child_element}{" "}
            //             {t("mailDetail.receiver")}
            //           </small>
            //         </div>
            //       </div>
            //     </div>
            //   </a>
            // </div>
            <div className="card pointer" key={index}>
              <div className="card-header-custom pointer">
                <div className="media">
                  <div className="bg-grey left-icon-box d-flex align-items-center justify-content-center text-center">
                    <div
                      className="bg-yellow left-icon-box d-flex align-items-center justify-content-center text-center"
                      style={{
                        background: "#f2ae00",
                      }}
                    >
                      <div className="circle big m-0">
                        <img
                          src={`data:image/png;base64,${
                            list_type_icon && list_type_icon
                          }`}
                          alt="ico"
                          style={{ width: "24px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="media-body pt-3 px-4 pointer">
                    <div className="form-row">
                      <div
                        className="col-md-4"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push("/mail-detail/" + id);
                        }}
                      >
                        <h5 className="font-size-18 mb-1">{name && name}</h5>
                        <div className="d-flex align-items-center font-size-14 mt-2">
                          <div className="icon mr-3">
                            {child_element || "0 "} {t("mailDetail.receiver")}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="font-size-14 action-icon text-right">
                          <a
                            href="javascript;:"
                            className="mr-4"
                            data-toggle="modal"
                            data-target="#NewMessage"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleAddMailingListModal();
                            }}
                          >
                            <i className="zmdi zmdi-plus zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                            {t("mailDetail.addMailingListGroup")}
                          </a>
                          <a
                            href="javascript;:"
                            className="mr-4"
                            data-toggle="modal"
                            data-target="#NewMessage"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleAddMailingListGroupModal();
                            }}
                          >
                             <i className="zmdi zmdi-plus zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                            {t("mailDetail.addMailingList")}
                          </a>

                          <a
                            href="javascript;:"
                            className="mr-4"
                            data-toggle="modal"
                            data-target="#NewMessage"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleEditMail();
                            }}
                          >
                            <i className="zmdi zmdi-edit zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                            {t("mailDetail.editMail")}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {addMailingListModal && (
                  <AddSubMail
                    item={{
                      id: 2,
                      name: "Simple List",
                      description: "Simple List",
                      identifier: "list",
                      icon: "iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD3SURBVHgB3ZTLCcMwDIYt51JysjNBjh2hY3ST0Ftupceemo7QTTJCRugEsW/JKalcQiiBWkLQBxEYP9AnGUn8SjEty7KjtdaFhecLl0s4TiHgOI4lHjfT2qVpCl3X1RQLimHh17iZxbN3zlmK1erDxkqA5bku3wDgxkB5Pej7vsaah3JswxUTnrE8pfoHI5tsjMm11gX+eo/XfHpusETNMAwn7/1dnACDFxioigYAOLRtW4kSvBnPpUXHlZoiKjjXZy0mFbQYl7w4iQSN4uYpkgoaxX1P7KSCRnFzD6SC9nMhfDZZKmgcDqSCxuVAKmhcTiu5oLG4B4W9ojGP+hlUAAAAAElFTkSuQmCC",
                      __typename: "Listtypelist",
                    }}
                    parentId={id}
                    handleAddMailingListModal={(isFromSave) => {
                      this.handleAddMailingListModal(isFromSave);
                    }}
                  />
                )}

{addMailingListGroupModal && (
                          <AddSubMail
                            item={{
                              id: 1,
                              name: "Group",
                              description: "Group",
                              identifier: "group",
                              icon: "iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACGSURBVHgB7ZQxDoAgDEW/xoPgGVi4/84Z4B4MWkxcNPQzVE0MbyhDm5T+X5ig4L3f0EGMcWrlZgwIN+16dW9x9WN4QDn0Et2THA62ZPFjPT1wsMfV8LjJ3zaoO639MyxPG1iw9BSxW2qoE4QQHAisRp2glJLkjYDUqPmfr+lo8GqDDHtyDTsZwyQcDhELBwAAAABJRU5ErkJggg==",
                              __typename: "Listtypelist",
                            }}
                           parentId={id}
                            handleAddMailingListGroupModal={(isFromSave) => {
                              this.handleAddMailingListGroupModal(isFromSave);
                            }}
                          />
                        )}

                {editMailing && (
                  <EditMail
                    handleEditMail={(isFromSave) => {
                      this.handleEditMail(isFromSave);
                    }}
                    parentId={id}
                    data={data}
                  />
                )}
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(MailListItemListView));
