import React, { Component } from "react";

import { Translation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

class GridTypeMenu extends Component {
  render() {
    const {
      system_email_count,
      snippet_count,
      person_data_type_count,
      channel_count,
      location_count,
      user_count,
      side_menu_count,
      report_type_count,
      channel_type_count,
      group_type_count,
      event_type_count,
      list_type_count,
      default_snippet_count,
    } = this.props.data;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="row mt-4 card-min-ht-100p">
              <div className="col-lg-3 mb-4 ">
                <Link to="/manage-menu" className="card h-100 m-0">
                  <div className="card-body py-3 text-center">
                    <h6 className="mb-1">
                      {t("administration.manageMenuList")}
                    </h6>
                    <small className="text-muted">
                      {" "}
                      {side_menu_count && side_menu_count}
                      {t("administration.MenuItemCount")}
                    </small>
                  </div>
                </Link>
              </div>

              <div className="col-lg-3 mb-4 ">
                <Link to="/manage-emails" className="card h-100 m-0">
                  <div className="card-body py-3 text-center">
                    <h6 className="mb-1">
                      {" "}
                      {t("administration.manageEmailTitle")}
                    </h6>
                    <small className="text-muted">
                      {" "}
                      {system_email_count && system_email_count}
                      {t("administration.emailCount")}
                    </small>
                  </div>
                </Link>
              </div>

              <div className="col-lg-3 mb-4 ">
                <Link to="/manage-location" className="card h-100 m-0">
                  <div className="card-body py-3 text-center">
                    <h6 className="mb-1">
                      {" "}
                      {t("administration.manageLocationList")}
                    </h6>
                    <small className="text-muted">
                      {" "}
                      {location_count && location_count}
                      {t("administration.locationCount")}
                    </small>
                  </div>
                </Link>
              </div>

              <div className="col-lg-3 mb-4 ">
                <Link to="/manage-user" className="card h-100 m-0">
                  <div className="card-body py-3 text-center">
                    <h6 className="mb-1">
                      {" "}
                      {t("administration.manageUserTitle")}
                    </h6>
                    <small className="text-muted">
                      {" "}
                      {user_count && user_count}
                      {t("administration.userCount")}
                    </small>
                  </div>
                </Link>
              </div>

              <div className="col-lg-3 mb-4 ">
                <Link to="/channel-list" className="card h-100 m-0">
                  <div className="card-body py-3 text-center">
                    <h6 className="mb-1">
                      {t("administration.manageChannelTitle")}
                    </h6>
                    <small className="text-muted">
                      {" "}
                      {channel_count && channel_count}
                      {t("administration.ChannelCount")}
                    </small>
                  </div>
                </Link>
              </div>

              <div className="col-lg-3 mb-4 ">
                <Link to="/manage-eventlist-type" className="card h-100 m-0">
                  <div className="card-body py-3 text-center">
                    <h6 className="mb-1">
                      {" "}
                      {t("administration.manageEventistTypeTitle")}
                    </h6>
                    <small className="text-muted">
                      {" "}
                      {event_type_count && event_type_count}
                      {t("administration.EventtypeCount")}
                    </small>
                  </div>
                </Link>
              </div>

              <div className="col-lg-3 mb-4 ">
                <Link to="/manage-report-type" className="card h-100 m-0">
                  <div className="card-body py-3 text-center">
                    <h6 className="mb-1">
                      {" "}
                      {t("administration.manageReportTypeTitle")}
                    </h6>
                    <small className="text-muted">
                      {" "}
                      {report_type_count && report_type_count}
                      {t("administration.reportTypeCount")}
                    </small>
                  </div>
                </Link>
              </div>

              <div className="col-lg-3 mb-4 ">
                <Link to="/manage-channel-type" className="card h-100 m-0">
                  <div className="card-body py-3 text-center">
                    <h6 className="mb-1">
                      {" "}
                      {t("administration.manageChannelTypeTitle")}
                    </h6>
                    <small className="text-muted">
                      {" "}
                      {channel_type_count && channel_type_count}
                      {t("administration.ChannelTypeCount")}
                    </small>
                  </div>
                </Link>
              </div>

              <div className="col-lg-3 mb-4 ">
                <Link to="/manage-snippet" className="card h-100 m-0">
                  <div className="card-body py-3 text-center">
                    <h6 className="mb-1">
                      {" "}
                      {t("administration.manageSnippetTitle")}
                    </h6>
                    <small className="text-muted">
                      {snippet_count && snippet_count}{" "}
                      {t("administration.snippetCount")}
                    </small>
                  </div>
                </Link>
              </div>

              <div className="col-lg-3 mb-4 ">
                <Link to="/manage-default-snippet" className="card h-100 m-0">
                  <div className="card-body py-3 text-center">
                    <h6 className="mb-1">
                      {" "}
                      {t("administration.manageDefaultSnippetTitle")}
                    </h6>
                    <small className="text-muted">
                      {default_snippet_count && default_snippet_count}
                      {t("administration.defaultSnippetCount")}
                    </small>
                  </div>
                </Link>
              </div>

              <div className="col-lg-3 mb-4 ">
                <Link to="/manage-person-datatype" className="card h-100 m-0">
                  <div className="card-body py-3 text-center">
                    <h6 className="mb-1">
                      {" "}
                      {t("administration.managePersonDatatypeList")}
                    </h6>
                    <small className="text-muted">
                      {" "}
                      {person_data_type_count && person_data_type_count}
                      {t("administration.PersonDataTypeCount")}
                    </small>
                  </div>
                </Link>
              </div>

              <div className="col-lg-3 mb-4 ">
                <Link to="/manage-group-type" className="card h-100 m-0">
                  <div className="card-body py-3 text-center">
                    <h6 className="mb-1">
                      {" "}
                      {t("administration.manageGroupTypeTitle")}
                    </h6>
                    <small className="text-muted">
                      {" "}
                      {group_type_count && group_type_count}
                      {t("administration.groupypeCount")}
                    </small>
                  </div>
                </Link>
              </div>

              <div className="col-lg-3 mb-4 ">
                <Link to="/manage-list-type" className="card h-100 m-0">
                  <div className="card-body py-3 text-center">
                    <h6 className="mb-1">
                      {t("administration.manageListTypeTitle")}
                    </h6>
                    <small className="text-muted">
                      {list_type_count && list_type_count}
                      {t("administration.ListtypeCount")}
                    </small>
                  </div>
                </Link>
              </div>

              <div className="col-lg-3 mb-4 ">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.handleSystemSetupdialogue();
                  }}
                  className="card h-100 m-0"
                >
                  <div className="card-body py-3 text-center">
                    <h6 className="mb-1">{t("administration.setupSystem")}</h6>
                  </div>
                </a>
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(GridTypeMenu);
