/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { getFacebookList, getSocialDetails, makefacebookuserpageaccesstoken } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID, saveDatatoLocalStorage } from "../../../util/utils";
import FacebookLogin from "react-facebook-login";
import HeaderDesktop from "../../Header/HeaderDesktop";

class FacebookConnection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      appID: "",
      appSecretKey: "",
      page_list: [],
      page_id: '',
      accessToken: ''
    };
  }

  componentDidMount() {
    apiCall(
      METHOD.QUERY,
      getSocialDetails(getUserID()),
      (data) => {
        this.setState({
          appID: data.facebook_app_id,
          appSecretKey: data.facebook_app_secret,
        });
      },
      (isLoading) => { },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  responseFacebook = (response) => {
    console.log("responseresponse",response)
    if (response.accessToken) {
      this.getFacebookPageList(response.accessToken)

     
      // saveDatatoLocalStorage(
      //   "fb_accessToken_expire",
      //   response.data_access_expiration_time
      // );
    

    } else {
      toast.info(this.props.t("ConnectionPage.facebook_connect_failed"));
    }

  };

  getFacebookPageList = (accessToken) => {
    apiCall(
      METHOD.QUERY,
      getFacebookList(getUserID(), accessToken),
      (data) => {
        this.setState({
          page_list: data.page_list,
          accessToken,
          page_id: data.page_list.length != 0 && data.page_list[0].id
        });
      },
      (isLoading) => { },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  checkedStatus = (page_id) => {
    if (this.state.page_id == page_id) {
      return true
    } else {
      return false
    }
  }
  handleRadioButton = (e, page_id) => {
    if (e.target.checked) {
      this.setState({
        page_id
      })
    }
  }
  handleBackStack = () => {
    let { from } = this.props.location.state || { from: { pathname: "/" } };
    this.props.history.push(from);
  };
  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };
  render() {
    const { appID, appSecretKey, page_list, page_id, isLoading,
      accessToken } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <div className="header-bg header-red sab-header1 sticky-top">
                {/* <HeaderDesktop/> */}
                <div className="container">
                  <div className="py-2 d-flex align-items-center">
                    <span>
                      <i
                        className="zmdi zmdi-close zmdi-hc-fw font-30 text-light pointer"
                        onClick={this.handleBackStack}
                      ></i>
                    </span>
                  </div>
                  <div className="text-center mt-4 text-light">
                    <h2 className="m-0 text-light">
                      {t("ConnectionPage.facebook_connection")}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="middle-section sab-middle-section1">
                <div className="container my-4 z-index-99">
                {appID && (
                     <FacebookLogin
                     appId={appID}
                     style={{ textAlign: 'center' }}
                     client_secret={appSecretKey}
                     scope="public_profile,pages_show_list,pages_manage_posts"
                     fields="name,email,accounts"
                     callback={this.responseFacebook}
                     onClick={this.componentClicked}
                     authType='reauthenticate'
                     icon="fa-facebook"
                   ></FacebookLogin>
                  )}

                  <h5 className="m-3 " style={{ textAlign: 'center' }} >
                    {t("ConnectionPage.facebook_page_list")}
                  </h5>

                  {page_list && page_list.map((item, index) => {
                    const { name, id } = item
                    return (<div className="icon-box-style3 mb-4 " key={index}>
                      <div className="ml-3 my-2 d-flex">
                        <div className="d-flex w-100">
                          <div className="align-self-center">
                            <label className="checkbox-container">
                              <input
                                id={id}
                                type="radio"
                                onChange={e => this.handleRadioButton(e, id)}
                                checked={this.checkedStatus(id)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          <a
                            href="javascript;:"
                            onClick={(e) => {
                              e.preventDefault()
                            }}
                            className="clearfix d-flex align-items-center flex-fill"
                          >
                            <div className="align-self-center">
                              <h5 className="m-0 font-18 d-block">
                                {name && name}
                              </h5>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>)
                  })}

                  {isLoading ? (<button
                    type="button"
                    style={{ maxWidth: "250px", margin: "auto" }}
                    className="btn btn-success btn-block"
                  >
                    {t("ConnectionPage.facebook_btn_lable")}

                    < div className="spinner-border spinner-border-sm ml-1 text-white" >
                      <span className="sr-only">
                        {t("GeneralMessages.loading")}
                      </span>
                    </div>
                  </button>) : page_list.length != 0 ? (

                    <button
                      type="button"
                      className="btn btn-success btn-block mt-2 "
                      style={{ maxWidth: "250px", margin: "auto" }}
                      onClick={() => {

                        if (!page_id) {
                          toast.info(this.props.t("ConnectionPage.pageSelection_validation"));
                        } else {
                          apiCall(
                            METHOD.QUERY,
                            makefacebookuserpageaccesstoken(page_id, accessToken, getUserID()),
                            (data) => {
                              saveDatatoLocalStorage("fbpageId", page_id);


                              const isFromCreate = this.props.location.state?.isFromCreate
                              const textValueObject = this.props.location.state?.textValueObject
                        
                              let facebookResponse = {}
                              let profileLink  = `https://facebook.com/`
                              facebookResponse.user_access_token  = this.state.accessToken
                              facebookResponse.isConnected = 1
                              facebookResponse.profileLink = profileLink
                              facebookResponse.page_id = page_id
                              facebookResponse.channelType= "facebook"
                              facebookResponse.textValueObject=textValueObject

                              if(isFromCreate){
                                facebookResponse.comeFrom = "createFacebook"
                              }else{
                                const channelId = this.props.location.state?.channelId
                                facebookResponse.comeFrom = "editFacebook"
                                facebookResponse.channelId = channelId
                              }
                              facebookResponse = JSON.stringify(facebookResponse)
                              saveDatatoLocalStorage("tempConnectResponse", facebookResponse);

                              this.props.history.push({
                                pathname: '/channel-list',
                                search: '',
                                state: { isFromFacebookConnect: true }
                            }); 

                              toast.success(this.props.t(data.message));
                              // this.handleBackStack()
                            },
                            (isLoading) => { this.setState({ isLoading }) },
                            (data) => {
                              toast.error(this.props.t(data.message));
                            }
                          );
                        }

                      }}
                    >
                      {t("ConnectionPage.facebook_btn_lable")}
                    </button>) : null}
                </div>
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(FacebookConnection));