/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { toast } from "react-toastify";
import { EventListQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
import CommonLoader from "../../CommanComponents/CommonLoader";
import LoadingPage from "../../CommanComponents/LoadingPage";
import EventItem from "./EventItem";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import AddNewEvent from "./AddNewEvent";
import AddNewReport from "../../Home/HomeDesktop/AddNewReport";

class ActiveEventList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventList: [],
      start: 0,
      end: 10,
      hasMore: true,
      isLoading: false,
      eventOperationType:'',
      newEventModalOpen:false,
      messageModalOpen:false,
      newMessageEventId:0,
      newMessageEventTypeId:0
    };
  }

  handleLoader = (status) => {
    this.setState(() => {
      return {
        ...this.state,
        isLoading: status,
      };
    });
  };
 
handleNeweventModal=(isFromSave)=>{
  this.setState((prev)=>{
    return({
      newEventModalOpen:!prev.newEventModalOpen
    })
  },()=>{
    if(this.state.messageModalOpen){
      document.body.classList.add("modal-open");
    }else{
      if(isFromSave){
        this.setState({
          hasMore:true,
          isLoading:false,
          start:0,
          end:10,
          eventList:[]
        },()=>{
          this.loadActiveEvent()
        })
    
      }
      document.body.classList.remove("modal-open");
    }
  })
}


handleEditeventModal =(eventId,isFromSave)=>{
  this.setState((prev)=>{
    return({
      eventOperationType:prev.newEventModalOpen?'':'edit',
      newEventModalOpen:!prev.newEventModalOpen,
      editEventId:eventId
    })
  },()=>{
    if(this.state.messageModalOpen){
      document.body.classList.add("modal-open");
    }else{
      if(isFromSave){
        this.setState({
          hasMore:true,
          isLoading:false,
          start:0,
          end:10,
          eventList:[]
        },()=>{
          this.loadActiveEvent()
        })
    
      }
      document.body.classList.remove("modal-open");
    }
  })
}


handleMessageModal =(data)=>{
  this.setState((prev)=>{
    return({
      messageModalOpen:!prev.messageModalOpen,
      newMessageEventItem:data,
    })
  },()=>{
    if(this.state.messageModalOpen){
      document.body.classList.add("modal-open");
    }else{
      document.body.classList.remove("modal-open");
    }
  })
}

  loadActiveEvent = () => {
    const { start, end, hasMore, isLoading } = this.state;
    if (!isLoading && hasMore) {
      this.setState({
        isLoading: true,
      });

      apiCall(
        METHOD.QUERY,
        EventListQuery(getUserID(), start, end, 1, ""),
        (data) => this.handleActiveResponse(data),
        (isLoading) => {this.handleLoader(isLoading)},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };
  componentDidMount() {
    window.scrollTo(0, 0)
    this.loadActiveEvent();
  }

  handleArchiveEvent =()=>{
    this.setState({
      hasMore:true,
      isLoading:false,
      start:0,
      end:10,
      eventList:[]
    },()=>{
      this.loadActiveEvent()
    })
  }
  handleActiveResponse = (data) => {
    const { start, end } = this.state;
    if (data.is_last == 1) {
      this.setState({ hasMore: false });
    }

    this.setState((prevState) => {
      return {
        ...this.state,
        start: start + end,
        eventList: [...prevState.eventList, ...data.event_list],
        isLoading: false,
      };
    });
  };

  loader = (<CommonLoader key={0} />);

  
  render() {
    const { hasMore, eventList,isLoading,newMessageEventItem,newEventModalOpen,messageModalOpen } = this.state;
    const {  searchHasMore, searchEvent } = this.props.searchData;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              {isLoading && this.loader}

              {newEventModalOpen && <AddNewEvent 
                            eventId={this.state.editEventId}
                            operation={this.state.eventOperationType}
                            handleEditeventModal={(id,isFromSave)=>{this.handleEditeventModal(id,isFromSave)}}
                            handleNeweventModal={(isFromSave)=>{this.handleNeweventModal(isFromSave)}}/>}

                        {messageModalOpen && <AddNewReport 
                            handleMessageModal={()=>{this.handleMessageModal()}}
                            newMessageEventItem={newMessageEventItem}
                            />}
                            
              {searchEvent && searchEvent.length == 0 ? (
                <div>
                  {eventList &&
                    eventList.map((data, index) => {
                      return (
                        <EventItem
                        eventType={1}
                        handleMessageModal ={(data)=>{this.handleMessageModal(data)}}
                        handleEditeventModal={(eventId)=>{this.handleEditeventModal(eventId)}}
                        handleArchiveEvent={()=>{this.handleArchiveEvent()}}
                        data={data} key={index} index={index} />
                      );
                    })}

                  {hasMore && (
                    <div className="text-center mt-5">
                      <button
                        type="button"
                        className="btn btn-primary-theme event"
                        onClick={(e) => {
                          e.preventDefault();
                          this.loadActiveEvent();
                        }}
                      >
                        {t("HomeScreen.showMore")}
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="container my-4">
                  {searchEvent &&
                    searchEvent.map((data, index) => {
                      return <EventItem 
                      eventType={1}
                      handleMessageModal ={(data)=>{this.handleMessageModal(data)}}
                      handleEditeventModal={(eventId)=>{this.handleEditeventModal(eventId)}} data={data} key={index}
                      handleArchiveEvent={()=>{this.handleArchiveEvent()}}
                      />})
                      
                      }

                  {searchHasMore && (
                    <div className="text-center mt-5">
                      <button
                        type="button"
                        className="btn btn-primary-theme event"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.searchApi();
                        }}
                      >
                        {t("HomeScreen.showMore")}
                      </button>
                    </div>
                  )}
                </div>
              )}
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(withRouter(ActiveEventList));
