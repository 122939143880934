/* eslint eqeqeq: 0 */
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateToMarkdown } from "draft-js-export-markdown";
import { stateFromHTML } from "draft-js-import-html";
import { editorStateFromRaw, MegadraftEditor } from "megadraft";
import "megadraft/dist/css/megadraft.css";

import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkCost,
  checksmscost,
  CreateReportChannelQuery,
  getChannelTypeList,
  ReortDetailQuery,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";
import Footer from "../../Footer/FooterMobile";
import LoadingPage from "../../CommanComponents/LoadingPage";
import ChannelTypeList from "./ChannelTypeList";

const snippet_type_cost = ["text2speech", "sms"];
const snippet_type_count = ["sms", "twitter"];

class AddNewChannelReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      channel_type_Image: "EB",
      channel_type_name: "Einberufung",
      channel_type_id: 0,
      channel_type_identifier: "",
      chaanne_list: [],
      reportDialogueStatus: false,
      channelName: "",

      // Display snippet
      content: ``,
      oldContent: ``,
      editorState: editorStateFromRaw(null),
      snippetType: "",
      snippetId: 0,
      snippetKbdId: 0,
      dialogueStatus: false,
      isLoading: false,
      isLoadingSubmit: false,
      costEditorState: editorStateFromRaw(null),
      cost: 0,
      snippetCount: 0,
      isRequestAnswer:0
    };
    this.setEditor = (editor) => {
      this.editor = editor;
    };
    this.focusEditor = () => {
      if (this.editor) {
        this.editor.focus();
      }
    };
  }

  apiCallSnippet = () => {
    apiCall(
      METHOD.QUERY,
      ReortDetailQuery(this.props.channelList.report_id),
      (data) => {
        this.handleSnipetResopnse(data);
      },
      (isLoading) => {
        this.setState({ isLoadingSubmit: isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  onEditorChange = (editorState) => {
    const { channel_type_identifier } = this.state;
    this.setState({ snippetCount: 0 });
    if (snippet_type_cost.includes(channel_type_identifier)) {
      let markdown = stateToMarkdown(
        this.state.costEditorState.getCurrentContent()
      );
      let new_markdown = stateToMarkdown(editorState.getCurrentContent());
      markdown = markdown.trim();
      new_markdown = new_markdown.trim();

      let length2 = new_markdown.length;
      let length1 = markdown.length;

      if (length2 - length1 >= 10 || length1 > length2) {
        this.checkTextCost(new_markdown, channel_type_identifier);
        this.setState({ costEditorState: editorState });
      }
    }
    if (snippet_type_count.includes(channel_type_identifier)) {
      let html = stateToHTML(editorState.getCurrentContent());
      let textContent = html
        .replace(/<\s*br[^>]?>/, "\n")
        .replace(/(<([^>]+)>)/g, "");
      textContent = textContent.trim();
      textContent = textContent.replace("&nbsp;", "");

      let length2 = textContent.length;
      this.setState({ snippetCount: length2 });
    }

    this.setState({ editorState });
  };

  handleSnipetResopnse = (data) => {
    const { content } = data;
    const { channel_type_identifier, editorState } = this.state;
    let content_result = JSON.parse(content);
    let old_content = content_result.content;
    let result = content_result.result;

    this.editordata = result;
    result = result.trim();
    result = result.replace(/<br\s*\/?>/gi, " ");
    // if (!result.includes("<br")) {
    //   result = result.split("\n").join("<br/>");
    // }
    this.setState(
      () => {
        return {
          oldContent: `${old_content}`,
          content: `${result}`,
          editorState: EditorState.createWithContent(stateFromHTML(result)),
          costEditorState: EditorState.createWithContent(stateFromHTML(result)),
        };
      },
      () => {
        if (snippet_type_cost.includes(channel_type_identifier)) {
          const markdown = stateToMarkdown(
            this.state.costEditorState.getCurrentContent()
          );
          this.checkTextCost(markdown, channel_type_identifier);
        }

        if (snippet_type_count.includes(channel_type_identifier)) {
          const new_markdown = stateToMarkdown(editorState.getCurrentContent());
          let length2 = new_markdown.length;
          this.setState({ snippetCount: length2 });
        }
      }
    );
  };

  checkTextCost = (markdown, channel_type_identifier) => {
    if (channel_type_identifier == "sms") {
      apiCall(
        METHOD.QUERY,
        checksmscost(
          getUserID(),
          this.state.channel_type_id,
          JSON.stringify(markdown),
          channel_type_identifier
        ),
        (data) => {
          this.setState({
            cost: data.price,
          });
        },
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    } else {
      apiCall(
        METHOD.QUERY,
        checkCost(
          getUserID(),
          this.state.channel_type_id,
          JSON.stringify(markdown),
          channel_type_identifier
        ),
        (data) => {
          this.setState({
            cost: data.price,
          });
        },
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };
  componentDidMount() {
    var megadraft = document.querySelector("#megadraft-editor");
    megadraft.onclick = function () {
      this.focus();
    };
    apiCall(
      METHOD.QUERY,
      getChannelTypeList(getUserID(),0),
      (data) => {
        let brandImageDefault, brandNameDefault, brandID, brand_identifier ;
        if (data.channel_list && data.channel_list.langth !== 0) {
          brandNameDefault = data.channel_list[0].name;
          brandImageDefault = data.channel_list[0].channel_type_icon;
          brandID = data.channel_list[0].id;
          brand_identifier = data.channel_list[0].channel_type_identifier;
        }
        this.setState(
          {
            chaanne_list: data.channel_list,
            channel_type_Image: brandImageDefault,
            channel_type_id: brandID,
            channel_type_name: brandNameDefault,
            channel_type_identifier: brand_identifier,
          },
          () => {
            this.apiCallSnippet();
          }
        );
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleReportDialogue = () => {
    this.setState((prevState) => {
      return {
        reportDialogueStatus: !prevState.reportDialogueStatus,
      };
    });
  };

  handleBrand = (image, name) => {
    this.setState(() => {
      return {
        status: false,
        brandType: name,
        brandImage: image,
      };
    });
  };

  handleReportSelection = (item) => {
    const { name, channel_type_icon, id, channel_type_identifier } = item;

    this.setState(
      {
        reportDialogueStatus: false,
        channel_type_Image: channel_type_icon,
        channel_type_name: name,
        channel_type_id: id,
        channel_type_identifier,
      },
      () => {
        const { editorState, costEditorState } = this.state;

        if (snippet_type_cost.includes(channel_type_identifier)) {
          const markdown = stateToMarkdown(costEditorState.getCurrentContent());
          this.checkTextCost(markdown, channel_type_identifier);
        }

        if (snippet_type_count.includes(channel_type_identifier)) {
          let html = stateToHTML(editorState.getCurrentContent());
          let textContent = html
            .replace(/<\s*br[^>]?>/, "\n")
            .replace(/(<([^>]+)>)/g, "");
          textContent = textContent.trim();
          textContent = textContent.replace("&nbsp;", "");

          let length2 = textContent.length;
          this.setState({ snippetCount: length2 });
        }
      }
    );
  };

  closeDialogue = () => {
    this.setState({
      dialogueStatus: false,
    });
  };

  handleChannelTextChange = (e) => {
    this.setState({
      channelName: e.target.value,
    });
  };

  creatReport = () => {
    const { editorState } = this.state;
    if (this.state.channelName.length == 0) {
      toast.info(this.props.t("AddNewChannel.channelNameValidation"));
    } else {
      let html = stateToHTML(editorState.getCurrentContent());

      this.setState(
        {
          content: html,
        },
        () => {
          this.apiCallCreateReport();
        }
      );
    }
  };
  
  setRequestAnswer = (event) => {
    if (event.target.checked) {
      this.setState({
        isRequestAnswer: 1,
      });
    } else {
      this.setState({
        isRequestAnswer: 0,
      });
    }
  };

  apiCallCreateReport = () => {
    const { content, oldContent, channel_type_id, channelName,isRequestAnswer } = this.state;
    const report_id = this.props.match.params.report_id;
    let objContent = {
      content: "",
      result: "",
    };
    let result = JSON.stringify(content);
    let old_content = JSON.stringify(oldContent);
    objContent.result = result;
    objContent.content = old_content;
    apiCall(
      METHOD.QUERY,
      CreateReportChannelQuery(
        getUserID(),
        report_id,
        channel_type_id,
        channelName,
        old_content,
        result,
        isRequestAnswer
      ),
      (data) => {
        this.handleCreateResponse(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleCreateResponse = async (data) => {
    toast.success(this.props.t(data.message));
    this.props.changeRouteStatus(0);
  };

  handlebadge = () => {
    const { snippetCount } = this.state;

    if (snippetCount > 280) {
      return "badge badge-danger mr-2 text-white badge-right";
    } else {
      return "badge badge-success mr-2 text-white badge-right";
    }
  };
  
  render() {
    const {
      isLoading,
      channel_type_Image,
      cost,
      channel_type_identifier,
      snippetCount,
      isLoadingSubmit,
      isRequestAnswer
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-red sab-header1 sticky-top">
                <div className="container">
                  <div className="py-2 d-flex align-items-center">
                    <span>
                      <i
                        className="zmdi zmdi-close zmdi-hc-fw font-30 text-light pointer"
                        onClick={() => this.props.changeRouteStatus(0)}
                      ></i>
                    </span>
                    {isLoadingSubmit ? (
                      <div
                        className="btn btn-light font-13 ml-auto pointer"
                        onClick={this.creatReport}
                      >
                        {t("AddNewChannel.btnSave")}
                        <div className="spinner-border spinner-border-sm ml-1 text-blue">
                          <span className="sr-only">
                            {t("GeneralMessages.loading")}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="btn btn-light font-13 ml-auto pointer"
                        onClick={this.creatReport}
                      >
                        {t("AddNewChannel.btnSave")}
                      </div>
                    )}
                  </div>
                  <div className="text-center mt-4 text-light">
                    <h2 className="m-0 text-light">
                      {" "}
                      {t("AddNewChannel.newChannelLable")}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="middle-section sab-middle-section1">
                {isLoading && <LoadingPage />}

                <div className="container my-4 z-index-99">
                  <div className="form-group mb-5">
                    <div className="form-group max-170 m-auto text-center">
                      <a
                        href="javascript;:"
                        className="clearfix text-dark"
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleReportDialogue();
                        }}
                      >
                        <label className="font-14">
                          {t("AddNewChannel.type")}
                        </label>
                        <div className="icon-drop-bg pointer">
                          <div className="rounded-circle">
                            <img
                              src={`data:image/png;base64,${
                                channel_type_Image && channel_type_Image
                              }`}
                              alt="ico"
                            />
                          </div>

                          <span className="font-12">
                            {this.state.channel_type_name &&
                              this.state.channel_type_name}
                          </span>
                        </div>
                      </a>
                    </div>

                    <ChannelTypeList
                      closeBrandDialogue={this.handleReportDialogue}
                      handleBrandSelection={this.handleReportSelection}
                      status={this.state.reportDialogueStatus}
                      BrandList={this.state.chaanne_list}
                    />

                    <div className="form-group">
                      <label className="font-14">
                        {t("AddNewChannel.title")}
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        onChange={this.handleChannelTextChange}
                        name="title"
                        text={this.state.channelName}
                      />
                    </div>
                  </div>
                  {channel_type_identifier == "twilio" && (
                              <div className="form-group">
                                <div onChange={(e) => this.setRequestAnswer(e)}>
                                  <input
                                    type="checkbox"
                                    value="0"
                                    name="link_type"
                                    checked={
                                      isRequestAnswer == 1 ? true : false
                                    }
                                    className="mr-2"
                                  />
                                  {t("AddNewChannel.requestAnswer")}
                                </div>
                              </div>
                            )}
                  <div
                    className="icon-box-style1 box-left-line-yellow mb-4 "
                    onClick={this.focusEditor}
                  >
                    <div className="pl-3 flex-fill">
                      <div className="w-100">
                        <MegadraftEditor
                          editorState={this.state.editorState}
                          onChange={this.onEditorChange}
                          ref={this.setEditor}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="py-2 d-flex align-items-center">
                    {snippet_type_count.includes(channel_type_identifier) && (
                      <p
                        className={this.handlebadge()}
                        style={{ float: "right" }}
                      >
                        {t("AddNewChannel.character")} {snippetCount}
                      </p>
                    )}

                    {snippet_type_cost.includes(channel_type_identifier) && (
                      <p
                        className="badge badge-success mr-2 text-white badge-right"
                        style={{ float: "right" }}
                      >
                        {t("AddNewChannel.credits")} {cost && cost}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <Footer type="2" />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.channelListStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.channelListDispatchToProps,
  ])
)(withRouter(withTranslation()(AddNewChannelReport)));
