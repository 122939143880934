/* eslint eqeqeq: 0 */
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import stateToMarkdown from "draft-js-export-markdown/lib/stateToMarkdown";
import { stateFromHTML } from "draft-js-import-html";
import { editorStateFromRaw, MegadraftEditor } from "megadraft";
import "megadraft/dist/css/megadraft.css";

import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkCost,
  checksmscost,
  getChannelTypeList,
  updateReportChannel,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";
import Footer from "../../Footer/FooterMobile";
import LoadingPage from "../../CommanComponents/LoadingPage";

const snippet_type_cost = ["text2speech", "sms"];
const snippet_type_count = ["sms", "twitter"];

class EditNewChannelReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      channel_type_Image: "EB",
      channel_type_name: "Einberufung",
      channel_type_id: 0,
      chaanne_list: [],
      reportDialogueStatus: false,
      channel_type_identifier: "",
      channelName: "",
      message_id: 0,
      report_id: 0,

      // Display snippet
      content: ``,
      oldContent: ``,
      editorState: editorStateFromRaw(null),
      costEditorState: editorStateFromRaw(null),
      dialogueStatus: false,
      isLoading: false,
      submitLoading: false,
      cost: 0,
      snippetCount: 0,
      isRequestAnswer:0

    };
    this.setEditor = (editor) => {
      this.editor = editor;
    };
    this.focusEditor = () => {
      if (this.editor) {
        this.editor.focus();
      }
    };
  }

  onEditorChange = (editorState) => {
    const { channel_type_identifier } = this.state;
    this.setState({ snippetCount: 0 });
    if (snippet_type_cost.includes(channel_type_identifier)) {
      const markdown = stateToMarkdown(
        this.state.costEditorState.getCurrentContent()
      );
      const new_markdown = stateToMarkdown(editorState.getCurrentContent());
      let length2 = new_markdown.trim().length;

      let length1 = markdown.trim().length;

      if (length2 - length1 >= 10 || length1 > length2) {
        this.checkTextCost(new_markdown, channel_type_identifier);
        this.setState({ costEditorState: editorState });
      }
    }
    if (snippet_type_count.includes(channel_type_identifier)) {
      let html = stateToHTML(editorState.getCurrentContent());
      let textContent = html
        .replace(/<\s*br[^>]?>/, "\n")
        .replace(/(<([^>]+)>)/g, "");
      textContent = textContent.replace("&nbsp;", "");
      let length2 = textContent.length;
      this.setState({ snippetCount: length2 });
    }

    this.setState({ editorState });
  };

  
  setRequestAnswer = (event) => {
    if (event.target.checked) {
      this.setState({
        isRequestAnswer: 1,
      });
    } else {
      this.setState({
        isRequestAnswer: 0,
      });
    }
  };
  componentDidMount() {
    const {
      title,
      content,
      id,
      channel_type_id,
      channel_type_name,
      channel_type_icon,
      report_id,
      channel_type_identifier,
      request_answer,
    } = this.props.channelDetail;

    let content_result = JSON.parse(content);
    let old_content = content_result.content;
    let result = content_result.result;

    this.editordata = result;

    apiCall(
      METHOD.QUERY,
      getChannelTypeList(getUserID(),0),
      (data) => {
        this.setState(
          {
            chaanne_list: data.channel_list,
            channel_type_Image: channel_type_icon,
            channel_type_name: channel_type_name,
            channel_type_id: channel_type_id,
            channel_type_identifier: channel_type_identifier,
            channelName: title,
            message_id: id,
            report_id,
            isRequestAnswer:request_answer,
            content: result,
            oldContent: old_content,
            editorState: EditorState.createWithContent(stateFromHTML(result)),
            costEditorState: EditorState.createWithContent(
              stateFromHTML(result)
            ),
          },
          () => {
            const { editorState } = this.state;
            if (snippet_type_cost.includes(channel_type_identifier)) {
              const markdown = stateToMarkdown(
                this.state.costEditorState.getCurrentContent()
              );
              this.checkTextCost(markdown, channel_type_identifier);
            }

            if (snippet_type_count.includes(channel_type_identifier)) {
              let html = stateToHTML(editorState.getCurrentContent());
              let textContent = html
                .replace(/<\s*br[^>]?>/, "\n")
                .replace(/(<([^>]+)>)/g, "");
              textContent = textContent.trim();
              textContent = textContent.replace("&nbsp;", "");

              let length2 = textContent.length;
              this.setState({ snippetCount: length2 });
            }
          }
        );
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  checkTextCost = (markdown, channel_type_identifier) => {
    if (channel_type_identifier == "sms") {
      apiCall(
        METHOD.QUERY,
        checksmscost(
          getUserID(),
          this.props.channelDetail.channel_id,
          JSON.stringify(markdown),
          channel_type_identifier
        ),
        (data) => {
          this.setState({
            cost: data.price,
          });
        },
        (isLoading) => {}
      );
    } else {
      apiCall(
        METHOD.QUERY,
        checkCost(
          getUserID(),
          this.state.channel_type_id,
          JSON.stringify(markdown),
          channel_type_identifier
        ),
        (data) => {
          this.setState({
            cost: data.price,
          });
        },
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleReportDialogue = () => {
    this.setState((prevState) => {
      return {
        reportDialogueStatus: !prevState.reportDialogueStatus,
      };
    });
  };

  handleBrand = (image, name) => {
    this.setState(() => {
      return {
        status: false,
        brandType: name,
        brandImage: image,
      };
    });
  };

  handleReportSelection = (item) => {
    const { name, channel_type_icon, channel_type_id } = item;

    this.setState({
      reportDialogueStatus: false,
      channel_type_Image: channel_type_icon,
      channel_type_name: name,
      channel_type_id: channel_type_id,
    });
  };

  closeDialogue = () => {
    this.setState({
      dialogueStatus: false,
    });
  };

  handleChannelTextChange = (e) => {
    this.setState({
      channelName: e.target.value,
    });
  };

  creatReport = () => {
    const { editorState } = this.state;
    if (this.state.channelName.length == 0) {
      toast.info(this.props.t("AddNewChannel.channelNameValidation"));
    } else {
      let html = stateToHTML(editorState.getCurrentContent());
      this.setState(
        {
          content: html,
        },
        () => {
          this.apiCallCreateReport();
        }
      );
    }
  };
  apiCallCreateReport = () => {
    const {
      content,
      oldContent,
      report_id,
      channelName,
      message_id,
      isRequestAnswer
    } = this.state;

    let objContent = {
      content: "",
      result: "",
    };

    let result = JSON.stringify(content);
    let old_content = JSON.stringify(oldContent);
    objContent.result = result;
    objContent.content = old_content;

    apiCall(
      METHOD.QUERY,
      updateReportChannel(
        getUserID(),
        message_id,
        report_id,
        this.props.channelDetail.channel_id,
        channelName,
        old_content,
        result,
        isRequestAnswer
      ),
      (data) => {
        this.handleCreateResponse(data);
      },
      (isLoading) => {
        this.setState({ submitLoading: isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleCreateResponse = async (data) => {
    toast.success(this.props.t(data.message));
    this.props.changeRouteStatus(0);
  };
  handlebadge = () => {
    const { snippetCount } = this.state;

    if (snippetCount > 280) {
      return "badge badge-danger mr-2 text-white badge-right";
    } else {
      return "badge badge-success mr-2 text-white badge-right";
    }
  };
  render() {
    const {
      isLoading,
      channel_type_Image,
      cost,
      channel_type_identifier,
      snippetCount,
      submitLoading,
      isRequestAnswer
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-red sab-header1 sticky-top">
                <div className="container">
                  <div className="py-2 d-flex align-items-center">
                    <span>
                      <i
                        className="zmdi zmdi-close zmdi-hc-fw font-30 text-light pointer"
                        onClick={() => this.props.changeRouteStatus(0)}
                      ></i>
                    </span>

                    {submitLoading ? (
                      <div
                        className="btn btn-light font-13 ml-auto pointer"
                        onClick={this.creatReport}
                      >
                        {t("AddNewChannel.btnSave")}
                        <div className="spinner-border spinner-border-sm ml-1 text-blue">
                          <span className="sr-only">
                            {t("GeneralMessages.loading")}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="btn btn-light font-13 ml-auto pointer"
                        onClick={this.creatReport}
                      >
                        {t("AddNewChannel.btnSave")}
                      </div>
                    )}
                  </div>
                  <div className="text-center mt-4 text-light">
                    <h2 className="m-0 text-light">
                      {t("AddNewChannel.editChannelLable")}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="middle-section sab-middle-section1">
                {isLoading && <LoadingPage />}

                <div className="container my-4 z-index-99">
                  <div className="form-group mb-5">
                    <div className="form-group max-170 m-auto text-center">
                      <a
                        href="javascript;:"
                        className="clearfix text-dark"
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleReportDialogue();
                        }}
                      >
                        <label className="font-14">
                          {" "}
                          {t("AddNewChannel.type")}
                        </label>
                        <div className="icon-drop-bg pointer">
                          <div className="rounded-circle">
                            <img
                              src={`data:image/png;base64,${
                                channel_type_Image && channel_type_Image
                              }`}
                              alt="ico"
                            />
                          </div>

                          <span className="font-12">
                            {this.state.channel_type_name &&
                              this.state.channel_type_name}
                          </span>
                        </div>
                      </a>
                    </div>
                    <div className="form-group">
                      <label className="font-14">
                        {" "}
                        {t("AddNewChannel.title")}
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        onChange={this.handleChannelTextChange}
                        name="title"
                        value={this.state.channelName}
                      />
                    </div>
                  </div>

                  {channel_type_identifier == "twilio" && (
                              <div className="form-group">
                                <div onChange={(e) => this.setRequestAnswer(e)}>
                                  <input
                                    type="checkbox"
                                    value="0"
                                    name="link_type"
                                    checked={
                                      isRequestAnswer == 1 ? true : false
                                    }
                                    className="mr-2"
                                  />
                                  {t("AddNewChannel.requestAnswer")}
                                </div>
                              </div>
                            )}
                  <div
                    className="icon-box-style1 box-left-line-yellow mb-4 "
                    onClick={this.focusEditor}
                  >
                    <div className="pl-3 d-flex">
                      <div className="w-100">
                        <MegadraftEditor
                          editorState={this.state.editorState}
                          onChange={this.onEditorChange}
                          ref={this.setEditor}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="py-2 d-flex align-items-center">
                    {snippet_type_count.includes(channel_type_identifier) && (
                      <p
                        className={this.handlebadge()}
                        style={{ float: "right" }}
                      >
                        {t("AddNewChannel.character")} {snippetCount}
                      </p>
                    )}
                    {snippet_type_cost.includes(channel_type_identifier) && (
                      <p
                        className="badge badge-success mr-2 text-white badge-right"
                        style={{ float: "right" }}
                      >
                        {t("AddNewChannel.credits")} {cost && cost}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <Footer type="2" />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.channelDetailStateToProps],
      state
    ),
  actions.appMapDispatchToProps([actions.asyncApiDispatchToProps])
)(withRouter(withTranslation()(EditNewChannelReport)));
