import React, { Component } from 'react'
import MediaQuery from "react-responsive";
import { withRouter } from 'react-router';
import { checkAdminPermission } from '../../../util/utils';
import SnippetManagementDesktop from "./SnippetManagementDesktop/SnippetList";
import SnippetManagementMobile from "./SnippetManagementMobile/Index";


 class ManageSnippetResponsive extends Component {
  componentDidMount() {
    if(!checkAdminPermission()){
      this.props.history.push("/")
    }
  }
  render() {
    return (
      <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <SnippetManagementDesktop />
       
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <SnippetManagementMobile />
      </MediaQuery>
    </React.Fragment>
    )
  }
}

export default withRouter(ManageSnippetResponsive)