/* eslint eqeqeq: 0 */
import React, { Component , Suspense} from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
import {
  expandableGetPersonListGroupWise,
  getmailinglistgroupperson,
  removegroupfromlist,
} from "../../../constants/apiParams";
import { toast } from "react-toastify";
import LoadingPage from "../../CommanComponents/LoadingPage";
const PersonItem = React.lazy(() => import("./PersonItem"));


class SearchSubGroupItemListView extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      person_list: [],
      is_Loading: false,
    };
  }
  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };


  getPersonList = (group_id) => {
    apiCall(
      METHOD.QUERY,
      expandableGetPersonListGroupWise(getUserID(), group_id),
      (data) => {
        this.setState({
          person_list: data.person_list,
        });
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleItemClick = (data) => {
    const { id,  person } = data;
    this.props.clearStateGroupDetail({});
    this.props.clearStateGroupPersonDetail({});
    if (person == 0) {
      this.props.history.push("/group-detail/" + id);
    } else {
      this.props.history.push("/group-personlist/" + id);
    }
  };

  render() {
    const { group_name, person,id,has_child } = this.props.data;
    const { person_list } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
           <React.Fragment>
              <div className="custom-accordian">
                <Accordion
                  allowZeroExpanded={true}
                  onChange={(res) => {
                    if(has_child==1 || person==0){
                      this.props.clearStateGroupDetail();
                      this.props.history.push("/group-detail/" + id);
                    }else{
                        res.length > 0 && this.getPersonList(id);
                    }
                  }}
                >
                  <AccordionItem>
                    <AccordionItemButton >
                      {group_name && group_name}
                        <p className="text-muted font-12 m-0">
                          {person && person}
                          {person == 1
                            ? t("GroupListScreen.singularPerson")
                            : t("GroupListScreen.pluralPerson")}
                        </p>
                      {/* <div
                        className="accordian-delete-btn pointer"
                        onClick={() => {
                          this.deleteGroup(id);
                        }}
                      >
                        <i className="zmdi zmdi-delete zmdi-hc-fw font-22"></i>
                      </div> */}
                    </AccordionItemButton>
                    <Suspense fallback={<LoadingPage />}>
                      <AccordionItemPanel>
                        {person_list &&
                          person_list.map((item, index) => (
                            <PersonItem
                              item={item}
                              groupId={id}
                              index={index}
                              key={index}
                              updatePersonList={this.updatePersonList}
                              updatePersonCount={this.props.updatePersonCount}
                            />
                          ))}
                      </AccordionItemPanel>
                    </Suspense>
                  </AccordionItem>
                </Accordion>
              </div>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.groupDetailStateToProps,
        stateToProps.groupListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupDetailDispatchToProps,
    actions.groupDetailPersonListDispatchToProps,
  ])
)(withRouter(withTranslation()(SearchSubGroupItemListView)));
