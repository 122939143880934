/* eslint eqeqeq: 0 */
import React, { Component,Suspense } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";

import {
  addgrouppersonintomailinglist,
  groupallpersonlist,
  GroupListQuery,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID, removeDuplicates } from "../../../util/utils";
import LoadingPage from "../../CommanComponents/LoadingPage";

class GroupListAddContacts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      group_list: [],
      start: 0,
      person_list: [],
      end: 15,
      hasMore: true,
      selectedGroupList: [],
      isLoading: false,
      groupLinkLoader: false,
      parent_group: true,
      breadcrumb: [],
      group_id: 0,
    };
  }

  loadGroupList = () => {
    const { start, end, hasMore, isLoading, group_id } = this.state;
    if (!isLoading && hasMore) {
      this.setState({
        isLoading: true,
      });

      apiCall(
        METHOD.QUERY,
        GroupListQuery(getUserID(), start, end, group_id, "", 1),
        (data) => this.handleGroupListResponse(data),
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  handleGroupListResponse = (data) => {
    const { start, end } = this.state;
    if (data.is_last == 1) {
      this.setState({ hasMore: false });
    }

    let old_group_list = this.state.group_list;
    old_group_list = old_group_list.concat(data.group_list);
    old_group_list = removeDuplicates(old_group_list, "id");

    this.setState((prevState) => {
      return {
        ...this.state,
        start: start + end,
        group_list: old_group_list,
        breadcrumb: data.breadcrumb,
        isLoading: false,
      };
    });
  };

  handleSubmit = () => {
    const {isFromMailDetail,listIdForAction} = this.props
    let  list_id=0
    if(isFromMailDetail){
      list_id= listIdForAction;

    }else{
      const mail_list_id = this.props.match.params.mail_person_id;
      const fields = mail_list_id.split("-");
       list_id = fields[0];
    }
  
    const { selectedGroupList } = this.state;
    let group_id = selectedGroupList.join(",");

    if (selectedGroupList.length == 0) {
      toast.error(this.props.t("personDetail.validateGroupSelection"));
    } else {
      apiCall(
        METHOD.QUERY,
        addgrouppersonintomailinglist(list_id, getUserID(), group_id),
        (data) => this.handleResponse(data),
        (isLoading) => {
          this.setState({ groupLinkLoader: isLoading });
        },
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };
  handleResponse = (data) => {
    toast.success(this.props.t(data.message));
    this.props.handleAddGroupContactModal(true);
  };
  loader = (<LoadingPage key={0} />);

  handleCheckBox = (e, group_id) => {
    if (e.target.checked) {
      this.addSelectedGroupID(group_id);
    } else {
      this.removeSelectedGroupID(group_id);
    }
  };

  addSelectedGroupID = (group_id) => {
    this.setState((prev) => {
      return {
        selectedGroupList: [...prev.selectedGroupList, group_id],
      };
    });
  };

  handleGroupClick = (group_id) => {
    this.setState(
      {
        start: 0,
        group_list: [],
        parent_group: group_id == 0 ? true : false,
        hasMore: true,
        group_id,
      },
      () => {
        this.loadGroupList();
      }
    );
  };

  removeSelectedGroupID = (group_id) => {
    let afterRemoveGroupList = this.state.selectedGroupList.filter((id) => {
      return id != group_id;
    });

    this.setState({
      selectedGroupList: afterRemoveGroupList,
    });
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };
  getPersonList = (group_id) => {
    apiCall(
      METHOD.QUERY,
      groupallpersonlist(getUserID(), group_id, ""),
      (data) => {
        this.setState({
          person_list: data.person_list,
        });
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  componentDidMount() {
    this.loadGroupList();
  }
  render() {
    const {
      end,
      hasMore,
      group_list,
      groupLinkLoader,
      selectedGroupList,
      parent_group,
      person_list,
      breadcrumb,
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <div className="d-block">
                <div
                  className="modal fade show"
                  id="NewEvent"
                  tabindex="-1"
                  aria-labelledby="NewEventLabel"
                  aria-modal="true"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-header border-0 d-block pt-4 pb-0">
                        
                      <div className="d-flex align-items-center justify-content-between mb-4">
                          <button
                            type="button"
                            onClick={() => {
                              this.props.handleAddGroupContactModal(false)
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                          </button>

                          <h5 className="modal-title">
                        {t("personDetail.addGroupContacts")}
                        </h5>

                          {groupLinkLoader ? (
                            <div
                              className="btn btn-primary-theme list pointer"
                             
                            >
                              {t("MailList.btnSave")}
                              <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                <span className="sr-only">
                                  {t("GeneralMessages.loading")}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="btn btn-primary-theme list pointer"
                              onClick={() => {
                                this.handleSubmit();
                              }}
                            >
                              {t("MailList.btnSave")}
                            </div>
                          )}
                        </div>
                     
                     
                      </div>
                      <div className="modal-body">
                        {!parent_group && (
                            <div className="d-block flex-fill z-index-9999">
                              <div className="d-flex mb-4">
                                <div className="d-flex w-100">
                                  <div className="clearfix d-flex0 0align-items-center">
                                    <p className="font-12 m-0">
                                      <React.Fragment>
                                        <a
                                          href="javascript;:"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.handleGroupClick(0);
                                          }}
                                          className="text-muted"
                                          data-tip={"Start"}
                                        >
                                          {t("GroupListScreen.start")}
                                          <ReactTooltip
                                            place="top"
                                            type="info"
                                            effect="float"
                                          />
                                        </a>
                                        {" > "}
                                      </React.Fragment>
                                      {breadcrumb &&
                                        breadcrumb.map((item, index) => {
                                          if (index == 0) {
                                            return (
                                              <React.Fragment key={index}>
                                                <a
                                                  href="javascript;:"
                                                  className="text-muted"
                                                  data-tip={item.name}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleGroupClick(
                                                      item.id
                                                    );
                                                  }}
                                                >
                                                  <ReactTooltip
                                                    place="top"
                                                    type="info"
                                                    effect="float"
                                                  />
                                                  {item.name}
                                                </a>
                                                {" > "}
                                              </React.Fragment>
                                            );
                                          } else if (
                                            index != 0 &&
                                            index != breadcrumb.length - 1
                                          ) {
                                            return (
                                              <React.Fragment key={index}>
                                                <a
                                                  href="javascript;:"
                                                  className="text-muted"
                                                  data-tip={item.name}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleGroupClick(
                                                      item.id
                                                    );
                                                  }}
                                                >
                                                  <ReactTooltip
                                                    place="top"
                                                    type="info"
                                                    effect="float"
                                                  />
                                                  [...]
                                                </a>

                                                {index !=
                                                  breadcrumb.length - 1 &&
                                                  " > "}
                                              </React.Fragment>
                                            );
                                          } else if (
                                            index ==
                                            breadcrumb.length - 1
                                          ) {
                                            return (
                                              <React.Fragment key={index}>
                                                {item.name && item.name}
                                              </React.Fragment>
                                            );
                                          }
                                        })}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                        )}

                        <div className="middle-section sab-middle-section1">
                          <div className="">
                            {group_list &&
                              group_list.map((item, index) => {
                                const { name, id, has_child,sub_group,person } = item;
                                return (
                                  <div className="custom-accordian bg-border-accordion" id={`groupDiv${index}`}>

                                  <Accordion
                                  allowZeroExpanded={true}
                                  onChange={(res) => {
                                    if(has_child!=0){
                                      res.length > 0 && this.handleGroupClick(id);
                                    }else{
                                      res.length > 0 && this.getPersonList(id);
                                    }
                
                                  }}
                                  key={index}
                                >
                                  <AccordionItem className="bg-border blue">
                                    <AccordionItemButton>
                                      {name && name}
                 {has_child == 0 ? (
                              <p className="text-muted font-12 m-0">
                                {person && person}{" "}
                                {person == 1
                                  ? t("GroupListScreen.singularPerson")
                                  : t("GroupListScreen.pluralPerson")}
                              </p>
                            ) : (
                              <p className="text-muted font-12 m-0">
                                {sub_group && sub_group}{" "}
                                {t("GroupListScreen.subGroup")}
                              </p>
                            )}
      
                
                                      {has_child==0 && <div className="align-self-center accordian-check-btn">
                                                    <label className="checkbox-container">
                                                      <input
                                                        type="checkbox"
                                                        onChange={(e) =>{
                                                          this.handleCheckBox(e, id)
                                                        }
                                                        }
                                                        checked={selectedGroupList.includes(id)}
                                                      />
                                                      <span className="checkmark"></span>
                                                    </label>
                                                  </div>}
                                     
                                    </AccordionItemButton>
                                    <Suspense fallback={<LoadingPage />}>
                                      <AccordionItemPanel>
                                        {person_list && person_list.map((perosn,index)=>{
                                          const {full_name,email,is_valid_email} = perosn
                                          return( 
                                         <div className="left-icon-box small d-flex" key={index}>
                                                <div
                                                  className="circle"
                                                  style={{ backgroundColor: "transparent", lineHeight:'55px' }}
                                                >
                                                  {is_valid_email && is_valid_email == 1 ? (
                                                    <i className="zmdi zmdi-check-circle zmdi-hc-fw font-size-22 text-success"></i>
                                                  ) : (
                                                    <i className="zmdi zmdi-alert-circle zmdi-hc-fw font-size-22 text-blue"></i>
                                                  )}
                                                </div>
                                              <div className="media-body pt-3 px-4">
                                                <h6 className="font-size-15 mb-1">{full_name && full_name}</h6>
                                                <p className="text-muted font-12 m-0">{email && email}</p>
                                              </div>
                                        </div>)
                                        })}
                                      </AccordionItemPanel>
                                    </Suspense>
                                  </AccordionItem>
                                </Accordion>
                                </div>
                                  );
                              })}
                          </div>
                          {hasMore && (
                            <div className="text-center mt-5">
                              <button
                                type="button"
                                className="btn btn-primary-theme list"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.loadGroupList();
                                }}
                              >
                                {t("HomeScreen.showMore")}
                              </button>
                            </div>
                          )}
                        </div>





                        {/* <div className="middle-section sab-middle-section1">
                          <div className="">
                            {group_list &&
                              group_list.map((item, index) => {
                                const { name, id, has_child } = item;
                                return (
                                  <div
                                    className="icon-box-style3  "
                                    key={index}
                                  >
                                    <div className="d-flex">
                                      <div className="d-flex w-100">
                                        <div className="align-self-center">
                                          <label className="checkbox-container">
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                this.handleCheckBox(e, id)
                                              }
                                              checked={selectedGroupList.includes(
                                                id
                                              )}
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                        </div>
                                        <a
                                          href="javascript;:"
                                          className="clearfix d-flex align-items-center flex-fill"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            if (has_child == 1) {
                                              this.handleGroupClick(id);
                                            }
                                          }}
                                        >
                                          <div className="align-self-center pointer">
                                            <h5 className="m-0 font-18 d-block">
                                              {name && name}
                                            </h5>
                                            <p className="text-muted font-12 m-0">
                                            </p>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                    {has_child == 1 && (
                                      <div className="align-self-center float-right ml-auto">
                                        <span className="text-dark">
                                          <i className="zmdi zmdi-chevron-right zmdi-hc-fw font-26"></i>
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                          {hasMore && (
                            <div className="text-center mt-5">
                              <button
                                type="button"
                                className="btn btn-primary-theme"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.loadGroupList();
                                }}
                              >
                                {t("HomeScreen.showMore")}
                              </button>
                            </div>
                          )}
                        </div> */}






                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-backdrop fade show"></div>
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(GroupListAddContacts));
