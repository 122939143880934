import React from "react";
import MediaQuery from "react-responsive";
import ChannelDetailMobile from "./ChannelDetailMobile/Index";
import ChannelDetailDesktop from "./ChannelDetailDesktop/Index";

export default function ChannelResponsive() {
  return (
    <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <ChannelDetailDesktop /> 
      
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <ChannelDetailMobile />
      </MediaQuery>
    </React.Fragment>
  );
}
