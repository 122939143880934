import { Field, Formik } from "formik";
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { ChangePassQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import { getUserID } from "../../util/utils";
import { RecoverPasswordSchema } from "../../util/validationSchema";
import ErrorMessageFormik from "../CommanComponents/ErrorMessageFormik";
import FooterDesktop from "../Footer/FooterDesktop";
import HeaderDesktop from "../Header/HeaderDesktop";

class ChangeUserPasswordDesktop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  handleSubmit = (values) => {
    const { current_password, new_password } = values;
    apiCall(
      METHOD.QUERY,
      ChangePassQuery(getUserID(), current_password, new_password),
      (data) => {
        toast.success(this.props.t(data.message));
        let { from } = this.props.location.state || { from: { pathname: "/" } };
        this.props.history.push(from);
      },
      (isLoading) => {
        this.setState({ isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  render() {
    const { isLoading } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <Formik
                enableReinitialize
                initialValues={{}}
                validationSchema={RecoverPasswordSchema(
                  t("changePassword.currentPasswordValidation"),
                  t("changePassword.toShortValidation"),
                  t("changePassword.toLongValidation"),
                  t("changePassword.passwordValidation"),
                  t("changePassword.comparePasswordValidation")
                )}
                onSubmit={(values) => {
                  this.handleSubmit(values);
                }}
              >
                {({ handleSubmit }) => (
                  <div className="content-wrapper">
                    <HeaderDesktop selectionType={0} />
                    <div className="content">
                      <div className="section section-padding">
                        <div className="container">
                          <div className="section-container">
                            <div className="section-title">
                              <h1 className="page-title h4">
                                {t("changePassword.title")}
                              </h1>
                            </div>
                            <div className="section-content">
                              <div className="card mt-4">
                                <div className="card-body">
                                  <div className="form-row">
                                    <div className="col-xl-6">
                                      <form>
                                        <div className="form-group">
                                          <label className="font-14">
                                            {t(
                                              "changePassword.currentPassword"
                                            )}
                                          </label>
                                          <Field
                                            name="current_password"
                                            placeholder="************"
                                            className="form-control"
                                            type="password"
                                            required
                                          />
                                          <ErrorMessageFormik name="current_password" />
                                        </div>

                                        <div className="form-group">
                                          <label className="font-14">
                                            {t("changePassword.newPassword")}
                                          </label>
                                          <Field
                                            name="new_password"
                                            placeholder="************"
                                            className="form-control"
                                            type="password"
                                            required
                                          />
                                          <ErrorMessageFormik name="new_password" />
                                        </div>

                                        <div className="form-group">
                                          <label className="font-14">
                                            {t(
                                              "changePassword.repearNewPassword"
                                            )}
                                          </label>
                                          <Field
                                            name="repear_password"
                                            placeholder="************"
                                            className="form-control"
                                            type="password"
                                            required
                                          />
                                          <ErrorMessageFormik name="repear_password" />
                                        </div>

                                        <div className="form-group text-right mt-4 mb-2">
                                          {isLoading ? (
                                            <button
                                              type="button"
                                              onClick={handleSubmit}
                                              className="btn btn-primary-theme btn-block"
                                            >
                                              {t("changePassword.title")}

                                              <div className="spinner-border spinner-border-sm ml-1 text-white">
                                                <span className="sr-only">
                                                  {t("GeneralMessages.loading")}
                                                </span>
                                              </div>
                                            </button>
                                          ) : (
                                            <button
                                              type="button"
                                              onClick={handleSubmit}
                                              className="btn btn-primary-theme"
                                            >
                                              {t(
                                                "changePassword.changePasswordButton"
                                              )}
                                            </button>
                                          )}
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <FooterDesktop selectionType={0} />
                  </div>
                )}
              </Formik>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(withRouter(ChangeUserPasswordDesktop));
