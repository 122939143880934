import React, { Component } from 'react'
import PersonItem from '../../GroupDetailPersonList/GroupDetailPersonListDesktop/PersonItem';
import NoRecordFound from "../../CommanComponents/NoRecordFound";

export default class PersonList extends Component {
    render() {
        const {personListForGroup} = this.props
        return (
            <div className="card-body-custom side-line">
            {!!personListForGroup.length &&
              personListForGroup.map((data, index) => {
                return (
                  <PersonItem data={data} key={index} index={index} />
                );
              })}

            { personListForGroup.length == 0 && (
              <NoRecordFound title="Person List" name="person list" />
            )}
        </div>
     
        )
    }
}
