/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ChannelDetails from "./ChannelDetails";
import HeaderDesktop from '../../Header/HeaderDesktop';
import FooterDesktop from '../../Footer/FooterDesktop';
class Index extends Component {
  render() {
    return (
      <div className="content-wrapper">
        <HeaderDesktop selectionType={2}/>
          <ChannelDetails />
        <FooterDesktop selectionType={2}/>
      </div>
    );
  }
}
export default withRouter(Index);
