/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { toast } from "react-toastify";
import { getmailinglistForAddRacipient, getmailinglistgroupForFilter, MailDetailsQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
import CommonLoader from "../../CommanComponents/CommonLoader";
import { Translation, withTranslation } from "react-i18next";

class PersonbaseOnMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      personList:[],
      breadcrumb:[],
      isLoading: false,
    };
  }
getPersonList =()=>{
  const personDataTypeId = this.props.personDataTypeId

  if(personDataTypeId && personDataTypeId != 0){
    apiCall(
      METHOD.QUERY,
      getmailinglistgroupForFilter(getUserID(), this.props.mail_id,personDataTypeId,this.props.filterValue),
      (data) => {
        this.setState({
          personList: data.person_list,
          breadcrumb:data.breadcrumb,
          data,
        });
        // this.props.getMailDetails(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }else{
   this.loadPersonListFromMailId()
  }
}
  loadPersonListFromMailId = () => {
    apiCall(
      METHOD.QUERY,
      getmailinglistForAddRacipient(getUserID(), this.props.mail_id,""),
      (data) => {
        this.setState({
          personList: data.person_list,
          breadcrumb:data.breadcrumb,
          data,
        });
        // this.props.getMailDetails(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleLoader = status => {
    this.setState(() => {
      return {
        isLoading: status
      };
    });
  };

  handleMailListResponse = (data) => {
    this.setState((prevState) => {
      return {
        data,
      };
    });
  };

  componentDidMount() {
    this.getPersonList();
  }

  movetoMainList = (mail_id) => {
    this.props.changeMailRouteStatus(0, 0);
  };

  handleCheckBox = (e, checkPersonID) => {
    if (e.target.checked) {
      this.props.updatePersonCountForPersonList(this.props.mail_id,true);
      this.props.addSelectedPersonId(checkPersonID,this.props.mail_id);
    } else {
      this.props.updatePersonCountForPersonList(this.props.mail_id,false);
      this.props.removeSelectedPersonId(checkPersonID,this.props.mail_id);
    }
  };

  getIsCheckedFromPersonId=(personId)=>{
    const checkedList = this.props.checkedList
    const mailId = this.props.mail_id
    let isPersonExist=false
    const index = checkedList.findIndex(
      (personMail) => personMail.id == mailId
    );
    if(index!=-1){
      let objPerson = checkedList[index]
     let personList = objPerson.person
    if(personList.includes(personId)){
      isPersonExist=true
    }else{
      isPersonExist=false
    }
    }else{
      isPersonExist=false
    }
    return isPersonExist
  }

  handleAlphabaticeSort = (alphabet) => {
    const { personList } = this.state;
    let index = -1;
    for (let i = 0; i < personList.length; i++) {
      const person = personList[i];
      let name = person.name;
      name = name.charAt(0).toUpperCase();
      if (alphabet == name && index == -1) {
        index = i;
      }
    }
    if (index != -1) {
      const divID = `personDiv${index}`;
      const section = document.getElementById(divID);
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  loader = (<CommonLoader key={0} />);
  render() {
    const { child_list, name, id } = this.state.data;
    const { isLoading,breadcrumb,personList } = this.state;
    const {searchArray} = this.props

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="container my-2 ">
              {isLoading && this.loader}
              <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="clearfix">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item pointer" 
                           onClick={()=>{
                            this.props.changeMailRouteStatus(0,0)
                             }}>
                            {t("mailDetail.home")}
                          </li>
                          <li
                              className="breadcrumb-item pointer"
                              aria-current="page"
                              onClick={()=>{
                               this.props.changeMailRouteStatus(1,breadcrumb[0]?.id)
                                }}
                            >
                             {breadcrumb[0]?.name}
                            </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                        {breadcrumb[1]?.name}
                          </li>
                        </ol>
                      </nav>
                        </div>
                    <ul className="list-inline grid-view-list m-0">
                      <li className="list-inline-item mr-3">
                        <a className="pointer" href="javascript;:" onClick={(e)=>{
                          e.preventDefault();
                          this.props.changeFilterRouteStatus(3)}}>
                          <i className="fa fa-filter font-30 text-dark"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
              
              { searchArray.length!=0 && (<div className="form-row mt-2">
          {searchArray.map((item, index) => {
              const { name, id, email } = item;
              return (
                <div className="container mb-4 d-block" key={index} id={`personDiv${index}`}>
                          <div className="ml-45p pt-1 0d-flex">
                            <div className="w-100 mb-4">
                            <div className="align-self-end float-right">
                              <label className="checkbox-container p-0 m-0">
                                <input
                                  type="checkbox"
                                  onChange={(e) => this.handleCheckBox(e, id)}
                                  checked={this.getIsCheckedFromPersonId(id)}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                              <h6 className="m-0">{name && name}</h6>
                              <p className="text-muted font-12 mb-2">
                                {email && email}
                              </p>
                            </div>
                          </div>
                        </div>
                        );
            })}
             </div>)} 

             {searchArray.length==0 && (
              <>
                {personList &&
                  personList.map((item, index) => {
                    const { id,name,email,is_deletable } = item;
                    return (
                      <div className="container mb-4 d-block" key={index} id={`personDiv${index}`}>
                          <div className="ml-45p pt-1 0d-flex">
                            <div className="w-100 mb-4">
                            <div className="align-self-end float-right">
                              <label className="checkbox-container p-0 m-0">
                                <input
                                  type="checkbox"
                                  onChange={(e) => this.handleCheckBox(e, id)}
                                  // checked={this.props.checkedList.includes(id)}
                                  checked={this.getIsCheckedFromPersonId(id)}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                              <h6 className="m-0">{name && name}</h6>
                              <p className="text-muted font-12 mb-2">
                                {email && email}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                  })}
              </>
           )}
         
         
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(PersonbaseOnMail);
