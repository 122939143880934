/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import onClickOutside from "react-onclickoutside";
import { toast } from "react-toastify";
import {
  deletePersonMailQuery,
  getallpersondatatypevaluesfromfilter,
  getmailinglistgroup,
  getmailinglistgroupForFilter,
  getpersondatatypefromfilter,
  removelist,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../../util/utils";
import FooterDesktop from "../../Footer/FooterDesktop";
import HeaderDesktop from "../../Header/HeaderDesktop";
import SearchMail from "../../Mail/MailListDesktop/SearchMail";
import SearchMailListBaseOnPerson from "../../Mail/MailListDesktop/SearchMailListBaseOnPerson";
import SearchPersonBaseOnMail from "../../Mail/MailListDesktop/SearchPersonBaseOnMail";
import SearchPersonResult from "../../Mail/MailListDesktop/SearchPersonResult";
import EditMail from "./EditMail";
import GeneralGGroupList from "./GeneralGGroupList";
import GeneralPersonList from "./GeneralPersonList";
import GroupItem from "./GroupItem";
import GroupListAddContacts from "./GroupListAddContacts";
import SearchPersonBaseonCompany from "../../Mail/MailListMobile/SearchPersonBaseonCompany";

class PersonList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      headerMenuStatus: false,
      list_id: 0,
      mail_id: 0,
      data: { person_list: [], group_list: [] },
      searchValue: "",
      personModal: false,
      addGroupContactModal: false,
      editGroup: false,
      addGGroup: false,
      editListPermission: false,
      deleteListPermission: false,
      breadcrumb: [],
      personDataTypeList: false,
      searchData: { person_list: [], group_list: [], breadcrumb: [] },
      personDataTypeList: [],
      personDataTypeValueList: [],
      personDataTypeListIsOpen: false,
      personDataTypeValueIsOpen: false,
      selectedValue: [],
      personDataTypeName: "",
      filterValue: "",
      personDataTypeId: 0,
      sortType: 1,
      searchDisplayType: 0,
      searchPersonList: [],
      mailListBaseOnPerson: [],
      personListBaseOnMailID: [],
      personListBreadCumb: [],
      selectedPersonId: 0,
      groupIdBaseOnPersonDetail:0
    };
  }

  handleBackStack = () => {
    const mail_list_id = this.props.match.params.mail_person_id;
    const fields = mail_list_id.split("-");
    const mail_id = fields[1];

    let { from } = this.props.location.state || {
      from: { pathname: "/mail-detail/" + mail_id },
    };
    this.props.history.push(from);
  };
  openSlider = () => {
    this.setState(() => {
      return {
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  handleActiveIndex = (mail_id, id) => {
    if (mail_id == id) {
      return "active";
    } else {
      return "";
    }
  };

  storeApiData = (data) => {
    this.setState({
      data,
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const mailListPermission = checkUserPermission("read_list");
    const editListPermission = checkUserPermission("edit_list");
    const deleteListPermission = checkUserPermission("delete_list");

    this.loadPersonDataTypeList();
    this.setState({
      editListPermission,
      deleteListPermission,
    });
    if (mailListPermission) {
      const mail_list_id = this.props.match.params.mail_person_id;
      const fields = mail_list_id.split("-");
      const list_id = fields[0];
      const mail_id = fields[1];

      this.setState({
        list_id: list_id,
        mail_id: mail_id,
      });
      apiCall(
        METHOD.QUERY,
        getmailinglistgroup(getUserID(), list_id),
        (data) => {
          this.storeApiData(data);
          this.setState({
            header_list: data.mailing_list,
            group_list: data.group_list,
            breadcrumb: data.breadcrumb,
            data,
          });
          // this.props.getMailDetails(data);
        },
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  }

  handleAfterApiCall = (data) => {
    this.setState(() => {
      return {
        ...this.state,
        mailList: data.mailing_list,
      };
    });
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  handleChangeMail = (id) => {
    this.setState(
      {
        list_id: id,
      },
      () => {
        const { mail_id } = this.state;
        this.props.history.push(
          "/mail-details-personlist/" + id + "-" + mail_id
        );
        const mailListPermission = checkUserPermission("read_list");
        if (mailListPermission) {
          this.loadPersonList(id);
        } else {
          toast.info(this.props.t("GeneralMessages.permissionError"));
        }
      }
    );
  };

  loadPersonList = (mail_id) => {
    apiCall(
      METHOD.QUERY,
      getmailinglistgroup(getUserID(), mail_id),
      (data) => {
        this.props.storeApiData(data);
        this.setState({
          header_list: data.mailing_list,
          group_list: data.group_list,
          breadcrumb: data.breadcrumb,
          data,
        });
        this.props.getMailDetails(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleDelete = (item) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to delete this person.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              deletePersonMailQuery(getUserID(), this.state.list_id, item.id),
              (data) => {
                toast.success(this.props.t(data.message));
                let getMailDetailData = this.state.data;
                getMailDetailData.total_person =
                  getMailDetailData.total_person - 1;
                let personList = getMailDetailData.person_list;

                let afterDeletePerson = personList.filter((person) => {
                  return person.id != item.id;
                });
                getMailDetailData.person_list = afterDeletePerson;
                this.setState({ data: getMailDetailData });
              },
              (isLoading) => this.handleLoader(isLoading),
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  updatePersonCount = (group_id) => {
    let temp_data = this.state.data;
    let group_list = temp_data.group_list;
    const index = group_list.findIndex((group) => group.id == group_id);
    group_list[index].person = group_list[index].person - 1;
    temp_data.group_list = group_list;
    this.setState({ data: temp_data });
  };

  delete_group_item = (id) => {
    let after_delete_group_list = this.state.group_list.filter((item) => {
      return item.id != id;
    });

    this.setState({
      group_list: after_delete_group_list,
    });
  };

  handleAlphabatice = (name, index, list) => {
    name = name.charAt(0).toUpperCase() + name.slice(1);
    let resulAlpha = "";
    if (index == 0) {
      resulAlpha = name.charAt(0);
    } else {
      let oldName = list[index - 1].name;
      oldName = oldName.charAt(0).toUpperCase() + oldName.slice(1);
      const digit1 = oldName.charAt(0);
      const digit2 = name.charAt(0);

      if (digit1 != digit2) {
        resulAlpha = digit2;
      } else {
        resulAlpha = "";
      }
    }
    return resulAlpha;
  };

  handleCheckBox = (e, value) => {
    let selectedValue = this.state.selectedValue;
    if (e.target.checked) {
      selectedValue.push(value);
    } else {
      selectedValue = selectedValue.filter((e) => e !== value);
    }
    this.setState({
      selectedValue,
    });
  };

  handleAddModal = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          personModal: !prev.personModal,
        };
      },
      () => {
        if (this.state.personModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            const mail_list_id = this.props.match.params.mail_person_id;
            const fields = mail_list_id.split("-");
            const list_id = fields[0];
            this.loadPersonList(list_id);
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  handleEditModal = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          editGroup: !prev.editGroup,
        };
      },
      () => {
        if (this.state.editGroup) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            const mail_list_id = this.props.match.params.mail_person_id;
            const fields = mail_list_id.split("-");
            const list_id = fields[0];
            this.loadPersonList(list_id);
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  handleAddGroupModal = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          addGGroup: !prev.addGGroup,
        };
      },
      () => {
        if (this.state.addGGroup) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            const mail_list_id = this.props.match.params.mail_person_id;
            const fields = mail_list_id.split("-");
            const list_id = fields[0];
            this.loadPersonList(list_id);
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  handleAddGroupContactModal = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          addGroupContactModal: !prev.addGroupContactModal,
        };
      },
      () => {
        if (this.state.addGroupContactModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            const mail_list_id = this.props.match.params.mail_person_id;
            const fields = mail_list_id.split("-");
            const list_id = fields[0];
            this.loadPersonList(list_id);
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  deleteList = () => {
    const mail_list_id = this.props.match.params.mail_person_id;
    const fields = mail_list_id.split("-");
    const list_id = fields[0];
    const mail_id = fields[1];
    confirmAlert({
      title: this.props.t("personList.deleteList"),
      message: this.props.t("personList.deleteListMessage"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              removelist(getUserID(), list_id),
              (data) => {
                let mybackStackpath = "/mail-detail/" + mail_id;
                this.props.history.push(mybackStackpath);
              },
              (isLoading) => {
                this.setState({ deleteLoader: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };

  setSearchPersonList = (data, searchValue) => {
    this.setState(
      {
        searchData: data,
        searchValue,
      },
      () => {
        console.log("<---searchData--->", this.state.searchData);
      }
    );
  };

  
  handlePersonListBaseonCompany = (personListBaseOnMailID)=>{
    this.setState({
      personListBaseOnMailID,
      searchDisplayType:4
    })
  }
  
  handleAlphabaticeSort = (alphabet) => {
    const { group_list } = this.state;
    let index = -1;
    for (let i = 0; i < group_list.length; i++) {
      const group = group_list[i];
      let name = group.name;
      name = name.charAt(0).toUpperCase();
      if (alphabet == name && index == -1) {
        index = i;
      }
    }
    if (index != -1) {
      const divID = `groupDiv${index}`;
      const section = document.getElementById(divID);
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  handleAlphabatice = (name, index, list) => {
    name = name.charAt(0).toUpperCase() + name.slice(1);
    let resulAlpha = "";
    if (index == 0) {
      resulAlpha = name.charAt(0);
    } else {
      let oldName = list[index - 1].name;
      oldName = oldName.charAt(0).toUpperCase() + oldName.slice(1);
      const digit1 = oldName.charAt(0);
      const digit2 = name.charAt(0);

      if (digit1 != digit2) {
        resulAlpha = digit2;
      } else {
        resulAlpha = "";
      }
    }
    return resulAlpha;
  };

  loadPersonDataTypeList = () => {
    apiCall(
      METHOD.QUERY,
      getpersondatatypefromfilter(getUserID()),
      (data) => this.handlePersonDataTypeResponse(data),
      (isLoading) => {
        this.setState({ isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handlePersonDataTypeResponse = (data) => {
    this.setState({
      personDataTypeList: data.person_data_type_list,
    });
  };

  loadPersonDataTypeValue = (personDataTypeId) => {
    const mail_list_id = this.props.match.params.mail_person_id;
    const fields = mail_list_id.split("-");
    const list_id = fields[0];
    apiCall(
      METHOD.QUERY,
      getallpersondatatypevaluesfromfilter(
        getUserID(),
        personDataTypeId,
        list_id
      ),
      (data) => this.handlePersonDataTypeValueResponse(data),
      (isLoading) => {
        this.setState({
          isLoading,
        });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handlePersonDataTypeValueResponse = (data) => {
    this.setState({
      personDataTypeValueList: data.list,
    });
  };

  handleApplyFilter = (filterValue) => {
    const { personDataTypeId, list_id } = this.state;
    console.log("handleApplyFilter -->", filterValue);
    this.setState({
      personDataTypeValueIsOpen: false,
    });
    if (filterValue == "") {
      this.setState(
        {
          filterValue,
          personDataTypeName: "",
          personDataTypeId: 0,
        },
        () => {
          this.loadPersonList(list_id);
        }
      );
    } else {
      apiCall(
        METHOD.QUERY,
        getmailinglistgroupForFilter(
          getUserID(),
          list_id,
          personDataTypeId,
          filterValue
        ),
        (data) => {
          this.setState({
            header_list: data.mailing_list,
            group_list: data.group_list,
            breadcrumb: data.breadcrumb,
            data,
          });
        },
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  // Search method

  handleDisplayType = () => {
    this.setState({
      searchDisplayType: 0,
    });
  };

  setSearchMailList = (personList) => {
    if (personList.length == 0) {
    }
    this.setState({
      searchPersonList: personList,
      searchDisplayType: personList.length == 0 ? 0 : 1,
    });
  };

  handleSearchDisplayTypeToInitialStage = () => {
    this.setState({
      searchDisplayType: 0,
      search: "",
      value: "",
    });
  };
  handleSearchDisplayType = (searchDisplayType) => {
    this.setState({
      searchDisplayType,
      search: "",
      value: "",
    });
  };

  handleMailbaseOnPerson = (mailList) => {
    if (mailList.length == 0) {
      this.setState({
        mailListBaseOnPerson: [],
        searchPersonList: [],
        // searchDisplayType: 0,
      });
    } else {
      this.setState({
        // searchDisplayType: 0,
        mailListBaseOnPerson: mailList,
      });
    }
  };

  setmailFromPerson = (mailListBaseOnPerson, selectedPersonId) => {
    console.log("mailListBaseOnPerson", mailListBaseOnPerson);
    console.log("selectedPersonId", selectedPersonId);
    if (mailListBaseOnPerson.length != 0) {
      this.setState({
        mailListBaseOnPerson,
        searchDisplayType: 2,
        selectedPersonId,
      });
    } else {
      toast.info(this.props.t("GeneralMessages.noMailFound"));
    }
  };

  handleMailWiseResponse = (
    personListBaseOnMailID,
    personResponse,
    personListBreadCumb
  ) => {
    this.setState({
      personListBaseOnMailID,
      personListBreadCumb,
      personResponse,
      searchDisplayType: 3,
    });
  };
  render() {
    const {
      header_list,
      addGGroup,
      group_list,
      data,
      personModal,
      searchData,
      addGroupContactModal,
      editGroup,
      editListPermission,
      deleteListPermission,
      breadcrumb,
      list_id,
      personDataTypeListIsOpen,
      personDataTypeList,
      personDataTypeValueIsOpen,
      personDataTypeValueList,
      selectedValue,
      personDataTypeName,
      personDataTypeId,
      sortType,
      searchDisplayType,
      mailListBaseOnPerson,
      personListBaseOnMailID,
      personListBreadCumb,
      selectedPersonId,
      groupIdBaseOnPersonDetail
      
    } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="content-wrapper">
              <HeaderDesktop selectionType={4} />
              <div className="content">
                <div className="section section-padding">
                  <div className="container">
                    <div className="section-container">
                      <div className="d-flex t-n-25">
                        <Link to="/mail-list" className="mr-3">
                          <i className="fa fa-arrow-left fa-lg font-size-18"></i>
                        </Link>
                        <nav className="mr-auto" aria-label="breadcrumb">
                          <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                              <Link to="/mail-list">
                                {t("MailDetailScreen.mailList")}
                              </Link>
                            </li>
                            <li
                              className="breadcrumb-item pointer"
                              aria-current="page"
                              onClick={() => {
                                const mailDetailId = breadcrumb[0]?.id;
                                this.props.history.push(
                                  "/mail-detail/" + mailDetailId
                                );
                              }}
                            >
                              {breadcrumb[0]?.name}
                            </li>
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                              {data.mailing_name && data.mailing_name}
                            </li>
                          </ol>
                        </nav>

                        <button
                          type="button"
                          className="btn btn-primary-theme list btn-sm mr-2"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleAddModal(false);
                          }}
                        >
                          <i className="zmdi zmdi-plus zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                          {t("personDetail.addPerson")}
                        </button>

                        <button
                          type="button"
                          className="btn btn-primary-theme list btn-sm mr-2"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleAddGroupModal(false);
                          }}
                        >
                          <i className="zmdi zmdi-plus zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                          {t("personList.addGroup")}
                        </button>
                        {deleteListPermission && (
                          <button
                            type="button"
                            className="btn btn-primary-theme list btn-sm mr-2"
                            onClick={(e) => {
                              e.preventDefault();
                              this.deleteList();
                            }}
                          >
                            <i className="zmdi zmdi-delete zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                            {t("personList.deleteGroup")}
                          </button>
                        )}

                        {editListPermission && (
                          <button
                            type="button"
                            className="btn btn-primary-theme list btn-sm mr-2"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleEditModal(false);
                            }}
                          >
                            <i className="zmdi zmdi-edit zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                            {t("personList.editGroup")}
                          </button>
                        )}

                        <button
                          type="button"
                          className="btn btn-primary-theme list btn-sm mr-2"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleAddGroupContactModal(false);
                          }}
                        >
                          <i className="zmdi zmdi-plus zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                          {t("personDetail.addGroupContacts")}
                        </button>
                      </div>

                      <div className="section-title text-center d-md-flex align-items-start m-0">
                        <div className="flex-fill mr-n-80">
                          <h1
                            className="page-title h4 pointer"
                            onClick={this.handleBackStack}
                          >
                            {data.mailing_name && data.mailing_name}
                          </h1>
                        </div>
                       </div>

                      <div className="section-content">
                        <div className="d-flex align-items-center justify-content-center my-1">
                          <ul className="list-inline custom-tab big">
                            <li
                              className="list-inline-item "
                              style={{ minWidth: "100px" }}
                            >
                              <a href="#" className="active">
                                {t("MailDetailScreen.person")}
                                <span className="font-size-20 d-block">
                                  {data.total_person && data.total_person}
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="d-flex align-items-center justify-content-center  mb-2 ">
                          <div className="flex-fill mr-n-25">
                            <div className="w-50 mx-auto">
                              <div className="flex-fill ">
                              <SearchMail
                    handleMailbaseOnPerson={(mailList) => {
                      this.handleMailbaseOnPerson(mailList);
                    }}
                    handlePersonListBaseonCompany={(personList)=>{
                      this.handlePersonListBaseonCompany(personList)
                    }}

                    setmailFromPerson={(personList, selectedPersonId) => {
                      this.setmailFromPerson(personList, selectedPersonId);
                    }}
                    searchArray={this.setSearchMailList}
                    handleSearchDisplayType={(searchDisplayType) => {
                      this.handleSearchDisplayType(searchDisplayType);
                    }}
                  />
                              </div>
                            </div>
                          </div>
                        </div>

                        {searchDisplayType == 0 && (
                          <React.Fragment>
                            {/* <div className="d-flex align-items-center justify-content-center mb-4 pb-3">
                              <ul className="list-inline alphabetic-sort m-0">
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("A");
                                  }}
                                >
                                  A.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("B");
                                  }}
                                >
                                  B.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("C");
                                  }}
                                >
                                  C.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("D");
                                  }}
                                >
                                  D.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("E");
                                  }}
                                >
                                  E.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("F");
                                  }}
                                >
                                  F.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("G");
                                  }}
                                >
                                  G.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("H");
                                  }}
                                >
                                  H.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("I");
                                  }}
                                >
                                  I.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("J");
                                  }}
                                >
                                  J.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("K");
                                  }}
                                >
                                  K.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("L");
                                  }}
                                >
                                  L.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("M");
                                  }}
                                >
                                  M.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("N");
                                  }}
                                >
                                  N.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("O");
                                  }}
                                >
                                  O.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("P");
                                  }}
                                >
                                  P.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("Q");
                                  }}
                                >
                                  Q.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("R");
                                  }}
                                >
                                  R.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("S");
                                  }}
                                >
                                  S.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("T");
                                  }}
                                >
                                  T.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("U");
                                  }}
                                >
                                  U.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("V");
                                  }}
                                >
                                  V.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("W");
                                  }}
                                >
                                  W.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("X");
                                  }}
                                >
                                  X.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("Y");
                                  }}
                                >
                                  Y.
                                </li>
                                <li
                                  className="list-inline-item pointer"
                                  onClick={() => {
                                    this.handleAlphabaticeSort("Z");
                                  }}
                                >
                                  Z.
                                </li>
                              </ul>
                            </div> */}
                            <div className="inner-sm-container w-75 mx-auto">
                              <div className="d-flex justify-content-between align-items-center font-size-14 mb-4">
                                <ul className="list-inline custom-filter-dropdown filter-scroll m-0">
                                  <li
                                    className={
                                      personDataTypeListIsOpen
                                        ? "list-inline-item mr-4 dropdown show"
                                        : "list-inline-item mr-4 dropdown"
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState((prev) => {
                                        return {
                                          personDataTypeListIsOpen:
                                            !prev.personDataTypeListIsOpen,
                                        };
                                      });
                                    }}
                                  >
                                    <button
                                      type="button"
                                      class="btn  dropdown-toggle"
                                      data-toggle="dropdown"
                                      aria-expanded={
                                        personDataTypeListIsOpen
                                          ? "true"
                                          : "false"
                                      }
                                    >
                                      {t(
                                        "mailDetailPersonListFilter.filterPerson"
                                      )}
                                      <i className="zmdi zmdi-chevron-down zmdi-hc-2x font-size-28 align-middle ml-1"></i>
                                    </button>

                                    <div
                                      className={
                                        personDataTypeListIsOpen
                                          ? "dropdown-menu show"
                                          : "dropdown-menu"
                                      }
                                    >
                                      {personDataTypeList &&
                                        personDataTypeList.map(
                                          (item, index) => {
                                            const { id, name } = item;
                                            return (
                                              <a
                                                className="dropdown-item"
                                                href="#"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.setState(
                                                    {
                                                      personDataTypeValueIsOpen: true,
                                                      personDataTypeId: id,
                                                      personDataTypeName: name,
                                                    },
                                                    () => {
                                                      this.loadPersonDataTypeValue(
                                                        id
                                                      );
                                                    }
                                                  );
                                                }}
                                              >
                                                {name}
                                              </a>
                                            );
                                          }
                                        )}
                                    </div>
                                  </li>

                                  {personDataTypeId != 0 && (
                                    <li
                                      className={
                                        personDataTypeValueIsOpen
                                          ? "list-inline-item mr-4 dropdown show"
                                          : "list-inline-item mr-4 dropdown"
                                      }
                                      // onClick={() => {
                                      //   this.setState((prev) => {
                                      //     return {
                                      //       personDataTypeValueIsOpen: !prev.personDataTypeValueIsOpen,
                                      //     };
                                      //   });
                                      // }}
                                    >
                                      <button
                                        type="button"
                                        class="btn  dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-expanded={
                                          personDataTypeValueIsOpen
                                            ? "true"
                                            : "false"
                                        }
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.setState((prev) => {
                                            return {
                                              personDataTypeValueIsOpen:
                                                !prev.personDataTypeValueIsOpen,
                                            };
                                          });
                                        }}
                                      >
                                        {personDataTypeName}
                                        <i className="zmdi zmdi-chevron-down zmdi-hc-2x font-size-28 align-middle ml-1"></i>
                                      </button>

                                      <div
                                        className={
                                          personDataTypeValueIsOpen
                                            ? "dropdown-menu min-w-200 show"
                                            : "dropdown-menu min-w-200"
                                        }
                                      >
                                        {personDataTypeValueList &&
                                          personDataTypeValueList.map(
                                            (item, index) => {
                                              const { value } = item;
                                              return (
                                                <div
                                                  key={index}
                                                  className="dropdown-item 0align-self-center "
                                                >
                                                  <div className="d-flex">
                                                    <label className="checkbox-container">
                                                      {value == "Yes"
                                                        ? t(
                                                            "mailDetailPersonListFilter.yes"
                                                          )
                                                        : value == "No"
                                                        ? t(
                                                            "mailDetailPersonListFilter.no"
                                                          )
                                                        : value}
                                                      <input
                                                        type="checkbox"
                                                        onChange={(e) =>
                                                          this.handleCheckBox(
                                                            e,
                                                            value
                                                          )
                                                        }
                                                        checked={selectedValue.includes(
                                                          value
                                                        )}
                                                      />
                                                      <span className="checkmark align-self-center"></span>
                                                    </label>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        <div className="text-right mx-3 mt-4 mb-2">
                                          {personDataTypeValueList &&
                                          personDataTypeValueList.length !=
                                            0 ? (
                                            <div
                                              className="btn btn-primary-theme list pointer"
                                              onClick={() => {
                                                const { selectedValue } =
                                                  this.state;
                                                const value =
                                                  selectedValue.toString();
                                                console.log("value", value);
                                                this.handleApplyFilter(value);
                                              }}
                                            >
                                              {t("mailDetailPersonList.apply")}
                                            </div>
                                          ) : (
                                            <span>
                                              {t(
                                                "mailDetailPersonList.no_record_found"
                                              )}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </li>
                                  )}
                                </ul>
                                <div className="clearfix sorting-filter ">
                                  <span className="font-13 text-muted mr-2">
                                    {t("GroupListScreen.sorting")}
                                  </span>
                                  <div
                                    className="btn-group btn-group-sm"
                                    role="group"
                                    aria-label="First group"
                                  >
                                    <button
                                      type="button"
                                      onClick={() =>
                                        this.setState({ sortType: 1 })
                                      }
                                      className={
                                        sortType == 1
                                          ? "btn btn-dark mr-2"
                                          : "btn btn-outline-secondary mr-2"
                                      }
                                    >
                                      {t("mailDetailPersonList.all")}
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        this.setState({ sortType: 2 })
                                      }
                                      className={
                                        sortType == 2
                                          ? "btn btn-dark mr-2"
                                          : "btn btn-outline-secondary mr-2"
                                      }
                                    >
                                      {t("GroupListScreen.a-z")}
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        this.setState({ sortType: 3 })
                                      }
                                      className={
                                        sortType == 3
                                          ? "btn btn-dark mr-2"
                                          : "btn btn-outline-secondary mr-2"
                                      }
                                    >
                                      {t("mailDetailPersonList.contactGroup")}
                                    </button>
                                  </div>
                                </div>
                              </div>

                              {personModal && (
                                <GeneralPersonList
                                  handleAddModal={(isFromSave) => {
                                    this.handleAddModal(isFromSave);
                                  }}
                                />
                              )}
                              {addGGroup && (
                                <GeneralGGroupList
                                  handleAddGroupModal={(isFromSave) => {
                                    this.handleAddGroupModal(isFromSave);
                                  }}
                                />
                              )}
                              {addGroupContactModal && (
                                <GroupListAddContacts
                                  handleAddGroupContactModal={(isFromSave) => {
                                    this.handleAddGroupContactModal(isFromSave);
                                  }}
                                />
                              )}
                              {editGroup && (
                                <EditMail
                                  data={this.state.data}
                                  handleEditModal={(isFromSave) => {
                                    this.handleEditModal(isFromSave);
                                  }}
                                />
                              )}

                              {searchData?.group_list.length == 0 &&
                                sortType != 2 &&
                                group_list &&
                                group_list.map((item, index) => {
                                  return (
                                    <GroupItem
                                      delete_group_item={this.delete_group_item}
                                      updatePersonCount={this.updatePersonCount}
                                      key={index}
                                      item={item}
                                      index={index}
                                    />
                                  );
                                })}
                              {searchData.group_list.length != 0 &&
                                sortType != 2 &&
                                this.state.searchValue != "" &&
                                searchData.group_list.map((item, index) => {
                                  return (
                                    <GroupItem
                                      delete_group_item={this.delete_group_item}
                                      updatePersonCount={this.updatePersonCount}
                                      key={index}
                                      item={item}
                                      index={index}
                                    />
                                  );
                                })}

                              {searchData.person_list.length != 0 &&
                                sortType != 3 &&
                                this.state.searchValue != "" &&
                                searchData.person_list.length != 0 && (
                                  <div className="table-responsive">
                                    <table className="table table-hover font-size-14  m-0 bg-white">
                                      {searchData.person_list &&
                                        searchData.person_list.map(
                                          (item, index) => {
                                            const {
                                              id,
                                              name,
                                              email,
                                              phone,
                                              is_deletable,
                                            } = item;
                                            return (
                                              <>
                                                {this.handleAlphabatice(
                                                  name,
                                                  index,
                                                  searchData.person_list
                                                ) != "" && (
                                                  <thead className="bg-white ">
                                                    <tr>
                                                      <th>
                                                        <span className="letter">
                                                          {this.handleAlphabatice(
                                                            name,
                                                            index,
                                                            searchData.person_list
                                                          )}
                                                        </span>
                                                      </th>
                                                      {index == 0 && (
                                                        <>
                                                          {" "}
                                                          <th>
                                                            <i className="zmdi zmdi-email zmdi-hc-2x font-size-28 text-muted"></i>
                                                          </th>
                                                          <th>
                                                            <i className="zmdi zmdi-smartphone-android zmdi-hc-2x font-size-28 text-muted"></i>
                                                          </th>
                                                        </>
                                                      )}
                                                    </tr>
                                                  </thead>
                                                )}

                                                <tbody className="bg-white ">
                                                  <tr>
                                                    <td className="font-weight-600">
                                                      {name}
                                                    </td>
                                                    <td className="text-muted">
                                                      {email}
                                                    </td>
                                                    <td className="text-muted">
                                                      {phone}
                                                    </td>

                                                    {is_deletable == 1 && (
                                                      <td className="text-right">
                                                        <a
                                                          href="javascript;:"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            this.handleDelete(
                                                              item
                                                            );
                                                          }}
                                                        >
                                                          <i className="zmdi zmdi-delete zmdi-hc-lg"></i>
                                                        </a>
                                                      </td>
                                                    )}
                                                  </tr>
                                                </tbody>
                                              </>
                                            );
                                          }
                                        )}
                                    </table>
                                  </div>
                                )}
                              {searchData.person_list.length == 0 &&
                                sortType != 3 &&
                                this.state.searchValue == "" &&
                                data.person_list.length != 0 && (
                                  <div className="table-responsive">
                                    <table className="table table-hover font-size-14  m-0 bg-white">
                                      {data.person_list &&
                                        data.person_list.map((item, index) => {
                                          const {
                                            id,
                                            name,
                                            email,
                                            phone,
                                            is_deletable,
                                          } = item;
                                          return (
                                            <>
                                              {this.handleAlphabatice(
                                                name,
                                                index,
                                                data.person_list
                                              ) != "" && (
                                                <thead className="bg-white ">
                                                  <tr>
                                                    <th>
                                                      <span className="letter">
                                                        {this.handleAlphabatice(
                                                          name,
                                                          index,
                                                          data.person_list
                                                        )}
                                                      </span>
                                                    </th>
                                                    {index == 0 && (
                                                      <>
                                                        {" "}
                                                        <th>
                                                          <i className="zmdi zmdi-email zmdi-hc-2x font-size-28 text-muted"></i>
                                                        </th>
                                                        <th>
                                                          <i className="zmdi zmdi-smartphone-android zmdi-hc-2x font-size-28 text-muted"></i>
                                                        </th>
                                                      </>
                                                    )}
                                                  </tr>
                                                </thead>
                                              )}

                                              <tbody
                                                className="bg-white "
                                                style={{ borderTop: "none" }}
                                              >
                                                <tr>
                                                  <td className="font-weight-600">
                                                    {name}
                                                  </td>
                                                  <td className="text-muted">
                                                    {email}
                                                  </td>
                                                  <td className="text-muted">
                                                    {phone}
                                                  </td>
                                                  <td className="text-right">
                                                    {is_deletable == 1 && (
                                                      <a
                                                        href="javascript;:"
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          this.handleDelete(
                                                            item
                                                          );
                                                        }}
                                                      >
                                                        <i className="zmdi zmdi-delete zmdi-hc-lg"></i>
                                                      </a>
                                                    )}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </>
                                          );
                                        })}
                                    </table>
                                  </div>
                                )}
                            </div>
                          </React.Fragment>
                        )}

              
{searchDisplayType == 1 && (
                 <SearchPersonResult 
                      suggetionList={this.state.searchPersonList}
                      handlePersonListBaseonCompany={(personList)=>{
                        this.handlePersonListBaseonCompany(personList)
                      }}
                      setmailFromPerson={(personList, selectedPersonId) => {
                        this.setmailFromPerson(
                          personList,
                          selectedPersonId
                        );
                      }}/>
                )}


                {searchDisplayType == 2 && (
                  <div className="container my-2">
                    {mailListBaseOnPerson.map((data, index) => {
                      return (
                        <SearchMailListBaseOnPerson
                          data={data}
                          key={index}
                          selectedPersonId={selectedPersonId}
                          handleMailWiseResponse={(
                            personListBaseOnMailID,
                            personResponse,
                            personListBreadCumb,
                            groupIdBaseOnPersonDetail
                          ) => {
                            this.handleMailWiseResponse(
                              personListBaseOnMailID,
                              personResponse,
                              personListBreadCumb,
                              groupIdBaseOnPersonDetail
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                )}

                {searchDisplayType == 3 && (
                  <div className="container my-2">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          {t("mailDetail.home")}
                        </li>
                        <li
                          className="breadcrumb-item pointer"
                          aria-current="page"
                          onClick={() => {
                            // const mailDetailId = breadcrumb[0]?.id
                            // this.props.history.push("/mail-detail/" + mailDetailId);
                          }}
                        >
                          {personListBreadCumb[0]?.name}
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          {personListBreadCumb[1]?.name}
                        </li>
                      </ol>
                    </nav>

                    {personListBaseOnMailID.map((data, index) => {
                      return (
                        <SearchPersonBaseOnMail
                          handleSearchDisplayType={(searchDisplayType) => {
                            this.handleSearchDisplayType(searchDisplayType);
                          }}
                          data={data}
                          key={index}
                          personResponse={this.state.personResponse}
                          groupIdBaseOnPersonDetail={groupIdBaseOnPersonDetail}
                        />
                      );
                    })}
                  </div>
                )}


                        
{searchDisplayType == 4 && (
                          <div className="container my-2">
                            {personListBaseOnMailID.map((data, index) => {
                              return (
                                <SearchPersonBaseonCompany
                                  handleSearchDisplayType={(
                                    searchDisplayType
                                  ) => {
                                    this.handleSearchDisplayType(
                                      searchDisplayType
                                    );
                                  }}
                                  data={data}
                                  key={index}
                                  personResponse={this.state.personResponse}
                                  groupIdBaseOnPersonDetail={
                                    groupIdBaseOnPersonDetail
                                  }
                                />
                              );
                            })}
                          </div>
                        )}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FooterDesktop selectionType={4} />
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.mailDetailStateToProps,
        stateToProps.mailPersonListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.mailDetailsDispatchToProps,
    actions.mailPersonListDispatchToProps,
  ])
)(withTranslation()(withRouter(PersonList)));
