/* eslint eqeqeq: 0 */
import ApolloClient, { gql } from "apollo-boost";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { toast } from "react-toastify";
import i18n from "./i18n";
export const mainURL = 'https://dev.urgentpublish-demo.de/api/'
const cache = new InMemoryCache({
  dataIdFromObject: (o) => o.id || null,
});

export const client = new ApolloClient({
  uri: "https://dev.urgentpublish-demo.de/api/graphql/",
  cache,
});

export const uploadLink = createUploadLink({
  uri: "https://dev.urgentpublish-demo.de/api/graphql/", // Apollo Server is served from port 4000
  headers: {
    "keep-alive": "true",
  },
});

export const METHOD = {
  QUERY: "query",
  MUTATION: "mutation",
};

export default (
  method = METHOD.QUERY,
  params = {},
  onSuccess,
  loading,
  onFailure,
  callBack
) => {
  loading(true);
  let request = {};
  switch (method) {
    case METHOD.QUERY:
      request = client.query({
        query: gql(params),
        fetchPolicy: "network-only",
      });
      break;
    case METHOD.MUTATION:
      request = client.mutate({
        query: gql(params),
        fetchPolicy: "network-only",
      });
      break;
    default:
      break;
  }
  request
    .then(({ data }) => {
      loading(false);
      let whichvar = Object.keys(data);
      const { success } = data[whichvar];

      if (success == 0) {
        // console.log(data);
        onFailure(data[whichvar]);
      } else {
        onSuccess(data[whichvar]);
      }
    })
    .catch((e) => {
      loading(false);
      toast.error(
        i18n.getDataByLanguage("en").translation.GeneralMessages
          .errorServerisNotResponding &&
          i18n.getDataByLanguage("en").translation.GeneralMessages
            .errorServerisNotResponding
      );
    });
};
