import React, { Component } from 'react'
import { Helmet } from "react-helmet";

export default class DesktopHelmetTag extends Component {
    render() {
        return (
          <React.Fragment>
            <Helmet>
              <meta charset="utf-8" />
              <link
                rel="icon"
                type="image/png"
                href="/images/favicon.ico"
                sizes="16x16"
              />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <meta name="theme-color" content="#000000" />
              <meta name="description" content="URGENTpublish crisis communication" />
              <meta name="robots" content="noindex nofollow" />

              <link rel="icon" href="/images/favicon.ico" type="image/x-icon" />
    <link rel="stylesheet" href="/font-awesome/css/all.min.css" />
    <link rel="stylesheet" href="/material-font/css/material-design-iconic-font.min.css"/>
     <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
    <link rel="stylesheet" href="/font-awesome/css/all.min.css" />
    <script type="text/jsx" src="/js/mdb.min.js"></script>
    <link rel="stylesheet" href="/css/bootstrap.min.css" />
    <link rel="stylesheet" href="/css/styleDesktopVersion.css" crossorigin="anonymous" />
    <link
                rel="stylesheet"
                href="/css/bootstrap-fileinput/bootstrap-fileinput.css"
              />
          </Helmet>
          </React.Fragment>
        );
    }
}
