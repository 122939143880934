import React from "react";
import MediaQuery from "react-responsive";
import ChannelMobile from "./ChannelMobile/Index";
import ChannelDesktop from "./ChannelDesktop/Index";

export default function ChannelResponsive() {
  return (
    <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <ChannelDesktop />
        
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <ChannelMobile />
      </MediaQuery>
    </React.Fragment>
  );
}
