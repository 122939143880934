import React, { Component } from 'react'
import MediaQuery from "react-responsive";
import ManageLocationMobile from "./ManageLocationMobile/Index";
import ManageLocationDesktop from "./ManageLocationDesktop/LocationList";
import { withRouter } from 'react-router';
import { checkAdminPermission } from '../../../util/utils';

 class ManageLocationResponsive extends Component {
   componentDidMount() {
    if(!checkAdminPermission()){
      this.props.history.push("/")
    }
   }
  render() {
    return (
      <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <ManageLocationDesktop />
        
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <ManageLocationMobile />
      </MediaQuery>
    </React.Fragment>
    )
  }
}
export default withRouter(ManageLocationResponsive)