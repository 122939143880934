/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import LogoutMenu from "../../CommanComponents/LogoutMenu";
import TopHeader from "../../CommanComponents/TopHeader";
import SearchGroup from "../../Group/GroupListMobile/SearchGroup";

class GroupDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      range: 0,
      child_group_list: [],
      isSubGroupList: false,
      isChild_Group_Loading: true,
      listType: 0,
    };
  }

  componentWillReceiveProps = () => {
    const { child_group_list } = this.state;
    const new_list = this.props.group_header_list;
    if (
      new_list.length != 0 &&
      JSON.stringify(child_group_list) != JSON.stringify(new_list)
    ) {
      this.setState({
        child_group_list: this.props.group_header_list,
      });
      this.setState({
        isChild_Group_Loading: true,
      });
    } else {
      this.setState({
        isChild_Group_Loading: false,
      });
    }
  };
  // handleChangeGroup = group_id => {
  //   this.props.history.push(
  //     "/group-detail/" + group_id
  //   );

  //   this.props.clearStateGroupDetail({});
  //   this.loadItem();
  // };
  handleChangeGroup = (group_id, person) => {
    this.props.clearStateGroupDetail({});
    this.props.clearStateGroupPersonDetail({});
    if (person == 0) {
      this.setState({ isSubGroupList: true });
      this.props.history.push("/group-detail/" + group_id);
    } else {
      this.setState({ isSubGroupList: false });
      this.props.history.push("/group-personlist/" + group_id);
    }
  };

  handleActiveIndex = (group_id, id) => {
    if (group_id == id) {
      this.handleScroll(group_id);

      return "active";
    } else {
      return "";
    }
  };

  handleScroll = (id) => {
    const { child_group_list } = this.state;
    const index =
      child_group_list && child_group_list.findIndex((group) => group.id == id);

    if (index > 3) {
      setTimeout(function () {
        var element = document.getElementById(`li_${id}`);
        var element1 = document.getElementById("ulID");
        var elementPosition = element.getBoundingClientRect();
        element1.scrollLeft = elementPosition.left;
      }, 500);
    }
  };

  render() {
    const { total_group } = this.props.groupDetail;
    const { groupName, listType, isFromPersonList } = this.props;
    // const pathName = this.props.location.pathname;
    // let group_id = 0;
    // if (pathName.includes("group-detail")) {
    //   group_id = this.props.groupDetail.id;
    // } else if (pathName.includes("group-personlist")) {
    //   group_id = this.props.groupDetailPersonList.id;
    // }
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <>
              <div className="header-bg header-blue sticky-top">
                <div className="container">
                  <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                    <TopHeader />
                    <Link
                      to="/group-list"
                      className="mb-0 ml-auto text-light pointer"
                    >
                      {groupName && groupName}
                    </Link>
                    <LogoutMenu />
                  </div>
                  <div className="clearfix ">
                    <div className="d-flex justify-content-center">
                      <ul className="list-inline header-count-list text-center m-0">
                        {!isFromPersonList && (
                          <li
                            className={
                              listType == 0
                                ? "list-inline-item pointer active"
                                : "list-inline-item pointer"
                            }
                            onClick={() => {
                              this.props.handleListType(0);
                            }}
                          >
                            <p className="font-16 text-white m-0">
                              {t("GroupListScreen.groupLable")}
                            </p>
                            <h5 className="font-weight-bold text-white m-0">
                              {total_group && total_group}
                            </h5>
                          </li>
                        )}

                        {!isFromPersonList && (
                          <li
                            className={
                              listType == 1
                                ? "list-inline-item pointer active"
                                : "list-inline-item pointer"
                            }
                            onClick={() => {
                              if(this.props?.groupDetail?.total_contact!=0){
                                this.props.handleListType(1)
                              }else{
                                toast.warn(this.props.t("GroupDetailScreen.personNotFound"));
                              }
                            }}
                          >
                            <p className="font-16 text-white m-0">
                              {t("GroupListScreen.personLable")}
                            </p>
                            <h5 className="font-weight-bold text-white m-0">
                              {this.props.groupDetail.total_contact &&
                                this.props.groupDetail.total_contact}
                            </h5>
                          </li>
                        )}

                        {isFromPersonList && (
                          <li className={"list-inline-item pointer active"}>
                            <p className="font-16 text-white m-0">
                              {t("GroupListScreen.personLable")}
                            </p>
                            <h5 className="font-weight-bold text-white m-0">
                            {this.props.GroupDetailPersonCount || 0}
                            </h5>
                          </li>
                        )}
                      </ul>
                    </div>
                    <SearchGroup
                    handleMailbaseOnPerson={(mailList) => {
                      this.handleMailbaseOnPerson(mailList);
                    }}
                    setGroupFromPerson={(personList,selectedPersonId)=>{this.props.setGroupFromPerson(personList,selectedPersonId)}}
                    searchArray={this.props.searchArray}
                    handleSearchDisplayType={(searchDisplayType) => {
                      this.props.handleSearchDisplayType(searchDisplayType);
                    }}
                handlePersonListBaseonCompany={(personList)=>this.props.handlePersonListBaseonCompany(personList)}

                  />
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.groupListStateToProps,
        stateToProps.groupDetailStateToProps,
        stateToProps.groupDetailPersonListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupListDispatchToProps,
    actions.groupDetailDispatchToProps,
    actions.groupDetailPersonListDispatchToProps,
  ])
)(withRouter(withTranslation()(GroupDetails)));
