/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ArchiveEventQuery,
  DeleteEventQuery,
  ReportListQuery,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../../util/utils";
import NoRecordFoundTemplate2 from "../../CommanComponents/NoRecordFoundTemplate2";
import FooterDesktop from "../../Footer/FooterDesktop";
import HeaderDesktop from "../../Header/HeaderDesktop";
import moment from "moment-timezone";
import EditNewEvent from "./EditNewEvent";
import AddNewReport from "./AddNewReport";
import CommonLoader from "../../CommanComponents/CommonLoader";
import { confirmAlert } from "react-confirm-alert";

class ReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      eventList: [],
      range: 0,
      is_child_array_equal: true,
      headerMenuStatus: false,
      newEventModalOpen: false,
      messageModalOpen: false,
      archiveEventPermission: false,
      newMessagePermission: false,
      deleteEventPermission: false,
      deleteEventProgress: false,
    };
  }
  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  componentDidMount() {
    const editEventPermission = checkUserPermission("edit_event");
    const archiveEventPermission = checkUserPermission("archive_event");
    const newMessagePermission = checkUserPermission("create_report");
    const deleteEventPermission = checkUserPermission("delete_event");
    this.setState({
      editEventPermission,
      archiveEventPermission,
      newMessagePermission,
      deleteEventPermission,
    });

    this.fetchReportList();
  }
  fetchReportList = () => {
    const event_id = this.props.match.params.event_id;
    const readReportList = checkUserPermission("read_report");
    if (readReportList) {
      apiCall(
        METHOD.QUERY,
        ReportListQuery(getUserID(), event_id),
        (data) => {
          this.props.getReportList(data);
        },
        (isLoading) => this.handleLoader(isLoading),
        (data) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    } else {
      toast.info(this.props.t("GeneralMessages.permissionError"));
    }
  };
  handleAfterApiCall = (data) => {
    this.setState(() => {
      return {
        ...this.state,
        eventList: data.event_list,
      };
    });
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  handleChangeEvent = (event_id) => {
    const readReportList = checkUserPermission("read_report");
    if (readReportList) {
      this.setState({ scrollset: false });
      let pathname = `/event/${event_id}`;
      this.props.history.push(pathname);
      this.getReportList(event_id);
    } else {
      toast.info(this.props.t("GeneralMessages.permissionError"));
    }
  };
  getReportList = (eventID) => {
    apiCall(
      METHOD.QUERY,
      ReportListQuery(getUserID(), eventID),
      (data) => {
        this.props.getReportList(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleBackStack = () => {
    let { from } = this.props.location.state || { from: { pathname: "/" } };
    this.props.history.push(from);
  };
  handelReportClick = (event_id, report_id) => {
    const { pathname } = this.props.location;
    const location = {
      pathname: `${this.props.history.location.pathname}/report/${report_id}`,
      state: { from: { pathname: pathname } },
    };
    this.props.history.push(location);
  };

  handleActiveIndex = (event_id, id) => {
    if (event_id == id) {
      return "active";
    } else {
      return "";
    }
  };

  handleEditeventModal = (eventId) => {
    this.setState((prev) => {
      return {
        eventOperationType: prev.newEventModalOpen ? "" : "edit",
        newEventModalOpen: !prev.newEventModalOpen,
        editEventId: eventId,
      };
    });
  };

  handleMessageModal = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          messageModalOpen: !prev.messageModalOpen,
        };
      },
      () => {
        if (this.state.messageModalOpen) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.fetchReportList();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  archiveEvent = (event_id) => {
    apiCall(
      METHOD.QUERY,
      ArchiveEventQuery(getUserID(), event_id),
      (data) => {
        let count = this.props.eventList.total_event;
        count = count && count - 1;
        this.props.setEventCount(count);
        toast.success(this.props.t(data.message));
        this.props.deleteEvent({ id: event_id });
        this.props.history.push("/home");
      },
      (isLoading) => {},
      (data) => {
        toast.warn(this.props.t(data.message));
      }
    );
  };

  deleteCurrentEvent = () => {
    const event_id = this.props.match.params.event_id;
    confirmAlert({
      title: this.props.t("EventDetails.deleteEvent"),
      message: this.props.t("EventDetails.deleteMessage"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              DeleteEventQuery(getUserID(), event_id),
              (data) => {
                let count = this.props.eventList.total_event;
                count = count && count - 1;
                this.props.setEventCount(count);
                toast.success(this.props.t(data.message));
                this.props.deleteEvent({ id: event_id });
                this.props.history.push("/home");
              },
              (isLoading) => {
                this.setState({
                  deleteEventProgress: true,
                });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    const {
      id,
      name,
      event_datetime,
      event_creator_name,
      event_type_icon,
      location,
      report_list,
      event_list,
      is_archive,
    } = this.props.reportList;
    const event_id = id;
    const {
      isLoading,
      newEventModalOpen,
      messageModalOpen,
      archiveEventPermission,
      newMessagePermission,
      deleteEventPermission,
      editEventPermission,
    } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="content-wrapper">
              <HeaderDesktop selectionType={2} />
              <div className="content">
                <div className="section section-padding">
                  <div className="container">
                    <div className="section-container">
                      <div className="d-flex t-n-25">
                        <Link to="/home" className="mr-3">
                          <i className="fa fa-arrow-left fa-lg font-size-18"></i>
                        </Link>
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                              <Link to="/home">{t("EventDetails.event")} </Link>
                            </li>
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                              {name && name}
                            </li>
                          </ol>
                        </nav>
                      </div>
                      <div className="section-title text-center">
                        <h1 className="page-title h4">{name && name}</h1>
                      </div>
                      <div className="section-content">
                        <div className="d-flex align-items-center justify-content-center mb-4">
                          <ul className="list-inline custom-tab event">
                            {event_list &&
                              event_list.map((data, index) => {
                                const { id, name } = data;
                                return (
                                  <li className="list-inline-item" key={index}>
                                    <a
                                      href="javascript;:"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.handleChangeEvent(id);
                                      }}
                                      className={this.handleActiveIndex(
                                        event_id,
                                        id
                                      )}
                                    >
                                      {name && name}
                                    </a>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                        <div className="card">
                          {isLoading && <CommonLoader />}
                          <div className="card-header-custom">
                            <div className="media">
                            <div className="bg-grey left-icon-box d-flex align-items-center justify-content-center text-center">
                <div
                              className="bg-red left-icon-box d-flex align-items-center justify-content-center text-center"
                              style={{ background: "#b31218" }}
                            >
                            <div className="circle big m-0">
                    <img
                      src={`data:image/png;base64,${
                        event_type_icon && event_type_icon
                      }`}
                      alt="ico"
                      style={{ width: "32px" }}
                    />
                  </div>
                </div>
                </div>
             <div className="media-body pt-3 px-4">
                                <div className="form-row">
                                  <div className="col-md-4">
                                    <h5 className="font-size-18 mb-1">
                                      {name && name}
                                    </h5>
                                    <small>
                                      {event_creator_name && event_creator_name}
                                    </small>
                                    <div className="d-flex align-items-center font-size-14 mt-2">
                                      <div className="icon mr-3">
                                        <i
                                          className="zmdi zmdi-calendar-note zmdi-hc-fw font-16"
                                          style={{ verticalAlign: "-1px" }}
                                        ></i>{" "}
                                        {event_datetime &&
                                          moment
                                            .unix(event_datetime)
                                            .tz(moment.tz.guess())
                                            .format("DD.MM.YYYY")}
                                      </div>
                                      <div className="icon mr-3">
                                        <i
                                          className="zmdi zmdi-time zmdi-hc-fw font-16"
                                          style={{ verticalAlign: "-1px" }}
                                        ></i>
                                        {event_datetime &&
                                          moment
                                            .unix(event_datetime)
                                            .tz(moment.tz.guess())
                                            .format("HH:mm")}
                                      </div>
                                      <div className="icon mr-3">
                                        <i className="zmdi zmdi-pin-drop mr-1"></i>{" "}
                                        {location && location}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-8">
                                    <div className="font-size-14 action-icon text-right">
                                      {editEventPermission && (
                                        <a
                                          href="javascript;:"
                                          className="mr-4"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.handleEditeventModal(
                                              this.props.match.params.event_id
                                            );
                                          }}
                                        >
                                          <i className="zmdi zmdi-edit zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                          {t("HomeScreen.toEdit")}
                                        </a>
                                      )}

                                      {newMessagePermission && (
                                        <a
                                          href="javascript;:"
                                          className="mr-4"
                                          data-toggle="modal"
                                          data-target="#NewMessage"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.handleMessageModal(false);
                                          }}
                                        >
                                          <i className="zmdi zmdi-comment zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                          {t("HomeScreen.newMessage")}
                                        </a>
                                      )}

                                      {archiveEventPermission &&
                                        is_archive == 0 && (
                                          <a
                                            href="javascript;:"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.archiveEvent(
                                                this.props.match.params.event_id
                                              );
                                            }}
                                            className="mr-4"
                                          >
                                            <i className="zmdi zmdi-archive zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                            {t("HomeScreen.archiveEvent")}
                                          </a>
                                        )}
                                      {deleteEventPermission && (
                                        <a
                                          href="javascript;:"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.deleteCurrentEvent();
                                          }}
                                          className="mr-4"
                                        >
                                          <i className="zmdi zmdi-delete zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                          {t("EventDetails.deleteEvent")}
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {!isLoading &&
                            report_list &&
                            report_list.length == 0 && (
                              <NoRecordFoundTemplate2 title="Report List" />
                            )}
                          {newEventModalOpen && (
                            <EditNewEvent
                              eventId={this.props.match.params.event_id}
                              operation="edit"
                              handleEditeventModal={() => {
                                this.handleEditeventModal(
                                  this.props.match.params.event_id
                                );
                              }}
                            />
                          )}
                          {messageModalOpen && (
                            <AddNewReport
                              handleMessageModal={(isFromSave) => {
                                this.handleMessageModal(isFromSave);
                              }}
                            />
                          )}
                          <div className="card-body-custom side-line">
                            {report_list &&
                              report_list.map((item, index) => {
                                const {
                                  id,
                                  report_datetime,
                                  report_type_icon,
                                  title,
                                  report_creator_name,
                                } = item;
                                return (
                                  <a
                                    href="javascript;:"
                                    className="media"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.handelReportClick(event_id, id);
                                    }}
                                    key={index}
                                  >
                                    <div className="left-icon-box small">
                                      <div
                                        className="circle"
                                        style={{ backgroundColor: "#b31218" }}
                                      >
                                        <img
                                          src={`data:image/png;base64,${
                                            report_type_icon && report_type_icon
                                          }`}
                                          alt="ico"
                                          style={{
                                            width: "24px",
                                            height: "24px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="media-body pt-3 px-4">
                                      <h6 className="font-size-15 mb-1">
                                        {title && title}
                                      </h6>
                                      <small>
                                        {report_creator_name &&
                                          report_creator_name}
                                      </small>
                                      <div className="d-flex align-items-center font-size-13 mt-1">
                                        <div className="icon mr-3">
                                          <i className="zmdi zmdi-calendar-note zmdi-hc-fw font-16"></i>{" "}
                                          {report_datetime &&
                                            moment
                                              .unix(report_datetime)
                                              .tz(moment.tz.guess())
                                              .format("DD.MM.YYYY")}
                                        </div>
                                        <div className="icon mr-3">
                                          <i className="zmdi zmdi-time zmdi-hc-fw font-16"></i>{" "}
                                          {report_datetime &&
                                            moment
                                              .unix(report_datetime)
                                              .tz(moment.tz.guess())
                                              .format("HH:mm")}
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FooterDesktop selectionType={2} />
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.eventListStateToProps,
        stateToProps.reporttListStateToProps,
        stateToProps.eventEventCountStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.reportListDispatchToProps,
    actions.eventListDispatchToProps,
    actions.deleteEventReportDispatchToProps,
    actions.editEventReportDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withTranslation()(withRouter(ReportList)));
