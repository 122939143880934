/* eslint eqeqeq: 0 */
/* eslint eqeqeq: 0 */
import React, { useEffect, useState } from "react";
import {
  initReactI18next,
  Translation,
  useTranslation,
  withTranslation,
} from "react-i18next";
import { withRouter } from "react-router";
import FooterDesktop from "../Footer/FooterDesktop";
import HeaderDesktop from "../Header/HeaderDesktop";

function ChangeLanguageDesktop(props) {
  const [language, setLanguage] = useState(0);
  const { t, i18n } = useTranslation("translation");

  const handleBackStack = () => {
    let { from } = props.location.state || { from: { pathname: "/" } };
    props.history.push(from);
  };
  console.log(t);
  useEffect(() => {
    i18n.changeLanguage(language);
    i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({ lng: language });
  }, [language, i18n]);

  const handleRadioButton = (e, language) => {
    if (e.target.checked) {
      setLanguage(language);
    }
  };
  const checkedStatus = (lang) => {
    if (language == lang) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Translation ns="translation">
      {(t, { i18n }) => {
        return (
          <div className="content-wrapper">
            <HeaderDesktop selectionType={0}/>
            <div className="content">
              <div className="section section-padding">
                <div className="container">
                  <div className="section-container">
                    <div className="section-title">
                        <h1 className="page-title h4">
                        {t("logOutMenu.changeLanguage")}
                        </h1>
                    </div>
                    <div className="section-content">
                      <div className="card mt-4">
                        <div className="card-body"> 
                          <ul className="list-unstyled m-0">
                              <li className="d-flex mb-4">
                                <div>
                                  <label className="checkbox-container">
                                    <input
                                      type="radio"
                                      onChange={(e) =>
                                        handleRadioButton(e, "en")
                                      }
                                      checked={checkedStatus("en")}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                                <a
                                  href="javascript;:"
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                  className="clearfix d-flex align-items-center flex-fill ml-2"
                                >
                                  <div className="align-self-center d-flex">
                                    <img
                                      src="/images/en_lang_icon.png"
                                      className="mr-2"
                                      alt=""
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                    <h5 className="m-0 font-18 d-block">
                                      {t("logOutMenu.lblEnglish")}
                                    </h5>
                                  </div>
                                </a>
                              </li>
                              <div className="d-flex">
                                <div>
                                  <label className="checkbox-container">
                                    <input
                                      type="radio"
                                      onChange={(e) =>
                                        handleRadioButton(e, "de")
                                      }
                                      checked={checkedStatus("de")}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                                <a
                                  href="javascript;:"
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                  className="clearfix d-flex align-items-center flex-fill ml-2"
                                >
                                  <div className="align-self-center d-flex">
                                    <img
                                      src="/images/de_lang_icon.png"
                                      className="mr-2"
                                      alt=""
                                      style={{ width: "24px", height: "24px" }}
                                    />

                                    <h5 className="m-0 font-18 d-block">
                                      {t("logOutMenu.lblGermen")}
                                    </h5>
                                  </div>
                                </a>
                              </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FooterDesktop selectionType={0}/>
          </div>
        );
      }}
    </Translation>
  );
}
export default withRouter(withTranslation()(ChangeLanguageDesktop));
