/* eslint eqeqeq: 0 */
import { convertToRaw, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import {
  createTypeStrategy,
  DraftJS,
  editorStateFromRaw,
  MegadraftEditor,
} from "megadraft";
//Import megadraft.css
import "megadraft/dist/css/megadraft.css";
// this is the default LinkInput that handles `LINK`-entities:
import LinkInput from "megadraft/lib/entity_inputs/LinkInput";
import icons from "megadraft/lib/icons";
//Editor Import
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  adminCreateSnippet,
  getSnippetType,
  refernceSnippetList,
} from "../../../../constants/apiParams";
import apiCall, { METHOD } from "../../../../constants/baseUrl";
import * as actions from "../../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../../constants/mapStateToProps";
import { getUserID } from "../../../../util/utils";
import Footer from "../../../Footer/FooterMobile";
import LoadingPage from "../../../CommanComponents/LoadingPage";
import ConditionalDialogue from "./EditorBaseOnType/ConditionalDialogue";
import DateTimeType from "./EditorBaseOnType/DateTimeType";
import NumberType from "./EditorBaseOnType/NumberType";
import SelectionType from "./EditorBaseOnType/SelectionType";
import TextType from "./EditorBaseOnType/TextType";
import SnippetTypeList from "./SnippetTypeList";

/**
 * All Editor Related method
 */

/*
 * This component represents the reference in the editor. In this case it is a span
 * */
const SnippetReferenceComponent = ({ entityKey, children, contentState }) => {
  const { url } = contentState.getEntity(entityKey).getData();

  return (
    <span
      className="editor__link"
      href={url}
      title={url}
      style={{ backgroundColor: "red" }}
    >
      {children}
    </span>
  );
};

/*
 * This is the dialog to select the reference to assign to the selected text
 * The list should be loaded dynamically
 * */
class ReferenceInput extends React.Component {
  constructor(props) {
    super(props);
    // load pages from somewhere
    this.pages = [
      { type: "text", title: "Text", id: "1" },
      { type: "selection", title: "Selection", id: "2" },
      { type: "number", title: "Number", id: "42" },
      // ...
    ];
    this.state = {
      snippet_list: [],
    };
  }

  componentDidMount() {
    apiCall(
      METHOD.QUERY,
      refernceSnippetList(getUserID(), 0, 100, 0),
      (data) => this.handleSnippetResponse(data),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  handleSnippetResponse = (data) => {
    this.setState((prevState) => {
      return {
        snippet_list: [...prevState.snippet_list, ...data.snippet_list],
      };
    });
  };

  onPageChange = (event) => {
    const index = event.target.value;
    let item = this.state.snippet_list[index];
    const master_id = new Date().getTime() + Math.random();
    item = { ...item, master_id: master_id };
    this.props.setEntity(item);
  };

  render() {
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <select className="toolbar__input " onChange={this.onPageChange}>
              <option className="white-text-color">
                {t("GeneralMessages.snippetSelectReference")}
              </option>

              {this.state.snippet_list.map(
                ({ snippet_type_name, name, id }, index) => (
                  <option
                    className="reference-text-color"
                    key={index}
                    value={index}
                  >
                    {name && name}
                  </option>
                )
              )}
            </select>
          );
        }}
      </Translation>
    );
  }
}

const entityInputs = {
  LINK: LinkInput,
  INTERNAL_PAGE_LINK: ReferenceInput,
};

const myDecorator = new DraftJS.CompositeDecorator([
  {
    strategy: createTypeStrategy("INTERNAL_PAGE_LINK"),
    component: SnippetReferenceComponent,
  },
]);

const styleMap = {
  INTERNAL_PAGE_LINK: {
    textDecoration: "line-through",
  },
};

const editor_actions = [
  { type: "inline", label: "B", style: "BOLD", icon: icons.BoldIcon },
  { type: "inline", label: "I", style: "ITALIC", icon: icons.ItalicIcon },
  // these actions correspond with the entityInputs above
  {
    type: "entity",
    label: "Page Link",
    style: "link",
    entity: "INTERNAL_PAGE_LINK",
    icon: icons.LinkIcon,
  },

  // { type: "separator" },
  // {
  //   type: "block",
  //   label: "UL",
  //   style: "unordered-list-item",
  //   icon: icons.ULIcon,
  // },
  // {
  //   type: "block",
  //   label: "OL",
  //   style: "ordered-list-item",
  //   icon: icons.OLIcon,
  // },
  // { type: "block", label: "H2", style: "header-two", icon: icons.H2Icon },
  // {
  //   type: "block",
  //   label: "QT",
  //   style: "blockquote",
  //   icon: icons.BlockQuoteIcon,
  // },
];

/*
 * Here we define the output for the server
 * */
const entityInputStyles = {
  LINK: (data) => {
    return {
      element: "a",
      attributes: {
        href: data.url,
      },
    };
  },
  INTERNAL_PAGE_LINK: (data) => {
    return {
      element: "reference",
      attributes: {
        id: data.id,
        placeholder: data.name,
        type: data.snippet_type_identifier,
      },
    };
  },
};


const draftToHtmlOptions = {
  blockRenderers: {},
  entityStyleFn(entity) {
    const entityType = entity.getType();
    const data = entity.getData();
    if (
      entityType in entityInputStyles &&
      typeof entityInputStyles[entityType] === "function"
    ) {
      return entityInputStyles[entityType](data);
    }
    return {};
  },
};

class AddAdminSnippet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snippet_type_Image: "EB",
      snippet_type_name: "Einberufung",
      snippet_type_identifier: "text",
      snippet_type_id: 0,
      snippet_type_list: [],
      reportDialogueStatus: false,
      snippetTitleName: "",

      //parameter of diffeernt snippet
      placeHolder: "",
      hide_on_zero: false,
      allow_zero: false,
      allow_custom_option: false,
      is_multiselect: false,
      concatinate: false,
      concatinate_value: "",
      optionsValue: "",
      use_date: false,
      use_time: false,
      default_date: new Date(),
      min_value: 0,
      max_value: 0,
      defualt_number: 0,
      option_list: [""],
      isEditorFocus: false,
      conditional_editor_content: [
        EditorState.createWithContent(stateFromHTML(""), myDecorator),
      ],
      conditional_editor_raw_value: [],

      dialogueStatus: false,
      isLoading: false,
      createSnippetLoader: false,

      /**
       * Editor State
       */
      content: "",
      editorState: editorStateFromRaw(null),
    };
    this.child = React.createRef();
  }

  componentDidMount() {
    //this.editor.focus();

    var megadraft = document.querySelector("#megadraft-editor");
    megadraft.onclick = function () {
      this.focus();
    };

    this.setState({
      editorState: EditorState.createWithContent(
        stateFromHTML(this.state.content),
        myDecorator
      ),
    });
    apiCall(
      METHOD.QUERY,
      getSnippetType(),
      (data) => {
        let brandImageDefault, brandNameDefault, brandID, brandIdentifier;
        if (data.snippet_type_list && data.snippet_type_list.langth !== 0) {
          brandNameDefault = data.snippet_type_list[0].name;
          brandImageDefault = data.snippet_type_list[0].icon;
          brandIdentifier = data.snippet_type_list[0].identifier;
          brandID = data.snippet_type_list[0].id;
        }
        this.setState({
          snippet_type_list: data.snippet_type_list,
          snippet_type_Image: brandImageDefault,
          snippet_type_id: brandID,
          snippet_type_name: brandNameDefault,
          snippet_type_identifier: brandIdentifier,
        });
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleReportDialogue = () => {
    this.setState((prevState) => {
      return {
        reportDialogueStatus: !prevState.reportDialogueStatus,
      };
    });
  };

  handleBrand = (image, name) => {
    this.setState(() => {
      return {
        status: false,
        brandType: name,
        brandImage: image,
      };
    });
  };

  handleReportSelection = (item) => {
    const { name, icon, id, identifier } = item;
    this.setState({
      reportDialogueStatus: false,
      snippet_type_Image: icon,
      snippet_type_name: name,
      snippet_type_id: id,
      snippet_type_identifier: identifier,
    });
  };

  handlePlaceHolderValue = (e) => {
    this.setState({
      placeHolder: e.target.value,
    });
  };

  handleSelectionInputtextValue = (e) => {
    this.setState({
      concatinate_value: e.target.value,
    });
  };
  setOptionList = (option_list) => {
    this.setState({
      option_list,
    });
  };

  handleNumberInputtextValue = (e, type) => {
    if (type == "min_value") {
      this.setState({
        min_value: e.target.value,
      });
    } else if (type == "defaultValue") {
      this.setState({
        defualt_number: e.target.value,
      });
    } else {
      this.setState({
        max_value: e.target.value,
      });
    }
  };

  handleSelectionCheckBox = (e, type) => {
    if (type == "hide_on_zero") {
      this.setState({ hide_on_zero: e.target.checked });
    } else if (type == "allow_zero") {
      this.setState({ allow_zero: e.target.checked });
    } else if (type == "allow_custom_option") {
      this.setState({ allow_custom_option: e.target.checked });
    } else if (type == "is_multiselect") {
      this.setState({ is_multiselect: e.target.checked });
    } else if (type == "concatinate") {
      this.setState({ concatinate: e.target.checked });
    }
  };
  handleHideOnZeroCheckBox = (e) => {
    this.setState({ hide_on_zero: e.target.checked });
  };

  handleChannelTextChange = (e) => {
    this.setState({
      snippetTitleName: e.target.value,
    });
  };

  handleDefaultDateChange = (date) => {
    this.setState({
      default_date: date,
    });
  };
  handleDateCheckBox = (e, type) => {
    if (type == "use_date") {
      this.setState({ use_date: e.target.checked });
    } else if (type == "use_time") {
      this.setState({ use_time: e.target.checked });
    } else if (type == "hide_on_zero") {
      this.setState({ hide_on_zero: e.target.checked });
    }
  };

  handleNumberCheckBox = (e, type) => {
    if (type == "hide_on_zero") {
      this.setState({ hide_on_zero: e.target.checked });
    }
  };

  validateSnippet = () => {
    const {
      snippetTitleName,
      placeHolder,
      hide_on_zero,
      allow_zero,
      allow_custom_option,
      is_multiselect,
      concatinate,
      option_list,
      concatinate_value,
      snippet_type_identifier,
      use_date,
      use_time,
      default_date,
      min_value,
      max_value,
      defualt_number,
      conditional_editor_content,
    } = this.state;

    let editorValue = stateToHTML(
      this.state.editorState.getCurrentContent(),
      draftToHtmlOptions
    );

    editorValue = editorValue.replace(/&gt;/g, ">");
    editorValue = editorValue.replace(/&lt;/g, "<");
    editorValue = editorValue.replace(/&quot;/g, '"');
    editorValue = editorValue.replace(/&apos;/g, "'");
    editorValue = editorValue.replace(/&amp;/g, "&");
    editorValue = editorValue.replace("<p>", "");
    editorValue = editorValue.replace("</p>", "");

    if (!snippetTitleName) {
      toast.info(this.props.t("AdminSetteliteSideMenu.snippetNameValidation"));
    } else {
      let contentObj = {};

      if (snippet_type_identifier == "text") {
        contentObj["content"] = editorValue;
        contentObj["placeholder"] = placeHolder;
        contentObj["hide_on_zero"] = hide_on_zero;
      } else if (snippet_type_identifier == "selection") {
        let final_option_list = option_list.filter((item) => item);
        contentObj["content"] = editorValue;
        contentObj["hide_on_zero"] = hide_on_zero;
        contentObj["allow_zero"] = allow_zero;
        contentObj["allow_custom_option"] = allow_custom_option;
        contentObj["is_multiselect"] = is_multiselect;
        contentObj["concatinate"] = concatinate;
        contentObj["options"] = final_option_list;
        contentObj["concatinate_value"] = concatinate_value;
        contentObj["placeholder"] = placeHolder;
      } else if (snippet_type_identifier == "date_time") {
        contentObj["content"] = editorValue;
        contentObj["hide_on_zero"] = hide_on_zero;
        contentObj["set_date"] = use_date;
        contentObj["set_time"] = use_time;
        contentObj["default_date"] = default_date;
      } else if (snippet_type_identifier == "number") {
        contentObj["content"] = editorValue;
        contentObj["hide_on_zero"] = hide_on_zero;
        contentObj["min_value"] = parseInt(min_value ? min_value : 0);
        contentObj["max_value"] = parseInt(max_value ? max_value : 100);
        contentObj["default"] = parseInt(defualt_number ? defualt_number : 0);
      } else if (snippet_type_identifier == "condition") {
        let conditonal_object_value = {};

        for(let index = 0 ; index<option_list.length; index++){
          let item = option_list[index]
          let editor_content = stateToHTML(
            conditional_editor_content[index].getCurrentContent(),
            draftToHtmlOptions
          );
          editor_content = editor_content.replace(/&gt;/g, ">");
          editor_content = editor_content.replace(/&lt;/g, "<");
          editor_content = editor_content.replace(/&quot;/g, '"');
          editor_content = editor_content.replace(/&apos;/g, "'");
          editor_content = editor_content.replace(/&amp;/g, "&");
          editor_content = editor_content.replace("<p>", "");
          editor_content = editor_content.replace("</p>", "");
          conditonal_object_value[item] = editor_content;
        }
        let rawContent = [];


        for(let i = 0 ; i<conditional_editor_content.length; i++){
          let item = conditional_editor_content[i]
          const rawDraftContentState1 = JSON.stringify(
            convertToRaw(item.getCurrentContent())
          );
          rawContent.push(rawDraftContentState1);
        }
    
        contentObj["content"] = editorValue;
        contentObj["raw_value"] = rawContent;
        contentObj["option_list"] = option_list;
        contentObj["options"] = JSON.stringify(
          JSON.stringify(conditonal_object_value)
        );
        contentObj["hide_on_zero"] = hide_on_zero;
      }
      this.apiCallCreateSnippet(contentObj, editorValue);
    }
  };

  apiCallCreateSnippet = (contentObj, editorValue) => {
    const {
      snippet_type_id,
      snippetTitleName,
      snippet_type_identifier,
    } = this.state;
    let result;
    if (snippet_type_identifier == "base") {
      result = JSON.stringify(editorValue);
    } else {
      result = JSON.stringify(JSON.stringify(contentObj));
    }

    const rawDraftContentState = JSON.stringify(
      convertToRaw(this.state.editorState.getCurrentContent())
    );

    apiCall(
      METHOD.QUERY,
      adminCreateSnippet(
        getUserID(),
        snippet_type_id,
        snippetTitleName,
        result,
        JSON.stringify(JSON.stringify(rawDraftContentState))
      ),
      (data) => {
        this.handleCreateResponse(data);
      },
      (isLoading) => {
        this.setState({ createSnippetLoader: isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleCreateResponse = (data) => {
    toast.success(this.props.t(data.message));
    this.props.changeRouteStatus(0, {});
  };

  focus = () => {
    this.editor.focus();
  };

  onChange = (editorState) => {
    this.setState({ editorState });
  };

  handleOptionText = (i) => (e) => {
    let option_list = [...this.state.option_list];
    option_list[i] = e.target.value;
    this.setState({
      option_list,
    });
  };
  handleOptionDelete = (i) => (e) => {
    e.preventDefault();
    let option_list = [
      ...this.state.option_list.slice(0, i),
      ...this.state.option_list.slice(i + 1),
    ];
    this.setState({
      option_list,
    });
  };
  addSelectionOption = (e) => {
    e.preventDefault();
    let option_list = this.state.option_list.concat([""]);
    this.setState({
      option_list,
    });
  };

  handleOnEditorStateChange = (i) => (editorState) => {
    let conditional_editor_content = [...this.state.conditional_editor_content];
    conditional_editor_content[i] = editorState;
    this.setState({
      conditional_editor_content,
    });
  };
  handleConditionalDynamicOption = (i) => (e) => {
    let option_list = [...this.state.option_list];

    option_list[i] = e.target.value;
    this.setState({
      option_list,
    });
  };
  handleDeleteConditionalOption = (i) => (e) => {
    e.preventDefault();
    let option_list = [
      ...this.state.option_list.slice(0, i),
      ...this.state.option_list.slice(i + 1),
    ];
    let conditional_editor_content = [
      ...this.state.conditional_editor_content.slice(0, i),
      ...this.state.conditional_editor_content.slice(i + 1),
    ];
    this.setState({
      option_list,
      conditional_editor_content,
    });
  };
  addConditionalDynamicOption = (e) => {
    e.preventDefault();
    let option_list = this.state.option_list.concat([""]);
    let conditional_editor_content = this.state.conditional_editor_content.concat(
      [EditorState.createWithContent(stateFromHTML(""), myDecorator)]
    );
    this.setState(
      {
        option_list,
        conditional_editor_content,
      },
      () => {
        this.clickChild(this.state.option_list.length - 1);
      }
    );
  };

  render() {
    const {
      isLoading,
      snippet_type_Image,
      snippet_type_name,
      snippetTitleName,
      createSnippetLoader,
      snippet_type_identifier,
      hide_on_zero,
      allow_zero,
      allow_custom_option,
      is_multiselect,
      concatinate,
      placeHolder,
      use_date,
      use_time,
      default_date,
      min_value,
      max_value,
      concatinate_value,
      defualt_number,
      option_list,
      conditional_editor_content,
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-green sab-header1 sticky-top">
                <div className="container">
                  <div className="py-2 d-flex align-items-center">
                    <span>
                      <i
                        className="zmdi zmdi-close zmdi-hc-fw font-30 text-light pointer"
                        onClick={() => this.props.changeRouteStatus(0, {})}
                      ></i>
                    </span>

                    {createSnippetLoader ? (
                      <div className="btn btn-light font-13 ml-auto pointer">
                        {t("AdminSetteliteSideMenu.btnSave")}
                        <div className="spinner-border spinner-border-sm ml-1 text-blue">
                          <span className="sr-only">
                            {t("GeneralMessages.loading")}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="btn btn-light font-13 ml-auto pointer"
                        onClick={this.validateSnippet}
                      >
                        {t("AdminSetteliteSideMenu.btnSave")}
                      </div>
                    )}
                  </div>
                  <div className="text-center mt-4 text-light">
                    <h2 className="m-0 text-light">
                      {t("AdminSetteliteSideMenu.createNewSnippet")}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="middle-section sab-middle-section1">
                {isLoading && <LoadingPage />}

                <div className="container my-4 z-index-99">
                  <div className="form-group mb-5">
                    <div className="form-group max-170 m-auto text-center">
                      <a
                        href="javascript;:"
                        className="clearfix text-dark"
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleReportDialogue();
                        }}
                      >
                        <label className="font-14">
                          {t("AddNewChannel.type")}
                        </label>
                        <div className="icon-drop-bg pointer">
                          <div className="rounded-circle">
                            <img
                              src={`data:image/png;base64,${
                                snippet_type_Image && snippet_type_Image
                              }`}
                              alt="ico"
                            />
                          </div>

                          <span className="font-12">
                            {snippet_type_name && snippet_type_name}
                          </span>
                        </div>
                      </a>
                    </div>

                    <SnippetTypeList
                      closeBrandDialogue={this.handleReportDialogue}
                      handleBrandSelection={this.handleReportSelection}
                      status={this.state.reportDialogueStatus}
                      BrandList={this.state.snippet_type_list}
                    />

                    <div className="form-group">
                      <label className="font-14">
                        {t("AddNewChannel.title")}
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        onChange={this.handleChannelTextChange}
                        name="title"
                        text={snippetTitleName}
                      />
                    </div>
                    <div className="icon-box-style1 box-left-line-yellow mb-4 pointer">
                      <div className="pl-3 flex-fill">
                        <div className="w-100">
                          <MegadraftEditor
                            editorState={this.state.editorState}
                            customStyleMap={styleMap}
                            contentEditable={false}
                            onChange={this.onChange}
                            actions={editor_actions}
                            placeholder={t("AddNewChannel.placeholder")}
                            entityInputs={entityInputs}
                            ref={(element) => {
                              this.editor = element;
                            }}
                            className="Responsive-selection-none"
                            onMouseDown={() => {
                              this.setState({ isEditorFocus: true });
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {snippet_type_identifier == "text" && (
                      <TextType
                        placeHolder={placeHolder}
                        hide_on_zero={hide_on_zero}
                        handleHideOnZeroCheckBox={this.handleHideOnZeroCheckBox}
                        handlePlaceHolderValue={this.handlePlaceHolderValue}
                      />
                    )}
                    {snippet_type_identifier == "selection" && (
                      <SelectionType
                        placeHolder={placeHolder}
                        hide_on_zero={hide_on_zero}
                        allow_zero={allow_zero}
                        option_list={option_list}
                        handleOptionText={this.handleOptionText}
                        handleOptionDelete={this.handleOptionDelete}
                        addSelectionOption={this.addSelectionOption}
                        allow_custom_option={allow_custom_option}
                        is_multiselect={is_multiselect}
                        concatinate={concatinate}
                        concatinate_value={concatinate_value}
                        handleSelectionCheckBox={this.handleSelectionCheckBox}
                        setOptionList={this.setOptionList}
                        handleSelectionInputtextValue={
                          this.handleSelectionInputtextValue
                        }
                        handlePlaceHolderValue={this.handlePlaceHolderValue}
                      />
                    )}
                    {snippet_type_identifier == "condition" && (
                      <ConditionalDialogue
                        placeHolder={placeHolder}
                        hide_on_zero={hide_on_zero}
                        option_list={option_list}
                        conditional_editor_content={conditional_editor_content}
                        handleConditionalDynamicOption={
                          this.handleConditionalDynamicOption
                        }
                        handleDeleteConditionalOption={
                          this.handleDeleteConditionalOption
                        }
                        addConditionalDynamicOption={
                          this.addConditionalDynamicOption
                        }
                        setClick={(click) => (this.clickChild = click)}
                        //ref={this.child}
                        handleOnEditorStateChange={
                          this.handleOnEditorStateChange
                        }
                        handleSelectionCheckBox={this.handleSelectionCheckBox}
                      />
                    )}
                    {snippet_type_identifier == "date_time" && (
                      <DateTimeType
                        handleDefaultDateChange={this.handleDefaultDateChange}
                        handleDateCheckBox={this.handleDateCheckBox}
                        use_date={use_date}
                        use_time={use_time}
                        hide_on_zero={hide_on_zero}
                        default_date={default_date}
                      />
                    )}

                    {snippet_type_identifier == "number" && (
                      <NumberType
                        handleNumberInputtextValue={
                          this.handleNumberInputtextValue
                        }
                        handleNumberCheckBox={this.handleNumberCheckBox}
                        min_value={min_value}
                        max_value={max_value}
                        defualt_number={defualt_number}
                        hide_on_zero={hide_on_zero}
                      />
                    )}
                  </div>
                </div>
              </div>
              <Footer type="2" />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.channelListStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.channelListDispatchToProps,
  ])
)(withRouter(withTranslation()(AddAdminSnippet)));
