/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getreporteventsnippetdata,
  updatereporteventsnippet,
} from "../../../../constants/apiParams";
import apiCall, { METHOD } from "../../../../constants/baseUrl";
import { getUserID } from "../../../../util/utils";
import Footer from "../../../Footer/FooterMobile";
import LoadingPage from "../../../CommanComponents/LoadingPage";
import SnippetAsyncSelector from "./SnippetAsyncSelector";
import CommonLoader from "../../../CommanComponents/CommonLoader";

class EditDefaultSnippet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      event_type_name: "",
      event_type_id: "",
      report_type_name: "",
      report_type_id: "",
      snippet_list: [],
      snippet_id: 0,
      data: {},
      editSnippetLoader: false,
    };
  }

  handleTextChange = (e, type) => {
    if (type == "name") {
      this.setState({
        name: e.target.value,
      });
    } else if (type == "description") {
      this.setState({
        description: e.target.value,
      });
    } else {
      this.setState({
        identifier: e.target.value,
      });
    }
  };
  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  getSnippetID = (value) => {
    this.setState({
      snippet_id: value.value,
    });
  };

  componentDidMount() {
    apiCall(
      METHOD.QUERY,
      getreporteventsnippetdata(getUserID(), this.props.item.id),
      (data) => this.getEventTypeDetail(data),
      (isLoading) => {
        this.setState({ isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  getEventTypeDetail = (data) => {
    this.setState({
      data,
      snippet_id: "93",
      snippet_name: "asdas",
      name: data.name,
    });
  };

  submitData = () => {
    const { snippet_id, name } = this.state;
    if (snippet_id == 0) {
      toast.info(this.props.t("manageDefaultSnippet.snippetValidation"));
    } else if (!name) {
      toast.info(this.props.t("manageDefaultSnippet.nameValidation"));
    } else {
      apiCall(
        METHOD.QUERY,
        updatereporteventsnippet(
          getUserID(),
          this.props.item.id,
          snippet_id,
          name
        ),
        (data) => {
          toast.success(this.props.t(data.message));
          this.props.handleEditModal(true, {});
        },
        (isLoading) => {
          this.setState({ loading: isLoading });
        },
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };
  render() {
    const { editSnippetLoader, isLoading, data, name, loading } = this.state;

    const {
      report_type_icon,
      report_type_name,
      event_type_name,
      event_type_icon,
    } = data && data;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="d-block">
              <div
                className="modal fade show"
                id="NewEvent"
                tabindex="-1"
                aria-labelledby="NewEventLabel"
                aria-modal="true"
                style={{ display: "block" }}
              >
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header border-0 d-block pt-4 pb-0">
                      <div className="d-flex align-items-center justify-content-between mb-4">
                        <button
                          type="button"
                          onClick={() => {
                            this.props.handleEditModal(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                        </button>

                        {loading ? (
                          <div className="btn btn-primary-theme pointer">
                            {t("AdminAddUser.save")}
                            <div className="spinner-border spinner-border-sm ml-1 text-blue">
                              <span className="sr-only">
                                {t("GeneralMessages.loading")}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="btn btn-primary-theme pointer"
                            onClick={() => {
                              this.submitData();
                            }}
                          >
                            {t("AdminAddUser.save")}
                          </div>
                        )}
                      </div>
                      <h5 className="modal-title">
                        {t("manageDefaultSnippet.editSnippetDefault")}
                      </h5>
                    </div>
                    <div className="modal-body">
                      <div className="middle-section sab-middle-section1">
                        {isLoading && <CommonLoader />}

                        <div className="container my-4 z-index-99">
                          <div className="form-group mb-5">
                            <div className="form-group">
                              <label className="font-14">
                                {t("manageDefaultSnippet.nameTitle")}
                              </label>

                              <input
                                type="text"
                                className="form-control"
                                onChange={(e) =>
                                  this.handleTextChange(e, "name")
                                }
                                name="name"
                                value={name && name}
                              />
                            </div>

                            <div className="form-group max-170 mr-2">
                              <a
                                href="javascript;:"
                                className="clearfix text-dark"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.handleReportDialogue();
                                }}
                              >
                                <label className="font-14">
                                  {t("manageDefaultSnippet.evnetTitle")}
                                </label>
                                <div className="icon-drop-bg pointer">
                                  <div className="rounded-circle">
                                    <img
                                      src={`data:image/png;base64,${
                                        event_type_icon && event_type_icon
                                      }`}
                                      alt="ico"
                                      style={{ width: "32px", height: "32px" }}
                                    />
                                  </div>

                                  <span className="font-12">
                                    {event_type_name && event_type_name}
                                  </span>
                                </div>
                              </a>
                            </div>

                            <div className="form-group max-170 mr-2">
                              <a
                                href="javascript;:"
                                className="clearfix text-dark"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.handleReportDialogue();
                                }}
                              >
                                <label className="font-14">
                                  {t("manageDefaultSnippet.reportTitle")}
                                </label>
                                <div className="icon-drop-bg pointer">
                                  <div className="rounded-circle">
                                    <img
                                      src={`data:image/png;base64,${
                                        report_type_icon && report_type_icon
                                      }`}
                                      alt="ico"
                                      style={{ width: "32px", height: "32px" }}
                                    />
                                  </div>

                                  <span className="font-12">
                                    {report_type_name && report_type_name}
                                  </span>
                                </div>
                              </a>
                            </div>

                            <div className="form-group">
                              <label className="font-14">
                                {t("manageDefaultSnippet.snippetTitle")}
                              </label>

                              <SnippetAsyncSelector
                                getSnippetID={this.getSnippetID}
                                snippet_id={this.props.item.id}
                                snippet_name={this.props.item.snippet_name}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop fade show"></div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(EditDefaultSnippet));
