/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";

class SubbGroupItemGridView extends Component {
  handleGroupDetail = (group_id, person) => {
    this.props.clearStateGroupDetail({});
    this.props.clearStateGroupPersonDetail({});
    if (person == 0) {
      this.props.history.push("/group-detail/" + group_id);
    } else {
      this.props.history.push("/group-personlist/" + group_id);
    }
  };
 
  render() {
    const { id, name, group_type_icon, person, description } = this.props.data;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            // <div
            //   className="icon-box-style2 mb-2 pointer"
            //   onClick={() => this.handleGroupDetail(id, person)}
            // >
            //   <div className="icon-box blue-bg d-flex">
            //     <div className="d-flex align-items-center justify-content-center w-100">
            //       <div className="rounded-50 bg-light">
            //         <img
            //           src={`data:image/png;base64,${
            //             group_type_icon && group_type_icon
            //           }`}
            //           style={{ width: "32px" }}
            //           alt="icon"
            //         />
            //       </div>
            //     </div>
            //   </div>
            //   <div className="ml-3 my-3 d-inline-block">
            //     <h5 className="m-0 font-18">{name && name}</h5>
            //     {person == 0 ? (
            //       <p className="text-muted font-12 mb-0">
            //         {description && description}
            //       </p>
            //     ) : (
            //       <p className="text-muted font-12 mb-0">
            //         {person && person} {t("GroupListScreen.contactPerson")}
            //       </p>
            //     )}
            //   </div>
            // </div>

                                <div className="col-6 mb-2 pointer" onClick={() => this.handleGroupDetail(id, person)}>
                                  <a
                                    href="javascript;:"
                                    onClick={(e)=>{e.preventDefault()}}
                                    className="card shadow-sm p-3 px-2 bg-blue-bottom justify-content-between h-100"
                                  >
                                    <div className="d-flex justify-content-between">
                                      <div className="">
                                        <h5 className="m-0 font-18 word-break">
                                          {name && name}
                                        </h5>
                                        {person == 0 ? (
                                        <p className="text-muted font-12 mb-0">
                                          { description && description!='undefined' && description}
                                        </p>
                                      ) : (
                                        <p className="text-muted font-12 mb-0">
                                          {person && person} {" "}
                                          {person && person == 1
                          ? t("GroupListScreen.singularPerson")
                          : t("GroupListScreen.pluralPerson")}
                                          {}
                                        </p>
                                      )}
                                      </div>
                                       </div>
                                  </a>
                                </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.groupDetailStateToProps,
        stateToProps.groupListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupDetailDispatchToProps,
    actions.groupDetailPersonListDispatchToProps,
  ])
)(withRouter(withTranslation()(SubbGroupItemGridView)));
