/* eslint eqeqeq: 0 */
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import gql from "graphql-tag";
import React, { Component } from "react";
import { ApolloProvider, Mutation } from "react-apollo";
import { SketchPicker } from "react-color";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import reactCSS from "reactcss";
import { getchanneltypedata } from "../../../../constants/apiParams";
import apiCall, { METHOD, uploadLink } from "../../../../constants/baseUrl";
import { getUserID } from "../../../../util/utils";
import Footer from "../../../Footer/FooterMobile";

const cache = new InMemoryCache({
  dataIdFromObject: (o) => o.id || null,
});

const client_mutation = new ApolloClient({
  cache,
  link: uploadLink,
});

const Addchanneltype = gql`
  mutation Addchanneltype($profile: AddchanneltypeInput!) {
    Addchanneltype(input: $profile) {
      success
      message
    }
  }
`;

class EditChannelType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      icon: undefined,
      whiteIcon: undefined,
      base_64_icon: undefined,
      base_64_whiteIcon: undefined,
      icon_file: undefined,
      whiteIcon_file: undefined,
      reportDialogueStatus: false,
      name: "",
      description: "",
      color: "#fff",
      pickerVisible: false,
      allow_more_message: false,
    };
  }
  onColorPickerInfoChange = (color) => {
    this.setState({
      color: color.hex,
    });
  };

  handleTextChange = (e, type) => {
    if (type == "name") {
      this.setState({
        name: e.target.value,
      });
    } else if (type == "description") {
      this.setState({
        description: e.target.value,
      });
    } else if (type == "color") {
      this.setState({
        color: e.target.value,
      });
    }
  };
  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleReportDialogue = () => {
    this.setState((prevState) => {
      return {
        reportDialogueStatus: !prevState.reportDialogueStatus,
      };
    });
  };

  componentDidMount() {
    apiCall(
      METHOD.QUERY,
      getchanneltypedata(getUserID(), this.props.item.id),
      (data) => this.getDetail(data),
      (isLoading) => {
        this.setState({ isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  getDetail = (data) => {
    const {
      name,
      description,
      icon,
      secondary_icon,
      color,
      allow_more_message,
    } = data;
    this.setState({
      name,
      description,
      base_64_icon: icon,
      base_64_whiteIcon: secondary_icon,
      color,
      allow_more_message: allow_more_message == 1 ? true : false,
    });
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.setState({ color: color.hex });
  };

  getIconUrl = (file, uploadFile, type) => {
    let images_extensions = ["jpg", "jpeg", "png"];
    let image_extension = file.type.split("/")[1];
    const result = images_extensions.indexOf(image_extension);
    if (result == -1) {
      toast.info(`${image_extension} file is not supported`);
    } else {
      if (type == "icon") {
        this.setState({
          icon: URL.createObjectURL(file),
          icon_file: uploadFile,
        });
      } else {
        this.setState({
          whiteIcon: URL.createObjectURL(file),
          whiteIcon_file: uploadFile,
        });
      }
    }
  };
  render() {
    const {
      icon,
      whiteIcon,
      name,
      description,
      base_64_icon,
      base_64_whiteIcon,
      color,
      allow_more_message,
    } = this.state;

    const styles = reactCSS({
      default: {
        color: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: `${this.state.color}`,
        },
        swatch: {
          padding: "5px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <ApolloProvider client={client_mutation}>
              <Mutation mutation={Addchanneltype}>
                {(Addchanneltype, { data, error, loading }) => {
                  if (data) {
                    const { success } = data;
                    if (success && success == 0) {
                      toast.error(this.props.t(data.Addchanneltype.message));
                    } else {
                      toast.success(this.props.t(data.Addchanneltype.message));
                      this.props.handleEditModal(true, {});
                    }
                  }
                  if (error) {
                    toast.error(
                      i18n.getDataByLanguage("en").translation.GeneralMessages
                        .errorServerisNotResponding &&
                        i18n.getDataByLanguage("en").translation.GeneralMessages
                          .errorServerisNotResponding
                    );
                  }

                  return (
                    <div className="d-block">
                      <div
                        className="modal fade show"
                        id="NewEvent"
                        tabindex="-1"
                        aria-labelledby="NewEventLabel"
                        aria-modal="true"
                        style={{ display: "block" }}
                      >
                        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header border-0 d-block pt-4 pb-0">
                              <div className="d-flex align-items-center justify-content-between mb-4">
                                <button
                                  type="button"
                                  onClick={() => {
                                    this.props.handleEditModal(false, {});
                                  }}
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                </button>

                                {loading ? (
                                  <div className="btn btn-primary-theme pointer">
                                    {t("AdminAddUser.save")}
                                    <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                      <span className="sr-only">
                                        {t("GeneralMessages.loading")}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="btn btn-primary-theme pointer"
                                    onClick={() => {
                                      const {
                                        name,
                                        description,
                                        color,
                                        icon_file,
                                        whiteIcon_file,
                                        allow_more_message,
                                      } = this.state;
                                      if (!name) {
                                        toast.info(
                                          this.props.t(
                                            "AdminAddChannelType.nameValidation"
                                          )
                                        );
                                      } else if (!description) {
                                        toast.info(
                                          this.props.t(
                                            "AdminAddChannelType.descriptionValidation"
                                          )
                                        );
                                      } else if (!color) {
                                        toast.info(
                                          this.props.t(
                                            "AdminAddChannelType.colorValidation"
                                          )
                                        );
                                      } else {
                                        let obj = {
                                          variables: {
                                            profile: {
                                              user_id: getUserID(),
                                              id: this.props.item.id,
                                              name,
                                              description,
                                              color,
                                              icon: icon_file
                                                ? icon_file
                                                : null,
                                              secondary_icon: whiteIcon_file
                                                ? whiteIcon_file
                                                : null,
                                              allow_more_message: allow_more_message
                                                ? 1
                                                : 0,
                                            },
                                          },
                                        };
                                        Addchanneltype(obj);
                                      }
                                    }}
                                  >
                                    {t("AdminAddUser.save")}
                                  </div>
                                )}
                              </div>
                              <h5 className="modal-title">
                                {t("AdminAddChannelType.edit_title")}
                              </h5>
                            </div>
                            <div className="modal-body">
                              <div className="middle-section sab-middle-section1">
                                <div className="container my-4 z-index-99">
                                  <div className="form-group mb-5">
                                    <div className="form-group">
                                      <div className="container my-4 00z-index-99">
                                        <form>
                                          <div className="form-group">
                                            <label className="font-14">
                                              {t(
                                                "AdminAddChannelType.nameTitle"
                                              )}
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              onChange={(e) =>
                                                this.handleTextChange(e, "name")
                                              }
                                              name="name"
                                              value={name && name}
                                              placeholder={t(
                                                "AdminAddChannelType.namePlaceHolder"
                                              )}
                                            />
                                          </div>

                                          <div className="form-group">
                                            <label className="font-14">
                                              {t(
                                                "AdminAddChannelType.descriptionTitle"
                                              )}
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              onChange={(e) =>
                                                this.handleTextChange(
                                                  e,
                                                  "description"
                                                )
                                              }
                                              name="description"
                                              value={description && description}
                                              placeholder={t(
                                                "AdminAddChannelType.descriptionPlaceHolder"
                                              )}
                                            />
                                          </div>
                                          <div className="form-group">
                                            <input
                                              type="checkbox"
                                              id="allow_more_message"
                                              className="mr-2"
                                              checked={allow_more_message}
                                              onChange={(e) =>
                                                this.setState({
                                                  allow_more_message:
                                                    e.target.checked,
                                                })
                                              }
                                            />
                                            <label htmlFor="allow_more_message">
                                              {t(
                                                "AdminAddChannelType.allowMoreMessage"
                                              )}
                                            </label>
                                          </div>
                                          <div className="form-group">
                                            <div>
                                              <label className="font-14">
                                                {t(
                                                  "AdminAddChannelType.colorTitle"
                                                )}
                                              </label>
                                            </div>
                                            <div
                                              style={styles.swatch}
                                              onClick={this.handleClick}
                                            >
                                              <div style={styles.color} />
                                            </div>
                                            {this.state.displayColorPicker ? (
                                              <div style={styles.popover}>
                                                <div
                                                  style={styles.cover}
                                                  onClick={this.handleClose}
                                                />
                                                <SketchPicker
                                                  color={color}
                                                  onChange={this.handleChange}
                                                />
                                              </div>
                                            ) : null}
                                          </div>

                                          <div className="form-group">
                                            <label className="font-14">
                                              {t(
                                                "AdminAddChannelType.iconTitle"
                                              )}
                                            </label>
                                            <div
                                              className="fileinput text-center fileinput-new d-flex align-items-center"
                                              data-provides="fileinput"
                                            >
                                              <div className="btn-file">
                                                <div
                                                  className="thumbnail fileinput-new "
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    background: "#999",
                                                  }}
                                                >
                                                  {icon ? (
                                                    <img src={icon} alt="" />
                                                  ) : base_64_icon ? (
                                                    <img
                                                      src={`data:image/png;base64,${
                                                        base_64_icon &&
                                                        base_64_icon
                                                      }`}
                                                      style={{ width: "32px" }}
                                                      alt="icon"
                                                    />
                                                  ) : (
                                                    <img
                                                      src="/images/no-image.png"
                                                      alt="icon"
                                                    />
                                                  )}
                                                </div>

                                                <input
                                                  type={"file"}
                                                  file-model="myFile"
                                                  name="image"
                                                  accept=""
                                                  onChange={(event) => {
                                                    const file =
                                                      event.target.files[0];
                                                    this.getIconUrl(
                                                      event.currentTarget
                                                        .files[0],
                                                      file,
                                                      "icon"
                                                    );
                                                  }}
                                                />

                                                <div
                                                  className="fileinput-preview fileinput-exists thumbnail uploaded-user-image rounded-circle"
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    borderRadius: "100%",
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <label className="font-14">
                                              {t(
                                                "AdminAddChannelType.whiteIconTitle"
                                              )}
                                            </label>

                                            <div
                                              className="fileinput text-center fileinput-new d-flex align-items-center"
                                              data-provides="fileinput"
                                            >
                                              <div className="btn-file">
                                                <div
                                                  className="thumbnail fileinput-new "
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    background: "#999",
                                                  }}
                                                >
                                                  {whiteIcon ? (
                                                    <img
                                                      src={whiteIcon}
                                                      alt=""
                                                    />
                                                  ) : base_64_whiteIcon ? (
                                                    <img
                                                      src={`data:image/png;base64,${
                                                        base_64_whiteIcon &&
                                                        base_64_whiteIcon
                                                      }`}
                                                      style={{ width: "32px" }}
                                                      alt="icon"
                                                    />
                                                  ) : (
                                                    <img
                                                      src="/images/no-image.png"
                                                      alt="icon"
                                                    />
                                                  )}
                                                </div>

                                                <input
                                                  type={"file"}
                                                  file-model="myFile"
                                                  name="image"
                                                  accept=""
                                                  onChange={(event) => {
                                                    const file =
                                                      event.target.files[0];
                                                    this.getIconUrl(
                                                      event.currentTarget
                                                        .files[0],
                                                      file,
                                                      "white_icon"
                                                    );
                                                  }}
                                                />
                                                <div
                                                  className="fileinput-preview fileinput-exists thumbnail uploaded-user-image rounded-circle"
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    borderRadius: "100%",
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-backdrop fade show"></div>
                    </div>
                  );
                }}
              </Mutation>
            </ApolloProvider>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(EditChannelType));
