/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { toast } from "react-toastify";
import { MailDetailsQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
import CommonLoader from "../../CommanComponents/CommonLoader";
import { Translation, withTranslation } from "react-i18next";

class SubMailList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isLoading: false,
    };
  }

  loadMailingList = () => {
    apiCall(
      METHOD.QUERY,
      MailDetailsQuery(getUserID(), this.props.mail_id, ""),
      (data) => this.handleMailListResponse(data),
      (isLoading) => this.setState({ isLoading }),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleMailListResponse = (data) => {
    this.setState((prevState) => {
      return {
        data,
      };
    });
  };

  componentDidMount() {
    this.loadMailingList();
  }

  movetoMainList = (mail_id) => {
    this.props.changeMailRouteStatus(0, 0);
  };

  handleCheckBox = (e, checkMailID,child_element) => {
    let personCountForMail = this.props.personCountForMail
    console.log("personCountForMail",personCountForMail);
    console.log("child_element",child_element);
    if (e.target.checked) {
      this.props.addSelectedMailId(checkMailID)
       personCountForMail=personCountForMail+child_element
      this.props.updatePersonCountForMail(personCountForMail)

    } else {
      this.props.removeSelectedMailId(checkMailID);
      personCountForMail=personCountForMail-child_element
      this.props.updatePersonCountForMail(personCountForMail)
    }
  }
  
  loader = (<CommonLoader key={0} />);
  render() {
    const { child_list, name, id } = this.state.data;
    const { isLoading } = this.state;
    const {searchArray} = this.props

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="container my-2">
              {isLoading && this.loader}
              <div className="d-flex align-items-center justify-content-between ">
                <div className="clearfix mb-2">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb m-0">
                      <li
                        className="breadcrumb-item"
                        onClick={() => {
                          this.props.changeMailRouteStatus(0, 0);
                        }}
                      >
                        {t("mailDetail.home")}
                      </li>

                      <li
                        className="breadcrumb-item pointer"
                        aria-current="page"
                      >
                        {name}
                      </li>
                    </ol>
                  </nav>
                </div>
                {/* <ul className="list-inline grid-view-list m-0">
                  <li className="list-inline-item mr-3">
                    <a
                      className="pointer"
                      href="javascript;:"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.changeRouteStatus(5);
                      }}
                    >
                      <i className="fa fa-filter font-30 text-dark"></i>
                    </a>
                  </li>
                </ul> */}
              </div>


              { searchArray.length!=0 && (<div className="form-row mt-2">
          {searchArray.map((item, index) => {
              const { name, id, person } = item;
              return (
                <div className="col-6 mb-2 pointer">
                <a
                  href="#"
                  className="card shadow-sm p-3 px-2 bg-yellow-bottom justify-content-between  h-100"
                >
                  <div className="d-flex justify-content-between">
                    <div
                      className="pointer"
                      onClick={(e) => {
                        e.preventDefault();
                       
                        this.props.changeStatusForPersonList(2, id);
                      }}
                    >
                      <h5 className="m-0 font-18 word-break">{name && name}</h5>
                      <p className="text-muted font-12 mb-0">
                        {person && person} {t("mailDetail.receiver")}
                      </p>
                    </div>
                    <div className="align-self-end">
                      <label className="checkbox-container p-0 m-0">
                        <input
                          type="checkbox"
                          onChange={(e) => this.handleCheckBox(e, id,person)}
                          checked={this.props.checkedList.includes(id)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </a>
              </div>
          );
            })}
             </div>)} 

             {searchArray.length==0 && (
              <div className="form-row mt-2">
                {child_list &&
                  child_list.map((item, index) => {
                    const { name, id, person } = item;
                    return (
                      <div className="col-6 mb-2 pointer">
                        <a
                          href="#"
                          className="card shadow-sm p-3 px-2 bg-yellow-bottom justify-content-between  h-100"
                        >
                          <div className="d-flex justify-content-between">
                            <div
                              className="pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.changeStatusForPersonList(2, id);
                              }}
                            >
                              <h5 className="m-0 font-18 word-break">{name && name}</h5>
                              <p className="text-muted font-12 mb-0">
                                {person && person} {t("mailDetail.receiver")}
                              </p>
                            </div>
                            <div className="align-self-end">
                              <label className="checkbox-container p-0 m-0">
                                <input
                                  type="checkbox"
                                  onChange={(e) => this.handleCheckBox(e, id,person)}
                                  checked={this.props.checkedList.includes(id)}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
              </div>
           )}
         
         
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(SubMailList);
