import React, { Component } from "react";
import MediaQuery from "react-responsive";
import ManageListTypeMobile from "./ManageListTypeMobile/Index";
import ManageListTypeDesktop from "./ManageListTypeDesktop/ListType";
import { withRouter } from "react-router";
import { checkAdminPermission } from "../../../util/utils";

class ManageListTypeResponsive extends Component {
  componentDidMount() {
    if (!checkAdminPermission()) {
      this.props.history.push("/");
    }
  }
  render() {
    return (
      <React.Fragment>
        <MediaQuery query="(min-device-width: 1224px)">
          <ManageListTypeDesktop />
        </MediaQuery>
        <MediaQuery query="(max-device-width: 1224px)">
          <ManageListTypeMobile />
        </MediaQuery>
      </React.Fragment>
    );
  }
}
export default withRouter(ManageListTypeResponsive);
