import React from "react";
import MediaQuery from "react-responsive";
import GroupDetailPersonListMobile from "./GroupDetailPersonListMobile/Index";
import GroupDetailPersonListDesktop from "./GroupDetailPersonListDesktop/Index";

export default function GroupDetailPerosnListResponsive() {
  return (
    <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <GroupDetailPersonListDesktop />
        
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <GroupDetailPersonListMobile />
      </MediaQuery>
    </React.Fragment>
  );
}
