import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import * as actions from "../../constants/mapDispatchToProps";
import { connect } from "react-redux";
import * as stateToProps from "../../constants/mapStateToProps";
class FooterMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      img1: "/images/menu-icon1-selected.png",
      img2: "/images/menu-icon2.png",
      img3: "/images/menu-icon3.png",
      img4: "/images/menu-icon4.png",
      type: 1
    };
  }
  imagePath = route => {
    let requestedPath = this.props.history.location.pathname;

    if (requestedPath === route && route === "/home") {
      this.setState(() => {
        return {
          img1: "/images/menu-icon1-selected.png",
          img2: "/images/menu-icon2.png",
          img3: "/images/menu-icon3.png",
          img4: "/images/menu-icon4.png",
          type: 1
        };
      });
    } else if (requestedPath === route && route === "/ereignisse") {
      this.setState(() => {
        return {
          img1: "/images/menu-icon1.png",
          img2: "/images/menu-icon2-selected.png",
          img3: "/images/menu-icon3.png",
          img4: "/images/menu-icon4.png",
          type: 2
        };
      });
    } else if (requestedPath === route && route === "/ansprechpartner") {
      this.setState(() => {
        return {
          img1: "/images/menu-icon1.png",
          img2: "/images/menu-icon2",
          img3: "/images/menu-icon3-selected.png",
          img4: "/images/menu-icon4.png",
          type: 3
        };
      });
    } else if (
      requestedPath === route &&
      route === "/verteiler-einstiegsseite"
    ) {
      this.setState(() => {
        return {
          img1: "/images/menu-icon1.png",
          img2: "/images/menu-icon2",
          img3: "/images/menu-icon3.png",
          img4: "/images/menu-icon4-selected.png",
          type: 4
        };
      });
    }
  };
  render() {
    let type = this.props.type;
    let img1 = "/images/menu-icon1-selected.png";
    let img2 = "/images/menu-icon2.png";
    let img3 = "/images/menu-icon3.png";
    let img4 = "/images/menu-icon4.png";
    if (type === "1") {
      img1 = "/images/menu-icon1-selected.png";
      img2 = "/images/menu-icon2.png";
      img3 = "/images/menu-icon3.png";
      img4 = "/images/menu-icon4.png";
    } else if (type === "2") {
      img1 = "/images/menu-icon1.png";
      img2 = "/images/menu-icon2-selected.png";
      img3 = "/images/menu-icon3.png";
      img4 = "/images/menu-icon4.png";
    } else if (type === "3") {
      img1 = "/images/menu-icon1.png";
      img2 = "/images/menu-icon2.png";
      img3 = "/images/menu-icon3-selected.png";
      img4 = "/images/menu-icon4.png";
    } else if (type === "4") {
      img1 = "/images/menu-icon1.png";
      img2 = "/images/menu-icon2.png";
      img3 = "/images/menu-icon3.png";
      img4 = "/images/menu-icon4-selected.png";
    } else {
      type = "0";
    }
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <footer className="fixed-bottom footer-menu">
              <div className="container">
                <div className="form-row f-menu">
                  <div
                    className={
                      type === "1"
                        ? "col-3 text-center menu-start"
                        : "col-3 text-center"
                    }
                  >
                    <Link to="/home" className={type === "1" ? "active" : ""}>
                      <img
                        src={img1}
                        alt="menu-icon1"
                        className="pointer menu-img"
                        style={{
                          width: "22px",
                          height: "22px",
                          marginBottom: "5px",
                        }}
                      />
                      <span>{t("FooterScreen.start")}</span>
                    </Link>
                  </div>
                  <div
                    className={
                      type === "2"
                        ? "col-3 text-center menu-meldungen"
                        : "col-3 text-center"
                    }
                  >
                    <Link
                      to="/event-list"
                      className={type === "2" ? "active" : ""}
                    >
                      <img
                        src={img2}
                        alt="menu-icon2"
                        className="pointer menu-img"
                        style={{
                          width: "22px",
                          height: "22px",
                          marginBottom: "5px",
                        }}
                      />
                      <span>{t("FooterScreen.event")}</span>
                    </Link>
                  </div>
                  <div
                    className={
                      type === "3"
                        ? "col-3 text-center menu-kontakte"
                        : "col-3 text-center"
                    }
                  >
                    <a
                      onClick={(e)=>{e.preventDefault()
                        this.props.clearGroupState();
                      this.props.history.push("/group-list");
                      }}
                      className={type === "3" ? "active" : ""}
                    >
                      <img
                        src={img3}
                        alt="menu-icon3"
                        className="pointer menu-img"
                        style={{
                          width: "22px",
                          height: "22px",
                          marginBottom: "5px",
                        }}
                      />

                      <span>{t("FooterScreen.contactPerson")}</span>
                    </a>
                  </div>
                  <div
                    className={
                      type === "4"
                        ? "col-3 text-center menu-verteiler"
                        : "col-3 text-center"
                    }
                  >
                    <Link
                      to="/mail-list"
                      className={type === "4" ? "active" : ""}
                    >
                      <img
                        src={img4}
                        alt="menu-icon4"
                        className="pointer menu-img"
                        style={{
                          width: "22px",
                          height: "22px",
                          marginBottom: "5px",
                        }}
                      />
                      <span>{t("FooterScreen.distributor")}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </footer>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupListDispatchToProps
  ])
)(withRouter(withTranslation()(FooterMobile)));