import React from "react";
import MediaQuery from "react-responsive";
import RegisterUserMobile from "./RegisterUserMobile";
import RegisterUserDesktop from "./RegisterUserDesktop";

export default function RegisterUserResponsive() {
  return (
    <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <RegisterUserDesktop />
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <RegisterUserMobile />
      </MediaQuery>
    </React.Fragment>
  );
}
