/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AddGroupType from "./AddGroupType";
import EditGroupType from "./EditGroupType";
import GroupList from "./GroupList";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeStatus: 0,
      dataValue: {},
    };
  }

  changeStatus = (type) => {
    this.setState({
      routeStatus: type,
    });
  };

  changeStatusWithStatusValue = (type, dataValue) => {
    this.setState({
      routeStatus: type,
      dataValue,
    });
  };

  render() {
    const { routeStatus, dataValue } = this.state;
    return (
      <div className="wrapper position-relative">
        {routeStatus == 0 && (
          <GroupList
            changeStatusWithStatusValue={this.changeStatusWithStatusValue}
          />
        )}

        {routeStatus == 1 && (
          <AddGroupType changeRouteStatus={this.changeStatus} />
        )}

        {routeStatus == 2 && (
          <EditGroupType
            changeRouteStatus={this.changeStatus}
            item={dataValue}
          />
        )}
      </div>
    );
  }
}
export default withRouter(Index);
