/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import {
  deletePerson,
  personDetailQuery,
  removePersonFromGroup,
  removepersonfrommailinglist
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../../util/utils";
import CommonLoader from "../../CommanComponents/CommonLoader";
import FooterDesktop from "../../Footer/FooterDesktop";
import HeaderDesktop from "../../Header/HeaderDesktop";
import AddToGroupList from "./AddToGroupList";
import EditPerson from "./EditPerson";
import GroupList from "./GroupList";

class PersonDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      headerMenuStatus: false,
      person_id: 0,
      clickStatus: 0,
      group_id: 0,
      addGroupModal: false,
      changeGroupModal: false,
      editPersonModal: false,
      socialMediaType: 0,
      addressType: "business",
      groupIdHeader:0
    };

    this.myContact = React.createRef();
    this.myBusiness = React.createRef();
    this.myMailing = React.createRef();
    this.myComment = React.createRef();
    this.myOtherField = React.createRef();
  }

  handleBackStack = () => {
    let { from } = this.props.location.state || {
      from: { pathname: "/group-list" },
    };
    this.props.history.push(from);
  };

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    
    const group_person_id = this.props.match.params.group_person_id;
    const fields = group_person_id.split("-");
    const person_id = fields[1];
    const group_id = fields[0];

    this.setState({
      groupIdHeader:group_id
    })

    const read_person_permission = checkUserPermission("read_person");
    // if(!read_person_permission){
    this.getPersonDetail();
    // }else{
    //   toast.info(this.props.t("GeneralMessages.permissionError"));
    // }
  }

  getPersonDetail = () => {
    const group_person_id = this.props.match.params.group_person_id;
    const fields = group_person_id.split("-");
    const person_id = fields[1];
    const group_id = fields[0];

    this.setState({
      person_id: person_id,
    });

    apiCall(
      METHOD.QUERY,
      personDetailQuery(getUserID(), person_id, group_id),
      (data) => {
        this.props.getPersonDetail(data);

          // let divID = document.getElementById("divPersonDetail")
          // divID.innerHTML=`<div class="widget-test">Test</div> <script> document.getElementsByClassName("widget-test")[0].innerHTML = "Scripts are working"; </script>`
     
     
        },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  handleTabEvent = (type) => {
    this.setState({
      clickStatus: type,
    });
  };

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  deletelist = (id) => {
    const group_person_id = this.props.match.params.group_person_id;
    const fields = group_person_id.split("-");
    const person_id = fields[1];
    confirmAlert({
      title: this.props.t("personDetail.deleteDistributor"),
      message: this.props.t("personDetail.deleteDistributorMessage"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              removepersonfrommailinglist(getUserID(), person_id, id),
              (data) => {
                toast.success(this.props.t(data.message));
                this.props.deleteMailList(id);
              },
              (isLoading) => {
                this.setState({ deleteLoader: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
              );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };

  handleDeletePerson = (item) => {
    confirmAlert({
      title: this.props.t("personDetail.confirmTodelete"),
      message: this.props.t("personDetail.confirmTodeleteMessage"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            const group_person_id = this.props.match.params.group_person_id;
            const fields = group_person_id.split("-");
            const group_id = fields[0];
            const person_id = fields[1];

            apiCall(
              METHOD.QUERY,
              deletePerson(getUserID(), group_id, person_id),
              (data) => {
                toast.success(this.props.t(data.message));
                this.props.deletePersonList({ id: person_id });
                this.props.history.push("/group-personlist/" + group_id);
              },
              (isLoading) => this.handleLoader(isLoading),
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };

  handleAddToGroupModal = () => {
    this.setState(
      (prev) => {
        return {
          addGroupModal: !prev.addGroupModal,
        };
      },
      () => {
        if (this.state.addGroupModal) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  handleChangeToGroupModal = () => {
    this.setState(
      (prev) => {
        return {
          changeGroupModal: !prev.changeGroupModal,
        };
      },
      () => {
        if (this.state.changeGroupModal) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  handleEditPersonModal = (isfromSave) => {
    this.setState(
      (prev) => {
        return {
          editPersonModal: !prev.editPersonModal,
        };
      },
      () => {
        if (this.state.editPersonModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isfromSave) {
            this.getPersonDetail();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  removepersonFromMailinglist = () => {
    const { id } = this.props.groupPersonDetail && this.props.groupPersonDetail;
    const group_person_id = this.props.match.params.group_person_id;
    const fields = group_person_id.split("-");
    const old_group_id = fields[0];
    apiCall(
      METHOD.QUERY,
      removePersonFromGroup(getUserID(), id, old_group_id),
      (data) => {
        toast.success(this.props.t(data.message));
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  getWhatsappNumber = (otherFieldList) => {
    let whatsAppNumber = "";
    for (let index = 0; index < otherFieldList.length; index++) {
      const otherField = otherFieldList[index];
      if (otherField.field_name == "WhatsApp-Nummer") {
        whatsAppNumber = otherField.field_value;
        break;
      }
    }
    return whatsAppNumber;
  };

 

  loader = (<CommonLoader />);

  render() {
    const {
      full_name,
      title,
      email,
      phone,
      mobile,
      website,
      fax,
      business_adress,
      private_adress,
      mailing_list,
      comment,
      other_field_list,
      socialmedia_facebook_stream,
      socialmedia_twitter_stream,
      socialmedia_linkedin_stream,
      socialmedia_instagram_stream,
      breadcrumb,
      is_removable_from_group,
      is_movable_from_group,
      id,
    } = this.props.groupPersonDetail && this.props.groupPersonDetail;

    const {
      addGroupModal,
      changeGroupModal,
      editPersonModal,
      socialMediaType,
      addressType,
      groupIdHeader
    } = this.state;
    console.log("socialmedia_facebook_stream -- socialmedia_facebook_stream", this.props.groupPersonDetail.socialmedia_facebook_stream);
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="content-wrapper">
              <HeaderDesktop selectionType={3} />
              <div className="content">
                <div className="section section-padding">
                  <div className="container">
                    <div className="section-container">
                      <div className="d-flex t-n-25">
                        <nav className="mr-auto">
                         
                          <p className="font-12 m-0">
                            <React.Fragment>
                              <Link
                                to="/group-list"
                                className="text-muted"
                                data-tip={"Start"}
                              >
                                {t("GroupListScreen.start")}
                                <ReactTooltip
                                  place="bottom"
                                  type="info"
                                  effect="float"
                                />
                              </Link>
                              {" > "}
                            </React.Fragment>
                            {breadcrumb &&
                              breadcrumb.map((item, index) => {
                                const { id, name, person } = item;
                                if (index == 0 && breadcrumb.length != 1) {
                                  return (
                                    <React.Fragment key={index}>
                                      <Link
                                        to={
                                          person == 0
                                            ? `/group-detail/${id}`
                                            : `/group-personlist/${id}`
                                        }
                                        className="text-muted"
                                        data-tip={name}
                                      >
                                        <ReactTooltip
                                          place="bottom"
                                          type="info"
                                          effect="float"
                                        />
                                        {name}
                                      </Link>
                                      {" > "}
                                    </React.Fragment>
                                  );
                                } else if (
                                  index != 0 &&
                                  index != breadcrumb.length - 1
                                ) {
                                  return (
                                    <React.Fragment key={index}>
                                      <Link
                                        to={
                                          person == 0
                                            ? `/group-detail/${id}`
                                            : `/group-personlist/${id}`
                                        }
                                        className="text-muted"
                                        data-tip={item.name}
                                      >
                                        <ReactTooltip
                                          place="bottom"
                                          type="info"
                                          effect="float"
                                        />
                                        [...]
                                      </Link>
                                      {" > "}
                                    </React.Fragment>
                                  );
                                } else if (
                                  index == breadcrumb.length - 1 ||
                                  breadcrumb.length == 1
                                ) {
                                  return (
                                    <React.Fragment key={index}>
                                      {name}
                                    </React.Fragment>
                                  );
                                }
                              })}
                          </p>
                        </nav>
                      
                      
                        {groupIdHeader !=0 &&   <React.Fragment>            
                        <button
                          type="button"
                          className="btn btn-primary-theme group btn-sm mr-2"
                          data-toggle="modal"
                          data-target="#NewEvent"
                          onClick={() => {
                            this.handleEditPersonModal(false);
                          }}
                        >
                          {t("personDetail.editPerson")}
                        </button>

                        {is_removable_from_group == 1 && (
                          <button
                            type="button"
                            className="btn btn-primary-theme group btn-sm mr-2"
                            data-toggle="modal"
                            data-target="#NewEvent"
                            onClick={() => {
                              this.removepersonFromMailinglist();
                            }}
                          >
                            {t("personDetail.removeGroupContacts")}
                          </button>
                        )}
                        {is_movable_from_group == 1 && (
                          <button
                            type="button"
                            className="btn btn-primary-theme group btn-sm mr-2"
                            data-toggle="modal"
                            data-target="#NewEvent"
                            onClick={() => {
                              this.handleChangeToGroupModal();
                            }}
                          >
                            {t("personDetail.changeGroup")}
                          </button>
                        )}

                        <button
                          type="button"
                          className="btn btn-primary-theme group btn-sm mr-2"
                          data-toggle="modal"
                          data-target="#NewEvent"
                          onClick={() => {
                            this.handleAddToGroupModal();
                          }}
                        >
                          {t("personDetail.addToGroup")}
                        </button>

                        <button
                          type="button"
                          className="btn btn-primary-theme group btn-sm mr-2"
                          data-toggle="modal"
                          data-target="#NewEvent"
                          onClick={() => {
                            this.handleDeletePerson();
                          }}
                        >
                          {t("personDetail.deletePerson")}
                        </button>
                        </React.Fragment>}

                     

                    
                  
                      </div>
                      <div className="section-title text-center d-md-flex align-items-start m-0">
                        <div className="flex-fill ">
                          <h1 className="page-title h4">{full_name}</h1>
                        </div>
                       </div>
                      <div className="section-content">
                        {addGroupModal && (
                          <AddToGroupList
                            handleAddToGroupModal={(isfromSave) => {
                              this.handleAddToGroupModal(isfromSave);
                            }}
                          />
                        )}
                        {changeGroupModal && (
                          <GroupList
                            handleChangeToGroupModal={(isfromSave) => {
                              this.handleChangeToGroupModal(isfromSave);
                            }}
                          />
                        )}
                        {editPersonModal && (
                          <EditPerson
                            handleEditPersonModal={(isfromSave) => {
                              this.handleEditPersonModal(isfromSave);
                            }}
                          />
                        )}

                        <div className="row justify-content-center mt-4">
                          <div className="col-lg-3">
                            <div className="card">
                              <div className="card-body py-3 text-center">
                                <h5 className="pb-1">
                                  <i className="zmdi zmdi-email zmdi-hc-lg"></i>
                                </h5>
                                <p className="font-size-14 m-0">
                                  <a href={`mailto: ${email && email}`}>
                                    {email}
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="card">
                              <div className="card-body py-3 text-center">
                                <h5 className="pb-1">
                                  <i className="zmdi zmdi-phone zmdi-hc-lg"></i>
                                </h5>
                                <p className="font-size-14 m-0">
                                  <a href={`tel: ${phone && phone}`}>{phone}</a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="card">
                              <div className="card-body py-3 text-center">
                                <h5 className="pb-1">
                                  <i className="zmdi zmdi-smartphone-android zmdi-hc-lg"></i>
                                </h5>
                                <p className="font-size-14 m-0">
                                  <a href={`tel: ${mobile && mobile}`}>
                                    {mobile}
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card mt-4">
                          <div className="card-header border-bottom p-3 px-4">
                            <h5 className="m-0">
                              {" "}
                              {t("PersonDetails.tab_title_contact")}{" "}
                            </h5>
                          </div>
                          <div className="card-body font-size-15">
                            <a
                              href={`tel: ${
                                other_field_list &&
                                this.getWhatsappNumber(other_field_list)
                              }`}
                              className="d-block mb-4"
                            >
                              <i className="zmdi zmdi-whatsapp zmdi-hc-lg text-muted mr-1"></i>{" "}
                              {other_field_list &&
                                this.getWhatsappNumber(other_field_list)}
                            </a>
                            <div className="d-flex">
                              {" "}
                              <a href="#" className="d-block group">
                                <i className="zmdi zmdi-twitter zmdi-hc-lg text-muted mr-2"></i>{" "}
                              </a>{" "}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: socialmedia_twitter_stream,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="card mt-4">
                          <div className="card-header border-bottom p-3 px-4 d-flex justify-content-between">
                            <h5 className="m-0">
                              {" "}
                              {t("contactPersonAddress.address")}{" "}
                            </h5>
                            <ul
                              className={
                                "list-inline text-tab font-size-14 m-0"
                              }
                            >
                              <li className="list-inline-item">
                                <a
                                  href="javascript;:"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                      addressType: "business",
                                    });
                                  }}
                                  className={
                                    addressType == "business"
                                      ? "active text-muted"
                                      : "text-muted"
                                  }
                                >
                                  {t("contactPersonAddress.businessLable")}{" "}
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  href="javascript;:"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                      addressType: "private",
                                    });
                                  }}
                                  className={
                                    addressType == "private"
                                      ? "active text-muted"
                                      : "text-muted"
                                  }
                                >
                                  {t("contactPersonAddress.privateLable")}{" "}
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="card-body font-size-15">
                            {addressType == "business" ? (
                              <span className="d-block mb-4">
                                <i className="zmdi zmdi-pin zmdi-hc-lg text-muted mr-1"></i>{" "}
                                {business_adress}
                              </span>
                            ) : (
                              <span className="d-block mb-4">
                                <i className="zmdi zmdi-pin zmdi-hc-lg text-muted mr-1"></i>{" "}
                                {private_adress}
                              </span>
                            )}
                            <a
                              href={website && website}
                              className="d-block mb-4"
                              target="_blank"
                            >
                              <i className="zmdi zmdi-globe zmdi-hc-lg text-muted mr-2"></i>{" "}
                              <span className="text-underline">
                                {" "}
                                {website}{" "}
                              </span>
                            </a>
                            <a href={fax && fax} className="d-block">
                              <i className="zmdi zmdi-local-printshop zmdi-hc-lg text-muted mr-1"></i>{" "}
                              {fax && fax}
                            </a>
                          </div>
                        </div>
                        <div className="card mt-4">
                          <div className="card-header border-bottom p-3 px-4">
                            <h5 className="m-0">
                              {t("PersonDetails.tab_title_distributor")}{" "}
                            </h5>
                          </div>
                          <div className="card-body font-size-14">
                            <div className="row">
                              {mailing_list &&
                                mailing_list.map((item, index) => {
                                  const { id, name, list_creator_name } = item;
                                  return (
                                    <div className="col-md-4" key={index}>
                                      <div className="card bg-border yellow">
                                        <div className="card-header-custom p-3 px-4">
                                          {name}
                                          {/* <div className="mr-3 my-3 ml-auto align-self-center" onClick={() => { this.deletelist(id) }}> */}
                                          <img
                                            src="/images/trash-simple.png"
                                            alt="delete icon"
                                            className="pointer"
                                            onClick={() => { this.deletelist(id) }}
                                            style={{ width: "16px", height: "16px",float:'right' }}
                                          />
                                        {/* </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                        <div className="card mt-4">
                          <div className="card-header border-bottom p-3 px-4 d-flex justify-content-between">
                            <h5 className="m-0">
                              {" "}
                              {t("PersonDetails.tab_comment")}{" "}
                            </h5>
                            <a href="javascript:;">
                              <i className="zmdi zmdi-info zmdi-hc-lg"></i>
                            </a>
                          </div>
                          <div className="card-body font-size-15">
                            {comment}
                          </div>
                        </div>
                        <div className="card mt-4">
                          <div className="card-header border-bottom p-3 px-4">
                            <h5>{t("PersonDetails.tab_title_remarks")}</h5>
                          </div>
                          <div className="card-body font-size-15">
                            {other_field_list &&
                              other_field_list.map((item, index) => {
                                return (
                                  <div key={index}>
                                    <div className="my-2 clearfix">
                                      <div className="d-flex0 w-100">
                                        <div className="float-left font-16">
                                          {item.field_name +
                                            " : " +
                                            item.field_value}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>

                        <div className="card mt-4">
                          <div className="card-header border-bottom p-3 px-4 d-flex justify-content-between">
                            <h5 className="m-0">
                              {" "}
                              {t("PersonDetails.tab_title_social_media")}{" "}
                            </h5>
                            <ul className="list-inline text-tab font-size-14 m-0">
                              <li className="list-inline-item">
                                <a
                                  href="javascript:;"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                      socialMediaType: 0,
                                    });
                                  }}
                                  className={
                                    socialMediaType == 0
                                      ? "active text-muted"
                                      : "text-muted"
                                  }
                                >
                                  <i className="zmdi zmdi-facebook zmdi-hc-lg"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  href="javascript:;"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                      socialMediaType: 1,
                                    });
                                  }}
                                  className={
                                    socialMediaType == 1
                                      ? "active text-muted"
                                      : "text-muted"
                                  }
                                >
                                  <i className="zmdi zmdi-twitter zmdi-hc-lg"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  href="javascript:;"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                      socialMediaType: 2,
                                    });
                                  }}
                                  className={
                                    socialMediaType == 2
                                      ? "active text-muted"
                                      : "text-muted"
                                  }
                                >
                                  <i className="zmdi zmdi-instagram zmdi-hc-lg"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  href="javascript:;"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                      socialMediaType: 3,
                                    });
                                  }}
                                  className={
                                    socialMediaType == 3
                                      ? "active text-muted"
                                      : "text-muted"
                                  }
                                >
                                  <i className="zmdi zmdi-linkedin zmdi-hc-lg"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="card-body font-size-14">
                            <div className="row" >
                              {socialMediaType == 0 && (
                               <iframe srcdoc={socialmedia_facebook_stream} allowTransparency="true" style={{width:'100%',height:'100vh',backgroundColor:'transparent'}}></iframe>
                              )}
                              {socialMediaType == 1 && (
                               <iframe srcdoc={socialmedia_twitter_stream} allowTransparency="true" style={{width:'100%',height:'100vh',backgroundColor:'transparent'}}></iframe>
                              )}
                              {socialMediaType == 2 && (
                               <iframe srcdoc={socialmedia_instagram_stream} allowTransparency="true" style={{width:'100%',height:'100vh',backgroundColor:'transparent'}}></iframe>
                              )}
                              {socialMediaType == 3 && (
                               <iframe srcdoc={socialmedia_linkedin_stream} allowTransparency="true" style={{width:'100%',height:'100vh',backgroundColor:'transparent'}}></iframe>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FooterDesktop selectionType={3} />
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.groupPersonDetailStateToProps,
        stateToProps.eventListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.groupPersonDetailDispatchToProps,
    actions.deleteEventReportDispatchToProps,
    actions.groupDetailPersonListDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withTranslation()(withRouter(PersonDetail)));
