import React from "react";
import { Link } from "react-router-dom";

export default function DesktopSideMenuItem(props) {
  const { icon, title, type, link } = props;
  return (
    <React.Fragment>
      {type === 2 ? (
        <li className="nav-item">
          <Link className="nav-link" to={link && link}>
            <img
              src={`data:image/png;base64,${icon && icon}`}
              alt="sidemenu_icon"
              style={{width:'18px',height:'18px'}}
            />
          <span className="font-size-14 ml-2">  {title && title}</span>
          </Link>
        </li>
      ) : (
        <li className="nav-item">
          <a
            className="nav-link"
            href={link && link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`data:image/png;base64,${icon && icon}`}
              alt="sidemenu_ico"
              style={{width:'18px',height:'18px'}}
            />
           <span className="font-size-14 ml-2">  {title && title}</span>
          </a>
        </li>
      )}
    </React.Fragment>
  );
}
