import React from "react";
import MediaQuery from "react-responsive";
import GroupDetailMobile from "./GroupDetailMobile/Index";
import GroupDetailDesktop from "./GroupDetailDesktop/Index";

export default function GroupDetailResponsive() {
  return (
    <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <GroupDetailDesktop />
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <GroupDetailMobile />
      </MediaQuery>
    </React.Fragment>
  );
}
