/* eslint eqeqeq: 0 */

import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import gql from "graphql-tag";
import React, { Component } from "react";
import { ApolloProvider, Mutation } from "react-apollo";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { getMenuDetails } from "../../../../constants/apiParams";
import apiCall, { METHOD, uploadLink } from "../../../../constants/baseUrl";
import { getUserID } from "../../../../util/utils";
import Footer from "../../../Footer/FooterMobile";
import LoadingPage from "../../../CommanComponents/LoadingPage";
import CommonLoader from "../../../CommanComponents/CommonLoader";


const apolloCache = new InMemoryCache();
const client_mutation = new ApolloClient({
  cache: apolloCache,
  link: uploadLink,
});

const Addsidemenu = gql`
 mutation Addsidemenu($profile: AddsidemenuInput!) 
 {Addsidemenu(input: $profile) 
  {success 
  message}
}
`;

class EditMenuItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      icon: undefined,
      icon_file: undefined,
      base_64_icon: undefined,
      reportDialogueStatus: false,
      name: '',
      identifier:'',
      type: 0,
      link: '',
      isLoading:false
    };
  }

  handleTextChange = (e, type) => {
    if (type == 'name') {
      this.setState({
        name: e.target.value
      })
    } else if (type == 'type') {
      this.setState({
        type: e.target.value
      })
    } else if (type == 'identifier') {
      this.setState({
        identifier: e.target.value
      })
    }else {
      this.setState({
        link: e.target.value
      })
    }
  }
  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleReportDialogue = () => {
    this.setState((prevState) => {
      return {
        reportDialogueStatus: !prevState.reportDialogueStatus,
      };
    });
  };

  setGender = (event) => {
    this.setState({
      type: event.target.value
    })
  }


  componentDidMount() {
    apiCall(
      METHOD.QUERY,
      getMenuDetails(getUserID(), this.props.item.id),
      (data) => this.getMenuDetails(data),
      (isLoading) => { this.setState({ isLoading }) },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }
  getMenuDetails = (data) => {
    const {  title, link, type, icon,identifier } = data

    this.setState({
      name: title, link, type, base_64_icon: icon, identifier
    })

  }
  getIconUrl = (file, uploadFile) => {
    let images_extensions = ["jpg", "jpeg", "png"];
    let image_extension = file.type.split("/")[1];
    const result = images_extensions.indexOf(image_extension);
    if (result == -1) {
      toast.info(`${image_extension} file is not supported`);
    } else {
      this.setState({
        icon: URL.createObjectURL(file),
        icon_file: uploadFile
      });
    }
  };
  loader = (<LoadingPage />);
  render() {
    const {
      icon,
      name, type, link, base_64_icon, isLoading,identifier
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <ApolloProvider client={client_mutation}>
              <Mutation mutation={Addsidemenu}>
                {(Addsidemenu, { data, error, loading }) => {
                  if (data) {

                    if (data.Addsidemenu.success == 0) {
                      toast.error(this.props.t(data.Addsidemenu.message));
                    } else {
                      toast.success(this.props.t(data.Addsidemenu.message));
                      this.props.changeRouteStatus(0, {})
                    }
                  }
                  if (error) {
                    toast.error(
                      i18n.getDataByLanguage("en").translation.GeneralMessages
                        .errorServerisNotResponding &&
                      i18n.getDataByLanguage("en").translation.GeneralMessages
                        .errorServerisNotResponding
                    );
                  }

                  return (
                    <React.Fragment>
                      <div className="header-bg header-green sab-header1 sticky-top">
                        <div className="container">
                          <div className="py-2 d-flex align-items-center">
                            <span>
                              <i
                                className="zmdi zmdi-close zmdi-hc-fw font-30 text-light pointer"
                                onClick={() => this.props.changeRouteStatus(0, {})}
                              ></i>
                            </span>

                            {loading ? (
                              <div className="btn btn-light font-13 ml-auto pointer">
                                {t("AdminAddUser.save")}
                                <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                  <span className="sr-only">
                                    {t("GeneralMessages.loading")}
                                  </span>
                                </div>
                              </div>
                            ) : (
                                <div
                                  className="btn btn-light font-13 ml-auto pointer"
                                  onClick={() => {
                                    const { name, type, link, icon_file, identifier } = this.state
                                    if (!name) {
                                      toast.info(this.props.t("ManageMenu.nameTitleValidation"));
                                    } else if (!link) {
                                      toast.info(this.props.t("ManageMenu.linkTitleValidation"));
                                    } else if (!identifier) {
                                      toast.info(this.props.t("ManageMenu.identifierValidation"));
                                    }else if (type==0) {
                                      toast.info(this.props.t("ManageMenu.TypeTitleValidation"));
                                    } else if (!icon_file && !base_64_icon) {
                                      toast.info(this.props.t("ManageMenu.iconValidation"));
                                    } else {

                                      let obj = {
                                        variables: {
                                          profile: {
                                            user_id: getUserID(),
                                            id: this.props.item.id,
                                            title:name,
                                            identifier,
                                            type,
                                            link,
                                            icon: icon_file ? icon_file : null,
                                          },
                                        },
                                      };
                                      Addsidemenu(obj)
                                    }
                                  }}
                                >
                                  {t("AdminAddUser.save")}
                                </div>
                              )}
                          </div>
                          <div className="text-center mt-4 text-light">
                            <h2 className="m-0 text-light">
                              {t("ManageMenu.editMenuItem")}
                            </h2>
                          </div>
                        </div>
                      </div>

                      <div className="middle-section sab-middle-section1">
                      {isLoading && (<CommonLoader/>)}

                        <div className="container my-4 z-index-99">
                          <div className="form-group mb-5">

                            <div className="form-group">
                              <div className="container my-4 00z-index-99">
                                <form>
                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("ManageMenu.nameTitle")}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) => this.handleTextChange(e, "name")}
                                      name="name"
                                      value={name && name}
                                      placeholder={t("ManageMenu.nameTitlePlaceHolder")}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("ManageMenu.linkTitle")}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) => this.handleTextChange(e, "link")}
                                      name="link"
                                      value={link && link}
                                      placeholder={t("ManageMenu.linkTitlePlaceHolder")}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("ManageMenu.identifierTitle")}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) => this.handleTextChange(e, "identifier")}
                                      name="identifier"
                                      value={identifier && identifier}
                                      placeholder={t("ManageMenu.identifierPlaceHolder")}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("ManageMenu.typeTitle")}
                                    </label>
                                   

                                    <div onChange={(e) => this.setGender(e)} >
                                      <input type="radio" checked={type==2?true:false} value="2" name="link_type" /> {t("ManageMenu.internalTitle")}    </div>
                                    <div onChange={(e) => this.setGender(e)} >
                                      <input type="radio" checked={type == 1 ? true : false} value="1" name="link_type" /> {t("ManageMenu.externalTitle")}
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("ManageMenu.iconTitle")}
                                    </label>

                                    <div
                                      className="fileinput text-center fileinput-new d-flex align-items-center"
                                      data-provides="fileinput"
                                    >
                                      <div className="btn-file">
                                        <div
                                          className="thumbnail fileinput-new "
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            background: "#999"
                                          }}
                                        >
                                          {icon ? (<img src={icon} alt="" />) :
                                            base_64_icon ? (<img src={`data:image/png;base64,${base_64_icon && base_64_icon}`}
                                              style={{ width: "32px" }}
                                              alt="icon"
                                            />) : (<img
                                              src="/images/no-image.png"
                                              alt="icon"
                                            />)}

                                        </div>

                                        <input
                                          type={"file"}
                                          file-model="myFile"
                                          name="image"
                                          accept=""
                                          onChange={(event) => {
                                            const file = event.target.files[0];
                                            this.getIconUrl(event.currentTarget.files[0], file);
                                          }}
                                        />


                                        <div
                                          className="fileinput-preview fileinput-exists thumbnail uploaded-user-image rounded-circle"
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            borderRadius: "100%",
                                          }}
                                        ></div>
                                      </div>
                                    </div>

                                  </div>

                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Footer type="2" />
                    </React.Fragment>
                  );
                }}
              </Mutation>
            </ApolloProvider>
          );
        }}

      </Translation>
    );
  }
}
export default withRouter(withTranslation()(EditMenuItem));
