/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { addrecipientdirect } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
import Footer from "../../Footer/FooterMobile";
import MailList from "./MailList";
import PersonbaseOnMail from "./PersonbaseOnMail";
import PersonDataTypeList from "./PersonDataTypeList";
import PersonDataTypeValue from "./PersonDataTypeValue";
import SearchMail from "./SearchMail";
import SubMailList from "./SubMailList";

class ChannelMailLinkIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      routeStatus: 0,
      mail_id: 0,
      mailIdForPerson: 0,
      selectedMailList: [],
      selectedPersonList: [],
      searchMailList: [],
      searchPersonList: [],
      isLoading: false,
      data: {},
      personDataTypeId: 0,
      filterValue: "",
      personDataTypeId:0,
      personDataTypeName:'',
      filterValue:'',
      personCountForMail:0
    };
  }

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  setSearchMailList = (list) => {
    if (this.state.routeStatus == 2) {
      this.setState({
        searchPersonList: list,
      });
    } else {
      this.setState({
        searchMailList: list,
      });
    }
  };
  handleClick = () => {
    const { selectedMailList,selectedPersonList } = this.state;
    const message_id = this.props.match.params.message_id;
    let finalSelectedMailId = []
    let list_id = selectedMailList.join(",");
    let person_id = selectedPersonList.join(",");

    for (let index = 0; index < selectedMailList.length; index++) {
      const mailId = selectedMailList[index];
      let mailobj = {
        id:mailId,
        person:[],
        is_all_person:1
      }
      finalSelectedMailId =[...finalSelectedMailId, mailobj]
    }

    for (let index = 0; index < selectedPersonList.length; index++) {
      const personListWithMailId = selectedPersonList[index];
      
      finalSelectedMailId =[...finalSelectedMailId, personListWithMailId]
    }
    console.log("finalSelectedMailId",finalSelectedMailId);
    let CommaSeperateList= finalSelectedMailId

    for (let index = 0; index < CommaSeperateList.length; index++) {
      const element = CommaSeperateList[index];
      let person = element.person
      person= person.join(",");
      element.person =person
      element.id=`${element.id}`
      console.log("element",element);

      CommaSeperateList[index]=element
    }
    console.log("CommaSeperateList",CommaSeperateList);
    if (selectedMailList.length == 0 && selectedPersonList.length ==0) {
      toast.error(this.props.t("ChannelDetail.mailListSelectValidation"));
    } else {
      let list = JSON.stringify(JSON.stringify(CommaSeperateList))
      console.log("list",list);
      apiCall(
        METHOD.QUERY,
        addrecipientdirect(getUserID(), message_id, list),
        (data) => {
          toast.success(this.props.t(data.message));
          this.props.changeRouteStatus(0);
        },
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  changeStatus = (type, mail_id) => {
    this.setState({
      routeStatus: type,
      mail_id,
    });
  };

  changeStatusForPersonList = (type, mail_id) => {
    this.setState({
      routeStatus: 2,
      mailIdForPerson: mail_id,
    });
  };

  addSelectedMailId = (mail_id) => {
    this.setState(
      (prev) => {
        return {
          selectedMailList: [...prev.selectedMailList, mail_id],
        };
      },
      () => {
        console.log("selectedMailList", this.state.selectedMailList);
      }
    );
  };

  removeSelectedMailId = (mail_id) => {
    let deletedMailList = this.state.selectedMailList.filter(
      (id) => {
        return id != mail_id;
      },
      () => {
        console.log("selectedMailList", this.state.selectedMailList);
      }
    );

    this.setState({
      selectedMailList: deletedMailList,
    });
  };

  addSelectedPersonId = (personId,mailId) => {
    let selectedPersonList = this.state.selectedPersonList
    const index = selectedPersonList.findIndex(
      (personMail) => personMail.id === mailId
    );
    if(index!=-1){
      let objPerson = selectedPersonList[index]
     let personList = objPerson.person
    personList=[...personList,personId]
    objPerson.person=personList
    selectedPersonList[index]=objPerson
    this.setState(selectedPersonList)
    }else{
      let personObj={
        id:mailId,
        person:[personId],
        is_all_person:0
      }
      console.log("personOBjWhenIndex--1",personObj);
      selectedPersonList.push(personObj)
      this.setState(selectedPersonList)
    }
   
    // this.setState(
    //   (prev) => {
    //     return {
    //       selectedPersonList: [...prev.selectedPersonList, personId],
    //     };
    //   },
    //   () => {
    //     console.log("selectedMailList", this.state.selectedPersonList);
    //   }
    // );
  };

  removeSelectedPersonId = (personId,mailId) => {
    let selectedPersonList = this.state.selectedPersonList
    const index = selectedPersonList.findIndex(
      (personMail) => personMail.id === mailId
    );
    if(index!=-1){
      let objPerson = selectedPersonList[index]
     let personList = objPerson.person
     let afterdeletePersonList = personList.filter(
      (id) => {
        return id != personId;
      },
      () => {
        console.log("selectedMailList", this.state.selectedPersonList);
      }
    );

    objPerson.person=afterdeletePersonList
    selectedPersonList[index]=objPerson
    this.setState(selectedPersonList)
    }

    // let deletedPersonList = this.state.selectedPersonList.filter(
    //   (id) => {
    //     return id != personId;
    //   },
    //   () => {
    //     console.log("selectedMailList", this.state.selectedPersonList);
    //   }
    // );

    // this.setState({
    //   selectedPersonList: deletedPersonList,
    // });
  };


  handleApplyFilter = (filterValue) => {
    if (filterValue == "") {
      this.setState({
        routeStatus: 2,
        filterValue,
        personDataTypeId: 0,
        personDataTypeName: '',
      });
    } else {
      this.setState({
        routeStatus: 2,
        filterValue,
      });
    }
  };

  storeApiData = (data) => {
    this.setState({
      data,
    });
  };

  setPersonDataTypeId = (personDataTypeId) => {
    this.setState({
      personDataTypeId,
    });
  };
  setPersonDataTypeName = (personDataTypeName) => {
    this.setState({
      personDataTypeName
    });
  };

  changeFilterRouteStatus = (value) => {
    this.setState((prevState) => {
      return {
        routeStatus: value,
      };
    });
  };

  handleClosePersonDataType=()=>{
    this.setState({
      personDataTypeId:0,
      personDataTypeName: '',
      filterValue:'',
      routeStatus: 2,
    })
  }
  handleClosePersonDataValue=()=>{
    this.setState({
      routeStatus: 3,
    })
  }

  getPersonCount=()=>{
    const {selectedPersonList} = this.state
    let personCount = 0
    for (let index = 0; index < selectedPersonList.length; index++) {
      const personObj = selectedPersonList[index];
      const person = personObj.person
      personCount=personCount+person.length
    }
    return personCount
  }

  updatePersonCountForMail =(personCountForMail)=>{
    this.setState({
      personCountForMail
    })
  }

  updatePersonCountForPersonList =(mailId,isChecked)=>{
    const {personCountForMail} = this.state;
    let count= personCountForMail;
  
    if(isChecked){
      count=count+1
      this.setState({
        personCountForMail:count
      })
     }else{
      count=count-1
      this.setState({
        personCountForMail:count
      })
     }
  }

  loader = (<div>{this.props.t("GeneralMessages.loading")}</div>);
  render() {
    const {
      routeStatus,
      isLoading,
      searchMailList,
      searchPersonList,
      filterValue,
      mailIdForPerson,
      personDataTypeId,
      // selectedPersonList,
      // selectedMailList,
      personCountForMail
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            
            <React.Fragment>
              {routeStatus == 3 ? (
                <PersonDataTypeList  changeFilterRouteStatus={(route)=>this.changeFilterRouteStatus(route)}
                handleClosePersonDataType={() => {
                  this.handleClosePersonDataType();
                }}
                setPersonDataTypeId={(personDataTypeId) => {
                  this.setPersonDataTypeId(personDataTypeId);
                }}
                setPersonDataTypeName={(personDataTypeName) => {
                  this.setPersonDataTypeName(personDataTypeName);
                }}
                />
              ) : routeStatus == 4 ? (
                <PersonDataTypeValue 
                mailIdForPerson={mailIdForPerson}
                handleApplyFilter={(filterValue) => {
                  this.handleApplyFilter(filterValue);
                }}
                personDataTypeId={this.state.personDataTypeId}
                personDataTypeName={this.state.personDataTypeName}
                handleClosePersonDataValue={() => {
                  this.handleClosePersonDataValue();
                }}
                changeFilterRouteStatus={(route)=>this.changeFilterRouteStatus(route)}
                />
              ) : (
                <React.Fragment>
                  <div className="header-bg header-yellow sab-header1 sticky-top d-flex flex-column">
                    <div className="container">
                      <div className="py-2 d-flex align-items-center">
                        <span
                          className="pointer"
                          onClick={() => this.props.changeRouteStatus(0)}
                        >
                          <i className="zmdi zmdi-close zmdi-hc-fw font-30 text-light"></i>
                        </span>

                        <div
                          className="btn btn-light font-13 ml-auto pointer"
                          onClick={this.handleClick}
                        >
                          {t("ChannelMailLink.Save")}
                        </div>
                      </div>
                      <div className="text-center mb-1  text-light">
                        <h2 className="text-light">
                        {t("ChannelMailLink.addRecipient")} {" "} {`(${personCountForMail})`}
                          {/* {t("ChannelMailLink.addRecipient")} {" "} {routeStatus==2?(`(${this.getPersonCount()})`):(`(${personCountForMail})`)} */}
                        </h2>
                      </div>
                      <SearchMail
                        routeStatus={routeStatus}
                        mail_id={this.state.mail_id}
                        mailIdForPerson={this.state.mailIdForPerson}
                        setSearchMailList={(list) => {
                          this.setSearchMailList(list);
                        }}
                      />
                    </div>
                  </div>
                  <div className="middle-section ">
                    {isLoading && this.loader}
                    <React.Fragment>
                      {routeStatus == 0 && (
                        <MailList
                          changeMailRouteStatus={this.changeStatus}
                          addSelectedMailId={this.addSelectedMailId}
                          removeSelectedMailId={this.removeSelectedMailId}
                          checkedList={this.state.selectedMailList}
                          searchArray={searchMailList}
                          updatePersonCountForMail={(personCountForMail)=>this.updatePersonCountForMail(personCountForMail)}
                          personCountForMail={personCountForMail}
                        />
                      )}
                      { routeStatus == 1 && (
                        <SubMailList
                          changeMailRouteStatus={this.changeStatus}
                          changeStatusForPersonList={
                            this.changeStatusForPersonList
                          }
                          addSelectedMailId={this.addSelectedMailId}
                          removeSelectedMailId={this.removeSelectedMailId}
                          mail_id={this.state.mail_id}
                          checkedList={this.state.selectedMailList}
                          searchArray={searchMailList}
                          updatePersonCountForMail={(personCountForMail)=>this.updatePersonCountForMail(personCountForMail)}
                          personCountForMail={personCountForMail}
                        />
                      )}
                      { routeStatus == 2 && (
                        <PersonbaseOnMail
                          changeMailRouteStatus={this.changeStatus}
                          changeFilterRouteStatus={this.changeFilterRouteStatus}
                          addSelectedPersonId={this.addSelectedPersonId}
                          removeSelectedPersonId={this.removeSelectedPersonId}
                          mail_id={this.state.mailIdForPerson}
                          checkedList={this.state.selectedPersonList}
                          searchArray={searchPersonList}
                          filterValue={filterValue||''}
                          personDataTypeId={personDataTypeId}
                          updatePersonCountForPersonList={(mailId,isChecked)=>this.updatePersonCountForPersonList(mailId,isChecked)}

                        />
                      )}
                      
                    </React.Fragment>
                  </div>
                  <Footer type="2" />
                </React.Fragment>
              )}
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(ChannelMailLinkIndex));
