import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import {
  getallpersonbypersondata,
  getgrouplistfromperson,
  getmailinglistfromperson,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { toast } from "react-toastify";
import { getUserID } from "../../../util/utils";
import { connect } from "react-redux";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
class SearchPersonResult extends Component {
  handelMailItemClick = (mail_id_range) => {
    this.props.history.push("/mail-detail/" + mail_id_range);
  };

  getGroupPersonList = (personId) => {
    apiCall(
      METHOD.QUERY,
      getgrouplistfromperson(getUserID(), personId),
      (data) => this.handleGetGroupPersonResponse(data, personId),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  getPersonListFromCompany = (value) => {
    apiCall(
      METHOD.QUERY,
      getallpersonbypersondata(getUserID(), value),
      (data) => this.handlePersonListBaseonCompany(data, value),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handlePersonListBaseonCompany = (data) => {
    this.props.setPersonListBaseOnCompany(data.person_list);
  };

  handleGetGroupPersonResponse = (data, personId) => {
    this.props.setGroupFromPerson(data.group_list, personId);
    // this.props.handleMailbaseOnPerson(data.mailing_list)
  };

  handleItemClick = (suggestion) => {
    const type = suggestion?.type;
    if (type == "person") {
      this.getGroupPersonList(suggestion?.id);
    } else if (type == "data") {
      const person = suggestion?.child_element;
      const group_id = suggestion?.id;
      if (person == 0) {

        this.props.clearStateGroupPersonDetail({});
        this.props.setPersonListHasMoreValue(true);
        this.props.setPersonListStartValue(0);
        
        this.props.history.push("/group-detail/" + group_id);
        // window.location.reload()
      } else {
        this.props.history.push("/group-personlist/" + group_id);
      }
    }else if(type=='company'){
      this.getPersonFromCompany(suggestion?.name)
    }
  };

  handlePersonDataTypeValue=(data)=>{
    this.props.handlePersonListBaseonCompany(data.person_list)
  }
  getPersonFromCompany=(personDataType)=>{
    apiCall(
      METHOD.QUERY,
      getallpersonbypersondata(getUserID(),personDataType),
      (data) => this.handlePersonDataTypeValue(data),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  render() {
    const { suggetionList } = this.props;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="container my-2">
              {suggetionList && suggetionList.lenght!=0 && suggetionList.map((suggestion, index) => {
                return (
                  <React.Fragment>
                    {suggestion.type == "data" && (
                      <div className="bg-white shadow-sm p-3">
                        {suggestion?.isFirst && suggestion.type == "data" && (
                          <h5 className="mt-1 mb-3 text-muted">
                            <u>{t("GeneralMessages.groupList")}</u>
                          </h5>
                        )}
                        <ul className="list-unstyled autosuggest-list">
                          <div
                            className="col-lg-10  pointer"
                            onClick={() => {
                              this.handleItemClick(suggestion);
                            }}
                          >
                            <a href="#" className="card list-card">
                              <div className="card-body p-0">
                                <div className="media">
                                  <div className="bg-blue left-icon-box-medium d-flex align-items-center justify-content-center text-center">
                                    <div className="circle big m-0">
                                      <img
                                        src={`data:image/png;base64,${
                                          suggestion.type_icon || ""
                                        }`}
                                        alt="ico"
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="clearfix p-3">
                                    <h6 className="mb-1">
                                      {suggestion.name || ""}
                                    </h6>
                                    <p className="text-muted font-12 mb-0">
                                    {suggestion.child_element && suggestion.child_element}{" "}
                                    { suggestion?.child_element == 1
                                      ? t("GroupListScreen.singularPerson")
                                      : t("GroupListScreen.pluralPerson")}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </ul>
                      </div>
                    )}

                    {suggestion.type == "person" && (
                      <div className="bg-white shadow-sm p-3">
                        {suggestion?.isFirst && suggestion.type == "person" && (
                          <h5 className="mt-1 mb-3 text-muted">
                            <u>{t("GeneralMessages.personList")}</u>
                          </h5>
                        )}
                        <ul className="list-unstyled autosuggest-list">
                          <li
                            className="border-bottom pointer"
                            onClick={() => {
                              this.handleItemClick(suggestion);
                            }}
                          >
                            <h5 className="font-15 mb-1">{suggestion.name}</h5>
                            <p className="font-15 text-muted m-0">
                              {suggestion.company && suggestion.company}{" "}
                              {suggestion.job_title && suggestion.job_title}
                            </p>
                            <hr className="dotted" />
                          </li>
                        </ul>
                      </div>
                    )}

                    {suggestion.type == "company" && (
                      <div className="bg-white shadow-sm p-3 ">
                        {suggestion?.isFirst && suggestion.type == "company" && (
                          <h5 className="mt-1 mb-3 text-muted">
                            <u>{t("GeneralMessages.companyList")}</u>
                          </h5>
                        )}
                        <ul className="list-unstyled autosuggest-list">
                          <li
                            className=" pb-1 border-bottom pointer"
                            onClick={() => {
                              this.handleItemClick(suggestion);
                            }}
                          >
                            <h5 className="font-15 ">{suggestion.name}</h5>
                            <hr className="dotted" />

                            {/* <p className="font-15 text-muted m-0">{suggestion.company && suggestion.company}  {suggestion.job_title && suggestion.job_title}</p> */}
                          </li>
                        </ul>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.groupListStateToProps,
        stateToProps.groupDetailStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupListDispatchToProps,
    actions.groupDetailDispatchToProps,
    actions.groupDetailPersonListDispatchToProps,
  ])
)(withRouter(withTranslation()(SearchPersonResult)));