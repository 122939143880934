/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { adminUserList, deleteUser } from "../../../../constants/apiParams";
import apiCall, { METHOD } from "../../../../constants/baseUrl";
import { getUserID } from "../../../../util/utils";
import Footer from "../../../Footer/FooterMobile";
import LoadingPage from "../../../CommanComponents/LoadingPage";
import FooterDesktop from "../../../Footer/FooterDesktop";
import HeaderDesktop from "../../../Header/HeaderDesktop";
import CommonLoader from "../../../CommanComponents/CommonLoader";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import { target } from "glamor";
import AdminSideMenu from "../../AdminMenu/AdminSideMenu";

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      user_list: [],
      start: 0,
      end: 10,
      hasMore: true,
      deleteLoadingStatus: false,
      headerMenuStatus: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.loadUserList();
  }
  loadUserList = () => {
    const { start, end } = this.state;
    apiCall(
      METHOD.QUERY,
      adminUserList(getUserID(), start, end),
      (data) => this.handleUserApiResponse(data),
      (isLoading) => {
        this.handleLoader(isLoading);
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleUserApiResponse = (data) => {
    const { start, end } = this.state;
    if (data.is_last == 1) {
      this.setState({ hasMore: false });
    }
    this.setState((prevState) => {
      return {
        ...this.state,
        start: start + end,
        user_list: [...prevState.user_list, ...data.user_list],
        isLoading: false,
      };
    });
  };

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: true,
      };
    });
  };
  handleUserApproval = (item) => {
    let tempItem = item;
    tempItem["is_approve"] = true;
    this.props.changeStatusWithStatusValue(2, tempItem);
  };
  handleDelete = (item) => {
    const { id } = item;

    confirmAlert({
      title: this.props.t("administration.confirmTodeleteUser"),
      message: this.props.t("administration.confirmTodeleteUserMessage"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              deleteUser(getUserID(), id),
              (data) => {
                let afterDeletedUser_list = this.state.user_list.filter(
                  (user) => {
                    return user.id != item.id;
                  }
                );

                toast.success(this.props.t(data.message));
                this.setState({
                  user_list: afterDeletedUser_list,
                });
              },
              (isLoading) => {
                this.setState({ deleteLoadingStatus: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };
  getColorBaseonStatus = (status) => {
    if (status == 0) {
      return "rounded-40 red-bg float-left ml-5p mb-2";
    } else if (status == 1) {
      return "rounded-40 green-bg float-left ml-5p mb-2";
    } else {
      return "rounded-40 yellow-bg float-left ml-5p mb-2";
    }
  };
  // ""
  closeSlider = () => {
    this.setState(() => {
      return {
        headerMenuStatus: false,
      };
    });
  };

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleAddModal = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          addModal: !prev.addModal,
        };
      },
      () => {
        if (this.state.addModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.setState(
              {
                start: 0,
                hasMore: false,
                user_list: [],
              },
              () => {
                this.loadUserList();
              }
            );
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  handleEditModal = (isFromSave, item) => {
    this.setState(
      (prev) => {
        return {
          editModal: !prev.editModal,
          editItemValue: item,
        };
      },
      () => {
        if (this.state.editModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.setState(
              {
                start: 0,
                hasMore: false,
                user_list: [],
              },
              () => {
                this.loadUserList();
              }
            );
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  loader = (<CommonLoader key={0} />);
  render() {
    const {
      hasMore,
      addModal,
      editModal,
      user_list,
      deleteLoadingStatus,
      isLoading,
    } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="content-wrapper">
              <HeaderDesktop selectionType={0} />
              <div className="content">
                <div className="section section-padding">
                  <div className="container">
                    <div className="section-container">
                      <div className="section-content">
                        <div className="row mt-2">
                          {isLoading && <CommonLoader />}
                          <AdminSideMenu activeIndex={3} />
                          <div className="col-lg-9">
                            <div className="section-title text-center d-md-flex align-items-start m-0">
                              <div className="flex-fill mr-n-1300">
                                <Link
                                  to="/administration"
                                  className="text-light"
                                >
                                  <h1 className="page-title h4">
                                    {t("AdminAddUser.user_list_title")}
                                  </h1>
                                </Link>
                              </div>
                              <button
                                type="button"
                                className="btn btn-primary-theme btn-sm"
                                onClick={() => {
                                  this.handleAddModal();
                                }}
                              >
                                {" "}
                                {t("GeneralMessages.addNew")}
                              </button>
                            </div>

                            <div className="row mt-4">
                              {deleteLoadingStatus ||
                                (isLoading && <CommonLoader />)}

                              {user_list &&
                                user_list.map((item, index) => {
                                  const { name, group_name, status } = item;
                                  return (
                                    <div className="col-lg-6 mb-4">
                                      {" "}
                                      <a
                                        href="javascript:;"
                                        className="card list-card"
                                        key={index}
                                      >
                                        <div className="card-body d-flex py-3 px-3">
                                          <div
                                            className="circle big m-0 mr-3"
                                            className={this.getColorBaseonStatus(
                                              status
                                            )}
                                          ></div>
                                          <div className="clearfix mr-2">
                                            <h6 className="mb-1">{name}</h6>
                                            <small className="text-muted text-break">
                                              {group_name}
                                            </small>
                                          </div>
                                          <div className="ml-auto">
                                            <ul className="list-inline action-menu-list d-flex m-0">
                                              <li className="list-inline-item mr-3">
                                                <i
                                                  className="zmdi zmdi-edit zmdi-hc-lg pointer"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleEditModal(
                                                      false,
                                                      item
                                                    );
                                                  }}
                                                />
                                              </li>
                                              <li className="list-inline-item mr-3 ">
                                                <i
                                                  className="zmdi zmdi-delete zmdi-hc-lg pointer"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleDelete(item);
                                                  }}
                                                />
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  );
                                })}
                            </div>
                            {hasMore && (
                              <div className="text-center mt-2">
                                <button
                                  type="button"
                                  className="btn btn-primary-theme"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.loadUserList();
                                  }}
                                >
                                  {t("HomeScreen.showMore")}
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    {addModal && (
                      <AddUser
                        handleAddModal={(isFromSave) => {
                          this.handleAddModal(isFromSave);
                        }}
                      />
                    )}
                    {editModal && (
                      <EditUser
                        item={this.state.editItemValue}
                        handleEditModal={(isFromSave, item) => {
                          this.handleEditModal(isFromSave, item);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <FooterDesktop selectionType={0} />
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default withTranslation()(UserList);
