/* eslint eqeqeq: 0 */
import de from "date-fns/locale/de"; // the locale you want
import { Field, Formik } from "formik";
import moment from "moment";
import React, { Component } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Autocomplete, Form } from "react-formik-ui";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CreateEventQuery,
  EventBrandTypeQuery,
  EventlocationTypeQuery,
  ReportListQuery
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID, months, ToTimeStamp } from "../../../util/utils";
import { CreateEventSchema } from "../../../util/validationSchema";
import CommonLoader from "../../CommanComponents/CommonLoader";
import ErrorMessageFormik from "../../CommanComponents/ErrorMessageFormik";
registerLocale("de", de);

class AddNewEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: false,
      brandList: [],
      location_list: [],
      brandType: "",
      brandImage: "",
      brandID: 0,
      startDate: new Date(),
      startTime: new Date(),
      datePickerIsOpen: false,
      timePickerIsOpen: false,
      brandTypeID: "",
      isLoading: false,
      loader:false
    };
    this.handleChange = this.handleChange.bind(this);
    this.openDatePicker = this.openDatePicker.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.openTimePicker = this.openTimePicker.bind(this);
  }

  handleBrandSelection = (item) => {
    const { name, icon, id } = item;
    this.setState(() => {
      return {
        ...this.state,
        status: false,
        brandType: name,
        brandImage: icon,
        brandID: id,
      };
    });
  };

  closeBrandDialogue = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: false,
      };
    });
  };

  openBrandDialogue = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
      };
    });
  };

  openDatePicker() {
    this.setState({
      ...this.state,
      datePickerIsOpen: !this.state.datePickerIsOpen,
    });
  }

  handleChange(date) {
    this.setState({
      ...this.state,
      startDate: date,
      datePickerIsOpen: false,
    });
  }

  openTimePicker() {
    this.setState({
      ...this.state,
      timePickerIsOpen: !this.state.timePickerIsOpen,
    });
  }

  handleTimeChange(time) {
    this.setState({
      ...this.state,
      startTime: time,
      timePickerIsOpen: false,
    });
  }

  handleAfterCreateApiCall = (data) => {
    toast.success(this.props.t(data.message));
    const { operation } = this.props;
    let event = {
      id: data.id,
      name: data.name,
      event_datetime: data.event_datetime,
      event_type_icon: data.event_type_icon,
      event_type_id: data.event_type_id,
      event_creator_name: data.event_creator_name,
      location: data.location,
    };

    if (operation == "edit") {
      this.props.editEvent(event);
      this.props.editEventReport(event);
      this.props.handleEditeventModal(0,true)
    } else {
      let count = this.props.eventList.total_event;
      count = count && count + 1;
      this.props.setEventCount(count);
      this.props.addNewEvent([event]);
      this.props.handleNeweventModal(true)
    }
  };

  handleAfterBrandApiCall = (data) => {
    let brandImageDefault, brandNameDefault, brandID;
    if (data.event_type_list && data.event_type_list.langth !== 0) {
      brandNameDefault = data.event_type_list[0].name;
      brandImageDefault = data.event_type_list[0].icon;
      brandID = data.event_type_list[0].id;
    }
    const {
      event_type_icon,
      event_type_id,
      event_type_name,
      event_datetime,
    } = this.props.reportList;

    const { operation } = this.props;

    this.setState(
      () => {
        return {
          brandList: data.event_type_list,
          brandType:
            operation == "edit" && event_type_name
              ? event_type_name
              : brandNameDefault,
          brandImage:
            operation == "edit" && event_type_icon
              ? event_type_icon
              : brandImageDefault,
          brandID:
            operation == "edit" && event_type_id ? event_type_id : brandID,
          startDate:
            operation == "edit" && event_datetime
              ? new Date(moment.unix(event_datetime))
              : new Date(),
          startTime:
            operation == "edit" && event_datetime
              ? new Date(moment.unix(event_datetime))
              : new Date(),
        };
      },
      () => this.getLocationList()
    );
  };

  handleSubmit = (values) => {
    const { operation } = this.props;
    const { startDate, startTime, brandID } = this.state;
    const { event_name, location } = values;
    const userID = getUserID();
    let timeStamp = this.generateTimeStamp(startDate, startTime);
    let eventid = operation == "edit" ? this.props.reportList.id : "";
    apiCall(
      METHOD.QUERY,
      CreateEventQuery(
        userID,
        eventid,
        brandID,
        location,
        event_name,
        timeStamp
      ),
      (data) => this.handleAfterCreateApiCall(data, location),
      (isLoading) =>
        this.setState({
        isLoading
        }),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  componentDidMount() {
    if(this.props.operation == "edit"){
      apiCall(
        METHOD.QUERY,
        ReportListQuery(getUserID(), this.props.eventId),
        (data) => {
          this.props.getReportList(data);
          apiCall(
            METHOD.QUERY,
            EventBrandTypeQuery(getUserID(),0),
            (data) => {
              this.handleAfterBrandApiCall(data);
            },
            (isLoading) => {this.setState({loader:isLoading})},
            (data) => {
              toast.error(this.props.t(data.message));
            }
          );
        },
        (isLoading) => {this.setState({loader:isLoading})},
        (data) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }else{
      apiCall(
        METHOD.QUERY,
        EventBrandTypeQuery(getUserID(),0),
        (data) => {
          this.handleAfterBrandApiCall(data);
        },
        (isLoading) => {this.setState({loader:isLoading})},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }

 
  }

  getLocationList = () => {
    apiCall(
      METHOD.QUERY,
      EventlocationTypeQuery(getUserID()),
      (data) => {
        const { location_id } = this.props.reportList;
        const { operation } = this.props;
        let locationName = [];
        let locationList = data.location_list;
        for (let i = 0; i < locationList.length; i++) {
          let item = locationList[i];
          locationName.push(item.name);
        }
        this.setState({
          location_list: locationName,
          locationID: operation == "edit" && location_id ? location_id : 0,
        });
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  generateTimeStamp = (dates, time) => {
    var date = dates.getDate();
    var month = months[dates.getMonth()];
    var year = dates.getFullYear();
    var hours = time.getHours();
    var minutes = time.getMinutes();
    var seconds = time.getSeconds();

    var shortDateTime =
      month +
      " " +
      date +
      " " +
      year +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds;

    return ToTimeStamp(shortDateTime);
  };

  render() {
    const {
      location_list,
      brandList,
      isLoading,
      brandID,
      loader
    } = this.state;
    const { operation } = this.props;

    const { location, name } = this.props.reportList;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <Formik
                enableReinitialize
                initialValues={{
                  event_name: operation == "edit" && name ? name : "",
                  location: operation == "edit" && location ? location : "",
                }}
                validationSchema={CreateEventSchema(
                  t("AddNewEvent.tooShort"),
                  t("AddNewEvent.tooLong"),
                  t("AddNewEvent.eventNameErrorMessage"),
                  t("AddNewEvent.locationErrorMessage")
                )}
                onSubmit={(values) => {
                  this.handleSubmit(values);
                }}
              >
                {({ errors, touched, values, handleSubmit, setErrors }) => (
      <div className="d-block">
                  <div
                    className="modal fade show"
                    id="NewEvent"
                    tabindex="-1"
                    aria-labelledby="NewEventLabel"
                    aria-modal="true"
                    style={{display:"block"}}
                  >
                    <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header border-0 d-block pt-4 pb-0">
                          <div className="d-flex align-items-center justify-content-between mb-4">
                            {operation == "edit" ? (
                              <button
                                type="button"
                                onClick={() => this.props.handleEditeventModal(0)}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={() => this.props.handleNeweventModal()}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                              </button>
                            )}

                            {isLoading ? (
                              <button
                                type="button"
                                onClick={handleSubmit}
                                className="btn btn-primary-theme event"
                                data-dismiss="modal"
                              >
                                {t("AddNewEvent.btnSave")}
                                <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                  <span className="sr-only">
                                    {t("GeneralMessages.loading")}
                                  </span>
                                </div>
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={handleSubmit}
                                className="btn btn-primary-theme event"
                                data-dismiss="modal"
                              >
                                {t("AddNewEvent.btnSave")}
                              </button>
                            )}
                          </div>
                          <h5 className="modal-title">
                            {" "}
                            {operation == "edit"
                              ? t("AddNewEvent.EditEventLable")
                              : t("AddNewEvent.newEventLable")}
                          </h5>
                        </div>
                        <div className="modal-body overflow-x-hidden">
                          <small className="d-block mb-2">
                            {" "}
                            {t("AddNewEvent.type")}
                          </small>
                          <div className="radio-container d-flex overflow-x-auto mb-3 p-0">
                         
                            {brandList &&
                              brandList.map((brand, index) => {
                                const { icon, name, id } = brand;
                                return (
                                  <div
                                    key={index}
                                  
                                    className=" pointer custom-control custom-radio custom-control-box text-dark mr-3 mb-3"
                                  >
                                    <input
                                      type="radio"
                                      name="NeuesEreignis"
                                      id={id}
                                      onClick={() => {
                                        this.handleBrandSelection(brand);
                                      }}
                                      className="custom-control-input"
                                      checked={brandID == id ? true : false}
                                    />
                                    <label
                                      className="card-body py-3 px-2 mb-0 text-center custom-control-label"
                                      htmlFor={id}
                                    >
                                      <p className="mb-2">
                                        {icon && (
                                          <img
                                            src={`data:image/png;base64,${
                                              icon && icon
                                            }`}
                                            alt="brand_icon"
                                            style={{width:'32px',height:'32px'}}
                                          />
                                        )}
                                      </p>
                                      <p className="font-size-14 text-nowrap mb-0">
                                        {name && name}
                                      </p>
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                       
                         <div className="clearfix">
                         {loader && <CommonLoader/>}
                            <div className="form-row">
                              <div className="col-xl-12">
                                <div className="form-group">
                                  <label> {t("AddNewEvent.title")}</label>
                                  <Field
                                    type="text"
                                    name="event_name"
                                    placeholder={t("AddNewEvent.title")}
                                    className="form-control"
                                    required
                                  />
                                  <ErrorMessageFormik name="event_name" />
                                </div>
                              
                                <div className="form-group">
                                  <label> {t("AddNewEvent.location")}</label>
                                  <Form mode="structured">
                                    <Autocomplete
                                      name="location"
                                      placeholder={t(
                                        "AddNewEvent.selectLocation"
                                      )}
                                      className="custom-select"
                                      suggestions={location_list}
                                      required
                                    />
                                  </Form>
                                </div>
                              
                                <div className="form-row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label> {t("AddNewEvent.date")}</label>
                                      <div className="input-group">
                                        <DatePicker
                                          selected={this.state.startDate}
                                          onChange={this.handleChange}
                                          onClickOutside={this.openDatePicker}
                                          open={this.state.datePickerIsOpen}
                                          dateFormat="dd.MM.yyyy"
                                          locale="de"
                                          className="form-control combine-datepicker"
                                          disabled
                                        />
                                        <div
                                          className="input-group-append"
                                          onClick={this.openDatePicker}
                                        >
                                          <span className="input-group-text">
                                            <i className="zmdi zmdi-calendar"></i>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label> {t("AddNewEvent.time")}</label>
                                      <div className="input-group ">
                                        <DatePicker
                                          selected={this.state.startTime}
                                          onChange={this.handleTimeChange}
                                          showTimeSelect
                                          showTimeSelectOnly
                                          timeIntervals={15}
                                          timeCaption={t(
                                            "AddNewEvent.timeCaption"
                                          )}
                                          dateFormat="HH:mm"
                                          locale="de"
                                          onClickOutside={this.openTimePicker}
                                          open={this.state.timePickerIsOpen}
                                          className="form-control combine-timepicker pointer"
                                          disabled
                                        />
                                        
                                        <div
                                          className="input-group-append"
                                          onClick={this.openTimePicker}
                                        >
                                          <span className="input-group-text">
                                            <i className="zmdi zmdi-time"></i>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  <div className="modal-backdrop fade show"></div>
                  </div>
                )}
              </Formik>
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.eventListStateToProps,
        stateToProps.reporttListStateToProps,
        stateToProps.eventEventCountStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.eventListDispatchToProps,
    actions.asyncApiDispatchToProps,
    actions.reportListDispatchToProps,
    actions.editEventReportDispatchToProps,
  ])
)(withRouter(withTranslation()(AddNewEvent)));
