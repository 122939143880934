/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { getallpersonmailinglistgroup, MailDetailsQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID, getValueFromLocalStorage, saveDatatoLocalStorage } from "../../../util/utils";
import FabIcon from "../../CommanComponents/FabIcon";
import LoadingPage from "../../CommanComponents/LoadingPage";
import LogoutMenu from "../../CommanComponents/LogoutMenu";
import TopHeader from "../../CommanComponents/TopHeader";
import Footer from "../../Footer/FooterMobile";
import SearchMail from "../../Mail/MailListMobile/SearchMail";
import SearchMailListBaseOnPerson from "../../Mail/MailListMobile/SearchMailListBaseOnPerson";
import SearchPersonBaseonCompany from "../../Mail/MailListMobile/SearchPersonBaseonCompany";
import SearchPersonBaseOnMail from "../../Mail/MailListMobile/SearchPersonBaseOnMail";
import SearchPersonResult from "../../Mail/MailListMobile/SearchPersonResult";
import MailDetailItemGridView from "./MailDetailItemGridView";
import MailDetailItemListView from "./MailDetailItemListView";
import MailDetailPersonList from "./MailDetailPersonList";
import MailDetailsSetteliteSideMenu from "./MailDetailsSetteliteSideMenu";




class MailDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      mailList: [],
      activeIndex: 0,
      range: 0,
      headerMenuStatus: false,
      displayType: 0,
      listType:0,
      search: "",
      searchMailList: [],
      person_list:[],
      searchDisplayType: 0,
      searchPersonList: [],
      mailListBaseOnPerson: [],
      personListBaseOnMailID: [],
      personListBreadCumb: [],
      selectedPersonId: 0,
      groupIdBaseOnPersonDetail:0
    };
  }
  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  componentDidMount() {
    const mailDetailDisplayType = getValueFromLocalStorage("mailDetailDisplayType");

    if(mailDetailDisplayType==1){this.setState({displayType:1})}else{this.setState({displayType:0})}
    // if(mailDetailListType==1){this.setState({listType:1})}else{this.setState({listType:0})}
    const personDataTypeId = this.props.personDataTypeId;

    if (personDataTypeId && personDataTypeId != 0) {
    this.setState({
      listType:1
    })
    }else{
      this.setState({
        listType:0
      })
    }
    const mail_id = this.props.match.params.mail_id;

    
    apiCall(
      METHOD.QUERY,
      MailDetailsQuery(getUserID(), mail_id, ""),
      (data) => {
        this.props.getMailDetails(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );

    this.getPersonList()
  }

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  handleChangeMail = (mailId) => {
    this.props.history.push("/mail-detail/" + mailId);
    this.getMailList(mailId);
  };

  getMailList = (mailId) => {
    apiCall(
      METHOD.QUERY,
      MailDetailsQuery(getUserID(), mailId, ""),
      (data) => {
        this.props.getMailDetails(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  loader = (<LoadingPage key={0} />);

  handleBackStack = () => {
    let { from } = this.props.location.state || {
      from: { pathname: "/mail-list" },
    };
    this.props.history.push("/mail-list", { from });
    // window.location.assign("/mail-list");
  };

  handleActiveIndex = (mail_id, id) => {
    if (mail_id == id) {
      this.handleScroll(mail_id);
      return "active";
    } else {
      return "";
    }
  };

  handleScroll = (id) => {
    const index = this.props.mailDetail.mailing_list.findIndex(
      (mail) => mail.id == id
    );

    if (index > 3) {
      setTimeout(function () {
        var element = document.getElementById(`li_${id}`);
        var element1 = document.getElementById("ulID");
        var elementPosition = element.getBoundingClientRect();
        element1.scrollLeft = elementPosition.left;
      }, 500);
    }
  };
  setSearchMailList = (mailList) => {
    this.setState({
      searchMailList: mailList,
    },()=>{
      console.log("searchMailList",mailList)
    });
  };

  getPersonList=()=>{
    const mailId = this.props.match.params.mail_id;
    apiCall(
      METHOD.QUERY,
      getallpersonmailinglistgroup(getUserID(), mailId, ""),
      (data) => {
        console.log("personlist data",data);
        this.setState({
          person_list:data.person_list
        })
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  handleAlphabatice = (name, index, list) => {
    name = name.charAt(0).toUpperCase() + name.slice(1);
    let resulAlpha = "";
    if (index == 0) {
      resulAlpha = name.charAt(0);
    } else {
      let oldName = list[index - 1].name;
      oldName = oldName.charAt(0).toUpperCase() + oldName.slice(1);
      const digit1 = oldName.charAt(0);
      const digit2 = name.charAt(0);

      if (digit1 != digit2) {
        resulAlpha = digit2;
      } else {
        resulAlpha = "";
      }
    }
    return resulAlpha;
  };
  
  // Search method
  
  handleDisplayType =()=>{
    this.setState({
      searchDisplayType:0
    })
  }
  
  setSearchMailList = (personList) => {
    if (personList.length == 0) {
    }
    this.setState({
      searchPersonList: personList,
      searchDisplayType: personList.length == 0 ? 0 : 1,
    });
  };

  handleSearchDisplayTypeToInitialStage = () => {
    this.setState({
      searchDisplayType:0,
      search:'',
      value:''

    });
  };
  handleSearchDisplayType  = (searchDisplayType ) => {
    this.setState({
      searchDisplayType,
      search:'',
      value:''
    });
  };

  handleMailbaseOnPerson = (mailList) => {
    if (mailList.length == 0) {
      this.setState({
        mailListBaseOnPerson: [],
        searchPersonList: [],
        // searchDisplayType: 0,
      });
    } else {
      this.setState({
        // searchDisplayType: 0,
        mailListBaseOnPerson: mailList,
      });
    }
  };

  
  setmailFromPerson=(mailListBaseOnPerson,selectedPersonId)=>{
    console.log("mailListBaseOnPerson",mailListBaseOnPerson);
    console.log("selectedPersonId",selectedPersonId);
    if(mailListBaseOnPerson.length!=0){
      this.setState({
        mailListBaseOnPerson,
        searchDisplayType:2,
        selectedPersonId
      })
    }else{
      toast.info(
        this.props.t("GeneralMessages.noMailFound") );
    }
  
  }

  handlePersonListBaseonCompany = (personListBaseOnMailID)=>{
    this.setState({
      personListBaseOnMailID,
      searchDisplayType:4
    })
  }

  handleMailWiseResponse=(personListBaseOnMailID,personResponse,personListBreadCumb)=>{
    this.setState({
      personListBaseOnMailID,
      personListBreadCumb,
      personResponse,
      searchDisplayType:3
    })
  }
  
  
  render() {
    const {
      id,
      name,
      list_type_icon,
      child_element,
      child_list,
      total_person,
    } = this.props.mailDetail;
    // const mail_id = this.props.mailDetail.id;
    const { isLoading, displayType,searchMailList,listType,
     searchDisplayType,
      mailListBaseOnPerson,
      personListBaseOnMailID,
      personListBreadCumb,
      selectedPersonId,
      groupIdBaseOnPersonDetail } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <>
              <div className="header-bg header-yellow sticky-top">
                <div className="container">
                  <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                    <TopHeader />
                    <p
                      className="mb-0 ml-auto text-light pointer"
                      onClick={() => {
                        this.handleBackStack();
                      }}
                    >
                      {name && name}
                    </p>
                    <LogoutMenu />
                  </div>
                  <div className="clearfix">
                    <div className="d-flex justify-content-center">
                      <ul className="list-inline header-count-list text-center m-0">
                        <li className={listType==0?("list-inline-item pointer active"):("list-inline-item pointer")} 
                        onClick={()=>{

                          this.setState({
                            listType:0
                          })
                          this.props.handleApplyFilter('')
                        }}>
                          <p className="font-16 text-white m-0">
                            {t("MailDetailScreen.distributor")}
                          </p>
                          <h5 className="font-weight-bold text-white m-0">
                            {child_element && child_element}
                          </h5>
                        </li>
                        <li className={listType==1?("list-inline-item pointer active"):("list-inline-item pointer")} 
                         onClick={()=>{
                          this.setState({
                            listType:1
                          })
                        }}>
                          <p className="font-16 text-white m-0">
                            {t("MailDetailScreen.person")}
                          </p>
                          <h5 className="font-weight-bold text-white m-0">
                            {total_person && total_person}
                          </h5>
                        </li>
                      </ul>
                    </div>
                    <SearchMail
                    handleMailbaseOnPerson={(mailList) => {
                      this.handleMailbaseOnPerson(mailList);
                    }}
                    handlePersonListBaseonCompany={(personList)=>{
                      this.handlePersonListBaseonCompany(personList)
                    }}

                    setmailFromPerson={(personList, selectedPersonId) => {
                      this.setmailFromPerson(personList, selectedPersonId);
                    }}
                    searchArray={this.setSearchMailList}
                    handleSearchDisplayType={(searchDisplayType) => {
                      this.handleSearchDisplayType(searchDisplayType);
                    }}
                  />
                  </div>
                </div>
              </div>

              <div className="middle-section">
                {isLoading && this.loader}
              
            
                   
                {listType==1 && searchDisplayType==0 && <MailDetailPersonList 
                 filterValue={this.props.filterValue||''}
                 personDataTypeId={this.props.personDataTypeId||0}
                changeRouteStatus={this.props.changeRouteStatus}/>}
                <div className="container my-3 z-index-99">
                    {listType==0 && searchDisplayType==0 && <React.Fragment>
                       <div className="d-flex justify-content-between mb-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/mail-list"> {t("mailDetail.home")}</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          {name && name}
                        </li>
                      </ol>
                    </nav>
                    <ul className="list-inline grid-view-list m-0">
                      <li className={displayType==0?("list-inline-item mr-3 active"):("list-inline-item mr-3")}>
                        <a
                          className="pointer"
                          href="javascript;:"
                          onClick={(e) => {
                            e.preventDefault();
                            saveDatatoLocalStorage("mailDetailDisplayType",0);
                            this.setState({ displayType: 0 });
                          }}
                        >
                          <i className="fa fa-list fa-lg text-muted"></i>
                        </a>
                      </li>
                      <li className={displayType==1?("list-inline-item mr-3 active"):("list-inline-item mr-3")}>
                        <a
                          className="pointer"
                          href="javascript;:"
                          onClick={(e) => {
                            e.preventDefault();
                            saveDatatoLocalStorage("mailDetailDisplayType",1);
                            this.setState({ displayType: 1 });
                          }}
                        >
                          <i className="fa fa-th-large fa-lg text-muted"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  {displayType == 0 && (
                    <div className="d-flex">
                      <div className="icon-box-style2 mb-3">
                        <div className="icon-box yellow-bg d-flex">
                          <div className="d-flex align-items-center justify-content-center w-100">
                            <div className="rounded-50 bg-light">
                              <img
                                src={`data:image/png;base64,${
                                  list_type_icon && list_type_icon
                                }`}
                                alt="ico"
                                style={{ width: "32px" }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="ml-100p m-3 clearfix d-flex align-items-center flex-fill">
                          <div className="align-self-center">
                            <h5 className="m-0 font-18 d-block">
                              {name && name}
                            </h5>
                            <p className="text-muted font-12 m-0">
                              {child_element && child_element}{" "}
                              {t("mailDetail.distributorLable")}
                            </p>
                          </div>
                          <div className="align-self-center ml-auto">
                            <i className="zmdi zmdi-chevron-right zmdi-hc-fw font-26 text-dark"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {searchMailList.length != 0 ? (
                    <>
                      {displayType == 0 ? (
                        <div className="container my-3">
                          {searchMailList &&
                            searchMailList.map((item, index) => {
                              return (
                                <MailDetailItemListView
                                  key={index}
                                  item={item}
                                  id={id}
                                />
                              );
                            })}
                        </div>
                      ) : (
                        <div className="form-row container my-3">
                          {searchMailList &&
                            searchMailList.map((item, index) => {
                              return (
                                <MailDetailItemGridView
                                  key={index}
                                  item={item}
                                  id={id}
                                />
                              );
                            })}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {" "}
                      {displayType == 0 ? (
                        <div className="container my-3">
                          {child_list &&
                            child_list.map((item, index) => {
                              return (
                                <MailDetailItemListView
                                  key={index}
                                  item={item}
                                  id={id}
                                />
                              );
                            })}
                        </div>
                      ) : (
                        <div className="form-row  my-3">
                          {child_list &&
                            child_list.map((item, index) => {
                              return (
                                <MailDetailItemGridView
                                  key={index}
                                  item={item}
                                  id={id}
                                />
                              );
                            })}
                        </div>
                      )}
                    </>
                  )}
  </React.Fragment>}
              
                 
              
{searchDisplayType == 1 && (
                 <SearchPersonResult 
                      suggetionList={this.state.searchPersonList}
                      handlePersonListBaseonCompany={(personList)=>{
                        this.handlePersonListBaseonCompany(personList)
                      }}
                      setmailFromPerson={(personList, selectedPersonId) => {
                        this.setmailFromPerson(
                          personList,
                          selectedPersonId
                        );
                      }}/>
                )}


                {searchDisplayType == 2 && (
                  <div className="container my-2">
                    {mailListBaseOnPerson.map((data, index) => {
                      return (
                        <SearchMailListBaseOnPerson
                          data={data}
                          key={index}
                          selectedPersonId={selectedPersonId}
                          handleMailWiseResponse={(
                            personListBaseOnMailID,
                            personResponse,
                            personListBreadCumb,
                            groupIdBaseOnPersonDetail
                          ) => {
                            this.handleMailWiseResponse(
                              personListBaseOnMailID,
                              personResponse,
                              personListBreadCumb,
                              groupIdBaseOnPersonDetail
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                )}

                {searchDisplayType == 3 && (
                  <div className="container my-2">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          {t("mailDetail.home")}
                        </li>
                        <li
                          className="breadcrumb-item pointer"
                          aria-current="page"
                          onClick={() => {
                            // const mailDetailId = breadcrumb[0]?.id
                            // this.props.history.push("/mail-detail/" + mailDetailId);
                          }}
                        >
                          {personListBreadCumb[0]?.name}
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          {personListBreadCumb[1]?.name}
                        </li>
                      </ol>
                    </nav>

                    {personListBaseOnMailID.map((data, index) => {
                      return (
                        <SearchPersonBaseOnMail
                          handleSearchDisplayType={(searchDisplayType) => {
                            this.handleSearchDisplayType(searchDisplayType);
                          }}
                          data={data}
                          key={index}
                          personResponse={this.state.personResponse}
                          groupIdBaseOnPersonDetail={groupIdBaseOnPersonDetail}
                        />
                      );
                    })}
                  </div>
                )}


{searchDisplayType == 4 && (
                  <div className="container my-2">
                    {personListBaseOnMailID.map((data, index) => {
                      return (
                        <SearchPersonBaseonCompany
                          handleSearchDisplayType={(searchDisplayType) => {
                            this.handleSearchDisplayType(searchDisplayType);
                          }}
                          data={data}
                          key={index}
                          personResponse={this.state.personResponse}
                          groupIdBaseOnPersonDetail={groupIdBaseOnPersonDetail}
                        />
                      );
                    })}
                  </div>
                )}


                  <FabIcon
                    color="rounded-yellow"
                    openSlider={this.openSlider}
                  />
                </div>
              </div>
              <MailDetailsSetteliteSideMenu
                closeSlider={this.closeSlider}
                headerMenuStatus={this.state.headerMenuStatus}
                changeRouteStatus={this.props.changeRouteStatus}
              />
              <Footer type="4" />
            </>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.mailDetailStateToProps, stateToProps.mailListStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.reportListDispatchToProps,
    actions.mailListDispatchToProps,
    actions.mailDetailsDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(MailDetails)));
