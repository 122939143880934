/* eslint eqeqeq: 0 */
import update from "immutability-helper";
import React, { Component } from "react";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import MultiBackend, { TouchTransition } from "react-dnd-multi-backend";
import Preview from "react-dnd-preview";
import TouchBackend from "react-dnd-touch-backend";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { changegrouporder, GroupListQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";
import LoadingPage from "../../CommanComponents/LoadingPage";
import RearrangePersonItem from "./RearrangeGroupItem";

const HTML5toTouch = {
  backends: [
    {
      backend: HTML5Backend,
    },
    {
      backend: TouchBackend,
      options: { enableMouseEvents: true }, // Note that you can call your backends with options
      preview: false,
      transition: TouchTransition,
    },
  ],
};

class RearrangeGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitLoader: false,
      end: 10,
      start: 0,
      hasMore: true,
      group_list: [],
    };
  }

  moveCard = (dragIndex, hoverIndex) => {
    const { group_list } = this.state;
    const dragCard = group_list[dragIndex];
    this.setState(
      update(this.state, {
        group_list: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        },
      })
    );
  };
  previewGenerator = (itemType, item, style) => {
    return <div as="h1" style={style}></div>;
  };

  loadItem = () => {
    const { end, isLoading, start, hasMore } = this.state;

    if (!isLoading && hasMore) {
      this.setState({
        isLoading: true,
      });

      //static
      apiCall(
        METHOD.QUERY,
        GroupListQuery(getUserID(), start, end, 0, "", 2),
        (data) => {
          this.handleGroupDetailApiResponse(data);
        },
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };
  loader = (<LoadingPage key={0} />);
  handleGroupDetailApiResponse = (data) => {
    const { end, start } = this.state;
    this.setState({
      isLoading: true,
    });

    if (data.is_last == 1) {
      this.setState({
        hasMore: false,
      });
    }

    this.setState((prevState) => {
      return {
        isLoading: false,
        start: start + end,
        group_list: [...prevState.group_list, ...data.group_list],
      };
    });
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        ...this.state,
        isLoading: status,
      };
    });
  };

  loader = (<LoadingPage key={0} />);

  handleSubmit = () => {
    let reArrangeArray = [];
    const { group_list } = this.state;

    group_list.map((item) => {
      return reArrangeArray.push({ id: item.id });
    });

    let newArray = JSON.stringify(JSON.stringify(reArrangeArray));
    apiCall(
      METHOD.QUERY,
      changegrouporder(getUserID(), newArray),
      (data) => {
        toast.success(this.props.t(data.message));
        this.props.changeStatus(0);
      },
      (isLoading) => {
        this.setState({ submitLoader: isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  render() {
    const { end, submitLoader, hasMore, group_list } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <div className="header-bg header-blue sab-header1 sticky-top">
                <div className="container pb-4">
                  <div className="py-2 d-flex align-items-center">
                    <span
                      className="pointer"
                      onClick={() => this.props.changeStatus(0)}
                    >
                      <i className="zmdi zmdi-close zmdi-hc-fw font-30 text-light"></i>
                    </span>

                    {submitLoader ? (
                      <div className="btn btn-light font-13 ml-auto pointer disabled">
                        {t("reArrangePerson.save")}
                        <div className="spinner-border spinner-border-sm ml-1 text-blue">
                          <span className="sr-only">
                            {t("GeneralMessages.loading")}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="btn btn-light font-13 ml-auto pointer"
                        onClick={this.handleSubmit}
                      >
                        {t("reArrangePerson.save")}
                      </div>
                    )}
                  </div>

                  <div className="text-center mt-2  text-light">
                    <h2 className="m-0 text-light">
                      {t("GroupListScreen.reArrangeGroupTitle")}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="middle-section sab-middle-section1">
                <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                  <InfiniteScroll
                    pageStart={end}
                    loadMore={this.loadItem}
                    hasMore={hasMore}
                    useWindow={false}
                    loader={this.loader}
                  >
                    <div className="container my-4 00z-index-99">
                      {!!group_list.length &&
                        group_list.map((item, index) => {
                          const { id, name, description } = item;
                          return (
                            <RearrangePersonItem
                              id={id}
                              index={index}
                              name={name}
                              description={description}
                              moveCard={this.moveCard}
                              key={index}
                            />
                          );
                        })}
                    </div>
                  </InfiniteScroll>

                  <Preview generator={this.previewGenerator} />
                </DndProvider>
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.groupListStateToProps],
      state
    ),
  actions.appMapDispatchToProps([actions.groupDetailPersonListDispatchToProps])
)(withTranslation()(withRouter(RearrangeGroup)));
