import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";

class MailingItemGridView extends Component {
  handelMailItemClick = (mail_id_range) => {
    this.props.history.push("/mail-detail/" + mail_id_range);
  };

  render() {
    const { data, index } = this.props;
    const { id, name, child_element, list_type_icon } = data;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="col-lg-4 mb-4" key={index}>
            <a
              href="javascript:;"
              className="card list-card"
              onClick={(e)=>{
                e.preventDefault()
                this.props.history.push("/mail-detail/"+id)
              }}
            >
           <div className="card-body p-0">
              <div className="media">
              <div className="bg-yellow left-icon-box-medium d-flex align-items-center justify-content-center text-center">
                      <div className="circle big m-0">
                        <img
                          src={`data:image/png;base64,${
                            list_type_icon && list_type_icon
                          }`}
                          alt="ico"
                          style={{ height: "20px", width: "20px" }}
                        />
                      </div>
                    </div>
            
                <div className="clearfix p-3" style={{textAlign:'start'}}>
                  <h6 className="mb-1">{name}</h6>
                  <small className="text-muted text-break">
                  {child_element && child_element} {t("mailDetail.receiver")}
                  </small>
                </div>
               </div>
               </div>
            </a>
          </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(withRouter(MailingItemGridView));
