/* eslint eqeqeq: 0 */
import { Field, Formik } from "formik";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { CreateGroupQuery, GrouptypeQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";
import { CreateGroupSchema } from "../../../util/validationSchema";
import ErrorMessageFormik from "../../CommanComponents/ErrorMessageFormik";


class AddNewGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: false,
      brandList: [],
      groupType: "",
      groupImage: "",
      groupID: 0,
      isLoading: false,
      groupList: [],
      parentGroupID: 0,
    };
  }

  handleGroupSelection = (item) => {
    const { name, icon, id } = item;
    this.setState(() => {
      return {
        ...this.state,
        status: false,
        groupType: name,
        groupImage: icon,
        groupID: id,
      };
    });
  };

  closeGroupDialogue = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: false,
      };
    });
  };

  openGroupDialogue = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
      };
    });
  };

  handleAfterCreateApiCall = (data, parentGroupID) => {
    toast.success(this.props.t(data.message));
    let group = {
      id: data.id,
      name: data.name,
      group_type_id: data.group_type_id,
      group_type_icon: data.group_type_icon,
      group_creator_name: data.group_creator_name,
      description: data.description,
      person: 0,
    };

    if (parentGroupID == 0) {
      this.props.addnewGroup([group]);
    }
    this.props.handleAddModal(true);
  };

  handleAfterBrandApiCall = (data) => {
    let brandImageDefault, brandNameDefault, groupID;
    if (data.group_type_list && data.group_type_list.langth !== 0) {
      brandNameDefault = data.group_type_list[0].name;
      brandImageDefault = data.group_type_list[0].icon;
      groupID = data.group_type_list[0].id;
    }
    const {
      event_type_icon,
      event_type_id,
      event_type_name,
    } = this.props.reportList;

    const { operation } = this.props;

    this.setState(() => {
      return {
        ...this.state,
        brandList: data.group_type_list,
        groupType:
          operation == "edit" && event_type_name
            ? event_type_name
            : brandNameDefault,
        groupImage:
          operation == "edit" && event_type_icon
            ? event_type_icon
            : brandImageDefault,
        groupID: operation == "edit" && event_type_id ? event_type_id : groupID,
      };
    });
  };

  handleSubmit = (values) => {
    const { group_name, description } = values;
    const { parentGroupID, groupID } = this.state;

    apiCall(
      METHOD.QUERY,
      CreateGroupQuery(
        getUserID(),
        groupID,
        group_name,
        description,
        parentGroupID
      ),
      (data) => this.handleAfterCreateApiCall(data, parentGroupID),
      (isLoading) =>
        this.setState(() => {
          return { isLoading: isLoading };
        }),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  componentDidMount() {
    apiCall(
      METHOD.QUERY,
      GrouptypeQuery(getUserID()),
      (data) => this.handleAfterBrandApiCall(data),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  handleParentGroup = (e) => {
    this.setState({ parentGroupID: e.target.value });
  };

  render() {
    const { brandList, status, groupImage, groupType, isLoading,groupID } = this.state;
    const { operation } = this.props;

    // const { event_datetime, event_type_icon, id, name } = this.props.reportList;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <Formik
                enableReinitialize
                initialValues={{ group_name: "", description: "" }}
                validationSchema={CreateGroupSchema(
                  t("GroupListScreen.tooShort"),
                  t("GroupListScreen.tooLong"),
                  t("GroupListScreen.groupNameErrorMessage"),
                  t("GroupListScreen.descriptionErrorMessage")
                )}
                onSubmit={(values) => {
                  this.handleSubmit(values);
                }}
              >
                {({ errors, touched, values, handleSubmit, setErrors }) => (
                 <div className="d-block">
                 <div
                   className="modal fade show"
                   id="NewEvent"
                   tabindex="-1"
                   aria-labelledby="NewEventLabel"
                   aria-modal="true"
                   style={{ display: "block" }}
                 >
                   <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                     <div className="modal-content text-left">
                       <div className="modal-header border-0 d-block pt-4 pb-0">
                         <div className="d-flex align-items-center justify-content-between mb-4">
                           <button
                             type="button"
                             onClick={() =>{this.props.handleAddModal(false)}}
                             className="close"
                             data-dismiss="modal"
                             aria-label="Close"
                           >
                             <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                           </button>
                           <h5 className="modal-title">
                         {t("GroupListScreen.addGroup")}
                         </h5>
                           {isLoading ? (
                             <div className="btn btn-primary-theme group pointer">
                               {t("AdminAddUser.save")}
                               <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                 <span className="sr-only">
                                   {t("GeneralMessages.loading")}
                                 </span>
                               </div>
                             </div>
                           ) : (
                             <div
                               className="btn btn-primary-theme group pointer"
                               onClick={handleSubmit}
                             >
                               {t("AdminAddUser.save")}
                             </div>
                           )}
                         </div>
                      
                       </div>
                       <div className="modal-body">
                        <form>
                          <div className="form-group">
                            <small className="d-block mb-2">
                            {t("GroupListScreen.type")}
                          </small>
                           
                            <div className="radio-container d-flex overflow-x-auto mb-3 p-0">
                            {brandList &&
                              brandList.map((brand, index) => {
                                const { icon, name, id } = brand;
                                return (
                                  <div
                                    key={index}
                                  
                                    className="custom-control custom-radio custom-control-box text-dark mr-3 mb-3"
                                  >
                                    <input
                                      type="radio"
                                      name="NeuesEreignis"
                                      id={id}
                                      onClick={() => {
                                        this.handleGroupSelection(brand);
                                      }}
                                      className="custom-control-input"
                                      checked={groupID == id ? true : false}
                                    />
                                    <label
                                      className="card-body py-3 px-2 mb-0 text-center custom-control-label"
                                      htmlFor={id}
                                    >
                                      <p className="mb-2">
                                        {icon && (
                                          <img
                                            src={`data:image/png;base64,${
                                              icon && icon
                                            }`}
                                            alt="brand_icon"
                                            style={{width:'24px',height:'24px'}}
                                          />
                                        )}
                                      </p>
                                      <p className="font-size-14 text-nowrap mb-0">
                                        {name && name}
                                      </p>
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                     
                            {/* <div
                              className="icon-drop-bg pointer"
                              onClick={this.openGroupDialogue}
                            >
                              <div className="rounded-circle ">
                                <img
                                  src={`data:image/png;base64,${
                                    groupImage && groupImage
                                  }`}
                                  alt="ico"
                                />
                              </div>
                              <span className="font-12">
                                {groupType && groupType}
                              </span>
                            </div>

                            <BrandTypeList
                              closeBrandDialogue={this.closeGroupDialogue}
                              handleBrandSelection={this.handleGroupSelection}
                              status={status}
                              BrandList={brandList}
                            /> */}


                          </div>

                          {/* <div className="form-group">
                            <div className="form-group">
                              <label
                                htmlFor="exampleInputPassword1"
                                className="font-14"
                              >
                                {t("GroupListScreen.parentGroup")}
                              </label>

                              <select
                                className="custom-select"
                                onChange={this.handleParentGroup}
                              >
                                <option disabled selected>
                                  {t("GroupListScreen.selectParentGroup")}
                                </option>
                                {groupList &&
                                  groupList.map((item, index) => {
                                    const { name, description, id } = item;
                                    return (
                                      <option key={index} value={id}>
                                        {name && name} {" - "}
                                        {description && description}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                            <ErrorMessageFormik name="group" />
                          </div> */}

                          <div className="form-group">
                            <label className="font-size-14">
                              {" "}
                              {t("GroupListScreen.titleInternal")}
                            </label>
                            <Field
                              type="text"
                              name="group_name"
                              placeholder={t("GroupListScreen.titleInternal")}
                              className="form-control"
                              required
                            />
                            <ErrorMessageFormik name="group_name" />
                          </div>

                          <div className="form-group">
                            <label className="font-size-14">
                              {" "}
                              {t("GroupListScreen.description")}
                            </label>
                            <Field
                              as="textarea"
                              name="description"
                              placeholder={t("GroupListScreen.description")}
                              className="form-control"
                              required
                            />
                            <ErrorMessageFormik name="description" />
                          </div>
                        </form>
                  </div>
                    </div>
                        </div>
                      </div>
                      <div className="modal-backdrop fade show"></div>
                    </div>
                )}
              </Formik>
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.eventListStateToProps,
        stateToProps.reporttListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupListDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(AddNewGroup)));
