/* eslint eqeqeq: 0 */
import React, { Component, Suspense } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import {
  addGeneralGroupToMaillingList,
  groupallpersonlist,
  GroupListQuery,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
import LoadingPage from "../../CommanComponents/LoadingPage";
import SearchGeneralGroup from "./SearchGeneralGroup";
import ReactTooltip from "react-tooltip";

class GeneralGGroupList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      group_list: [],
      start: 0,
      end: 20,
      hasMore: true,
      isLoading: false,
      addGroupLoading: false,
      searchGroupList: [],
      subGroupId: 0,
      breadcrumb: [],
      person_list:[]
    };
  }

  handleLoader = (status) => {
    this.setState(() => {
      return {
        ...this.state,
        isLoading: status,
      };
    });
  };

  loadGeneralGroupList = () => {
    const { start, end, hasMore, isLoading, subGroupId } = this.state;
    if (!isLoading && hasMore) {
      this.setState({
        isLoading: true,
      });

      apiCall(
        METHOD.QUERY,
        GroupListQuery(getUserID(), start, end, subGroupId, "", 0),
        (data) => this.handleGeneralListResponse(data),
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  setSearchArrayList = (arrayList) => {
    this.setState({
      searchGroupList: arrayList,
    });
  };

  handleGeneralListResponse = (data) => {
    const { start, end } = this.state;
    if (data.is_last == 1) {
      this.setState({ hasMore: false });
    }

    this.setState((prevState) => {
      return {
        ...this.state,
        start: start + end,
        group_list: [...prevState.group_list, ...data.group_list],
        isLoading: false,
        breadcrumb: data.breadcrumb,
      };
    });
  };

  
  handleAddClick = (group_id) => {
    const mail_list_id = this.props.match.params.mail_person_id;
    const fields = mail_list_id.split("-");
    const list_id = fields[0];
    apiCall(
      METHOD.QUERY,
      addGeneralGroupToMaillingList(getUserID(), group_id, list_id),
      (data) => {
        toast.success(this.props.t(data.message));
        this.props.changeRouteStatus(0);
      },
      (isLoading) => {
        this.setState({
          addGroupLoading: isLoading,
        });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  
  getPersonList = (group_id) => {
    apiCall(
      METHOD.QUERY,
      groupallpersonlist(getUserID(), group_id, ""),
      (data) => {
        this.setState({
          person_list: data.person_list,
        });
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  
  handleGroupClick = (group_id) => {
    this.setState(
      {
        subGroupId: group_id,
        group_list: [],
        start: 0,
        hasMore: true,
      },
      () => {
        this.loadGeneralGroupList();
      }
    );
  };

  loader = (<LoadingPage key={0} />);

  render() {
    const {
      end,
      hasMore,
      group_list,
      addGroupLoading,
      searchGroupList,
      subGroupId,
      breadcrumb,
      person_list
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <div className="header-bg header-yellow sab-header1 sticky-top">
                <div className="container pb-2">
                  <div className="py-1 d-flex align-items-center">
                    <span
                      className="pointer"
                      onClick={() => this.props.changeRouteStatus(0)}
                    >
                      <i className="zmdi zmdi-close zmdi-hc-fw font-30 text-light"></i>
                    </span>
                  </div>

                  <div className="text-center  text-light">
                    <h2 className="m-0 text-light">
                      {t("generalGroupList.addGroup")}
                    </h2>
                  </div>
                  <SearchGeneralGroup searchArray={this.setSearchArrayList} />
                </div>
              </div>

              {subGroupId != 0 && (
                <div className="d-flex justify-content-between align-items-center my-4 ml-2">
                  <p className="font-12 m-0">
                    <React.Fragment>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState(
                            {
                              start: 0,
                              hasMore: true,
                              group_list: [],
                              subGroupId: 0,
                            },
                            () => {
                              //  this.loadGeneralGroupList()
                            }
                          );
                        }}
                        className="text-muted"
                        data-tip={"Start"}
                      >
                        {t("GroupListScreen.start")}
                        <ReactTooltip place="top" type="info" effect="float" />
                      </a>
                      {" > "}
                    </React.Fragment>
                    {breadcrumb &&
                      breadcrumb.map((item, index) => {
                        if (index == 0) {
                          return (
                            <React.Fragment key={index}>
                              <a
                                href="javascript;:"
                                className="text-muted"
                                data-tip={item.name}
                                onClick={(e) => {
                                  e.preventDefault();

                                  this.setState(
                                    {
                                      start: 0,
                                      hasMore: true,
                                      group_list: [],
                                      subGroupId: item.id,
                                    },
                                    () => {
                                      //   this.loadGeneralGroupList()
                                    }
                                  );
                                }}
                              >
                                <ReactTooltip
                                  place="top"
                                  type="info"
                                  effect="float"
                                />
                                {item.name}
                              </a>
                              {" > "}
                            </React.Fragment>
                          );
                        } else if (
                          index != 0 &&
                          index != breadcrumb.length - 1
                        ) {
                          return (
                            <React.Fragment key={index}>
                              <a
                                href="javascript;:"
                                className="text-muted"
                                data-tip={item.name}
                                onClick={(e) => {
                                  e.preventDefault();

                                  this.setState(
                                    {
                                      start: 0,
                                      hasMore: true,
                                      group_list: [],
                                      subGroupId: item.id,
                                    },
                                    () => {
                                      //   this.loadGeneralGroupList()
                                    }
                                  );
                                }}
                              >
                                <ReactTooltip
                                  place="top"
                                  type="info"
                                  effect="float"
                                />
                                [...]
                              </a>
                              {" > "}
                            </React.Fragment>
                          );
                        } else if (index == breadcrumb.length - 1) {
                          return (
                            <React.Fragment key={index}>
                              {item.name && item.name}
                            </React.Fragment>
                          );
                        }
                      })}
                  </p>
                </div>
              )}

              <div className="middle-section sab-middle-section1 p-2">
                {addGroupLoading && this.loader}

                {searchGroupList && searchGroupList.length != 0 ? (
             <div className="custom-accordian bg-border-accordion p-2" >
                    {searchGroupList &&
                      searchGroupList.map((item, index) => {
                        const { id, name, has_child, person ,sub_group} = item;
                        return (
                          <Accordion
                        allowZeroExpanded={true}
                        onChange={(res) => {
                          if(has_child!=0){
                            res.length > 0 && this.handleGroupClick(id);
                          }else{
                            res.length > 0 && this.getPersonList(id);
                          }
      
                        }}
                        key={index}
                      >
                        <AccordionItem className="bg-border blue">
                          <AccordionItemButton>
                            {name && name}
      
                            {has_child == 0 ? (
                              <p className="text-muted font-12 m-0">
                                {person && person}{" "}
                                {person == 1
                                  ? t("GroupListScreen.singularPerson")
                                  : t("GroupListScreen.pluralPerson")}
                              </p>
                            ) : (
                              <p className="text-muted font-12 m-0">
                                {sub_group && sub_group}{" "}
                                {t("GroupListScreen.subGroup")}
                              </p>
                            )}
      
                            {has_child==0 && <div className="align-self-center accordian-add-btn">
                                          {/* <label className="checkbox-container">
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>{
                                                this.handleCheckBox(e, id)
                                              }
                                              }
                                              checked={selectedGroupList.includes(id)}
                                            />
                                            <span className="checkmark"></span>
                                          </label> */}

                                  <a
                                  href="#"
                                  className="text-dark"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleAddClick(id)
                                  }}
                                >
                                  <i className="fa fa-plus pointer"  ></i>
                                </a>
                                        </div>}
                           
                          </AccordionItemButton>
                          <Suspense fallback={<LoadingPage />}>
                            <AccordionItemPanel>
                              {person_list && person_list.map((perosn,index)=>{
                                const {full_name,email,is_valid_email} = perosn
                                return( 
                               <div className="left-icon-box small d-flex" key={index}>
                                      <div
                                        className="circle"
                                        style={{ backgroundColor: "transparent", lineHeight:'55px' }}
                                      >
                                        {is_valid_email && is_valid_email == 1 ? (
                                          <i className="zmdi zmdi-check-circle zmdi-hc-fw font-size-22 text-success"></i>
                                        ) : (
                                          <i className="zmdi zmdi-alert-circle zmdi-hc-fw font-size-22 text-blue"></i>
                                        )}
                                      </div>
                                    <div className="media-body pt-3 px-4">
                                      <h6 className="font-size-15 mb-1">{full_name && full_name}</h6>
                                      <p className="text-muted font-12 m-0">{email && email}</p>
                                    </div>
                              </div>)
                              })}
                            </AccordionItemPanel>
                          </Suspense>
                        </AccordionItem>
                      </Accordion>
                    );
                      })}
                  </div>
                ) : (
                  <InfiniteScroll
                    pageStart={end}
                    loadMore={this.loadGeneralGroupList}
                    hasMore={hasMore}
                    useWindow={false}
                    loader={this.loader}
                  >
                            <div className="custom-accordian bg-border-accordion p-2" >
                            {group_list &&
                              group_list.map((item, index) => {
                                const { name, id,  sub_group,person,has_child } = item;
                                return (
                     <Accordion
                        allowZeroExpanded={true}
                        onChange={(res) => {
                          if(has_child!=0){
                            res.length > 0 && this.handleGroupClick(id);
                          }else{
                            res.length > 0 && this.getPersonList(id);
                          }
      
                        }}
                        key={index}
                      >
                        <AccordionItem className="bg-border blue">
                          <AccordionItemButton>
                            {name && name}
      
                            {has_child == 0 ? (
                              <p className="text-muted font-12 m-0">
                                {person && person}{" "}
                                {person == 1
                                  ? t("GroupListScreen.singularPerson")
                                  : t("GroupListScreen.pluralPerson")}
                                {" / "}
                                {t("GroupListScreen.associatedLable")}
                              </p>
                            ) : (
                              <p className="text-muted font-12 m-0">
                                {sub_group && sub_group}{" "}
                                {t("GroupListScreen.subGroup")}
                              </p>
                            )}
      
                            {has_child==0 && <div className="align-self-center accordian-add-btn">
                                  <a
                                  href="#"
                                  className="text-dark"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleAddClick(id)
                                  }}
                                >
                                  <i className="fa fa-plus pointer"  ></i>
                                </a>
                                        </div>}
                           
                          </AccordionItemButton>
                          <Suspense fallback={<LoadingPage />}>
                            <AccordionItemPanel>
                              {person_list && person_list.map((perosn,index)=>{
                                const {full_name,email,is_valid_email} = perosn
                                return( 
                               <div className="left-icon-box small d-flex" key={index}>
                                      <div
                                        className="circle"
                                        style={{ backgroundColor: "transparent", lineHeight:'55px' }}
                                      >
                                        {is_valid_email && is_valid_email == 1 ? (
                                          <i className="zmdi zmdi-check-circle zmdi-hc-fw font-size-22 text-success"></i>
                                        ) : (
                                          <i className="zmdi zmdi-alert-circle zmdi-hc-fw font-size-22 text-blue"></i>
                                        )}
                                      </div>
                                    <div className="media-body pt-3 px-4">
                                      <h6 className="font-size-15 mb-1">{full_name && full_name}</h6>
                                      <p className="text-muted font-12 m-0">{email && email}</p>
                                    </div>
                              </div>)
                              })}
                            </AccordionItemPanel>
                          </Suspense>
                        </AccordionItem>
                      </Accordion>
                               );
                              })}
                          </div>
                  </InfiniteScroll>
                )}
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(withRouter(GeneralGGroupList));
