/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { EventListQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { checkUserPermission, getUserID } from "../../../util/utils";
import CommonLoader from "../../CommanComponents/CommonLoader";
import LoadingPage from "../../CommanComponents/LoadingPage";
import FooterDesktop from "../../Footer/FooterDesktop";
import HeaderDesktop from "../../Header/HeaderDesktop";
import AddNewEvent from "./AddNewEvent";
import ActiveEventList from "./ActiveEventList";
import ArchiveEventList from "./ArchiveEventList";

class EventList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventType: 1,
      search: "",
      searchEvent: [],
      searchStart: 0,
      searchEnd: 10,
      searchHasMore: true,
      event_create_permission:false
    };
    this.searchRef = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    const event_create_permission = checkUserPermission("create_event");
    const readEventPermission = checkUserPermission("read_event");
    this.setState({
      event_create_permission,
      readEventPermission
    })

  }
  handleLoader = (status) => {
    this.setState(() => {
      return {
        ...this.state,
        isLoading: status,
      };
    });
  };

  handleSearchResponse = (data) => {
    const { searchStart, searchEnd } = this.state;

    if (data.is_last === 1) {
      this.setState(() => {
        return {
          ...this.state,
          searchHasMore: false,
        };
      });
    }

    this.setState(
      (prevState) => {
        return {
          ...this.state,
          searchStart: searchStart + searchEnd,
          searchEvent: [...prevState.searchEvent, ...data.event_list],
          isLoading: false,
        };
      },
      () => {
        if (this.state.searchEvent.length === 0) {
          toast.info(
            this.props.t("GeneralMessages.searchErrorMessage") +
              "'" +
              this.state.search +
              "'"
          );
        }
      }
    );
  };
  
  loader = (<CommonLoader />);

  handleEventType = (type) => {
    this.setState(() => {
      return {
        ...this.state,
        eventType: type,
      };
    });
  };

  changeEventSearch = (e) => {
    const searchValue = e.target.value;

    this.setState({
      search: searchValue,
      searchEvent: [],
      searchHasMore: true,
      searchStart: 0,
      searchEnd: 10,
    });
  };

  clearSearch = () => {
    this.searchRef.current.value = "";

    this.setState(() => {
      return {
        ...this.state,
        search: "",
        searchEvent: [],
        searchHasMore: true,
        searchStart: 0,
        searchEnd: 10,
        eventOperationType:'',
      };
    });
  };

  searchApi = () => {
    const {
      search,
      isLoading,
      searchStart,
      searchEnd,
      eventType,
      searchHasMore,
    } = this.state;
    if (!isLoading && searchHasMore) {
      this.setState({
        isLoading: true,
      });
      apiCall(
        METHOD.QUERY,
        EventListQuery(getUserID(), searchStart, searchEnd, eventType, search),
        (data) => this.handleSearchResponse(data),
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: false,
      };
    });
  };

  handleBackStack = () => {
    let { from } = this.props.location.state || { from: { pathname: "/home" } };
    this.props.history.push(from);
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: false,
      };
    });
  };
  handleNeweventModal=(isFromSave)=>{
   
    this.setState((prev)=>{
      return({
        newEventModalOpen:!prev.newEventModalOpen
      })
    },()=>{
      if(this.state.messageModalOpen){
        document.body.classList.add("modal-open");
      }else{
        if(isFromSave){
          window.location.reload();
        }
        document.body.classList.remove("modal-open");
      }
    })
  }

  render() {
    const { eventType, search,newEventModalOpen,eventOperationType,event_create_permission,readEventPermission } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="content-wrapper">
              <HeaderDesktop selectionType={2}/>
              <div className="content">
                <div className="section section-padding">
                  <div className="container">
                    <div className="section-container">
                      <div className="section-title position-relative text-center d-md-flex align-items-start m-0">
                        <div className="flex-fill mr-n-130">
                          <h1 className="page-title h4">
                          {eventType == 1
                        ? t("ActiveArchiveEvent.ActiveEvent")
                        : t("ActiveArchiveEvent.ArchiveEvent")}
                          </h1>
                        </div>
                        {event_create_permission && <button
                          type="button"
                          className="btn btn-primary-theme event btn-sm"
                          data-toggle="modal"
                          data-target="#NewEvent"
                          onClick={() => {
                            this.handleNeweventModal();
                          }}
                        >
                          {t("HomeScreen.newEvent")}
                        </button>}
                        
                      </div>
                      <div className="section-content">
                         {newEventModalOpen && <AddNewEvent 
                          operation={eventOperationType}
                          handleNeweventModal={(isFromSave)=>{this.handleNeweventModal(isFromSave)}}/>}

                        <div className="d-flex align-items-center justify-content-end mt-3 mb-4 pb-3">
                          <div className="flex-fill mr-n-50">
                            <div className="input-group w-50 mx-auto">
                              <input
                                type="E-Mail"
                                placeholder={t("ActiveArchiveEvent.search")}
                                className="form-control"
                                ref={this.searchRef}
                                onChange={this.changeEventSearch}
                                onKeyPress={(e) =>
                                  e.keyCode == 13 ||
                                  (e.which == 13 && this.searchApi())
                                }
                              />
                              <div className="input-group-append">
                                <button type="button"  className="input-group-text pointer">
                                  <i
                                    className="fa fa-search"
                                    onClick={this.searchApi}
                                  ></i>
                                  {search.length !== 0 && (
                                    <i
                                      className="fa fa-times ml-3"
                                      onClick={this.clearSearch}
                                    ></i>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="sort">
                            <a
                              href="javascript;:"
                              className={
                                eventType == 1 ? "mr-2 active" : "mr-2"
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  eventType: 1,
                                });
                              }}
                            >
                              <i className="fa fa-window-maximize fa-lg text-muted"></i>
                            </a>
                            <a
                              href="javascript;:"
                              className={
                                eventType == 2 ? "active" : ""
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  eventType: 2,
                                });
                              }}
                            >
                              <i className="fa fa-archive fa-lg text-muted"></i>
                            </a>
                          </div>
                        </div>
                        {/* <EventListType
                                    key={index}
                                    data={data}
                                    start={this.props.eventList.start}
                                    handleMessageModal ={(data)=>{this.handleMessageModal(data)}}
                                    handleEditeventModal={(eventId)=>{this.handleEditeventModal(eventId)}}
                                  /> */}

                        {readEventPermission && eventType == 1 && (<ActiveEventList
                            searchData={this.state}
                            searchApi={this.searchApi}
                          />)}

                          
                        {readEventPermission && eventType == 2 && (<ArchiveEventList
                            searchData={this.state}
                            searchApi={this.searchApi}
                          />)}
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FooterDesktop selectionType={2}/>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(withRouter(EventList));
