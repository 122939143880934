/* eslint eqeqeq: 0 */
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { editorStateFromRaw, MegadraftEditor } from "megadraft";
import "megadraft/dist/css/megadraft.css";
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getEmailDetails,
  updateEmailDetails,
} from "../../../../constants/apiParams";
import apiCall, { METHOD } from "../../../../constants/baseUrl";
import { getUserID } from "../../../../util/utils";
import LoadingPage from "../../../CommanComponents/LoadingPage";

class EditEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportDialogueStatus: false,
      editEmailLoader: false,
      isLoading: false,
      subject: "",
      content: "",
      identifier: "",
      editorState: editorStateFromRaw(null),
    };
  }

  componentDidMount() {
    const { id } = this.props.item;
    apiCall(
      METHOD.QUERY,
      getEmailDetails(id, getUserID()),
      (data) => {
        const { subject, content, identifier } = data;

        this.setState({
          subject,
          content,
          identifier,
          editorState: EditorState.createWithContent(stateFromHTML(content)),
        });
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleReportDialogue = () => {
    this.setState((prevState) => {
      return {
        reportDialogueStatus: !prevState.reportDialogueStatus,
      };
    });
  };

  focus = () => {
    this.editor.focus();
  };

  onChange = (editorState) => {
    this.setState({ editorState });
  };

  handleSubmit = () => {
    this.apiCallEditUser();
  };

  apiCallEditUser = () => {
    const { subject, editorState } = this.state;
    let html = stateToHTML(editorState.getCurrentContent());
    html = JSON.stringify(html);
    apiCall(
      METHOD.QUERY,
      updateEmailDetails(getUserID(), this.props.item.id, subject, html),
      (data) => {
        toast.success(this.props.t(data.message));
        this.props.changeRouteStatus(0, {});
      },
      (isLoading) => {
        this.setState({ addUserLoader: isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  handleSubjectChange = (e) => {
    this.setState({
      subject: e.target.value,
    });
  };
  render() {
    const { isLoading, identifier, subject, editEmailLoader } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-green sab-header1 sticky-top">
                <div className="container">
                  <div className="py-2 d-flex align-items-center">
                    <span>
                      <i
                        className="zmdi zmdi-close zmdi-hc-fw font-30 text-light pointer"
                        onClick={() => this.props.changeRouteStatus(0, {})}
                      ></i>
                    </span>

                    {editEmailLoader ? (
                      <div className="btn btn-light font-13 ml-auto pointer">
                        {t("editEmail.save")}
                        <div className="spinner-border spinner-border-sm ml-1 text-blue">
                          <span className="sr-only">
                            {t("GeneralMessages.loading")}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="btn btn-light font-13 ml-auto pointer"
                        onClick={() => this.handleSubmit()}
                      >
                        {t("editEmail.save")}
                      </div>
                    )}
                  </div>
                  <div className="text-center mt-4 text-light">
                    <h2 className="m-0 text-light">{t("editEmail.title")}</h2>
                  </div>
                </div>
              </div>

              <div className="middle-section sab-middle-section1">
                {isLoading && <LoadingPage />}

                <div className="container my-4 z-index-99">
                  <div className="form-group mb-5">
                    <div className="form-group">
                      <label className="font-14">
                        {t("editEmail.identifier")}
                      </label>

                      <h6>{identifier && identifier} </h6>
                    </div>

                    <div className="form-group">
                      <label className="font-14">
                        {t("editEmail.subject")}
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        onChange={this.handleSubjectChange}
                        name="subject"
                        value={subject && subject}
                      />
                    </div>

                    <div className="form-group">
                      <label className="font-14">
                        {t("editEmail.content")}
                      </label>
                      <div
                        className="icon-box-style1 box-left-line-yellow mb-4 "
                        onDoubleClick={() => {
                          this.focus();
                        }}
                      >
                        <div className="pl-3 d-flex">
                          <div className="w-100">
                            <MegadraftEditor
                              editorState={this.state.editorState}
                              onChange={this.onChange}
                              ref={(element) => {
                                this.editor = element;
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(EditEmail));
