/* eslint eqeqeq: 0 */
import {
  GROUP_DETAIL,
  GROUP_DETAIL_CLEARSTATE,
  GROUP_DETAIL_DELETE,
  GROUP_DETAIL_HASMORE,
  GROUP_DETAIL_START,
  UPDATE_GROUP_DETAILS,
  UPDATE_GROUP_DETAIL_BY_ID,
  UPDATE_GROUP_LIST,
} from "../constants/action-types";

const initialState = { group_list: [], hasMore: true, start: 0 };
export default function groupDetailReducer(state = initialState, action) {
  switch (action.type) {
    case GROUP_DETAIL:
      return { ...state, ...action.payload };
    case UPDATE_GROUP_LIST:
    let newGroupList = action.payload.group_list
    let stateGroupList = state.group_list
    console.log("is there are same",JSON.stringify(newGroupList) == JSON.stringify(stateGroupList))
     if( JSON.stringify(newGroupList) != JSON.stringify(stateGroupList)){
      return {
        ...state,
        group_list: [...state.group_list, ...action.payload.group_list],
      };
     }
    case GROUP_DETAIL_HASMORE:
      return { ...state, hasMore: action.payload };
    case GROUP_DETAIL_START:
      return { ...state, start: action.payload };
    case GROUP_DETAIL_CLEARSTATE:
      return initialState;
    case UPDATE_GROUP_DETAILS:
      return {
        ...state,
        ...{
          name: action.payload.name,
          description: action.payload.description,
          group_type_icon: action.payload.group_type_icon,
          group_type_name: action.payload.group_type_name,
          parent_id: action.payload.parent_id,
        },
      };

    case UPDATE_GROUP_DETAIL_BY_ID:
      return {
        ...state,
        group_list: state.group_list.map((item, i) =>
          item.id == action.payload.id ? { ...item, ...action.payload } : item
        ),
      };
    case GROUP_DETAIL_DELETE:
      let groupList = state.group_list.filter((item) => {
        return item.id != action.payload.id;
      });
      return { ...state, group_list: groupList };
    default:
      return state;
  }
}

function objectsAreSame(x, y) {
  var objectsAreSame = true;
  for(var propertyName in x) {
     if(x[propertyName] !== y[propertyName]) {
        objectsAreSame = false;
        break;
     }
  }
  return objectsAreSame;
}