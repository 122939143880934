import React, { Component } from "react";

import { Translation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

class ListTypeMenu extends Component {
  render() {
    const {
      system_email_count,
      snippet_count,
      person_data_type_count,
      channel_count,
      location_count,
      user_count,
      side_menu_count,
      report_type_count,
      channel_type_count,
      group_type_count,
      event_type_count,
      list_type_count,
      default_snippet_count,
    } = this.props.data;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <>
              <div className="card pointer bg-border green">
                <div className="card-header-custom">
                  <div className="media">
                    <div className="media-body pt-4 px-4 pb-4">
                      <Link to="/manage-menu">
                        <div className="form-row">
                          <div className="col-md-6 ">
                            <h5 className="mb-1">
                              {" "}
                              {t("administration.manageMenuList")}
                            </h5>
                            <small className="text-muted">
                              {side_menu_count && side_menu_count}{" "}
                              {t("administration.MenuItemCount")}
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card pointer bg-border green">
                <div className="card-header-custom">
                  <div className="media">
                    <div className="media-body pt-4 px-4 pb-4">
                      <Link to="/manage-emails">
                        <div className="form-row">
                          <div className="col-md-6 ">
                            <h5 className="mb-1">
                              {" "}
                              {t("administration.manageEmailTitle")}
                            </h5>
                            <small className="text-muted">
                              {system_email_count && system_email_count}{" "}
                              {t("administration.emailCount")}
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card pointer bg-border green">
                <div className="card-header-custom">
                  <div className="media">
                    <div className="media-body pt-4 px-4 pb-4">
                      <Link to="/manage-location">
                        <div className="form-row">
                          <div className="col-md-6 ">
                            <h5 className="mb-1">
                              {" "}
                              {t("administration.manageLocationList")}
                            </h5>
                            <small className="text-muted">
                              {location_count && location_count}{" "}
                              {t("administration.locationCount")}{" "}
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card pointer bg-border green">
                <div className="card-header-custom">
                  <div className="media">
                    <div className="media-body pt-4 px-4 pb-4">
                      <Link to="/manage-user">
                        <div className="form-row">
                          <div className="col-md-6 ">
                            <h5 className="mb-1">
                              {t("administration.manageUserTitle")}
                            </h5>
                            <small className="text-muted">
                              {user_count && user_count}{" "}
                              {t("administration.userCount")}
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card pointer bg-border green">
                <div className="card-header-custom">
                  <div className="media">
                    <div className="media-body pt-4 px-4 pb-4">
                      <Link to="/channel-list">
                        <div className="form-row">
                          <div className="col-md-6 ">
                            <h5 className="mb-1">
                              {" "}
                              {t("administration.manageChannelTitle")}
                            </h5>
                            <small className="text-muted">
                              {channel_count && channel_count}{" "}
                              {t("administration.ChannelCount")}
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card pointer bg-border green">
                <div className="card-header-custom">
                  <div className="media">
                    <div className="media-body pt-4 px-4 pb-4">
                      <Link to="/manage-eventlist-type">
                        <div className="form-row">
                          <div className="col-md-6 ">
                            <h5 className="mb-1">
                              {" "}
                              {t("administration.manageEventistTypeTitle")}
                            </h5>
                            <small className="text-muted">
                              {event_type_count && event_type_count}{" "}
                              {t("administration.EventtypeCount")}
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card pointer bg-border green">
                <div className="card-header-custom">
                  <div className="media">
                    <div className="media-body pt-4 px-4 pb-4">
                      <Link to="/manage-report-type">
                        <div className="form-row">
                          <div className="col-md-6 ">
                            <h5 className="mb-1">
                              {" "}
                              {t("administration.manageReportTypeTitle")}
                            </h5>
                            <small className="text-muted">
                              {report_type_count && report_type_count}{" "}
                              {t("administration.reportTypeCount")}
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card pointer bg-border green">
                <div className="card-header-custom">
                  <div className="media">
                    <div className="media-body pt-4 px-4 pb-4">
                      <Link to="/manage-channel-type">
                        <div className="form-row">
                          <div className="col-md-6 ">
                            <h5 className="mb-1">
                              {" "}
                              {t("administration.manageChannelTypeTitle")}
                            </h5>
                            <small className="text-muted">
                              {channel_type_count && channel_type_count}
                              {t("administration.ChannelTypeCount")}
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card pointer bg-border green">
                <div className="card-header-custom">
                  <div className="media">
                    <div className="media-body pt-4 px-4 pb-4">
                      <Link to="/manage-snippet">
                        <div className="form-row">
                          <div className="col-md-6 ">
                            <h5 className="mb-1">
                              {" "}
                              {t("administration.manageSnippetTitle")}
                            </h5>
                            <small className="text-muted">
                              {snippet_count && snippet_count}{" "}
                              {t("administration.snippetCount")}
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card pointer bg-border green">
                <div className="card-header-custom">
                  <div className="media">
                    <div className="media-body pt-4 px-4 pb-4">
                      <Link to="/manage-default-snippet">
                        <div className="form-row">
                          <div className="col-md-6 ">
                            <h5 className="mb-1">
                              {" "}
                              {t("administration.manageDefaultSnippetTitle")}
                            </h5>
                            <small className="text-muted">
                              {default_snippet_count && default_snippet_count}
                              {t("administration.defaultSnippetCount")}
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card pointer bg-border green">
                <div className="card-header-custom">
                  <div className="media">
                    <div className="media-body pt-4 px-4 pb-4">
                      <Link to="/manage-person-datatype">
                        <div className="form-row">
                          <div className="col-md-6 ">
                            <h5 className="mb-1">
                              {" "}
                              {t("administration.managePersonDatatypeList")}
                            </h5>
                            <small className="text-muted">
                              {person_data_type_count && person_data_type_count}
                              {t("administration.PersonDataTypeCount")}
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card pointer bg-border green">
                <div className="card-header-custom">
                  <div className="media">
                    <div className="media-body pt-4 px-4 pb-4">
                      <Link to="/manage-group-type">
                        <div className="form-row">
                          <div className="col-md-6 ">
                            <h5 className="mb-1">
                              {" "}
                              {t("administration.manageGroupTypeTitle")}
                            </h5>
                            <small className="text-muted">
                              {" "}
                              {group_type_count && group_type_count}
                              {t("administration.groupypeCount")}
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card pointer bg-border green">
                <div className="card-header-custom">
                  <div className="media">
                    <div className="media-body pt-4 px-4 pb-4">
                      <Link to="/manage-list-type">
                        <div className="form-row">
                          <div className="col-md-6 ">
                            <h5 className="mb-1">
                              {" "}
                              {t("administration.manageListTypeTitle")}
                            </h5>
                            <small className="text-muted">
                              {" "}
                              {list_type_count && list_type_count}
                              {t("administration.ListtypeCount")}
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card pointer bg-border green">
                <div className="card-header-custom">
                  <div className="media">
                    <div className="media-body pt-4 px-4 pb-4">
                      <div
                        className="form-row"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.handleSystemSetupdialogue();
                        }}
                      >
                        <div className="col-md-6 ">
                          <h5 className="mb-1">
                            {" "}
                            {t("administration.setupSystem")}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(ListTypeMenu);
