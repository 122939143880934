/* eslint eqeqeq: 0 */
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import stateToMarkdown from "draft-js-export-markdown/lib/stateToMarkdown";
import { stateFromHTML } from "draft-js-import-html";
import { editorStateFromRaw, MegadraftEditor } from "megadraft";
import "megadraft/dist/css/megadraft.css";
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkCost,
  checksmscost,
  getChannelTypeList,
  updateReportChannel
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";

const snippet_type_cost = ["text2speech", "sms"];
const snippet_type_count = ["sms", "twitter"];

class EditNewChannelReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      channel_type_Image: "EB",
      channel_type_name: "Einberufung",
      channel_type_id: 0,
      chaanne_list: [],
      reportDialogueStatus: false,
      channel_type_identifier: "",
      channelName: "",
      message_id: 0,
      report_id: 0,

      // Display snippet
      content: ``,
      oldContent: ``,
      editorState: editorStateFromRaw(null),
      costEditorState: editorStateFromRaw(null),
      dialogueStatus: false,
      isLoading: false,
      submitLoading: false,
      cost: 0,
      snippetCount: 0,
      isRequestAnswer:0
    };
    this.setEditor = (editor) => {
      this.editor = editor;
    };
    this.focusEditor = () => {
      if (this.editor) {
        this.editor.focus();
      }
    };
  }

  onEditorChange = (editorState) => {
    const { channel_type_identifier } = this.state;
    this.setState({ snippetCount: 0 });
    if (snippet_type_cost.includes(channel_type_identifier)) {
      const markdown = stateToMarkdown(
        this.state.costEditorState.getCurrentContent()
      );
      const new_markdown = stateToMarkdown(editorState.getCurrentContent());
      let length2 = new_markdown.trim().length;

      let length1 = markdown.trim().length;

      if (length2 - length1 >= 10 || length1 > length2) {
        this.checkTextCost(new_markdown, channel_type_identifier);
        this.setState({ costEditorState: editorState });
      }
    }
    if (snippet_type_count.includes(channel_type_identifier)) {
      let html = stateToHTML(editorState.getCurrentContent());
      let textContent = html
        .replace(/<\s*br[^>]?>/, "\n")
        .replace(/(<([^>]+)>)/g, "");
      textContent = textContent.replace("&nbsp;", "");
      let length2 = textContent.length;
      this.setState({ snippetCount: length2 });
    }

    this.setState({ editorState });
  };

  
  setRequestAnswer = (event) => {
    if (event.target.checked) {
      this.setState({
        isRequestAnswer: 1,
      });
    } else {
      this.setState({
        isRequestAnswer: 0,
      });
    }
  };

  componentDidMount() {
    const {
      title,
      content,
      id,
      channel_type_id,
      channel_type_name,
      channel_type_icon,
      report_id,
      request_answer,
      channel_type_identifier,
    } = this.props.channelDetail;

    let content_result = JSON.parse(content);
    let old_content = content_result.content;
    let result = content_result.result;

    this.editordata = result;

    apiCall(
      METHOD.QUERY,
      getChannelTypeList(getUserID(), 0),
      (data) => {
        this.setState(
          {
            chaanne_list: data.channel_list,
            channel_type_Image: channel_type_icon,
            channel_type_name: channel_type_name,
            channel_type_id: channel_type_id,
            channel_type_identifier: channel_type_identifier,
            channelName: title,
            message_id: id,
            report_id,
            content: result,
            isRequestAnswer:request_answer,
            oldContent: old_content,
            editorState: EditorState.createWithContent(stateFromHTML(result)),
            costEditorState: EditorState.createWithContent(
              stateFromHTML(result)
            ),
          },
          () => {
            const { editorState } = this.state;
            if (snippet_type_cost.includes(channel_type_identifier)) {
              const markdown = stateToMarkdown(
                this.state.costEditorState.getCurrentContent()
              );
              this.checkTextCost(markdown, channel_type_identifier);
            }

            if (snippet_type_count.includes(channel_type_identifier)) {
              let html = stateToHTML(editorState.getCurrentContent());
              let textContent = html
                .replace(/<\s*br[^>]?>/, "\n")
                .replace(/(<([^>]+)>)/g, "");
              textContent = textContent.trim();
              textContent = textContent.replace("&nbsp;", "");

              let length2 = textContent.length;
              this.setState({ snippetCount: length2 });
            }
          }
        );
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  checkTextCost = (markdown, channel_type_identifier) => {
    if (channel_type_identifier == "sms") {
      apiCall(
        METHOD.QUERY,
        checksmscost(
          getUserID(),
          this.props.channelDetail.channel_id,
          JSON.stringify(markdown),
          channel_type_identifier
        ),
        (data) => {
          this.setState({
            cost: data.price,
          });
        },
        (isLoading) => {}
      );
    } else {
      apiCall(
        METHOD.QUERY,
        checkCost(
          getUserID(),
          this.state.channel_type_id,
          JSON.stringify(markdown),
          channel_type_identifier
        ),
        (data) => {
          this.setState({
            cost: data.price,
          });
        },
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleReportDialogue = () => {
    this.setState((prevState) => {
      return {
        reportDialogueStatus: !prevState.reportDialogueStatus,
      };
    });
  };

  handleBrand = (image, name) => {
    this.setState(() => {
      return {
        status: false,
        brandType: name,
        brandImage: image,
      };
    });
  };

  handleReportSelection = (item) => {
    const { name, channel_type_icon, channel_type_id } = item;

    this.setState({
      reportDialogueStatus: false,
      channel_type_Image: channel_type_icon,
      channel_type_name: name,
      channel_type_id: channel_type_id,
    });
  };

  closeDialogue = () => {
    this.setState({
      dialogueStatus: false,
    });
  };

  handleChannelTextChange = (e) => {
    this.setState({
      channelName: e.target.value,
    });
  };

  creatReport = () => {
    const { editorState } = this.state;
    if (this.state.channelName.length == 0) {
      toast.info(this.props.t("AddNewChannel.channelNameValidation"));
    } else {
      let html = stateToHTML(editorState.getCurrentContent());
      this.setState(
        {
          content: html,
        },
        () => {
          this.apiCallCreateReport();
        }
      );
    }
  };
  apiCallCreateReport = () => {
    const {
      content,
      oldContent,
      report_id,
      channelName,
      message_id,
      isRequestAnswer
    } = this.state;

    let objContent = {
      content: "",
      result: "",
    };

    console.log("channelName", channelName);
    let result = JSON.stringify(content);
    let old_content = JSON.stringify(oldContent);
    objContent.result = result;
    objContent.content = old_content;

    apiCall(
      METHOD.QUERY,
      updateReportChannel(
        getUserID(),
        message_id,
        report_id,
        this.props.channelDetail.channel_id,
        channelName,
        old_content,
        result,
        isRequestAnswer
      ),
      (data) => {
        this.handleCreateResponse(data);
      },
      (isLoading) => {
        this.setState({ submitLoading: isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleCreateResponse = async (data) => {
    toast.success(this.props.t(data.message));
    this.props.handleEditChannel(true);
  };
  handlebadge = () => {
    const { snippetCount } = this.state;
    if (snippetCount > 280) {
      return "badge badge-danger mr-2 text-white badge-right";
    } else {
      return "badge badge-success mr-2 text-white badge-right";
    }
  };
  render() {
    const {
      isLoading,
      channel_type_Image,
      cost,
      channel_type_identifier,
      snippetCount,
      submitLoading,
      editChannel,
      isRequestAnswer
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="d-block">
                <div
                  className="modal fade show"
                  id="NewEvent"
                  tabindex="-1"
                  aria-labelledby="NewEventLabel"
                  aria-modal="true"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog modal-lg modal-dialog-centered0 modal-dialog-scrollable0">
                    <div className="modal-content">
                      <div className="modal-header border-0 d-block pt-4 pb-0">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <button
                            type="button"
                            onClick={() => this.props.handleEditChannel()}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                          </button>
                          <h5 className="modal-title">
                        {t("AddNewChannel.editChannelLable")}
                      </h5>
                          {submitLoading ? (
                            <button
                              type="button"
                              onClick={this.creatReport}
                              className="btn btn-primary-theme event"
                              data-dismiss="modal"
                            >
                              {t("AddNewEvent.btnSave")}
                              <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                <span className="sr-only">
                                  {t("GeneralMessages.loading")}
                                </span>
                              </div>
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={this.creatReport}
                              className="btn btn-primary-theme event"
                              data-dismiss="modal"
                            >
                              {t("AddNewChannel.btnSave")}
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="modal-body">
                        <small className="d-block mb-2">
                          {t("AddNewChannel.type")}
                        </small>
                        <div className="radio-container d-flex overflow-x-auto mb-3 p-0">
                          <div className="custom-control custom-radio custom-control-box text-dark mr-3 mb-3">
                            <input
                              type="radio"
                              name="NeuesEreignis"
                              id="checked"
                              className="custom-control-input"
                              checked
                            />
                            <label
                              className="card-body py-3 px-2 mb-0 text-center custom-control-label"
                              htmlFor="checked"
                            >
                              <p className="mb-2">
                                {channel_type_Image && (
                                  <img
                                    src={`data:image/png;base64,${
                                      channel_type_Image && channel_type_Image
                                    }`}
                                    alt="brand_icon"
                                    style={{
                                      width: "24px",
                                      height: "24px",
                                    }}
                                  />
                                )}
                              </p>
                              <p className="font-size-14 text-nowrap mb-0">
                                {this.state.channel_type_name &&
                                  this.state.channel_type_name}
                              </p>
                            </label>
                          </div>
                        </div>
                        <div className="clearfix">
                          <div className="form-row">
                            <div className="col-md-12 p-0">
                              <div className="form-group">
                                <label> {t("AddNewChannel.title")}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={this.handleChannelTextChange}
                                  name="title"
                                  value={this.state.channelName}
                                />
                              </div>
                            </div>


                            {channel_type_identifier == "twilio" && (
                              <div className="form-group">
                                <div onChange={(e) => this.setRequestAnswer(e)}>
                                  <input
                                    type="checkbox"
                                    value="0"
                                    name="link_type"
                                    checked={
                                      isRequestAnswer == 1 ? true : false
                                    }
                                    className="mr-2"
                                  />
                                  {t("AddNewChannel.requestAnswer")}
                                </div>
                              </div>
                            )}

                            {/* <div className="col-md-8">
                              <div className="form-group">
                                <label> {t("AddNewEvent.location")}</label>
                                <Form mode="structured">
                                  <Autocomplete
                                    name="location"
                                    placeholder={t(
                                      "AddNewEvent.selectLocation"
                                    )}
                                    className="custom-select"
                                    suggestions={location_list}
                                    required
                                  />
                                </Form>
                              </div>
                            </div>
                          */}

                            <div
                              className="icon-box-style1 box-left-line-yellow mb-4 "
                              onClick={this.focusEditor}
                            >
                              <div className="pl-3 flex-fill">
                                <MegadraftEditor
                                  editorState={this.state.editorState}
                                  onChange={this.onEditorChange}
                                  ref={this.setEditor}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="py-2 d-flex align-items-center">
                          {snippet_type_count.includes(
                            channel_type_identifier
                          ) && (
                            <p
                              className={this.handlebadge()}
                              style={{ float: "right" }}
                            >
                              {t("AddNewChannel.character")} {snippetCount}
                            </p>
                          )}

                          {snippet_type_cost.includes(
                            channel_type_identifier
                          ) && (
                            <p
                              className="badge badge-success mr-2 text-white badge-right"
                              style={{ float: "right" }}
                            >
                              {t("AddNewChannel.credits")} {cost && cost}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-backdrop fade show"></div>
              </div>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.channelDetailStateToProps],
      state
    ),
  actions.appMapDispatchToProps([actions.asyncApiDispatchToProps])
)(withRouter(withTranslation()(EditNewChannelReport)));
