/* eslint eqeqeq: 0 */

import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import gql from "graphql-tag";
import React, { Component } from "react";
import { ApolloProvider, Mutation } from "react-apollo";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getinstallationvalue,
  getlocationdata,
} from "../../../constants/apiParams";
import apiCall, { METHOD, uploadLink } from "../../../constants/baseUrl";
import { checkAdminPermission, getUserID, saveDatatoLocalStorage } from "../../../util/utils";
import Footer from "../../Footer/FooterMobile";
import LoadingPage from "../../CommanComponents/LoadingPage";
import CommonLoader from "../../CommanComponents/CommonLoader";

const apolloCache = new InMemoryCache();
const client_mutation = new ApolloClient({
  cache: apolloCache,
  link: uploadLink,
});

const Updatesystemlogo = gql`
  mutation Updatesystemlogo($profile: UpdatesystemlogoInput!) {
    Updatesystemlogo(input: $profile) {
      success
      message
      system_logo
    }
  }
`;

class SetUpSystemDesktop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      icon: undefined,
      icon_file: undefined,
      base_64_icon: undefined,
    };
  }

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  componentDidMount() {
    if(!checkAdminPermission()){
      this.props.history.push("/")
    }
    apiCall(
      METHOD.QUERY,
      getinstallationvalue(),
      (data) => this.getInstallationResponse(data),
      (isLoading) => {
        this.setState({ isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }
  getInstallationResponse = (data) => {
    this.setState({
      base_64_icon: data.system_logo,
    });
  };
  getIconUrl = (file, uploadFile) => {
    let images_extensions = ["jpg", "jpeg", "png"];
    let image_extension = file.type.split("/")[1];
    const result = images_extensions.indexOf(image_extension);
    if (result == -1) {
      toast.info(`${image_extension} file is not supported`);
    } else {
      this.setState({
        icon: URL.createObjectURL(file),
        icon_file: uploadFile,
      });
    }
  };
  loader = (<CommonLoader />);
  render() {
    const { icon, isLoading, base_64_icon } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <ApolloProvider client={client_mutation}>
              <Mutation mutation={Updatesystemlogo}>
                {(Updatesystemlogo, { data, error, loading }) => {
                  if (data) {
                    if (data.Updatesystemlogo.success == 0) {
                      toast.error(this.props.t(data.Updatesystemlogo.message));
                    } else {
                      toast.success(
                        this.props.t(data.Updatesystemlogo.message)
                      );
                      saveDatatoLocalStorage(
                        "system_logo",
                        data.Updatesystemlogo.system_logo
                      );
                      this.props.handleSystemSetupdialogue();
                    }
                  }
                  if (error) {
                    toast.error(
                      i18n.getDataByLanguage("en").translation.GeneralMessages
                        .errorServerisNotResponding &&
                        i18n.getDataByLanguage("en").translation.GeneralMessages
                          .errorServerisNotResponding
                    );
                  }

                  return (
                    <div className="d-block">
                      <div
                        className="modal fade show"
                        id="NewMessage"
                        tabindex="-1"
                        aria-labelledby="NewMessageLabel"
                        aria-modal="true"
                        style={{ display: "block" }}
                      >
                        <div className="modal-dialog modal-lg 0modal-dialog-centered 0modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header border-0 d-block pt-4 pb-0">
                              <div className="d-flex align-items-center justify-content-between mb-4">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  onClick={() => {
                                    this.props.handleSystemSetupdialogue();
                                  }}
                                >
                                  <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                </button>

                                {loading ? (
                                  <button
                                    type="button"
                                    className="btn btn-primary-theme"
                                    data-dismiss="modal"
                                  >
                                    {t("AdminAddUser.save")}
                                    <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                      <span className="sr-only">
                                        {t("GeneralMessages.loading")}
                                      </span>
                                    </div>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-primary-theme"
                                    data-dismiss="modal"
                                    onClick={() => {
                                      const { icon_file } = this.state;
                                      let obj = {
                                        variables: {
                                          profile: {
                                            user_id: getUserID(),
                                            system_logo: icon_file
                                              ? icon_file
                                              : null,
                                          },
                                        },
                                      };
                                      Updatesystemlogo(obj);
                                    }}
                                  >
                                    {t("AddNewReport.btnSave")}
                                  </button>
                                )}
                              </div>
                              <h5 className="modal-title">
                                {" "}
                                {t("administration.setupSystem")}
                              </h5>
                            </div>
                            <div className="modal-body">
                              <div className="middle-section sab-middle-section1">
                                {isLoading && this.loader}
                                <div className="container my-4 z-index-99">
                                  <div className="form-group mb-5">
                                    <div className="form-group">
                                      <div className="container my-4 00z-index-99">
                                        <form>
                                          <div className="form-group">
                                            <label className="font-14">
                                              {t("setUpSystem.icon")}
                                            </label>
                                            <div
                                              className="fileinput text-center fileinput-new d-flex align-items-center"
                                              data-provides="fileinput"
                                            >
                                              <div className="btn-file">
                                                <div
                                                  className="thumbnail fileinput-new "
                                                  style={{
                                                    width: "200px",
                                                    height: "200px",
                                                    background: "#999",
                                                  }}
                                                >
                                                  {icon ? (
                                                    <img
                                                      src={icon}
                                                      alt=""
                                                      style={{
                                                        width: "200px",
                                                        height: "200px",
                                                      }}
                                                    />
                                                  ) : base_64_icon ? (
                                                    <img
                                                      src={`data:image/png;base64,${
                                                        base_64_icon &&
                                                        base_64_icon
                                                      }`}
                                                      style={{
                                                        width: "200px",
                                                        height: "200px",
                                                      }}
                                                      alt="icon"
                                                    />
                                                  ) : (
                                                    <img
                                                      src="/images/no-image.png"
                                                      alt="icon"
                                                      style={{
                                                        width: "200px",
                                                        height: "200px",
                                                      }}
                                                    />
                                                  )}
                                                </div>
                                                <input
                                                  type={"file"}
                                                  file-model="myFile"
                                                  name="image"
                                                  accept=""
                                                  onChange={(event) => {
                                                    const file =
                                                      event.target.files[0];
                                                    this.getIconUrl(
                                                      event.currentTarget
                                                        .files[0],
                                                      file
                                                    );
                                                  }}
                                                />

                                                <div
                                                  className="fileinput-preview fileinput-exists thumbnail uploaded-user-image rounded-circle"
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    borderRadius: "100%",
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-backdrop fade show"></div>
                    </div>
                  );
                }}
              </Mutation>
            </ApolloProvider>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(SetUpSystemDesktop));
