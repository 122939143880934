/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { SnippetDataQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID, stringToHTML } from "../../../util/utils";

class NumberDialogue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      nestedSnippetData: {},
      content: {},
      number: 0,
      minValue: 0,
      maxValue: 0,
      hide_on_zero: false,
    };
  }


  handleLoader = status => {
    this.setState(() => {
      return {
        isLoading: status
      };
    });
  };


  close_text_dialogue = () => {
    const { hide_on_zero } = this.state;

    const {
      setDynamicValue,
      closeDialogue,
       id
    } = this.props;

    if (hide_on_zero) {
      setDynamicValue(id, "");
      closeDialogue();
    } else {
      closeDialogue();
    }
  }


  componentDidMount() {
    window.scrollTo(0, 0)

    const { id } = this.props;
    apiCall(
      METHOD.QUERY,
      SnippetDataQuery(id,getUserID()),
      data => {
        let jsonContent = JSON.parse(data.content);
        this.setState({
          nestedSnippetData: data,
          content: jsonContent,
          minValue: jsonContent.min_value,
          maxValue: jsonContent.max_value,
          number: jsonContent.default,
          hide_on_zero: jsonContent.hide_on_zero,
          raw_value: JSON.parse(JSON.parse(data.raw_value)),

        });
      },
      isLoading => { },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  onCounterClick = type => {

    const { minValue, maxValue } = this.state;
    if (type) {
      this.setState(prevState => {
        if (prevState.number !== maxValue) {
          return {
            ...this.state,
            number: prevState.number + 1
          };
        } else {
          toast.info(
            this.props.t("snippetDialogue.conditionDialogueValidation") +
            minValue +
            " - " +
            maxValue +
            "."
          );
        }
      });
    } else {
      this.setState(prevState => {
        if (prevState.number !== minValue) {
          return {
            ...this.state,
            number: prevState.number - 1
          };
        } else {
          toast.info(
            this.props.t("snippetDialogue.conditionDialogueValidation") +
            minValue +
            " - " +
            maxValue +
            "."
          );
        }
      });
    }
  };

  submitData = () => {
    const {
      setDynamicValue,
      setFinalDynamicValueWithInnerSnippet,
      closeDialogue,
      setDynamicValueForMultiLine,
      id,
      setDynamicValueForMultiLineWithInnerSnippet
    } = this.props;

    const {
      content,
      number,
      minValue,
      maxValue,
      raw_value
    } = this.state;


    let is_multiline = false;
    if (raw_value.blocks.length > 1) {
      is_multiline = true;
    }




    if (minValue > number || number > maxValue) {
      toast.info(
        this.props.t("snippetDialogue.conditionDialogueValidation") +
        minValue +
        " - " +
        maxValue +
        "."
      );
    } else {
      let val = content.content;
      val = val.replace("<value>", number);
      
    let entityMap = raw_value.entityMap 
    let input_value = number
    if (is_multiline) {
      let blocks = raw_value.blocks;
      // find the value tag
      if(entityMap==0){
         for (let i in blocks) {
          let block = blocks[i];
          let text = block.text;
          const value_position = text.indexOf("<value>");

          if(value_position != -1){
            text = text.replace("<value>", input_value);
            blocks[i].text = text;
            let inlineStyleRanges = blocks[i].inlineStyleRanges;

            for (let j in inlineStyleRanges) {
              let style = inlineStyleRanges[j];
              if (style.offset >= value_position) {
                const input_Value_length = input_value.length;
                let offset = style.offset
                offset = offset + input_Value_length - 7;
                style.offset = offset
                inlineStyleRanges[j]=style
              }
            }
            blocks[i].inlineStyleRanges =inlineStyleRanges;
          }
        }
        setDynamicValueForMultiLine(id,raw_value);
      }else{
         for (let i in blocks) {
          let block = blocks[i];
          let text = block.text;
          const value_position = text.indexOf("<value>");

          if(value_position != -1){
            text = text.replace("<value>", input_value);
            blocks[i].text = text;
            let inlineStyleRanges = blocks[i].inlineStyleRanges;

            // update style with new value
            for (let j in inlineStyleRanges) {
              let style = inlineStyleRanges[j];
              if (style.offset >= value_position) {
                const input_Value_length = input_value.length;
                let offset = style.offset
                offset = offset + input_Value_length - 7;
                style.offset = offset
                inlineStyleRanges[j]=style
              }
            }
            blocks[i].inlineStyleRanges =inlineStyleRanges;
            
            let entityRange = blocks[i].entityRanges
            for (let i in entityRange) {
              let entityElement = entityRange[i];
              let offset = entityElement.offset
              if(offset>value_position){
                const input_Value_length = input_value.length;
                offset = offset +input_Value_length- 7
                entityElement.offset = offset
                entityRange[i] =entityElement
              } 
            }
          }
        }
        setDynamicValueForMultiLineWithInnerSnippet(id,raw_value);
      }
     
    } else {
     
      if(entityMap==0){
        let innerReference = stringToHTML(val, Date.now());
        var children = innerReference.children;
        let referenceInSpan = children[0];

        referenceInSpan.onclick = (e) => {
          e.preventDefault();
          if (e.stopPropagation) e.stopPropagation();
          this.props.handleReferenceClick(referenceInSpan);
        };
        referenceInSpan.setAttribute("class", "reference-backgroupred");
        referenceInSpan.innerHTML = referenceInSpan.getAttribute("placeholder");
        // val = innerReference;
        setFinalDynamicValueWithInnerSnippet(
          id,
          ""+number,
          raw_value
        );
      } else {
        let val1 = raw_value.blocks[0].text;
      let value_tag_position = val1.search("<value>")
      val1 = val1.replace("<value>", input_value);
      setDynamicValue(id, val1, raw_value, value_tag_position, number);

      }
    }
      closeDialogue();
    }


  };

  render() {
    const { number, nestedSnippetData } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="modal-custom-footer" style={{display:'block'}}>
            <div className="modal-inner-content">
                <div className="card-header d-flex justify-content-between pb-0">
                    <a href="javascript;:" onClick={(e)=>{
                      e.preventDefault()
                      this.close_text_dialogue();
                    }}><i className="zmdi zmdi-close zmdi-hc-2x"></i></a>
                    <button
                        className="btn pt-1 p-0"
                        onClick={this.submitData}
                        type="button"
                        data-toggle="collapse"
                        data-target="#Brand-toggler"
                        aria-controls="Brand-toggler"
                        aria-expanded="true"
                        aria-label="Toggle navigation"
                      >
                        <i className="zmdi zmdi-save zmdi-hc-fw font-size-28"></i>
                      </button>
                </div>
           
                <div className="card-body pt-3">
                <div className="text-center">
                  <p className="mt-4">
                    {nestedSnippetData.name && nestedSnippetData.name}
                  </p>

                  <div className="max-170 m-auto">
                    <div className="input-group mb-3">
                      <div
                        className="pointer input-group-append input-group-text font-20 bg-dark"
                        onClick={() => {
                          this.onCounterClick(false);
                        }}
                      >
                        <i className=" zmdi zmdi-minus zmdi-hc-fw text-light"></i>
                      </div>
                      <label className="form-control">{number}</label>
                      <div
                        className="pointer input-group-prepend input-group-text font-20 bg-dark"
                        onClick={() => {
                          this.onCounterClick(true);
                        }}
                      >
                        <i className="zmdi zmdi-plus zmdi-hc-fw text-light pointer"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(NumberDialogue);  