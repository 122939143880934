/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import {
  MaillingListQuery,
  getmailinglistfromperson,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import {
  checkUserPermission,
  getUserID,
  getValueFromLocalStorage,
  saveDatatoLocalStorage,
} from "../../../util/utils";
import CommonLoader from "../../CommanComponents/CommonLoader";
import LoadingPage from "../../CommanComponents/LoadingPage";
import FooterDesktop from "../../Footer/FooterDesktop";
import HeaderDesktop from "../../Header/HeaderDesktop";
import AddNewMail from "./AddNewMail";
import MailingItemGridView from "./MailingItemGridView";
import SearchMail from "./SearchMail";
import SearchPersonResult from "./SearchPersonResult";
import SearchMailListBaseOnPerson from "./SearchMailListBaseOnPerson";
import SearchPersonBaseOnMail from "./SearchPersonBaseOnMail";
import MailListItemListView from "./MailListItemListView";
import SearchPersonBaseonCompany from "../MailListMobile/SearchPersonBaseonCompany";

class MailingList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      mailList: [],
      end: 10,
      headerMenuStatus: false,
      isLoading: false,
      searchMailList: [],
      displayType: 0,
      addModal: false,
      addMailPermission: false,
      searchDisplayType: 0,
      mailListBaseOnPerson: [],
      personListBaseOnMailID: [],
      personListBreadCumb: [],
      searchPersonList: [],
      selectedPersonId: 0,
      displayType: 0,
      groupIdBaseOnPersonDetail: 0,
    };
    this.searchRef = React.createRef();
  }

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  loader = (<CommonLoader key={0} />);

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  handleAddModal = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          addModal: !prev.addModal,
        };
      },
      () => {
        if (this.state.addModal) {
          document.body.classList.add("modal-open");
        } else {
          const mailListPermission = checkUserPermission("read_list");

          if (isFromSave && mailListPermission) {
            this.loadItem();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  loadItem = () => {
    const { end } = this.state;
    apiCall(
      METHOD.QUERY,
      MaillingListQuery(getUserID(), this.props.mailList.start, end, ""),
      (data) => {
        this.props.getMailList(data.mailing_list);
        this.handleMailApiResponse(data);
      },
      (isLoading) => {
        this.handleLoader(isLoading);
      },
      (data) => {
        toast.error(this.props.t(data.message));
        this.props.getMailHasMoreValue(false);
      }
    );
  };

  

  refreshPage = () => {
    const { end } = this.state;
    apiCall(
      METHOD.QUERY,
      MaillingListQuery(getUserID(), 0, end, ""),
      (data) => {
        this.props.getMailList(data.mailing_list);
        this.handleMailApiResponse(data);
      },
      (isLoading) => {
        this.handleLoader(isLoading);
      },
      (data) => {
        toast.error(this.props.t(data.message));
        this.props.getMailHasMoreValue(false);
      }
    );
  };

  handleMailApiResponse = (data) => {
    if (data.is_last === 1) {
      this.props.getMailHasMoreValue(false);
    }
    this.props.getMailStartValue(this.props.mailList.start + this.state.end);

    this.setState({
      loader: false,
    });
  };


  componentDidMount() {
    const mailDisplayType = getValueFromLocalStorage("mailDisplayType");
    if (mailDisplayType == 1) {
      this.setState({
        displayType: 1,
      });
    } else {
      this.setState({
        displayType: 0,
      });
    }

    const addMailPermission = checkUserPermission("create_list");
    const mailListPermission = checkUserPermission("read_list");
    if (mailListPermission) {
      this.setState(
        {
          hasMore: true,
          start: 0,
          mailList: [],
          isLoading: false,
          addMailPermission,
          mailListPermission,
        },
        () => {
          this.loadItem();
        }
      );
    } else {
      toast.info(this.props.t("GeneralMessages.permissionError"));
    }
  }

  handleListAfterAction=()=>{
    this.props.getMailList([]);
    
    setTimeout(() => {
      this.refreshPage();
    }, 500);
  }
  handleDisplayType = () => {
    this.setState({
      searchDisplayType: 0,
    });
  };

  setSearchMailList = (personList) => {
    this.setState({
      searchPersonList: personList,
      searchDisplayType: personList.length == 0 ? 0 : 1,
    });
  };

  handleSearchDisplayTypeToInitialStage = () => {
    this.setState({
      searchDisplayType: 0,
      search: "",
      value: "",
    });
  };
  handleSearchDisplayType = (searchDisplayType) => {
    this.setState({
      searchDisplayType,
      search: "",
      value: "",
    });
  };

  handleMailbaseOnPerson = (mailList) => {
    if (mailList.length == 0) {
      this.setState({
        mailListBaseOnPerson: [],
        searchPersonList: [],
        // searchDisplayType: 0,
      });
    } else {
      this.setState({
        // searchDisplayType: 0,
        mailListBaseOnPerson: mailList,
      });
    }
  };

  handlePersonListBaseonCompany = (personListBaseOnMailID) => {
    console.log("personList------>",personListBaseOnMailID);
    this.setState({
      personListBaseOnMailID,
      searchDisplayType: 4,
    });
  };
  setmailFromPerson = (mailListBaseOnPerson, selectedPersonId) => {
    console.log("mailListBaseOnPerson", mailListBaseOnPerson);
    console.log("selectedPersonId", selectedPersonId);
    if (mailListBaseOnPerson.length != 0) {
      this.setState({
        mailListBaseOnPerson,
        searchDisplayType: 2,
        selectedPersonId,
      });
    } else {
      toast.info(this.props.t("GeneralMessages.noMailFound"));
    }
  };

  handleMailWiseResponse = (
    personListBaseOnMailID,
    personResponse,
    personListBreadCumb,
    groupIdBaseOnPersonDetail
  ) => {
    this.setState({
      personListBaseOnMailID,
      personListBreadCumb,
      personResponse,
      searchDisplayType: 3,
      groupIdBaseOnPersonDetail,
    });
  };

  getMailPersonList = (personId) => {
    apiCall(
      METHOD.QUERY,
      getmailinglistfromperson(getUserID(), personId),
      (data) => this.handleGetMailPersonResponse(data),
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleGetMailPersonResponse = (data) => {
    console.log("data", data.person_list);
    // this.props.handleMailbaseOnPerson(data.mailing_list)
  };

  render() {
    const {
      end,
      searchMailList,
      addModal,
      isLoading,
      addMailPermission,
      mailListPermission,
      searchDisplayType,
      searchPersonList,
      mailListBaseOnPerson,
      personListBaseOnMailID,
      personListBreadCumb,
      selectedPersonId,
      displayType,
      groupIdBaseOnPersonDetail,
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="content-wrapper">
              <HeaderDesktop selectionType={4} />
              <div className="content">
                <div className="section section-padding">
                  <div className="container">
                    <div className="section-container">
                      {isLoading && <CommonLoader />}
                      <div className="section-title position-relative text-center d-md-flex align-items-start m-0">
                        <div className="flex-fill mr-n-130">
                          <h1 className="page-title h4">
                            {t("MailList.title")}
                          </h1>
                        </div>
                        {addMailPermission && (
                          <button
                            type="button"
                            className="btn btn-primary-theme list btn-sm"
                            data-toggle="modal"
                            data-target="#NewEvent"
                            onClick={() => {
                              this.handleAddModal(false);
                            }}
                          >
                            <i className="zmdi zmdi-plus zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                            {t("MailList.addNewGroup")}
                          </button>
                        )}
                      </div>
                      <div className="section-content">
                        {addModal && (
                          <AddNewMail
                            handleAddModal={(isFromSave) => {
                              this.handleAddModal(isFromSave);
                            }}
                          />
                        )}

                        <div className="d-flex align-items-center justify-content-end mt-3 mb-4 pb-3">
                          <div className="flex-fill mr-n-25">
                            <div className="w-50 mx-auto">
                              <div className="flex-fill">
                                <SearchMail
                                  handleMailbaseOnPerson={(mailList) => {
                                    this.handleMailbaseOnPerson(mailList);
                                  }}
                                  handlePersonListBaseonCompany={(
                                    personList
                                  ) => {
                                    this.handlePersonListBaseonCompany(
                                      personList
                                    );
                                  }}
                                  setmailFromPerson={(
                                    personList,
                                    selectedPersonId
                                  ) => {
                                    this.setmailFromPerson(
                                      personList,
                                      selectedPersonId
                                    );
                                  }}
                                  searchArray={(personList) => {
                                    this.setSearchMailList(personList);
                                  }}
                                  handleSearchDisplayType={(
                                    searchDisplayType
                                  ) => {
                                    this.handleSearchDisplayType(
                                      searchDisplayType
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="sort">
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                saveDatatoLocalStorage("mailDisplayType", 0);

                                this.setState({
                                  displayType: 0,
                                });
                              }}
                              className={
                                displayType == 0 ? "active mr-2" : "mr-2"
                              }
                            >
                              <i className="fa fa-list fa-lg text-muted"></i>
                            </a>
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                saveDatatoLocalStorage("mailDisplayType", 1);

                                this.setState({
                                  displayType: 1,
                                });
                              }}
                              className={displayType == 1 ? "active" : ""}
                            >
                              <i className="fa fa-th-large fa-lg text-muted"></i>
                            </a>
                          </div>
                        </div>

                        {searchDisplayType == 1 && (
                          <SearchPersonResult
                            suggetionList={this.state.searchPersonList}
                            handlePersonListBaseonCompany={(personList) => {
                              this.handlePersonListBaseonCompany(personList);
                            }}
                            setmailFromPerson={(
                              personList,
                              selectedPersonId
                            ) => {
                              this.setmailFromPerson(
                                personList,
                                selectedPersonId
                              );
                            }}
                          />
                        )}

                        {displayType == 0 && (
                          <>
                            {searchDisplayType == 0 &&
                              this.props.mailList &&
                              this.props.mailList.mailList.map(
                                (mailItem, index) => {
                                  return (
                                    <MailListItemListView
                                      data={mailItem}
                                      key={index}
                                     
                                    />
                                  );
                                }
                              )}
                          </>
                        )}

                        {displayType == 1 && (
                          <div className="row text-center align-items-center justify-content-center">
                            {searchDisplayType == 0 &&
                              this.props.mailList &&
                              this.props.mailList.mailList.map(
                                (mailItem, index) => {
                                  return (
                                    <MailingItemGridView
                                      data={mailItem}
                                      key={index}
                                    />
                                  );
                                }
                              )}
                          </div>
                        )}

                        {searchDisplayType == 2 && (
                          <div className="container my-2">
                            {mailListBaseOnPerson.map((data, index) => {
                              return (
                                <SearchMailListBaseOnPerson
                                  data={data}
                                  key={index}
                                  selectedPersonId={selectedPersonId}
                                  handleMailWiseResponse={(
                                    personListBaseOnMailID,
                                    personResponse,
                                    personListBreadCumb,
                                    group_id
                                  ) => {
                                    this.handleMailWiseResponse(
                                      personListBaseOnMailID,
                                      personResponse,
                                      personListBreadCumb,
                                      group_id
                                    );
                                  }}
                                />
                              );
                            })}
                          </div>
                        )}

                        {searchDisplayType == 3 && (
                          <div className="container my-2">
                            <nav aria-label="breadcrumb">
                              <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                  {t("mailDetail.home")}
                                </li>
                                <li
                                  className="breadcrumb-item pointer"
                                  aria-current="page"
                                  onClick={() => {
                                    // const mailDetailId = breadcrumb[0]?.id
                                    // this.props.history.push("/mail-detail/" + mailDetailId);
                                  }}
                                >
                                  {personListBreadCumb[0]?.name}
                                </li>
                                <li
                                  className="breadcrumb-item active"
                                  aria-current="page"
                                >
                                  {personListBreadCumb[1]?.name}
                                </li>
                              </ol>
                            </nav>

                            {personListBaseOnMailID.map((data, index) => {
                              return (
                                <SearchPersonBaseOnMail
                                  handleSearchDisplayType={(
                                    searchDisplayType
                                  ) => {
                                    this.handleSearchDisplayType(
                                      searchDisplayType
                                    );
                                  }}
                                  data={data}
                                  key={index}
                                  groupIdBaseOnPersonDetail={
                                    groupIdBaseOnPersonDetail
                                  }
                                  personResponse={this.state.personResponse}
                                />
                              );
                            })}
                          </div>
                        )}

                        {searchDisplayType == 4 && (
                          <div className="container my-2">
                            {personListBaseOnMailID.map((data, index) => {
                              return (
                                <SearchPersonBaseonCompany
                                  handleSearchDisplayType={(
                                    searchDisplayType
                                  ) => {
                                    this.handleSearchDisplayType(
                                      searchDisplayType
                                    );
                                  }}
                                  data={data}
                                  key={index}
                                  personResponse={this.state.personResponse}
                                  groupIdBaseOnPersonDetail={
                                    groupIdBaseOnPersonDetail
                                  }
                                />
                              );
                            })}
                          </div>
                        )}

                        {this.props.mailList.hasMore &&
                          searchDisplayType == 0 &&
                          mailListPermission && (
                            <div className="text-center mt-5">
                              <button
                                type="button"
                                className="btn btn-primary-theme list"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.loadItem();
                                }}
                              >
                                {t("HomeScreen.showMore")}
                              </button>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FooterDesktop />
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps([stateToProps.mailListStateToProps], state),
  actions.appMapDispatchToProps([
    actions.mailListDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(MailingList)));
