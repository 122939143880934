/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PersonDataTypeList from "./PersonDataTypeList";
import AddPerson from "./AddPerson";
import EditNewGroup from "./EditNewGroup";
import PersonDataTypeValue from "./PersonDataTypeValue";
import PersonList from "./PersonList";
import RearrangePerson from "./RearrangePerson";
class Index extends Component {
  constructor(props) {

    super(props);
    this.state = {
      routeStatus: 0,
      personDataTypeId:0,
      personDataTypeName:'',
      filterValue:'',
      filterSortType:1
    };
  }
  changeRouteStatus = value => {
    this.setState(prevState => {
      return {
        routeStatus: value
      };
    });
  };

  
  handleClosePersonDataType=()=>{
    this.setState({
      personDataTypeId:0,
      personDataTypeName:'',
      filterValue:'',
      routeStatus: 0,
    })
  }

  handleFilterSortType= (filterSortType)=>{
    this.setState({
      filterSortType,
      routeStatus: 0,
    })
  }
  handleApplyFilter =(filterValue)=>{
    console.log("handleApplyFilter -->",filterValue);
    if(filterValue==''){
      this.setState({
        routeStatus: 0,
        filterValue,
      personDataTypeName:'',
        personDataTypeId:0
      })
    }else{
      this.setState({
        routeStatus: 0,
        filterValue,
      })
    }
  }
  setPersonDataTypeId=(personDataTypeId)=>{
    this.setState({
      personDataTypeId,
    })
  }

  setPersonDataTypeName=(personDataTypeName)=>{
    this.setState({
      personDataTypeName,
    })
  }
  render() {
    const { routeStatus,filterValue,personDataTypeId,filterSortType } = this.state;

    return (
      <React.Fragment>
        <div className="wrapper position-relative">
          {routeStatus == 0 && (
            <PersonList
            filterValue={filterValue||''}
            personDataTypeId={personDataTypeId||0}
            filterSortType={filterSortType}
            changeRouteStatus={this.changeRouteStatus} />
          )}
          {routeStatus == 1 && (
            <AddPerson changeRouteStatus={this.changeRouteStatus} />
          )}
          {routeStatus == 2 && (
            <EditNewGroup changeRouteStatus={this.changeRouteStatus} />
          )}
          {routeStatus == 3 && (
            <RearrangePerson changeRouteStatus={this.changeRouteStatus} />
          )}
           {routeStatus == 4 && (
             <PersonDataTypeList changeRouteStatus={this.changeRouteStatus}
             handleClosePersonDataType={()=>{this.handleClosePersonDataType()}}
             setPersonDataTypeId={(personDataTypeId)=>{this.setPersonDataTypeId((personDataTypeId))}} 
             setPersonDataTypeName={(name)=>{this.setPersonDataTypeName((name))}} 
             handleFilterSortType={(sortType)=>{this.handleFilterSortType((sortType))}}
             filterSortType={filterSortType}
             />
          )}
           {routeStatus == 5 && (
             <PersonDataTypeValue 
             handleApplyFilter={(filterValue)=>{this.handleApplyFilter(filterValue)}}
             personDataTypeId = {this.state.personDataTypeId} 
             personDataTypeName = {this.state.personDataTypeName} 
             handleClosePersonDataType={()=>{this.handleClosePersonDataType()}}
             changeRouteStatus={this.changeRouteStatus} />
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(Index);
