/* eslint eqeqeq: 0 */
import de from "date-fns/locale/de"; // the locale you want
import { Field, Formik } from "formik";
import moment from "moment";
import React, { Component } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Autocomplete, Form } from "react-formik-ui";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CreateEventQuery,
  EventBrandTypeQuery,
  EventlocationTypeQuery
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID, months, ToTimeStamp } from "../../../util/utils";
import { CreateEventSchema } from "../../../util/validationSchema";
import ErrorMessageFormik from "../../CommanComponents/ErrorMessageFormik";
import BrandTypeList from "./BrandTypeList";
registerLocale("de", de);

class AddNewEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: false,
      brandList: [],
      location_list: [],
      brandType: "",
      brandImage: "",
      brandID: 0,
      startDate: new Date(),
      startTime: new Date(),
      datePickerIsOpen: false,
      timePickerIsOpen: false,
      brandTypeID: "",
      isLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.openDatePicker = this.openDatePicker.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.openTimePicker = this.openTimePicker.bind(this);
  }

  handleBrandSelection = (item) => {
    const { name, icon, id } = item;
    this.setState(() => {
      return {
        ...this.state,
        status: false,
        brandType: name,
        brandImage: icon,
        brandID: id,
      };
    });
  };

  closeBrandDialogue = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: false,
      };
    });
  };

  openBrandDialogue = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
      };
    });
  };

  openDatePicker() {
    this.setState({
      ...this.state,
      datePickerIsOpen: !this.state.datePickerIsOpen,
    });
  }

  handleChange(date) {
    this.setState({
      ...this.state,
      startDate: date,
      datePickerIsOpen: false,
    });
  }

  openTimePicker() {
    this.setState({
      ...this.state,
      timePickerIsOpen: !this.state.timePickerIsOpen,
    });
  }

  handleTimeChange(time) {
    this.setState({
      ...this.state,
      startTime: time,
      timePickerIsOpen: false,
    });
  }

  handleAfterCreateApiCall = (data) => {
    toast.success(this.props.t(data.message));
    const { operation } = this.props;
    let event = {
      id: data.id,
      name: data.name,
      event_datetime: data.event_datetime,
      event_type_icon: data.event_type_icon,
      event_creator_name: data.event_creator_name,
      location: data.location,
    };

    if (operation == "edit") {
      this.props.editEvent(event);
      this.props.editEventReport(event);
      this.props.changeRouteStatus(0);
    } else {
      let count = this.props.eventList.total_event;
      count = count && count + 1;
      this.props.setEventCount(count);
      this.props.addNewEvent([event]);
      this.props.changeStatus(0);
    }
  };

  handleAfterBrandApiCall = (data) => {
    let brandImageDefault, brandNameDefault, brandID;
    if (data.event_type_list && data.event_type_list.langth !== 0) {
      brandNameDefault = data.event_type_list[0].name;
      brandImageDefault = data.event_type_list[0].icon;
      brandID = data.event_type_list[0].id;
    }
    const {
      event_type_icon,
      event_type_id,
      event_type_name,
      event_datetime,
    } = this.props.reportList;

    const { operation } = this.props;

    this.setState(
      () => {
        return {
          brandList: data.event_type_list,
          brandType:
            operation == "edit" && event_type_name
              ? event_type_name
              : brandNameDefault,
          brandImage:
            operation == "edit" && event_type_icon
              ? event_type_icon
              : brandImageDefault,
          brandID:
            operation == "edit" && event_type_id ? event_type_id : brandID,
          startDate:
            operation == "edit" && event_datetime
              ? new Date(moment.unix(event_datetime))
              : new Date(),
          startTime:
            operation == "edit" && event_datetime
              ? new Date(moment.unix(event_datetime))
              : new Date(),
        };
      },
      () => this.getLocationList()
    );
  };

  handleSubmit = (values) => {
    const { operation } = this.props;
    const { startDate, startTime, brandID } = this.state;
    const { event_name, location } = values;
    const userID = getUserID();
    let timeStamp = this.generateTimeStamp(startDate, startTime);
    let eventid = operation == "edit" ? this.props.reportList.id : "";
    apiCall(
      METHOD.QUERY,
      CreateEventQuery(
        userID,
        eventid,
        brandID,
        location,
        event_name,
        timeStamp
      ),
      (data) => this.handleAfterCreateApiCall(data, location),
      (isLoading) =>
        this.setState(() => {
          return { ...this.state, isLoading: isLoading };
        }),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  componentDidMount() {
    apiCall(
      METHOD.QUERY,
      EventBrandTypeQuery(getUserID(),0),
      (data) => {
        this.handleAfterBrandApiCall(data);
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  getLocationList = () => {
    apiCall(
      METHOD.QUERY,
      EventlocationTypeQuery(getUserID()),
      (data) => {
        const { location_id } = this.props.reportList;
        const { operation } = this.props;
        let locationName = [];
        let locationList = data.location_list;
        for (let i = 0; i < locationList.length; i++) {
          let item = locationList[i];
          locationName.push(item.name);
        }
        this.setState({
          location_list: locationName,
          locationID: operation == "edit" && location_id ? location_id : 0,
        });
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  generateTimeStamp = (dates, time) => {
    var date = dates.getDate();
    var month = months[dates.getMonth()];
    var year = dates.getFullYear();
    var hours = time.getHours();
    var minutes = time.getMinutes();
    var seconds = time.getSeconds();

    var shortDateTime =
      month +
      " " +
      date +
      " " +
      year +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds;

    return ToTimeStamp(shortDateTime);
  };

  render() {
    const {
      location_list,
      brandList,
      status,
      brandImage,
      brandType,
      isLoading,
    } = this.state;
    const { operation, changeRouteStatus } = this.props;

    const { location, name } = this.props.reportList;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <Formik
                enableReinitialize
                initialValues={{
                  event_name: operation == "edit" && name ? name : "",
                  location: operation == "edit" && location ? location : "",
                }}
                validationSchema={CreateEventSchema(
                  t("AddNewEvent.tooShort"),
                  t("AddNewEvent.tooLong"),
                  t("AddNewEvent.eventNameErrorMessage"),
                  t("AddNewEvent.locationErrorMessage")
                )}
                onSubmit={(values) => {
                  this.handleSubmit(values);
                }}
              >
                {({ errors, touched, values, handleSubmit, setErrors }) => (
                  <React.Fragment>
                    <div className="header-bg header-red sab-header1 sticky-top">
                      <div className="container">
                        <div className="py-2 d-flex align-items-center">
                          {operation == "edit" ? (
                            <span
                              className="pointer"
                              onClick={() => changeRouteStatus(0)}
                            >
                              <i className="zmdi zmdi-close zmdi-hc-fw font-30 text-light"></i>
                            </span>
                          ) : (
                            <span
                              className="pointer"
                              onClick={() => this.props.changeStatus(0)}
                            >
                              <i className="zmdi zmdi-close zmdi-hc-fw font-30 text-light"></i>
                            </span>
                          )}

                          {isLoading ? (
                           <div className="btn btn-light font-13 ml-auto pointer"  onClick={handleSubmit}> 
                              {t("AddNewEvent.btnSave")}
                              <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                <span className="sr-only">
                                  {t("GeneralMessages.loading")}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="btn btn-light font-13 ml-auto pointer"  onClick={handleSubmit}> {t("AddNewEvent.btnSave")}</div>
                          
                          )}
                        </div>
                        <div className="text-center mt-4 text-light">
                          <h2 className="m-0 text-light">
                            {operation == "edit"
                              ? t("AddNewEvent.EditEventLable")
                              : t("AddNewEvent.newEventLable")}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="middle-section sab-middle-section1">
                      <div className="container my-4 00z-index-99">
                        <div className="form-group max-170 m-auto text-center">
                          <label className="font-14">
                            {" "}
                            {t("AddNewEvent.type")}
                          </label>
                          <div
                            className="icon-drop-bg pointer"
                            onClick={this.openBrandDialogue}
                          >
                            <div className="rounded-circle ">
                              <img
                                src={`data:image/png;base64,${
                                  brandImage && brandImage
                                }`}
                                alt="ico"
                              />
                            </div>
                            <span className="font-12">
                              {brandType && brandType}
                            </span>
                          </div>

                          <BrandTypeList
                            closeBrandDialogue={this.closeBrandDialogue}
                            handleBrandSelection={this.handleBrandSelection}
                            status={status}
                            BrandList={brandList}
                          />
                        </div>
                        <div className="form-group">
                          <label className="font-14">
                            {" "}
                            {t("AddNewEvent.title")}
                          </label>
                          <Field
                            type="text"
                            name="event_name"
                            placeholder={t("AddNewEvent.title")}
                            className="form-control"
                            required
                          />
                          <ErrorMessageFormik name="event_name" />
                        </div>
                        <div className="form-group">
                          <div className="form-group">
                            <label
                              htmlFor="exampleInputPassword1"
                              className="font-14"
                            >
                              {t("AddNewEvent.location")}
                            </label>
                            <Form mode="structured">
                              <Autocomplete
                                name="location"
                                placeholder={t("AddNewEvent.selectLocation")}
                                className="custom-select"
                                suggestions={location_list}
                                required
                              />
                            </Form>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <label className="font-14">
                              {t("AddNewEvent.date")}
                            </label>
                            <div className="input-group">
                              <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleChange}
                                onClickOutside={this.openDatePicker}
                                open={this.state.datePickerIsOpen}
                                dateFormat="dd.MM.yyyy"
                                locale="de"
                                className="form-control combine-datepicker"
                                disabled
                              />
                              <div
                                className="input-group-append pointer"
                                onClick={this.openDatePicker}
                              >
                                <span className="input-group-text bg-white">
                                  <i className="zmdi zmdi-calendar-note zmdi-hc-fw font-22"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <label className="font-14">
                              {t("AddNewEvent.time")}
                            </label>

                            <div className="form-group">
                              <div
                                className="input-group date"
                                id="datetimepicker3"
                              >
                                <DatePicker
                                  selected={this.state.startTime}
                                  onChange={this.handleTimeChange}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption={t("AddNewEvent.timeCaption")}
                                  dateFormat="HH:mm"
                                  locale="de"
                                  onClickOutside={this.openTimePicker}
                                  open={this.state.timePickerIsOpen}
                                  className="form-control combine-timepicker pointer"
                                  disabled
                                />

                                <div
                                  className="input-group-append pointer"
                                  onClick={this.openTimePicker}
                                >
                                  <span className="input-group-text bg-white">
                                    <i className="zmdi zmdi-time zmdi-hc-fw font-22"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </Formik>
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.eventListStateToProps,
        stateToProps.reporttListStateToProps,
        stateToProps.eventEventCountStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.eventListDispatchToProps,
    actions.asyncApiDispatchToProps,
    actions.reportListDispatchToProps,
    actions.editEventReportDispatchToProps,
  ])
)(withRouter(withTranslation()(AddNewEvent)));
