import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import onClickOutside from "react-onclickoutside";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import {
  checkUserPermission,
  getValueFromLocalStorage,
  saveDatatoLocalStorage,
} from "../../util/utils";
import SideMenuDesktop from "./SideMenuDesktop";

class HeaderDesktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerMenuStatus: false,
      placeHolder: "",
      selectionType:0,
      selfServicePermission:false,
      hoverType:-1
    };
  }

  handleLogout = () => {
    const systemLogo = localStorage.getItem("system_logo");
    localStorage.clear();
    saveDatatoLocalStorage("system_logo", systemLogo);

    const { pathname } = this.props.location;
    const location = {
      pathname: `/`,
      state: { from: { pathname: pathname } },
    };
    this.props.history.push(location);
  };

  handleHeaderMenu = () => {
    this.setState((prevState) => {
      return {
        headerMenuStatus: !prevState.headerMenuStatus,
      };
    });
  };
  handleLogout = () => {
    const systemLogo = localStorage.getItem("system_logo");
    localStorage.clear();
    saveDatatoLocalStorage("system_logo", systemLogo);

    const { pathname } = this.props.location;
    const location = {
      pathname: `/`,
      state: { from: { pathname: pathname } },
    };
    this.props.history.push(location);
  };

  handleChangePassword = () => {
    const { pathname } = this.props.location;
    const location = {
      pathname: "/change-password",
      state: { from: { pathname: pathname } },
    };
    this.props.history.push(location);
  };

  handelManageProfile = () => {
    const { pathname } = this.props.location;
    const location = {
      pathname: "/update-profile",
      state: { from: { pathname: pathname } },
    };
    this.props.history.push(location);
  };

  changeLanguage = () => {
    const { pathname } = this.props.location;
    const location = {
      pathname: "/change-language",
      state: { from: { pathname: pathname } },
    };
    this.props.history.push(location);
  };

  handleClickOutside = () => {
    if (this.state.headerMenuStatus) {
      this.handleHeaderMenu();
    }

  };

  componentDidMount() {

    const selfServicePermission = checkUserPermission("self_service");

    let first_name, name;

    first_name =
      getValueFromLocalStorage("first_name") &&
      getValueFromLocalStorage("first_name").charAt(0).toUpperCase();
    name =
      getValueFromLocalStorage("name") &&
      getValueFromLocalStorage("name").charAt(0).toUpperCase();

    this.setState({
      placeHolder: (first_name && first_name) + (name && name),
      image: getValueFromLocalStorage("image"),
      firstName: getValueFromLocalStorage("first_name"),
      lastName: getValueFromLocalStorage("name"),
      selfServicePermission
    });


    const selectionType = this.props.selectionType
    this.setState({
      selectionType
    })
  }
  handleLoader = (status) => {
    this.setState(() => {
      return {
        ...this.state,
        isLoading: status,
      };
    });
  };

  render() {
    const {
      headerMenuStatus,
      placeHolder,
      image,
      firstName,
      lastName,
      selectionType,
      selfServicePermission,
      hoverType   
    } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <nav className={hoverType==0?("navbar navbar-expand-lg fixed-top event-header"):
            hoverType==1?("navbar navbar-expand-lg fixed-top event-header"):
            hoverType==2?("navbar navbar-expand-lg fixed-top group-header"):
            hoverType==3?("navbar navbar-expand-lg fixed-top list-header"):
            ("navbar navbar-expand-lg fixed-top")}>
              <div className="container">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="logo mr-auto  ">
                  <Link className="navbar-brand" to="/">
                    {image ? (
                      <img
                        src={`data:image/png;base64,${image}`}
                        alt="loginLogo"
                        style={{ width: "60px", height: "60px" }}
                      />
                    ) : (
                      <img
                        src="/images/login_logo.png"
                        alt="loginLogo"
                        style={{ width: "60px", height: "60px" }}
                      />
                    )}
                  </Link>
                </div>

                <SideMenuDesktop />
                <div
                  className="order-lg-2 d-flex align-items-center ml-lg-3"
                  onClick={this.handleHeaderMenu}
                >
                  <div className="dropdown user-menu ml-3">
                    <a
                      href="#"
                      data-toggle="dropdown"
                      aria-expanded={headerMenuStatus}
                    >
                      <span className="user-name">
                        {placeHolder && placeHolder}
                      </span>
                      <span className="full-name">
                        {firstName && firstName} {lastName && lastName}
                      </span>
                    </a>
                    <div
                      className={
                        headerMenuStatus
                          ? "dropdown-menu dropdown-menu-right show"
                          : "dropdown-menu dropdown-menu-right"
                      }
                    >
                      <div className="dropdown-menu-grid">
                       
                       {selfServicePermission && <a
                          className="dropdown-item"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handelManageProfile();
                          }}
                          href="javascript;:"
                        >
                          <i className="zmdi zmdi-account zmdi-hc-lg font-size-18"></i>{" "}
                          <span className="ml-2">
                            {t("logOutMenu.manageProfile")}
                          </span>
                        </a>}
                        

                        <a
                          className="dropdown-item"
                          onClick={(e) => {
                            e.preventDefault();
                            this.changeLanguage();
                          }}
                          href="javascript;:"
                        >
                          <i className="zmdi zmdi-globe-alt zmdi-hc-lg font-size-18"></i>
                          <span className="ml-2">
                            {" "}
                            {t("logOutMenu.changeLanguage")}
                          </span>
                        </a>

                          {selfServicePermission && <a
                          className="dropdown-item"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleChangePassword();
                          }}
                          href="javascript;:"
                        >
                          <i className="zmdi zmdi-lock-outline zmdi-hc-lg font-size-18"></i>{" "}
                          <span className="ml-2">
                            {t("logOutMenu.changePassword")}
                          </span>
                        </a>}
                        
                        <a
                          className="dropdown-item"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleLogout();
                          }}
                          href="javascript;:"
                        >
                          <i className="zmdi zmdi-power zmdi-hc-lg font-size-18"></i>
                          <span className="ml-2">
                            {t("logOutMenu.logOut")}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ml-auto">
                    <li className={selectionType==1?("nav-item active event"):("nav-item")} 
                    onMouseEnter={() => this.setState({hoverType:0})}
                    onMouseLeave={() => this.setState({hoverType:-1})}
                    >
                      <Link className="nav-link" to="/">
                        {t("FooterScreen.start")}
                      </Link>
                    </li>
                    <li className={selectionType==2?("nav-item active event"):("nav-item")}
                         onMouseEnter={() => this.setState({hoverType:1})}
                         onMouseLeave={() => this.setState({hoverType:-1})}
                    >
                      <Link className="nav-link event" to="/event-list">
                        {t("FooterScreen.event")}
                      </Link>
                    </li>
                    <li className={selectionType==3?("nav-item active group"):("nav-item")}
                    onMouseEnter={() => this.setState({hoverType:2})}
                    onMouseLeave={() => this.setState({hoverType:-1})}>
                      <Link
                        className="nav-link"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.clearGroupState();
                          this.props.history.push("/group-list");
                        }}
                        href="javascript:;"
                      >
                        {t("FooterScreen.contactPerson")}
                      </Link>
                    </li>
                    <li className={selectionType==4?("nav-item active list"):("nav-item")}
                   onMouseEnter={() => this.setState({hoverType:3})}
                   onMouseLeave={() => this.setState({hoverType:-1})}>
                      <Link to="/mail-list" className="nav-link">
                        {t("FooterScreen.distributor")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) => stateToProps.appMapStateToProps([], state),
  actions.appMapDispatchToProps([actions.groupListDispatchToProps])
)(withRouter(withTranslation()(onClickOutside(HeaderDesktop))));
