/* eslint eqeqeq: 0 */
import moment from "moment-timezone";
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { ReortDetailQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { checkUserPermission } from "../../../util/utils";
import CommonLoader from "../../CommanComponents/CommonLoader";
import LoadingPage from "../../CommanComponents/LoadingPage";
import FooterDesktop from "../../Footer/FooterDesktop";
import HeaderDesktop from "../../Header/HeaderDesktop";
import AddNewChannelReport from "./AddNewChannelReport";
import EditNewReport from "./EditNewReport";

const snippet_type_person_progress = ["text2speech", "sms", "email"];

class ChannelList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerMenuStatus: false,
      isLoading: false,
      reportDetail: {},
      reportList: [],
      editReportModalShow: false,
      createChannelModalShow: false,
      editReportPermission: false,
      createMessagePermission: false,
    };
  }
  
  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };
  openSlider = () => {
    this.setState(() => {
      return {
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        headerMenuStatus: false,
      };
    });
  };

  handleApiResponse = (data) => {
    this.setState({
      reportDetail: data,
    });
  };

  componentDidMount() {
    const report_id = this.props.match.params.report_id;
    const editReportPermission = checkUserPermission("edit_report");
    const createMessagePermission = checkUserPermission("create_message");
    this.setState({
      editReportPermission,
      createMessagePermission,
    });

    this.getChannelList(report_id);
  }

  setChannelID = (report_id) => {
    let event_id = this.props.match.params.event_id;
    let myPath = `/event/${event_id}/report/${report_id}`;
    this.props.history.push(myPath);
    this.getChannelList(report_id);
  };

  handleActiveIndex = (report_id, id) => {
    if (report_id == id) {
      return "active";
    } else {
      return "";
    }
  };

  getChannelList = (report_id) => {
    apiCall(
      METHOD.QUERY,
      ReortDetailQuery(report_id),
      (data) => {
        this.props.getChannelList(data);
        this.handleApiResponse(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleMessageStatus = (
    message_status,
    percentage_status,
    message_status_identifier
  ) => {
    if (
      message_status_identifier != "sending_failed" &&
      message_status_identifier != "sent_successfully"
    ) {
      let messageValue = message_status + "(" + percentage_status + " %)";
      return messageValue;
    } else {
      return message_status;
    }
  };

  loader = (<LoadingPage />);

  handleBackStack = () => {
    let mybackStackpath = "/event/" + this.props.match.params.event_id;
    let { from } = this.props.location.state || {
      from: { pathname: mybackStackpath },
    };
    this.props.history.push(from);
  };

  handelChannelClick = (message_id, event_id) => {
    const { pathname } = this.props.location;
    const location = {
      pathname: `${this.props.history.location.pathname}/message/${message_id}`,
      state: { from: { pathname: pathname } },
    };
    this.props.history.push(location);
  };

  handlesendDate = (message_send_datetime, event_datetime) => {
    const event_date = moment
      .unix(event_datetime)
      .tz(moment.tz.guess())
      .format("DD.MM.YYYY");
    const message_send_date = moment
      .unix(message_send_datetime)
      .tz(moment.tz.guess())
      .format("DD.MM.YYYY");

    if (event_date == message_send_date) {
      return (
        <span className="mr-3 mb-1 mb-md-0">
          <i className="zmdi zmdi-alarm-check zmdi-mail-fw font-16 mr-2"></i>
          {moment
            .unix(message_send_datetime)
            .tz(moment.tz.guess())
            .format("HH:mm")}
        </span>
      );
    } else {
      return (
        <span className="mr-3 mb-1 mb-md-0">
          <i className="zmdi zmdi-alarm-check zmdi-mail-fw font-16 mr-2"></i>
          {moment
            .unix(message_send_datetime)
            .tz(moment.tz.guess())
            .format("DD.MM.YYYY")}
        </span>
      );
    }
  };

  handleAddnewChannel = () => {
    this.setState(
      (prev) => {
        return {
          createChannelModalShow: !prev.createChannelModalShow,
        };
      },
      () => {
        if (this.state.createChannelModalShow) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  handleEditReportModal = () => {
    this.setState(
      (prev) => {
        return {
          editReportModalShow: !prev.editReportModalShow,
        };
      },
      () => {
        if (this.state.editReportModalShow) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  render() {
    const {
      event_name,
      report_datetime,
      report_creator_name,
      report_type_icon,
      channel_list,
      event_id,
      report_id,
      report_list,
      title,
    } = this.props.channelList;
    const {
      isLoading,
      createChannelModalShow,
      editReportModalShow,
      editReportPermission,
      createMessagePermission,
    } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="content-wrapper">
              <HeaderDesktop selectionType={2} />
              <div className="content">
                <div className="section section-padding">
                  <div className="container">
                    <div className="section-container">
                      <div className="d-flex t-n-25">
                        <Link to="/home" className="mr-3">
                          <i className="fa fa-arrow-left fa-lg font-size-18"></i>
                        </Link>
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                              <Link to="/home">{t("EventDetails.event")} </Link>
                            </li>
                            <li className="breadcrumb-item">
                              <Link to={`/event/${event_id}`}>
                                {event_name && event_name}{" "}
                              </Link>
                            </li>
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                              {title && title}
                            </li>
                          </ol>
                        </nav>
                      </div>
                      <div className="section-title text-center">
                        <h1 className="page-title h4">{title && title}</h1>
                      </div>
                      <div className="section-content">
                        <div className="d-flex align-items-center justify-content-center mb-4">
                          <ul className="list-inline custom-tab event">
                            {report_list &&
                              report_list.map((data, index) => {
                                const { id, title } = data;
                                return (
                                  <li className="list-inline-item" key={index}>
                                    <a
                                      href="javascript;:"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setChannelID(id);
                                      }}
                                      className={this.handleActiveIndex(
                                        report_id,
                                        id
                                      )}
                                    >
                                      {title && title}{" "}
                                    </a>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                        <div className="card">
                          <div className="card-header-custom">
                            <div className="media">
                              <div className="bg-grey left-icon-box d-flex align-items-center justify-content-center text-center">
                                <div
                                  className="bg-red left-icon-box d-flex align-items-center justify-content-center text-center"
                                  style={{ background: "#b31218" }}
                                >
                                  <div className="circle big m-0">
                                    <img
                                      src={`data:image/png;base64,${
                                        report_type_icon && report_type_icon
                                      }`}
                                      alt="ico"
                                      style={{ width: "32px" }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="media-body pt-3 px-4">
                                <div className="form-row">
                                  <div className="col-md-7">
                                    <h5 className="font-size-18 mb-1">
                                      {title && title}
                                    </h5>
                                    <small>
                                      {report_creator_name &&
                                        report_creator_name}
                                    </small>
                                    <div className="d-flex align-items-center font-size-14 mt-2">
                                      <div className="icon mr-3">
                                        <i className="zmdi zmdi-calendar mr-1"></i>{" "}
                                        {report_datetime &&
                                          moment
                                            .unix(report_datetime)
                                            .tz(moment.tz.guess())
                                            .format("DD.MM.YYYY")}
                                      </div>
                                      <div className="icon mr-3">
                                        <i className="zmdi zmdi-time mr-1"></i>{" "}
                                        {report_datetime &&
                                          moment
                                            .unix(report_datetime)
                                            .tz(moment.tz.guess())
                                            .format("HH:mm")}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-5">
                                    <div className="font-size-14 action-icon text-right">
                                      {createMessagePermission && (
                                        <a
                                          href="javascript;:"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.handleAddnewChannel();
                                          }}
                                          className="mr-4"
                                        >
                                          <i className="zmdi zmdi-file-plus zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                          {t("AddNewChannel.createChannel")}
                                        </a>
                                      )}

                                      {editReportPermission && (
                                        <a
                                          href="javascript;:"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.handleEditReportModal();
                                          }}
                                          className="mr-4"
                                        >
                                          <i className="zmdi zmdi-edit zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                          {t("AddNewChannel.editReport")}
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-body-custom side-line">
                            {isLoading && <CommonLoader />}
                            {channel_list &&
                              channel_list.map((channel, index) => {
                                const {
                                  message_id,
                                  channel_type_name,
                                  channel_type_identifier,
                                  message_datetime,
                                  message_creator_name,
                                  message_send_datetime,
                                  message_status,
                                  percentage_status,
                                  message_status_identifier,
                                  channel_type_secondary_icon,
                                  channel_type_color,
                                } = channel;
                                const sendingStatusFromTranslation = `sendingStatus.${message_status_identifier}`;

                                return (
                                  <a
                                    href="javascript;:"
                                    className="media before-line"
                                    key={index}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.handelChannelClick(
                                        message_id,
                                        event_id
                                      );
                                    }}
                                  >
                                    <div className="left-icon-box small">
                                      <div
                                        className="before-line-color"
                                        style={{
                                          backgroundColor: channel_type_color,
                                        }}
                                      ></div>

                                      <div
                                        className="circle "
                                        style={{
                                          backgroundColor: channel_type_color,
                                        }}
                                      >
                                        <img
                                          src={`data:image/png;base64,${
                                            channel_type_secondary_icon &&
                                            channel_type_secondary_icon
                                          }`}
                                          alt="email"
                                          style={{ width: "24px" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="media-body pt-3 px-4">
                                      <h6 className="font-size-15 mb-1">
                                        {channel_type_name && channel_type_name}
                                      </h6>
                                      <small>
                                        {" "}
                                        {message_creator_name &&
                                          message_creator_name}
                                      </small>
                                      <div className="d-flex align-items-center font-size-13 mt-1">
                                        <div className="icon mr-3">
                                          <i className="zmdi zmdi-calendar zmdi-hc-fw"></i>{" "}
                                          {message_datetime &&
                                            moment
                                              .unix(message_datetime)
                                              .tz(moment.tz.guess())
                                              .format("DD.MM.YYYY")}
                                        </div>
                                        <div className="icon mr-3">
                                          <i className="zmdi zmdi-time zmdi-hc-fw"></i>{" "}
                                          {message_datetime &&
                                            moment
                                              .unix(message_datetime)
                                              .tz(moment.tz.guess())
                                              .format("HH:mm")}
                                        </div>
                                        <span className="mr-3 mb-1 mb-md-0">
                                          <i className="zmdi zmdi-time zmdi-hc-fw font-16 mr-2p"></i>

                                          {message_datetime &&
                                            moment
                                              .unix(message_datetime)
                                              .tz(moment.tz.guess())
                                              .format("HH:mm")}
                                        </span>

                                        {message_status &&
                                          snippet_type_person_progress.includes(
                                            channel_type_identifier
                                          ) && (
                                            <React.Fragment>
                                              <span className="mr-3 mb-1 mb-md-0">
                                                <i className="zmdi zmdi-mail-send zmdi-mail-fw font-16 mr-2"></i>
                                                {message_status_identifier !=
                                                  "sending_failed" &&
                                                  message_status_identifier !=
                                                    "sent_successfully" &&
                                                  t(
                                                    sendingStatusFromTranslation
                                                  ) +
                                                    " (" +
                                                    percentage_status +
                                                    "%)"}
                                                {message_status_identifier ==
                                                  "sending_failed" ||
                                                  (message_status_identifier ==
                                                    "sent_successfully" &&
                                                    t(
                                                      sendingStatusFromTranslation
                                                    ))}
                                              </span>
                                            </React.Fragment>
                                          )}

                                        {message_send_datetime &&
                                          message_status_identifier != "" &&
                                          message_status_identifier ==
                                            "sent_successfully" &&
                                          this.handlesendDate(
                                            message_send_datetime,
                                            message_datetime
                                          )}
                                      </div>
                                    </div>
                                  </a>
                                );
                              })}
                          </div>
                        </div>

                        {createChannelModalShow && (
                          <AddNewChannelReport
                            key="uniqueKey"
                            handleAddnewChannel={() => {
                              this.handleAddnewChannel();
                            }}
                          />
                        )}
                        {editReportModalShow && (
                          <EditNewReport
                            key="uniqueKey"
                            handleEditReportModal={() => {
                              this.handleEditReportModal();
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FooterDesktop selectionType={2} />
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.reporttListStateToProps,
        stateToProps.channelListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.reportListDispatchToProps,
    actions.channelListDispatchToProps,
  ])
)(withRouter(withTranslation()(ChannelList)));
