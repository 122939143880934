import React from "react";
import MediaQuery from "react-responsive";
import ReportMobile from "./ReportMobile/Index";
import ReportDesktop from "./ReportDesktop/ReportList";

export default function ReportResponsive() {
  return (
    <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <ReportDesktop />
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <ReportMobile />
      </MediaQuery>
    </React.Fragment>
  );
}
