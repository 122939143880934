import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { getUserID } from "../../../util/utils";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { toast } from "react-toastify";
import { getgroupdetailfromperson, getmailinglistgroupperson } from "../../../constants/apiParams";
class SearchGroupListBaseOnPerson extends Component {

  handelMailItemClick = (mail_id_range) => {
    this.props.history.push("/mail-detail/" + mail_id_range);
  };

  getPersonList = (group_id) => {
    apiCall(
      METHOD.QUERY,
      getgroupdetailfromperson(getUserID(),group_id,this.props.selectedPersonId ),
      (data) => {
       this.props.handleGroupWiseResponse(data.person_list,data,data.breadcrumb,group_id)
        // this.props.getMailDetails(data);
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  render() {
    const { data, index } = this.props;
    const { id,name,group_id,email } = data;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
              <div className="ml-100p m-3 clearfix d-flex align-items-center flex-fill pointer" onClick={()=>{
                this.getPersonList(id)
              }} >
                <div className="align-self-center">
                  <h5 className="m-0 font-18 d-block">{name && name}</h5>
               
                </div>
                <div className="align-self-center ml-auto">
                  <i className="zmdi zmdi-chevron-right zmdi-hc-fw font-26 text-dark"></i>
                </div>
              </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(withRouter(SearchGroupListBaseOnPerson));
