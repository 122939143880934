import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import {
  getallpersonbypersondata,
  getgrouplistfromperson,
  getmailinglistfromperson,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { toast } from "react-toastify";
import { getUserID } from "../../../util/utils";

import { connect } from "react-redux";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
class SearchPersonResult extends Component {
  handelMailItemClick = (mail_id_range) => {
    this.props.history.push("/mail-detail/" + mail_id_range);
  };

  getGroupPersonList = (personId) => {
    apiCall(
      METHOD.QUERY,
      getgrouplistfromperson(getUserID(), personId),
      (data) => this.handleGetGroupPersonResponse(data, personId),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleGetGroupPersonResponse = (data, personId) => {
    this.props.setGroupFromPerson(data.group_list, personId);
    // this.props.handleMailbaseOnPerson(data.mailing_list)
  };

  
  prepareSuggetionArray=(personList,company_list,data_list)=>{
    let suggetion =[]

    for (let index = 0; index < data_list.length; index++) {
      let data = data_list[index];
      data.type = "data";
      data.isFirst = index == 0 ? true : false;
      suggetion.push(data);
    }
    for (let index = 0; index < personList.length; index++) {
      let person = personList[index];
      person.type = "person";
      person.isFirst = index == 0 ? true : false;
      suggetion.push(person);
    }
    
    for (let index = 0; index < company_list.length; index++) {
      let company = company_list[index];
      company.type = "company";
      company.isFirst = index == 0 ? true : false;
      suggetion.push(company);
    }

    console.log("suggetion",suggetion);
    return suggetion
  }
  handlePersonDataTypeValue=(data)=>{
    this.props.handlePersonListBaseonCompany(data.person_list)
  }
  getPersonFromCompany=(personDataType)=>{
    apiCall(
      METHOD.QUERY,
      getallpersonbypersondata(getUserID(),personDataType),
      (data) => this.handlePersonDataTypeValue(data,personDataType),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  handleItemClick = (suggestion) => {
    const type = suggestion?.type;
    if (type == "person") {
      this.getGroupPersonList(suggestion?.id);
    } else if (type == "data") {
      const person = suggestion?.child_element;
      const group_id = suggestion?.id;
      if (person == 0) {
        this.props.history.push("/group-detail/" + group_id);
        this.props.clearStateGroupPersonDetail({});
        this.props.setPersonListHasMoreValue(true);
        this.props.setPersonListStartValue(0);
      } else {
        this.props.history.push("/group-personlist/" + group_id);
      }
    }else if(type=='company'){
      this.getPersonFromCompany(suggestion?.name)
    }
  };
  render() {
    const { suggetionList } = this.props;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="container my-2">
              {suggetionList.map((suggestion, index) => {
                return (
                  <React.Fragment>
                    {suggestion.type == "data" && (
                      <div className="bg-white shadow-sm p-3">
                        {suggestion?.isFirst && suggestion.type == "data" && (
                          <h5 className="mt-1 mb-3 text-muted">
                             <u>{t("GeneralMessages.groupList")}</u>
                          </h5>
                        )}
                        <ul className="list-unstyled autosuggest-list">
                          <div
                            className="icon-box-style2  pointer"
                            onClick={() => {
                              this.handleItemClick(suggestion);
                            }}
                          >
                            <div className="icon-box blue-bg d-flex">
                              <div className="d-flex align-items-center justify-content-center w-100">
                                <div className="rounded-50 bg-light">
                                  <img
                                    src={`data:image/png;base64,${
                                      suggestion.type_icon &&
                                      suggestion.type_icon
                                    }`}
                                    style={{ width: "32px" }}
                                    alt="icon"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="ml-3 my-3 d-inline-block">
                              <h5 className="m-0 font-18">
                                {suggestion.name || ""}
                              </h5>
                              <p className="text-muted font-12 mb-0">
                              {suggestion.child_element && suggestion.child_element}{" "}
                                    {suggestion?.child_element == 1
                                      ? t("GroupListScreen.singularPerson")
                                      : t("GroupListScreen.pluralPerson")}
                              </p>
                            </div>
                          </div>
                        </ul>
                      </div>
                    )}

                    {suggestion.type == "person" && (
                      <div className="bg-white shadow-sm p-3">
                        {suggestion?.isFirst && suggestion.type == "person" && (
                          <h5 className="mt-1 mb-3 text-muted">
                            <u>{t("GeneralMessages.personList")}</u>
                          </h5>
                        )}
                        <ul className="list-unstyled autosuggest-list">
                          <li
                            className="border-bottom pointer"
                            onClick={() => {
                              this.handleItemClick(suggestion);
                            }}
                          >
                            <h5 className="font-15 mb-1">{suggestion.name}</h5>
                            <p className="font-15 text-muted m-0">
                              {suggestion.company && suggestion.company}{" "}
                              {suggestion.job_title && suggestion.job_title}
                            </p>
                            <hr className="dotted" />
                          </li>
                        </ul>
                      </div>
                    )}

                    {suggestion.type == "company" && (
                      <div className="bg-white shadow-sm p-3 ">
                        {suggestion?.isFirst && suggestion.type == "company" && (
                          <h5 className="mt-1 mb-3 text-muted">
                            <u>{t("GeneralMessages.companyList")}</u>
                          </h5>
                        )}
                        <ul className="list-unstyled autosuggest-list">
                          <li
                            className=" pb-1 border-bottom pointer"
                            onClick={() => {
                              this.handleItemClick(suggestion);
                            }}
                          >
                            <h5 className="font-15 ">{suggestion.name}</h5>
                            <hr className="dotted" />

                            {/* <p className="font-15 text-muted m-0">{suggestion.company && suggestion.company}  {suggestion.job_title && suggestion.job_title}</p> */}
                          </li>
                        </ul>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.groupListStateToProps,
        stateToProps.groupDetailStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupListDispatchToProps,
    actions.groupDetailDispatchToProps,
    actions.groupDetailPersonListDispatchToProps,
  ])
)(withRouter(withTranslation()(SearchPersonResult)));
