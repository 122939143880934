/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AddNewEvent from "../../Event/EventListMobile/AddNewEvent";
import AddNewReport from "./AddNewReport";
import ReportList from "./ReportList";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeStatus: 0,
    };
  }
  changeRouteStatus = (value) => {
    this.setState((prevState) => {
      return {
        routeStatus: value,
      };
    });
  };
  render() {
    const { routeStatus } = this.state;
    return (
      <React.Fragment>
        <div className="wrapper position-relative">
          {routeStatus == 0 && (
            <ReportList changeRouteStatus={this.changeRouteStatus} />
          )}
          {routeStatus == 1 && (
            <AddNewEvent
              operation="edit"
              changeRouteStatus={this.changeRouteStatus}
            />
          )}
          {routeStatus == 2 && (
            <AddNewReport changeRouteStatus={this.changeRouteStatus} />
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(Index);
