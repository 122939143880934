import { Field, Formik } from "formik";
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { registerUser } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import { UpdateProfileSchema } from "../../util/validationSchema";
import ErrorMessageFormik from "../CommanComponents/ErrorMessageFormik";

 class RegisterUserMobile extends Component {
   constructor(props) {
     super(props);

     this.state = {
       isLoading: false,
       isRegisterSuccessfully: false,
       message:''
     };
   }
   handleSuccess = (message) => {
     
     
     this.setState({
       isRegisterSuccessfully: true,
       message,
     });
   }

   reutrnToLogin = () => {
      this.props.history.push("/home");
   }
   handleSubmit = (values, actions) => {
     const { name, first_name, email, password } = values;
     apiCall(
       METHOD.QUERY,
       registerUser(email, name, first_name, password),
       (data) => {
         this.handleSuccess(data.message);
         actions.resetForm({
           name: "",
           email: "",
           first_name: "",
           password: "",
           repear_password: "",
         });
       },
       (isLoading) => {
         this.setState({ isLoading });
       },
       (data) => {
         toast.error(this.props.t(data.message));
       }
     );
   };
   render() {
     const { isLoading,isRegisterSuccessfully,message} = this.state;
     return (
       <Translation ns="translation">
         {(t, { i18n }) => {
           return (
             <div className="wrapper position-relative">
               {isRegisterSuccessfully ? (
                 <React.Fragment>
                   <div className="header-bg header-blue sab-header1 sticky-top">
                     <div className="container">
                       <div className="text-center mt-4 text-light">
                         <h2 className="m-0 text-light">
                           {t("registerUser.title")}
                         </h2>
                       </div>
                     </div>
                   </div>
                   <div className="middle-section sab-middle-section1">
                     <div className="container my-4 00z-index-99">
                       <h4 className="m-0">{t(message)}</h4>
                     </div>
                     <button
                       type="button"
                       className="btn btn-success btn-block mt-2"
                       onClick={() => {
                         this.reutrnToLogin();
                       }}
                     >
                       {t("registerUser.returnToLogin")}
                     </button>
                   </div>
                 </React.Fragment>
               ) : (
                 <Formik
                   enableReinitialize
                   initialValues={{
                     name: "",
                     email: "",
                     first_name: "",
                     password: "",
                     repear_password: "",
                   }}
                   validationSchema={UpdateProfileSchema(
                     t("registerUser.first_name_error"),
                     t("registerUser.name_error"),
                     t("registerUser.email_error"),
                     t("registerUser.email_valid_error"),
                     t("registerUser.passwordValidation"),
                     t("registerUser.toShortValidation"),
                     t("registerUser.toLongValidation"),
                     t("registerUser.comparePasswordValidation")
                   )}
                   onSubmit={(values, actions) => {
                     this.handleSubmit(values, actions);
                   }}
                 >
                   {({ handleSubmit }) => (
                     <React.Fragment>
                       <div className="header-bg header-blue sab-header1 sticky-top">
                         <div className="container">
                        
                           <div className="text-center mt-4 text-light">
                             <h2 className="m-0 text-light">
                               {t("registerUser.title")}
                             </h2>
                           </div>
                         </div>
                       </div>
                       <div className="middle-section sab-middle-section1">
                         <div className="container my-4 00z-index-99">
                           <form>
                             <div className="form-group">
                               <label className="font-14">
                                 {t("registerUser.first_name")}
                               </label>
                               <Field
                                 type="text"
                                 name="first_name"
                                 placeholder={t(
                                   "registerUser.first_name_placeholder"
                                 )}
                                 className="form-control"
                                 required
                               />
                               <ErrorMessageFormik name="first_name" />
                             </div>

                             <div className="form-group">
                               <label className="font-14">
                                 {t("registerUser.name")}
                               </label>
                               <Field
                                 type="text"
                                 name="name"
                                 placeholder={t("registerUser.name")}
                                 className="form-control"
                                 required
                               />
                               <ErrorMessageFormik name="name" />
                             </div>

                             <div className="form-group">
                               <label className="font-14">
                                 {t("registerUser.email")}
                               </label>
                               <Field
                                 type="text"
                                 name="email"
                                 placeholder={t(
                                   "registerUser.email_placeholder"
                                 )}
                                 className="form-control"
                                 required
                               />
                               <ErrorMessageFormik name="email" />
                             </div>

                             <div className="form-group">
                               <label className="font-14">
                                 {t("registerUser.password")}
                               </label>
                               <Field
                                 type="password"
                                 name="password"
                                 placeholder="************"
                                 className="form-control paassword-value"
                                 required
                               />
                               <ErrorMessageFormik name="password" />
                             </div>

                             <div className="form-group">
                               <label className="font-14">
                                 {t("registerUser.confirm_Password")}
                               </label>
                               <Field
                                 type="password"
                                 name="repear_password"
                                 placeholder="************"
                                 className="form-control  paassword-value"
                                 required
                               />
                               <ErrorMessageFormik name="repear_password" />
                             </div>

                             <div className="form-group text-center mt-4">
                               {isLoading ? (
                                 <button
                                   type="button"
                                   onClick={handleSubmit}
                                   className="btn btn-success btn-block"
                                 >
                                   {t("registerUser.regiter_btn_lable")}

                                   <div className="spinner-border spinner-border-sm ml-1 text-white">
                                     <span className="sr-only">
                                       {t("GeneralMessages.loading")}
                                     </span>
                                   </div>
                                 </button>
                               ) : (
                                 <button
                                   type="button"
                                   onClick={handleSubmit}
                                   className="btn btn-success btn-block"
                                 >
                                   {t("registerUser.regiter_btn_lable")}
                                 </button>
                               )}
                             </div>
                           </form>
                         </div>
                       </div>
                     </React.Fragment>
                   )}
                 </Formik>
               )}
             </div>
           );
         }}
       </Translation>
     );
   }
 }
export default withTranslation()(withRouter(RegisterUserMobile));
