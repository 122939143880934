import React, { Component } from "react";
import Bounce from "react-reveal/Bounce";
import SnippetType from "./SnippetType";

export default class SnippetTypeList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      status,
      closeBrandDialogue,
      handleBrandSelection,
      BrandList,
    } = this.props;
    return (
      <div className="modal-custom-footer" style={{ display: "block" }}>
        <div className="modal-inner-content">
          <div className="card-header d-flex justify-content-between pb-0">
                  <button
                        className="btn pt-1 p-0"
                        onClick={closeBrandDialogue}
                        type="button"
                        data-toggle="collapse"
                        data-target="#Brand-toggler"
                        aria-controls="Brand-toggler"
                        aria-expanded="true"
                        aria-label="Toggle navigation"
                      >
                        <i className="zmdi zmdi-close zmdi-hc-fw font-size-28"></i>
                      </button>
          </div>
          <div className="card-body pt-3">
            <div className="text-center">
              <ul className="navbar-nav mr-auto">
                {BrandList &&
                  BrandList.map((item, index) => {
                    return (
                      <SnippetType
                        handleBrandSelection={handleBrandSelection}
                        key={index}
                        item={item}
                      />
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
