/* eslint eqeqeq: 0 */

import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import gql from "graphql-tag";
import { truncate } from "lodash";
import React, { Component } from "react";
import { ApolloProvider, Mutation } from "react-apollo";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { uploadLink } from "../../../../constants/baseUrl";
import { getUserID } from "../../../../util/utils";
import Footer from "../../../Footer/FooterMobile";

const apolloCache = new InMemoryCache();
const client_mutation = new ApolloClient({
  cache: apolloCache,
  link: uploadLink,
});

const Addreporttype = gql`
  mutation Addreporttype($profile: AddreporttypeInput!) {
    Addreporttype(input: $profile) {
      success
      message
    }
  }
`;

class AddReportType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      icon: undefined,
      whiteIcon: undefined,
      icon_file: undefined,
      whiteIcon_file: undefined,
      reportDialogueStatus: false,
      name: "",
      description: "",
      identifier: "",
    };
  }

  handleTextChange = (e, type) => {
    if (type == "name") {
      this.setState({
        name: e.target.value,
      });
    } else if (type == "description") {
      this.setState({
        description: e.target.value,
      });
    } else {
      this.setState({
        identifier: e.target.value,
      });
    }
  };
  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleReportDialogue = () => {
    this.setState((prevState) => {
      return {
        reportDialogueStatus: !prevState.reportDialogueStatus,
      };
    });
  };

  getIconUrl = (file, uploadFile, type) => {
    let images_extensions = ["jpg", "jpeg", "png"];
    let image_extension = file.type.split("/")[1];
    const result = images_extensions.indexOf(image_extension);
    if (result == -1) {
      toast.info(`${image_extension} file is not supported`);
    } else {
      if (type == "icon") {
        this.setState({
          icon: URL.createObjectURL(file),
          icon_file: uploadFile,
        });
      } else {
        this.setState({
          whiteIcon: URL.createObjectURL(file),
          whiteIcon_file: uploadFile,
        });
      }
    }
  };
  render() {
    const { icon, whiteIcon, name, description, identifier } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <ApolloProvider client={client_mutation}>
              <Mutation mutation={Addreporttype}>
                {(Addreporttype, { data, error, loading }) => {
                  if (data) {
                    if (data.Addreporttype.success == 0) {
                      toast.error(this.props.t(data.Addreporttype.message));
                    } else {
                      toast.success(this.props.t(data.Addreporttype.message));
                      this.props.handleAddModal(truncate);
                    }
                  }
                  if (error) {
                    toast.error(
                      i18n.getDataByLanguage("en").translation.GeneralMessages
                        .errorServerisNotResponding &&
                        i18n.getDataByLanguage("en").translation.GeneralMessages
                          .errorServerisNotResponding
                    );
                  }

                  return (
                    <div className="d-block">
                      <div
                        className="modal fade show"
                        id="NewEvent"
                        tabindex="-1"
                        aria-labelledby="NewEventLabel"
                        aria-modal="true"
                        style={{ display: "block" }}
                      >
                        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header border-0 d-block pt-4 pb-0">
                              <div className="d-flex align-items-center justify-content-between mb-4">
                                <button
                                  type="button"
                                  onClick={() => {
                                    this.props.handleAddModal(false);
                                  }}
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                </button>

                                {loading ? (
                                  <div className="btn btn-primary-theme pointer">
                                    {t("AdminAddUser.save")}
                                    <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                      <span className="sr-only">
                                        {t("GeneralMessages.loading")}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="btn btn-primary-theme pointer"
                                    onClick={() => {
                                      const {
                                        name,
                                        description,
                                        identifier,
                                        icon_file,
                                        whiteIcon_file,
                                      } = this.state;
                                      if (!name) {
                                        toast.info(
                                          this.props.t(
                                            "AdminAddReportType.nameValidation"
                                          )
                                        );
                                      } else if (!description) {
                                        toast.info(
                                          this.props.t(
                                            "AdminAddReportType.descriptionValidation"
                                          )
                                        );
                                      } else if (!identifier) {
                                        toast.info(
                                          this.props.t(
                                            "AdminAddReportType.identifierValidation"
                                          )
                                        );
                                      } else if (!icon_file) {
                                        toast.info(
                                          this.props.t(
                                            "AdminAddReportType.iconValidation"
                                          )
                                        );
                                      } else if (!whiteIcon_file) {
                                        toast.info(
                                          this.props.t(
                                            "AdminAddReportType.whiteIconValidation"
                                          )
                                        );
                                      } else {
                                        let obj = {
                                          variables: {
                                            profile: {
                                              user_id: getUserID(),
                                              id: null,
                                              name,
                                              description,
                                              identifier,
                                              icon: icon_file,
                                              white_icon: whiteIcon_file,
                                            },
                                          },
                                        };
                                        Addreporttype(obj);
                                      }
                                    }}
                                  >
                                    {t("AdminAddUser.save")}
                                  </div>
                                )}
                              </div>
                              <h5 className="modal-title">
                                {t("AdminAddReportType.addTitle")}
                              </h5>
                            </div>
                            <div className="modal-body">
                              <div className="middle-section sab-middle-section1">
                                <div className="container my-4 z-index-99">
                                  <div className="form-group mb-5">
                                    <div className="form-group">
                                      <div className="container my-4 00z-index-99">
                                        <form>
                                          <div className="form-group">
                                            <label className="font-14">
                                              {t(
                                                "AdminAddReportType.nameTitle"
                                              )}
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              onChange={(e) =>
                                                this.handleTextChange(e, "name")
                                              }
                                              name="name"
                                              value={name && name}
                                              placeholder={t(
                                                "AdminAddReportType.namePlaceHolder"
                                              )}
                                            />
                                          </div>

                                          <div className="form-group">
                                            <label className="font-14">
                                              {t(
                                                "AdminAddReportType.descriptionTitle"
                                              )}
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              onChange={(e) =>
                                                this.handleTextChange(
                                                  e,
                                                  "description"
                                                )
                                              }
                                              name="description"
                                              value={description && description}
                                              placeholder={t(
                                                "AdminAddReportType.descriptionPlaceHolder"
                                              )}
                                            />
                                          </div>

                                          <div className="form-group">
                                            <label className="font-14">
                                              {t(
                                                "AdminAddReportType.identifierTitle"
                                              )}
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              onChange={(e) =>
                                                this.handleTextChange(
                                                  e,
                                                  "identifier"
                                                )
                                              }
                                              name="identifier"
                                              value={identifier && identifier}
                                              placeholder={t(
                                                "AdminAddReportType.identifierPlaceHolder"
                                              )}
                                            />
                                          </div>

                                          <div className="form-group">
                                            <label className="font-14">
                                              {t(
                                                "AdminAddReportType.iconTitle"
                                              )}
                                            </label>

                                            <div
                                              className="fileinput text-center fileinput-new d-flex align-items-center"
                                              data-provides="fileinput"
                                            >
                                              <div className="btn-file">
                                                <div
                                                  className="thumbnail fileinput-new "
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    background: "#999",
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      icon
                                                        ? icon
                                                        : "/images/no-image.png"
                                                    }
                                                    style={{width:'100px',height:'100px'}}
                                                    alt=""
                                                  />
                                                </div>

                                                <input
                                                  type={"file"}
                                                  file-model="myFile"
                                                  name="image"
                                                  accept=""
                                                  onChange={(event) => {
                                                    const file =
                                                      event.target.files[0];
                                                    this.getIconUrl(
                                                      event.currentTarget
                                                        .files[0],
                                                      file,
                                                      "icon"
                                                    );
                                                  }}
                                                />

                                                <div
                                                  className="fileinput-preview fileinput-exists thumbnail uploaded-user-image rounded-circle"
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    borderRadius: "100%",
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <label className="font-14">
                                              {t(
                                                "AdminAddReportType.whiteIconTitle"
                                              )}
                                            </label>

                                            <div
                                              className="fileinput text-center fileinput-new d-flex align-items-center"
                                              data-provides="fileinput"
                                            >
                                              <div className="btn-file">
                                                <div
                                                  className="thumbnail fileinput-new "
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    background: "#999",
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      whiteIcon
                                                        ? whiteIcon
                                                        : "/images/no-image.png"
                                                    }
                                                    alt=""
                                                    style={{width:'100px',height:'100px'}}
                                                  />
                                                </div>

                                                <input
                                                  type={"file"}
                                                  file-model="myFile"
                                                  name="image"
                                                  accept=""
                                                  onChange={(event) => {
                                                    const file =
                                                      event.target.files[0];
                                                    this.getIconUrl(
                                                      event.currentTarget
                                                        .files[0],
                                                      file,
                                                      "white_icon"
                                                    );
                                                  }}
                                                />
                                                <div
                                                  className="fileinput-preview fileinput-exists thumbnail uploaded-user-image rounded-circle"
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    borderRadius: "100%",
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-backdrop fade show"></div>
                    </div>
                  );
                }}
              </Mutation>
            </ApolloProvider>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(AddReportType));
