/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Translation, withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { channellist, removechannel } from "../../../../constants/apiParams";
import apiCall, { METHOD } from "../../../../constants/baseUrl";
import { getUserID } from "../../../../util/utils";
import CommonLoader from "../../../CommanComponents/CommonLoader";
import FooterDesktop from "../../../Footer/FooterDesktop";
import HeaderDesktop from "../../../Header/HeaderDesktop";
import AddChannel from "./AddChannel";
import EditChannel from "./EditChannel";
import createHistory from "history/createBrowserHistory";
import AdminSideMenu from "../../AdminMenu/AdminSideMenu";

class ChannelList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      channel_list: [],
      deleteLoadingStatus: false,
      headerMenuStatus: false,
      dataValue: {},
      isFromEdit: false,
      tempResponse: {},
      addModal: false,
      editModal: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.handleTwitterFacebookConnect();
    this.loadList();
  }

  afterCreateTwitterChannel = () => {
    this.setState({
      tempResponse: {},
    });
  };

  changeStatusWithStatusValue = (type, dataValue) => {
    this.setState({
      routeStatus: type,
      dataValue,
    });
  };

  handleTwitterFacebookConnect = () => {
    const isFromtwitterConnect = this.props.location.state
      ?.isFromtwitterConnect;
    const isFromfacebookConnect = this.props.location.state
      ?.isFromFacebookConnect;
    if (isFromtwitterConnect && isFromtwitterConnect) {
      let tempResponse = localStorage.getItem("tempConnectResponse");
      tempResponse = JSON.parse(tempResponse);
      const comeFrom = tempResponse.comeFrom;
      console.log("tempResponse", tempResponse);
      localStorage.removeItem("tempConnectResponse");

      if (comeFrom == "createTwitter") {
        this.setState({
          addModal: true,
          channelType: "twitter",
          tempResponse,
        });
      } else if (comeFrom == "editTwitter") {
        this.setState({
          editModal: true,
          channelType: "twitter",
          tempResponse,
        });
      }
      const history = createHistory();
      if (
        history.location.state &&
        history.location.state.isFromtwitterConnect
      ) {
        let state = { ...history.location.state };
        delete state.isFromtwitterConnect;
        delete state.isFromEdit;
        history.replace({ ...history.location, state });
      }
    } else if (!isFromfacebookConnect) {
      this.setState({
        tempResponse: {},
      });
    }
    if (isFromfacebookConnect && isFromfacebookConnect) {
      let tempResponse = localStorage.getItem("tempConnectResponse");
      tempResponse = JSON.parse(tempResponse);
      const comeFrom = tempResponse?.comeFrom;
      localStorage.removeItem("tempConnectResponse");

      if (comeFrom && comeFrom == "createFacebook") {
        this.setState({
          addModal: true,
          channelType: "facebook",
          tempResponse,
        });
      } else if (comeFrom && comeFrom == "editFacebook") {
        console.log("tempresponse in index", tempResponse);
        this.setState({
          editModal: true,
          channelType: "facebook",
          tempResponse,
        });
      }
      const history = createHistory();
      if (
        history.location.state &&
        history.location.state.isFromtwitterConnect
      ) {
        let state = { ...history.location.state };
        delete state.isFromfacebookConnect;
        delete state.isFromEdit;
        history.replace({ ...history.location, state });
      }
    } else if (!isFromtwitterConnect) {
      this.setState({
        tempResponse: {},
      });
    }
  };
  loadList = () => {
    apiCall(
      METHOD.QUERY,
      channellist(getUserID(), 1),
      (data) => this.handleApiResponse(data),
      (isLoading) => {
        this.handleLoader(isLoading);
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleApiResponse = (data) => {
    this.setState({
      channel_list: data.channel_list,
    });
  };

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: true,
      };
    });
  };

  handleDelete = (item) => {
    const { id } = item;
    confirmAlert({
      title: this.props.t("ManageChannel.confirmTodeleteTitle"),
      message: this.props.t("ManageChannel.confirmTodeleteMessage"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              removechannel(getUserID(), id),
              (data) => {
                let afterDeletedchannel_list = this.state.channel_list.filter(
                  (channel) => {
                    return channel.id != item.id;
                  }
                );
                toast.success(this.props.t(data.message));
                this.setState({
                  channel_list: afterDeletedchannel_list,
                });
              },
              (isLoading) => {
                this.setState({ deleteLoadingStatus: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        headerMenuStatus: false,
      };
    });
  };

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleAddModal = (isFromSave) => {
    const isFromtwitterConnect = this.props.location.state
      ?.isFromtwitterConnect;
    if (!isFromtwitterConnect) {
      this.setState(
        (prev) => {
          return {
            addModal: !prev.addModal,
            tempResponse: {},
          };
        },
        () => {
          if (this.state.addModal) {
            document.body.classList.add("modal-open");
          } else {
            if (isFromSave) {
              this.loadList();
            }
            document.body.classList.remove("modal-open");
          }
        }
      );
    } else {
      this.setState((prev) => {
        return {
          addModal: !prev.addModal,
        };
      });
    }
  };

  handleEditModal = (isFromSave, item) => {
    this.setState(
      (prev) => {
        return {
          editModal: !prev.editModal,
          editItemValue: item,
        };
      },
      () => {
        if (this.state.editModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.loadList();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  loader = (<CommonLoader key={0} />);
  render() {
    const {
      channel_list,
      isLoading,
      addModal,
      editModal,
      tempResponse,
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="content-wrapper">
              <HeaderDesktop selectionType={0} />
              <div className="content">
                <div className="section section-padding">
                  <div className="container">
                    <div className="section-container">
                      <div className="section-content">
                        <div className="row mt-4">

                        <div className="row mt-2">
                          {isLoading && <CommonLoader />}
                          <AdminSideMenu activeIndex={4} />
                          <div className="col-lg-9">
                          <div className="section-title text-center d-md-flex align-items-start m-0">
                        <div className="flex-fill mr-n-1300">
                          <Link to="/administration" className="text-light">
                            <h1 className="page-title h4">
                              {t("ManageChannel.title")}
                            </h1>
                          </Link>
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary-theme btn-sm"
                          onClick={() => {
                            this.handleAddModal();
                          }}
                        >
                          {" "}
                          {t("GeneralMessages.addNew")}
                        </button>
                      </div>
                    
                            <div className="row mt-4">

                          {channel_list &&
                            channel_list.map((item, index) => {
                              const {
                                name,
                                channel_type_icon,
                                description,
                              } = item;
                              return (
                                <div className="col-lg-6 mb-4" key={index}>
                                  <a
                                    href="javascript:;"
                                    className="card list-card"
                                  >
                                    <div className="card-body d-flex py-3 px-3">
                                      <div className="circle big m-0 mr-3">
                                        <img
                                          src={`data:image/png;base64,${
                                            channel_type_icon &&
                                            channel_type_icon
                                          }`}
                                          alt="ico"
                                          style={{ width: "20px" }}
                                        />
                                      </div>
                                      <div className="clearfix mr-2">
                                        <h6 className="mb-1">{name}</h6>
                                        <small className="text-muted text-break">
                                          {description}
                                        </small>
                                      </div>
                                      <div className="ml-auto">
                                        <ul className="list-inline action-menu-list d-flex m-0">
                                          <li className="list-inline-item mr-3">
                                            <i
                                              className="zmdi zmdi-edit zmdi-hc-lg pointer"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.handleEditModal(
                                                  false,
                                                  item
                                                );
                                              }}
                                            />
                                          </li>
                                          <li className="list-inline-item mr-3 ">
                                            <i
                                              className="zmdi zmdi-delete zmdi-hc-lg pointer"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.handleDelete(item);
                                              }}
                                            />
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              );
                            })}
                         </div>
                          </div>
                        </div>
                      </div>
                      </div>
                      </div>

                    {addModal && (
                      <AddChannel
                        handleAddModal={(isFromSave) => {
                          this.handleAddModal(isFromSave);
                        }}
                        tempResponse={tempResponse}
                        afterCreateTwitterChannel={() =>
                          this.afterCreateTwitterChannel()
                        }
                      />
                    )}
                    {editModal && (
                      <EditChannel
                        item={this.state.editItemValue}
                        handleEditModal={(isFromSave, item) => {
                          this.handleEditModal(isFromSave, item);
                        }}
                        tempResponse={tempResponse}
                        afterCreateTwitterChannel={() =>
                          this.afterCreateTwitterChannel()
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
              <FooterDesktop selectionType={0} />
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(ChannelList));
