import React, { Component } from "react";
import MediaQuery from "react-responsive";
import AdminstrationMenuMobile from "./AdminstrationMenuMobile";
import AdminstrationMenuDesktop from "./AdminstrationMenuDesktop";
import { withRouter } from "react-router";
import { checkAdminPermission } from "../../../util/utils";

class AdminstartionResponsive extends Component {
  componentDidMount() {
    if (!checkAdminPermission()) {
      this.props.history.push("/");
    }
  }
  render() {
    return (
      <React.Fragment>
        <MediaQuery query="(min-device-width: 1224px)">
          <AdminstrationMenuDesktop />
        </MediaQuery>
        <MediaQuery query="(max-device-width: 1224px)">
          <AdminstrationMenuMobile />
        </MediaQuery>
      </React.Fragment>
    );
  }
}
export default withRouter(AdminstartionResponsive);
