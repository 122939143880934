/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import EditMail from "./EditMail";
import GeneralGGroupList from "./GeneralGGroupList";
import GeneralPersonList from "./GeneralPersonList";
import GroupListAddContacts from "./GroupListAddContacts";
import PersonDataTypeList from "./PersonDataTypeList";
import PersonDataTypeValue from "./PersonDataTypeValue";
import PersonList from "./PersonList";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeStatus: 0,
      data : {},
      personDataTypeId:0,
      personDataTypeName:'',
      filterValue:'',
      filterSortType:1
    };
  }

  handleClosePersonDataType=()=>{
    this.setState({
      personDataTypeId:0,
      personDataTypeName:'',
      filterValue:'',
      routeStatus: 0,
    })
  }

  handleFilterSortType= (filterSortType)=>{
    this.setState({
      filterSortType,
      routeStatus: 0,
    })
  }
  handleApplyFilter =(filterValue)=>{
    console.log("handleApplyFilter -->",filterValue);
    if(filterValue==''){
      this.setState({
        routeStatus: 5,
        filterValue,
      personDataTypeName:'',
        personDataTypeId:0
      })
    }else{
      this.setState({
        routeStatus: 0,
        filterValue,
      })
    }
  }
  setPersonDataTypeId=(personDataTypeId)=>{
    this.setState({
      personDataTypeId,
    })
  }

  setPersonDataTypeName=(personDataTypeName)=>{
    this.setState({
      personDataTypeName,
    })
  }

  storeApiData = (data) => {
    this.setState({
      data
    })
  }

 
  changeRouteStatus = value => {
    this.setState(prevState => {
      return {
        routeStatus: value
      };
    });
  };
  render() {
    const { routeStatus,filterValue,personDataTypeId,filterSortType } = this.state;
    return (
      <React.Fragment>
        <div className="wrapper position-relative">
          
          {routeStatus == 0 && (
            <PersonList storeApiData={this.storeApiData}
            filterValue={filterValue||''}
            personDataTypeId={personDataTypeId||0}
            filterSortType={filterSortType}
            changeRouteStatus={this.changeRouteStatus} />
          )}

          {routeStatus == 1 && (
            <GeneralPersonList changeRouteStatus={this.changeRouteStatus} />
          )}

          {routeStatus == 2 && (
            <GeneralGGroupList changeRouteStatus={this.changeRouteStatus} />
          )}

          {routeStatus == 3 && (
            <EditMail data={this.state.data} changeRouteStatus={this.changeRouteStatus} />
          )}

          {routeStatus == 4 && (
            <GroupListAddContacts changeRouteStatus={this.changeRouteStatus} />
          )}

          {routeStatus == 5 && (
            <PersonDataTypeList changeRouteStatus={this.changeRouteStatus}
            handleClosePersonDataType={()=>{this.handleClosePersonDataType()}}
            setPersonDataTypeId={(personDataTypeId)=>{this.setPersonDataTypeId((personDataTypeId))}} 
            setPersonDataTypeName={(name)=>{this.setPersonDataTypeName((name))}} 
            handleFilterSortType={(sortType)=>{this.handleFilterSortType((sortType))}}
            filterSortType={filterSortType}
            />
          )}

          {routeStatus == 6 && (
            <PersonDataTypeValue 
            handleApplyFilter={(filterValue)=>{this.handleApplyFilter(filterValue)}}
            personDataTypeId = {this.state.personDataTypeId} 
            personDataTypeName = {this.state.personDataTypeName} 
            handleClosePersonDataType={()=>{this.handleClosePersonDataType()}}
            changeRouteStatus={this.changeRouteStatus} />
          )}

        </div>

      </React.Fragment>
    );
  }
}
export default withRouter(Index);
