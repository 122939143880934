
import React, { Component } from 'react'
import { withRouter } from "react-router";
import MediaQuery from "react-responsive";
import ManageLocationMobile from "./UserManagementMobile/Index";
import ManageLocationDesktop from "./UserManagementDesktop/UserList";
import { checkAdminPermission } from '../../../util/utils';
class ManageUserResponsive extends Component {
  componentDidMount() {
    if(!checkAdminPermission()){
      this.props.history.push("/")
    }
  }
  render() {
    return (
      <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <ManageLocationDesktop />
       
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <ManageLocationMobile />
      </MediaQuery>
    </React.Fragment>
    )
  }
}
export default withRouter(ManageUserResponsive)