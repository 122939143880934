/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Translation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  adminEvnetListTypeList,
  deleteEventType,
} from "../../../../constants/apiParams";
import apiCall, { METHOD } from "../../../../constants/baseUrl";
import { getUserID } from "../../../../util/utils";
import CommonLoader from "../../../CommanComponents/CommonLoader";
import FooterDesktop from "../../../Footer/FooterDesktop";
import HeaderDesktop from "../../../Header/HeaderDesktop";
import AdminSideMenu from "../../AdminMenu/AdminSideMenu";
import AddEventType from "./AddEventType";
import EditEventType from "./EditEventType";
class EventListType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      event_type_list: [],
      deleteLoadingStatus: false,
      headerMenuStatus: false,
      editModal: false,
      addModal: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.loadListTypeList();
  }

  loadListTypeList = () => {
    apiCall(
      METHOD.QUERY,
      adminEvnetListTypeList(getUserID(), 1),
      (data) => this.handleUserApiResponse(data),
      (isLoading) => {
        this.setState({ isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleUserApiResponse = (data) => {
    this.setState((prevState) => {
      return {
        event_type_list: data.event_type_list,
        isLoading: false,
      };
    });
  };

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: true,
      };
    });
  };

  handleDelete = (item) => {
    const { id } = item;

    confirmAlert({
      title: this.props.t("AdminEventListType.confirmTodeleteListTypeTitle"),
      message: this.props.t(
        "AdminEventListType.confirmTodeleteListTypeMessage"
      ),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              deleteEventType(getUserID(), id),
              (data) => {
                let afterDeletedReportTypeList = this.state.event_type_list.filter(
                  (group_type) => {
                    return group_type.id != item.id;
                  }
                );
                toast.success(this.props.t(data.message));
                this.setState({
                  event_type_list: afterDeletedReportTypeList,
                });
              },
              (isLoading) => {
                this.setState({ deleteLoadingStatus: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        headerMenuStatus: false,
      };
    });
  };

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleAddModal = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          addModal: !prev.addModal,
        };
      },
      () => {
        if (this.state.addModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.loadListTypeList();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  handleEditModal = (isFromSave, item) => {
    this.setState(
      (prev) => {
        return {
          editModal: !prev.editModal,
          editItemValue: item,
        };
      },
      () => {
        if (this.state.editModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.loadListTypeList();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  loader = (<CommonLoader key={0} />);
  render() {
    const { event_type_list, isLoading, editModal, addModal } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="content-wrapper">
              <HeaderDesktop selectionType={0} />
              <div className="content">
                <div className="section section-padding">
                  <div className="container">
                    <div className="section-container">
                      <div className="section-content">
                      <div className="row mt-2">
                          {isLoading && <CommonLoader />}
                          <AdminSideMenu activeIndex={5} />
                          <div className="col-lg-9">
                          <div className="section-title text-center d-md-flex align-items-start m-0">
                        <div className="flex-fill mr-n-1300">
                          <Link to="/administration" className="text-light">
                            <h1 className="page-title h4">
                              {t("AdminEventListType.List_type_title")}
                            </h1>
                          </Link>
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary-theme btn-sm"
                          onClick={() => {
                            this.handleAddModal();
                          }}
                        >
                          {" "}
                          {t("GeneralMessages.addNew")}
                        </button>
                      </div>
                   
                            <div className="row mt-4">

                          {event_type_list &&
                            event_type_list.map((item, index) => {
                              const { name, description, icon } = item;
                              return (
                                <div className="col-lg-6 mb-4" key={index}>
                                  <a
                                    href="javascript:;"
                                    className="card list-card"
                                  >
                                    <div className="card-body d-flex py-3 px-3">
                                      <div className="circle big m-0 mr-3">
                                        <img
                                          src={`data:image/png;base64,${
                                            icon && icon
                                          }`}
                                          alt="ico"
                                          style={{ width: "20px" }}
                                        />
                                      </div>
                                      <div className="clearfix mr-2">
                                        <h6 className="mb-1">{name}</h6>
                                        <small className="text-muted text-break">
                                          {description}
                                        </small>
                                      </div>
                                      <div className="ml-auto">
                                        <ul className="list-inline action-menu-list d-flex m-0">
                                          <li className="list-inline-item mr-3">
                                            <i
                                              className="zmdi zmdi-edit zmdi-hc-lg pointer"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.handleEditModal(
                                                  false,
                                                  item
                                                );
                                              }}
                                            />
                                          </li>
                                          <li className="list-inline-item mr-3 ">
                                            <i
                                              className="zmdi zmdi-delete zmdi-hc-lg pointer"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.handleDelete(item);
                                              }}
                                            />
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    </div>
                    </div>
                    {addModal && (
                      <AddEventType
                        handleAddModal={(isFromSave) => {
                          this.handleAddModal(isFromSave);
                        }}
                      />
                    )}
                    {editModal && (
                      <EditEventType
                        item={this.state.editItemValue}
                        handleEditModal={(isFromSave, item) => {
                          this.handleEditModal(isFromSave, item);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <FooterDesktop selectionType={0} />
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(EventListType);
