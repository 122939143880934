import React, { Component } from "react";
import MediaQuery from "react-responsive";
import EmailManangementMobile from "./EmailManangementMobile/Index";
import EmailManangementDesktop from "./EmailManangementDesktop/EmailList";
import { withRouter } from "react-router";
import { checkAdminPermission } from "../../../util/utils";
class ManageEmailResponsive extends Component {
  componentDidMount() {
    if (!checkAdminPermission()) {
      this.props.history.push("/");
    }
  }
  render() {
    return (
      <React.Fragment>
        <MediaQuery query="(min-device-width: 1224px)">
          <EmailManangementDesktop />
        </MediaQuery>
        <MediaQuery query="(max-device-width: 1224px)">
          <EmailManangementMobile />
        </MediaQuery>
      </React.Fragment>
    );
  }
}
export default withRouter(ManageEmailResponsive);
