import { Field, Formik } from "formik";
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { registerUser } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import { UpdateProfileSchema } from "../../util/validationSchema";
import ErrorMessageFormik from "../CommanComponents/ErrorMessageFormik";
import { Link } from "react-router-dom";

class RegisterUserDesktop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isRegisterSuccessfully: false,
      message: "",
      systemLogo: "",
    };
  }
  handleSuccess = (message) => {
    this.setState({
      isRegisterSuccessfully: true,
      message,
    });
  };

  componentDidMount() {
    this.setState({
      systemLogo: localStorage.getItem("system_logo"),
    });
  }
  reutrnToLogin = () => {
    this.props.history.push("/home");
  };
  handleSubmit = (values, actions) => {
    const { name, first_name, email, password } = values;
    apiCall(
      METHOD.QUERY,
      registerUser(email, name, first_name, password),
      (data) => {
        this.handleSuccess(data.message);
        actions.resetForm({
          name: "",
          email: "",
          first_name: "",
          password: "",
          repear_password: "",
        });
      },
      (isLoading) => {
        this.setState({ isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  render() {
    const {
      isLoading,
      isRegisterSuccessfully,
      message,
      systemLogo,
    } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              {isRegisterSuccessfully ? (
                <div className="content-wrapper">
                  <div className="main-container d-flex align-items-center justify-content-center">
                    <div className="section section-padding">
                      <div className="container">
                        <div className="auth-box">
                          <div className="middle-section sab-middle-section1">
                            <div className="container my-4 00z-index-99">
                              <h4 className="m-0">{t(message)}</h4>
                            </div>
                            <button
                              type="button"
                              className="btn btn-success btn-block mt-2"
                              onClick={() => {
                                this.reutrnToLogin();
                              }}
                            >
                              {t("registerUser.returnToLogin")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Formik
                  enableReinitialize
                  initialValues={{
                    name: "",
                    email: "",
                    first_name: "",
                    password: "",
                    repear_password: "",
                  }}
                  validationSchema={UpdateProfileSchema(
                    t("registerUser.first_name_error"),
                    t("registerUser.name_error"),
                    t("registerUser.email_error"),
                    t("registerUser.email_valid_error"),
                    t("registerUser.passwordValidation"),
                    t("registerUser.toShortValidation"),
                    t("registerUser.toLongValidation"),
                    t("registerUser.comparePasswordValidation")
                  )}
                  onSubmit={(values, actions) => {
                    this.handleSubmit(values, actions);
                  }}
                >
                  {({ handleSubmit }) => (
                    <div className="content-wrapper">
                      <div className="main-container d-flex align-items-center justify-content-center">
                        <div className="section section-padding">
                          <div className="container">
                            <div className="auth-box">
                              <div className="section-title text-center mb-4">
                                {systemLogo ? (
                                  <img
                                    src={`data:image/png;base64,${systemLogo}`}
                                    alt="loginLogo"
                                    className="mb-3"
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                ) : (
                                  <img
                                    src="/images/login_logo.png"
                                    alt="loginLogo"
                                    style={{ width: "150px", height: "150px" }}
                                  />
                                )}

                                <p className="text-muted font-size-18">
                                  {t("registerUser.title")}
                                </p>
                              </div>
                              <form>
                                <div className="form-group">
                                  <label className="font-14">
                                    {t("registerUser.first_name")}
                                  </label>
                                  <Field
                                    type="text"
                                    name="first_name"
                                    placeholder={t(
                                      "registerUser.first_name_placeholder"
                                    )}
                                    className="form-control"
                                    required
                                  />
                                  <ErrorMessageFormik name="first_name" />
                                </div>

                                <div className="form-group">
                                  <label className="font-14">
                                    {t("registerUser.name")}
                                  </label>
                                  <Field
                                    type="text"
                                    name="name"
                                    placeholder={t("registerUser.name")}
                                    className="form-control"
                                    required
                                  />
                                  <ErrorMessageFormik name="name" />
                                </div>

                                <div className="form-group">
                                  <label className="font-14">
                                    {t("registerUser.email")}
                                  </label>
                                  <Field
                                    type="text"
                                    name="email"
                                    placeholder={t(
                                      "registerUser.email_placeholder"
                                    )}
                                    className="form-control"
                                    required
                                  />
                                  <ErrorMessageFormik name="email" />
                                </div>

                                <div className="form-group">
                                  <label className="font-14">
                                    {t("registerUser.password")}
                                  </label>
                                  <Field
                                    type="password"
                                    name="password"
                                    placeholder="************"
                                    className="form-control paassword-value"
                                    required
                                  />
                                  <ErrorMessageFormik name="password" />
                                </div>

                                <div className="form-group">
                                  <label className="font-14">
                                    {t("registerUser.confirm_Password")}
                                  </label>
                                  <Field
                                    type="password"
                                    name="repear_password"
                                    placeholder="************"
                                    className="form-control  paassword-value"
                                    required
                                  />
                                  <ErrorMessageFormik name="repear_password" />
                                </div>

                                <div className="form-group text-center mt-4">
                                  {isLoading ? (
                                    <button
                                      type="button"
                                      onClick={handleSubmit}
                                      className="btn btn-primary-theme btn-block"
                                    >
                                      {t("registerUser.regiter_btn_lable")}

                                      <div className="spinner-border spinner-border-sm ml-1 text-white">
                                        <span className="sr-only">
                                          {t("GeneralMessages.loading")}
                                        </span>
                                      </div>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      onClick={handleSubmit}
                                      className="btn btn-primary-theme btn-block"
                                    >
                                      {t("registerUser.regiter_btn_lable")}
                                    </button>
                                  )}
                                </div>
                                <Link to="/" className="text-center d-block text-info">
                                {t("GeneralMessages.backtoLogin")}
                                </Link>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Formik>
              )}
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(withRouter(RegisterUserDesktop));
