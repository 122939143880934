/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  personDetailQuery,
  removepersonfrommailinglist
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../../util/utils";
import FabIcon from "../../CommanComponents/FabIcon";
import Footer from "../../Footer/FooterMobile";
import LoadingPage from "../../CommanComponents/LoadingPage";
import LogoutMenu from "../../CommanComponents/LogoutMenu";
import TopHeader from "../../CommanComponents/TopHeader";
import PersonDetailSetteliteSideMenu from "./PersonDetailSetteliteSideMenu";

class PersonDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      headerMenuStatus: false,
      person_id: 0,
      clickStatus: 0,
      group_id: 0,
      socialMediaType: 0,
      dropdownScrollValue:0,
      groupIdHeader:0
    };

    this.myContact = React.createRef();
    this.myBusiness = React.createRef();
    this.mySocialMediaType = React.createRef();
    this.myMailing = React.createRef();
    this.myComment = React.createRef();
    this.myOtherField = React.createRef();
  }

  handleBackStack = () => {
    let { from } = this.props.location.state || {
      from: { pathname: "/group-list" },
    };
    this.props.history.push(from);
  };

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  componentDidMount() {
    const read_person_permission = checkUserPermission("read_person");
    
    if(read_person_permission){
      const group_person_id = this.props.match.params.group_person_id;
      const fields = group_person_id.split("-");
      const person_id = fields[1];
      const group_id = fields[0];
  
      this.setState({
        person_id: person_id,
        groupIdHeader:group_id
      });
  
      apiCall(
        METHOD.QUERY,
        personDetailQuery(getUserID(), person_id,group_id),
        (data) => {
          this.props.getPersonDetail(data);
        },
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }else{
      toast.info(this.props.t("GeneralMessages.permissionError"));
    }
    
  }

  handleTabEvent = (type) => {
    this.setState({
      clickStatus: type,
    });
  };

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  deletelist = (id) => {
    const group_person_id = this.props.match.params.group_person_id;
    const fields = group_person_id.split("-");
    const person_id = fields[1];
    confirmAlert({
      title: this.props.t("personDetail.deleteDistributor"),
      message: this.props.t("personDetail.deleteDistributorMessage"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              removepersonfrommailinglist(getUserID(), person_id, id),
              (data) => {
                toast.success(this.props.t(data.message));
                this.props.deleteMailList(id);
              },
              (isLoading) => {
                this.setState({ deleteLoader: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };

  loader = (<LoadingPage />);

  render() {
    const {
      full_name,
      title,
      email,
      phone,
      mobile,
      website,
      fax,
      business_adress,
      private_adress,
      mailing_list,
      comment,
      other_field_list,
      socialmedia_facebook_stream,
      socialmedia_twitter_stream,
      socialmedia_linkedin_stream,
      socialmedia_instagram_stream,
    } = this.props.groupPersonDetail && this.props.groupPersonDetail;
    const {socialMediaType,dropdownScrollValue,groupIdHeader} = this.state
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-blue sticky-top d-flex flex-column">
                <div className="container d-flex flex-column flex-fill">
                  <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                    <TopHeader />
                    <p
                      className="mb-0 ml-auto text-light pointer"
                      onClick={() => {
                        this.handleBackStack();
                      }}
                    >
                      {t("PersonDetails.title")}
                    </p>
                    <LogoutMenu />
                  </div>

                  <div className="text-center text-light">
                    <a href="#1" className="clearfix mb-3">
                      <div className="rounded-40 bg-light m-auto">
                        <i className="zmdi zmdi-account zmdi-hc-fw font-24 text-dark"></i>
                      </div>
                    </a>
                    <h5 className="m-0 text-light mt-2">{`${title && title}  ${
                      full_name && full_name
                    }`}</h5>
                    {/* <p className="text-light m-0 font-13">
                      {company_name && company_name}
                    </p>
                    <p className="text-light m-0 font-13">
                      {department && department}
                    </p> */}
                    <div className="header-dropdown mt-2">
                      <select
                        class="custom-select header-dropdown text-white"
                        id="inputGroupSelect01"
                        onChange={(e) => {
                          if (e.target.value == 0) {
                            this.setState({
                              dropdownScrollValue:0
                            },()=>{
                              this.myContact.current.scrollIntoView({
                                behavior: "smooth",
                              });
                            })
                          } else if (e.target.value == 1) {

                            this.setState({
                              dropdownScrollValue:1
                            },()=>{
                              this.myBusiness.current.scrollIntoView({
                                behavior: "smooth",
                              });
                            })
                            
                          } else if (e.target.value == 2) {
                            this.setState({
                              dropdownScrollValue:2
                            },()=>{
                              this.myMailing.current.scrollIntoView({
                                behavior: "smooth",
                              });
                            })
                           
                          } else if (e.target.value == 3) {
                            this.setState({
                              dropdownScrollValue:3
                            },()=>{
                              this.myOtherField.current.scrollIntoView({
                                behavior: "smooth",
                              });
                            })
                          }else{
                            this.setState({
                              dropdownScrollValue:4
                            },()=>{
                              this.mySocialMediaType.current.scrollIntoView({
                                behavior: "smooth",
                              });
                            })
                          }  
                        }}
                      >
                        {/* <option selected>{t("PersonDetails.choose")}</option> */}
                        <option value="0" defaultChecked={true}>
                          {t("PersonDetails.tab_title_contact")}
                        </option>
                        <option value="1">
                          {t("PersonDetails.tab_title_address")}
                        </option>
                        <option value="2">
                          {t("PersonDetails.tab_title_distributor")}
                        </option>
                        <option value="3">
                          {t("PersonDetails.tab_title_remarks")}
                        </option>
                        <option value="4">
                          {t("PersonDetails.tab_title_social_media")}
                        </option>
                      
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="middle-section">
                <div className="container my-4 z-index-99">
                  <div className="mx-3">
                    <div ref={this.myContact}>
                      <div className="clearfix mt-4 pt-3">
                        <h6>{t("PersonDetails.tab_title_contact")}</h6>
                        <hr />
                      </div>
                      <div className="mb-2">
                        <div className="my-2 clearfix">
                          <div className="d-flex align-items-center w-100">
                            <div className="media">
                              <div className="media-left mr-2">
                                <i className={dropdownScrollValue==0?("zmdi zmdi-email zmdi-hc-fw font-22  align-middle "):("zmdi zmdi-email zmdi-hc-fw font-22 text-muted align-middle")}
                                ></i>
                              </div>
                              <div className="media-body">
                                {/* <p className="font-14 m-0">{email && email}</p> */}
                                <a
                                  href={`mailto: ${email && email} `}
                                  className="font-16 m-0"
                                >
                                  {email && email}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-2">
                        <div className="my-2 clearfix">
                          <div className="d-flex align-items-center w-100">
                            <div className="media">
                              <div className="media-left mr-2">
                                <i className={dropdownScrollValue==0?("zmdi zmdi-phone zmdi-hc-fw font-22  align-middle"):("zmdi zmdi-phone zmdi-hc-fw font-22 text-muted align-middle")}></i>
                              </div>
                              <div className="media-body">
                                <a
                                  href={`tel: ${phone && phone} `}
                                  className="font-16 m-0"
                                >
                                  {phone && phone}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-2">
                        <div className="my-2 clearfix">
                          <div className="d-flex align-items-center w-100">
                            <div className="media">
                              <div className="media-left mr-2">
                                <i className={dropdownScrollValue==0?("zmdi zmdi-smartphone-iphone zmdi-hc-fw font-22  align-middle"):("zmdi zmdi-smartphone-iphone zmdi-hc-fw font-22 text-muted align-middle")}></i>
                              </div>
                              <div className="media-body">
                                <a
                                  href={`tel: ${mobile && mobile}`}
                                  className="font-16 m-0"
                                >
                                  {mobile && mobile}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div ref={this.myBusiness}>
                      <div className="clearfix mt-4 pt-3">
                        <h6>{t("contactPersonAddress.businessLable")}</h6>
                        <hr />
                      </div>
                      <div className="media my-2">
                        <i className={dropdownScrollValue==1?("zmdi zmdi-pin zmdi-hc-fw  mt-2"):("zmdi zmdi-pin zmdi-hc-fw text-muted mt-2 text-muted ")}></i>
                        <div className="media-body">
                          <span
                            className="font-14"
                            style={{ whiteSpace: "pre" }}
                          >
                            {business_adress && business_adress}
                          </span>
                        </div>
                      </div>
                      <div className="my-2">
                        <i className={dropdownScrollValue==1?("zmdi zmdi-dribbble zmdi-hc-fw "):("zmdi zmdi-dribbble zmdi-hc-fw text-muted")}></i>
                        <a
                          href={website && website}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-14"
                        >
                          {website && website}
                        </a>
                      </div>
                      <div className="my-2">
                        <i className={dropdownScrollValue==1?("zmdi zmdi-local-printshop zmdi-hc-fw "):("zmdi zmdi-local-printshop zmdi-hc-fw text-muted")}></i>
                        <a href={`tel: ${fax && fax} `} className="font-14 m-0">
                          {fax && fax}
                        </a>
                      </div>
                      <div className="clearfix mt-4 pt-3">
                        <h6>{t("contactPersonAddress.privateLable")}</h6>
                        <hr />
                      </div>
                      <div className="my-2">
                        <i className={dropdownScrollValue?("zmdi zmdi-pin zmdi-hc-fw "):("zmdi zmdi-pin zmdi-hc-fw text-muted")}></i>
                        <span className="font-14">
                          {private_adress && private_adress}
                        </span>
                      </div>
                    </div>

                    <div ref={this.myMailing}>
                         <div className="clearfix mt-4 pt-3">
                                <h6>
                                  {t("PersonDetails.tab_title_distributor")}
                                </h6>
                                <hr />
                              </div>
                              <div className="form-row">
                      {mailing_list &&
                        mailing_list.map((item, index) => {
                          const {
                            id,
                            name,
                            list_creator_name,
                          } = item;
                          return (
                            // <a href="javascript;:" className="d-flex" key={index}>
                            //   <div className="icon-box-style2 mb-2">
                            //     <div className="icon-box yellow-bg d-flex">
                            //       <div className="d-flex align-items-center justify-content-center w-100">
                            //         <div className="rounded-50 bg-light">
                            //           <img
                            //             src={`data:image/png;base64,${
                            //               list_type_icon && list_type_icon
                            //             }`}
                            //             alt="icon"
                            //             style={{ width: "26px" }}
                            //           />
                            //         </div>
                            //       </div>
                            //     </div>
                            //     <div className="ml-3 my-3 d-inline-block">
                            //       <h5 className="m-0 font-18">{name && name} </h5>
                            //       <p className="text-muted font-12 mb-0">
                            //         {list_creator_name && list_creator_name}
                            //       </p>
                            //     </div>
                            //     <div
                            //       className="mr-3 my-3 ml-auto align-self-center"
                            //       onClick={() => {
                            //         this.deletelist(id);
                            //       }}
                            //     >
                            //       <img
                            //         src="/images/trash-simple.png"
                            //         alt="delete icon"
                            //         style={{ width: "16px", height: "16px" }}
                            //       />
                            //     </div>
                            //   </div>
                            // </a>
                            <>
                                <div className="col-6">
                                  <a
                                    href="javascript;:"
                                    className="card shadow-sm p-3 px-2 bg-yellow-bottom justify-content-between"
                                    key={index}
                                    onClick={(e)=>{
                                      e.preventDefault()
                                    }}
                                  >
                                    <div className="d-flex justify-content-between">
                                      <div className="">
                                        <h5 className="m-0 font-18">
                                          {name && name}{" "}
                                        </h5>
                                        <p className="text-muted font-12 mb-0">
                                          {list_creator_name &&
                                            list_creator_name}
                                        </p>
                                      </div>
                                      <div
                                        className=""
                                        onClick={() => {
                                          this.deletelist(id);
                                        }}
                                      >
                                        <img
                                          src="/images/trash-simple.png"
                                          alt="delete icon"
                                          style={{
                                            width: "16px",
                                            height: "16px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                            </>
                          );
                        })}
                        
                    </div>
                    </div>

                    <div ref={this.myComment}>
                      <div className="my-2">{comment && comment}</div>
                    </div>

                    <div ref={this.myOtherField}>
                      <div className="clearfix mt-4 pt-3">
                        <h6>{t("PersonDetails.tab_title_remarks")}</h6>
                        <hr />
                      </div>
                      {other_field_list &&
                        other_field_list.map((item, index) => {
                          return (
                            <div key={index}>
                              <div className="my-2 clearfix">
                                <div className="d-flex0 w-100">
                                  <div className="float-left font-16">
                                    {item.field_name + " : " + item.field_value}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
               
               

                    <div className="card mt-4" ref={this.mySocialMediaType}>
                              <div className="card-header border-bottom p-3 px-4 d-flex justify-content-between">
                                <h5 className="m-0">  {t("PersonDetails.tab_title_social_media")} </h5>
                                <ul className="list-inline text-tab font-size-14 m-0">
                                  <li className="list-inline-item">
                                    <a
                                      href="javascript:;"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                          socialMediaType: 0,
                                        });
                                      }}
                                      className={
                                        socialMediaType == 0
                                          ? "active text-muted"
                                          : "text-muted"
                                      }
                                    >
                                      <i className="zmdi zmdi-facebook zmdi-hc-lg"></i>
                                    </a>
                                  </li>
                              
                                  <li className="list-inline-item">
                                    <a
                                      href="javascript:;"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                          socialMediaType: 1,
                                        });
                                      }}
                                      className={
                                        socialMediaType == 1
                                          ? "active text-muted"
                                          : "text-muted"
                                      }
                                    >
                                      <i className="zmdi zmdi-twitter zmdi-hc-lg"></i>
                                    </a>
                                  </li>
                                 
                                  <li className="list-inline-item">
                                    <a
                                      href="javascript:;"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                          socialMediaType: 2,
                                        });
                                      }}
                                      className={
                                        socialMediaType == 2
                                          ? "active text-muted"
                                          : "text-muted"
                                      }
                                    >
                                      <i className="zmdi zmdi-instagram zmdi-hc-lg"></i>
                                    </a>
                                  </li>

                                  <li className="list-inline-item">
                                    <a
                                      href="javascript:;"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                          socialMediaType: 3,
                                        });
                                      }}
                                      className={
                                        socialMediaType == 3
                                          ? "active text-muted"
                                          : "text-muted"
                                      }
                                    >
                                      <i className="zmdi zmdi-linkedin zmdi-hc-lg"></i>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="card-body font-size-14">
                                <div className="row">
                                {socialMediaType == 0 && (
                               <iframe srcdoc={socialmedia_facebook_stream} style={{width:'100%'}}></iframe>
                              )}
                              {socialMediaType == 1 && (
                               <iframe srcdoc={socialmedia_twitter_stream} style={{width:'100%'}}></iframe>
                              )}
                              {socialMediaType == 2 && (
                               <iframe srcdoc={socialmedia_instagram_stream} style={{width:'100%'}}></iframe>
                              )}
                              {socialMediaType == 3 && (
                               <iframe srcdoc={socialmedia_linkedin_stream} style={{width:'100%'}}></iframe>
                              )}
                                </div>
                              </div>
                            </div>
                        
                  </div>
                </div>

                <PersonDetailSetteliteSideMenu
                  closeSlider={this.closeSlider}
                  headerMenuStatus={this.state.headerMenuStatus}
                  changeRouteStatus={this.props.changeRouteStatus}
                />
              {groupIdHeader!=0 && <FabIcon color="rounded-blue" openSlider={this.openSlider} />}
              </div>

              <Footer type="3" />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.groupPersonDetailStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.groupPersonDetailDispatchToProps
   
  ])
)(withTranslation()(withRouter(PersonDetail)));
