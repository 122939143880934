/* eslint eqeqeq: 0 */
import FloatingLabel from "floating-label-react";
import "floating-label-react/styles.css";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getPersonField,
  personSubscriptionDetail,
  updatePersonSubScription,
} from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";

class UpdateProfileMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: false,
      isLoading: false,
      fieldList: [],
      fieldDialogStatus: false,
      formField: [],
      first_name: "",
      last_name: "",
      title: "",
      contact_email_business: "",
      mail_list: [],
      personValueList: [],
      activate_hash: "",
      successStatus: 0,
      message: "",
    };
  }

  updateValueinForm = (item) => {
    const { formField } = this.state;

    const index = formField.findIndex((field) => field.id == item.id);
    formField[index] = {
      ...formField[index],
      ...{ item },
    };

    this.setState({
      formField,
    });
  };

  handleAfterCreateApiCall = (data) => {
    toast.success(this.props.t(data.message));
    // let mail = {
    //   id: data.id,
    //   name: name,
    //   description: description,
    //   list_type_icon: this.state.mailImage,
    //   list_type_id: this.state.mailId,
    //   child_element: paranet_id
    // };

    this.props.changeRouteStatus(0);
  };

  handleAfterFieldTypeApiCall = (data) => {
    let personDataTypeList = data.person_data_type_list;
    for (let i = 0; i < personDataTypeList.length; i++) {
      let item = personDataTypeList[i];
      if (item.is_default_custom == 1) {
        item["value"] = "";
        item["is_default_custom"] = 0;
        item["is_default_field"] = 0;
      } else {
        item["value"] = "";
        item["is_default_custom"] = 0;
      }
    }
    this.setState(() => {
      return {
        ...this.state,
        fieldList: data.person_data_type_list,
        formField: data.person_data_type_list,
      };
    });

    let activate_hash = this.props.history.location.hash;
    activate_hash = activate_hash.replace("#", "");
    this.setState({ activate_hash });

    apiCall(
      METHOD.QUERY,
      personSubscriptionDetail(activate_hash),
      (data) => {
        const {
          mailing_list,
          person_data,
          contact_email_business,
          title,
          first_name,
          name,
        } = data;
        this.setState(
          {
            mail_list: mailing_list,
            personValueList: person_data,
            first_name,
            last_name: name,
            contact_email_business,
            title,
          },
          () => {
            let tempFormField = this.state.formField;

            for (let i = 0; i < person_data.length; i++) {
              let item = person_data[i];
              const { id, value } = item;
              const index = tempFormField.findIndex((field) => {
                return field.id == id;
              });

              if (index != -1) {
                tempFormField[index]["value"] = value;
                this.setState({
                  formField: tempFormField,
                });
              }
            }
          }
        );
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleFieldDialogStatus = (fieldDialogStatus) => {
    this.setState({
      fieldDialogStatus,
    });
  };

  handleChangeText = (e, item) => {
    let value;
    if (item.field_type == "checkbox") {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }
    item["value"] = value;
    const { formField } = this.state;
    const index = formField.findIndex((field) => field.id == item.id);
    formField[index] = {
      ...formField[index],
      ...{ item },
    };

    this.setState({
      formField,
    });
  };

  handleStaticField = (e, type) => {
    if (type == "firstName") {
      this.setState({
        first_name: e.target.value,
      });
    } else if (type == "lastName") {
      this.setState({
        last_name: e.target.value,
      });
    } else if (type == "title") {
      this.setState({
        title: e.target.value,
      });
    } else if (type == "businessEmail") {
      this.setState({
        contact_email_business: e.target.value,
      });
    }
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };
  handleAfterCreatePerson = (data) => {
    const { message } = data;
    // toast.success(this.props.t(message));

    this.setState({
      message,
      successStatus: 1,
    });
    // this.props.history.push("/");
  };

  handleSubmit = (values) => {
    var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    const {
      first_name,
      last_name,
      title,
      formField,
      contact_email_business,
      mail_list,
      activate_hash,
    } = this.state;
    let formFieldStatus = "",
      boolStringStatus = false;

    for (let i = 0; i < formField.length; i++) {
      let item = formField[i];
      if (
        item.is_default_field == 1 &&
        item.is_required == 1 &&
        !item.value &&
        !boolStringStatus
      ) {
        boolStringStatus = true;
        formFieldStatus =
          this.props.t("addNewPerson.generalEmailValidation") + item.name;
      }
    }

    if (!first_name) {
      toast.info(this.props.t("addNewPerson.firstNameValidation"));
    } else if (!last_name) {
      toast.info(this.props.t("addNewPerson.lastNameValidation"));
    } else if (!contact_email_business) {
      toast.info(this.props.t("addNewPerson.emailValidation"));
    } else if (!regexEmail.test(contact_email_business)) {
      toast.info(this.props.t("addNewPerson.validEmailValidation"));
    } else if (formFieldStatus) {
      toast.info(formFieldStatus);
    } else {
      let person_Data = [];

      for (let i = 0; i < formField.length; i++) {
        let item = formField[i];
        if (item.is_default_field == 1) {
          let objPerson = {
            id: item.id,
            value: item.value,
          };
          person_Data.push(objPerson);
        }
      }

      let mail_subscription = "";
      let comma_seperate = [];

      for (let i = 0; i < mail_list.length; i++) {
        let item = mail_list[i];
        item.is_subscribe == 1 && comma_seperate.push(item.id);
      }
      mail_subscription = comma_seperate.join(",");

      person_Data = JSON.stringify(JSON.stringify(person_Data));
      apiCall(
        METHOD.QUERY,
        updatePersonSubScription(
          activate_hash,
          first_name,
          last_name,
          title,
          contact_email_business,
          person_Data,
          mail_subscription
        ),
        (data) => this.handleAfterCreatePerson(data),
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  handleCheckBoxChange = (index) => {
    let tempMailList = this.state.mail_list;

    tempMailList[index]["is_subscribe"] =
      tempMailList[index]["is_subscribe"] == 0 ? 1 : 0;
    this.setState({
      mail_list: tempMailList,
    });
  };
  componentDidMount() {
    apiCall(
      METHOD.QUERY,
      getPersonField(),
      (data) => this.handleAfterFieldTypeApiCall(data),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  render() {
    const { isLoading, formField, message, successStatus } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <div className="card-header header pt-3 blue-bg ">
                <div className="container  ">
                  <div className="text-center pt-3 text-light">
                    <h3 className="text-light">
                      {t("updateProfile.yourProfile")}
                    </h3>
                  </div>
                </div>
              </div>

              <div className="middle-section sab-middle-section4">
                {successStatus == 0 ? (
                  <div className="container my-4 00z-index-99">
                    <form className="login-form custom-login-form">
                      <p className="mb-4 text-center h4">
                        {t("updateProfile.infoTitle")}
                      </p>
                      <div className="form-group">
                        <FloatingLabel
                          placeholder={t("updateProfile.firstNamePlaceHolder")}
                          type="text"
                          onChange={(e) =>
                            this.handleStaticField(e, "firstName")
                          }
                          value={this.state.first_name}
                        />
                      </div>

                      <div className="form-group">
                        <FloatingLabel
                          placeholder={t("updateProfile.lastNamePlaceHolder")}
                          type="text"
                          onChange={(e) =>
                            this.handleStaticField(e, "lastName")
                          }
                          value={this.state.last_name}
                        />
                      </div>
                      <div className="form-group">
                        <FloatingLabel
                          placeholder={t("updateProfile.titlePlaceHolder")}
                          type="text"
                          onChange={(e) => this.handleStaticField(e, "title")}
                          value={this.state.title}
                        />
                      </div>
                      <div className="form-group">
                        <FloatingLabel
                          placeholder={t(
                            "updateProfile.businessNamePlaceHolder"
                          )}
                          type="email"
                          onChange={(e) =>
                            this.handleStaticField(e, "businessEmail")
                          }
                          value={this.state.contact_email_business}
                        />
                      </div>

                      {formField &&
                        formField.map((item, index) => {
                          const {
                            id,
                            name,
                            description,
                            field_type,
                            is_default_field,
                            value,
                          } = item;
                          return (
                            <React.Fragment key={index}>
                              {is_default_field == 1 && field_type == "text" && (
                                <div className="form-group">
                                  <FloatingLabel
                                    placeholder={description && description}
                                    type="text"
                                    id={id}
                                    onChange={(e) =>
                                      this.handleChangeText(e, item)
                                    }
                                    value={value}
                                  />
                                </div>
                              )}

                              {is_default_field == 1 && field_type == "email" && (
                                <div className="form-group">
                                  <FloatingLabel
                                    placeholder={description && description}
                                    type="email"
                                    id={id}
                                    onChange={(e) =>
                                      this.handleChangeText(e, item)
                                    }
                                    value={value}
                                  />
                                </div>
                              )}
                              {is_default_field == 1 && field_type == "tel" && (
                                <div className="form-group">
                                  <label className="font-14">
                                    {name && name}
                                  </label>
                                  <input
                                    type="tel"
                                    className="form-control"
                                    id={id}
                                    placeholder={description && description}
                                    onChange={(e) =>
                                      this.handleChangeText(e, item)
                                    }
                                    text={value}
                                  />
                                </div>
                              )}

                              {is_default_field == 1 && field_type == "link" && (
                                <div className="form-group">
                                  <label className="font-14">
                                    {name && name}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id={id}
                                    placeholder={description && description}
                                    onChange={(e) =>
                                      this.handleChangeText(e, item)
                                    }
                                    text={value}
                                  />
                                </div>
                              )}
                              {is_default_field == 1 &&
                                field_type == "text_multiline" && (
                                  <div className="form-group">
                                    <textarea
                                      type="textarea"
                                      className="form-control"
                                      id={id}
                                      placeholder={description && description}
                                      onChange={(e) =>
                                        this.handleChangeText(e, item)
                                      }
                                      text={value}
                                    />
                                  </div>
                                )}

                              {is_default_field == 1 &&
                                field_type == "checkbox" &&
                                name != "Alarmierungsrelevant" && (
                                  <div className="form-group">
                                    <input
                                      type="checkbox"
                                      id={id}
                                      className="mr-2"
                                      checked={value}
                                      onChange={(e) =>
                                        this.handleChangeText(e, item)
                                      }
                                    />
                                    <label htmlFor={id}>{name && name}</label>
                                  </div>
                                )}
                            </React.Fragment>
                          );
                        })}
                    </form>
                    {/* <div className="md-form mt-4">
                      <p className="mb-4 mt-4 text-left h4">
                        {t("updateProfile.selectSubscription")}
                      </p>
                      {mail_list &&
                        mail_list.map((item, index1) => {

                          const { id, name, is_subscribe } = item;
                          return (
                            <div
                              className="custom-control custom-checkbox mb-4 text-left"
                              key={index1}
                            >
                              <input
                                type="checkbox"
                                id={id}
                                className="mr-2"
                                checked={is_subscribe}
                                onChange={() =>
                                  this.handleCheckBoxChange(index1)
                                }
                              />

                              <label htmlFor={id}>{name && name}</label>
                            </div>
                          );
                        })}
                    </div>
                 */}

                    <div className="py-2 d-flex align-items-center">
                      {isLoading ? (
                        <div className="btn btn-primary font-13 btn-block pointer disabled">
                          {t("updateProfile.Save")}
                          <div className="spinner-border spinner-border-sm ml-1 text-blue">
                            <span className="sr-only">
                              {t("GeneralMessages.loading")}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="btn btn-primary font-13 btn-block pointer"
                          onClick={this.handleSubmit}
                        >
                          {t("updateProfile.Save")}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="pt-5 pb-3  text-center">
                    <img
                      src="/images/login_logo.png"
                      alt="loginLogo"
                      className="text-align-center"
                      style={{ width: "150px", height: "150px" }}
                    ></img>

                    <p className="mt-4 text-center h4">
                      {t(message && message)}
                    </p>
                  </div>
                )}
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.eventListStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupDetailPersonListDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(UpdateProfileMobile)));
