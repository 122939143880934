import React, { Component } from "react";

export default class UserType extends Component {

  getCheckBoxStaus = (id) => {
    if (this.props.checklist && this.props.checklist.includes(id)) {
      return true;
    } else {
      return false;
    }
  }
  render() {
    const { item } = this.props;
    const { name, id } = item;
    return (
      <React.Fragment>
        <li className="nav-item pointer">
          <a className="nav-link" href="#" >
            <input
              type="checkbox"
              className="mr-4"
              checked={this.getCheckBoxStaus(id)}
              onChange={(e) => {
                this.props.checkboxChangeEvent(e, id, name)}}
            />

            <span className="ml-2">{name && name}</span>
          </a>
        </li>
      </React.Fragment>
    );
  }
}
