/* eslint eqeqeq: 0 */
/* eslint eqeqeq: 0 */
import moment from "moment-timezone";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  channelDetail,
  checkCost,
  checksmscost,
  getSocialDetails,
  removechannelmessage,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../../util/utils";
import CommonLoader from "../../CommanComponents/CommonLoader";
import LoadingPage from "../../CommanComponents/LoadingPage";
import EditNewChannelReport from "../ChannelDetailDesktop/EditNewChannelReport";
import ChannelMailLinkIndex from "./ChannelMailLinkIndex";
import EmailChannelSend from "./EmailChannelSend";
import FacebookChannelSend from "./FacebookChannelSend";
import RecipientsList from "./RecipientsList";
import SMSSend from "./SMSSend";
import TexttoSpeechSend from "./TexttoSpeechSend";
import TwilioSend from "./TwilioSend";
import TwitterChannelSend from "./TwitterChannelSend";

const snippet_type_distributor = ["text2speech", "sms", "email","twilio"];
const snippet_type_cost = ["text2speech", "sms"];
const snippet_type_count = ["sms", "twitter"];
const snippet_type_person_progress = ["text2speech", "sms", "email","twilio"];

class ChannelDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerMenuStatus: false,
      data: {},
      isLoading: false,
      tabSelection: 0,
      sideLineChannelColor: "",
      backgroundChannelColor: "",
      snippetCount: 0,
      cost: 0,
      socialData: {},
      editMessagePermission: false,
      sendMessagePermission: false,
      deleteMessagePermission: false,
      readMessagePermission: false,
      deleteLoader: false,
      editChannelModal: false,
      addRacipientModal: false,
    };
  }

  openSlider = () => {
    this.setState(() => {
      return {
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        headerMenuStatus: false,
      };
    });
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  deleteMessage = () => {
    const message_id = this.props.match.params.message_id;

    confirmAlert({
      title: this.props.t("ChannelDetail.deleteMessage"),
      message: this.props.t("ChannelDetail.deleteTitle"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              removechannelmessage(getUserID(), message_id),
              (data) => {
                let mybackStackpath =
                  "/event/" +
                  this.props.match.params.event_id +
                  "/report/" +
                  this.props.match.params.report_id;
                this.props.history.push(mybackStackpath);
              },
              (isLoading) => {
                this.setState({ deleteLoader: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const readMessagePermission = checkUserPermission("read_message");
    const editMessagePermission = checkUserPermission("edit_message");
    const sendMessagePermission = checkUserPermission("send_message");
    const deleteMessagePermission = checkUserPermission("delete_message");
    this.setState({
      readMessagePermission,
      editMessagePermission,
      sendMessagePermission,
      deleteMessagePermission,
    });
    apiCall(
      METHOD.QUERY,
      getSocialDetails(getUserID()),
      (data) => {
        this.setState({ socialData: data });
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
    const message_id = this.props.match.params.message_id;
    const event_id = this.props.match.params.event_id;
    if (readMessagePermission) {
      this.channelDetailApi(message_id, event_id);
    } else {
      toast.info(this.props.t("GeneralMessages.permissionError"));
    }
  }

  changeMessage = (message_id) => {
    this.setState({
      tabSelection: 0,
    });
    const event_id = this.props.match.params.event_id;
    const report_id = this.props.match.params.report_id;
    const myPath = `/event/${event_id}/report/${report_id}/message/${message_id}`;
    this.props.history.push(myPath);

    const readMessagePermission = checkUserPermission("read_message");
    if (readMessagePermission) {
      this.channelDetailApi(message_id, event_id);
    } else {
      toast.info(this.props.t("GeneralMessages.permissionError"));
    }
  };
  channelDetailApi = (message_id, event_id) => {
    apiCall(
      METHOD.QUERY,
      channelDetail(getUserID(), message_id, event_id),
      (data) => this.handleChannelDetailResponse(data),
      (isLoading) => this.setState({ isLoading }),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleChannelDetailResponse = (data) => {
    //  this.handleChannelTypeColor(data.channel_type_identifier);
    this.props.getChannelDetail(data);

    const {
      content,
      channel_id,
      channel_type_identifier,
    } = this.props.channelDetail;

    let content_result = JSON.parse(content);
    let result = content_result.result;
    let textContent = result
      .replace(/<\s*br[^>]?>/, "\n")
      .replace(/(<([^>]+)>)/g, "");
    textContent = textContent.trim();
    textContent = textContent.replace("&nbsp;", "");

    if (snippet_type_cost.includes(channel_type_identifier)) {
      this.checkTextCost(textContent, channel_id, channel_type_identifier);
    }
    if (snippet_type_count.includes(channel_type_identifier)) {
      let length2 = textContent.length;
      this.setState({ snippetCount: length2 });
    }
  };

  handleTabSelection = (tabSelection) => {
    this.setState({
      tabSelection,
    });
  };

  checkTextCost = (markdown, channel_type_id, channel_type_identifier) => {
    if (channel_type_identifier == "sms") {
      apiCall(
        METHOD.QUERY,
        checksmscost(
          getUserID(),
          channel_type_id,
          JSON.stringify(markdown),
          channel_type_identifier
        ),
        (data) => {
          this.setState({
            cost: data.price,
          });
        },
        (isLoading) => {},
        (data) => {
          this.setState({
            cost: 0,
          });
        }
      );
    } else {
      apiCall(
        METHOD.QUERY,
        checkCost(
          getUserID(),
          channel_type_id,
          JSON.stringify(markdown),
          channel_type_identifier
        ),
        (data) => {
          this.setState({
            cost: data.price,
          });
        },
        (isLoading) => {},
        (data) => {
          this.setState({
            cost: 0,
          });
        }
      );
    }
  };

  handleChannelTypeColor = (channel_type_identifier) => {
    if (channel_type_identifier == "email") {
      this.setState({
        sideLineChannelColor: "box-left-line-email",
        backgroundChannelColor: "email-bg",
      });
    } else if (channel_type_identifier == "facebook") {
      this.setState({
        sideLineChannelColor: "box-left-line-facebook",
        backgroundChannelColor: "facebook-bg",
      });
    } else if (channel_type_identifier == "twitter") {
      this.setState({
        sideLineChannelColor: "box-left-line-twitter",
        backgroundChannelColor: "twitter-bg",
      });
    } else if (channel_type_identifier == "sms") {
      this.setState({
        sideLineChannelColor: "box-left-line-sms",
        backgroundChannelColor: "sms-bg",
      });
    } else if (channel_type_identifier == "whatsapp") {
      this.setState({
        sideLineChannelColor: "box-left-line-whatsapp",
        backgroundChannelColor: "whatsapp-bg",
      });
    } else if (channel_type_identifier == "web") {
      this.setState({
        sideLineChannelColor: "box-left-line-internet",
        backgroundChannelColor: "internet-bg",
      });
    } else if (channel_type_identifier == "cms_feed") {
      this.setState({
        sideLineChannelColor: "box-left-line-newsLatter",
        backgroundChannelColor: "newsLatter-bg",
      });
    } else {
      this.setState({
        sideLineChannelColor: "box-left-line-texttoSpeech",
        backgroundChannelColor: "texttoSpeech-bg",
      });
    }
  };

  handleContent = (content) => {
    if (content) {
      let content_result = JSON.parse(content);
      let result = content_result.result;
      return result;
    }
  };

  handlebadge = () => {
    const { snippetCount } = this.state;

    if (snippetCount > 280) {
      return "badge badge-danger mr-2 mb-2 text-white badge-right";
    } else {
      return "badge badge-success mr-2 mb-2 text-white badge-right";
    }
  };

  handleBackStack = () => {
    let mybackStackpath =
      "/event/" +
      this.props.match.params.event_id +
      "/report/" +
      this.props.match.params.report_id;

    let { from } = this.props.location.state || {
      from: { pathname: mybackStackpath },
    };
    this.props.history.push(from);
  };

  handleActiveIndex = (channel_id, id) => {
    if (channel_id == id) {
      this.handleScroll(channel_id);
      return "active";
    } else {
      return "";
    }
  };

  handleScroll = (id) => {
    const index = this.props.channelDetail.channel_list.findIndex(
      (channel) => channel.channel_id == id
    );

    if (index > 3) {
      setTimeout(function () {
        var element = document.getElementById(`li_${id}`);
        var element1 = document.getElementById("ulID");
        var elementPosition = element.getBoundingClientRect();
        element1.scrollLeft = elementPosition.left;
      }, 500);
    }
  };
  handlesendDate = (message_send_datetime, event_datetime) => {
    const event_date = moment
      .unix(event_datetime)
      .tz(moment.tz.guess())
      .format("DD.MM.YYYY");
    const message_send_date = moment
      .unix(message_send_datetime)
      .tz(moment.tz.guess())
      .format("DD.MM.YYYY");

    if (event_date == message_send_date) {
      return (
        <span className="mr-3 mb-1 mb-md-0">
          <i className="zmdi zmdi-alarm-check zmdi-mail-fw font-16 mr-2"></i>
          {moment
            .unix(message_send_datetime)
            .tz(moment.tz.guess())
            .format("HH:mm")}
        </span>
      );
    } else {
      return (
        <span className="mr-3">
          <i className="zmdi zmdi-alarm-check zmdi-mail-fw font-16 mr-2"></i>
          {moment
            .unix(message_send_datetime)
            .tz(moment.tz.guess())
            .format("DD.MM.YYYY")}
        </span>
      );
    }
  };

  handleEditChannel = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          editChannelModal: !prev.editChannelModal,
        };
      },
      () => {
        if (this.state.editChannelModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            const message_id = this.props.match.params.message_id;
            const event_id = this.props.match.params.event_id;
            this.channelDetailApi(message_id, event_id);
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };
  handleAddRacipient = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          addRacipientModal: !prev.addRacipientModal,
        };
      },
      () => {
        if (this.state.addRacipientModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.setState({
              tabSelection: 0,
            });
            const message_id = this.props.match.params.message_id;
            const event_id = this.props.match.params.event_id;
            this.channelDetailApi(message_id, event_id);
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };
  loader = (<LoadingPage />);
  render() {
    const { isLoading, tabSelection, snippetCount, cost } = this.state;
    const {
      title,
      content,
      event_name,
      event_creator_name,
      channel_type_icon,
      channel_list,
      channel_id,
      channel_type_identifier,
      message_datetime,
      message_send_datetime,
      message_status,
      message_status_identifier,
      percentage_status,
      color,
      event_id,
      report_id,
      message_link,
      report_title,
    } = this.props.channelDetail && this.props.channelDetail;
    const {
      socialData,
      editMessagePermission,
      sendMessagePermission,
      deleteMessagePermission,
      deleteLoader,
      editChannel,
      editChannelModal,
      addRacipientModal,
    } = this.state;
    const border_left = `solid 2px ${color}`;
    const sendingStatusFromTranslation = `sendingStatus.${message_status_identifier}`;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="content">
              <div className="section section-padding">
                <div className="container">
                  <div className="section-container">
                    <div className="d-flex t-n-25">
                      <Link
                        to={`/event/${event_id}/report/${report_id}`}
                        className="mr-3"
                      >
                        <i className="fa fa-arrow-left fa-lg font-size-18"></i>
                      </Link>
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to="/home"> {t("EventDetails.event")} </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={`/event/${event_id && event_id}`}>
                              {event_name && event_name}{" "}
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={`/event/${event_id}/report/${report_id}`}>
                              {report_title}
                            </Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            {title && title}
                          </li>
                        </ol>
                      </nav>
                    </div>
                    <div className="section-title text-center">
                      <h1 className="page-title h4">{title && title}</h1>
                    </div>
                    <div className="section-content">
                      <div className="card">
                        {isLoading && <CommonLoader />}
                        <div className="card-header-custom">
                          <div className="media">
                            <div
                              className="bg-gray left-icon-box d-flex align-items-center justify-content-center text-center"
                              style={{ backgroundColor: color }}
                            >
                              <div className="circle big m-0">
                                <img
                                  src={`data:image/png;base64,${
                                    channel_type_icon && channel_type_icon
                                  }`}
                                  alt="ico"
                                  style={{ width: "32px" }}
                                />
                              </div>
                            </div>
                            <div className="media-body pt-3 px-4">
                              <div className="form-row">
                                <div className="col-md-4">
                                  <h5 className="font-size-18 mb-1">
                                    {title && title}
                                  </h5>
                                  <small>
                                    {event_creator_name && event_creator_name}
                                  </small>
                                  <div className="d-flex align-items-center font-size-14 mt-2">
                                    <div className="icon mr-3">
                                      <i
                                        className="zmdi zmdi-calendar-note zmdi-hc-fw font-16 mr-2p"
                                        style={{ verticalAlign: "-1px" }}
                                      ></i>
                                      {message_datetime &&
                                        moment
                                          .unix(message_datetime)
                                          .tz(moment.tz.guess())
                                          .format("DD.MM.YYYY")}
                                    </div>
                                    <div className="icon mr-3">
                                      <i
                                        className="zmdi zmdi-time zmdi-hc-fw font-16 mr-2p"
                                        style={{ verticalAlign: "-1px" }}
                                      ></i>
                                      {message_datetime &&
                                        moment
                                          .unix(message_datetime)
                                          .tz(moment.tz.guess())
                                          .format("HH:mm")}
                                    </div>
                                    <div className="icon mr-3">
                                      {message_status &&
                                        snippet_type_person_progress.includes(
                                          channel_type_identifier
                                        ) && (
                                          <React.Fragment>
                                            <span className="mr-3 mb-1 mb-md-0">
                                              <i className="zmdi zmdi-mail-send zmdi-mail-fw font-16 mr-2"></i>
                                              {message_status_identifier !=
                                                "sending_failed" &&
                                                message_status_identifier !=
                                                  "sent_successfully" &&
                                                t(
                                                  sendingStatusFromTranslation
                                                ) +
                                                  " (" +
                                                  percentage_status +
                                                  "%)"}
                                              {message_status_identifier ==
                                                "sending_failed" ||
                                                (message_status_identifier ==
                                                  "sent_successfully" &&
                                                  t(
                                                    sendingStatusFromTranslation
                                                  ))}
                                            </span>
                                          </React.Fragment>
                                        )}
                                      {message_send_datetime &&
                                        message_status_identifier &&
                                        message_status_identifier ==
                                          "sent_successfully" &&
                                        this.handlesendDate(
                                          message_send_datetime,
                                          message_datetime
                                        )}{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-8">
                                <div className="font-size-14 action-icon text-right d-flex justify-content-end">

                                    {snippet_type_distributor.includes(
                                      channel_type_identifier
                                    ) &&
                                      this.props.channelDetail
                                        .message_status_identifier == "" && (
                                        <a
                                          href="javascript;:"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.handleAddRacipient();
                                          }}
                                          className="mr-4"
                                        >
                                          <i className="zmdi zmdi-plus zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                          {t("ChannelDetail.addRecipientLable")}
                                        </a>
                                      )}

                                    {editMessagePermission && (
                                      <a
                                        href="javascript;:"
                                        className="mr-4"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleEditChannel();
                                        }}
                                      >
                                        <i className="zmdi zmdi-accounts-alt zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                        {t("ChannelDetail.editChannelReport")}
                                      </a>
                                    )}

                                    {deleteMessagePermission && (
                                      <a
                                        href="javascript;:"
                                        className="mr-4"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.deleteMessage();
                                        }}
                                      >
                                        <i className="zmdi zmdi-delete zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                        {t("ChannelDetail.deleteMessage")}
                                      </a>
                                    )}

                                    {sendMessagePermission && (
                                      <>
                                      
                                        {channel_type_identifier &&
                                          channel_type_identifier ==
                                            "text2speech" && (
                                            <TexttoSpeechSend
                                              closeSlider={
                                                this.props.closeSlider
                                              }
                                            />
                                          )}
                                            {channel_type_identifier &&
                                          channel_type_identifier ==
                                            "twilio" && (
                                            <TwilioSend
                                              closeSlider={
                                                this.props.closeSlider
                                              }
                                            />
                                          )}
                                        {channel_type_identifier &&
                                          channel_type_identifier == "sms" && (
                                            <SMSSend
                                              closeSlider={
                                                this.props.closeSlider
                                              }
                                            />
                                          )}
                                        {channel_type_identifier &&
                                          channel_type_identifier ==
                                            "email" && (
                                            <EmailChannelSend
                                              closeSlider={
                                                this.props.closeSlider
                                              }
                                            />
                                          )}
                                        {channel_type_identifier &&
                                          channel_type_identifier ==
                                            "facebook" && (
                                            <FacebookChannelSend
                                              closeSlider={
                                                this.props.closeSlider
                                              }
                                              appID={socialData.facebook_app_id}
                                              appSecretKey={
                                                socialData.facebook_app_secret
                                              }
                                            />
                                          )}
                                        {channel_type_identifier &&
                                          channel_type_identifier ==
                                            "twitter" && (
                                            <TwitterChannelSend
                                              closeSlider={
                                                this.props.closeSlider
                                              }
                                              consumerKey={
                                                socialData.twitter_consumer_key
                                              }
                                              consumerSecret={
                                                socialData.twitter_consumer_secret
                                              }
                                            />
                                          )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body-custom side-line">
                          <div className="media">
                            <div className="left-icon-box">
                              <div className="circle icon"></div>
                            </div>
                            <div className="media-body pt-2 px-4">
                              <div className="d-flex align-items-center font-size-14 mb-3">
                                <a
                                  href="javascript;:"
                                  className={
                                    tabSelection == 0
                                      ? "text-dark text-underline mr-3"
                                      : "mr-3"
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleTabSelection(0);
                                  }}
                                >
                                  {t("ChannelDetail.newsTextLable")}
                                </a>
                                {snippet_type_distributor.includes(
                                  channel_type_identifier
                                ) && (
                                  <a
                                    href="javascript;:"
                                    className={
                                      tabSelection == 1
                                        ? "text-dark text-underline mr-3"
                                        : "mr-3"
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.handleTabSelection(1);
                                    }}
                                  >
                                    {t("ChannelDetail.distributorLable")}
                                  </a>
                                )}
                              </div>
                              {tabSelection == 1 ? (
                                <RecipientsList
                                  channelDetailApi={(message_id, event_id) =>
                                    this.channelDetailApi(message_id, event_id)
                                  }
                                  message_status_identifier={
                                    message_status_identifier
                                  }
                                />
                              ) : (
                                <React.Fragment>
                                  <div
                                    className="icon-box-style1 mb-4"
                                    style={{ borderLeft: border_left }}
                                  >
                                    <div className="pl-3 py-3 d-flex w-100 ">
                                      <div className="w-100">
                                        <div
                                          id="final_div"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              content &&
                                              this.handleContent(content),
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="align-items-center justify-content-between  mr-4">
                                    <p className="font-13 mb-0">
                                      {event_creator_name && event_creator_name}
                                    </p>
                                  </div>
                                  <div className="d-flex  mr-4">
                                    {channel_type_identifier == "twitter" &&
                                      message_link != "" && (
                                        <a
                                          className="font-13 mb-0"
                                          style={{ float: "left" }}
                                          href={message_link && message_link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {message_link && message_link}
                                        </a>
                                      )}
                                  </div>

                                  <div className="py-2 d-flex align-items-center float-right0 ">
                                    {snippet_type_count.includes(
                                      channel_type_identifier
                                    ) && (
                                      <p
                                        className={this.handlebadge()}
                                        style={{ float: "right" }}
                                      >
                                        {t("AddNewChannel.character")}{" "}
                                        {snippetCount}
                                      </p>
                                    )}

                                    {snippet_type_cost.includes(
                                      channel_type_identifier
                                    ) && (
                                      <p className="badge badge-success mr-2 mb-2">
                                        {t("AddNewChannel.credits")}
                                        {cost && cost}
                                      </p>
                                    )}
                                  </div>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                        {editChannelModal && (
                          <EditNewChannelReport
                            handleEditChannel={(isFromSave) => {
                              this.handleEditChannel(isFromSave);
                            }}
                          />
                        )}
                        {addRacipientModal && (
                          <ChannelMailLinkIndex
                            handleAddRacipient={(isFromSave) => {
                              this.handleAddRacipient(isFromSave);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.channelDetailStateToProps,
        stateToProps.channelListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.channelDetailsDispatchToProps,
    actions.channelListDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(ChannelDetails)));
