import React from "react";
import MediaQuery from "react-responsive";
import MailListMobile from "./MailListMobile/Index";
import MailListDesktop from "./MailListDesktop/MailingList";

export default function MailListResponsive() {
  return (
    <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <MailListDesktop />
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <MailListMobile />
      </MediaQuery>
    </React.Fragment>
  );
}
