/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import FooterDesktop from "../../Footer/FooterDesktop";
import HeaderDesktop from "../../Header/HeaderDesktop";
import AddPerson from "./AddPerson";
import EditNewGroup from "./EditNewGroup";
import PersonList from "./PersonList";
import RearrangePerson from "./RearrangePerson";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeStatus: 0,
    };
  }
  changeRouteStatus = (value) => {
    this.setState((prevState) => {
      return {
        routeStatus: value,
      };
    });
  };
  render() {
    const { routeStatus } = this.state;
    return (
      <div className="content-wrapper">
        <HeaderDesktop selectionType={3}/>
        <div className="content">
          <div className="section section-padding">
            <div className="container">
              <div className="0section-container">
                <PersonList />
              </div>
            </div>
          </div>
        </div>
        <FooterDesktop selectionType={3}/>
      </div>
    );
  }
}
export default withRouter(Index);
