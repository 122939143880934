/* eslint eqeqeq: 0 */
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { editorStateFromRaw, MegadraftEditor } from "megadraft";
import "megadraft/dist/css/megadraft.css";

import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { UpdateReportDataQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";
import Footer from "../../Footer/FooterMobile";
import LoadingPage from "../../CommanComponents/LoadingPage";

class EditNewReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportImage: "EB",
      report: "Einberufung",
      reportID: 0,
      channelName: "",
      // Display snippet
      content: ``,
      oldContent: ``,
      editorState: editorStateFromRaw(null),
      snippetType: "",
      snippetId: 0,
      snippetKbdId: 0,
      dialogueStatus: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    var megadraft = document.querySelector("#megadraft-editor");
    megadraft.onclick = function () {
      this.focus();
    };

    const {
      title,
      content,
      report_type_name,
      report_type_id,
      report_type_icon,
    } = this.props.channelList;

    let content_result = JSON.parse(content);
    let new_content = content_result.result;
    let old_content = content_result.content;

    if (!new_content.includes("<br")) {
      new_content = new_content.split("\n").join("<br/>");
    }

    let brandImageDefault, brandNameDefault, brandID;
    brandNameDefault = report_type_name;
    brandImageDefault = report_type_icon;
    brandID = report_type_id;

    this.editordata = new_content;
    this.setState({
      reportImage: brandImageDefault,
      reportID: brandID,
      report: brandNameDefault,
      channelName: title,
      editorState: EditorState.createWithContent(stateFromHTML(new_content)),
      oldContent: `${old_content}`,
      content: `${new_content}`,
    });
  }

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  closeDialogue = () => {
    this.setState({
      dialogueStatus: false,
    });
  };

  handleReportNameTextChange = (e) => {
    this.setState({
      channelName: e.target.value,
    });
  };

  onEditorChange = (editorState) => {
    this.setState({ editorState });
  };

  handleModelChange = (editorState) => {
    this.editordata = editorState;
  };

  onSaveClick = () => {
    // const { editorState } = this.state;
    // let html = stateToHTML(editorState.getCurrentContent());
  };

  creatReport = () => {
    const { editorState } = this.state;

    if (this.state.channelName.length == 0) {
      toast.info(this.props.t("AddNewChannel.reportlNameValidation"));
    } else {
      let html = stateToHTML(editorState.getCurrentContent());
      this.setState(
        {
          content: html,
        },
        () => {
          this.apiCallCreateReport();
        }
      );
    }
  };
  apiCallCreateReport = () => {
    const {
      content,
      oldContent,
      reportID,
      report,
      reportImage,
      channelName,
    } = this.state;
    const { report_id, event_id } = this.props.channelList;
    apiCall(
      METHOD.QUERY,
      UpdateReportDataQuery(
        report_id,
        event_id,
        getUserID(),
        reportID,
        channelName,
        JSON.stringify(oldContent),
        JSON.stringify(content)
      ),
      (data) => {
        let content_result = JSON.parse(this.props.channelList.content);
        content_result["result"] = content;
        content_result["content"] = oldContent;

        const reportObj = {
          title: channelName,
          content: JSON.stringify(content_result),
          report_type_id: reportID,
          report_type_name: report,
          report_type_icon: reportImage,
        };

        this.props.updateChannelDetail(reportObj);
        toast.success(this.props.t(data.message));
        this.props.handleEditReportModal()
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  render() {
    const {
      isLoading,
    } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
            <div className="d-block">
              <div
                className="modal fade show"
                id="NewMessage"
                tabindex="-1"
                aria-labelledby="NewMessageLabel"
                aria-modal="true"
                style={{display:'block'}}
              >
                <div className="modal-dialog modal-lg 0modal-dialog-centered 0modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header border-0 d-block pt-4 pb-0">
                      <div className="d-flex align-items-center justify-content-between mb-4">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={()=>{
                            this.props.handleEditReportModal()
                          }}
                        >
                          <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                        </button>
                      <h5 className="modal-title">{t("AddNewReport.editMessageLable")}</h5>

                        <button
                          type="button"
                          className="btn btn-primary-theme"
                          data-dismiss="modal"
                          onClick={this.creatReport}
                        >
                          {t("AddNewReport.btnSave")}
                        </button>
                      </div>
                    </div>
                    <div className="modal-body">
                {isLoading && <LoadingPage />}

                      <small className="d-block mb-2">  {t("AddNewReport.type")}</small>
                      <div className="radio-container d-flex overflow-x-auto mb-3 p-0">
                      <div
                         className="custom-control custom-radio custom-control-box text-dark mr-3 mb-3">
                        <input
                          type="radio"
                          name="NeueMeldung"
                          id="reportType"
                          className="custom-control-input"
                          checked
                        />
                        <label
                          className="card-body py-3 px-2 mb-0 text-center custom-control-label"
                          htmlFor="reportType"
                        >
                          <p className="mb-2">
                            <img
                              src={`data:image/png;base64,${
                                this.state.reportImage && this.state.reportImage
                              }`}
                              alt="ico"
                              style={{
                                width: "24px",
                                height: "24px",
                              }}
                            />
                          </p>
                          <p className="font-size-14 text-nowrap mb-0">  {this.state.report && this.state.report}</p>
                        </label>
                      </div>
                 
                       </div>
                 <div className="form-group">
                  <label className="font-size-14"> {t("AddNewReport.title")}
                    </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleReportNameTextChange}
                    name="title"
                    value={this.state.channelName}
                  />
                </div>
                    
                  <div className="icon-box-style1 box-left-line-yellow mb-4 ">
                    <div className="pl-3 py-3 flex-fill">
                      <div className="w-100">
                        <MegadraftEditor
                          editorState={this.state.editorState}
                          onChange={this.onEditorChange}
                        />
                      </div>
                    </div>
                  </div>
           
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop fade show"></div>
            </div>
          </React.Fragment>
   );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.channelListStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.channelListDispatchToProps,
  ])
)(withRouter(withTranslation()(EditNewReport)));
