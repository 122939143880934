/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { GroupListQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID, getValueFromLocalStorage, saveDatatoLocalStorage } from "../../../util/utils";
import FabIcon from "../../CommanComponents/FabIcon";
import LoadingPage from "../../CommanComponents/LoadingPage";
import LogoutMenu from "../../CommanComponents/LogoutMenu";
import NoRecordFound from "../../CommanComponents/NoRecordFound";
import TopHeader from "../../CommanComponents/TopHeader";
import Footer from "../../Footer/FooterMobile";
import GroupItemGridView from "./GroupItemGridView";
import GroupItemListView from "./GroupItemListView";
import GroupSetteliteSideMenu from "./GroupSetteliteSideMenu";
import SearchGroup from "./SearchGroup";
import SearchGroupListBaseOnPerson from "./SearchGroupListBaseOnPerson";
import SearchPersonBaseonCompany from "./SearchPersonBaseonCompany";
import SearchPersonBaseOnGroup from "./SearchPersonBaseOnGroup";
import SearchPersonResult from "./SearchPersonResult";

class GroupList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      groupList: [],
      end: 10,
      headerMenuStatus: false,
      loader: false,
      searchGroupList: [],
      searchPersonList: [],
      sortType: 1,
      displayType:0,
      searchDisplayType: 0,
      groupListBaseOnPerson: [],
      personListBaseOnGroupID:[],
      personListBreadCumb:[],
      selectedPersonId:0,
      groupIdBaseOnPersonDetail:0
    };
    this.searchRef = React.createRef();
  }

  handleLoader = (status) => {
    this.setState({
      loader: status,
    });
  };

  loader = (<LoadingPage key={0} />);

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  loadItem = () => {
    const { end, loader, sortType } = this.state;
    if (!loader && this.props.groupList.hasMore) {
      this.setState({
        loader: true,
      });
      apiCall(
        METHOD.QUERY,
        GroupListQuery(
          getUserID(),
          this.props.groupList.start,
          end,
          0,
          "",
          sortType
        ),
        (data) => {
          this.props.getGroupList(data.group_list);
          this.handleGroupApiResponse(data);
        },
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  
  handlePersonListBaseonCompany = (personListBaseOnGroupID)=>{
    this.setState({
      personListBaseOnGroupID,
      searchDisplayType:4
    })
  }

  changeSortType = (sortType) => {
    this.setState(
      {
        sortType: sortType,
      },
      () => {
        this.props.clearGroupState();
      }
    );
  };
  handleGroupApiResponse = (data) => {
    if (data.is_last == 1) {
      this.props.getGroupHasMoreValue(false);
    }
    this.props.getGroupStartValue(this.props.groupList.start + this.state.end);

    this.setState({
      loader: false,
    });
  };

  setSearchGroupList = (groupList, personList) => {
    this.setState({
      searchGroupList: groupList,
      searchPersonList: personList,
    });
  };

  componentDidMount() {
    const groupDisplayType = getValueFromLocalStorage("groupDisplayType");
    this.setState({
      displayType:groupDisplayType
    })
  }





  handleDisplayType =()=>{
    this.setState({
      searchDisplayType:0
    })
  }
  
  setSearchGroupList = (personList) => {
    if (personList.length == 0) {
    }
    this.setState({
      searchPersonList: personList,
      searchDisplayType: personList.length == 0 ? 0 : 1,
    });
  };

  handleSearchDisplayTypeToInitialStage = () => {
    this.setState({
      searchDisplayType:0,
      search:'',
      value:''

    });
  };
  handleSearchDisplayType  = (searchDisplayType ) => {
    this.setState({
      searchDisplayType,
      search:'',
      value:''
    });
  };

  handleGroupbaseOnPerson = (mailList) => {
    if (mailList.length == 0) {
      this.setState({
        groupListBaseOnPerson: [],
        searchPersonList: [],
        // searchDisplayType: 0,
      });
    } else {
      this.setState({
        // searchDisplayType: 0,
        groupListBaseOnPerson: mailList,
      });
    }
  };
  // getMailPersonList=(personId)=>{
  //   apiCall(
  //     METHOD.QUERY,
  //     getmailinglistfromperson(getUserID(),personId),
  //     (data) => this.handleGetGroupPersonResponse(data),
  //     (isLoading) => this.handleLoader(isLoading),
  //     (data) => {
  //       toast.error(this.props.t(data.message));
  //     }
  //   );
  // }

  // handleGetGroupPersonResponse=(data)=>{
  //   console.log("data",data.person_list);
  //   // this.props.handleMailbaseOnPerson(data.mailing_list)
  // }

  
  setGroupFromPerson=(groupListBaseOnPerson,selectedPersonId)=>{
    console.log("groupListBaseOnPerson",groupListBaseOnPerson);
    console.log("selectedPersonId",selectedPersonId);
    if(groupListBaseOnPerson.length!=0){
      this.setState({
        groupListBaseOnPerson,
        searchDisplayType:2,
        selectedPersonId
      })
    }else{
      toast.info(
        this.props.t("GeneralMessages.noMailFound") );
    }
  
  }

  handleGroupWiseResponse=(personListBaseOnGroupID,personResponse,personListBreadCumb,groupIdBaseOnPersonDetail)=>{
    this.setState({
      personListBaseOnGroupID,
      personListBreadCumb,
      personResponse,
      searchDisplayType:3,
      groupIdBaseOnPersonDetail
    })
  }
  render() {
    const { end,  sortType,displayType,
      searchDisplayType,groupListBaseOnPerson,selectedPersonId,personListBreadCumb,personListBaseOnGroupID,
      groupIdBaseOnPersonDetail} = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-blue sab-header1 sticky-top d-flex flex-column">
                <div className="container d-flex flex-column flex-fill">
                  <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                    <TopHeader />
                    <p className="mb-0 ml-auto text-light">
                      {t("GroupListScreen.headerTitle")}
                    </p>
                    <LogoutMenu />
                  </div>
          
                  <SearchGroup
                    handleMailbaseOnPerson={(mailList) => {
                      this.handleMailbaseOnPerson(mailList);
                    }}
                    handlePersonListBaseonCompany={(personList)=>{
                      this.handlePersonListBaseonCompany(personList)
                    }}
                    setGroupFromPerson={(personList,selectedPersonId)=>{this.setGroupFromPerson(personList,selectedPersonId)}}
                    searchArray={this.setSearchGroupList}
                    handleSearchDisplayType={(searchDisplayType) => {
                      this.handleSearchDisplayType(searchDisplayType);
                    }}
                  />
                </div>
              </div>

              <div className="middle-section sab-middle-section1 ">
            
            {searchDisplayType ==0 &&  <div className="container my-3 z-index-99">
                <div className="d-flex justify-content-between">
                  <div className="mb-2">
                    <div className="clearfix sorting-filter">
                      <span className="font-13 text-muted mr-2">
                        {t("GroupListScreen.sorting")}
                      </span>
                      <div
                        className="btn-group btn-group-sm"
                        role="group"
                        aria-label="First group"
                      >
                        <button
                          type="button"
                          onClick={() => this.changeSortType(1)}
                          className={
                            sortType == 1
                              ? "btn btn-dark"
                              : "btn btn-outline-secondary"
                          }
                        >
                          {t("GroupListScreen.a-z")}
                        </button>
                        <button
                          type="button"
                          onClick={() => this.changeSortType(2)}
                          className={
                            sortType == 2
                              ? "btn btn-dark"
                              : "btn btn-outline-secondary"
                          }
                        >
                          {t("GroupListScreen.orderOfPriority")}
                        </button>
                        <button
                          type="button"
                          onClick={() => this.changeSortType(3)}
                          className={
                            sortType == 3
                              ? "btn btn-dark"
                              : "btn btn-outline-secondary"
                          }
                        >
                          {t("GroupListScreen.crisisContacts")}
                        </button>
                      </div>
                    </div>
                  </div>
               
                  <ul className="list-inline grid-view-list m-0 mb-2">
                      <li className={displayType== 0 ?"list-inline-item mr-2 active":"list-inline-item mr-2"}>
                        <a
                          className="pointer"
                          href="javascript;:"
                          onClick={(e) => {
                            e.preventDefault();
                            saveDatatoLocalStorage("groupDisplayType", 0);
                            this.setState({ displayType: 0 });
                          }}
                        >
                          <i className="fa fa-list fa-lg text-muted"></i>
                        </a>
                      </li>
                      <li className={displayType== 1 ?"list-inline-item active":"list-inline-item"}>
                        <a
                          className="pointer "
                          href="javascript;:"
                          onClick={(e) => {
                            e.preventDefault();
                            saveDatatoLocalStorage("groupDisplayType", 1);
                            this.setState({ displayType: 1 });
                          }}
                        >
                          <i className="fa fa-th-large fa-lg text-muted"></i>
                        </a>
                      </li>
                    </ul>
              
                </div>

</div>
             }
                {/* {searchGroupList.length != 0 || searchPersonList != 0 ? (
                  <SearchGroupList
                    group_list={this.state.searchGroupList}
                    person_list={this.state.searchPersonList}
                  />
                ) : ( */}
                  
                 {/* )} */}

                 {searchDisplayType ==0 && <InfiniteScroll
                    pageStart={end}
                    loadMore={this.loadItem}
                    hasMore={this.props.groupList.hasMore}
                    useWindow={false}
                    loader={this.loader}
                  >
                    {displayType==0?(<>  <div className="form-row px-4">
                      {this.props.groupList &&
                        this.props.groupList.groupList.map((data, index) => {
                          return (
                            <GroupItemListView data={data} key={index} index={index} />
                          );
                        })}
                    </div>
                      {this.props.groupList &&
                        this.props.groupList.length == 0 && (
                          <NoRecordFound title="Group List" name="group list" />
                        )}</>):(<>  <div className="form-row m-2">
                      {this.props.groupList &&
                        this.props.groupList.groupList.map((data, index) => {
                          return (
                            <GroupItemGridView data={data} key={index} index={index} />
                          );
                        })}
                    </div>
                      {this.props.groupList &&
                        this.props.groupList.length == 0 && (
                          <NoRecordFound title="Group List" name="group list" />
                        )}</>)}
                  
                  </InfiniteScroll>}
                 
              

               
{searchDisplayType == 1 && (
                 <SearchPersonResult 
                      suggetionList={this.state.searchPersonList}
                      handlePersonListBaseonCompany={(personList)=>{
                        this.handlePersonListBaseonCompany(personList)
                      }}
                      setGroupFromPerson={(personList,selectedPersonId)=>{this.setGroupFromPerson(personList,selectedPersonId)}}/>
                )}

            {searchDisplayType == 2 && (
                  <div className="container my-2">
                    {groupListBaseOnPerson.map((data, index) => {
                      return <SearchGroupListBaseOnPerson data={data} key={index} 
                      selectedPersonId={selectedPersonId}
                      handleGroupWiseResponse={(personListBaseOnGroupID,personResponse,personListBreadCumb,groupIdBaseOnPersonDetail)=>{this.handleGroupWiseResponse(personListBaseOnGroupID,personResponse,personListBreadCumb,groupIdBaseOnPersonDetail)}}/>;
                    })}
                  </div>
                )}

              {searchDisplayType == 3 && (
                  <div className="container my-2">
                       <nav aria-label="breadcrumb">
                        <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item">
                            {t("mailDetail.home")}
                          </li>
                          <li
                              className="breadcrumb-item pointer"
                              aria-current="page"
                              onClick={()=>{
                                // const mailDetailId = breadcrumb[0]?.id
                                // this.props.history.push("/mail-detail/" + mailDetailId);
                                }}
                            >
                             {personListBreadCumb[0]?.name}
                            </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                             {personListBreadCumb[1]?.name}
                         
                          </li>
                        </ol>
                      </nav>

                    {personListBaseOnGroupID.map((data, index) => {
                      return <SearchPersonBaseOnGroup 
                      handleSearchDisplayType={(searchDisplayType) => {
                        this.handleSearchDisplayType(searchDisplayType);
                      }}
                      groupIdBaseOnPersonDetail={groupIdBaseOnPersonDetail}
                      data={data} key={index} 
                      personResponse={this.state.personResponse}/>;
                    })}
                  </div>
                )}


{searchDisplayType == 4 && (
                  <div className="container my-2">
                    {personListBaseOnGroupID.map((data, index) => {
                      return (
                        <SearchPersonBaseonCompany
                          handleSearchDisplayType={(searchDisplayType) => {
                            this.handleSearchDisplayType(searchDisplayType);
                          }}
                          data={data}
                          key={index}
                          personResponse={this.state.personResponse}
                          groupIdBaseOnPersonDetail={groupIdBaseOnPersonDetail}
                        />
                      );
                    })}
                  </div>
                )}

                <GroupSetteliteSideMenu
                  closeSlider={this.closeSlider}
                  headerMenuStatus={this.state.headerMenuStatus}
                  changeStatus={this.props.changeStatus}
                />
                <FabIcon color="rounded-blue" openSlider={this.openSlider} />
              </div>
              <Footer type="3" />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.groupListStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupListDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(GroupList)));
