import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { adminOptionList } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
import Footer from "../../Footer/FooterMobile";
import LoadingPage from "../../CommanComponents/LoadingPage";
import LogoutMenu from "../../CommanComponents/LogoutMenu";
import TopHeader from "../../CommanComponents/TopHeader";

class AdminstrationMenuMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: {},
    };
  }

  componentDidMount() {
    apiCall(
      METHOD.QUERY,
      adminOptionList(getUserID()),
      (data) => {
        this.setState({
          data,
        });
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };
  loader = (<LoadingPage key={0} />);
  render() {
    const { isLoading, data } = this.state;
    const {
      system_email_count,
      snippet_count,
      default_snippet_count,
      person_data_type_count,
      channel_count,
      location_count,
      user_count,
      side_menu_count,
      report_type_count,
      channel_type_count,
      group_type_count,
      event_type_count,
      list_type_count,
    } = data;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-green sab-header1 sticky-top d-flex flex-column">
                <div className="container d-flex flex-column flex-fill">
                  <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                    <TopHeader />
                    <p className="mb-0 ml-auto text-light">
                      <Link to="/" className="text-light">
                        {t("administration.title")}
                      </Link>
                    </p>
                    <LogoutMenu />
                  </div>
                </div>
              </div>
              <div className="middle-section sab-middle-section1 ">
                <div className="middle-section sab-middle-section1 left-line left-line-custom remove-scroll">
                  {isLoading && this.loader}
                  <div className="container my-4 z-index-99">
                    <Link to="/manage-menu" className="mb-4 d-block">
                      <div className="rounded-40 admin-bg float-left ml-5p">
                        <span className="text-light"> </span>
                      </div>
                      <div className="pl-3 pt-1 d-flex">
                        <div className="w-100">
                          <h6 className="m-0">
                            {t("administration.manageMenuList")}
                          </h6>
                          <p className="text-muted font-12 mb-2">
                            {side_menu_count && side_menu_count}
                            {t("administration.MenuItemCount")}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="container my-4 z-index-99">
                    <Link to="/manage-emails" className="mb-4 d-block">
                      <div className="rounded-40 admin-bg float-left ml-5p">
                        <span className="text-light"> </span>
                      </div>
                      <div className="pl-3 pt-1 d-flex">
                        <div className="w-100">
                          <h6 className="m-0">
                            {t("administration.manageEmailTitle")}
                          </h6>
                          <p className="text-muted font-12 mb-2">
                            {system_email_count && system_email_count}
                            {t("administration.emailCount")}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="container my-4 z-index-99">
                    <Link to="/manage-location" className="mb-4 d-block">
                      <div className="rounded-40 admin-bg float-left ml-5p">
                        <span className="text-light"> </span>
                      </div>
                      <div className="pl-3 pt-1 d-flex">
                        <div className="w-100">
                          <h6 className="m-0">
                            {t("administration.manageLocationList")}
                          </h6>
                          <p className="text-muted font-12 mb-2">
                            {location_count && location_count}
                            {t("administration.locationCount")}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="container my-4 z-index-99 ">
                    <Link to="/manage-user" className="mb-4 d-block">
                      <div className="rounded-40 admin-bg float-left ml-5p">
                        <span className="text-light"> </span>
                      </div>
                      <div className="pl-3 pt-1 d-flex">
                        <div className="w-100">
                          <h6 className="m-0">
                            {t("administration.manageUserTitle")}
                          </h6>
                          <p className="text-muted font-12 mb-2">
                            {user_count && user_count}
                            {t("administration.userCount")}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="container my-4 z-index-99 last">
                    <Link to="/channel-list" className="mb-4 d-block ">
                      <div className="rounded-40 admin-bg float-left ml-5p">
                        <span className="text-light"> </span>
                      </div>
                      <div className="pl-3 pt-1 d-flex">
                        <div className="w-100">
                          <h6 className="m-0">
                            {t("administration.manageChannelTitle")}
                          </h6>
                          <p className="text-muted font-12 mb-2">
                            {channel_count && channel_count}
                            {t("administration.ChannelCount")}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <hr
                  style={{
                    borderTop: "1px solid",
                    borderColor: "#D3D3D3",
                    marginTop: "0px",
                  }}
                />

                <div className="middle-section sab-middle-section1 left-line left-line-custom">
                  <div className="container my-4 z-index-99">
                    <Link to="/manage-eventlist-type" className="mb-4 d-block">
                      <div className="rounded-40 admin-bg float-left ml-5p">
                        <span className="text-light"> </span>
                      </div>
                      <div className="pl-3 pt-1 d-flex">
                        <div className="w-100">
                          <h6 className="m-0">
                            {t("administration.manageEventistTypeTitle")}
                          </h6>
                          <p className="text-muted font-12 mb-2">
                            {event_type_count && event_type_count}
                            {t("administration.EventtypeCount")}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="container my-4 z-index-99">
                    <Link to="/manage-report-type" className="mb-4 d-block">
                      <div className="rounded-40 admin-bg float-left ml-5p">
                        <span className="text-light"> </span>
                      </div>
                      <div className="pl-3 pt-1 d-flex">
                        <div className="w-100">
                          <h6 className="m-0">
                            {t("administration.manageReportTypeTitle")}
                          </h6>
                          <p className="text-muted font-12 mb-2">
                            {report_type_count && report_type_count}
                            {t("administration.reportTypeCount")}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="container my-4 z-index-99">
                    <Link to="/manage-channel-type" className="mb-4 d-block">
                      <div className="rounded-40 admin-bg float-left ml-5p">
                        <span className="text-light"> </span>
                      </div>
                      <div className="pl-3 pt-1 d-flex">
                        <div className="w-100">
                          <h6 className="m-0">
                            {t("administration.manageChannelTypeTitle")}
                          </h6>
                          <p className="text-muted font-12 mb-2">
                            {channel_type_count && channel_type_count}
                            {t("administration.ChannelTypeCount")}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="container my-4 z-index-99">
                    <Link to="/manage-snippet" className="mb-4 d-block">
                      <div className="rounded-40 admin-bg float-left ml-5p">
                        <span className="text-light"> </span>
                      </div>
                      <div className="pl-3 pt-1 d-flex">
                        <div className="w-100">
                          <h6 className="m-0">
                            {t("administration.manageSnippetTitle")}
                          </h6>
                          <p className="text-muted font-12 mb-2">
                            {snippet_count && snippet_count}{" "}
                            {t("administration.snippetCount")}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="container my-4 z-index-99">
                    <Link to="/manage-default-snippet" className="mb-4 d-block">
                      <div className="rounded-40 admin-bg float-left ml-5p">
                        <span className="text-light"> </span>
                      </div>
                      <div className="pl-3 pt-1 d-flex">
                        <div className="w-100">
                          <h6 className="m-0">
                            {t("administration.manageDefaultSnippetTitle")}
                          </h6>
                          <p className="text-muted font-12 mb-2">
                            {default_snippet_count && default_snippet_count}
                            {t("administration.defaultSnippetCount")}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <hr
                  style={{
                    borderTop: "1px solid",
                    borderColor: "#D3D3D3",
                    marginTop: "0px",
                  }}
                />
                <div className="middle-section sab-middle-section1 left-line left-line-custom">
                  <div className="container my-4 z-index-99 ">
                    <Link to="/manage-person-datatype" className="mb-4 d-block">
                      <div className="rounded-40 admin-bg float-left ml-5p">
                        <span className="text-light"> </span>
                      </div>
                      <div className="pl-3 pt-1 d-flex">
                        <div className="w-100">
                          <h6 className="m-0">
                            {t("administration.managePersonDatatypeList")}
                          </h6>
                          <p className="text-muted font-12 mb-2">
                            {person_data_type_count && person_data_type_count}
                            {t("administration.PersonDataTypeCount")}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="container my-4 z-index-99">
                    <Link to="/manage-group-type" className="mb-4 d-block">
                      <div className="rounded-40 admin-bg float-left ml-5p">
                        <span className="text-light"> </span>
                      </div>
                      <div className="pl-3 pt-1 d-flex">
                        <div className="w-100">
                          <h6 className="m-0">
                            {t("administration.manageGroupTypeTitle")}
                          </h6>
                          <p className="text-muted font-12 mb-2">
                            {group_type_count && group_type_count}
                            {t("administration.groupypeCount")}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <hr
                  style={{
                    borderTop: "1px solid",
                    borderColor: "#D3D3D3",
                    marginTop: "0px",
                  }}
                />

                <div className="middle-section sab-middle-section1 left-line left-line-custom">
                  <div className="container my-4 z-index-99 ">
                    <Link to="/manage-list-type" className="mb-4 d-block">
                      <div className="rounded-40 admin-bg float-left ml-5p">
                        <span className="text-light"> </span>
                      </div>
                      <div className="pl-3 pt-1 d-flex">
                        <div className="w-100">
                          <h6 className="m-0">
                            {t("administration.manageListTypeTitle")}
                          </h6>
                          <p className="text-muted font-12 mb-2">
                            {list_type_count && list_type_count}
                            {t("administration.ListtypeCount")}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="container my-4 z-index-99 ">
                    <Link to="/setup-system" className="mb-4 d-block">
                      <div className="rounded-40 admin-bg float-left ml-5p">
                        <span className="text-light"> </span>
                      </div>
                      <div className="pl-3 pt-1 d-flex">
                        <div className="w-100">
                          <h6 className="m-0">
                            {t("administration.setupSystem")}
                          </h6>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <Footer />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(AdminstrationMenuMobile);
