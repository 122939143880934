/* eslint eqeqeq: 0 */
/* eslint eqeqeq: 0 */

//Editor Import
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import {
  createTypeStrategy,
  DraftJS,
  editorStateFromRaw,
  MegadraftEditor,
} from "megadraft";
import "megadraft/dist/css/megadraft.css";
// this is the default LinkInput that handles `LINK`-entities:
import LinkInput from "megadraft/lib/entity_inputs/LinkInput";
import icons from "megadraft/lib/icons";
//Editor Import
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  refernceSnippetList,
  SnippetDataQuery,
  updateAminCreateSnippet,
} from "../../../../constants/apiParams";
import apiCall, { METHOD } from "../../../../constants/baseUrl";
import * as actions from "../../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../../constants/mapStateToProps";
import { getUserID } from "../../../../util/utils";
import Footer from "../../../Footer/FooterMobile";
import LoadingPage from "../../../CommanComponents/LoadingPage";
import ConditionalDialogue from "./EditorBaseOnType/ConditionalDialogue";
import DateTimeType from "./EditorBaseOnType/DateTimeType";
import NumberType from "./EditorBaseOnType/NumberType";
import SelectionType from "./EditorBaseOnType/SelectionType";
import TextType from "./EditorBaseOnType/TextType";
import CommonLoader from "../../../CommanComponents/CommonLoader";

/**
 * All Editor Related method
 */

/*
 * This component represents the reference in the editor. In this case it is a span
 * */
const SnippetReferenceComponent = ({ entityKey, children, contentState }) => {
  const { url } = contentState.getEntity(entityKey).getData();

  return (
    <span
      className="editor__link"
      href={url}
      title={url}
      style={{ backgroundColor: "red" }}
    >
      {children}
    </span>
  );
};

/*
 * This is the dialog to select the reference to assign to the selected text
 * The list should be loaded dynamically
 * */
class ReferenceInput extends React.Component {
  constructor(props) {
    super(props);
    // load pages from somewhere
    this.pages = [
      { type: "text", title: "Text", id: "1" },
      { type: "selection", title: "Selection", id: "2" },
      { type: "number", title: "Number", id: "42" },
      // ...
    ];
    this.state = {
      snippet_list: [],
    };
  }

  componentDidMount() {
    apiCall(
      METHOD.QUERY,
      refernceSnippetList(getUserID(), 0, 100, 0),
      (data) => this.handleSnippetResponse(data),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  handleSnippetResponse = (data) => {
    this.setState((prevState) => {
      return {
        snippet_list: [...prevState.snippet_list, ...data.snippet_list],
      };
    });
  };

  onPageChange = (event) => {
    const index = event.target.value;
    let item = this.state.snippet_list[index];
    const master_id = new Date().getTime() + Math.random();

    item = { ...item, master_id: master_id };
    this.props.setEntity(item);
  };
  render() {
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <select className="toolbar__input" onChange={this.onPageChange}>
              <option className="white-text-color">
                {t("GeneralMessages.snippetSelectReference")}
              </option>

              {this.state.snippet_list.map(
                ({ snippet_type_name, name, id }, index) => (
                  <option
                    className=" reference-text-color"
                    key={index}
                    value={index}
                  >
                    {name}
                  </option>
                )
              )}
            </select>
          );
        }}
      </Translation>
    );
  }
}

const entityInputs = {
  LINK: LinkInput,
  INTERNAL_PAGE_LINK: ReferenceInput,
};

const myDecorator = new DraftJS.CompositeDecorator([
  {
    strategy: createTypeStrategy("INTERNAL_PAGE_LINK"),
    component: SnippetReferenceComponent,
  },
]);

const styleMap = {
  INTERNAL_PAGE_LINK: {
    textDecoration: "line-through",
  },
};

const editor_actions = [
  { type: "inline", label: "B", style: "BOLD", icon: icons.BoldIcon },
  { type: "inline", label: "I", style: "ITALIC", icon: icons.ItalicIcon },
  // these actions correspond with the entityInputs above
  {
    type: "entity",
    label: "Page Link",
    style: "link",
    entity: "INTERNAL_PAGE_LINK",
    icon: icons.LinkIcon,
  },

  // { type: "separator" },
  // { type: "block", label: "UL", style: "unordered-list-item", icon: icons.ULIcon },
  // { type: "block", label: "OL", style: "ordered-list-item", icon: icons.OLIcon },
  // { type: "block", label: "H2", style: "header-two", icon: icons.H2Icon },
  // { type: "block", label: "QT", style: "blockquote", icon: icons.BlockQuoteIcon }
];

/*
 * Here we define the output for the server
 * */
const entityInputStyles = {
  LINK: (data) => {
    return {
      element: "a",
      attributes: {
        href: data.url,
      },
    };
  },
  INTERNAL_PAGE_LINK: (data) => {
    return {
      element: "reference",
      attributes: {
        id: data.id,
        placeholder: data.name,
        type: data.snippet_type_identifier,
      },
    };
  },
};

const draftToHtmlOptions = {
  blockRenderers: {},
  entityStyleFn(entity) {
    const entityType = entity.getType();
    const data = entity.getData();
    if (
      entityType in entityInputStyles &&
      typeof entityInputStyles[entityType] === "function"
    ) {
      return entityInputStyles[entityType](data);
    }
    return {};
  },
};

class EditAdminSnippet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snippet_type_Image: "EB",
      snippet_type_name: "Einberufung",
      snippet_type_identifier: "text",
      snippet_type_id: 0,
      reportDialogueStatus: false,
      snippetTitleName: "",

      //parameter of diffeernt snippet
      placeHolder: "",
      hide_on_zero: false,
      allow_zero: false,
      allow_custom_option: false,
      is_multiselect: false,
      concatinate: false,
      concatinate_value: "",
      use_date: false,
      use_time: false,
      default_date: new Date(),
      min_value: 0,
      max_value: 0,
      defualt_number: 0,

      dialogueStatus: false,
      isLoading: false,
      createSnippetLoader: false,
      option_list: [""],
      conditional_editor_content: [
        EditorState.createWithContent(stateFromHTML(""), myDecorator),
      ],
      conditional_editor_raw_value: [],
      /**
       * Editor State
       */
      content: "",
      refChildNodes: [],
      editorState: editorStateFromRaw(null),

      contentEditor: {},
    };
    this.child = React.createRef();
  }
  focus = () => {
    this.editor.focus();
  };

  componentDidMount() {
    var megadraft = document.querySelector("#megadraft-editor");
    megadraft.onclick = function () {
      this.focus();
    };

    const {
      id,
      name,
      snippet_type_identifier,
      snippet_type_id,
      snippet_type_name,
    } = this.props.item;

    apiCall(
      METHOD.QUERY,
      SnippetDataQuery(id,getUserID()),
      (data) => {
        let objContent;

        let contentState = convertFromRaw(
          JSON.parse(JSON.parse(data.raw_value))
        );
        this.setState({
          editorState: EditorState.createWithContent(contentState, myDecorator),
          snippet_type_Image: data.snippet_type_icon,
        });

        if (snippet_type_identifier != "base") {
          objContent = JSON.parse(data.content);
          this.mangeValuesBaseOnSnippetType(
            snippet_type_identifier,
            objContent
          );
        }
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
    this.setState({
      snippet_type_id: snippet_type_id,
      snippet_type_name: snippet_type_name,
      snippet_type_identifier: snippet_type_identifier,
      snippetTitleName: name,
    });
  }

  mangeValuesBaseOnSnippetType = (snippet_type_identifier, objSnippetProps) => {
    if (snippet_type_identifier == "text") {
      this.setState({
        placeHolder: objSnippetProps.placeholder,
        hide_on_zero: objSnippetProps.hide_on_zero,
      });
    } else if (snippet_type_identifier == "selection") {
      this.setState({
        hide_on_zero: objSnippetProps.hide_on_zero,
        allow_zero: objSnippetProps.allow_zero,
        allow_custom_option: objSnippetProps.allow_custom_option,
        is_multiselect: objSnippetProps.is_multiselect,
        concatinate: objSnippetProps.concatinate,
        concatinate_value: objSnippetProps.concatinate_value,
        option_list: objSnippetProps.options,
      });
    } else if (snippet_type_identifier == "date_time") {
      this.setState({
        hide_on_zero: objSnippetProps.hide_on_zero,
        use_date: objSnippetProps.set_date,
        use_time: objSnippetProps.set_time,
        default_date: objSnippetProps.default_date,
      });
    } else if (snippet_type_identifier == "number") {
      this.setState({
        hide_on_zero: objSnippetProps.hide_on_zero,
        min_value: objSnippetProps.min_value,
        max_value: objSnippetProps.max_value,
        defualt_number: objSnippetProps.default,
      });
    } else if (snippet_type_identifier == "condition") {
      let raw_value_array = objSnippetProps.raw_value;
      let conditional_editor_content = [];

      for (let index = 0; index < raw_value_array.length; index++) {
        const element = raw_value_array[index];
        const contentState = convertFromRaw(JSON.parse(element));
        conditional_editor_content.push(
          EditorState.createWithContent(contentState, myDecorator)
        );
      }

      this.setState(
        {
          hide_on_zero: objSnippetProps.hide_on_zero,
          option_list: objSnippetProps.option_list,
          conditional_editor_content,
        },
        () => {
          for (let index = 0; index < this.state.option_list.length; index++) {
            this.clickChild(index);
          }
        }
      );
    }
  };

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleReportDialogue = () => {
    this.setState((prevState) => {
      return {
        reportDialogueStatus: !prevState.reportDialogueStatus,
      };
    });
  };

  handleBrand = (image, name) => {
    this.setState(() => {
      return {
        status: false,
        brandType: name,
        brandImage: image,
      };
    });
  };

  handlePlaceHolderValue = (e) => {
    this.setState({
      placeHolder: e.target.value,
    });
  };

  handleSelectionInputtextValue = (e, type) => {
    if (type == "option") {
      this.setState({
        optionsValue: e.target.value,
      });
    } else {
      this.setState({
        concatinate_value: e.target.value,
      });
    }
  };

  handleNumberInputtextValue = (e, type) => {
    if (type == "min_value") {
      this.setState({
        min_value: e.target.value,
      });
    } else if (type == "defaultValue") {
      this.setState({
        defualt_number: e.target.value,
      });
    } else {
      this.setState({
        max_value: e.target.value,
      });
    }
  };

  handleSelectionCheckBox = (e, type) => {
    if (type == "hide_on_zero") {
      this.setState({ hide_on_zero: e.target.checked });
    } else if (type == "allow_zero") {
      this.setState({ allow_zero: e.target.checked });
    } else if (type == "allow_custom_option") {
      this.setState({ allow_custom_option: e.target.checked });
    } else if (type == "is_multiselect") {
      this.setState({ is_multiselect: e.target.checked });
    } else if (type == "concatinate") {
      this.setState({ concatinate: e.target.checked });
    }
  };
  handleHideOnZeroCheckBox = (e) => {
    this.setState({ hide_on_zero: e.target.checked });
  };

  handleChannelTextChange = (e) => {
    this.setState({
      snippetTitleName: e.target.value,
    });
  };

  handleDefaultDateChange = (date) => {
    this.setState({
      default_date: date,
    });
  };
  handleDateCheckBox = (e, type) => {
    if (type == "use_date") {
      this.setState({ use_date: e.target.checked });
    } else if (type == "use_time") {
      this.setState({ use_time: e.target.checked });
    } else if (type == "hide_on_zero") {
      this.setState({ hide_on_zero: e.target.checked });
    }
  };

  handleNumberCheckBox = (e, type) => {
    if (type == "hide_on_zero") {
      this.setState({ hide_on_zero: e.target.checked });
    }
  };

  validateSnippet = () => {
    const {
      snippetTitleName,
      placeHolder,
      hide_on_zero,
      allow_zero,
      allow_custom_option,
      is_multiselect,
      concatinate,
      option_list,
      concatinate_value,
      snippet_type_identifier,
      use_date,
      use_time,
      default_date,
      min_value,
      max_value,
      defualt_number,
      conditional_editor_content,
    } = this.state;

    let editorValue = stateToHTML(
      this.state.editorState.getCurrentContent(),
      draftToHtmlOptions
    );

    editorValue = editorValue.replace(/&gt;/g, ">");
    editorValue = editorValue.replace(/&lt;/g, "<");
    editorValue = editorValue.replace(/&quot;/g, '"');
    editorValue = editorValue.replace(/&apos;/g, "'");
    editorValue = editorValue.replace(/&amp;/g, "&");

    editorValue = editorValue.replace("<p>", "");
    editorValue = editorValue.replace("</p>", "");

    if (!snippetTitleName) {
      toast.info(this.props.t("AdminSetteliteSideMenu.snippetNameValidation"));
    } else {
      let contentObj = {};

      if (snippet_type_identifier == "text") {
        contentObj["content"] = editorValue;
        contentObj["placeholder"] = placeHolder;
        contentObj["hide_on_zero"] = hide_on_zero;
      } else if (snippet_type_identifier == "selection") {
        let final_option_list = option_list.filter((item) => item);
        contentObj["content"] = editorValue;
        contentObj["hide_on_zero"] = hide_on_zero;
        contentObj["allow_zero"] = allow_zero;
        contentObj["allow_custom_option"] = allow_custom_option;
        contentObj["is_multiselect"] = is_multiselect;
        contentObj["concatinate"] = concatinate;
        contentObj["options"] = final_option_list;
        contentObj["concatinate_value"] = concatinate_value;
        contentObj["placeholder "] = placeHolder;
      } else if (snippet_type_identifier == "date_time") {
        contentObj["content"] = editorValue;
        contentObj["hide_on_zero"] = hide_on_zero;
        contentObj["set_date"] = use_date;
        contentObj["set_time"] = use_time;
        contentObj["default_date"] = default_date;
      } else if (snippet_type_identifier == "number") {
        contentObj["content"] = editorValue;
        contentObj["hide_on_zero"] = hide_on_zero;
        contentObj["min_value"] = parseInt(min_value ? min_value : 0);
        contentObj["max_value"] = parseInt(max_value ? max_value : 100);
        contentObj["default"] = parseInt(defualt_number ? defualt_number : 0);
      } else if (snippet_type_identifier == "condition") {
        let conditonal_object_value = {};

        for (let index = 0; index < option_list.length; index++) {
          let item = option_list[index];
          let editor_content = stateToHTML(
            conditional_editor_content[index].getCurrentContent(),
            draftToHtmlOptions
          );
          editor_content = editor_content.replace(/&gt;/g, ">");
          editor_content = editor_content.replace(/&lt;/g, "<");
          editor_content = editor_content.replace(/&quot;/g, '"');
          editor_content = editor_content.replace(/&apos;/g, "'");
          editor_content = editor_content.replace(/&amp;/g, "&");
          editor_content = editor_content.replace("<p>", "");
          editor_content = editor_content.replace("</p>", "");
          conditonal_object_value[item] = editor_content;
        }

        let rawContent = [];

        for (let i = 0; i < conditional_editor_content.length; i++) {
          let item = conditional_editor_content[i];

          const rawDraftContentStates = JSON.stringify(
            convertToRaw(item.getCurrentContent())
          );
          rawContent.push(rawDraftContentStates);
        }

        contentObj["content"] = editorValue;
        contentObj["raw_value"] = rawContent;
        contentObj["option_list"] = option_list;
        contentObj["options"] = JSON.stringify(
          JSON.stringify(conditonal_object_value)
        );
        contentObj["hide_on_zero"] = hide_on_zero;
      }
      this.apiCallCreateSnippet(contentObj, editorValue);
    }
  };

  apiCallCreateSnippet = (contentObj, editorValue) => {
    const {
      snippet_type_id,
      snippetTitleName,
      snippet_type_identifier,
      editorState,
    } = this.state;

    const rawDraftContentState = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );

    let result;
    if (snippet_type_identifier == "base") {
      result = JSON.stringify(editorValue);
    } else {
      result = JSON.stringify(JSON.stringify(contentObj));
    }
    const { id } = this.props.item;

    apiCall(
      METHOD.QUERY,
      updateAminCreateSnippet(
        getUserID(),
        id,
        snippet_type_id,
        snippetTitleName,
        result,
        JSON.stringify(JSON.stringify(rawDraftContentState))
      ),
      (data) => {
        this.handleCreateResponse(data);
      },
      (isLoading) => {
        this.setState({ createSnippetLoader: isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleCreateResponse = (data) => {
    toast.success(this.props.t(data.message));
    this.props.handleEditModal(true, {});
  };

  onChange = (editorState) => {
    this.setState({ editorState });
  };

  handleOptionText = (i) => (e) => {
    let option_list = [...this.state.option_list];
    option_list[i] = e.target.value;
    this.setState({
      option_list,
    });
  };
  handleOptionDelete = (i) => (e) => {
    e.preventDefault();
    let option_list = [
      ...this.state.option_list.slice(0, i),
      ...this.state.option_list.slice(i + 1),
    ];
    this.setState({
      option_list,
    });
  };
  addSelectionOption = (e) => {
    e.preventDefault();
    let option_list = this.state.option_list.concat([""]);
    this.setState({
      option_list,
    });
  };

  handleOnEditorStateChange = (i) => (editorState) => {
    let conditional_editor_content = [...this.state.conditional_editor_content];
    conditional_editor_content[i] = editorState;
    this.setState({
      conditional_editor_content,
    });
  };
  handleConditionalDynamicOption = (i) => (e) => {
    let option_list = [...this.state.option_list];

    option_list[i] = e.target.value;
    this.setState({
      option_list,
    });
  };
  handleDeleteConditionalOption = (i) => (e) => {
    e.preventDefault();
    let option_list = [
      ...this.state.option_list.slice(0, i),
      ...this.state.option_list.slice(i + 1),
    ];
    let conditional_editor_content = [
      ...this.state.conditional_editor_content.slice(0, i),
      ...this.state.conditional_editor_content.slice(i + 1),
    ];
    this.setState({
      option_list,
      conditional_editor_content,
    });
  };
  addConditionalDynamicOption = (e) => {
    e.preventDefault();
    let option_list = this.state.option_list.concat([""]);
    let conditional_editor_content = this.state.conditional_editor_content.concat(
      [EditorState.createWithContent(stateFromHTML(""), myDecorator)]
    );
    this.setState(
      {
        option_list,
        conditional_editor_content,
      },
      () => {
        this.clickChild(this.state.option_list.length - 1);
      }
    );
  };

  focus = () => {
    this.editor.focus();
  };

  render() {
    const {
      isLoading,
      snippet_type_Image,
      snippet_type_name,
      snippetTitleName,
      createSnippetLoader,
      snippet_type_identifier,
      hide_on_zero,
      allow_zero,
      allow_custom_option,
      concatinate_value,
      is_multiselect,
      concatinate,
      placeHolder,
      use_date,
      use_time,
      default_date,
      min_value,
      max_value,
      defualt_number,
      option_list,
      conditional_editor_content,
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="d-block">
              <div
                className="modal fade show"
                id="NewEvent"
                tabindex="-1"
                aria-labelledby="NewEventLabel"
                aria-modal="true"
                style={{ display: "block" }}
              >
                <div className="modal-dialog modal-lg 0modal-dialog-centered 0modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header border-0 d-block pt-4 pb-0">
                      <div className="d-flex align-items-center justify-content-between mb-4">
                        <button
                          type="button"
                          onClick={() => this.props.handleEditModal(false, {})}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                        </button>

                        {createSnippetLoader ? (
                          <div className="btn btn-primary-theme pointer">
                            {t("AdminAddUser.save")}
                            <div className="spinner-border spinner-border-sm ml-1 text-blue">
                              <span className="sr-only">
                                {t("GeneralMessages.loading")}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="btn btn-primary-theme pointer"
                            onClick={this.validateSnippet}
                          >
                            {t("AdminAddUser.save")}
                          </div>
                        )}
                      </div>
                      <h5 className="modal-title">
                        {t("AdminSetteliteSideMenu.EditSnippet")}
                      </h5>
                    </div>
                    <div className="modal-body">
                      {isLoading && <CommonLoader />}

                      <div className="middle-section sab-middle-section1">
                        {isLoading && <CommonLoader />}

                        <div className="container my-4 z-index-99">
                          <div className="form-group mb-5">
                            <div className="form-group max-170 m-auto text-center">
                              <a
                                href="javascript;:"
                                className="clearfix text-dark"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.handleReportDialogue();
                                }}
                              >
                                <label className="font-14">
                                  {t("AddNewChannel.type")}
                                </label>
                                <div className="icon-drop-bg pointer">
                                  <div className="rounded-circle">
                                    <img
                                      src={`data:image/png;base64,${
                                        snippet_type_Image && snippet_type_Image
                                      }`}
                                      alt="ico"
                                      style={{width:'32px',height:'32px'}}
                                    />
                                  </div>

                                  <span className="font-12">
                                    {snippet_type_name && snippet_type_name}
                                  </span>
                                </div>
                              </a>
                            </div>

                            <div className="form-group">
                              <label className="font-14">
                                {t("AddNewChannel.title")}
                              </label>

                              <input
                                type="text"
                                className="form-control"
                                onChange={this.handleChannelTextChange}
                                name="title"
                                value={snippetTitleName}
                              />
                            </div>

                            <div className="pl-3 py-3 flex-fill border rounded megadraft-box" id="divMegadraft">

                              <div className="pl-3 flex-fill">
                                <div className="w-100">
                                  <MegadraftEditor
                                    editorState={this.state.editorState}
                                    customStyleMap={styleMap}
                                    onChange={this.onChange}
                                    actions={editor_actions}
                                    placeholder={t("AddNewChannel.placeholder")}
                                    entityInputs={entityInputs}
                                    ref={(element) => {
                                      this.editor = element;
                                    }}
                                    onFocus={()=>{
                                      document.getElementById("divMegadraft").classList.add('megadraftOnSelect')
                                    }}
          
                                    onBlur={()=>{
                                      document.getElementById("divMegadraft").classList.remove('megadraftOnSelect')
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            {snippet_type_identifier == "text" && (
                              <TextType
                                placeHolder={placeHolder}
                                hide_on_zero={hide_on_zero}
                                handleHideOnZeroCheckBox={
                                  this.handleHideOnZeroCheckBox
                                }
                                handlePlaceHolderValue={
                                  this.handlePlaceHolderValue
                                }
                              />
                            )}
                            {snippet_type_identifier == "selection" && (
                              <SelectionType
                                placeHolder={placeHolder}
                                hide_on_zero={hide_on_zero}
                                allow_zero={allow_zero}
                                option_list={option_list}
                                handleOptionText={this.handleOptionText}
                                handleOptionDelete={this.handleOptionDelete}
                                addSelectionOption={this.addSelectionOption}
                                allow_custom_option={allow_custom_option}
                                is_multiselect={is_multiselect}
                                concatinate={concatinate}
                                concatinate_value={concatinate_value}
                                handleSelectionCheckBox={
                                  this.handleSelectionCheckBox
                                }
                                handleSelectionInputtextValue={
                                  this.handleSelectionInputtextValue
                                }
                                handlePlaceHolderValue={
                                  this.handlePlaceHolderValue
                                }
                              />
                            )}
                            {snippet_type_identifier == "condition" && (
                              <ConditionalDialogue
                                placeHolder={placeHolder}
                                hide_on_zero={hide_on_zero}
                                option_list={option_list}
                                conditional_editor_content={
                                  conditional_editor_content
                                }
                                handleConditionalDynamicOption={
                                  this.handleConditionalDynamicOption
                                }
                                handleDeleteConditionalOption={
                                  this.handleDeleteConditionalOption
                                }
                                addConditionalDynamicOption={
                                  this.addConditionalDynamicOption
                                }
                                handleOnEditorStateChange={
                                  this.handleOnEditorStateChange
                                }
                                handleSelectionCheckBox={
                                  this.handleSelectionCheckBox
                                }
                                setClick={(click) => (this.clickChild = click)}
                              />
                            )}
                            {snippet_type_identifier == "date_time" && (
                              <DateTimeType
                                handleDefaultDateChange={
                                  this.handleDefaultDateChange
                                }
                                handleDateCheckBox={this.handleDateCheckBox}
                                use_date={use_date}
                                use_time={use_time}
                                hide_on_zero={hide_on_zero}
                                default_date={default_date}
                              />
                            )}

                            {snippet_type_identifier == "number" && (
                              <NumberType
                                handleNumberInputtextValue={
                                  this.handleNumberInputtextValue
                                }
                                handleNumberCheckBox={this.handleNumberCheckBox}
                                min_value={min_value}
                                max_value={max_value}
                                defualt_number={defualt_number}
                                hide_on_zero={hide_on_zero}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop fade show"></div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.channelListStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.channelListDispatchToProps,
  ])
)(withRouter(withTranslation()(EditAdminSnippet)));
