/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getFieldTypeEditPerson,
  getPersonField,
  updatePerson,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";
import FieldTypeList from "../../GroupDetail/GroupDetailDesktop/FieldTypeList";

class EditPerson extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: false,
      isLoading: false,
      fieldList: [],
      fieldDialogStatus: false,
      formField: [],
      first_name: "",
      last_name: "",
      title: "",
      person_id: 0,
      contact_email_business: "",
      sendRegistrationEmail: false,
    };
  }

  updateValueinForm = (item) => {
    const { formField } = this.state;

    const index = formField.findIndex((field) => field.id == item.id);
    formField[index] = {
      ...formField[index],
      ...{ item },
    };

    this.setState({
      formField,
    });
  };

  handleAfterCreateApiCall = (data) => {
    toast.success(this.props.t(data.message));
    this.props.changeRouteStatus(0);
  };

  handleAfterFieldTypeApiCall = (data) => {
    const { person_id } = this.state;
    const personDataTypeList = data.person_data_type_list;

    for (let i = 0; i < personDataTypeList.length; i++) {
      let item = personDataTypeList[i];
      if (item.is_default_custom == 1) {
        item["value"] = "";
        item["is_default_custom"] = 0;
        item["is_default_field"] = 0;
      } else {
        item["value"] = "";
        item["is_default_custom"] = 0;
      }
    }

    this.setState(
      () => {
        return {
          ...this.state,
          fieldList: data.person_data_type_list,
          formField: data.person_data_type_list,
        };
      },
      () => {
        apiCall(
          METHOD.QUERY,
          getFieldTypeEditPerson(getUserID(), person_id),
          (data) => this.updateFieldTypeAfterApiCall(data),
          (isLoading) => this.handleLoader(isLoading),
          (data) => {
            toast.error(this.props.t(data.message));
          }
        );
      }
    );
  };

  updateFieldTypeAfterApiCall = (data) => {
    const {
      person_data,
      first_name,
      name,
      title,
      contact_email_business,
    } = data;
    const { formField } = this.state;

    let tempFormField = formField;

    person_data &&
      person_data.map((item, index) => {
        tempFormField.map((item1, index1) => {
          return (
            item.id == item1.id && [
              ...tempFormField,
              (tempFormField[index1] = {
                ...item1,
                value: item.value,
                is_default_field: 1,
              }),
            ]
          );
        });
      });
      console.log("tempFormField",tempFormField);

    this.setState({
      formField: tempFormField,
      first_name,
      last_name: name,
      title,
      contact_email_business,
    });
  };
  handleFieldDialogStatus = (fieldDialogStatus) => {
    this.setState({
      fieldDialogStatus,
    });
  };

  handleChangeText = (e, item) => {
    let value;
    if (item.field_type == "checkbox") {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }
    item["value"] = value;
    const { formField } = this.state;
    const index = formField.findIndex((field) => field.id == item.id);
    formField[index] = {
      ...formField[index],
      ...{ item },
    };

    this.setState({
      formField,
    });
  };

  handleStaticField = (e, type) => {
    if (type == "firstName") {
      this.setState({
        first_name: e.target.value,
      });
    } else if (type == "lastName") {
      this.setState({
        last_name: e.target.value,
      });
    } else if (type == "title") {
      this.setState({
        title: e.target.value,
      });
    } else if (type == "businessEmail") {
      this.setState({
        contact_email_business: e.target.value,
      });
    }
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };
  handleAfterCreatePerson = (data) => {
    const { message } = data;
    toast.success(this.props.t(message));
    this.props.getPersonDetail(data);
    this.props.handleEditPersonModal(false);
  };

  handleSubmit = (values) => {
    var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

    const {
      person_id,
      first_name,
      last_name,
      title,
      formField,
      contact_email_business,
      sendRegistrationEmail,
    } = this.state;

    let formFieldStatus = "",
      boolStringStatus = false;

    formField &&
      formField.map((item) => {
        if (
          item.is_default_field == 1 &&
          item.is_required == 1 &&
          !item.value &&
          !boolStringStatus
        ) {
          boolStringStatus = true;
          formFieldStatus = "Please enter " + item.name;
        }
      });

    if (!first_name) {
      toast.info(this.props.t("addNewPerson.firstNameValidation"));
    } else if (!last_name) {
      toast.info(this.props.t("addNewPerson.lastNameValidation"));
    } else if (!contact_email_business) {
      toast.info(this.props.t("addNewPerson.emailValidation"));
    } else if (!regexEmail.test(contact_email_business)) {
      toast.info(this.props.t("addNewPerson.validEmailValidation"));
    } else if (formFieldStatus) {
      toast.info(formFieldStatus);
    } else {
      let person_Data = [];

      formField &&
        formField.map((item) => {
          if (item.is_default_field == 1) {
            let objPerson = {
              id: item.id,
              value: item.value,
            };
            person_Data.push(objPerson);
          }
        });

        console.log("person_Data --> person_Data",person_Data);
      person_Data = JSON.stringify(JSON.stringify(person_Data));
      apiCall(
        METHOD.QUERY,
        updatePerson(
          getUserID(),
          person_id,
          first_name,
          last_name,
          title,
          contact_email_business,
          person_Data,
          sendRegistrationEmail ? 1 : 0
        ),
        (data) => this.handleAfterCreatePerson(data),
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  componentDidMount() {
    const group_person_id = this.props.match.params.group_person_id;
    const fields = group_person_id.split("-");
    const person_id = fields[1];
    this.setState({
      person_id,
      sendRegistrationEmail:
        this.props.groupPersonDetail.send_registration_mail == 1 ? true : false,
    });
    apiCall(
      METHOD.QUERY,
      getPersonField(),
      (data) => this.handleAfterFieldTypeApiCall(data),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  render() {
    const {
      fieldDialogStatus,
      isLoading,
      formField,
      fieldList,
      sendRegistrationEmail,
    } = this.state;
    const { changeRouteStatus } = this.props;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="d-block">
            <div
              className="modal fade show"
              id="NewEvent"
              tabindex="-1"
              aria-labelledby="NewEventLabel"
              aria-modal="true"
              style={{ display: "block" }}
            >
              <div className="modal-dialog modal-lg 0modal-dialog-centered 0modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header border-0 d-block pt-4 pb-0">
                    {/* <div className="d-flex 0align-items-center 0justify-content-between mb-4">
                    <div className="py-2 d-flex 0align-items-center 0justify-content-between">
                      <span
                        className="pointer"
                        onClick={() => this.props.handleEditPersonModal(false)}
                      >
                        <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                      </span>
                      {isLoading ? (
                        <div className="btn btn-primary-theme group pointer">
                          {t("addNewPerson.toSave")}
                          <div className="spinner-border spinner-border-sm ml-1 text-blue">
                            <span className="sr-only">
                              {" "}
                              {t("GeneralMessages.loading")}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="btn btn-primary-theme group pointer"
                          onClick={this.handleSubmit}
                        >
                          {t("addNewPerson.toSave")}
                        </div>
                      )}
                    </div>
                  
                    </div>
                    <h5 className="modal-title">
                    {t("addNewPerson.editPerson")}
                    </h5> */}


                    <div className="d-flex align-items-center justify-content-between mb-4">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => this.props.handleEditPersonModal(false)}
                        >
                          <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                        </button>
                        <h5 className="modal-title">
                    {t("addNewPerson.editPerson")}
                    </h5>
                        {isLoading ? (
                          <div
                            className="btn btn-primary-theme group pointer"
                            onClick={this.handleSubmit}
                          >
                          {t("addNewPerson.toSave")}
                            <div className="spinner-border spinner-border-sm ml-1 text-blue">
                              <span className="sr-only">
                                {t("GeneralMessages.loading")}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="btn btn-primary-theme group pointer"
                            onClick={this.handleSubmit}
                          >
                           {t("addNewPerson.toSave")}
                          </div>
                        )}
                      </div>

                  </div>
                 
                 
              
                  <div className="modal-body">
            <div className="wrapper position-relative">
                <div className="middle-section sab-middle-section1">
                  <div className="container my-4 00z-index-99">
                    <form className="login-form">
                      <div className="form-group">
                        <label className="font-14">
                          {" "}
                          {t("addNewPerson.firstName")}
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("addNewPerson.firstNamePlaceHolder")}
                          onChange={(e) =>
                            this.handleStaticField(e, "firstName")
                          }
                          value={this.state.first_name}
                        />
                      </div>

                      <div className="form-group">
                        <label className="font-14">
                          {t("addNewPerson.lastName")}
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("addNewPerson.lastNamePlaceHolder")}
                          onChange={(e) =>
                            this.handleStaticField(e, "lastName")
                          }
                          value={this.state.last_name}
                        />
                      </div>
                      <div className="form-group">
                        <label className="font-14">
                          {t("addNewPerson.title")}
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("addNewPerson.titlePlaceHolder")}
                          onChange={(e) => this.handleStaticField(e, "title")}
                          value={this.state.title}
                        />
                      </div>
                      <div className="form-group">
                        <label className="font-14">
                          {t("addNewPerson.businessEmail")}
                        </label>

                        <input
                          type="email"
                          className="form-control"
                          placeholder={t("addNewPerson.businessPlaceHolder")}
                          onChange={(e) =>
                            this.handleStaticField(e, "businessEmail")
                          }
                          value={this.state.contact_email_business}
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="checkbox"
                          id="sendRegistrationEmail"
                          className="mr-2"
                          checked={sendRegistrationEmail}
                          onChange={(e) =>
                            this.setState({
                              sendRegistrationEmail: e.target.checked,
                            })
                          }
                        />
                        <label htmlFor="sendRegistrationEmail">
                          {t("addNewPerson.sendRegistrationEmail")}
                        </label>
                      </div>
                      {formField &&
                        formField.map((item, index) => {
                          const {
                            id,
                            name,
                            description,
                            field_type,
                            is_default_field,
                            value,
                          } = item;
                          console.log("item after fetch",item);
                          return (
                            <React.Fragment key={index}>
                              {is_default_field == 1 && field_type == "text" && (
                                <div className="form-group">
                                  <label className="font-14">
                                    {name && name}
                                  </label>

                                  <input
                                    type="text"
                                    className="form-control"
                                    id={id}
                                    placeholder={description && description}
                                    onChange={(e) =>
                                      this.handleChangeText(e, item)
                                    }
                                    value={value}
                                  />
                                </div>
                              )}

{is_default_field == 1 && field_type == "html" && (
                                <div className="form-group">
                                  <label className="font-14">
                                    {name && name}
                                  </label>

                                  <input
                                    type="text"
                                    className="form-control"
                                    id={id}
                                    placeholder={description && description}
                                    onChange={(e) =>
                                      this.handleChangeText(e, item)
                                    }
                                    value={value}
                                  />
                                </div>
                              )}
                              {is_default_field == 1 && field_type == "email" && (
                                <div className="form-group">
                                  <label className="font-14">
                                    {name && name}
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id={id}
                                    placeholder={description && description}
                                    onChange={(e) =>
                                      this.handleChangeText(e, item)
                                    }
                                    value={value}
                                  />
                                </div>
                              )}
                              {is_default_field == 1 && field_type == "tel" && (
                                <div className="form-group">
                                  <label className="font-14">
                                    {name && name}
                                  </label>
                                  <input
                                    type="tel"
                                    className="form-control"
                                    id={id}
                                    placeholder={description && description}
                                    onChange={(e) =>
                                      this.handleChangeText(e, item)
                                    }
                                    text={value}
                                  />
                                </div>
                              )}

                              {is_default_field == 1 && field_type == "link" && (
                                <div className="form-group">
                                  <label className="font-14">
                                    {name && name}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id={id}
                                    placeholder={description && description}
                                    onChange={(e) =>
                                      this.handleChangeText(e, item)
                                    }
                                    text={value}
                                  />
                                </div>
                              )}
                              {is_default_field == 1 &&
                                field_type == "text_multiline" && (
                                  <div className="form-group">
                                    <label className="font-14">
                                      {name && name}
                                    </label>

                                    <textarea
                                      type="textarea"
                                      className="form-control"
                                      id={id}
                                      placeholder={description && description}
                                      onChange={(e) =>
                                        this.handleChangeText(e, item)
                                      }
                                      value={value}
                                    />
                                  </div>
                                )}

                              {is_default_field == 1 &&
                                field_type == "checkbox" && (
                                  <div className="form-group">
                                    <input
                                      type="checkbox"
                                      id="vehicle1"
                                      className="custom-checkbox"
                                      name={name && name}
                                      onClick={(e) =>
                                        this.handleChangeText(e, item)
                                      }
                                      checked={value}
                                    />
                                    <label className="font-14">
                                      {description && description}
                                    </label>
                                  </div>
                                )}
                            </React.Fragment>
                          );
                        })}
                    </form>
                    <div className="btn-fixed-bottom-custom clearfix z-index-99 mr-1 mb-1">
                      <button
                        className="navbar-toggler collapsed float-right"
                        onClick={() => this.handleFieldDialogStatus(true)}
                        type="button"
                        data-toggle="collapse"
                        data-target="#Brand-toggler"
                        aria-controls="Brand-toggler"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <div className={`rounded-40 rounded-blue float-right`}>
                          <i className="zmdi zmdi-plus zmdi-hc-fw font-22"></i>
                        </div>
                      </button>
                    </div>

                    {fieldDialogStatus && (
                      <FieldTypeList
                        handleFieldDialogStatus={this.handleFieldDialogStatus}
                        fieldList={fieldList}
                        addValue={this.updateValueinForm}
                      />
                    )}
                  </div>
                </div>
            </div>
            </div>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop fade show"></div>
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.eventListStateToProps,
        stateToProps.groupPersonDetailStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupDetailPersonListDispatchToProps,
    actions.groupPersonDetailDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withTranslation()(withRouter(EditPerson)));
