/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AddChannel from "./AddChannel";
import ChannelList from "./ChannelList";
import EditChannel from "./EditChannel";
import createHistory from "history/createBrowserHistory";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeStatus: 0,
      dataValue: {},
      channelType: "",
      isFromEdit: false,
      tempResponse: {},
    };
  }

  changeStatus = (type) => {
    const isFromtwitterConnect = this.props.location.state
      ?.isFromtwitterConnect;
    if (!isFromtwitterConnect) {
      this.setState({
        routeStatus: type,
      });
    } else {
      this.setState({
        routeStatus: type,
        tempResponse: {},
      });
    }
  };

  changeStatusWithStatusValue = (type, dataValue) => {
    this.setState({
      routeStatus: type,
      dataValue,
    });
  };
  componentDidMount() {
    const isFromtwitterConnect = this.props.location.state
      ?.isFromtwitterConnect;
    const isFromfacebookConnect = this.props.location.state
      ?.isFromFacebookConnect;
    if (isFromtwitterConnect && isFromtwitterConnect) {
      let tempResponse = localStorage.getItem("tempConnectResponse");
      tempResponse = JSON.parse(tempResponse);
      const comeFrom = tempResponse.comeFrom;
      console.log("tempResponse", tempResponse);
      localStorage.removeItem("tempConnectResponse");

      if (comeFrom == "createTwitter") {
        this.setState({
          routeStatus: 1,
          channelType: "twitter",
          tempResponse,
        });
      } else if (comeFrom == "editTwitter") {
        this.setState({
          routeStatus: 2,
          channelType: "twitter",
          tempResponse,
        });
      }
      const history = createHistory();
      if (
        history.location.state &&
        history.location.state.isFromtwitterConnect
      ) {
        let state = { ...history.location.state };
        delete state.isFromtwitterConnect;
        delete state.isFromEdit;
        history.replace({ ...history.location, state });
      }
    } else if (!isFromfacebookConnect) {
      this.setState({
        tempResponse: {},
      });
    }
    if (isFromfacebookConnect && isFromfacebookConnect) {
      let tempResponse = localStorage.getItem("tempConnectResponse");
      tempResponse = JSON.parse(tempResponse);
      const comeFrom = tempResponse?.comeFrom;
      localStorage.removeItem("tempConnectResponse");

      if (comeFrom && comeFrom == "createFacebook") {
        this.setState({
          routeStatus: 1,
          channelType: "facebook",
          tempResponse,
        });
      } else if (comeFrom && comeFrom == "editFacebook") {
        console.log("tempresponse in index", tempResponse);
        this.setState({
          routeStatus: 2,
          channelType: "facebook",
          tempResponse,
        });
      }
      const history = createHistory();
      if (
        history.location.state &&
        history.location.state.isFromtwitterConnect
      ) {
        let state = { ...history.location.state };
        delete state.isFromfacebookConnect;
        delete state.isFromEdit;
        history.replace({ ...history.location, state });
      }
    } else if (!isFromtwitterConnect) {
      this.setState({
        tempResponse: {},
      });
    }
  }
  render() {
    const { routeStatus, dataValue, tempResponse } = this.state;
    return (
      <div className="wrapper position-relative">
        {routeStatus == 0 && (
          <ChannelList
            changeStatusWithStatusValue={this.changeStatusWithStatusValue}
          />
        )}

        {routeStatus == 1 && (
          <AddChannel
            tempResponse={tempResponse}
            changeRouteStatus={this.changeStatus}
          />
        )}

        {routeStatus == 2 && (
          <EditChannel
            tempResponse={tempResponse}
            changeRouteStatus={this.changeStatus}
            item={dataValue}
          />
        )}
      </div>
    );
  }
}
export default withRouter(Index);
