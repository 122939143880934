/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  adminSnippetList,
  deleteSnippet,
  getSnippetType,
} from "../../../../constants/apiParams";
import apiCall, { METHOD } from "../../../../constants/baseUrl";
import { getUserID, removeDuplicates } from "../../../../util/utils";
import FabIcon from "../../../CommanComponents/FabIcon";
import Footer from "../../../Footer/FooterMobile";
import LoadingPage from "../../../CommanComponents/LoadingPage";
import LogoutMenu from "../../../CommanComponents/LogoutMenu";
import TopHeader from "../../../CommanComponents/TopHeader";
import AdminSetteliteSideMenu from "./AdminSetteliteSideMenu";
import HeaderDesktop from "../../../Header/HeaderDesktop";
import FooterDesktop from "../../../Footer/FooterDesktop";
import CommonLoader from "../../../CommanComponents/CommonLoader";
import AddAdminSnippet from "../SnippetManagementDesktop/AddAdminSnippet";
import EditAdminSnippet from "../SnippetManagementDesktop/EditAdminSnippet";
import AdminSideMenu from "../../AdminMenu/AdminSideMenu";

class SnippetList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      snippet_list: [],
      snippet_type_list: [],
      options: [],
      start: 0,
      end: 10,
      hasMore: true,
      deleteLoadingStatus: false,
      headerMenuStatus: false,
      snippet_type: 0,
      filterSnippetType: {},
      addModal: false,
      editModal: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    apiCall(
      METHOD.QUERY,
      getSnippetType(),
      (data) => {
        let options = [];
        data.snippet_type_list &&
          data.snippet_type_list.map((d) =>
            options.push({ value: d.id, label: d.name })
          );

        this.setState({
          snippet_type_list: data.snippet_type_list,
          options,
        });
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );

    this.loadSnippet();
  }

  loadSnippet = () => {
    const { start, end, hasMore, snippet_type } = this.state;
    if (hasMore) {
      apiCall(
        METHOD.QUERY,
        adminSnippetList(getUserID(), start, end, snippet_type),
        (data) => {
          this.handleSnippetResponse(data);
        },
        (isLoading) => {
          this.handleLoader(isLoading);
        },
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  handleFilterChange = (filterSnippetType) => {
    if (Object.keys(filterSnippetType).length == 0) {
      this.setState(
        {
          filterSnippetType: {},
          snippet_type: 0,
          hasMore: true,
          start: 0,
          snippet_list: [],
          isLoading: false,
        },
        () => {
          this.loadSnippet();
        }
      );
    } else {
      this.setState(
        {
          filterSnippetType,
          snippet_type: filterSnippetType.value,
          hasMore: true,
          start: 0,
          snippet_list: [],
          isLoading: false,
        },
        () => {
          this.loadSnippet();
        }
      );
    }
  };

  handleSnippetResponse = (data) => {
    const { end } = this.state;
    if (data.is_last == 1) {
      this.setState({ hasMore: false });
    }

    let old_snippet = this.state.snippet_list;
    old_snippet = old_snippet.concat(data.snippet_list);
    old_snippet = removeDuplicates(old_snippet, "id");

    this.setState((prevState) => {
      return {
        start: prevState.start + end,
        snippet_list: old_snippet,
        isLoading: false,
      };
    });
  };

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: true,
      };
    });
  };

  handleDelete = (item) => {
    const { id } = item;

    confirmAlert({
      title: this.props.t("administration.confirmTodelete"),
      message: this.props.t("administration.confirmTodeleteMessage"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              deleteSnippet(getUserID(), id),
              (data) => {
                let afterDeletedSnippetList = this.state.snippet_list.filter(
                  (snippet) => {
                    return snippet.id != item.id;
                  }
                );

                toast.success(this.props.t(data.message));
                this.setState({
                  snippet_list: afterDeletedSnippetList,
                });
              },
              (isLoading) => {
                this.setState({ deleteLoadingStatus: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        headerMenuStatus: false,
      };
    });
  };

  clearFilter = () => {
    this.setState(
      {
        filterSnippetType: {},
        snippet_type: 0,
        hasMore: true,
        start: 0,
        snippet_list: [],
        isLoading: false,
      },
      () => {
        this.loadSnippet();
      }
    );
  };
  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleAddModal = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          addModal: !prev.addModal,
        };
      },
      () => {
        if (this.state.addModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.setState(
              {
                hasMore: true,
                start: 0,
                end: 10,
                snippet_list: [],
              },
              () => {
                this.loadSnippet();
              }
            );
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  handleEditModal = (isFromSave, item) => {
    this.setState(
      (prev) => {
        return {
          editModal: !prev.editModal,
          editItemValue: item,
        };
      },
      () => {
        if (this.state.editModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.setState(
              {
                hasMore: true,
                start: 0,
                end: 10,
                snippet_list: [],
              },
              () => {
                this.loadSnippet();
              }
            );
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  loader = (<CommonLoader key={0} />);
  render() {
    const {
      hasMore,
      snippet_list,
      options,
      deleteLoadingStatus,
      filterSnippetType,
      isLoading,
      addModal,
      editModal,
    } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="content-wrapper">
              <HeaderDesktop selectionType={0} />
              <div className="content">
                <div className="section section-padding">
                  <div className="container">
                    <div className="section-container">
                      <div className="section-content">
                        <div className="row mt-2 card-min-ht-100p">
                          {isLoading && <CommonLoader />}
                          <AdminSideMenu activeIndex={8} />
                          <div className="col-lg-9">
                            <div className="section-title text-center d-md-flex align-items-start m-0">
                              <div className="flex-fill mr-n-1300">
                                <Link
                                  to="/administration"
                                  className="text-light"
                                >
                                  <h1 className="page-title h4">
                                    {t("administration.snippetListTitle")}
                                  </h1>
                                </Link>
                              </div>
                              <button
                                type="button"
                                className="btn btn-primary-theme btn-sm"
                                onClick={() => {
                                  this.handleAddModal();
                                }}
                              >
                                {" "}
                                {t("GeneralMessages.addNew")}
                              </button>
                            </div>

                            <div className="position-relative">
                              <Select
                                value={filterSnippetType}
                                onChange={(value) =>
                                  this.handleFilterChange(value)
                                }
                                options={options}
                                className="p-2 w-100 z-index-999"
                                clearable={false}
                                placeholder={t("administration.filterSnippet")}
                              />
                              {Object.keys(filterSnippetType).length != 0 && (
                                <span className="dropdown-close-icon">
                                  <i
                                    className="zmdi zmdi-close zmdi-hc-lg align-middle pointer"
                                    onClick={() => this.clearFilter()}
                                  />
                                </span>
                              )}
                            </div>

                            <div className="row mt-4 ">
                              {deleteLoadingStatus && this.loader}
                              {snippet_list &&
                                snippet_list.map((item, index) => {
                                  const { name, snippet_type_name } = item;
                                  return (
                                    <div className="col-lg-6 mb-4 " key={index}>
                                      <a
                                        href="javascript:;"
                                        className="card list-card"
                                      >
                                        <div className="card-body d-flex py-3 px-3 align-items-center">
                                          <div className="circle big m-0 mr-3"></div>
                                          <div className="clearfix mr-2">
                                            <h6 className="mb-1">{name}</h6>
                                            <small className="text-muted text-break">
                                              {snippet_type_name}
                                            </small>
                                          </div>
                                          <div className="ml-auto">
                                            <ul className="list-inline action-menu-list d-flex m-0">
                                              <li className="list-inline-item mr-3">
                                                <i
                                                  className="zmdi zmdi-edit zmdi-hc-lg pointer"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleEditModal(
                                                      false,
                                                      item
                                                    );
                                                  }}
                                                />
                                              </li>
                                              <li className="list-inline-item mr-3 ">
                                                <i
                                                  className="zmdi zmdi-delete zmdi-hc-lg pointer"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleDelete(item);
                                                  }}
                                                />
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  );
                                })}
                            </div>

                            {hasMore && (
                              <div className="text-center mt-2">
                                <button
                                  type="button"
                                  className="btn btn-primary-theme"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.loadSnippet();
                                  }}
                                >
                                  {t("HomeScreen.showMore")}
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {addModal && (
                      <AddAdminSnippet
                        handleAddModal={(isFromSave) => {
                          this.handleAddModal(isFromSave);
                        }}
                      />
                    )}
                    {editModal && (
                      <EditAdminSnippet
                        item={this.state.editItemValue}
                        handleEditModal={(isFromSave, item) => {
                          this.handleEditModal(isFromSave, item);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <FooterDesktop selectionType={0} />
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(SnippetList);
