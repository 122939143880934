import React from "react";

export default function SnippetType(props) {
                 const { handleBrandSelection, item } = props;
                 const { icon, name } = item;
                 return (
                     <li
                       className="nav-item pointer "
                       onClick={() => handleBrandSelection(item)}
                     >
                       <a
                         className="nav-link float-left"
                         href="javascript;:"
                         onClick={(e) => {
                           e.preventDefault();
                         }}
                       >
                         <img
                           src={`data:image/png;base64,${icon && icon}`}
                           alt="brand_icon"
                           className="mr-2"
                           style={{width:'28px',height:'28px'}}
                         />
                         {name && name}
                       </a>
                     </li>
                 );
               }
