/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import {
  getautosuggestperson,
  getmailinglistfromperson,
  MaillingListQuery,getlistsearchhistory, getallpersonbypersondata
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
import LoadingPage from "../../CommanComponents/LoadingPage";
import Autosuggest from "react-autosuggest";

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (suggestions, value) => {
  return suggestions;
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.name;

// Use your imagination to render suggestions.
// Use your imagination to render suggestions.
const renderSuggestion = (suggestion,translationMailList,translationPersonList,translationCompanyList,
  searchValue,translationReciever) => (
  <React.Fragment>
     {suggestion.type=='data' && <div className="bg-white shadow-sm p-3">
    {suggestion?.isFirst && suggestion.type=='data' && <h5 className="mt-1 mb-3 text-muted"><u>{translationMailList}</u></h5>}
    <ul className="list-unstyled autosuggest-list">
    <div className="col-lg-10  pointer" >
              <a
                href="#"
                className="card list-card"
              >
                <div className="card-body p-0">
                  <div className="media">
                    <div className="bg-yellow left-icon-box-medium d-flex align-items-center justify-content-center text-center">
                      <div className="circle big m-0">
                        <img
                          src={`data:image/png;base64,${
                            suggestion.type_icon || ''
                          }`}
                          alt="ico"
                          style={{ height: "20px", width: "20px" }}
                        />
                      </div>
                    </div>
                    <div className="clearfix p-3">
                      <h6 className="mb-1">{suggestion.name||''}</h6>
                      <p className="text-muted font-12 mb-0">

                    {suggestion.child_element}{" "}
                    {translationReciever}
                    </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <hr className="dotted"/>
 </ul>
  </div>}


    {suggestion.type=='person' && <div className="bg-white shadow-sm p-3">
    {suggestion?.isFirst && suggestion.type=='person' &&  searchValue.length!=0 &&<h5 className="mt-1 mb-3 text-muted"><u>{translationPersonList}</u></h5>}
    <ul className="list-unstyled autosuggest-list">
      <li className="border-bottom">
        <h5 className="font-15 mb-1">{suggestion.name}</h5>
        <p className="font-15 text-muted m-0">{suggestion.company && suggestion.company}  {suggestion.job_title && suggestion.job_title}</p>
        <hr className="dotted"/>
      </li>
    </ul>
  </div>}

  {suggestion.type=='company' && <div className="bg-white shadow-sm p-3 ">
    {suggestion?.isFirst && suggestion.type=='company' && <h5 className="mt-1 mb-3 text-muted"><u>{translationCompanyList}</u></h5>}
    <ul className="list-unstyled autosuggest-list">
      <li className=" pb-1 border-bottom">
        <h5 className="font-15 ">{suggestion.name}</h5>
        <hr className="dotted"/>

        {/* <p className="font-15 text-muted m-0">{suggestion.company && suggestion.company}  {suggestion.job_title && suggestion.job_title}</p> */}
      </li>
    </ul>
  </div>}
  </React.Fragment>
);
class SearchMail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      mailList: [],
      isLoading: false,
      suggestions: [],
      value: "",
    };
    this.searchRef = React.createRef();
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    if(value.length!=0){
      apiCall(
        METHOD.QUERY,
        getautosuggestperson(getUserID(),value,1,0),
        (data) => this.handleSearchResponse(data,value,false),
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }else{
      apiCall(
        METHOD.QUERY,
        getlistsearchhistory(getUserID(),1),
        (data) => this.handleSearchResponse(data,'',true),
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };
 
  shouldRenderSuggestions = () => {
    return true
  };

  handleGetMailPersonResponse = (data) => {
    console.log("data", data);
    // this.props.handleMailbaseOnPerson(data.mailing_list)
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  changeEventSearch = (e, { newValue }) => {
    const searchValue = newValue;
    this.setState(
      {
        search: searchValue,
        value: searchValue,
        suggestions: [],
      },
      () => {
        this.props.searchArray([]);
      }
    );
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

 


  handleSearchResponse = (data,value,isFromHistory) => {
    let suggetion =[]
    if(!isFromHistory){
    let personList = data.person_list
   let company_list = data.company_list
   let data_list = data.data_list
   suggetion=  this.prepareSuggetionArray(personList,company_list,data_list)
    }else{
      let personList = data.person_list
      suggetion=  this.prepareSuggetionArray(personList,[],[])
    }
 
    this.setState(
      () => {
        return {
          suggestions: getSuggestions(suggetion,value),
          isLoading: false
        };
      },
      () => {
        // this.props.searchArray(data.mailing_list);
        // if (this.state.mailList.length == 0) {
        //   toast.info(
        //    this.props.t("GeneralMessages.searchErrorMessage") +
        //      "'" +
        //      this.state.search +
        //      "'"
        //  );
        // }
      }
    );
  };
  prepareSuggetionArray=(personList,company_list,data_list)=>{
    let suggetion =[]

    for (let index = 0; index < data_list.length; index++) {
      let data = data_list[index];
      data.type = "data";
      data.isFirst = index == 0 ? true : false;
      suggetion.push(data);
    }
    for (let index = 0; index < personList.length; index++) {
      let person = personList[index];
      person.type = "person";
      person.isFirst = index == 0 ? true : false;
      suggetion.push(person);
    }
    
    for (let index = 0; index < company_list.length; index++) {
      let company = company_list[index];
      company.type = "company";
      company.isFirst = index == 0 ? true : false;
      suggetion.push(company);
    }

    console.log("suggetion",suggetion);
    return suggetion
  }


  loader = (<LoadingPage />);
  clearSearch = () => {
    this.searchRef.current.value = "";
    this.setState(
      () => {
        return {
          ...this.state,
          search: "",
          suggestions: [],
          value: "",
        };
      },
      () => {
        this.props.handleSearchDisplayType(0);
        this.props.searchArray([]);
      }
    );
  };

  getMailPersonList=(personId)=>{
    if(personId==0){
      //this.onClickSearch()
    }else{
    apiCall(
      METHOD.QUERY,
      getmailinglistfromperson(getUserID(),personId),
      (data) => this.handleGetMailPersonResponse(data,personId),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }
}

  handleGetMailPersonResponse = (data, personId) => {
    console.log("data", data.mailing_list);
    this.props.setmailFromPerson(data.mailing_list, personId);
    // this.props.handleMailbaseOnPerson(data.mailing_list)
  };

  searchApi = () => {
    const { search } = this.state;
    apiCall(
      METHOD.QUERY,
      MaillingListQuery(getUserID(), 0, 100, search),
      (data) => this.handleSearchResponse(data),
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  onClickSearch = ()=>{
    apiCall(
      METHOD.QUERY,
      getautosuggestperson(getUserID(),this.state.value,1,1),
      (data) => {
        let personList = data.person_list;
        let company_list = data.company_list;
        let data_list = data.data_list;
        if (personList.length == 0 && company_list.length==0 && data_list.length==0) {
          toast.info(this.props.t("GeneralMessages.searchErrorMessage") +
              "'" + this.state.value +"'");
        }else{
          let suggetion = this.prepareSuggetionArray(
            personList,
            company_list,
            data_list
          );
          this.props.searchArray(suggetion);
        }
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }
  
  handlePersonDataTypeValue=(data)=>{
    this.props.handlePersonListBaseonCompany(data.person_list)
  }

  getPersonFromCompany=(personDataType)=>{
    apiCall(
      METHOD.QUERY,
      getallpersonbypersondata(getUserID(),personDataType),
      (data) => this.handlePersonDataTypeValue(data),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  render() {
    const { search } = this.state;
    const { value, suggestions } = this.state;
    const inputProps = {
      value,
      onChange: this.changeEventSearch,
    };
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className=" clearfix">
              <div className="input-group align-items-start mt-1">
              <div className="flex-fill mr-n-50">
               <div className="input-group  mx-auto">
               <Autosuggest
                suggestions={suggestions||[]}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                shouldRenderSuggestions={this.shouldRenderSuggestions}
                className="form-control"
                ref={this.searchRef}
                onSuggestionSelected={(suggetion,suggestionValue )=>{
                  const type = suggestionValue?.suggestion?.type
                  if(type=='person'){
                    this.getMailPersonList(suggestionValue?.suggestion?.id)
                  }else if(type=='data'){
                  const is_parent  = suggestionValue?.suggestion?.is_parent 
                  const list_id = suggestionValue?.suggestion?.id
                  const parent_id = suggestionValue?.suggestion?.parent_id
                    const  list_parent_id = list_id + "-"+parent_id
                  console.log("suggestionValue?.suggestion --> ",suggestionValue?.suggestion);
                  if (is_parent == 1) {
                    this.props.history.push("/mail-detail/" + list_id);
                  } else {
                    this.props.history.push("/mail-details-personlist/" + list_parent_id);
                  }
                  }else if(type=='company'){
                    this.getPersonFromCompany(suggestionValue?.suggestion?.name)
                  }
                }}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={(suggetions)=>renderSuggestion(suggetions,t("GeneralMessages.mailList")
                  ,t("GeneralMessages.personList"),t("GeneralMessages.companyList"),this.state.value,
                  t("mailDetail.receiver"))}
                inputProps={inputProps}
                placeholder= {t("MailList.search")}
              />

                <div className="input-group-append">
                  <span className="input-group-text bg-white h-38" style={{marginLeft:'-4px'}}>
                    <i
                      className="zmdi zmdi-search zmdi-hc-fw font-22 pointer"
                      onClick={() => {
                        this.onClickSearch()
                      }}
                    ></i>
                    {value.length != 0 && (
                      <i
                        className="zmdi zmdi-close zmdi-hc-fw font-22 pointer"
                        onClick={this.clearSearch}
                      ></i>
                    )}
                  </span>
                </div>
              </div>
            </div>
            </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(SearchMail));
