import React from "react";
import MediaQuery from "react-responsive";
import ManageProfileMobile from "./ManageProfileMobile";
import ManageProfileDesktop from "./ManageProfileDesktop";

export default function ManageProfileResponsive() {
  return (
    <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <ManageProfileDesktop />
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <ManageProfileMobile />
      </MediaQuery>
    </React.Fragment>
  );
}
