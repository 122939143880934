/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";

class GroupItemListView extends Component {
  handleGroupDetail = (group_id, person) => {
    this.props.clearStateGroupDetail({});
    this.props.clearStateGroupPersonDetail({});
    if (person == 0) {
      this.props.history.push("/group-detail/" + group_id);
    } else {
      this.props.history.push("/group-personlist/" + group_id);
    }
  };
  render() {
    const { id, name, group_type_icon, person, description, has_child } =
      this.props.data;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="col-lg-4 mb-4 pointer" key={this.props.id}>
              <a
                href="javascript:;"
                className="card list-card"
                onClick={(e) => {
                  e.preventDefault();
                  this.handleGroupDetail(id, person);
                }}
              >
                <div className="card-body p-0">
                  <div className="media">
                    <div className="bg-blue left-icon-box-medium d-flex align-items-center justify-content-center text-center">
                      <div className="circle big m-0">
                        <img
                          src={`data:image/png;base64,${
                            group_type_icon && group_type_icon
                          }`}
                          alt="ico"
                          style={{ height: "20px", width: "20px" }}
                        />
                      </div>
                    </div>
                    <div className="clearfix p-3">
                      <h6 className="mb-1">{name}</h6>

                      {person == 0 && description != "" ? (
                        <p className="text-muted font-12 mb-0">
                          {description && description}
                        </p>
                      ) : person == 0 && description == "" ? (
                        <p className="text-muted font-12 mb-0">
                          {has_child && has_child}{" "}
                          {t("GroupListScreen.subGroup")}
                        </p>
                      ) : person != 0 ? (
                        <p className="text-muted font-12 mb-0">
                          {person && person}{" "}
                          {person && person == 1
                            ? t("GroupListScreen.singularPerson")
                            : t("GroupListScreen.pluralPerson")}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </a>
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.groupDetailStateToProps,
        stateToProps.groupListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupDetailDispatchToProps,
    actions.groupDetailPersonListDispatchToProps,
  ])
)(withRouter(withTranslation()(GroupItemListView)));
