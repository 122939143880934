/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { GroupDetailPersonListQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../../util/utils";
import LoadingPage from "../../CommanComponents/LoadingPage";
import NoRecordFound from "../../CommanComponents/NoRecordFound";
import AddPerson from "../../GroupDetailPersonList/GroupDetailPersonListDesktop/AddPerson";
import GroupDetails from "../../GroupDetail/GroupDetailDesktop/GroupDetails";
import PersonItem from "./PersonItem";
import EditNewGroup from "./EditNewGroup";
import RearrangePerson from "./RearrangePerson";
import CommonLoader from "../../CommanComponents/CommonLoader";

import SearchGroup from "../../Group/GroupListDesktop/SearchGroup";

import SearchPersonResult from "../../Group/GroupListDesktop/SearchPersonResult";
import SearchGroupListBaseOnPerson from "../../Group/GroupListDesktop/SearchGroupListBaseOnPerson";
import SearchPersonBaseOnGroup from "../../Group/GroupListDesktop/SearchPersonBaseOnGroup";
import SearchPersonBaseonCompany from "../../Group/GroupListDesktop/SearchPersonBaseonCompany";

class PersonList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      activeIndex: 0,
      headerMenuStatus: false,
      hasMore: true,
      end: 10,
      child_group_id: 0,
      start: 0,
      sortType: 1,
      child_group_list: [],
      addNewPerson: false,
      editSubGroup: false,
      reArrangeGroupModal: false,
      personListPermission: false,
      searchPersonList: [],
      searchGroupList: [],
      searchDisplayType: 0,
      groupListBaseOnPerson: [],
      personListBaseOnGroupID:[],
      personListBreadCumb:[],
      selectedPersonId:0,
      groupIdBaseOnPersonDetail:0,
    };
  }
  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  componentDidMount() {
    const personListPermission = checkUserPermission("read_person");
    const addPersonPermission = checkUserPermission("create_person");
    const editGroupPermission = checkUserPermission("edit_group");

    const child_id = this.props.match.params.group_id;
    this.setState(
      {
        child_group_id: child_id,
        personListPermission,
        addPersonPermission,
        editGroupPermission,
      },
      () => {
        if (this.state.personListPermission) {
          this.loadItem(child_id);
        }
      }
    );
  }

  handleAfterApiCall = (data) => {
    this.setState(() => {
      return {
        ...this.state,
        groupList: data.group_list,
      };
    });
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  handleChangeGroup = (group_id, person) => {
    console.log("group_id", group_id);
    console.log("person", person);
    const child_id = this.props.match.params.group_id;

    if (child_id != group_id) {
      if (person == 0) {
        this.props.clearStateGroupDetail({});
        this.props.history.push("/group-detail/" + group_id);
      } else {
        this.props.clearStateGroupPersonDetail({});
        this.props.setPersonListHasMoreValue(true);
        this.props.setPersonListStartValue(0);
        this.props.history.push("/group-personlist/" + group_id);
        setTimeout(() => {
          this.loadItem(group_id);
        }, 100);
      }
    }
  };

  changeSortType = (sortType) => {
    this.setState(
      {
        sortType: sortType,
      },
      () => {
        this.props.clearStateGroupPersonDetail();
      }
    );
  };

  groupLoadItem = (group_id) => {
    const child_id = this.props.match.params.group_id;
    const { end, isLoading, sortType } = this.state;

    if (!isLoading && this.props.groupDetailPersonList.hasMore) {
      this.setState({
        isLoading: true,
      });
      apiCall(
        METHOD.QUERY,
        GroupDetailPersonListQuery(
          getUserID(),
          child_id,
          this.props.groupDetailPersonList.start,
          end,
          sortType
        ),
        (data) => {
          this.setState({ child_group_list: data.group_list });
          if (this.props.groupDetailPersonList.id) {
            this.props.updatePersonList(data);
          } else {
            this.props.getPersonList(data);
          }

          this.handleGroupDetailApiResponse(data);
        },
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  loadItem = () => {
    const child_id = this.props.match.params.group_id;
    const { end, isLoading, sortType } = this.state;

    if (!isLoading && this.props.groupDetailPersonList.hasMore) {
      this.setState({
        isLoading: true,
      });
      apiCall(
        METHOD.QUERY,
        GroupDetailPersonListQuery(
          getUserID(),
          child_id,
          this.props.groupDetailPersonList.start,
          end,
          sortType
        ),
        (data) => {
          this.setState({ child_group_list: data.group_list });
          if (this.props.groupDetailPersonList.id) {
            this.props.updatePersonList(data);
          } else {
            this.props.getPersonList(data);
          }

          this.handleGroupDetailApiResponse(data);
        },
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  loader = (<CommonLoader key={0} />);

  handleGroupDetailApiResponse = (data) => {
    const { end } = this.state;
    this.setState({
      isLoading: true,
    });

    if (data.is_last == 1) {
      this.props.setPersonListHasMoreValue(false);

      this.setState(() => {
        return {
          ...this.state,
          hasMore: false,
        };
      });
    }

    this.props.setPersonListStartValue(
      this.props.groupDetailPersonList.start + end
    );
    this.setState((prevState) => {
      return {
        isLoading: false,
        start: prevState.start + end,
      };
    });
  };

  handleActiveIndex = (group_id, id) => {
    if (group_id == id) {
      return "active";
    } else {
      return "";
    }
  };

  // handleScroll = (id) => {
  //   const index = this.props.groupDetailPersonList.group_list.findIndex(
  //     (group) => group.id == id
  //   );

  //   if (index > 3) {
  //     setTimeout(function () {
  //       var element = document.getElementById(`li_${id}`);
  //       var element1 = document.getElementById("ulID");
  //       var elementPosition = element.getBoundingClientRect();
  //       element1.scrollLeft = elementPosition.left;
  //     }, 500);
  //   }
  // };

  handleAddPerson = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          addNewPerson: !prev.addNewPerson,
        };
      },
      () => {
        if (this.state.addNewPerson) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.props.updatePersonList({ person_list: [] });
            this.props.getPersonList({ person_list: [] });
            this.props.setPersonListHasMoreValue(true);
            this.props.setPersonListStartValue(0);
            setTimeout(() => {
              if (this.state.personListPermission) {
                this.loadItem();
              }
            }, 100);
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  handleEditGroup = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          editSubGroup: !prev.editSubGroup,
        };
      },
      () => {
        if (this.state.editSubGroup) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.props.updatePersonList({ person_list: [] });
            this.props.getPersonList({ person_list: [] });
            this.props.setPersonListHasMoreValue(true);
            this.props.setPersonListStartValue(0);
            if (this.state.personListPermission) {
              this.loadItem();
            }
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  handleRearrangePerson = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          reArrangeGroupModal: !prev.reArrangeGroupModal,
        };
      },
      () => {
        if (this.state.reArrangeGroupModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  setSearchGroupList = (groupList, personList) => {
    this.setState({
      searchGroupList: groupList,
      searchPersonList: personList,
    });
  };


  

// search methods


setGroupFromPerson=(groupListBaseOnPerson,selectedPersonId)=>{
  console.log("groupListBaseOnPerson",groupListBaseOnPerson);
  console.log("selectedPersonId",selectedPersonId);
  if(groupListBaseOnPerson.length!=0){
    this.setState({
      groupListBaseOnPerson,
      searchDisplayType:2,
      selectedPersonId
    })
  }else{
    toast.info(
      this.props.t("GeneralMessages.noMailFound") );
  }

}

handleGroupWiseResponse=(personListBaseOnGroupID,personResponse,personListBreadCumb)=>{
  this.setState({
    personListBaseOnGroupID,
    personListBreadCumb,
    personResponse,
    searchDisplayType:3
  })
}


handleDisplayType =()=>{
  this.setState({
    searchDisplayType:0
  })
}

setSearchGroupList = (personList) => {
  if (personList.length == 0) {
  }
  this.setState({
    searchPersonList: personList,
    searchDisplayType: personList.length == 0 ? 0 : 1,
  });
};

handleSearchDisplayTypeToInitialStage = () => {
  this.setState({
    searchDisplayType:0,
    search:'',
    value:''

  });
};
handleSearchDisplayType  = (searchDisplayType ) => {
  this.setState({
    searchDisplayType,
    search:'',
    value:''
  });
};

handleGroupbaseOnPerson = (mailList) => {
  if (mailList.length == 0) {
    this.setState({
      groupListBaseOnPerson: [],
      searchPersonList: [],
      // searchDisplayType: 0,
    });
  } else {
    this.setState({
      // searchDisplayType: 0,
      groupListBaseOnPerson: mailList,
    });
  }
};

handlePersonListBaseonCompany = (personListBaseOnGroupID)=>{
  console.log("personListBaseOnGroupIDpersonListBaseOnGroupID",personListBaseOnGroupID);
  this.setState({
    personListBaseOnGroupID,
    searchDisplayType:4
  })
}
  render() {
    const {
      end,
      sortType,
      isLoading,
      child_group_list,
      addNewPerson,
      editSubGroup,
      reArrangeGroupModal,
      personListPermission,
      addPersonPermission,
      editGroupPermission,
      breadcrumbSelectedId,
      searchPersonList,
      searchDisplayType,groupListBaseOnPerson,selectedPersonId,personListBreadCumb,personListBaseOnGroupID,
      groupIdBaseOnPersonDetail
    } = this.state;
    const { name, group_type_icon, person_list, breadcrumb, person } =
      this.props.groupDetailPersonList && this.props.groupDetailPersonList;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <>
              <GroupDetails
                isFromPersonList={true}
                name={name}
                breadcrumb={breadcrumb}
                handleChangeGroup={(group_id, person) => {
                  this.handleChangeGroup(group_id, person);
                }}
              />

              <div className="section-content">
                {isLoading && this.loader}

                <div className="d-flex align-items-center justify-content-center">
                  <ul className="list-inline custom-tab group m-0">
                    {child_group_list &&
                      child_group_list.map((data, index) => {
                        const { id, name, person } = data;
                        return (
                          <li className="list-inline-item " key={index}>
                            <a
                              href="javascript;:"
                              onClick={(e) => {
                                e.preventDefault();
                                this.handleChangeGroup(id, person);
                              }}
                              className={this.handleActiveIndex(
                                this.props.groupDetailPersonList.id,
                                id
                              )}
                            >
                              {name && name}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>

                <div className="d-flex align-items-center justify-content-end  pb-3">
                  <div className="flex-fill mr-n-25">
                    <div className="mx-auto">
                      <div className="flex-fill">
                      <SearchGroup
                    handleMailbaseOnPerson={(mailList) => {this.handleMailbaseOnPerson(mailList);}}
                    handlePersonListBaseonCompany={(personList)=>this.handlePersonListBaseonCompany(personList)}
                    setGroupFromPerson={(personList,selectedPersonId)=>{this.setGroupFromPerson(personList,selectedPersonId)}}
                    searchArray={this.setSearchGroupList}
                    handleSearchDisplayType={(searchDisplayType) => {this.handleSearchDisplayType(searchDisplayType);}}
                  />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-4 z-index-99">
                  <div className="mb-2">
                    <div className="clearfix sorting-filter">
                      <span className="font-13 text-muted mr-2">
                        {t("GroupListScreen.sorting")}
                      </span>
                      <div
                        className="btn-group btn-group-sm"
                        role="group"
                        aria-label="First group"
                      >
                        <button
                          type="button"
                          onClick={() => this.changeSortType(1)}
                          className={
                            sortType == 1
                              ? "btn btn-dark mr-2"
                              : "btn btn-outline-secondary mr-2"
                          }
                        >
                          {t("GroupListScreen.a-z")}
                        </button>

                        <button
                          type="button"
                          onClick={() => this.changeSortType(2)}
                          className={
                            sortType == 2
                              ? "btn btn-dark mr-2"
                              : "btn btn-outline-secondary mr-2"
                          }
                        >
                          {t("GroupListScreen.orderOfPriority")}
                        </button>
                        <button
                          type="button"
                          onClick={() => this.changeSortType(3)}
                          className={
                            sortType == 3
                              ? "btn btn-dark mr-2"
                              : "btn btn-outline-secondary mr-2"
                          }
                        >
                          {t("GroupListScreen.crisisContacts")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
              
              {searchDisplayType==0 && <React.Fragment>
                <div className="card-header-custom">
                    <div className="media">
                      <div
                        className="bg-blue left-icon-box d-flex align-items-center justify-content-center text-center"
                        style={{ backgroundColor: "#b31218" }}
                      >
                        <div className="circle big m-0">
                          <img
                            src={`data:image/png;base64,${
                              group_type_icon && group_type_icon
                            }`}
                            alt="ico"
                            style={{ width: "24px", height: "24px" }}
                          />
                        </div>
                      </div>
                      <div className="media-body pt-3 px-4">
                        <div className="form-row">
                          <div className="col-md-6">
                            <h5 className="font-size-18 mb-1">
                              {name && name}
                            </h5>
                            <div className="d-flex align-items-center font-size-14 mt-2">
                              <div className="icon mr-3">
                                {person && person}{" "}
                                {person && person == 1
                                  ? t("GroupListScreen.singularPerson")
                                  : t("GroupListScreen.pluralPerson")}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="font-size-14 action-icon text-right">
                              {this.props.groupDetail.group_list.length == 0 &&
                                addPersonPermission && (
                                  <a
                                    href="javascript;:"
                                    className="mr-4"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.handleAddPerson(false);
                                    }}
                                  >
                                    <i className="zmdi zmdi-plus zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                    {t(
                                      "GroupDetailPersonListScreen.addNewPerson"
                                    )}
                                  </a>
                                )}

                              {editGroupPermission && (
                                <a
                                  href="javascript;:"
                                  className="mr-4"
                                  data-toggle="modal"
                                  data-target="#NewMessage"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleEditGroup(false);
                                  }}
                                >
                                  <i className="zmdi zmdi-edit zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                  {t("GroupDetailPersonListScreen.editGroup")}
                                </a>
                              )}

                              <a
                                href="javascript;:"
                                className="mr-4"
                                data-toggle="modal"
                                data-target="#NewMessage"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.handleRearrangePerson(false);
                                }}
                              >
                                <i className="zmdi zmdi-view-agenda zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                {t("GroupListScreen.setpriority")}
                              </a>
                              {/*                            
                              <a
                                href="javascript;:"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.deletegroup();
                                }}
                                className="mr-4"
                              >
                                <i className="zmdi zmdi-delete zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                {t("GroupDetailScreen.deleteGroupTitle")}
                              </a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {addNewPerson && (
                    <AddPerson
                      handleAddPerson={(isFromSave) => {
                        this.handleAddPerson(isFromSave);
                      }}
                    />
                  )}

                  {editSubGroup && (
                    <EditNewGroup
                      handleEditGroup={(isFromSave) => {
                        this.handleEditGroup(isFromSave);
                      }}
                    />
                  )}
                  {reArrangeGroupModal && (
                    <RearrangePerson
                      handleRearrangePerson={(isFromSave) => {
                        this.handleRearrangePerson(isFromSave);
                      }}
                    />
                  )}
             
                  <div className="card-body-custom side-line">
                    {!!person_list.length &&
                      searchPersonList.length == 0 &&
                      person_list.map((data, index) => {
                        return (
                          <PersonItem data={data} key={index} index={index} />
                        );
                      })}

                    {searchPersonList.length != 0 &&
                      searchPersonList.map((data, index) => {
                        return (
                          <PersonItem data={data} key={index} index={index} />
                        );
                      })}

                    {!isLoading && person_list.length == 0 && (
                      <NoRecordFound title="Person List" name="person list" />
                    )}

                    {this.props.groupDetailPersonList.hasMore &&
                      searchPersonList.length == 0 &&
                      personListPermission && (
                        <div className="text-center mt-2 mb-3">
                          <button
                            type="button"
                            className="btn btn-primary-theme"
                            onClick={(e) => {
                              e.preventDefault();
                              this.loadItem();
                            }}
                          >
                            {t("HomeScreen.showMore")}
                          </button>
                        </div>
                      )}
                  </div>
             
                </React.Fragment>}
              
                {searchDisplayType == 1 && (
                 <SearchPersonResult 
                      suggetionList={this.state.searchPersonList}
                      handlePersonListBaseonCompany={(personList)=>this.handlePersonListBaseonCompany(personList)}
                      setGroupFromPerson={(personList,selectedPersonId)=>{this.setGroupFromPerson(personList,selectedPersonId)}}/>
                )}

            {searchDisplayType == 2 && (
                  <div className="container my-2">
                    {groupListBaseOnPerson.map((data, index) => {
                      return <SearchGroupListBaseOnPerson data={data} key={index} 
                      selectedPersonId={selectedPersonId}
                      handleGroupWiseResponse={(personListBaseOnGroupID,personResponse,personListBreadCumb,group_id)=>{this.handleGroupWiseResponse(personListBaseOnGroupID,personResponse,personListBreadCumb,group_id)}}/>;
                    })}
                  </div>
                )}

              {searchDisplayType == 3 && (
                  <div className="container my-2">
                       <nav aria-label="breadcrumb">
                        <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item">
                            {t("mailDetail.home")}
                          </li>
                          <li
                              className="breadcrumb-item pointer"
                              aria-current="page"
                              onClick={()=>{
                                // const mailDetailId = breadcrumb[0]?.id
                                // this.props.history.push("/mail-detail/" + mailDetailId);
                                }}
                            >
                             {personListBreadCumb[0]?.name}
                            </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                             {personListBreadCumb[1]?.name}
                         
                          </li>
                        </ol>
                      </nav>

                    {personListBaseOnGroupID.map((data, index) => {
                      return <SearchPersonBaseOnGroup
                      handleSearchDisplayType={(searchDisplayType) => {
                        this.handleSearchDisplayType(searchDisplayType);
                      }}
                      groupIdBaseOnPersonDetail={groupIdBaseOnPersonDetail}
                      data={data} key={index} 
                      personResponse={this.state.personResponse}/>;
                    })}
                  </div>
                )}

                
{searchDisplayType == 4 && (
                  <div className="container my-2">
                    {personListBaseOnGroupID.map((data, index) => {
                      return (
                        <SearchPersonBaseonCompany
                          handleSearchDisplayType={(searchDisplayType) => {
                            this.handleSearchDisplayType(searchDisplayType);
                          }}
                          data={data}
                          key={index}
                          personResponse={this.state.personResponse}
                          groupIdBaseOnPersonDetail={groupIdBaseOnPersonDetail}
                        />
                      );
                    })}
                  </div>
                )}
                </div>
              </div>
            </>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.groupDetailStateToProps,
        stateToProps.groupDetailPersonListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupDetailDispatchToProps,
    actions.groupDetailPersonListDispatchToProps,
  ])
)(withRouter(withTranslation()(PersonList)));
