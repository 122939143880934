import React, { Component } from 'react'
import DesktopSideMenuItem from '../Header/DesktopSideMenuItem';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { SideMenuQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { connect } from "react-redux";
import { Translation, withTranslation } from "react-i18next";
import { checkUserPermission } from '../../util/utils';


 class SideMenuDesktop extends Component {
     constructor(props) {
         super(props)
     
         this.state = {
              isSideBarMenuOpen:false,
              adminPermission:false
         }
     }
     
  


      componentDidMount() {
          const adminPermission = checkUserPermission("administration");
        this.setState({
          adminPermission
        },()=>{
          apiCall(
            METHOD.QUERY,
            SideMenuQuery(),
            (data) => this.handleAfterApiCall(data),
            (isLoading) => this.handleLoader(isLoading),
            (data) => {
              toast.error(this.props.t(data.message));
            }
          );
        });
      }

      handleLoader = (status) => {
        this.setState(() => {
          return {
            ...this.state,
            isLoading: status,
          };
        });
      };

      
  handleAfterApiCall = (data) => {
    this.props.getSideMenuList(data);

    const systemLogo = localStorage.getItem("system_logo");
    this.setState(() => {
      return {
        ...this.state,
        systemLogo,
        side_menu_list: data.side_menu_list,
        installed_version: data.installed_version,
      };
    });
  };


      handleSidebarMenu = ()=>{
          this.setState((prev)=>{
              return({
                isSideBarMenuOpen:!prev.isSideBarMenuOpen
              })
          })
      }
    render() {
        const { side_menu_list, installed_version } =
      this.props.sideMenu && this.props.sideMenu;
      const {isSideBarMenuOpen,systemLogo,adminPermission} = this.state

        return (
            <div className={isSideBarMenuOpen?("sidebar-toggle-btn ml-3 active"):("sidebar-toggle-btn ml-3")}>
            <a href="javascript;:" onClick={(e)=>{
                e.preventDefault()
                this.handleSidebarMenu()
            }}>
                <img src="/images/menu.png" className="menu" style={{width:"22px"}} alt="Menu" />
                <img src="/images/close.png" className="menu-close" style={{width:"20px"}} alt="Menu Close" />
            </a>
            <div className="nav-sidebar" >
                <ul className="list-inline sidebar-icons m-0">

                <Link to="/home" className="d-block mb-2">
                          {systemLogo ? (
                            <img
                              src={`data:image/png;base64,${systemLogo}`}
                              alt="loginLogo"
                              style={{ width: "60px", height: "60px", display: "block", margin: "0 auto" }}
                            />
                          ) : (
                            <img
                              src="/images/login_logo.png"
                              alt="loginLogo"
                              style={{ width: "60px", height: "60px", display: "block", margin: "0 auto" }}
                            />
                          )}
                        </Link>


                        {side_menu_list &&
                        side_menu_list.map((item, index) => {
                          if(item.identifier=='administration'){
                            if(adminPermission){
                              return <DesktopSideMenuItem {...item} key={index} />
                            }
                          }else{
                            return <DesktopSideMenuItem {...item} key={index} />
                          }
                        })}
             
                </ul>
            </div>
        </div>
        )
    }
}
export default connect(
    (state) =>
      stateToProps.appMapStateToProps([stateToProps.sideMenuStateToProps], state),
    actions.appMapDispatchToProps([actions.sideMenuDispatchToProps])
  )(withTranslation()(SideMenuDesktop));
  