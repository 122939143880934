/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Translation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  adminLocationList,
  deleteLocation,
  adminLocationListSearch,
} from "../../../../constants/apiParams";
import apiCall, { METHOD } from "../../../../constants/baseUrl";
import {
  getUserID,
  getValueFromLocalStorage,
  saveDatatoLocalStorage,
} from "../../../../util/utils";
import CommonLoader from "../../../CommanComponents/CommonLoader";
import FooterDesktop from "../../../Footer/FooterDesktop";
import HeaderDesktop from "../../../Header/HeaderDesktop";
import AddLocation from "../ManageLocationDesktop/AddLocation";
import EditLocation from "../ManageLocationDesktop/EditLocation";
import AdminSideMenu from "../../AdminMenu/AdminSideMenu";

class LocationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      location_list: [],
      deleteLoadingStatus: false,
      headerMenuStatus: false,
      editModal: false,
      addModal: false,
      search: "",
      searchList: [],
      displayType: 1,
      searchLocationList: [],
    };
    this.searchRef = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const locationDisplayType = getValueFromLocalStorage("locationDisplayType");

    if (locationDisplayType == 1) {
      this.setState({
        displayType: 1,
      });
    } else {
      this.setState({
        displayType: 0,
      });
    }

    this.loadListTypeList();
  }

  loadListTypeList = () => {
    apiCall(
      METHOD.QUERY,
      adminLocationList(getUserID()),
      (data) => this.handleUserApiResponse(data),
      (isLoading) => {
        this.setState({ isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleUserApiResponse = (data) => {
    this.setState((prevState) => {
      return {
        location_list: data.location_list,
        isLoading: false,
      };
    });
  };

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: true,
      };
    });
  };

  handleDelete = (item) => {
    const { id } = item;

    confirmAlert({
      title: this.props.t("ManageLocation.confirmTodeleteLocationTitle"),
      message: this.props.t("ManageLocation.confirmTodeleteLocationMessage"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              deleteLocation(getUserID(), id),
              (data) => {
                let afterDeletedLocationList = this.state.location_list.filter(
                  (location) => {
                    return location.id != item.id;
                  }
                );
                toast.success(this.props.t(data.message));
                this.setState({
                  location_list: afterDeletedLocationList,
                });
              },
              (isLoading) => {
                this.setState({ deleteLoadingStatus: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        headerMenuStatus: false,
      };
    });
  };

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleAddModal = (isFromSave) => {
    this.setState(
      (prev) => {
        return {
          addModal: !prev.addModal,
        };
      },
      () => {
        if (this.state.addModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.loadListTypeList();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  handleEditModal = (isFromSave, item) => {
    this.setState(
      (prev) => {
        return {
          editModal: !prev.editModal,
          editItemValue: item,
        };
      },
      () => {
        if (this.state.editModal) {
          document.body.classList.add("modal-open");
        } else {
          if (isFromSave) {
            this.loadListTypeList();
          }
          document.body.classList.remove("modal-open");
        }
      }
    );
  };

  searchApi = () => {
    const { search } = this.state;
    apiCall(
      METHOD.QUERY,
      adminLocationListSearch(getUserID(), 1, search),
      (data) => this.handleSearchResponse(data),
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  changeSearch = (e) => {
    const searchValue = e.target.value;
    this.setState({
      search: searchValue,
      searchLocationList: [],
    });
  };

  handleSearchResponse = (data) => {
    this.setState(
      (prevState) => {
        return {
          searchLocationList: data.location_list,
        };
      },
      () => {
        if (this.state.searchLocationList.length === 0) {
          toast.info(
            this.props.t("GeneralMessages.searchErrorMessage") +
              "'" +
              this.state.search +
              "'"
          );
        }
      }
    );
  };

  clearSearch = () => {
    this.searchRef.current.value = "";
    this.setState({
      search: "",
      searchLocationList: [],
    });
  };
  loader = (<CommonLoader key={0} />);
  render() {
    const {
      location_list,
      isLoading,
      editModal,
      addModal,
      search,
      displayType,
      searchLocationList,
    } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="content-wrapper">
              <HeaderDesktop selectionType={0} />
              <div className="content">
                <div className="section section-padding">
                  <div className="container">
                    <div className="section-container">
                      <div className="section-content">
                        <div className="row mt-2">
                          {isLoading && <CommonLoader />}
                          <AdminSideMenu activeIndex={2} />
                          <div className="col-lg-9">
                            <div className="section-title text-center d-md-flex align-items-start m-0">
                              <div className="flex-fill mr-n-100">
                                <Link
                                  to="/administration"
                                  className="text-light"
                                >
                                  <h1 className="page-title h4">
                                    {t("ManageLocation.title")}
                                  </h1>
                                </Link>
                              </div>
                              <button
                                type="button"
                                className="btn btn-primary-theme btn-sm"
                                onClick={() => {
                                  this.handleAddModal();
                                }}
                              >
                                {" "}
                                {t("GeneralMessages.addNew")}
                              </button>
                            </div>
                            <div className="d-flex align-items-center justify-content-end mt-3 mb-4 pb-3">
                              <div className="flex-fill mr-n-50">
                                <div className="input-group w-50 mx-auto">
                                  <input
                                    type="E-Mail"
                                    placeholder={t(
                                      "ManageLocation.searchLocation"
                                    )}
                                    className="form-control"
                                    ref={this.searchRef}
                                    onChange={this.changeSearch}
                                    onKeyPress={(e) =>
                                      e.keyCode == 13 ||
                                      (e.which == 13 && this.searchApi())
                                    }
                                  />
                                  <div className="input-group-append">
                                    <button
                                      type="button"
                                      className="input-group-text pointer"
                                    >
                                      <i
                                        className="fa fa-search"
                                        onClick={this.searchApi}
                                      ></i>
                                      {search.length !== 0 && (
                                        <i
                                          className="fa fa-times ml-3"
                                          onClick={this.clearSearch}
                                        ></i>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="sort">
                                <a
                                  href="javascript;:"
                                  className={
                                    displayType == 0 ? "mr-2 active" : "mr-2"
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    saveDatatoLocalStorage(
                                      "locationDisplayType",
                                      0
                                    );
                                    this.setState({
                                      displayType: 0,
                                    });
                                  }}
                                >
                                  <i className="fa fa-list fa-lg text-muted"></i>
                                </a>
                                <a
                                  href="javascript;:"
                                  className={displayType == 1 ? "active" : ""}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    saveDatatoLocalStorage(
                                      "locationDisplayType",
                                      1
                                    );
                                    this.setState({
                                      displayType: 1,
                                    });
                                  }}
                                >
                                  <i className="fa fa-th-large fa-lg text-muted"></i>
                                </a>
                              </div>
                            </div>

                            {displayType == 0 &&
                              searchLocationList.length != 0 &&
                              searchLocationList.map((item, index) => {
                                const { name, description, icon } = item;
                                return (
                                  <>
                                    <div
                                      className="card pointer bg-border green"
                                      key={index}
                                    >
                                      <div className="card-header-custom">
                                        <div className="media">
                                          <div className="media-body pt-4 px-4 pb-4">
                                            <div className="form-row">
                                              <div className="col-md-9 d-flex">
                                                <div className="circle big m-0 mr-3">
                                                  <img
                                                    src={`data:image/png;base64,${
                                                      icon && icon
                                                    }`}
                                                    alt="ico"
                                                    style={{ width: "20px" }}
                                                  />
                                                </div>
                                                <div className="clearfix mr-2">
                                                  <h5 className="mb-1">
                                                    {name}
                                                  </h5>
                                                  <small className="text-muted">
                                                    {description}
                                                  </small>
                                                </div>
                                              </div>
                                              <div className="col-md-3">
                                                <div className="font-size-14 action-icon text-right">
                                                  <a
                                                    href="javascript;:"
                                                    className="mr-4"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      this.handleEditModal(
                                                        false,
                                                        item
                                                      );
                                                    }}
                                                  >
                                                    <i className="zmdi zmdi-edit zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                                    {/* {t(
                                                      "ManageLocation.editLocation"
                                                    )} */}
                                                  </a>
                                                  <a
                                                    href="javascript;:"
                                                    className="mr-4"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      this.handleDelete(item);
                                                    }}
                                                  >
                                                    <i className="zmdi zmdi-delete zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                                    {/* {t(
                                                      "ManageLocation.deleteLocation"
                                                    )} */}
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}

                            {displayType == 0 &&
                              searchLocationList.length == 0 &&
                              location_list.map((item, index) => {
                                const { name, description, icon } = item;
                                return (
                                  <>
                                    <div
                                      className="card pointer bg-border green"
                                      key={index}
                                    >
                                      <div className="card-header-custom">
                                        <div className="media">
                                          <div className="media-body pt-4 px-4 pb-4">
                                            <div className="form-row">
                                              <div className="col-md-9 d-flex">
                                                <div className="circle big m-0 mr-3">
                                                  <img
                                                    src={`data:image/png;base64,${
                                                      icon && icon
                                                    }`}
                                                    alt="ico"
                                                    style={{ width: "20px" }}
                                                  />
                                                </div>
                                                <div className="clearfix mr-2">
                                                  <h5 className="mb-1">
                                                    {name}
                                                  </h5>
                                                  <small className="text-muted">
                                                    {description}
                                                  </small>
                                                </div>
                                              </div>
                                              <div className="col-md-3">
                                                <div className="font-size-14 action-icon text-right">
                                                  <a
                                                    href="javascript;:"
                                                    className="mr-4"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      this.handleEditModal(
                                                        false,
                                                        item
                                                      );
                                                    }}
                                                  >
                                                    <i className="zmdi zmdi-edit zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                                    {/* {t(
                                                        "ManageLocation.editLocation"
                                                      )} */}
                                                  </a>
                                                  <a
                                                    href="javascript;:"
                                                    className="mr-4"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      this.handleDelete(item);
                                                    }}
                                                  >
                                                    <i className="zmdi zmdi-delete zmdi-hc-2x font-size-20 align-bottom mr-1"></i>{" "}
                                                    {/* {t(
                                                        "ManageLocation.deleteLocation"
                                                      )} */}
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}

                            <div className="row ">
                              {displayType == 1 &&
                                searchLocationList.length == 0 && (
                                  <div className="row mt-4">
                                    {location_list.map((item, index) => {
                                      const { name, description, icon } = item;
                                      return (
                                        <div
                                          className="col-lg-6 mb-4"
                                          key={index}
                                        >
                                          <a
                                            href="javascript:;"
                                            className="card list-card"
                                          >
                                            <div className="card-body d-flex py-3 px-3">
                                              <div className="circle big m-0 mr-3">
                                                <img
                                                  src={`data:image/png;base64,${
                                                    icon && icon
                                                  }`}
                                                  alt="ico"
                                                  style={{ width: "20px" }}
                                                />
                                              </div>
                                              <div className="clearfix mr-2">
                                                <h6 className="mb-1">{name}</h6>
                                                <small className="text-muted text-break">
                                                  {description}
                                                </small>
                                              </div>
                                              <div className="ml-auto">
                                                <ul className="list-inline action-menu-list d-flex m-0">
                                                  <li className="list-inline-item mr-3">
                                                    <i
                                                      className="zmdi zmdi-edit zmdi-hc-lg pointer"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        this.handleEditModal(
                                                          false,
                                                          item
                                                        );
                                                      }}
                                                    />
                                                  </li>
                                                  <li className="list-inline-item mr-3 ">
                                                    <i
                                                      className="zmdi zmdi-delete zmdi-hc-lg pointer"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        this.handleDelete(item);
                                                      }}
                                                    />
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </a>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}

                              {displayType == 1 &&
                                searchLocationList.length != 0 && (
                                  <div className="row mt-4">
                                    {searchLocationList.map((item, index) => {
                                      const { name, description, icon } = item;
                                      return (
                                        <div
                                          className="col-lg-6 mb-4"
                                          key={index}
                                        >
                                          <a
                                            href="javascript:;"
                                            className="card list-card"
                                          >
                                            <div className="card-body d-flex py-3 px-3">
                                              <div className="circle big m-0 mr-3">
                                                <img
                                                  src={`data:image/png;base64,${
                                                    icon && icon
                                                  }`}
                                                  alt="ico"
                                                  style={{ width: "20px" }}
                                                />
                                              </div>
                                              <div className="clearfix mr-2">
                                                <h6 className="mb-1">{name}</h6>
                                                <small className="text-muted text-break">
                                                  {description}
                                                </small>
                                              </div>
                                              <div className="ml-auto">
                                                <ul className="list-inline action-menu-list d-flex m-0">
                                                  <li className="list-inline-item mr-3">
                                                    <i
                                                      className="zmdi zmdi-edit zmdi-hc-lg pointer"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        this.handleEditModal(
                                                          false,
                                                          item
                                                        );
                                                      }}
                                                    />
                                                  </li>
                                                  <li className="list-inline-item mr-3 ">
                                                    <i
                                                      className="zmdi zmdi-delete zmdi-hc-lg pointer"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        this.handleDelete(item);
                                                      }}
                                                    />
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </a>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {addModal && (
                      <AddLocation
                        handleAddModal={(isFromSave) => {
                          this.handleAddModal(isFromSave);
                        }}
                      />
                    )}
                    {editModal && (
                      <EditLocation
                        item={this.state.editItemValue}
                        handleEditModal={(isFromSave, item) => {
                          this.handleEditModal(isFromSave, item);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <FooterDesktop selectionType={0} />
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(LocationList);
