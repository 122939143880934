import React, { Component } from 'react'
import MediaQuery from "react-responsive";
import ManagePersonDataTypeMobile from "./ManagePersonDataTypeMobile/Index";
import ManagePersonDataTypeDesktop from "./ManagePersonDataTypeDesktop/PersonDataTypeList";
import { withRouter } from "react-router";
import { checkAdminPermission } from '../../../util/utils';

 class ManageLocationResponsive extends Component {
   componentDidMount() {
    if(!checkAdminPermission()){
      this.props.history.push("/")
    }
   }
  render() {
    return (
      <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <ManagePersonDataTypeDesktop />
        
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <ManagePersonDataTypeMobile />
      </MediaQuery>
    </React.Fragment>
    )
  }
}
export default withRouter(ManageLocationResponsive)