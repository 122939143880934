import React from "react";
import MediaQuery from "react-responsive";
import PersonDetailMobile from "./PersonDetailMobile/Index";
import PersonDetailDesktop from "./PersonDetailDesktop/PersonDetail";

export default function PersonDetailResponsive() {
  return (
    <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <PersonDetailDesktop />
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <PersonDetailMobile />
      </MediaQuery>
    </React.Fragment>
  );
}
