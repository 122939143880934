/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { adminEmailList } from "../../../../constants/apiParams";
import apiCall, { METHOD } from "../../../../constants/baseUrl";
import { getUserID } from "../../../../util/utils";
import Footer from "../../../Footer/FooterMobile";
import LoadingPage from "../../../CommanComponents/LoadingPage";
import LogoutMenu from "../../../CommanComponents/LogoutMenu";
import TopHeader from "../../../CommanComponents/TopHeader";
class EmailList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email_list: [],
      headerMenuStatus: false,
    };
  }
  componentDidMount() {
    this.loadUserList();
  }

  loadUserList = () => {
    apiCall(
      METHOD.QUERY,
      adminEmailList(getUserID()),
      (data) => this.handleEmailApiResponse(data),
      (isLoading) => {
        this.setState({ isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleEmailApiResponse = (data) => {
    this.setState((prevState) => {
      return {
        ...this.state,
        email_list: data.systememail_list,
        isLoading: false,
      };
    });
  };

  loader = (<LoadingPage key={0} />);
  render() {
    const { email_list, isLoading } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-green sab-header1 sticky-top d-flex flex-column">
                <div className="container d-flex flex-column flex-fill">
                  <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                    <TopHeader />

                    <p className="mb-0 ml-auto text-light">
                      <Link to="/administration" className="text-light">
                        {t("manageAdminEmail.title")}
                      </Link>
                    </p>
                    <LogoutMenu />
                  </div>
                </div>
              </div>

              <div className="middle-section sab-middle-section1  left-line-custom">
                {isLoading && this.loader}
                {email_list &&
                  email_list.map((item, index) => {
                    const { subject } = item;
                    return (
                      <div
                        className={
                          index == email_list.length - 1
                            ? "container my-4 z-index-99 last"
                            : "container my-4 z-index-99 "
                        }
                        key={index}
                      >
                        <a
                          href="javascript;:"
                          className="mb-4 d-block custom-hover-box"
                          key={index}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <div className="rounded-40 admin-bg float-left ml-5p">
                            <span className="text-light"></span>
                          </div>
                          <div className="pl-3 pt-1 d-flex" key={index}>
                            <div className="d-flex justify-content-between w-100">
                              <div className="clearfix">
                                <h6 className="m-0">{subject && subject}</h6>
                              </div>
                              <div className="action-btns clearfix">
                                <i
                                  className="zmdi zmdi-edit zmdi-hc-lg mr-3 pointer"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.changeStatusWithStatusValue(
                                      2,
                                      item
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
              </div>
              <Footer />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(EmailList);
