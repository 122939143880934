import React from "react";
import MediaQuery from "react-responsive";
import EventListMobile from "./EventListMobile/Index";
import EventListDesktop from "./EventListDesktop/EventList";

export default function EventResponsive() {
  return (
    <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <EventListDesktop />
       
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <EventListMobile />
      </MediaQuery>
    </React.Fragment>
  );
}
