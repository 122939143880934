import React, { Component } from "react";
import TwitterLogin from "react-twitter-auth";
import MediaQuery from "react-responsive";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import {
  getSocialDetails,
  maketwitteroauthtoken,
} from "../../../constants/apiParams";
import apiCall, { METHOD, mainURL } from "../../../constants/baseUrl";
import { getUserID, saveDatatoLocalStorage } from "../../../util/utils";
import TopHeader from "../../CommanComponents/TopHeader";
import LogoutMenu from "../../CommanComponents/LogoutMenu";
import HeaderDesktop from "../../Header/HeaderDesktop";
class TwitterConnection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      twitter_consumer_key: "",
      twitter_consumer_secret: "",
    };
  }

  loginHandler = () => {
    const oauth_token = localStorage.getItem("twitter_oauth_token");
    const oauth_token_secret = localStorage.getItem(
      "twitter_oauth_token_secret"
    );
    const screen_name = localStorage.getItem("twitter_screen_name");
    const isFromCreate = this.props.location.state?.isFromCreate;
    const textValueObject = this.props.location.state?.textValueObject;

    let twitterResponseTemp = {};
    let profileLink = `https://twitter.com/${screen_name}`;
    twitterResponseTemp.oauth_token = oauth_token;
    twitterResponseTemp.oauth_token_secret = oauth_token_secret;
    twitterResponseTemp.isConnected = 1;
    twitterResponseTemp.profileLink = profileLink;
    twitterResponseTemp.channelType = "twitter";
    twitterResponseTemp.textValueObject = textValueObject;
    if (isFromCreate) {
      twitterResponseTemp.comeFrom = "createTwitter";
    } else {
      const channelId = this.props.location.state?.channelId;
      twitterResponseTemp.comeFrom = "editTwitter";
      twitterResponseTemp.channelId = channelId;
    }
    twitterResponseTemp = JSON.stringify(twitterResponseTemp);
    saveDatatoLocalStorage("tempConnectResponse", twitterResponseTemp);
    apiCall(
      METHOD.QUERY,
      maketwitteroauthtoken(oauth_token_secret, oauth_token, getUserID()),
      (data) => {
        toast.info(this.props.t("ConnectionPage.twitter_connect_successfully"));
        this.props.history.push({
          pathname: "/channel-list",
          search: "",
          state: { isFromtwitterConnect: true },
        });
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  componentDidMount() {
    const isFromEdit = this.props.location.state?.isFromEdit;
    console.log("isFromEdit-?", isFromEdit);
    saveDatatoLocalStorage("oauth_token", "");
    saveDatatoLocalStorage("oauth_token_secret", "");
    saveDatatoLocalStorage("isConnected", 0);
    saveDatatoLocalStorage("ProfileLink", "");

    apiCall(
      METHOD.QUERY,
      getSocialDetails(getUserID()),
      (data) => {
        this.setState({
          twitter_consumer_key: data.twitter_consumer_key,
          twitter_consumer_secret: data.twitter_consumer_secret,
        });
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }
  onSuccess = (data) => {
    console.log(data);
  };
  onFailed = (error) => {
    this.loginHandler();
    console.log(error);
  };
  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };
  render() {
    const { twitter_consumer_key, twitter_consumer_secret } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <MediaQuery query="(min-device-width: 1224px)">
                <div className="wrapper position-relative">
                  <div className="header-bg header-red sab-header1 sticky-top d-flex flex-column">
                    <div className="container d-flex flex-column flex-fill">
                      <div className="text-center py-2 d-flex  0align-items-center 0justify-content-between">
                        <HeaderDesktop selectionType={3} />
                        <p
                          className="mb-0 ml-auto text-light  pointer"
                          onClick={this.handleBackStack}
                        >
                          {t("ConnectionPage.twitter_connection")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="middle-section sab-middle-section1 d-flex align-item-bottam"
                    style={{ paddingTop: "80px" }}
                  >
                    <div className="container">
                      <TwitterLogin
                        loginUrl={`${mainURL}twitter/login`}
                        requestTokenUrl={`${mainURL}twitter/request-token`}
                        onFailure={(error) => this.onFailed(error)}
                        onSuccess={(data) => this.onSuccess(data)}
                        showIcon={true}
                      />
                    </div>
                  </div>
                </div>
              </MediaQuery>
              <MediaQuery query="(max-device-width: 1224px)">
                <div className="wrapper position-relative">
                  <div className="header-bg header-red sab-header1 sticky-top d-flex flex-column">
                    <div className="container d-flex flex-column flex-fill">
                      <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                        <TopHeader />
                        <p
                          className="mb-0 ml-auto text-light  pointer"
                          onClick={this.handleBackStack}
                        >
                          {t("ConnectionPage.twitter_connection")}
                        </p>
                        <LogoutMenu />
                      </div>
                    </div>
                  </div>
                  <div className="middle-section sab-middle-section1">
                    <TwitterLogin
                      loginUrl={`${mainURL}twitter/login`}
                      requestTokenUrl={`${mainURL}twitter/request-token`}
                      onFailure={(error) => this.onFailed(error)}
                      onSuccess={(data) => this.onSuccess(data)}
                      showIcon={true}
                    />
                  </div>
                </div>
              </MediaQuery>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(TwitterConnection));
