import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { sendTwitterPost } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";

import { connect } from "react-redux";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
class TwitterChannelSend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  checkTwitterConnection = () => {};

  postOnTwitter = () => {
    const message_id = this.props.match.params.message_id;
    apiCall(
      METHOD.QUERY,
      sendTwitterPost(getUserID(), message_id),
      (data) => {
        this.props.updateChannelMessageLink({message_link:data.message_link})

        toast.success(this.props.t(data.message));
        this.props.closeSlider();
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };
  render() {
    const { isLoading } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <a
                href="javascript;:"
                className="float-right mr-3"
                onClick={(e) => {
                  e.preventDefault();
                  this.postOnTwitter();
                }}
              >
                <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                  {t("ChannelDetail.postOnTwitter")}
                </p>

                {isLoading ? (
                  <div className="float-left rounded-40 red-bg text-center disabled">
                    <div className="spinner-border spinner-border-sm ml-1 text-blue">
                      <span className="sr-only">
                        {t("GeneralMessages.loading")}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="float-left rounded-40 red-bg text-center">
                    <img
                      className="m-0"
                      src="/images/meldung-neu.png"
                      alt="ico"
                    />
                  </div>
                )}
              </a>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  state =>
    stateToProps.appMapStateToProps(
      [stateToProps.channelDetailStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.channelDetailsDispatchToProps,
  ])
)(withRouter(withTranslation()(TwitterChannelSend)));

