/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
class PersonItem extends Component {
  ItemClick = (id) => {
    const { pathname } = this.props.location;
    const location = {
      pathname: "/person-detail/" + id,
      state: { from: { pathname: pathname } },
    };
    this.props.history.push(location);
  };

  render() {
    const { id, full_name, email, is_valid_email } = this.props.data;
    return (
      <React.Fragment>
        <a
          href="javascript;:"
          className="media"
          onClick={(e) => {
            e.preventDefault();
            const group_id_person_id =
              this.props.match.params.group_id + "-" + id;
            this.ItemClick(group_id_person_id);
          }}
          key={id}
        >
          <div className="left-icon-box small">
            <div
              className="circle"
              style={{ backgroundColor: "transparent", lineHeight: "55px" }}
            >
              {is_valid_email && is_valid_email == 1 ? (
                <i className="zmdi zmdi-check-circle zmdi-hc-fw font-size-22 text-success"></i>
              ) : (
                <i className="zmdi zmdi-alert-circle zmdi-hc-fw font-size-22 text-blue"></i>
              )}
            </div>
          </div>
          <div className="media-body pt-3 px-4">
            <h6 className="font-size-15 mb-1">{full_name && full_name}</h6>
            <p className="text-muted font-12 m-0">{email && email}</p>
          </div>

          {/* <div className="align-self-center float-right ml-auto">
           <span className="text-dark">
            <i className="zmdi zmdi-chevron-right zmdi-hc-fw font-26"></i>
       </span>
        </div> */}
        </a>
      </React.Fragment>
    );
  }
}
export default withRouter(PersonItem);
