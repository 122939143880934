/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AddSubMail from "./AddSubMail";
import EditMail from "./EditMail";
import MailDetails from "./MailDetails";
import PersonDataTypeList from "./PersonDataTypeList";
import PersonDataTypeValue from "./PersonDataTypeValue";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeStatus: 0,
      personDataTypeId:0,
      personDataTypeName:'',
      filterValue:''
    };
  }
  changeRouteStatus = (value) => {
    this.setState({
      routeStatus: value,
    });
  };

  handleClosePersonDataType=()=>{
    this.setState({
      personDataTypeId:0,
      personDataTypeName:'',
      filterValue:'',
      routeStatus: 0,
    })
  }
  handleApplyFilter =(filterValue)=>{
    console.log("handleApplyFilter -->",filterValue);
    if(filterValue==''){
      this.setState({
        routeStatus: 0,
        filterValue,
        personDataTypeName:'',
        personDataTypeId:0
      })
    }else{
      this.setState({
        routeStatus: 0,
        filterValue,
      })
    }
   
  }
  setPersonDataTypeId=(personDataTypeId)=>{
    this.setState({
      personDataTypeId,
    })
  }

  setPersonDataTypeName=(personDataTypeName)=>{
    this.setState({
      personDataTypeName,
    })
  }
  render() {
    const { routeStatus,filterValue,personDataTypeId } = this.state;
    return (
      <React.Fragment>
        <div className="wrapper position-relative">
          {routeStatus == 0 && (
            <MailDetails 
            filterValue={filterValue||''}
            personDataTypeId={personDataTypeId||0}
            handleApplyFilter={this.handleApplyFilter}
             changeRouteStatus={this.changeRouteStatus} />
          )}
          {routeStatus == 1 && (
            <AddSubMail
              item={{
                id: 1,
                name: "Group",
                description: "Group",
                identifier: "group",
                icon:
                  "iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACGSURBVHgB7ZQxDoAgDEW/xoPgGVi4/84Z4B4MWkxcNPQzVE0MbyhDm5T+X5ig4L3f0EGMcWrlZgwIN+16dW9x9WN4QDn0Et2THA62ZPFjPT1wsMfV8LjJ3zaoO639MyxPG1iw9BSxW2qoE4QQHAisRp2glJLkjYDUqPmfr+lo8GqDDHtyDTsZwyQcDhELBwAAAABJRU5ErkJggg==",
                __typename: "Listtypelist",
              }}
              changeRouteStatus={this.changeRouteStatus}
            />
          )}

          {routeStatus == 2 && (
            <AddSubMail
              item={{
                id: 2,
                name: "Simple List",
                description: "Simple List",
                identifier: "list",
                icon:
                  "iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD3SURBVHgB3ZTLCcMwDIYt51JysjNBjh2hY3ST0Ftupceemo7QTTJCRugEsW/JKalcQiiBWkLQBxEYP9AnGUn8SjEty7KjtdaFhecLl0s4TiHgOI4lHjfT2qVpCl3X1RQLimHh17iZxbN3zlmK1erDxkqA5bku3wDgxkB5Pej7vsaah3JswxUTnrE8pfoHI5tsjMm11gX+eo/XfHpusETNMAwn7/1dnACDFxioigYAOLRtW4kSvBnPpUXHlZoiKjjXZy0mFbQYl7w4iQSN4uYpkgoaxX1P7KSCRnFzD6SC9nMhfDZZKmgcDqSCxuVAKmhcTiu5oLG4B4W9ojGP+hlUAAAAAElFTkSuQmCC",
                __typename: "Listtypelist",
              }}
              changeRouteStatus={this.changeRouteStatus}
            />
          )}

          {routeStatus == 3 && (
            <EditMail changeRouteStatus={this.changeRouteStatus} />
          )}

          {routeStatus == 5 && (
            <PersonDataTypeList changeRouteStatus={this.changeRouteStatus}
            handleClosePersonDataType={()=>{this.handleClosePersonDataType()}}
            setPersonDataTypeId={(personDataTypeId)=>{this.setPersonDataTypeId((personDataTypeId))}} 
            setPersonDataTypeName={(name)=>{this.setPersonDataTypeName((name))}} 
            />
          )}

          {routeStatus == 6 && (
            <PersonDataTypeValue 
            handleApplyFilter={(filterValue)=>{this.handleApplyFilter(filterValue)}}
            personDataTypeId = {this.state.personDataTypeId} 
            personDataTypeName = {this.state.personDataTypeName} 
            handleClosePersonDataType={()=>{this.handleClosePersonDataType()}}
            changeRouteStatus={this.changeRouteStatus} />
          )}

        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(Index);
