/* eslint eqeqeq: 0 */
import { Field, Formik } from "formik";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { EditGroupQuery, GrouptypeQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";
import { CreateGroupSchema } from "../../../util/validationSchema";
import ErrorMessageFormik from "../../CommanComponents/ErrorMessageFormik";
import BrandTypeList from "../../Event/EventListDesktop/BrandTypeList";

class EditNewGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: false,
      brandList: [],
      groupType: "",
      groupImage: "",
      groupID: 0,
      isLoading: false,
    };
  }

  handleGroupSelection = (item) => {
    const { name, icon, id } = item;
    this.setState(() => {
      return {
        ...this.state,
        status: false,
        groupType: name,
        groupImage: icon,
        groupID: id,
      };
    });
  };

  closeGroupDialogue = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: false,
      };
    });
  };

  openGroupDialogue = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
      };
    });
  };

  handleAfterCreateApiCall = (
    group_id,
    grouptype_id,
    group_name,
    description,
    parent_id,
    data
  ) => {
    console.log("data.message",data);
    toast.success(this.props.t(data.message));
    let group = {
      id: group_id,
      name: group_name,
      group_type_id: grouptype_id,
      group_type_icon: this.state.groupImage,
      group_type_name: this.state.groupType,
      parent_id: parent_id,
      description: description,
    };

    if (this.props.groupDetail.parent_id == parent_id) {
      this.props.updateGroupById(group);
      this.props.updateGroupDetails(group);
    } else {
      this.props.deleteGroup(group);
    }

    this.props.handleEditGroup(true);
  };

  handleAfterBrandApiCall = (data) => {
    let brandImageDefault, brandNameDefault, groupID;
    if (data.group_type_list && data.group_type_list.langth !== 0) {
      brandNameDefault = data.group_type_list[0].name;
      brandImageDefault = data.group_type_list[0].icon;
      groupID = data.group_type_list[0].id;
    }

    const {
      group_type_icon,
      group_type_name,
      group_type_id,
    } = this.props.groupDetail;

    this.setState(() => {
      return {
        ...this.state,
        brandList: data.group_type_list,
        groupType: group_type_name ? group_type_name : brandNameDefault,
        groupImage: group_type_icon ? group_type_icon : brandImageDefault,
        groupID: group_type_id ? group_type_id : groupID,
      };
    });
  };

  handleSubmit = (values) => {
    const { group_name, description } = values;
    const { groupID } = this.state;
    const group_id = this.props.match.params.group_id;
    console.log("description;;",description);
    apiCall(
      METHOD.QUERY,
      EditGroupQuery(
        getUserID(),
        groupID,
        group_name,
        description,
        this.props.groupDetail.parent_id,
        group_id
      ),
      (data) =>
        this.handleAfterCreateApiCall(
          group_id,
          groupID,
          group_name,
          description,
          this.props.groupDetail.parent_id,
          data
        ),
      (isLoading) =>
        this.setState(() => {
          return { ...this.state, isLoading: isLoading };
        }),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  componentDidMount() {
    this.props.groupDetail.group_type_name &&
      this.setState({ groupType: this.props.groupDetail.group_type_name });

    apiCall(
      METHOD.QUERY,
      GrouptypeQuery(getUserID()),
      (data) => this.handleAfterBrandApiCall(data),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  render() {
    const { brandList, status, groupImage, groupType, isLoading } = this.state;
    const { handleEditGroup } = this.props;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <Formik
                enableReinitialize
                initialValues={{
                  group_name:
                    this.props.groupDetail.name && this.props.groupDetail.name,
                  description:
                    this.props.groupDetail.description ||''
                }}
                validationSchema={CreateGroupSchema(
                  t("GroupDetailScreen.tooShort"),
                  t("GroupDetailScreen.tooLong"),
                  t("GroupDetailScreen.mailNameErrorMessage"),
                )}
                onSubmit={(values) => {
                  this.handleSubmit(values);
                }}
              >
                {({ errors, touched, values, handleSubmit, setErrors }) => (
                <div className="d-block">
                <div
                  className="modal fade show"
                  id="NewMessage"
                  tabindex="-1"
                  aria-labelledby="NewMessageLabel"
                  aria-modal="true"
                  style={{display:'block'}}
                >
                  <div className="modal-dialog modal-lg 0modal-dialog-centered 0modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-header border-0 d-block pt-4 pb-0">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => handleEditGroup(false)}
                          >
                            <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                          </button>
                          <h5 className="modal-title" style={{marginLeft:'45px'}}>
                        {t("GroupDetailScreen.editGroup")}</h5>
                         
                          
                          {isLoading ? (
                            <div
                              className="btn btn-primary-theme group pointer"
                              onClick={handleSubmit}
                            >
                              {t("GroupDetailScreen.btnSave")}
                              <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                <span className="sr-only">
                                  {t("GeneralMessages.loading")}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="btn btn-primary-theme group pointer"
                              onClick={handleSubmit}
                            >
                              {t("GroupDetailScreen.btnSave")}
                            </div>
                          )}
                        </div>
                      
                      
                      </div>
                      <div className="modal-body">
               
                    <div className="middle-section sab-middle-section1">
                      <div className="container my-4 00z-index-99">
                        <form>
                          <div onClick={this.openGroupDialogue}
                                    className=" pointer custom-control custom-radio form-group max-170 m-auto custom-control-box text-dark text-center mb-3"
                                  >
                                    <input
                                      type="radio"
                                      name="NeuesEreignis"
                                      className="custom-control-input"
                                      checked={true}
                                    />
                                    <label
                                      className="card-body py-3 px-2 mb-0 text-center custom-control-label"
                                    >
                                      <p className="mb-2">
                                        {groupImage && (
                                          <img
                                            src={`data:image/png;base64,${
                                              groupImage && groupImage
                                            }`}
                                            alt="brand_icon"
                                            style={{width:'32px',height:'32px'}}
                                          />
                                        )}
                                      </p>
                                      <p className="font-size-14 text-nowrap mb-0">
                                      {groupType && groupType}
                                      </p>
                                    </label>
                                  </div>


                          <div className="form-group">
                            <label className="font-14">
                              {t("GroupDetailScreen.titleInternal")}
                            </label>
                            <Field
                              type="text"
                              name="group_name"
                              placeholder={t("GroupDetailScreen.titleInternal")}
                              className="form-control"
                              required
                            />
                            <ErrorMessageFormik name="group_name" />
                          </div>

                          <div className="form-group">
                            <label className="font-14">
                              {" "}
                              {t("GroupDetailScreen.description")}
                            </label>
                            <Field
                              as="textarea"
                              name="description"
                              placeholder={t("GroupDetailScreen.description")}
                              className="form-control"
                            />
                            {/* <ErrorMessageFormik name="description" /> */}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                {status &&  <BrandTypeList
                              closeBrandDialogue={this.closeGroupDialogue}
                              handleBrandSelection={this.handleGroupSelection}
                              status={status}
                              BrandList={brandList}
                            />} 
                  </div>
                  </div>
                </div>
                <div className="modal-backdrop fade show"></div>
              </div>
                )}
              </Formik>
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.groupDetailStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupDetailDispatchToProps,
    actions.groupListDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(EditNewGroup)));
