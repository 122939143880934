/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { getmailinglistForAddRacipient, MailDetailsQuery, MaillingListQuery, searchGroupDetailQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
import LoadingPage from "../../CommanComponents/LoadingPage";

class SearchMail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      mailList: [],
      personList:[],
      isLoading: false
    };
    this.searchRef = React.createRef();
  }
  changeEventSearch = e => {
    const searchValue = e.target.value;
    this.setState(
      {
        search: searchValue,
        mailList: [],
      },
      () => {
        const {routeStatus} = this.props
        if(routeStatus==0){
          this.props.setSearchMailList([]);
        }
      }
    );
  };

  handleLoader = status => {
    this.setState(() => {
      return {
        isLoading: status
      };
    });
  };

  handleSearchResponse = data => {
    this.setState(
      () => {
        return {
          mailList: data.mailing_list,
          isLoading: false
        };
      },
      () => {
        this.props.setSearchMailList(data.mailing_list);
        if (this.state.mailList.length == 0 ) {
          toast.info(
             this.props.t("GeneralMessages.searchErrorMessage") +
               "'" +
               this.state.search +
               "'"
           );
        }
      }
    );
  };

  handleMailDetailSearchResponse = data => {
    this.setState(
      () => {
        return {
          mailList: data.child_list,
          isLoading: false
        };
      },
      () => {
        this.props.setSearchMailList(data.child_list);
        if (this.state.mailList.length == 0 ) {
          toast.info(
             this.props.t("GeneralMessages.searchErrorMessage") +
               "'" +
               this.state.search +
               "'"
           );
        }
      }
    );
  };

  handlePersonSearchResponse = data => {
    this.setState(
      () => {
        return {
          personList: data.person_list,
          isLoading: false
        };
      },
      () => {
        this.props.setSearchMailList(data.person_list);
        if (this.state.personList.length == 0 ) {
          toast.info(
             this.props.t("GeneralMessages.searchErrorMessage") +
               "'" +
               this.state.search +
               "'"
           );
        }
      }
    );
  };

  loader = (<LoadingPage />);
  clearSearch = () => {
    this.searchRef.current.value = "";

    this.setState(
      () => {
        return {
          ...this.state,
          search: "",
          mailList:[]
        };
      },
      () => {
        this.props.setSearchMailList([]);
      }
    );
  };

  searchApi = () => {
    const { search } = this.state;
const {routeStatus} = this.props
if(routeStatus==0){
  apiCall(
    METHOD.QUERY,
    MaillingListQuery(getUserID(), 0, 100, search),
    (data) => this.handleSearchResponse(data),
    (isLoading) => this.handleLoader(isLoading),
    (data) => {
      toast.error(this.props.t(data.message));
    }
  );
}else if(routeStatus==1){
  apiCall(
    METHOD.QUERY,
    MailDetailsQuery(getUserID(), this.props.mail_id, search),
    (data) => this.handleMailDetailSearchResponse(data),
    (isLoading) => this.setState({ isLoading }),
    (data) => {
      toast.error(this.props.t(data.message));
    }
  );
}else if(routeStatus==2){
  apiCall(
    METHOD.QUERY,
    getmailinglistForAddRacipient(getUserID(), this.props.mailIdForPerson,search),
    (data) => this.handlePersonSearchResponse(data),
    (isLoading) => this.setState({ isLoading }),
    (data) => {
      toast.error(this.props.t(data.message));
    }
  );
}
    
  };

  render() {
    const { search } = this.state;
    const {routeStatus} = this.props
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="clearfix ">
              <div className="input-group mt-2">
                <input
                  type="text"
                  placeholder={routeStatus==2?(t("personList.searchPlaceHolder")): (t("MailList.search"))}
                  className="form-control"
                  ref={this.searchRef}
                  onChange={this.changeEventSearch}
                  onKeyPress={e =>
                    e.keyCode == 13 || (e.which == 13 && this.searchApi())
                  }
                />
                <div className="input-group-append ">
                  <span className="input-group-text bg-white input-group-append ">
                    <i
                      className="zmdi zmdi-search zmdi-hc-fw font-22 pointer"
                      onClick={this.searchApi}
                    ></i>
                    {search.length != 0 && (
                      <i
                        className="zmdi zmdi-close zmdi-hc-fw font-22 pointer"
                        onClick={this.clearSearch}
                      ></i>
                    )}
                  </span>
                </div>
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(SearchMail));
