import MediaQuery from "react-responsive";
import ManageReportTypeDesktop from "./ManageReportTypeDesktop/ReportList";
import ManageReportTypeMobile from "./ManageReportTypeMobile/Index";
import React, { Component } from 'react'
import { withRouter } from "react-router";
import { checkAdminPermission } from "../../../util/utils";

class ManageReportTypeResponsive extends Component {
  componentDidMount() {
    if(!checkAdminPermission()){
      this.props.history.push("/")
    }
  }
  render() {
    return (
      <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <ManageReportTypeDesktop />
        
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <ManageReportTypeMobile />
      </MediaQuery>
    </React.Fragment>
    )
  }
}
export default withRouter(ManageReportTypeResponsive)