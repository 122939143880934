export const objectProcessor = (state, newState) => ({ ...state, ...newState });

export const arrayProcessor = (state, newState) => [...state, newState];

export const saveToken = (data, isTokenNotExist = true) => {
  if (isTokenNotExist) {
    localStorage.setItem("token", data.token.token);
    localStorage.setItem("type", data.token.type);
  }
};

export const removeFromArray = (arr = [], index) => {
  arr.splice(index, 1);
  return arr;
};

export const pathName = () => {
  const myPath = window.location.pathname;
  return myPath;
};
export const updateSpacificValue = (array = [], index, updateObj = {}) => {
  let temp = [...array];
  temp[index] = { ...temp[index], updateObj };
  return temp;
};

export const getSaveToken = () => {
  let token = localStorage.getItem("token");
  let type = localStorage.getItem("type");
  return type + " " + token;
};

export const getHeaderToken = () => {
  let token = getSaveToken();
  return {
    headers: {
      Authorization: token,
    },
  };
};

export const setCompleteProfile = (data) => {
  localStorage.setItem("isCompleteProfile", data);
};

export const saveIsLoggedIn = (data) => {
  localStorage.setItem("isLoggedIn", data);
};

export const saveDatatoLocalStorage = (type, data) => {
  localStorage.setItem(type, data);
};

export const saveUserID = (data) => {
  localStorage.setItem("userID", data);
};

export const checkUserPermission = (key) => {
  const user_group = JSON.parse(localStorage.getItem("user_role"));
  let item = user_group && user_group.filter(
    (data) =>
      JSON.stringify(data).toLowerCase().indexOf(key.toLowerCase()) !== -1
  );
  if (item && item.length >= 1) {
    return true;
  } else {
    return false;
  }
};

export const checkAdminPermission = () => {
  const adminPermission = checkUserPermission("administration");
  return adminPermission
};

export const getValueFromLocalStorage = (param) => {
  let value = localStorage.getItem(param);
  return value;
};

export const getUserID = () => {
  let userID = localStorage.getItem("user_id");
  return userID;
};
export const getIsLoggedIn = () => {
  let isLoggedIn = localStorage.getItem("isLoggedIn");
  return isLoggedIn;
};

export const stringToHTML = (reference, id) => {
  var dom = document.createElement("span");
  dom.setAttribute("id", id);
  dom.innerHTML = reference;
  return dom;
};
export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const convertSpecialChar = (str) => {
  var map = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": '"',
    "&#039;": "'",
  };
  return str.replace(/&amp;|&lt;|&gt;|&quot;|&#039;/g, function (m) {
    return map[m];
  });
};

export const isHTML = (str) => {
  var doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};

export const saveAsVerified = (data) => {
  localStorage.setItem("verified", data);
};
export const getVerified = () => {
  let isLoggedIn = localStorage.getItem("verified");
  return isLoggedIn;
};

export const deleteApiState = (data) => {
  if (data) {
    return data.deleteApiState();
  }
};

export const deleteAuthState = (data) => {
  if (data) {
    return data.deleteAuthState();
  }
};

export const checkUser = () => {
  let user_id = getUserID();
  if (user_id) {
    return true;
  } else {
    return false;
  }
};

export const userCompleteProfile = () => {
  if (localStorage.getItem("isCompleteProfile") === 1) {
    return true;
  } else {
    return false;
  }
};

export const ToTimeStamp = (date) => {
  var datum = Date.parse(date);
  return datum / 1000;
};

export const isTokenExist = () => {
  let token = localStorage.getItem("token");
  if (token) {
    return true;
  } else {
    return false;
  }
};

export const commaSeparated = (data) => {
  let ids = "";
  data.map((item, index) => {
    if (index === data.length - 1) {
      return (ids = ids + item);
    } else {
      return (ids = ids + item + ",");
    }
  });
  return ids;
};

export const searchInArray = (
  array = [],
  searchValue = "",
  filterValue = ""
) => {
  if (searchValue.length > 2) {
    let result = array.filter((data, index) =>
      data.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    return result;
  } else {
    return array;
  }
};

export const searchInArray2 = (
  array = [],
  searchValue = "",
  filterValue = ""
) => {
  if (searchValue.length >= 1) {
    let result = array.filter((data, index) =>
      data.user_name.toLowerCase().includes(searchValue.toLowerCase())
    );
    return result;
  } else {
    return array;
  }
};
export var months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const removeDuplicateEntry = (duplicatesArray) => {
  const seen = new Set();
  const filteredArr = duplicatesArray.filter((el) => {
    const duplicate = seen.has(el.id);
    seen.add(el.id);
    return !duplicate;
  });
  return filteredArr;
};

export const getCurrentTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

// export const errorToastOptions = () => {
//   const option={className: css({
//         background: "#00FF00 !important",
//         color: "white !important",
//         fontWeight: "bold"
//       })}
  
//   return
// }

export const removeDuplicates = (originalArray, prop) => {
  var newArray = [];
  var lookupObject = {};

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  newArray = reverseArr(newArray)
  return newArray;
}

export const reverseArr=(input)=> {
  var ret = new Array;
  for(var i = input.length-1; i >= 0; i--) {
      ret.push(input[i]);
  }
  return ret;
}