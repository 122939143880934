/* eslint eqeqeq: 0 */
/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { GroupListQuery, movepersongroup } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";
import LoadingPage from "../../CommanComponents/LoadingPage";
import NoRecordFound from "../../CommanComponents/NoRecordFound";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

class AddToSubGroupList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      groupList: [],
      end: 10,
      headerMenuStatus: false,
      loader: false,
      searchGroupList: [],
      searchPersonList: [],
      sortType: 1,
      start:0,
      hasMore:true,
      breadcrumb:[],
      rootGroupId:0
    };
    this.searchRef = React.createRef();
  }

  handleLoader = (status) => {
    this.setState({
      loader: status,
    });
  };

  loader = (<LoadingPage key={0} />);

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  componentDidMount() {
    this.setState({
      rootGroupId:this.props.rootGroupId
    })
  }
  loadItem = () => {
    const { end, isLoading, hasMore,start,rootGroupId } = this.state;

    if (!isLoading && hasMore) {
      this.setState({
        isLoading: true,
      });
      apiCall(
        METHOD.QUERY,
        GroupListQuery(
          getUserID(),
          start,
          end,
          rootGroupId,
          "",
          1
        ),
        (data) => {
          this.setState({
            breadcrumb: data.breadcrumb,
          });
          this.handleGroupDetailApiResponse(data)
         
        },
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };


  handleGroupDetailApiResponse = (data) => {
    const { start,end,groupList } = this.state;
    this.setState({
      isLoading: true,
    });

    if (data.is_last == 1) {
      this.setState({
        hasMore:false
      })
    }

  let newStart =start+end
  let newGroupList = [...groupList,...data.group_list]
    this.setState((prevState) => {
      return {
        isLoading: false,
        start:newStart,
        groupList:newGroupList
      };
    });
  };
 


  handleGroupSelection = (groupId) => {
    const { id } = this.props.groupPersonDetail && this.props.groupPersonDetail;
    const group_person_id = this.props.match.params.group_person_id;
    const fields = group_person_id.split("-");
    apiCall(
      METHOD.QUERY,
      movepersongroup(getUserID(), groupId, id, 0),
      (data) => {
        toast.success(this.props.t(data.message));
        this.props.changeRouteStatus(0);
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };


  render() {
    const { end,breadcrumb,hasMore,groupList,rootGroupId } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-blue sab-header1 sticky-top">
                <div className="container pb-4">
                  <div className="py-2 d-flex align-items-center">
                    <span
                      className="pointer"
                      onClick={() => this.props.changeRouteStatus(0)}
                    >
                      <i className="zmdi zmdi-close zmdi-hc-fw font-30 text-light"></i>
                    </span>
                  </div>

                  <div className="text-center mt-2  text-light">
                    <h2 className="m-0 text-light">
                      {t("personDetail.addToSubGroup")}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="middle-section sab-middle-section1 ">
              <div className="d-flex justify-content-between align-items-center my-4 ml-2">
                        <p className="font-12 m-0">
                          <React.Fragment>
                            <a
                              href="#"
                              onClick={(e)=>{
                                e.preventDefault()
                                this.props.changeToGroupList()
                              }}
                              className="text-muted"
                              data-tip={"Start"}
                            >
                              {t("GroupListScreen.start")}
                              <ReactTooltip
                                place="top"
                                type="info"
                                effect="float"
                              />
                            </a>
                            {" > "}
                          </React.Fragment>
                          {breadcrumb &&
                            breadcrumb.map((item, index) => {
                              if (index == 0) {
                                return (
                                  <React.Fragment key={index}>
                                    <a
                                      href="javascript;:"
                                      className="text-muted"
                                      data-tip={item.name}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        // this.setState({
                                        //   start:0,
                                        //   hasMore:true,
                                        //   groupList:[]
                                        // },()=>{
                                        //   this.props.setGroupListId(item.id)
                                        // })
                                        this.setState({
                                          start:0,
                                          hasMore:true,
                                          groupList:[],
                                          rootGroupId:item.id
                                        })
                                      }}
                                    >
                                      <ReactTooltip
                                        place="top"
                                        type="info"
                                        effect="float"
                                      />
                                      {item.name}
                                    </a>
                                    {" > "}
                                  </React.Fragment>
                                );
                              } else if (
                                index != 0 &&
                                index != breadcrumb.length - 1
                              ) {
                                return (
                                  <React.Fragment key={index}>
                                    <a
                                      href="javascript;:"
                                      className="text-muted"
                                      data-tip={item.name}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        // this.setState({
                                        //   start:0,
                                        //   hasMore:true,
                                        //   groupList:[]
                                        // },()=>{
                                        //   this.props.setGroupListId(item.id)
                                        // })
                                        this.setState({
                                          start:0,
                                          hasMore:true,
                                          groupList:[],
                                          rootGroupId:item.id
                                        })
                                      }}
                                    >
                                      <ReactTooltip
                                        place="top"
                                        type="info"
                                        effect="float"
                                      />
                                      [...]
                                    </a>
                                    {" > "}
                                  </React.Fragment>
                                );
                              } else if (index == breadcrumb.length - 1) {
                                return (
                                  <React.Fragment key={index}>
                                    {item.name && item.name}
                                  </React.Fragment>
                                );
                              }
                            })}
                        </p>
                        </div>
             
             {rootGroupId!=0 &&   <InfiniteScroll
                  pageStart={end}
                  loadMore={this.loadItem}
                  hasMore={hasMore}
                  useWindow={false}
                  loader={this.loader}
                >
                  <div className="container my-4">
                    {groupList &&
                      groupList.map((data, index) => {
                        const {
                          id,
                          name,
                          group_type_icon,
                          person,
                          description,
                          has_child
                        } = data;
                        return (

                          <div
                            className="icon-box-style2 mb-2 pointer"
                            key={index}
                            onClick={() => {

                              console.log("has_child",has_child);
                              console.log("person",person);
                              if(has_child==0 ){
                              this.handleGroupSelection(id)
                              }else{
                                this.setState({
                                  start:0,
                                  hasMore:true,
                                  groupList:[],
                                  rootGroupId:id
                                })
                              }
                            }}
                          >
                            <div className="icon-box blue-bg d-flex">
                              <div className="d-flex align-items-center justify-content-center w-100">
                                <div className="rounded-50 bg-light">
                                  <img
                                    src={`data:image/png;base64,${
                                      group_type_icon && group_type_icon
                                    }`}
                                    style={{ width: "32px" }}
                                    alt="icon"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="ml-3 my-3 d-inline-block">
                              <h5 className="m-0 font-18">{name && name}</h5>
                              {person == 0 ? (
                                <p className="text-muted font-12 mb-0">
                                  {description && description}
                                </p>
                              ) : (
                                <p className="text-muted font-12 mb-0">
                                  {person && person}{" "}
                                  {t("GroupListScreen.contactPerson")}
                                </p>
                              )}
                            </div>
                          </div>
                        );
                      })}

                    {this.props.groupList &&
                      this.props.groupList.length == 0 && (
                        <NoRecordFound title="Group List" name="group list" />
                      )}
                  </div>
                </InfiniteScroll>
              }
              </div>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.groupListStateToProps,
        stateToProps.groupPersonDetailStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupListDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(AddToSubGroupList)));
