/* eslint eqeqeq: 0 */
import update from "immutability-helper";
import React, { Component } from "react";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import MultiBackend, { TouchTransition } from "react-dnd-multi-backend";
import Preview from "react-dnd-preview";
import TouchBackend from "react-dnd-touch-backend";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { changegrouporder, GroupListQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";
import CommonLoader from "../../CommanComponents/CommonLoader";
import LoadingPage from "../../CommanComponents/LoadingPage";
import RearrangePersonItem from "./RearrangeGroupItem";

const HTML5toTouch = {
  backends: [
    {
      backend: HTML5Backend,
    },
    {
      backend: TouchBackend,
      options: { enableMouseEvents: true }, // Note that you can call your backends with options
      preview: false,
      transition: TouchTransition,
    },
  ],
};

class RearrangeGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitLoader: false,
      end: 10,
      start: 0,
      hasMore: true,
      group_list: [],
    };
  }

  moveCard = (dragIndex, hoverIndex) => {
    const { group_list } = this.state;
    const dragCard = group_list[dragIndex];
    this.setState(
      update(this.state, {
        group_list: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        },
      })
    );
  };
  previewGenerator = (itemType, item, style) => {
    return <div as="h1" style={style}></div>;
  };

  loadItem = () => {
    const { end, start,  } = this.state;
      apiCall(
        METHOD.QUERY,
        GroupListQuery(getUserID(), start, end, 0, "", 2),
        (data) => {
          this.handleGroupDetailApiResponse(data);
        },
        (isLoading) => {this.handleLoader(isLoading)},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
  };
  loader = (<CommonLoader key={0} />);
  handleGroupDetailApiResponse = (data) => {
    const { end, start } = this.state;
  
    if (data.is_last == 1) {
      this.setState({
        hasMore: false,
      });
    }

    this.setState((prevState) => {
      return {
        start: start + end,
        group_list: [...prevState.group_list, ...data.group_list],
      };
    });
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        ...this.state,
        isLoading: status,
      };
    });
  };
  componentDidMount() {
    this.loadItem()
  }

  loader = (<CommonLoader key={0} />);

  handleSubmit = () => {
    let reArrangeArray = [];
    const { group_list } = this.state;

    group_list.map((item) => {
      return reArrangeArray.push({ id: item.id });
    });

    let newArray = JSON.stringify(JSON.stringify(reArrangeArray));
    apiCall(
      METHOD.QUERY,
      changegrouporder(getUserID(), newArray),
      (data) => {
        toast.success(this.props.t(data.message));
        this.props.handleRearrangeModal(true);
      },
      (isLoading) => {
        this.setState({ submitLoader: isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  render() {
    const {  submitLoader,isLoading, hasMore, group_list } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <div className="d-block">
                <div
                  className="modal fade show"
                  id="NewEvent"
                  tabindex="-1"
                  aria-labelledby="NewEventLabel"
                  aria-modal="true"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-header border-0 d-block pt-4 pb-0">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <button
                            type="button"
                            onClick={() => {
                              this.props.handleRearrangeModal(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                          </button>
                          <h5 className="modal-title">
                          {t("GroupListScreen.reArrangeGroupTitle")}
                        </h5>
                          {submitLoader ? (
                            <div className="btn btn-primary-theme group pointer">
                              {t("AdminAddUser.save")}
                              <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                <span className="sr-only">
                                  {t("GeneralMessages.loading")}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="btn btn-primary-theme group pointer"
                              onClick={this.handleSubmit}
                            >
                              {t("AdminAddUser.save")}
                            </div>
                          )}
                        </div>
                      
                      </div>
                      <div className="modal-body">

                        {isLoading && this.loader}
                        <div className="middle-section sab-middle-section1">
                          <DndProvider
                            backend={MultiBackend}
                            options={HTML5toTouch}
                          >
                            {/* <InfiniteScroll
                              pageStart={end}
                              loadMore={this.loadItem}
                              hasMore={hasMore}
                              useWindow={false}
                              loader={this.loader}
                            > */}
                              
                                {!!group_list.length &&
                                  group_list.map((item, index) => {
                                    const { id, name, description } = item;
                                    return (
                                      <RearrangePersonItem
                                        id={id}
                                        index={index}
                                        name={name}
                                        description={description}
                                        moveCard={this.moveCard}
                                        key={index}
                                      />
                                    );
                                  })}
                              
                            {/* </InfiniteScroll> */}

                            <Preview generator={this.previewGenerator} />
                          </DndProvider>
                        </div>
                      </div>
               
                      {hasMore && (
                        <div className="text-center mt-5">
                          <button
                            type="button"
                            className="btn btn-primary-theme group"
                            onClick={(e) => {
                              e.preventDefault();
                              this.loadItem();
                            }}
                          >
                            {t("HomeScreen.showMore")}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-backdrop fade show"></div>
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.groupListStateToProps],
      state
    ),
  actions.appMapDispatchToProps([actions.groupDetailPersonListDispatchToProps])
)(withTranslation()(withRouter(RearrangeGroup)));
