/* eslint eqeqeq: 0 */
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { editorStateFromRaw, MegadraftEditor } from "megadraft";
import "megadraft/dist/css/megadraft.css";
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getEmailDetails,
  updateEmailDetails,
} from "../../../../constants/apiParams";
import apiCall, { METHOD } from "../../../../constants/baseUrl";
import { getUserID } from "../../../../util/utils";
import CommonLoader from "../../../CommanComponents/CommonLoader";

class EditEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportDialogueStatus: false,
      editEmailLoader: false,
      isLoading: false,
      subject: "",
      content: "",
      identifier: "",
      editorState: editorStateFromRaw(null),
    };
  }

  componentDidMount() {
    const { id } = this.props.item;
    apiCall(
      METHOD.QUERY,
      getEmailDetails(id, getUserID()),
      (data) => {
        const { subject, content, identifier } = data;

        this.setState({
          subject,
          content,
          identifier,
          editorState: EditorState.createWithContent(stateFromHTML(content)),
        });
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleReportDialogue = () => {
    this.setState((prevState) => {
      return {
        reportDialogueStatus: !prevState.reportDialogueStatus,
      };
    });
  };

  focus = () => {
    this.editor.focus();
  };

  onChange = (editorState) => {
    this.setState({ editorState });
  };

  handleSubmit = () => {
    this.apiCallEditUser();
  };

  apiCallEditUser = () => {
    const { subject, editorState } = this.state;
    let html = stateToHTML(editorState.getCurrentContent());
    html = JSON.stringify(html);
    apiCall(
      METHOD.QUERY,
      updateEmailDetails(getUserID(), this.props.item.id, subject, html),
      (data) => {
        toast.success(this.props.t(data.message));
        this.props.handleEditEmailModal(true, {});
      },
      (isLoading) => {
        this.setState({ editEmailLoader: isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  handleSubjectChange = (e) => {
    this.setState({
      subject: e.target.value,
    });
  };
  render() {
    const { isLoading, identifier, subject, editEmailLoader } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="d-block">
              <div
                className="modal fade show"
                id="NewEvent"
                tabindex="-1"
                aria-labelledby="NewEventLabel"
                aria-modal="true"
                style={{ display: "block" }}
              >
                <div className="modal-dialog modal-lg 0modal-dialog-centered 0modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header border-0 d-block pt-4 pb-0">
                      <div className="d-flex align-items-center justify-content-between mb-4">
                        <button
                          type="button"
                          onClick={() => this.props.handleEditEmailModal()}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                        </button>

                        {editEmailLoader ? (
                          <div className="btn btn-light pointer">
                            {t("AdminAddUser.save")}
                            <div className="spinner-border spinner-border-sm ml-1 text-blue">
                              <span className="sr-only">
                                {t("GeneralMessages.loading")}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="btn btn-primary-theme pointer"
                            onClick={() => this.handleSubmit()}
                          >
                            {t("AdminAddUser.save")}
                          </div>
                        )}
                      </div>
                      <h5 className="modal-title">{t("editEmail.title")}</h5>
                    </div>
                    <div className="modal-body">
                      <div className="middle-section sab-middle-section1">
                        {isLoading && <CommonLoader />}

                        <div className="container my-4 z-index-99">
                          <div className="form-group mb-5">
                            <div className="form-group">
                              <label className="font-14">
                                {t("editEmail.identifier")}
                              </label>

                              <h6>{identifier && identifier} </h6>
                            </div>

                            <div className="form-group">
                              <label className="font-14">
                                {t("editEmail.subject")}
                              </label>

                              <input
                                type="text"
                                className="form-control"
                                onChange={this.handleSubjectChange}
                                name="subject"
                                value={subject && subject}
                              />
                            </div>

                            <div className="form-group">
                              <label className="font-14">
                                {t("editEmail.content")}
                              </label>

                              <div
                                className="pl-3 py-3 flex-fill border rounded megadraft-box"
                                onDoubleClick={() => {
                                  this.focus();
                                }}
                                id="divMegadraft"
                              >
                                <div className="w-100">
                                  <MegadraftEditor
                                    editorState={this.state.editorState}
                                    onChange={this.onChange}
                                    ref={(element) => {
                                      this.editor = element;
                                    }}
                                    onFocus={() => {
                                      document
                                        .getElementById("divMegadraft")
                                        .classList.add("megadraftOnSelect");
                                    }}
                                    onBlur={() => {
                                      document
                                        .getElementById("divMegadraft")
                                        .classList.remove("megadraftOnSelect");
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop fade show"></div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(EditEmail));
