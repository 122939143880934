/* eslint eqeqeq: 0 */
import React from "react";

export default function ChannelType(props) {
  const { handleBrandSelection, item } = props;
  const { icon, name } = item;
  return (
    <React.Fragment>
      <li
        className="nav-item pointer"
        onClick={() => handleBrandSelection(item)}
      >
        <a
          className="nav-link"
          href="javascript;:"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <img
            src={`data:image/png;base64,${icon && icon}`}
            alt="brand_icon"
            style={{ width: "18px", height: "18px", marginRight: "3px" }}
          />
          <span className="ml-2">{name && name}</span>
        </a>
      </li>
    </React.Fragment>
  );
}
