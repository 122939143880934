import { Field, Formik } from "formik";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { CreateGroupQuery, GrouptypeQuery } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";
import { CreateGroupSchema } from "../../../util/validationSchema";
import ErrorMessageFormik from "../../CommanComponents/ErrorMessageFormik";
import BrandTypeList from "../../Event/EventListMobile/BrandTypeList";

class AddSubGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: false,
      brandList: [],
      groupType: "",
      groupImage: "",
      groupTypeId: 0,
      isLoading: false,
    };
  }

  handleGroupSelection = (item) => {
    const { name, icon, id } = item;
    this.setState(() => {
      return {
        ...this.state,
        status: false,
        groupType: name,
        groupImage: icon,
        groupTypeId: id,
      };
    });
  };

  closeGroupDialogue = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: false,
      };
    });
  };

  openGroupDialogue = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
      };
    });
  };

  handleAfterCreateApiCall = (data, paranet_id) => {
    toast.success(this.props.t(data.message));
    // const {
    //   id,
    //   group_type_icon,
    //   name,
    //   description,
    //   group_type_id,
    //   group_creator_name,
    // } = data;

    // let group = {
    //   id,
    //   group_type_icon,
    //   has_child: 0,
    //   name,
    //   description,
    //   group_type_id,
    //   group_creator_name,
    //   person: 0,
    // };

    this.props.clearStateGroupDetail();
    //this.props.updateGroupList({ group_list: [group] });
    this.props.changeRouteStatus(0);
  };

  handleAfterGroupApiCall = (data) => {
    let brandImageDefault, brandNameDefault, groupTypeId;
    if (data.group_type_list && data.group_type_list.langth !== 0) {
      brandNameDefault = data.group_type_list[0].name;
      brandImageDefault = data.group_type_list[0].icon;
      groupTypeId = data.group_type_list[0].id;
    }

    this.setState(() => {
      return {
        ...this.state,
        brandList: data.group_type_list,
        groupType: brandNameDefault,
        groupImage: brandImageDefault,
        groupTypeId: groupTypeId,
      };
    });
  };

  handleSubmit = (values) => {
    const { group_name, description } = values;
    const { groupTypeId } = this.state;
    const group_id = this.props.match.params.group_id;
    apiCall(
      METHOD.QUERY,
      CreateGroupQuery(
        getUserID(),
        groupTypeId,
        group_name,
        description,
        group_id
      ),
      (data) =>
        this.handleAfterCreateApiCall(data, group_name, description, group_id),
      (isLoading) =>
        this.setState(() => {
          return { ...this.state, isLoading: isLoading };
        }),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  componentDidMount() {
    apiCall(
      METHOD.QUERY,
      GrouptypeQuery(getUserID()),
      (data) => this.handleAfterGroupApiCall(data),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  render() {
    const { brandList, status, groupType, groupImage, isLoading } = this.state;
    const { changeRouteStatus } = this.props;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <Formik
                enableReinitialize
                initialValues={{ group_name: "" ,description:''}}
                validationSchema={CreateGroupSchema(
                  t("GroupDetailScreen.tooShort"),
                  t("GroupDetailScreen.tooLong"),
                  t("GroupDetailScreen.groupNameErrorMessage"),
                  t("GroupDetailScreen.descriptionErrorMessage")
                )}
                onSubmit={(values) => {
                  this.handleSubmit(values);
                }}
              >
                {({ errors, touched, values, handleSubmit, setErrors }) => (
                  <React.Fragment>
                    <div className="header-bg header-blue sab-header1 sticky-top">
                      <div className="container">
                        <div className="py-2 d-flex align-items-center">
                          <span
                            className="pointer"
                            onClick={() => changeRouteStatus(0)}
                          >
                            <i className="zmdi zmdi-close zmdi-hc-fw font-30 text-light"></i>
                          </span>

                          {isLoading ? (
                            <div
                              className="btn btn-light font-13 ml-auto pointer disabled"
                              onClick={handleSubmit}
                            >
                              {t("GroupDetailScreen.btnSave")}
                              <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                <span className="sr-only">
                                  {t("GeneralMessages.loading")}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="btn btn-light font-13 ml-auto pointer"
                              onClick={handleSubmit}
                            >
                              {t("GroupDetailScreen.btnSave")}
                            </div>
                          )}
                        </div>
                        <div className="text-center mt-4 text-light">
                          <h2 className="m-0 text-light">
                            {" "}
                            {t("GroupDetailScreen.addsubGroup")}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="middle-section sab-middle-section1">
                      <div className="container my-4 00z-index-99">
                        <form>
                          <div className="form-group max-170 m-auto text-center">
                            <label className="font-14">
                              {" "}
                              {t("GroupDetailScreen.type")}
                            </label>
                            <div
                              className="icon-drop-bg pointer"
                              onClick={this.openGroupDialogue}
                            >
                              <div className="rounded-circle ">
                                <img
                                  src={`data:image/png;base64,${
                                    groupImage && groupImage
                                  }`}
                                  alt="ico"
                                />
                              </div>
                              <span className="font-12">
                                {groupType && groupType}
                              </span>
                            </div>

                            <BrandTypeList
                              closeBrandDialogue={this.closeGroupDialogue}
                              handleBrandSelection={this.handleGroupSelection}
                              status={status}
                              BrandList={brandList}
                            />
                          </div>

                          <div className="form-group">
                            <label className="font-14">
                              {" "}
                              {t("GroupDetailScreen.titleInternal")}
                            </label>
                            <Field
                              type="text"
                              name="group_name"
                              placeholder={t("GroupDetailScreen.titleInternal")}
                              className="form-control"
                              required
                            />
                            <ErrorMessageFormik name="group_name" />
                          </div>

                          <div className="form-group">
                            <label className="font-14">
                              {t("GroupDetailScreen.description")}
                            </label>
                            <Field
                              as="textarea"
                              name="description"
                              placeholder={t("GroupDetailScreen.description")}
                              className="form-control"
                              required
                            />
                            <ErrorMessageFormik name="description" />
                          </div>
                        </form>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </Formik>
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.eventListStateToProps,
        stateToProps.groupDetailStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.mailListDispatchToProps,
    actions.groupDetailDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(AddSubGroup)));
