import React from "react";
import MediaQuery from "react-responsive";
import MailDetailPersonListMobile from "./MobileDetailPersonListMobile/Index";
import MailDetailPersonListDesktop from "./MailDetailPersonListDesktop/Index";

export default function MailDetailPersonListResponsive() {
  return (
    <React.Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <MailDetailPersonListDesktop />
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <MailDetailPersonListMobile />
      </MediaQuery>
    </React.Fragment>
  );
}
