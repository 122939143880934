/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { getpersondatatypefromfilter } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
import LoadingPage from "../../CommanComponents/LoadingPage";
class PersonDataTypeList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      personDataTypeList: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    const { personDataTypeList } = this.state;
    if (personDataTypeList?.length == 0) {
      this.loadPersonDataTypeList();
    }
  }

  loadPersonDataTypeList = () => {
    apiCall(
      METHOD.QUERY,
      getpersondatatypefromfilter(getUserID()),
      (data) => this.handlePersonDataTypeResponse(data),
      (isLoading) => {
        this.setState({ isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handlePersonDataTypeResponse = (data) => {
    this.setState({
      personDataTypeList: data.person_data_type_list,
    });
  };

  loader = (<LoadingPage key={0} />);

  render() {
    const { personDataTypeList } = this.state;

    console.log("name", this.props.shahil);
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
            <div className="sticky-top bg-white">
              <div className="container pb-1 mt-2">
                <div className="py-1 d-flex align-items-center text-center">
                  <span
                    className="pointer"
                    onClick={() => this.props.handleClosePersonDataType()}
                  >
                    <i className="zmdi zmdi-close zmdi-hc-fw font-28"></i>
                  </span>

                  <div className="text-center ml-1">
                  <h5>
                    {t("mailDetailPersonListFilter.filterPerson")}
                  </h5>
                </div>
                </div>
              </div>
            </div>
          
          
            <div className="middle-section sab-middle-section1 bg-white">
               
                
                  <div className="container my-3 00z-index-99">
                    {personDataTypeList &&
                      personDataTypeList.map((item, index) => {
                        const { id, name } = item;
                        return (
                          <div className="form-group pointer mb-2" key={index} onClick={()=>{
                            this.props.changeFilterRouteStatus(4);
                          this.props.setPersonDataTypeId(id);
                          this.props.setPersonDataTypeName(name);
                          }}>
                            <div className="d-flex align-items-center justify-content-between border-bottom pb-1 mb-1">
                              <label className="text-dark font-14">
                                {name && name}
                              </label>
                              <i className="zmdi zmdi-chevron-right zmdi-hc-fw font-22"></i>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                
            </div>
            <div className="bottom-button">
                <button type="button" className="btn btn-warning btn-block rounded-0 text-white text-capitalize"
                >
                   <span><i className="fa fa-chevron-up"> </i></span>
                 {personDataTypeList.length} {t("mailDetailPersonListFilter.showContacts")}
                  </button>
            </div>
          </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(withRouter(PersonDataTypeList));
