/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import {
  getallpersondatatypevaluesfromfilter,
  getallpersondatatypevaluesfromgroupfilter,
  messageMaillingListQuery,
  removemessagelist,
  removepersonmessagelist,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import LoadingPage from "../../CommanComponents/LoadingPage";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; //Import css
import { getUserID } from "../../../util/utils";

class PersonDataTypeValue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      selectedValue: [],
    };
  }

  componentDidMount() {
    const {list} = this.state
    if(list?.length==0){
      this.loadPersonDataTypeValue();
    }
  }

  loadPersonDataTypeValue = () => {
    const group_id = this.props.match.params.group_id;

    apiCall(
      METHOD.QUERY,
      getallpersondatatypevaluesfromgroupfilter(
        getUserID(),
        this.props.personDataTypeId,
        group_id
      ),
      (data) => this.handlePersonDataTypeResponse(data),
      (isLoading) => {this.setState({
        isLoading
      })},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handlePersonDataTypeResponse = (data) => {
    this.setState({
      list: data.list,
    });
  };

  handleCheckBox = (e, value) => {
    let selectedValue = this.state.selectedValue;
    if (e.target.checked) {
      selectedValue.push(value);
    } else {
      selectedValue = selectedValue.filter((e) => e !== value);
    }
    this.setState({
      selectedValue,
    });
  };

  loader = (<LoadingPage key={0} />);

  render() {
    const { list, selectedValue ,isLoading} = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
         
         <div className="sticky-top bg-white">
                <div className="container pb-2 mt-2">
                  <div className="py-2 d-flex align-items-center">
                    <span
                      className="pointer"
                      onClick={() => this.props.changeRouteStatus(4)}
                    >
                      <i className="zmdi zmdi-arrow-back zmdi-hc-fw font-28"></i>
                    </span>
                    <div className="text-center ml-1">
                      <h5>
                        {this.props.personDataTypeName}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
          
              <div className="middle-section sab-middle-section1 bg-white">
                <div className="container 00z-index-99">
                  <div className="mt-2">
                    {isLoading && this.loader}
                    {list &&
                      list.map((item, index) => {
                        const { value } = item;

                        return (
                          <>
                            <div className="icon-box-style3 " key={index}>
                              <div className="ml-3 d-flex">
                                <div className="d-flex w-100 align-items-center">
                                  <div className="align-self-center">
                                    <label className="checkbox-container custom-checkbox-container mb-0">
                                    <div className="align-self-center">
                                      {value == "Yes" ? (
                                        <h5 className=" font-18 d-block">
                                          {t("mailDetailPersonListFilter.yes")}{" "}
                                        </h5>
                                      ) : value == "No" ? (
                                        <h5 className=" font-18 d-block">
                                          {t("mailDetailPersonListFilter.no")}{" "}
                                        </h5>
                                      ) : (
                                        <h5 className=" font-18 d-block">
                                          {value}
                                        </h5>
                                      )}
                                    </div>
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          this.handleCheckBox(e, value)
                                        }
                                        checked={selectedValue.includes(value)}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                  
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="bottom-button">
                <button
                  type="button"
                  className="btn btn-info btn-block rounded-0 text-white text-capitalize"
                  onClick={() => {
                    const { selectedValue } = this.state;
                    const value = selectedValue.toString();
                    console.log("value", value);
                    this.props.handleApplyFilter(value);
                  }}
                >
                  <span>
                    <i className="fa fa-chevron-up"> </i>
                  </span>
                  {selectedValue.length}{" "}
                  {t("mailDetailPersonListFilter.showContacts")}
                </button>
              </div>
          
           
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.channelDetailStateToProps],
      state
    ),
  actions.appMapDispatchToProps([])
)(withRouter(withTranslation()(PersonDataTypeValue)));
