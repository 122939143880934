import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deletePersonMailQuery,
  getallpersonmailinglistgroup,

  getmailinglistgroupForFilter,
  getmailinglistgroupMailDetail
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import { getUserID } from "../../../util/utils";
import GroupItem from "./GroupItem";

class MailDetailPersonList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      headerMenuStatus: false,
      list_id: 0,
      mail_id: 0,
      data: { person_list: [], group_list: [], breadcrumb: [] },
      searchData: { person_list: [], group_list: [], breadcrumb: [] },
      searchValue: "",
      breadcrumb: [],
    };
  }

  componentDidMount() {
    const list_id = this.props.match.params.mail_id;

    this.setState({
      list_id: list_id,
    });

    const personDataTypeId = this.props.personDataTypeId;
    if (personDataTypeId && personDataTypeId != 0) {
      apiCall(
        METHOD.QUERY,
        getmailinglistgroupForFilter(
          getUserID(),
          list_id,
          personDataTypeId,
          this.props.filterValue
        ),
        (data) => {
          this.setState({
            header_list: data.mailing_list,
            group_list: data.group_list,
            breadcrumb: data.breadcrumb,
            data,
          });
        },
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    } else {
      apiCall(
        METHOD.QUERY,
        getallpersonmailinglistgroup(getUserID(), list_id, ""),
        (data) => {
          this.setState({
            group_list: data.group_list,
            data,
          });
        },
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  }

  handleAfterApiCall = (data) => {
    this.setState(() => {
      return {
        ...this.state,
        mailList: data.mailing_list,
      };
    });
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  handleChangeMail = (id) => {
    this.setState(
      {
        list_id: id,
      },
      () => {
        const { mail_id } = this.state;
        this.props.history.push(
          "/mail-details-personlist/" + id + "-" + mail_id
        );
        this.loadPersonList(id);
      }
    );
  };

  loadPersonList = (mail_id) => {
    apiCall(
      METHOD.QUERY,
      getmailinglistgroupMailDetail(
        getUserID(),
        mail_id,
        this.props.personDataTypeId,
        this.props.filterValue
      ),
      (data) => {
        this.props.storeApiData(data);
        this.setState({
          header_list: data.mailing_list,
          group_list: data.group_list,
          breadcrumb: data.breadcrumb,
          data,
        });
        // this.props.getMailDetails(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleDelete = (item) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to delete this person.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              deletePersonMailQuery(getUserID(), item.list_id, item.id),
              (data) => {
                toast.success(this.props.t(data.message));
              },
              (isLoading) => this.handleLoader(),
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  updatePersonCount = (group_id) => {
    let temp_data = this.state.data;
    let group_list = temp_data.group_list;
    const index = group_list.findIndex((group) => group.id == group_id);
    group_list[index].person = group_list[index].person - 1;
    temp_data.group_list = group_list;
    this.setState({ data: temp_data });
  };

  delete_group_item = (id) => {
    let searchData = this.state.searchData;
    let searchGroupList = searchData.group_list;
    if (searchGroupList.length != 0 && this.state.searchValue != 0) {
      let after_delete_group_list = searchGroupList.filter((item) => {
        return item.id != id;
      });

      let after_delete_group_list1 = this.state.group_list.filter((item) => {
        return item.id != id;
      });

      searchData.group_list = after_delete_group_list;
      this.setState({
        searchData,
        group_list: after_delete_group_list1,
      });
    } else {

      let after_delete_group_list = this.state.group_list.filter((item) => {
        return item.id != id;
      });

      this.setState({
        group_list: after_delete_group_list,
      });
    }
  };

  handleAlphabaticeSort = (alphabet) => {
    const { group_list } = this.state;
    let index = -1;
    for (let i = 0; i < group_list.length; i++) {
      const group = group_list[i];
      let name = group.name;
      name = name.charAt(0).toUpperCase();
      if (alphabet == name && index == -1) {
        index = i;
      }
    }
    if (index != -1) {
      const divID = `groupDiv${index}`;
      const section = document.getElementById(divID);
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  handleAlphabatice = (name, index, list) => {
    name = name.charAt(0).toUpperCase() + name.slice(1);
    let resulAlpha = "";
    if (index == 0) {
      resulAlpha = name.charAt(0);
    } else {
      let oldName = list[index - 1].name;
      oldName = oldName.charAt(0).toUpperCase() + oldName.slice(1);
      const digit1 = oldName.charAt(0);
      const digit2 = name.charAt(0);

      if (digit1 != digit2) {
        resulAlpha = digit2;
      } else {
        resulAlpha = "";
      }
    }
    return resulAlpha;
  };

  render() {
    const {
      header_list,
      group_list,
      data,
      list_id,
      searchData,
      breadcrumb,
    } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="container mt-4 z-index-99">
              <div className="d-flex align-items-center justify-content-between ">
                <div className="clearfix">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/mail-list">
                          {t("MailDetailScreen.mailList")}
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item pointer"
                        aria-current="page"
                      >
                        {data.mailing_name && data.mailing_name}
                      </li>
                    </ol>
                  </nav>
                  </div>

                <ul className="list-inline grid-view-list m-0">
                  <li className="list-inline-item mr-3">
                    <a
                      className="pointer"
                      href="javascript;:"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.changeRouteStatus(5);
                      }}
                    >
                      <i className="fa fa-filter font-30 text-dark"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="d-flex"></div>

              {searchData.group_list.length == 0 &&
                group_list &&
                group_list.map((item, index) => {
                  return (
                    <GroupItem
                      delete_group_item={this.delete_group_item}
                      updatePersonCount={this.updatePersonCount}
                      key={index}
                      item={item}
                      index={index}
                    />
                  );
                })}

              {searchData.group_list.length != 0 &&
                this.state.searchValue != "" &&
                searchData.group_list.map((item, index) => {
                  return (
                    <GroupItem
                      delete_group_item={this.delete_group_item}
                      updatePersonCount={this.updatePersonCount}
                      key={index}
                      item={item}
                      index={index}
                    />
                  );
                })}

              {this.state.searchValue != "" && (
                <div className="mt-2 ">
                  <h3>{t("GroupListScreen.pluralPerson")}</h3>
                </div>
              )}

              {searchData.person_list &&
                searchData.person_list.map((item, index) => {
                  const { id, name, email } = item;
                  return (
                    <div className="container mt-4 d-block" key={index}>
                      {this.handleAlphabatice(
                        name,
                        index,
                        searchData.person_list
                      ) != "" && (
                        <div className="rounded-40 yellow-bg float-left ml-5p z-index-99">
                          <span className="text-light">
                            {this.handleAlphabatice(
                              name,
                              index,
                              searchData.person_list
                            )}
                          </span>
                        </div>
                      )}
                      <div className="ml-65p pt-1 0d-flex">
                        <div className="w-100 mb-4">
                          <a
                            href="javascript;:"
                            className="text-dark float-right mt-1 pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleDelete(item);
                              // this.handleDelete(item);
                            }}
                          >
                            <i className="zmdi zmdi-delete zmdi-hc-fw font-24"></i>
                          </a>
                          <h6 className="m-0">{name && name}</h6>
                          <p className="text-muted font-12 mb-2">
                            {email && email}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {this.state.searchValue == "" &&
                searchData.person_list.length == 0 &&
                data.person_list &&
                data.person_list.map((item, index) => {
                  const { id, name, email } = item;
                  return (
                    <div className="container mb-4 d-block">
                      <div className="ml-45p pt-1 0d-flex">
                        <div className="w-100 mb-4">
                          <a
                            href="javascript;:"
                            className="text-dark float-right mt-1 pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleDelete(item);
                            }}
                          >
                            <i className="zmdi zmdi-delete zmdi-hc-fw font-24"></i>
                          </a>
                          <h6 className="m-0">{name && name}</h6>
                          <p className="text-muted font-12 mb-2">
                            {email && email}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.mailDetailStateToProps,
        stateToProps.mailPersonListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.mailDetailsDispatchToProps,
    actions.mailPersonListDispatchToProps,
  ])
)(withTranslation()(withRouter(MailDetailPersonList)));
