/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  adminChannelTypeList,
  getAdminchanneldata,
  updateAdminChannel,
} from "../../../../constants/apiParams";
import apiCall, { METHOD } from "../../../../constants/baseUrl";
import { getUserID, saveDatatoLocalStorage } from "../../../../util/utils";
import Footer from "../../../Footer/FooterMobile";

class EditChannel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportDialogueStatus: false,
      name: "",
      identifier: "",
      description: "",
      is_active: 0,
      sms_api_username: "",
      sms_api_key: "",
      sms_test_phonenumber: "",
      sms_from_email: "",
      sms_source: "",
      sms_country: "",
      sms_custom_string: "",
      t2s_api_username: "",
      t2s_api_key: "",
      t2s_test_phonenumber: "",
      t2s_voice: "",
      t2s_language: "",
      t2s_custom_string: "",
      channel_type_Image: "",
      channel_type_name: "",
      channel_type_id: 0,
      channel_type_identifier: "",
      chaanne_type_list: [],
      loading: false,
      tempResponse: {},
      channelID: 0,
      authToken: "",
      authSecretKey: "",
      profileLink: "",
      isConnected: 0,
      fbIsConneced: 0,
      fbAccessToken: 0,
      fbPageId: 0,
    };
  }

  componentDidMount() {
    const tempResponse = this.props.tempResponse;
    let id = 0;

    if (
      Object.keys(tempResponse).length !== 0 &&
      tempResponse.constructor === Object
    ) {
      console.log("in object Itemid");
      id = tempResponse.channelId;
    } else {
      console.log("props item id");
      id = this.props.item.id;
    }

    this.setState({
      channelID: id,
    });
    apiCall(
      METHOD.QUERY,
      getAdminchanneldata(getUserID(), id),
      (data) => this.getDetail(data, tempResponse),
      (isLoading) => {
        this.setState({ isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  getDetail = (data, _tempResponse) => {
    const {
      channel_type_icon,
      channel_type_identifier,
      settings,
      channel_type_id,
      channel_type_name,
    } = data;
    let name, description, identifier, is_active;
    name = data.name;
    description = data.description;
    identifier = data.identifier;
    is_active = data.is_active;

    let sms_api_username = "",
      sms_api_key = "",
      sms_test_phonenumber = "",
      sms_from_email = "",
      sms_source = "",
      sms_country = "",
      sms_custom_string = "",
      t2s_api_key = "",
      t2s_api_username = "",
      t2s_custom_string = "",
      t2s_language = "",
      t2s_test_phonenumber = "",
      authToken = "",
      authSecretKey = "",
      profileLink = "",
      isConnected = 0,
      fbIsConneced = 0,
      fbAccessToken = 0,
      fbPageId = 0,
      t2s_voice = "",
      account_sid = "",
      auth_token = "",
      language = "",
      iso_country = "",
      twilio_number = "",
      page_id="",
      number = "";
    let tempResponse = {};
    if (channel_type_identifier == "sms") {
      let temp_settings = JSON.parse(settings);
      sms_api_username = temp_settings.api_username;
      sms_api_key = temp_settings.api_key;
      sms_test_phonenumber = temp_settings.test_phonenumber;
      sms_from_email = temp_settings.from_email;
      sms_source = temp_settings.source;
      sms_country = temp_settings.country;
      sms_custom_string = temp_settings.custom_string;
    } else if (channel_type_identifier == "text2speech") {
      let temp_settings = JSON.parse(settings);
      t2s_api_key = temp_settings.api_key;
      t2s_api_username = temp_settings.api_username;
      t2s_custom_string = temp_settings.custom_string;
      t2s_language = temp_settings.language;
      t2s_test_phonenumber = temp_settings.test_phonenumber;
      t2s_voice = temp_settings.voice;
    } else if (channel_type_identifier == "twilio") {
      let temp_settings = JSON.parse(settings);
      account_sid = temp_settings.account_sid;
      auth_token = temp_settings.auth_token;
      language = temp_settings.language;
      iso_country = temp_settings.iso_country;
      twilio_number = temp_settings.twilio_number;
      number = temp_settings.number;
    } else if (channel_type_identifier == "twitter") {
      if (
        Object.keys(_tempResponse).length !== 0 &&
        _tempResponse.constructor === Object
      ) {
        tempResponse = _tempResponse;
        const textValueObject = tempResponse.textValueObject;
        name = textValueObject.name;
        description = textValueObject.description;
        is_active = textValueObject.is_active;
        identifier = textValueObject.identifier;
      } else {
        let temp_settings = JSON.parse(settings);
        authToken = temp_settings.oauth_token;
        profileLink = temp_settings.profile_link;
        isConnected = temp_settings.is_connected;
        authSecretKey = temp_settings.oauth_token_secret;
        tempResponse.oauth_token = authToken;
        tempResponse.oauth_token_secret = authSecretKey;
        tempResponse.isConnected = isConnected;
        tempResponse.profileLink = profileLink;
        tempResponse.channelType = "twitter";
      }
    } else if (channel_type_identifier == "facebook") {
      if (
        Object.keys(_tempResponse).length !== 0 &&
        _tempResponse.constructor === Object
      ) {
        tempResponse = _tempResponse;
        const textValueObject = tempResponse.textValueObject;
        name = textValueObject.name;
        description = textValueObject.description;
        is_active = textValueObject.is_active;
        identifier = textValueObject.identifier;
      } else {
        let temp_settings = JSON.parse(settings);

        tempResponse.user_access_token = temp_settings.user_access_token
        tempResponse.page_id = temp_settings.page_id
        tempResponse.profileLink = temp_settings.profile_link
        tempResponse.isConnected = temp_settings.fbisConnected

        console.log("tempResponse --<>",tempResponse);

        fbIsConneced = temp_settings.fbisConnected;
        profileLink = temp_settings.profile_link;
        fbPageId = temp_settings.page_id;
       fbAccessToken = temp_settings.user_access_token;
        tempResponse.channelType = "facebook";
      }
    }

    this.setState(
      {
        name,
        isConnected,
        page_id,
        description,
        identifier,
        channel_type_id,
        channel_type_name,
        channel_type_Image: channel_type_icon,
        channel_type_identifier,
        is_active,
        sms_api_username,
        sms_api_key,
        sms_test_phonenumber,
        sms_from_email,
        sms_source,
        sms_country,
        sms_custom_string,
        t2s_api_key,
        t2s_api_username,
        t2s_custom_string,
        t2s_language,
        t2s_test_phonenumber,
        t2s_voice,
        tempResponse,
        authToken,
        authSecretKey,
        profileLink,
        isConnected,
        fbIsConneced,
        fbAccessToken,
        fbPageId,
        account_sid,
        auth_token,
        language,
        iso_country,
        twilio_number,
        number,
      },
      () => {
        apiCall(
          METHOD.QUERY,
          adminChannelTypeList(getUserID()),
          (data) => {
            this.setState({
              chaanne_type_list: data.channel_type_list,
            });
          },
          (isLoading) => this.handleLoader(isLoading),
          (data) => {
            toast.error(this.props.t(data.message));
          }
        );
      }
    );
  };

  handleTextChange = (e, type) => {
    if (type == "name") {
      this.setState({
        name: e.target.value,
      });
    } else if (type == "identifier") {
      this.setState({
        identifier: e.target.value,
      });
    } else if (type == "description") {
      this.setState({
        description: e.target.value,
      });
    } else if (type == "sms_api_key") {
      this.setState({
        sms_api_key: e.target.value,
      });
    } else if (type == "sms_api_username") {
      this.setState({
        sms_api_username: e.target.value,
      });
    } else if (type == "sms_country") {
      this.setState({
        sms_country: e.target.value,
      });
    } else if (type == "sms_custom_string") {
      this.setState({
        sms_custom_string: e.target.value,
      });
    } else if (type == "sms_from_email") {
      this.setState({
        sms_from_email: e.target.value,
      });
    } else if (type == "sms_source") {
      this.setState({
        sms_source: e.target.value,
      });
    } else if (type == "sms_test_phonenumber") {
      this.setState({
        sms_test_phonenumber: e.target.value,
      });
    } else if (type == "t2s_api_key") {
      this.setState({
        t2s_api_key: e.target.value,
      });
    } else if (type == "t2s_api_username") {
      this.setState({
        t2s_api_username: e.target.value,
      });
    } else if (type == "t2s_custom_string") {
      this.setState({
        t2s_custom_string: e.target.value,
      });
    } else if (type == "t2s_language") {
      this.setState({
        t2s_language: e.target.value,
      });
    } else if (type == "t2s_test_phonenumber") {
      this.setState({
        t2s_test_phonenumber: e.target.value,
      });
    } else if (type == "t2s_voice") {
      this.setState({
        t2s_voice: e.target.value,
      });
    } else if (type == "account_sid") {
      this.setState({
        account_sid: e.target.value,
      });
    } else if (type == "auth_token") {
      this.setState({
        auth_token: e.target.value,
      });
    } else if (type == "twilio_number") {
      this.setState({
        twilio_number: e.target.value,
      });
    } else if (type == "iso_country") {
      this.setState({
        iso_country: e.target.value,
      });
    }
  };

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleReportDialogue = () => {
    this.setState((prevState) => {
      return {
        reportDialogueStatus: !prevState.reportDialogueStatus,
      };
    });
  };

  setFieldType = (event) => {
    this.setState({
      field_type: event.target.value,
    });
  };

  set_is_active = (event) => {
    if (event.target.checked) {
      this.setState({
        is_active: 1,
      });
    } else {
      this.setState({
        is_active: 0,
      });
    }
  };

  validateInputs = () => {
    const {
      name,
      identifier,
      sms_api_username,
      sms_api_key,
      sms_test_phonenumber,
      sms_from_email,
      sms_source,
      sms_country,
      sms_custom_string,
      t2s_api_username,
      t2s_api_key,
      t2s_test_phonenumber,
      t2s_voice,
      t2s_language,
      channel_type_identifier,
      t2s_custom_string,
      auth_token,
      twilio_number,
      account_sid,
      language,
      number,
      iso_country,
    } = this.state;
    let validationStatus = false;

    if (!name) {
      toast.info(this.props.t("ManageChannel.nameValidation"));
    } else if (!identifier) {
      toast.info(this.props.t("ManageChannel.identifierValidation"));
    } else if (!sms_api_username && channel_type_identifier == "sms") {
      toast.info(this.props.t("ManageChannel.sms_api_usernameValidation"));
    } else if (!sms_api_key && channel_type_identifier == "sms") {
      toast.info(this.props.t("ManageChannel.sms_api_keyValidation"));
    } else if (!sms_test_phonenumber && channel_type_identifier == "sms") {
      toast.info(this.props.t("ManageChannel.sms_test_phonenumberValidation"));
    } else if (!sms_from_email && channel_type_identifier == "sms") {
      toast.info(this.props.t("ManageChannel.sms_from_emailValidation"));
    } else if (!sms_source && channel_type_identifier == "sms") {
      toast.info(this.props.t("ManageChannel.sms_sourceValidation"));
    } else if (!sms_country && channel_type_identifier == "sms") {
      toast.info(this.props.t("ManageChannel.sms_countryValidation"));
    } else if (!sms_custom_string && channel_type_identifier == "sms") {
      toast.info(this.props.t("ManageChannel.sms_custom_stringValidation"));
    } else if (!t2s_api_username && channel_type_identifier == "text2speech") {
      toast.info(this.props.t("ManageChannel.t2s_api_usernameValidation"));
    } else if (!t2s_api_key && channel_type_identifier == "text2speech") {
      toast.info(this.props.t("ManageChannel.t2s_api_keyValidation"));
    } else if (
      !t2s_test_phonenumber &&
      channel_type_identifier == "text2speech"
    ) {
      toast.info(this.props.t("ManageChannel.t2s_test_phonenumberValidation"));
    } else if (!t2s_voice && channel_type_identifier == "text2speech") {
      toast.info(this.props.t("ManageChannel.t2s_voiceValidation"));
    } else if (!t2s_language && channel_type_identifier == "text2speech") {
      toast.info(this.props.t("ManageChannel.t2s_languageValidation"));
    } else if (!t2s_custom_string && channel_type_identifier == "text2speech") {
      toast.info(this.props.t("ManageChannel.t2s_custom_stringValidation"));
    } else if (!account_sid && channel_type_identifier == "twilio") {
      toast.info(this.props.t("ManageChannel.account_sid_Validation"));
    } else if (!auth_token && channel_type_identifier == "twilio") {
      toast.info(this.props.t("ManageChannel.auth_token_Validation"));
    } else if (!twilio_number && channel_type_identifier == "twilio") {
      toast.info(this.props.t("ManageChannel.twilio_number_Validation"));
    } else if (!language && channel_type_identifier == "twilio") {
      toast.info(this.props.t("ManageChannel.language_Validation"));
    } else if (!iso_country && channel_type_identifier == "twilio") {
      toast.info(this.props.t("ManageChannel.iso_country_Validation"));
    } else if (!number && channel_type_identifier == "twilio") {
      toast.info(this.props.t("ManageChannel.number_Validation"));
    } else {
      validationStatus = true;
    }
    return validationStatus;
  };

  set_is_default = (event) => {
    if (event.target.checked) {
      this.setState({
        is_default: 1,
      });
    } else {
      this.setState({
        is_default: 0,
      });
    }
  };

  createChannel = () => {
    const is_validate = this.validateInputs();

    const tempResponse = this.props.tempResponse;
    let id = 0;

    if (
      Object.keys(tempResponse).length !== 0 &&
      tempResponse.constructor === Object
    ) {
      id = tempResponse.channelId;
    } else {
      id = this.props.item.id;
    }

    if (is_validate) {
      const {
        name,
        identifier,
        description,
        is_active,
        sms_api_username,
        sms_api_key,
        sms_test_phonenumber,
        sms_from_email,
        sms_source,
        sms_country,
        sms_custom_string,
        t2s_api_username,
        t2s_api_key,
        t2s_test_phonenumber,
        t2s_voice,
        t2s_language,
        t2s_custom_string,
        channel_type_identifier,
        channel_type_id,
        auth_token,
        twilio_number,
        account_sid,
        language,
        number,
        iso_country,
      } = this.state;
      let settings = {};

      if (channel_type_identifier == "sms") {
        settings["api_username"] = sms_api_username;
        settings["api_key"] = sms_api_key;
        settings["test_phonenumber"] = sms_test_phonenumber;
        settings["from_email"] = sms_from_email;
        settings["custom_string"] = sms_custom_string;
        settings["source"] = sms_source;
        settings["country"] = sms_country;
      } else if (channel_type_identifier == "text2speech") {
        settings["api_username"] = t2s_api_username;
        settings["api_key"] = t2s_api_key;
        settings["test_phonenumber"] = t2s_test_phonenumber;
        settings["voice"] = t2s_voice;
        settings["language"] = t2s_language;
        settings["custom_string"] = t2s_custom_string;
      } else if (channel_type_identifier == "twilio") {
        settings["account_sid"] = account_sid;
        settings["auth_token"] = auth_token;
        settings["twilio_number"] = twilio_number;
        settings["language"] = language;
        settings["number"] = number;
        settings["iso_country"] = iso_country;
      } else if (channel_type_identifier == "twitter") {
        settings["oauth_token"] = tempResponse.oauth_token;
        settings["oauth_token_secret"] = tempResponse.oauth_token_secret;
        settings["profile_link"] = tempResponse.profileLink;
        settings["is_connected"] = tempResponse.isConnected;
      } else if (channel_type_identifier == "facebook") {
        settings["fbisConnected"] = tempResponse.isConnected;
        settings["profile_link"] = tempResponse.profileLink;
        settings["page_id"] = tempResponse.page_id;
        settings["user_access_token"] = tempResponse.user_access_token;
        
      }
      console.log("tempResponse in update", tempResponse);
      apiCall(
        METHOD.QUERY,
        updateAdminChannel(
          getUserID(),
          id,
          name,
          description,
          identifier,
          channel_type_id,
          JSON.stringify(JSON.stringify(settings)),
          is_active
        ),
        (data) => {
          this.handleCreateResponse(data, channel_type_identifier);
        },
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  handleReportSelection = (item) => {
    const { name, secondary_icon, id, identifier } = item;

    this.setState({
      reportDialogueStatus: false,
      channel_type_Image: secondary_icon,
      channel_type_name: name,
      channel_type_id: id,
      channel_type_identifier: identifier,
    });
  };

  handleCreateResponse = (data) => {
    toast.success(this.props.t(data.message));
    this.props.changeRouteStatus(0);
    window.location.reload();
  };
  render() {
    const {
      name,
      identifier,
      description,
      is_active,
      sms_api_username,
      sms_api_key,
      sms_test_phonenumber,
      sms_from_email,
      sms_source,
      sms_country,
      sms_custom_string,
      t2s_api_username,
      t2s_api_key,
      t2s_test_phonenumber,
      t2s_voice,
      t2s_language,
      t2s_custom_string,
      channel_type_identifier,
      channel_type_Image,
      loading,
      fbIsConneced,
      tempResponse,
      isLoading,
      channelID,
      account_sid,
      auth_token,
      language,
      iso_country,
      twilio_number,
      number,
    } = this.state;
    const { isConnected, profileLink,page_id } = tempResponse && tempResponse;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-green sab-header1 sticky-top">
                <div className="container">
                  <div className="py-2 d-flex align-items-center">
                    <span>
                      <i
                        className="zmdi zmdi-close zmdi-hc-fw font-30 text-light pointer"
                        onClick={() => this.props.changeRouteStatus(0, {})}
                      ></i>
                    </span>

                    {loading ? (
                      <div className="btn btn-light font-13 ml-auto pointer">
                        {t("AdminAddUser.save")}
                        <div className="spinner-border spinner-border-sm ml-1 text-blue">
                          <span className="sr-only">
                            {t("GeneralMessages.loading")}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="btn btn-light font-13 ml-auto pointer"
                        onClick={() => {
                          this.createChannel();
                        }}
                      >
                        {t("AdminAddUser.save")}
                      </div>
                    )}
                  </div>
                  <div className="text-center mt-4 text-light">
                    <h2 className="m-0 text-light">
                      {t("ManageChannel.editItemTitle")}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="middle-section sab-middle-section1">
                <div className="container my-4 z-index-99">
                  <div className="form-group max-170 m-auto text-center">
                    <a
                      href="javascript;:"
                      className="clearfix text-dark"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReportDialogue();
                      }}
                    >
                      <label className="font-14">
                        {t("AddNewChannel.type")}
                      </label>
                      <div className="icon-drop-bg pointer">
                        <div className="rounded-circle">
                          <img
                            src={`data:image/png;base64,${
                              channel_type_Image && channel_type_Image
                            }`}
                            alt="ico"
                          />
                        </div>

                        <span className="font-12">
                          {this.state.channel_type_name &&
                            this.state.channel_type_name}
                        </span>
                      </div>
                    </a>
                  </div>

                  {/* <ChannelTypeList
                              closeBrandDialogue={this.handleReportDialogue}
                              handleBrandSelection={this.handleReportSelection}
                              status={this.state.reportDialogueStatus}
                              BrandList={chaanne_type_list}
                            /> */}

                  <div className="form-group mb-5">
                    <div className="form-group">
                      <div className="container my-4 00z-index-99">
                        <form>
                          <div className="form-group">
                            <label className="font-14">
                              {t("ManageChannel.nametitle")}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => this.handleTextChange(e, "name")}
                              name="name"
                              value={name && name}
                              placeholder={t("ManageChannel.namePlaceHolder")}
                            />
                          </div>

                          <div className="form-group">
                            <label className="font-14">
                              {t("ManageChannel.descriptiontitle")}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) =>
                                this.handleTextChange(e, "description")
                              }
                              name="description"
                              value={description && description}
                              placeholder={t(
                                "ManageChannel.descriptionPlaceHolder"
                              )}
                            />
                          </div>

                          <div className="form-group">
                            <label className="font-14">
                              {t("ManageChannel.identifiertitle")}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) =>
                                this.handleTextChange(e, "identifier")
                              }
                              name="identifier"
                              value={identifier && identifier}
                              placeholder={t(
                                "ManageChannel.identifierPlaceHolder"
                              )}
                            />
                          </div>

                          {channel_type_identifier == "sms" && (
                            <React.Fragment>
                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.sms_api_usernametitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "sms_api_username")
                                  }
                                  name="sms_api_username"
                                  value={sms_api_username && sms_api_username}
                                  placeholder={t(
                                    "ManageChannel.sms_api_usernametitle"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.sms_api_keytitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "sms_api_key")
                                  }
                                  name="sms_api_key"
                                  value={sms_api_key && sms_api_key}
                                  placeholder={t(
                                    "ManageChannel.sms_api_keyPlaceHolder"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.sms_test_phonenumbertitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(
                                      e,
                                      "sms_test_phonenumber"
                                    )
                                  }
                                  name="sms_test_phonenumber"
                                  value={
                                    sms_test_phonenumber && sms_test_phonenumber
                                  }
                                  placeholder={t(
                                    "ManageChannel.sms_test_phonenumberPlaceHolder"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.sms_from_emailtitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "sms_from_email")
                                  }
                                  name="sms_from_email"
                                  value={sms_from_email && sms_from_email}
                                  placeholder={t(
                                    "ManageChannel.sms_from_emailPlaceHolder"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.sms_custom_stringtitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(
                                      e,
                                      "sms_custom_string"
                                    )
                                  }
                                  name="sms_custom_string"
                                  value={sms_custom_string && sms_custom_string}
                                  placeholder={t(
                                    "ManageChannel.sms_custom_stringPlaceHolder"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.sms_sourcetitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "sms_source")
                                  }
                                  name="sms_source"
                                  value={sms_source && sms_source}
                                  placeholder={t(
                                    "ManageChannel.sms_sourcePlaceHolder"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.sms_countrytitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "sms_country")
                                  }
                                  name="sms_country"
                                  value={sms_country && sms_country}
                                  placeholder={t(
                                    "ManageChannel.sms_countryPlaceHolder"
                                  )}
                                />
                              </div>
                            </React.Fragment>
                          )}

                          {channel_type_identifier == "twilio" && (
                            <React.Fragment>
                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.account_sid")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "account_sid")
                                  }
                                  name="account_sid"
                                  value={account_sid && account_sid}
                                  placeholder={t("ManageChannel.account_sid")}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.auth_token")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "auth_token")
                                  }
                                  name="auth_token"
                                  value={auth_token && auth_token}
                                  placeholder={t("ManageChannel.auth_token")}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.twilio_number")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "twilio_number")
                                  }
                                  name="twilio_number"
                                  value={twilio_number && twilio_number}
                                  placeholder={t("ManageChannel.twilio_number")}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.language")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "language")
                                  }
                                  name="twilio_number"
                                  value={language && language}
                                  placeholder={t("ManageChannel.language")}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.iso_country")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "iso_country")
                                  }
                                  name="iso_country"
                                  value={iso_country && iso_country}
                                  placeholder={t("ManageChannel.iso_country")}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.number")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "number")
                                  }
                                  name="number"
                                  value={number && number}
                                  placeholder={t("ManageChannel.number")}
                                />
                              </div>
                            </React.Fragment>
                          )}

                          {channel_type_identifier == "text2speech" && (
                            <React.Fragment>
                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.t2s_api_usernametitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "t2s_api_username")
                                  }
                                  name="t2s_api_username"
                                  value={t2s_api_username && t2s_api_username}
                                  placeholder={t(
                                    "ManageChannel.t2s_api_usernamePlaceHolder"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.t2s_api_keytitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "t2s_api_key")
                                  }
                                  name="t2s_api_key"
                                  value={t2s_api_key && t2s_api_key}
                                  placeholder={t(
                                    "ManageChannel.t2s_api_keyPlaceHolder"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.t2s_test_phonenumbertitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(
                                      e,
                                      "t2s_test_phonenumber"
                                    )
                                  }
                                  name="t2s_test_phonenumber"
                                  value={
                                    t2s_test_phonenumber && t2s_test_phonenumber
                                  }
                                  placeholder={t(
                                    "ManageChannel.t2s_test_phonenumberPlaceHolder"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.t2s_voicetitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "t2s_voice")
                                  }
                                  name="t2s_voice"
                                  value={t2s_voice && t2s_voice}
                                  placeholder={t(
                                    "ManageChannel.t2s_voicePlaceHolder"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.t2s_languagetitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "t2s_language")
                                  }
                                  name="t2s_language"
                                  value={t2s_language && t2s_language}
                                  placeholder={t(
                                    "ManageChannel.t2s_languagePlaceHolder"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.t2s_custom_stringtitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(
                                      e,
                                      "t2s_custom_string"
                                    )
                                  }
                                  name="t2s_custom_string"
                                  value={t2s_custom_string && t2s_custom_string}
                                  placeholder={t(
                                    "ManageChannel.t2s_custom_stringPlaceHolder"
                                  )}
                                />
                              </div>
                            </React.Fragment>
                          )}

                          <div className="form-group">
                            <div onChange={(e) => this.set_is_active(e)}>
                              <input
                                type="checkbox"
                                className="mr-2"
                                checked={is_active == 1 ? true : false}
                                value="0"
                                name="link_type"
                              />
                              {t("ManageChannel.is_active_title")}
                            </div>
                          </div>

                          {channel_type_identifier == "facebook" && (
                            <div className="form-group">
                              <div>
                                {t("ManageChannel.status")}
                                {!isConnected || isConnected == 0
                                  ? t("ManageChannel.notConnected")
                                  : t("ManageChannel.connected")}
                              </div>
                            </div>
                          )}

                          {channel_type_identifier == "twitter" && (
                            <div className="form-group">
                              <div>
                                {t("ManageChannel.status")}
                                {!isConnected || isConnected == 0
                                  ? t("ManageChannel.notConnected")
                                  : t("ManageChannel.connected")}
                              </div>
                            </div>
                          )}

                          <div className="form-group">
                            {channel_type_identifier == "facebook" && (
                              <Link
                                to={{
                                  pathname: "/connect-facebook",
                                  state: {
                                    isFromEdit: true,
                                    channelId: channelID,
                                    textValueObject: {
                                      name,
                                      description,
                                      identifier,
                                      is_active,
                                    },
                                  },
                                }}
                              >
                                {t("ManageChannel.connectpages")}
                              </Link>
                            )}
                          </div>

                          <div className="form-group">
                            {channel_type_identifier == "twitter" && (
                              <Link
                                to={{
                                  pathname: "/connect-twitter",
                                  state: {
                                    isFromEdit: true,
                                    channelId: channelID,
                                    textValueObject: {
                                      name,
                                      description,
                                      identifier,
                                      is_active,
                                    },
                                  },
                                }}
                              >
                                {t("ManageChannel.connecttwitter")}
                              </Link>
                            )}
                          </div>

                          {profileLink &&
                                    channel_type_identifier == "twitter" && (
                                      <div className="form-group">
                                        <a
                                          href={profileLink || '#'}
                                          rel="noopener noreferrer"
                                          target="_blank"
                                        >
                                          {t("ManageChannel.gotoLink")}
                                        </a>
                                      </div>
                                    )}
               
                   {page_id && page_id != '' && channel_type_identifier == "facebook" && (
                          <div className="form-group">
                             <a href={page_id && `https://www.facebook.com/${page_id}`} rel="noopener noreferrer" target="_blank" >{t("ManageChannel.gotoLink")}</a>
                          </div>)}    
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer type="2" />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(EditChannel));
