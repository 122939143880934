import React, { Component } from "react";
import MediaQuery from "react-responsive";
import ManageChannelMobile from "./ManageChannelMobile/Index";
import ManageChannelDesktop from "./ManageChannelDesktop/ChannelList";
import { withRouter } from "react-router";
import { checkAdminPermission } from "../../../util/utils";

class ManageChannelResponsive extends Component {
  componentDidMount() {
    if (!checkAdminPermission()) {
      this.props.history.push("/");
    }
  }
  render() {
    return (
      <React.Fragment>
        <MediaQuery query="(min-device-width: 1224px)">
          <ManageChannelDesktop />
        </MediaQuery>
        <MediaQuery query="(max-device-width: 1224px)">
          <ManageChannelMobile />
        </MediaQuery>
      </React.Fragment>
    );
  }
}
export default withRouter(ManageChannelResponsive);
