/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import {
  GroupListQuery,
  getallpersonmailinglistgroup,
  groupallpersonlist,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import * as actions from "../../../constants/mapDispatchToProps";
import * as stateToProps from "../../../constants/mapStateToProps";
import {
  getUserID,
  getValueFromLocalStorage,
  saveDatatoLocalStorage,
} from "../../../util/utils";
import FabIcon from "../../CommanComponents/FabIcon";
import Footer from "../../Footer/FooterMobile";
import LoadingPage from "../../CommanComponents/LoadingPage";
import NoRecordFound from "../../CommanComponents/NoRecordFound";
import GroupDetails from "./GroupDetails";
import GroupDetailsSetteliteSideMenu from "./GroupDetailsSetteliteSideMenu";
import SearchSubGroupItemListView from "./SearchSubGroupItemListView";
import SubbGroupItemGridView from "./SubbGroupItemGridView";
import SubGroupItemListView from "./SubGroupItemListView";
import SearchPersonResult from "../../Group/GroupListMobile/SearchPersonResult";
import SearchGroupListBaseOnPerson from "../../Group/GroupListMobile/SearchGroupListBaseOnPerson";
import SearchPersonBaseOnGroup from "../../Group/GroupListMobile/SearchPersonBaseOnGroup";
import SearchPersonBaseonCompany from "../../Group/GroupListDesktop/SearchPersonBaseonCompany";

class GroupDetailItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortType: 1,
      isLoading: false,
      headerMenuStatus: false,
      hasMore: true,
      end: 10,
      group_header_list: [],
      searchGroupList: [],
      searchPersonList: [],
      displayType: 0,
      listType: 0,
      personListForGroup: [],
      searchDisplayType: 0,
      groupListBaseOnPerson: [],
      personListBaseOnGroupID:[],
      personListBreadCumb:[],
      selectedPersonId:0,
      groupIdBaseOnPersonDetail:0
    };
  }

  componentDidMount() {
    const groupDetailDisplayType = getValueFromLocalStorage(
      "groupDetailDisplayType"
    );
    this.setState({
      displayType: groupDetailDisplayType,
    });
    this.getPersonList();
  }

  handleListType = (listType) => {
    this.setState({
      listType,
    });
  };
  loadItem = () => {
    const rootGroup = this.props.match.params.group_id;
    const { end, isLoading, sortType } = this.state;
    if (!isLoading && this.props.groupDetail.hasMore) {
      this.setState({
        isLoading: true,
      });
      apiCall(
        METHOD.QUERY,
        GroupListQuery(
          getUserID(),
          this.props.groupDetail.start,
          end,
          rootGroup,
          "",
          sortType
        ),
        (data) => {
          this.setState({
            group_header_list: data.group_header_list,
          });
          if (this.props.groupDetail.id) {
            this.props.updateGroupList(data);
          } else {
            this.props.getGroupDetail(data);
          }

          this.handleGroupDetailApiResponse(data);
        },
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  loader = (<LoadingPage key={0} />);

  handleGroupDetailApiResponse = (data) => {
    const { end } = this.state;
    this.setState({
      isLoading: true,
    });

    if (data.is_last == 1) {
      this.props.setHasMoreValue(false);
    }

    this.props.setStartValue(this.props.groupDetail.start + end);
    this.setState((prevState) => {
      return {
        isLoading: false,
      };
    });
  };

  getPersonList = () => {
    const rootGroup = this.props.match.params.group_id;
    apiCall(
      METHOD.QUERY,
      groupallpersonlist(getUserID(), rootGroup, ""),
      (data) => {
        this.setState({
          personListForGroup: data.person_list,
        });
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  changeSortType = (sortType) => {
    this.setState(
      {
        sortType: sortType,
      },
      () => {
        this.props.clearStateGroupDetail();
      }
    );
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };
  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  setSearchGroupList = (groupList, personList) => {
    this.setState({
      searchGroupList: groupList,
      searchPersonList: personList,
    });
  };

  handleAlphabatice = (name, index, list) => {
    name = name.charAt(0).toUpperCase() + name.slice(1);
    let resulAlpha = "";
    if (index == 0) {
      resulAlpha = name.charAt(0);
    } else {
      let oldName = list[index - 1].full_name;
      oldName = oldName.charAt(0).toUpperCase() + oldName.slice(1);
      const digit1 = oldName.charAt(0);
      const digit2 = name.charAt(0);

      if (digit1 != digit2) {
        resulAlpha = digit2;
      } else {
        resulAlpha = "";
      }
    }
    return resulAlpha;
  };

  updateRouteWithClearState = (route) => {
    this.props.clearStateGroupDetail();
    this.props.changeRouteStatus(route);
  };


// search methods


setGroupFromPerson=(groupListBaseOnPerson,selectedPersonId)=>{
  console.log("groupListBaseOnPerson",groupListBaseOnPerson);
  console.log("selectedPersonId",selectedPersonId);
  if(groupListBaseOnPerson.length!=0){
    this.setState({
      groupListBaseOnPerson,
      searchDisplayType:2,
      selectedPersonId
    })
  }else{
    toast.info(
      this.props.t("GeneralMessages.noMailFound") );
  }

}

handleGroupWiseResponse=(personListBaseOnGroupID,personResponse,personListBreadCumb)=>{
  this.setState({
    personListBaseOnGroupID,
    personListBreadCumb,
    personResponse,
    searchDisplayType:3
  })
}


handleDisplayType =()=>{
  this.setState({
    searchDisplayType:0
  })
}

setSearchGroupList = (personList) => {
  if (personList.length == 0) {
  }
  this.setState({
    searchPersonList: personList,
    searchDisplayType: personList.length == 0 ? 0 : 1,
  });
};

handleSearchDisplayTypeToInitialStage = () => {
  this.setState({
    searchDisplayType:0,
    search:'',
    value:''

  });
};
handleSearchDisplayType  = (searchDisplayType ) => {
  this.setState({
    searchDisplayType,
    search:'',
    value:''
  });
};

handleGroupbaseOnPerson = (mailList) => {
  if (mailList.length == 0) {
    this.setState({
      groupListBaseOnPerson: [],
      searchPersonList: [],
      // searchDisplayType: 0,
    });
  } else {
    this.setState({
      // searchDisplayType: 0,
      groupListBaseOnPerson: mailList,
    });
  }
};


  
handlePersonListBaseonCompany = (personListBaseOnGroupID)=>{
  console.log("personListBaseOnGroupIDpersonListBaseOnGroupID",personListBaseOnGroupID);
  this.setState({
    personListBaseOnGroupID,
    searchDisplayType:4
  })
}

  render() {
    const {
      sortType,
      end,
      isLoading,
      group_header_list,
      displayType,
      searchGroupList,
      searchPersonList,
      listType,
      personListForGroup,
      searchDisplayType,groupListBaseOnPerson,selectedPersonId,personListBreadCumb,personListBaseOnGroupID,
      groupIdBaseOnPersonDetail
    } = this.state;
    const { name, group_type_icon, total_contact, breadcrumb } =
      this.props.groupDetail && this.props.groupDetail;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <GroupDetails
                group_header_list={group_header_list}
                current_id={this.props.groupDetail.id}
                searchArray={this.setSearchGroupList}
                groupName={name}
                listType={listType}
                handleListType={(listType) => { this.handleListType(listType);}}
                isFromPersonList={false}
                handleMailbaseOnPerson={this.handleMailbaseOnPerson}
                setGroupFromPerson={(personList,selectedPersonId)=>{this.setGroupFromPerson(personList,selectedPersonId)}}
                searchArray={this.setSearchGroupList}
                handleSearchDisplayType={(searchDisplayType) => {
                  this.handleSearchDisplayType(searchDisplayType);
                }}
                handlePersonListBaseonCompany={(personList)=>this.handlePersonListBaseonCompany(personList)}

              />

                {searchDisplayType==0 && <React.Fragment>
                  <div className="middle-section sab-middle-section1">
                {listType == 0 ? (
                  <React.Fragment>
                    <div className="container my-4 z-index-99">
                      <div className="icon-box-style2 mr-4 mb-4">
                        <div className="icon-box blue-bg d-flex">
                          <div className="d-flex align-items-center justify-content-center w-100">
                            <div className="rounded-50 bg-light">
                              <img
                                src={`data:image/jpg;base64,${
                                  group_type_icon && group_type_icon
                                }`}
                                style={{ width: "32px" }}
                                alt="icon"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="ml-3 my-3 d-inline-block">
                          <h5 className="m-0 font-18">{name && name} </h5>
                          <p className="text-muted font-12 mb-0">
                            {total_contact && total_contact}{" "}
                            {total_contact && total_contact == 1
                              ? t("GroupListScreen.singularPerson")
                              : t("GroupListScreen.pluralPerson")}
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between align-items-center my-4">
                        <p className="font-12 m-0">
                          <React.Fragment>
                            <Link
                              to="/group-list"
                              className="text-muted"
                              data-tip={"Start"}
                            >
                              {t("GroupListScreen.start")}
                              <ReactTooltip
                                place="top"
                                type="info"
                                effect="float"
                              />
                            </Link>
                            {" > "}
                          </React.Fragment>
                          {breadcrumb &&
                            breadcrumb.map((item, index) => {
                              if (index == 0) {
                                return (
                                  <React.Fragment key={index}>
                                    <a
                                      href="javascript;:"
                                      className="text-muted"
                                      data-tip={item.name}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.clearStateGroupDetail();
                                        this.props.history.push(
                                          "/group-detail/" + item.id
                                        );
                                      }}
                                    >
                                      <ReactTooltip
                                        place="top"
                                        type="info"
                                        effect="float"
                                      />
                                      {item.name}
                                    </a>
                                    {" > "}
                                  </React.Fragment>
                                );
                              } else if (
                                index != 0 &&
                                index != breadcrumb.length - 1
                              ) {
                                return (
                                  <React.Fragment key={index}>
                                    <a
                                      href="javascript;:"
                                      className="text-muted"
                                      data-tip={item.name}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.clearStateGroupDetail();
                                        this.props.history.push(
                                          "/group-detail/" + item.id
                                        );
                                      }}
                                    >
                                      <ReactTooltip
                                        place="top"
                                        type="info"
                                        effect="float"
                                      />
                                      [...]
                                    </a>
                                    {" > "}
                                  </React.Fragment>
                                );
                              } else if (index == breadcrumb.length - 1) {
                                return (
                                  <React.Fragment key={index}>
                                    {name && name}
                                  </React.Fragment>
                                );
                              }
                            })}
                        </p>

                        <ul className="list-inline grid-view-list m-0">
                          <li
                            className={
                              displayType == 0
                                ? "list-inline-item mr-3 active"
                                : "list-inline-item mr-3"
                            }
                          >
                            <a
                              className="pointer"
                              href="javascript;:"
                              onClick={(e) => {
                                e.preventDefault();
                                saveDatatoLocalStorage(
                                  "groupDetailDisplayType",
                                  0
                                );
                                this.setState({ displayType: 0 });
                              }}
                            >
                              <i className="fa fa-list fa-lg text-muted"></i>
                            </a>
                          </li>
                          <li
                            className={
                              displayType == 1
                                ? "list-inline-item mr-3 active"
                                : "list-inline-item mr-3"
                            }
                          >
                            <a
                              className="pointer"
                              href="javascript;:"
                              onClick={(e) => {
                                e.preventDefault();
                                saveDatatoLocalStorage(
                                  "groupDetailDisplayType",
                                  1
                                );
                                this.setState({ displayType: 1 });
                              }}
                            >
                              <i className="fa fa-th-large fa-lg text-muted"></i>
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="my-2 z-index-99">
                        <div className="mb-2">
                          <div className="clearfix sorting-filter">
                            <span className="font-13 text-muted mr-2">
                              {t("GroupListScreen.sorting")}
                            </span>
                            <div
                              className="btn-group btn-group-sm"
                              role="group"
                              aria-label="First group"
                            >
                              <button
                                type="button"
                                onClick={() => this.changeSortType(1)}
                                className={
                                  sortType == 1
                                    ? "btn btn-dark"
                                    : "btn btn-outline-secondary"
                                }
                              >
                                {t("GroupListScreen.a-z")}
                              </button>

                              <button
                                type="button"
                                onClick={() => this.changeSortType(2)}
                                className={
                                  sortType == 2
                                    ? "btn btn-dark"
                                    : "btn btn-outline-secondary"
                                }
                              >
                                {t("GroupListScreen.orderOfPriority")}
                              </button>
                              <button
                                type="button"
                                onClick={() => this.changeSortType(3)}
                                className={
                                  sortType == 3
                                    ? "btn btn-dark"
                                    : "btn btn-outline-secondary"
                                }
                              >
                                {t("GroupListScreen.crisisContacts")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  
                    {searchGroupList.length != 0 ? (
                      !!searchGroupList.length &&
                      searchGroupList.map((data, index) => {
                        return (
                          <SearchSubGroupItemListView
                            data={data}
                            key={index}
                            index={index}
                            changeRouteStatus={(route) => {
                              this.updateRouteWithClearState(route);
                            }}
                          />
                        );
                      })
                    ) : (
                      <InfiniteScroll
                        pageStart={end}
                        loadMore={this.loadItem}
                        hasMore={this.props.groupDetail.hasMore}
                        useWindow={false}
                        loader={this.loader}
                      >
                        {displayType == 0 ? (
                          <div className="container my-4 mr-4">
                            {!!this.props.groupDetail.group_list.length &&
                              this.props.groupDetail.group_list.map(
                                (data, index) => {
                                  return (
                                    <SubGroupItemListView
                                      data={data}
                                      key={index}
                                      index={index}
                                      changeRouteStatus={(route) => {
                                        this.updateRouteWithClearState(route);
                                      }}
                                    />
                                  );
                                }
                              )}

                            {!isLoading &&
                              this.props.groupDetail.group_list &&
                              this.props.groupDetail.group_list.length == 0 && (
                                <NoRecordFound
                                  title="Group List"
                                  name="group list"
                                />
                              )}
                          </div>
                        ) : (
                          
                          <div className="form-row m-2">
                            {!!this.props.groupDetail.group_list.length &&
                              this.props.groupDetail.group_list.map(
                                (data, index) => {
                                  return (
                                    <SubbGroupItemGridView
                                      data={data}
                                      key={index}
                                      index={index}
                                      changeRouteStatus={(route) => {
                                        this.updateRouteWithClearState(route);
                                      }}
                                    />
                                  );
                                }
                              )}

                            {!isLoading &&
                              this.props.groupDetail.group_list &&
                              this.props.groupDetail.group_list.length == 0 && (
                                <NoRecordFound
                                  title="Group List"
                                  name="group list"
                                />
                              )}
                          </div>
                        )}
                      </InfiniteScroll>
                    )}
                    <FabIcon
                      color="rounded-blue"
                      openSlider={this.openSlider}
                    />
                  </React.Fragment>
                ) : (
                  <div className="container my-3 z-index-99">
                    {personListForGroup &&
                      personListForGroup.map((person, index) => {
                        const { id, full_name, email, is_valid_email } = person;
                        console.log("person", person);
                        return (
                          <div className="container mt-4 d-block pointer" key={index} onClick={()=>{
                            const rootGroup = this.props.match.params.group_id;
                            const groupPersonId= rootGroup+"-"+id
                             this.props.history.push("/person-detail/" + groupPersonId);
                          }}>
                            <div
                              className="rounded-40 float-left  mr-2"
                              style={{ lineHeight: "50px" }}
                            >
                              {is_valid_email && is_valid_email == 1 ? (
                                <i className="zmdi zmdi-check-circle zmdi-hc-fw font-22 text-success"></i>
                              ) : (
                                <i className="zmdi zmdi-alert-circle zmdi-hc-fw font-22 text-blue"></i>
                              )}
                            </div>
                            <div className="ml-65p pt-1 0d-flex">
                              <div className="w-100 mb-4">
                                <h6 className="m-0">
                                  {full_name && full_name}
                                </h6>
                                <p className="text-muted font-12 mb-2">
                                  {email && email}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
              <GroupDetailsSetteliteSideMenu
                closeSlider={this.closeSlider}
                headerMenuStatus={this.state.headerMenuStatus}
                changeRouteStatus={(route) => {
                  this.updateRouteWithClearState(route);
                }}
              />
              <Footer type="3" />
                  
                  </React.Fragment>}
              
                  {searchDisplayType == 1 && (
                 <SearchPersonResult 
                      suggetionList={this.state.searchPersonList}
                      handlePersonListBaseonCompany={(personList)=>this.handlePersonListBaseonCompany(personList)}
                      setGroupFromPerson={(personList,selectedPersonId)=>{this.setGroupFromPerson(personList,selectedPersonId)}}/>
                )}

            {searchDisplayType == 2 && (
                  <div className="container my-2">
                    {groupListBaseOnPerson.map((data, index) => {
                      return <SearchGroupListBaseOnPerson data={data} key={index} 
                      selectedPersonId={selectedPersonId}
                      handleGroupWiseResponse={(personListBaseOnGroupID,personResponse,personListBreadCumb,group_id)=>{this.handleGroupWiseResponse(personListBaseOnGroupID,personResponse,personListBreadCumb,group_id)}}/>;
                    })}
                  </div>
                )}

              {searchDisplayType == 3 && (
                  <div className="container my-2">
                       <nav aria-label="breadcrumb">
                        <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item">
                            {t("mailDetail.home")}
                          </li>
                          <li
                              className="breadcrumb-item pointer"
                              aria-current="page"
                              onClick={()=>{
                                // const mailDetailId = breadcrumb[0]?.id
                                // this.props.history.push("/mail-detail/" + mailDetailId);
                                }}
                            >
                             {personListBreadCumb[0]?.name}
                            </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                             {personListBreadCumb[1]?.name}
                         
                          </li>
                        </ol>
                      </nav>

                    {personListBaseOnGroupID.map((data, index) => {
                      return <SearchPersonBaseOnGroup
                      handleSearchDisplayType={(searchDisplayType) => {
                        this.handleSearchDisplayType(searchDisplayType);
                      }}
                      groupIdBaseOnPersonDetail={groupIdBaseOnPersonDetail}
                      data={data} key={index} 
                      personResponse={this.state.personResponse}/>;
                    })}
                  </div>
                )}

                
{searchDisplayType == 4 && (
                  <div className="container my-2">
                    {personListBaseOnGroupID.map((data, index) => {
                      return (
                        <SearchPersonBaseonCompany
                          handleSearchDisplayType={(searchDisplayType) => {
                            this.handleSearchDisplayType(searchDisplayType);
                          }}
                          data={data}
                          key={index}
                          personResponse={this.state.personResponse}
                          groupIdBaseOnPersonDetail={groupIdBaseOnPersonDetail}
                        />
                      );
                    })}
                  </div>
                )}


            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.groupListStateToProps,
        stateToProps.groupDetailStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupListDispatchToProps,
    actions.groupDetailDispatchToProps,
    actions.groupDetailPersonListDispatchToProps,
  ])
)(withRouter(withTranslation()(GroupDetailItem)));
