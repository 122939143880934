import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import {
  getmailinglistgroupperson,
  removepersonfrommailinglistgroup,
} from "../../../constants/apiParams";
import { getUserID } from "../../../util/utils";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

class SearchPersonBaseOnGroup extends Component {
  handelMailItemClick = (mail_id_range) => {
    this.props.history.push("/mail-detail/" + mail_id_range);
  };

  handleDelete = (person_id) => {
    const data = this.props.personResponse;
    console.log("datadata", data);
    console.log("data.group_id", data.group_id);
    console.log("person_id", person_id);
    console.log("data.mailing_id", data.mailing_id);
    confirmAlert({
      title: this.props.t("GroupDetailPersonListScreen.deleteTitel"),
      message: this.props.t("GroupDetailPersonListScreen.deleteMessage"),
      buttons: [
        {
          label: this.props.t("GroupDetailPersonListScreen.deleteYes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              removepersonfrommailinglistgroup(
                getUserID(),
                data.group_id,
                person_id,
                data.mailing_id
              ),
              (data) => {
                this.props.handleSearchDisplayType(0);
                toast.success(this.props.t(data.message));
              },
              (isLoading) => {},
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("GroupDetailPersonListScreen.deleteNo"),
          onClick: () => {},
        },
      ],
    });
  };
  componentDidMount() {
    console.log("data<<<<<<<<<<<--->>>>>>>>>>> ", this.props.data);
  }

  render() {
    const { data, index, groupIdBaseOnPersonDetail } = this.props;
    const {
      id,
      full_name,
      group_id,
      list_type_icon,
      email,
      is_valid_email,
      parent_id,
    } = data;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div
              className="ml-40p m-3 clearfix d-flex align-items-center flex-fill pointer "
              onClick={() => {
                let group_person_id = groupIdBaseOnPersonDetail + "-" + id;
                this.props.history.push("/person-detail/" + group_person_id);
              }}
            >
              <div className="rounded-40 float-left">
                {is_valid_email && is_valid_email == 1 ? (
                  <i className="zmdi zmdi-check-circle font-28 mr-4 text-success"></i>
                ) : (
                  <i className="zmdi zmdi-alert-circle font-28 mr-4 text-blue"></i>
                )}
              </div>
              <div className="align-self-center">
                <h5 className="m-0 font-18 d-block">
                  {full_name && full_name}
                </h5>
                <p className="text-muted font-12 m-0">{email && email}</p>
              </div>
              <div className="align-self-center ml-auto">
                {/* <i
                        className="zmdi zmdi-delete zmdi-hc-fw font-24 pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleDelete(id);
                        }}
                      ></i> */}
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(withRouter(SearchPersonBaseOnGroup));
